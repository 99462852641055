// External Dependencies
import { Collapse } from '@mui/material';
import { FC } from 'react';
import { Genders } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  CustomInput,
  CustomSelect,
  DatePickerField,
  GenderSelect,
  GradeSelect,
  ShirtSizeSelect,
  SpokenLanguageSelect,
} from 'components/shared';
import { hasPermission } from 'state/self/selectors';
import { useGetOrganization } from 'gql/queries';
import SuccessorOrganizationSelect from 'components/shared/Selectors/SuccessorOrganizationSelect';

// Local Dependencies
import { StudentValues } from '../Students/shared/StudentForm';

// Local Typings
interface Props {
  genderId?: StudentValues['genderId'];
  isDirector?: boolean;
  isEditing?: boolean;
  isStudent?: boolean;
}

// Component Definition
const ProfileInfoFields: FC<Props> = ({
  genderId,
  isDirector = false,
  isEditing,
  isStudent,
}) => {
  const {
    data: organizationData,
  } = useGetOrganization();

  const successorOrganization = organizationData?.organization.successorOrganization;

  const canEditUsers = useSelector(hasPermission('users', 'edit'));

  return (
    <>
      <CustomInput
        label="First Name *"
        name="firstName"
      />

      <CustomInput
        label="Middle Name"
        name="middleName"
      />

      <CustomInput
        label="Last Name *"
        name="lastName"
      />

      {isDirector && (
        <CustomInput
          label="Title"
          name="title"
          placeholder="Director"
        />
      )}

      <GenderSelect />

      <Collapse in={genderId === Genders.Other.toString()}>
        <CustomInput
          label="Other Gender Label"
          name="otherGenderLabel"
          variant="filled"
        />
      </Collapse>

      <SpokenLanguageSelect />

      {isStudent && canEditUsers && <GradeSelect />}

      <DatePickerField
        label="Date of Birth"
        name="dateOfBirth"
      />

      <ShirtSizeSelect />

      {(isStudent && canEditUsers) ? (
        <>
          <CustomInput
            label="Student ID"
            name="studentInfo.studentId"
          />
          <CustomSelect
            label="Is Eligible?"
            name="isEligible"
            options={[
              {
                id: 1,
                label: 'Yes',
              },
              {
                id: 0,
                label: 'No',
              },
            ]}
            required
          />

          {/* TODO: we can remove the isEditing condition when the */}
          {/* API allows successorOrganizationId on create */}
          {isEditing && successorOrganization && (
            <SuccessorOrganizationSelect
              helperText="Select a successor organization for this student."
            />
          )}
        </>
      ) : null}

      <CustomInput
        label="Allergies"
        name="allergies"
      />
    </>
  );
};

export default ProfileInfoFields;
