// External Dependencies
import {
  Box, Button, CardContent, Typography,
} from '@mui/material';
import { FC } from 'react';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Internal Dependencies
import {
  Container,
  EnhancedCard,
  Flex,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { UniformShowResponse } from 'gql/queries';
import { getFullName } from 'utils';
import { hasPermission } from 'state/self/selectors';

// Local Typings
interface Props {
  uniform: UniformShowResponse;
}

// Local Variables
const StyledSpan = styled.span(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

// Component Definition
const UniformCheckoutDetails: FC<Props> = ({
  uniform,
}) => {
  const canWriteUniformCheckouts = useSelector(
    hasPermission('uniformCheckouts', 'write'),
  );
  const canEditUniformCheckouts = useSelector(
    hasPermission('uniformCheckouts', 'edit'),
  );

  const handleNavigateToCreateCheckoutPage = () => {
    navigate(`/${PATHS.UNIFORM_CHECKOUTS}/new?uniform_id=${uniform.id}`);
  };

  const handleNavigateToEditCheckoutPage = (checkoutId: string) => () => {
    navigate(`/${PATHS.UNIFORM_CHECKOUTS}/${checkoutId}/edit`);
  };

  return (
    <Box mt={2}>
      <Container maxWidth={500}>
        <Subtitle>
          Checkout Status
        </Subtitle>

        <EnhancedCard>
          <CardContent>
            {uniform.activeCheckout ? (
              <Flex justifyContent="space-between">
                <Typography>
                  Checked out to{' '}
                  <StyledSpan>
                    {getFullName(uniform.activeCheckout.user)}
                  </StyledSpan>
                </Typography>

                {canEditUniformCheckouts && (
                  <Button
                    color="primary"
                    onClick={handleNavigateToEditCheckoutPage(uniform.activeCheckout.id)}
                    variant="outlined"
                  >
                    Edit Checkout
                  </Button>
                )}
              </Flex>
            ) : (
              <Flex justifyContent="space-between">
                <Typography>
                  Available for checkout
                </Typography>

                {canWriteUniformCheckouts && (
                  <Button
                    color="primary"
                    onClick={handleNavigateToCreateCheckoutPage}
                    variant="contained"
                  >
                    Checkout
                  </Button>
                )}
              </Flex>
            )}
          </CardContent>
        </EnhancedCard>
      </Container>
    </Box>
  );
};

export default UniformCheckoutDetails;
