// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';
import FinanceIcon from 'mdi-material-ui/Finance';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const FinancialTransactionsTableZeroState: FC = () => (
  <TableDataGridZeroState
    Icon={FinanceIcon}
    excludeNeedHelp
    maxTextWidth="75%"
    message={(
      <Typography>
        All financial transactions will appear here automatically
        when added in other parts of the Presto app.
      </Typography>
    )}
  />
);

export default FinancialTransactionsTableZeroState;
