import { CustomInput } from 'components/shared';
import { FC, HTMLProps } from 'react';

interface Props {
  formBlock: GQL.IMyFormBlock;
  isTextArea?: boolean;
  type?: HTMLProps<HTMLInputElement>['type'];
}

const TextBlock: FC<Props> = ({
  formBlock,
  isTextArea,
  type,
}) => {
  return (
    <CustomInput
      label={formBlock.label}
      multiline={isTextArea}
      name="response"
      rows={4}
      type={type}
    />
  );
};

export default TextBlock;
