// External Dependencies
import {
  Box,
  CardContent,
  Divider,
  Grow,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC, useEffect } from 'react';
import { FinancialPaymentTypes } from '@presto-assistant/api_types';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

// Internal Dependencies
import {
  Checkbox,
  CustomSelect,
} from 'components/shared';
import { useFormikTextField } from 'hooks/useFormikTextField';

// Local Dependencies
import { PaymentsByUserFormValues } from './PaymentFormByUserForm';
import {
  useGetPaymentTypeSelectOptions,
} from '../All/FinancialPaymentsTable/data';

// Local Typings
interface Props {
  formValues: PaymentsByUserFormValues;
  hasStudentFees: boolean;
  onResetFormikForm: () => void;
  paymentTypeId: string;
  studentId: string;
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '.checkboxContainer': {
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.secondary,
    },
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  '.formElement': {
    margin: 0,
  },
  '.sectionSubtitle': {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
}));

const startAdornment = <InputAdornment position="start">$</InputAdornment>;

// Component Definition
const PaymentFormPayment: FC<Props> = ({
  formValues,
  onResetFormikForm,
  paymentTypeId,
  studentId,
}) => {
  const { setFieldValue } = useFormikContext();

  const amountField = useFormikTextField('amount', formValues.amount.toLocaleString());
  const checkNumberField = useFormikTextField('checkNumber', formValues.checkNumber);
  const creditCardAccountHolderField = useFormikTextField('creditCardAccountHolder', formValues.creditCardAccountHolder);
  const creditCardExpField = useFormikTextField('creditCardExp', formValues.creditCardExp, { format: 'ccExp' });
  const creditCardLastFourField = useFormikTextField('creditCardLastFour', formValues.creditCardLastFour, { max: 4 });
  const datePaidField = useFormikTextField('datePaid', formValues.datePaid);
  const otherLabelField = useFormikTextField('otherLabel', formValues.otherLabel);
  const referenceNumberField = useFormikTextField('referenceNumber', formValues.referenceNumber);

  const handleChangePayTotalAmountCheckbox = (evt: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('isPayingAllFees', evt.target.checked);
  };

  // If the student id changes, then we need to reset the formik form values
  useEffect(() => {
    if (studentId) {
      onResetFormikForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId]);

  const { data: typeOptionsData } = useGetPaymentTypeSelectOptions();

  let cashAndCheckOptions;
  if (typeOptionsData?.financialPaymentTypes) {
    // Directors are not allowed to enter a Stripe payment for a student
    // We remove the first option (Stripe) from the types
    cashAndCheckOptions = typeOptionsData.financialPaymentTypes.filter(
      (type) => type.id !== FinancialPaymentTypes.Stripe.toString(),
    );
  }

  const isCheckPayment = paymentTypeId === FinancialPaymentTypes.Check.toString();
  const isCCPayment = paymentTypeId === FinancialPaymentTypes.CreditCard.toString();
  const isOtherPaymentType = paymentTypeId === FinancialPaymentTypes.Other.toString();

  useEffect(() => {
    if (!isCheckPayment) {
      setFieldValue('checkNumber', '');
    }

    if (!isCCPayment) {
      setFieldValue('creditCardAccountHolder', '');
      setFieldValue('creditCardExp', '');
      setFieldValue('creditCardLastFour', '');
    }

    if (!isOtherPaymentType) {
      setFieldValue('otherLabel', '');
      setFieldValue('referenceNumber', '');
    }
  }, [isCCPayment, isCheckPayment]);

  return (
    <>
      <Divider sx={{ mx: 3, my: 0.5 }} />

      <StyledCardContent>
        <Typography
          component="h2"
          className="sectionSubtitle"
          variant="subtitle1"
        >
          Enter payment info
        </Typography>

        <Box
          display="grid"
          gap={2}
          gridTemplateColumns="repeat(auto-fill, 192px)"
        >
          <TextField
            InputProps={{
              readOnly: formValues.isPayingAllFees,
              startAdornment,
            }}
            label="Amount"
            variant="filled"
            {...amountField}
          />

          <TextField
            label="Date Paid"
            type="date"
            variant="filled"
            {...datePaidField}
          />

          {typeOptionsData?.financialPaymentTypes && (
            <CustomSelect
              label="Payment Type"
              menuClasses="formElement"
              name="financialPaymentTypeId"
              options={cashAndCheckOptions}
            />
          )}

          {isCheckPayment && (
            <Grow in={isCheckPayment}>
              <TextField
                label="Check Number"
                variant="filled"
                {...checkNumberField}
              />
            </Grow>
          )}

          {isCCPayment && (
            <>
              <Grow in>
                <TextField
                  label="Account Holder"
                  variant="filled"
                  {...creditCardAccountHolderField}
                />
              </Grow>
              <Grow in>
                <TextField
                  label="Expiration"
                  variant="filled"
                  {...creditCardExpField}
                />
              </Grow>
              <Grow in>
                <TextField
                  label="Last Four"
                  variant="filled"
                  {...creditCardLastFourField}
                />
              </Grow>
            </>
          )}

          {isOtherPaymentType && (
            <>
              <Grow in>
                <TextField
                  label="Payment Type Name"
                  placeholder="Online store"
                  variant="filled"
                  {...otherLabelField}
                />
              </Grow>
              <Grow in>
                <TextField
                  label="Reference Number"
                  variant="filled"
                  {...referenceNumberField}
                />
              </Grow>
            </>
          )}
        </Box>

        <div className="checkboxContainer">
          <Checkbox
            checked={formValues.isPayingAllFees}
            color="primary"
            label="Pay in full"
            onChange={handleChangePayTotalAmountCheckbox}
          />
        </div>
      </StyledCardContent>
    </>
  );
};

export default PaymentFormPayment;
