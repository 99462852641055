// Internal Dependencies
import { AuthRouteProps } from 'components/shared/Auth';
import { PATHS } from 'utils/constants/routes';
import AdminAdultsWithPermissionsTable from 'pages/DistrictAdmin/AdminOrganization/AdminAdultsWithPermissionsTable/All';
import AdminAssistantsNew from 'pages/DistrictAdmin/AdminAssistants/New';
import AdminAssistantsTable from 'pages/DistrictAdmin/AdminAssistants/All/AdminAssistantsTable';
import AdminCreateOrganization from 'pages/DistrictAdmin/AdminCreateOrganization';
import AdminDirectorPermissions from 'pages/DistrictAdmin/AdminDirectorPermissions/All';
import AdminEditDistrictInfo from 'pages/DistrictAdmin/AdminSettings/AdminEditDistrictInfo';
import AdminLibrary from 'pages/DistrictAdmin/AdminLibrary/All';
import AdminLibraryEdit from 'pages/DistrictAdmin/AdminLibrary/Edit';
import AdminLibraryNew from 'pages/DistrictAdmin/AdminLibrary/New';
import AdminLibraryShow from 'pages/DistrictAdmin/AdminLibrary/Show';
import AdminLicenses from 'pages/DistrictAdmin/AdminLicenses/All';
import AdminOrganizationsClaim from 'pages/DistrictAdmin/AdminOrganizationsClaim';
import AdminUniforms from 'pages/DistrictAdmin/AdminUniforms/All';
import AdminUniformsEdit from 'pages/DistrictAdmin/AdminUniforms/Edit';
import AdminUniformsNew from 'pages/DistrictAdmin/AdminUniforms/New';
import AdminUniformsShow from 'pages/DistrictAdmin/AdminUniforms/Show';
import DistrictAdminDashboard from 'pages/DistrictAdmin/AdminDashboard';
import DistrictAdminDeletedInventory from 'pages/DistrictAdmin/AdminDeletedInventory/All';
import DistrictAdminDeletedInventoryShow from 'pages/DistrictAdmin/AdminDeletedInventory/Show';
import DistrictAdminDynamicFields from 'pages/DistrictAdmin/AdminDynamicFields/All';
import DistrictAdminDynamicFieldsEdit from 'pages/DistrictAdmin/AdminDynamicFields/Edit';
import DistrictAdminDynamicFieldsNew from 'pages/DistrictAdmin/AdminDynamicFields/New';
import DistrictAdminEditDirectorPermissions from 'pages/DistrictAdmin/AdminOrganization/shared/DistrictAdminEditPermissions';
import DistrictAdminEditParentPermissions from 'pages/DistrictAdmin/AdminOrganization/shared/DistrictAdminEditParentPermissions';
import DistrictAdminInventory from 'pages/DistrictAdmin/AdminInventory/All';
import DistrictAdminInventoryEdit from 'pages/DistrictAdmin/AdminInventory/Edit';
import DistrictAdminInventoryNew from 'pages/DistrictAdmin/AdminInventory/New';
import DistrictAdminInventorySettings from 'pages/DistrictAdmin/AdminInventory/Settings';
import DistrictAdminInventoryShow from 'pages/DistrictAdmin/AdminInventory/Show';
import DistrictAdminInvitations from 'pages/DistrictAdmin/AdminInvitations';
import DistrictAdminOrganization from 'pages/DistrictAdmin/AdminOrganization';
import DistrictAdminOrganizations from 'pages/DistrictAdmin/AdminOrganizations';
import DistrictAdminSettings from 'pages/DistrictAdmin/AdminSettings';
import Feedback from 'pages/Feedback';
import JoinNewOrganization from 'pages/JoinNewOrganization';
import JoinNewOrganizationSuccess from 'pages/JoinNewOrganization/Success';
import Profile from 'pages/Profile';

// This isn't really a hook, but it could likely become one as we add more feature flags
export const useGetDistrictAdminRoutes = (): AuthRouteProps[] => [
  {
    component: DistrictAdminDashboard,
    path: PATHS.DASHBOARD,
  },
  {
    component: DistrictAdminDynamicFields,
    path: PATHS.DYNAMIC_FIELDS,
  },
  {
    component: DistrictAdminDynamicFieldsEdit,
    path: `${PATHS.DYNAMIC_FIELDS}/:id/edit`,
  },
  {
    component: DistrictAdminDynamicFieldsNew,
    path: `${PATHS.DYNAMIC_FIELDS}/new`,
  },
  // ** DISTRICT ASSISTANTS **
  {
    component: AdminAssistantsTable,
    path: PATHS.DISTRICT_ASSISTANTS,
  },
  {
    component: AdminAssistantsNew,
    path: `${PATHS.DISTRICT_ASSISTANTS}/new`,
  },
  // ** INVENTORY **
  {
    component: DistrictAdminInventory,
    path: PATHS.INVENTORY,
  },
  {
    component: DistrictAdminDeletedInventory,
    path: PATHS.INVENTORY_DELETED,
  },
  {
    component: DistrictAdminDeletedInventoryShow,
    path: `${PATHS.INVENTORY_DELETED}/:id`,
  },
  {
    component: DistrictAdminInventoryNew,
    path: `${PATHS.INVENTORY_NEW}`,
  },
  {
    component: DistrictAdminInventorySettings,
    path: `${PATHS.INVENTORY_SETTINGS}`,
  },
  {
    component: DistrictAdminInventoryShow,
    path: `${PATHS.INVENTORY}/:id`,
  },
  {
    component: DistrictAdminInventoryEdit,
    path: `${PATHS.INVENTORY}/:id/edit`,
  },
  {
    component: DistrictAdminInvitations,
    path: `${PATHS.INVITATIONS}`,
  },
  {
    component: DistrictAdminOrganization,
    path: `${PATHS.ORGANIZATIONS}/:id`,
  },
  {
    component: AdminAdultsWithPermissionsTable,
    path: `${PATHS.ORGANIZATIONS}/:id/${PATHS.PEOPLE}/${PATHS.ADULTS_WITH_PERMISSIONS}`,
  },
  {
    component: DistrictAdminEditParentPermissions,
    path: `${PATHS.ORGANIZATIONS}/:id/${PATHS.PEOPLE}/${PATHS.ADULTS_WITH_PERMISSIONS}/:id/edit/${PATHS.PERMISSIONS}`,
  },
  {
    component: DistrictAdminEditDirectorPermissions,
    path: `${PATHS.ORGANIZATIONS}/:id/${PATHS.DIRECTORS}/:id/edit/${PATHS.PERMISSIONS}`,
  },
  {
    component: DistrictAdminOrganizations,
    path: PATHS.ORGANIZATIONS,
  },
  {
    component: AdminCreateOrganization,
    path: `${PATHS.ORGANIZATIONS}/new`,
  },
  {
    component: AdminOrganizationsClaim,
    path: `${PATHS.ORGANIZATIONS_CLAIM}`,
  },
  {
    component: JoinNewOrganization,
    path: PATHS.JOIN_NEW_ORGANIZATION,
  },
  {
    component: JoinNewOrganizationSuccess,
    path: PATHS.JOIN_NEW_ORGANIZATION_SUCCESS,
  },
  {
    component: AdminLicenses,
    path: `${PATHS.LICENSES}`,
  },
  {
    component: DistrictAdminSettings,
    path: PATHS.SETTINGS,
  },
  {
    component: AdminDirectorPermissions,
    path: PATHS.DIRECTOR_PERMISSIONS,
  },
  // ** LIBRARY **
  {
    component: AdminLibrary,
    path: PATHS.LIBRARY,
  },
  {
    component: AdminLibraryNew,
    path: `${PATHS.LIBRARY}/new`,
  },
  {
    component: AdminLibraryShow,
    path: `${PATHS.LIBRARY}/:id`,
  },
  {
    component: AdminLibraryEdit,
    path: `${PATHS.LIBRARY}/:id/edit`,
  },
  // ** PROFILE **
  {
    component: Profile,
    path: `${PATHS.PROFILE}`,
  },
  // ** UNIFORMS **
  {
    component: AdminUniforms,
    path: PATHS.UNIFORMS,
  },
  {
    component: AdminUniformsNew,
    path: `${PATHS.UNIFORMS}/new`,
  },
  {
    component: AdminUniformsShow,
    path: `${PATHS.UNIFORMS}/:id`,
  },
  {
    component: AdminUniformsEdit,
    path: `${PATHS.UNIFORMS}/:id/edit`,
  },
  {
    component: AdminEditDistrictInfo,
    path: PATHS.SETTINGS_DISTRICT_INFO,
  },
  {
    component: Feedback,
    path: PATHS.FEEDBACK,
  },
];
