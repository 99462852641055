// External Dependencies
import {
  CardContent,
  CardContentProps,
} from '@mui/material';
import { Ref, forwardRef } from 'react';
import styled from 'styled-components';

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    padding: theme.spacing(2),
  },
}));

// Component Definition
const EnhancedCardContent = forwardRef((
  props: CardContentProps,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    children,
    ...otherProps
  } = props;

  return (
    <StyledCardContent
      {...otherProps}
      ref={ref}
    >
      {children}
    </StyledCardContent>
  );
});

export default EnhancedCardContent;
