// External Dependencies
import { Box, CircularProgress } from '@mui/material';
import { FC, useEffect } from 'react';
import { PATHS } from 'utils/constants/routes';
import { navigate } from '@reach/router';

// Internal Dependencies
import { useGetOrganization } from 'gql/queries';

// Local Dependencies
import EndOfYearWrapper from './EndOfYearWrapper';

// Component Definition
const EndOfYear: FC = () => {
  const { data, loading } = useGetOrganization();

  useEffect(() => {
    if (!loading && data?.organization
      && !data?.organization?.canBeginEndOfYearProcess) {
      navigate(`${PATHS.DASHBOARD}`);
    }
  }, [data, loading]);

  return (
    <>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          mt={4}
        >
          <CircularProgress />
        </Box>
      )}

      {data?.organization?.canBeginEndOfYearProcess && (
        <EndOfYearWrapper
          maxCompletedStep={data?.organization?.maxCompletedEndOfYearStep ?? 0}
        />
      )}
    </>
  );
};

export default EndOfYear;
