// External Dependencies
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

// Internal Dependencies
import {
  GET_UNIFORM,
  useGetUniformCheckout,
} from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { useUpdateUniformCheckout } from 'gql/mutations';

// Local Dependencies
import UniformCheckoutForm, { UniformCheckoutFormValues } from '../shared/UniformCheckoutForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Local Variables
const navigateToUniformShow = (id: string) => {
  navigate(`/${PATHS.UNIFORMS}/${id}`);
};

// Component Definition
const UniformCheckoutEdit: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const [uniformId, setUniformId] = useState<string | null>(null);

  const {
    data: uniformCheckoutData,
    loading: isUniformCheckoutLoading,
  } = useGetUniformCheckout(id);

  const [updateUniformCheckout] = useUpdateUniformCheckout(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['uniformsIndex'],
      onCompleted: (data) => navigateToUniformShow(data.updateUniformCheckout.uniform.id),
      refetchQueries: () => [
        { query: GET_UNIFORM, variables: { id: uniformId } },
      ],
    },
  );

  const handleSubmit = async (values: UniformCheckoutFormValues) => {
    await updateUniformCheckout({
      variables: {
        ...values,
        id: id!,
      },
    });
  };

  useEffect(() => {
    setUniformId(uniformCheckoutData?.uniformCheckout.uniform.id ?? null);
  }, [uniformCheckoutData]);

  return (
    <Page isLoading={isUniformCheckoutLoading}>
      {uniformCheckoutData?.uniformCheckout && (
        <UniformCheckoutForm
          onSubmit={handleSubmit}
          title="Edit Uniform Checkout"
          uniform={uniformCheckoutData.uniformCheckout.uniform}
          uniformCheckout={uniformCheckoutData.uniformCheckout}
          user={uniformCheckoutData.uniformCheckout.user}
        />
      )}
    </Page>
  );
};

export default UniformCheckoutEdit;
