// External Dependencies
import { FC } from 'react';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateUniform } from 'gql/mutations';
import { useGetGettingStartedCounts } from 'gql/queries';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import { convertDollarsToCents } from '@presto-assistant/api_types/utils';
import UniformForm, { UniformFormValues } from '../shared/UniformForm';

// Local Variables
const navigateToShow = (id: string) => {
  navigate(`/${PATHS.UNIFORMS}/${id}`);
};

// Component Definition
const UniformNewPage: FC = () => {
  const canUseDynamicFields = useCanUseDynamicFields();

  const uniformParams = useSelector(tableQueryParams('uniforms'));

  const {
    data: gettingStartedCountsData,
  } = useGetGettingStartedCounts();

  const hasAtLeastOneUniformItem = (gettingStartedCountsData?.dashboardMetrics?.uniformCount
    ?? 0) > 0;

  const [handleCreateUniform] = useCreateUniform(
    {
      clearCachePredicates: [
        'uniformsIndex',
        ...!hasAtLeastOneUniformItem ? ['dashboardMetrics'] : [],
      ],
      onCompleted: (data) => navigateToShow(data.createUniform.id),
    },
  );

  const handleSubmit = async (values: UniformFormValues) => {
    const updatedValues = {
      ...values,
      purchaseValueInCents: values.purchaseValueInCents
        ? convertDollarsToCents(Number(values.purchaseValueInCents))
        : undefined,
      uniformTypeId: values.uniformTypeId.toString(),
    };

    // The API won't like us sending in a value that doesn't exist in the model
    delete updatedValues.orgTypeId;

    await handleCreateUniform({
      variables: {
        input: {
          ...updatedValues,
          ...(canUseDynamicFields
            ? applyDynamicFields(updatedValues as unknown as DynamicFields)
            : {}),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'Uniforms',
        path: `/${PATHS.UNIFORMS}${uniformParams}`,
      }}
    >
      <UniformForm
        canUseDynamicFields={canUseDynamicFields}
        onSubmit={handleSubmit}
        title="New Uniform"
      />
    </Page>
  );
};

export default UniformNewPage;
