// External Dependencies
import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';
import AccountBalance from '@mui/icons-material/AccountBalance';

// Internal Dependencies
import {
  Container,
  History,
  Page,
  ShowCard,
  ShowPageDataDisplay,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { useGetFinancialAccount } from 'gql/queries';
import { useIsOnlinePaymentsAvailable } from 'hooks/useCanMakePayments';

// Local Dependencies
import OnlinePaymentsAlert from '../shared/OnlinePaymentsAlert';

// Local Typings
interface RouteProps {
  id: string;
}

// Component Definition
const AccountsShowPage: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const {
    data,
    error,
    loading,
  } = useGetFinancialAccount(id!);

  const isOnlinePaymentsAvailable = useIsOnlinePaymentsAvailable();

  const canEditAccounts = useSelector(hasPermission('financialAccounts', 'edit'));

  const editPath = canEditAccounts
    ? `/${PATHS.FINANCIAL_ACCOUNTS}/${id}/edit` : undefined;

  return (
    <Page
      backButtonProps={{
        label: 'Financial Overview',
        path: `/${PATHS.FINANCIAL_OVERVIEW}`,
      }}
      editButtonPath={editPath}
      error={error}
      isLoading={!data || loading}
    >
      <Container>
        <ShowCard
          icon={AccountBalance}
          isLoading={loading}
          title="Financial Account"
        >
          <ShowPageDataDisplay
            label="Name"
            value={data?.financialAccount.label}
          />

          {isOnlinePaymentsAvailable && (
            <ShowPageDataDisplay
              label="Online Payments Allowed"
              type="boolean"
              value={data?.financialAccount.onlinePaymentsEnabled}
            />
          )}

          <ShowPageDataDisplay
            label="Can Apply Fundraiser Credits"
            type="boolean"
            value={data?.financialAccount.canApplyFundraiserCredits}
          />

          {!data?.financialAccount.onlinePaymentsEnabled
            && isOnlinePaymentsAvailable
            && <OnlinePaymentsAlert />}
        </ShowCard>

        {data?.financialAccount && (
          <History
            id={data.financialAccount.id}
            resource="financialAccounts"
          />
        )}
      </Container>
    </Page>
  );
};

export default AccountsShowPage;
