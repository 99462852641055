// External Dependencies
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

// Internal Dependencies
import { GET_INVENTORY_ITEM } from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { useGetInventoryCheckout } from 'gql/queries/inventory-checkout-queries';
import { useUpdateInventoryCheckout } from 'gql/mutations';

// Local Dependencies
import InventoryCheckoutForm, { InventoryCheckoutFormValues } from '../shared/InventoryCheckoutForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Local Variables
const navigateToInventoryShow = (id: string) => {
  navigate(`/${PATHS.INVENTORY}/${id}`);
};

// Component Definition
const InventoryCheckoutEdit: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const [inventoryItemId, setInventoryItemId] = useState<string | null>(null);

  const {
    data: inventoryCheckoutData,
    loading: isInventoryCheckoutLoading,
  } = useGetInventoryCheckout(id!);

  const [updateInventoryCheckout] = useUpdateInventoryCheckout(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['inventoryItemsIndex'],
      onCompleted: (data) => navigateToInventoryShow(data.updateInventoryCheckout.item.id),
      refetchQueries: () => [
        { query: GET_INVENTORY_ITEM, variables: { id: inventoryItemId } },
      ],
    },
  );

  const handleSubmit = async (values: InventoryCheckoutFormValues) => {
    await updateInventoryCheckout({
      variables: {
        ...values,
        id: id!,
      },
    });
  };

  useEffect(() => {
    setInventoryItemId(inventoryCheckoutData?.inventoryCheckout.item.id ?? null);
  }, [inventoryCheckoutData]);

  return (
    <Page isLoading={isInventoryCheckoutLoading}>
      {inventoryCheckoutData?.inventoryCheckout && (
        <InventoryCheckoutForm
          inventoryCheckout={inventoryCheckoutData.inventoryCheckout}
          inventoryItem={inventoryCheckoutData.inventoryCheckout.item}
          member={inventoryCheckoutData.inventoryCheckout.user}
          onSubmit={handleSubmit}
          title="Edit Inventory Checkout"
        />
      )}
    </Page>
  );
};

export default InventoryCheckoutEdit;
