// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';

// Local Dependencies
import InventoryTable from './InventoryTable';

// Component Definition
const Inventory: FC = () => (
  <>
    <Subtitle>
      Inventory Items
    </Subtitle>
    <EnhancedCard>
      <InventoryTable />
    </EnhancedCard>
  </>
);

export default Inventory;
