// External Dependencies
import { Button, Link, Typography } from '@mui/material';
import { FC, useState } from 'react';

// Internal Dependencies
import { Flex } from 'components/shared';

// Local Typings
interface Props {
  recipients: GQL.IEmailShowRecipient[];
}

// Local Variables
const USER_SHOW_LIMIT = 3;

// Component Definition
const RecipientList: FC<Props> = ({ recipients }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleList = () => {
    setIsOpen((open) => !open);
  };

  const list = isOpen && recipients.length > USER_SHOW_LIMIT
    ? recipients
    : recipients.slice(0, USER_SHOW_LIMIT);

  const usersString = list
    .map((user) => `${user.firstName} ${user.lastName}`)
    .join(', ');

  const remainingCount = recipients.length - list.length;

  const hasHiddenUsers = remainingCount > 0;

  return (
    <Flex
      flexWrap="nowrap"
      justifyContent="space-between"
    >
      <Typography
        component="p"
        variant="body2"
      >
        <strong>To: </strong>{usersString}
        {hasHiddenUsers && (
          <>
            {' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              component="button"
              onClick={toggleList}
              variant="body2"
            >
              {`& ${remainingCount} more`}
            </Link>
          </>
        )}
      </Typography>

      {recipients.length > USER_SHOW_LIMIT && (
        <Button
          color="primary"
          onClick={toggleList}
          size="small"
          variant="text"
        >
          {hasHiddenUsers ? 'Show' : 'Hide'}
        </Button>
      )}
    </Flex>
  );
};

export default RecipientList;
