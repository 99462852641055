// External Dependencies
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { useGradeColDef } from 'components/shared/TableDataGrid/hooks';

// Local Variables
export const useColumns = ({
  checklist,
} : {
  checklist: GQL.IChecklist;
}) => {
  const firstMemberDetails = checklist.memberItemDetails[0];
  const items = useMemo(
    () => firstMemberDetails?.checklistItems || [],
    [firstMemberDetails?.checklistItems],
  );

  const gradeCol = useGradeColDef<GQL.IChecklistMemberItemDetails>({
    field: 'member.grade' as keyof GQL.IChecklistMemberItemDetails,
    valueGetter: (params) => params.row.member.grade,
  });

  return useMemo<DataGridColDef<GQL.IChecklistMemberItemDetails>[]>(() => {
    return [
      {
        field: 'member.firstName' as keyof GQL.IChecklistMemberItemDetails,
        headerName: 'First Name',
        valueGetter: (params) => params.row.member.firstName,
      },
      {
        field: 'member.lastName' as keyof GQL.IChecklistMemberItemDetails,
        headerName: 'Last Name',
        valueGetter: (params) => params.row.member.lastName,
      },
      { ...gradeCol },
      ...items.map<DataGridColDef<GQL.IChecklistMemberItemDetails>>((item, index) => ({
        editable: true,
        field: item.label as keyof GQL.IChecklistMemberItemDetails,
        headerName: `${item.label}${item.isRequired ? '*' : ''}`,
        minWidth: 200,
        type: 'boolean',
        valueGetter: (params) => {
          const checklistItem = params.row?.checklistItems[index];

          return Boolean(checklistItem?.completedAt);
        },
        valueSetter: (params) => {
          return {
            ...params.row,
            checklistItems: params.row.checklistItems.map((checklistItem) => {
              if (checklistItem.itemId === item.itemId) {
                return {
                  ...checklistItem,
                  completedAt: params.value ? new Date().toISOString() : null,
                };
              }

              return checklistItem;
            }),
          };
        },
      })),
    ];
  }, [gradeCol, items]);
};
