// External Dependencies
import { FC, useCallback } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { useCreateFinancialCreditTransfer } from 'gql/mutations';

// Local Dependencies
import { useGetFinancialCredit } from 'gql/queries';
import CreditTransferForm from './CreditTransferForm';

// Local Typings
interface RouteProps {
  creditId: string;
}

// Component Definition
const FinancialCreditsTransfersNew: FC<RouteComponentProps<RouteProps>> = ({
  creditId,
}) => {
  const showPath = `/${PATHS.FINANCIAL_CREDITS}/${creditId}`;

  const {
    data: financialCreditData,
    loading: isFinancialCreditLoading,
  } = useGetFinancialCredit(creditId!);

  const navigateToShow = useCallback(() => {
    navigate(showPath);
  }, [showPath]);

  const [createFinancialCreditTransfer] = useCreateFinancialCreditTransfer(
    {
      clearCachePredicates: [
        'financialCredit',
        'financialCredits',
        'financialCreditsIndex',
      ],
      onCompleted: navigateToShow,
    },
  );

  const handleSubmitForm = (
    submitValues: GQL.ICreateFinancialCreditTransferInput,
  ) =>
    createFinancialCreditTransfer({
      variables: {
        input: submitValues,
      },
    });

  return (
    <Page
      backButtonProps={{
        label: 'Credit',
        path: showPath,
      }}
      isLoading={isFinancialCreditLoading}
    >
      {financialCreditData?.financialCredit && (
        <CreditTransferForm
          financialCredit={financialCreditData.financialCredit}
          onSubmit={handleSubmitForm}
        />
      )}
    </Page>
  );
};

export default FinancialCreditsTransfersNew;
