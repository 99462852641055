// External Dependencies
import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
} from 'utils';
import { useGetFinancialAccounts, useGetFinancialItems } from 'gql/queries';
import {
  useMemo,
} from 'react';

// Local Variables
export const useColumns = ({
  extraColumns,
  schoolYearEnding,
}: {
  extraColumns: GridColDef<GQL.IFinancialFeeIndexItem>[];
  schoolYearEnding: number;
}) => {
  const {
    data: financialAccountsData,
  } = useGetFinancialAccounts();

  const {
    data: financialItemsData,
  } = useGetFinancialItems(schoolYearEnding);

  return useMemo(() => {
    const columns: GridColDef<GQL.IFinancialFeeIndexItem>[] = [
      {
        field: 'userFirstName',
        headerName: 'First Name',
        width: 160,
      },
      {
        field: 'userLastName',
        headerName: 'Last Name',
        width: 160,
      },
      {
        field: 'financialItemLabel',
        headerName: 'Item',
        type: 'singleSelect',
        valueGetter: (params) => (params.row as GQL.IFinancialFeeIndexItem).financialItemLabel,
        valueOptions: financialItemsData?.financialItems.data.map((item) => ({
          label: item.label,
          value: item.id,
        })),
        valueParser: (value) => financialItemsData?.financialItems.data
          .find((account) => account.id === value)?.label,
        width: 160,
      },
      {
        field: 'financialAccountId',
        headerName: 'Account',
        type: 'singleSelect',
        valueGetter: (params) => (params.row as GQL.IFinancialFeeIndexItem).financialAccountLabel,
        valueOptions: financialAccountsData?.financialAccounts.map((account) => ({
          label: account.label,
          value: account.id,
        })),
        valueParser: (value) => financialAccountsData?.financialAccounts
          .find((account) => account.id === value)?.label,
        width: 160,
      },
      {
        field: 'isWaived',
        headerName: 'Is Waived',
        type: 'boolean',
        width: 160,
      },
      {
        field: 'balanceDueInCents',
        headerName: 'Balance Due',
        type: 'number',
        valueFormatter: (params) =>
          displayPriceStringFromDollarAmount(params.value),
        valueGetter: (params) =>
          convertCentsToDollars((params.row as GQL.IFinancialFeeIndexItem).balanceDueInCents),
        width: 160,
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    extraColumns,
    financialAccountsData,
    financialItemsData,
  ]);
};
