// External Dependencies
import { Box, CircularProgress, Typography } from '@mui/material';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import CalendarIcon from 'mdi-material-ui/CalendarMultiselect';

// Internal Dependencies
import {
  StyledLink,
  ZeroStateIcon,
} from 'components/shared';
import { useGetGoogleCalendarOAuthUrl } from 'gql/queries';
import useIsOrgCreator from 'hooks/useIsOrgCreator';

// Component Definition
const GoogleSyncMessage: FC = () => {
  const {
    palette: {
      history: {
        showMoreButton,
      },
    },
  } = useTheme();

  const isOrgCreator = useIsOrgCreator();

  const [
    getURL,
    { loading },
  ] = useGetGoogleCalendarOAuthUrl();

  const handleSendToGoole = async () => {
    getURL();
  };

  const syncText = isOrgCreator ? (
    <StyledLink onClick={handleSendToGoole}>
      sync your Google calendar
    </StyledLink>
  ) : "ask the organization's creator to sync Google calendar";

  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <ZeroStateIcon
        Icon={CalendarIcon}
        htmlColor={showMoreButton}
      />
      <Box px={2}>
        <Typography
          color="textSecondary"
          component="div"
          textAlign="center"
          variant="body2"
        >
          Please {syncText} to preview upcoming events.
        </Typography>
      </Box>
    </>
  );
};

export default GoogleSyncMessage;
