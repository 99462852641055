// External Dependencies
import {
  Box,
  Checkbox,
  Collapse,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { alpha } from '@mui/material/styles';
import styled from 'styled-components';

// Local Dependencies
import EnhancedAlert from '../EnhancedAlert';
import PermissionFormGroup from './PermissionFormGroup';
import SaveButton from '../SaveButton';

// Local Typings
interface Props {
  isDirector: boolean;
  isLoading: boolean;
  mayEditPermissions: boolean;
  onChangePermission: (permissionId: number) => void;
  onSave: () => void;
  onToggleEditPermissionsCheckbox: () => void;
  organizationName: string | undefined;
  permissionIds: number[];
  permissions: GQL.IUser['permissions'];
}

// Local Variables
const StyledSection = styled.section(({ theme }) => ({
  '.checkbox': {
    '&:hover': {
      backgroundColor: alpha(theme.palette.stripeBlue['500'], theme.palette.action.hoverOpacity),
    },
    color: theme.palette.stripeBlue['600'],
  },
  '.checked': {
    '&.Mui-checked': {
      '&:hover': {
        backgroundColor: alpha(theme.palette.stripeBlue['500'], theme.palette.action.hoverOpacity),
      },
      color: theme.palette.stripeBlue['500'],
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.stripeBlue['500'], theme.palette.action.hoverOpacity),
    },
  },
  '.editOtherUsers': {
    margin: `0 ${theme.spacing(2)}`,
  },
  '.label': {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
}));

// Component Definition
const PermissionEditPageUI: FC<Props> = ({
  isDirector,
  isLoading,
  mayEditPermissions,
  onChangePermission,
  onSave,
  onToggleEditPermissionsCheckbox,
  organizationName,
  permissionIds,
  permissions,
}) => (
  <>
    <StyledSection>
      <Collapse in={Boolean(organizationName)}>
        {organizationName && (
        <EnhancedAlert>
          These permissions apply only to{' '}
          <strong>
            {organizationName}
          </strong>
        </EnhancedAlert>
        )}
      </Collapse>

      {isDirector && (
      <>
        <Box m={2}>
          <Typography className="label">Overall</Typography>

          <FormControl className="editOtherUsers">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={mayEditPermissions}
                  classes={{
                    checked: 'checked',
                    colorSecondary: 'checkbox',
                  }}
                  color="primary"
                  onChange={onToggleEditPermissionsCheckbox}
                />
              )}
              label="Can edit permissions for other users?"
            />
          </FormControl>
        </Box>

        <Divider />
      </>
      )}

      <PermissionFormGroup
        actions={['write']}
        label="Email Members"
        onChange={onChangePermission}
        permissions={permissions?.emailMembers}
        selectedPermissionIds={permissionIds}
      />
      <Divider />

      <PermissionFormGroup
        label="File Uploads"
        onChange={onChangePermission}
        permissions={permissions?.s3Uploads}
        selectedPermissionIds={permissionIds}
      />
      <Divider />

      <PermissionFormGroup
        label="Finances"
        onChange={onChangePermission}
        permissions={permissions?.finances}
        selectedPermissionIds={permissionIds}
      />
      <Divider />

      <PermissionFormGroup
        label="Financial Accounts"
        onChange={onChangePermission}
        permissions={permissions?.financialAccounts}
        selectedPermissionIds={permissionIds}
      />
      <Divider />

      <PermissionFormGroup
        label="Groups"
        onChange={onChangePermission}
        permissions={permissions?.groups}
        selectedPermissionIds={permissionIds}
      />
      <Divider />

      <PermissionFormGroup
        actions={['write', 'read', 'delete']}
        label="Inventory (All Fields)"
        onChange={onChangePermission}
        permissions={permissions?.inventory}
        selectedPermissionIds={permissionIds}
      />

      <Box
        marginLeft={4}
        paddingBottom={2}
      >
        <PermissionFormGroup
          actions={['edit']}
          dense
          label="Basic Info"
          onChange={onChangePermission}
          permissions={permissions?.inventory}
          selectedPermissionIds={permissionIds}
        />

        <PermissionFormGroup
          actions={['edit']}
          dense
          label="Status Info"
          onChange={onChangePermission}
          permissions={permissions?.inventoryStatusInfo}
          selectedPermissionIds={permissionIds}
        />

        <PermissionFormGroup
          actions={['edit']}
          dense
          label="Location Info"
          onChange={onChangePermission}
          permissions={permissions?.inventoryLocationInfo}
          selectedPermissionIds={permissionIds}
        />

        <PermissionFormGroup
          actions={['edit']}
          dense
          label="Purchase Info"
          onChange={onChangePermission}
          permissions={permissions?.inventoryPurchaseInfo}
          selectedPermissionIds={permissionIds}
        />

        <PermissionFormGroup
          actions={['edit']}
          dense
          label="Dynamic Fields"
          onChange={onChangePermission}
          permissions={permissions?.inventoryDynamicFields}
          selectedPermissionIds={permissionIds}
        />
      </Box>

      <Divider />

      <PermissionFormGroup
        actions={['read', 'write', 'edit']}
        label="Inventory Checkouts"
        onChange={onChangePermission}
        permissions={permissions?.inventoryCheckouts}
        selectedPermissionIds={permissionIds}
      />
      <Divider />

      <PermissionFormGroup
        label="Library"
        onChange={onChangePermission}
        permissions={permissions?.library}
        selectedPermissionIds={permissionIds}
      />
      <Divider />

      <PermissionFormGroup
        actions={['read', 'write', 'edit']}
        label="Payments"
        onChange={onChangePermission}
        permissions={permissions?.payments}
        selectedPermissionIds={permissionIds}
      />
      <Divider />

      <PermissionFormGroup
        label="Uniform Checkouts"
        onChange={onChangePermission}
        permissions={permissions?.uniformCheckouts}
        selectedPermissionIds={permissionIds}
      />
      <Divider />

      <PermissionFormGroup
        label="Uniforms"
        onChange={onChangePermission}
        permissions={permissions?.uniforms}
        selectedPermissionIds={permissionIds}
      />
      <Divider />

      <PermissionFormGroup
        actions={['read', 'write', 'edit', 'delete']}
        label="Users"
        onChange={onChangePermission}
        permissions={permissions?.users}
        selectedPermissionIds={permissionIds}
      />
    </StyledSection>

    <DialogActions>
      <SaveButton
        isSaving={isLoading}
        onClick={onSave}
      >
        Save
      </SaveButton>
    </DialogActions>
  </>
);

export default PermissionEditPageUI;
