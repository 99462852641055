// External Dependencies
import { FC } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { useGetUniform } from 'gql/queries';
import { useUpdateUniform } from 'gql/mutations';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import { convertDollarsToCents } from '@presto-assistant/api_types/utils';
import UniformForm, { UniformFormValues } from '../shared/UniformForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Component Definition
const UniformEditPage: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const canUseDynamicFields = useCanUseDynamicFields();

  const {
    data,
    error,
    loading,
  } = useGetUniform(id!);

  const navigateToShow = () => {
    navigate(`/${PATHS.UNIFORMS}/${id}`);
  };

  const [handleUpdateUniform] = useUpdateUniform(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['uniformsIndex'],
      onCompleted: navigateToShow,
      refetchQueries: () => ['Uniform'],
    },
  );

  const handleSubmit = async (values: UniformFormValues) => {
    const updatedValues = {
      ...values,
      purchaseValueInCents: values.purchaseValueInCents
        ? convertDollarsToCents(Number(values.purchaseValueInCents))
        : undefined,
      uniformTypeId: values.uniformTypeId.toString(),
    };

    // The API won't like us sending in a value that doesn't exist in the model
    delete updatedValues.orgTypeId;

    await handleUpdateUniform({
      variables: {
        id: id!,
        input: {
          ...updatedValues,
          ...(canUseDynamicFields
            ? applyDynamicFields(updatedValues as unknown as DynamicFields)
            : {}),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'Uniform',
        path: `/${PATHS.UNIFORMS}/${id}`,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <UniformForm
        canUseDynamicFields={canUseDynamicFields}
        onSubmit={handleSubmit}
        title="Edit Uniform"
        uniform={data?.uniform}
      />
    </Page>
  );
};

export default UniformEditPage;
