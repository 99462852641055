// External Dependencies
import {
  Box,
  CardActions,
  CardContent,
  Container,
  TextField,
} from '@mui/material';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { FC, FormEvent, useState } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { ShowCardHeader } from 'components/shared';
import { addNotification } from 'state/notifications/actions';

// Local Dependencies
import {
  EnhancedCard,
  SaveButton,
} from '..';
import StripeInput from './StripeInput';

// Local Typings
interface Props {
  onSetPaymentMethod: (paymentMethod: PaymentMethod) => void;
}

// Component Definition
const StripePaymentForm: FC<Props> = ({
  onSetPaymentMethod,
}) => {
  const [isSubmittingCardDetails, setIsSubmittingCardDetails] = useState(false);

  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: FormEvent) => {
    setIsSubmittingCardDetails(true);

    event.preventDefault();

    const card = elements?.getElement(CardNumberElement);

    if (card == null) {
      return;
    }

    const paymentResult = await stripe?.createPaymentMethod({
      card,
      type: 'card',
    });

    if (paymentResult?.paymentMethod) {
      onSetPaymentMethod(paymentResult.paymentMethod);
    }

    if (paymentResult?.error) {
      dispatch(addNotification(paymentResult.error.message ?? '', 'error'));
    }

    setIsSubmittingCardDetails(false);
  };

  const isSubmitting = isSubmittingCardDetails;

  return (
    <Container maxWidth="sm">
      <EnhancedCard>
        <ShowCardHeader title="Payment Method" />

        <form onSubmit={handleSubmit}>
          <CardContent>
            <Box
              marginY={2}
            >
              <TextField
                label="Credit Card Number"
                name="ccnumber"
                variant="filled"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    autocomplete: 'cc-number',
                    component: CardNumberElement,
                  },
                }}
              />
            </Box>

            <Box
              display="flex"
              gap={2}
              marginY={2}
            >
              <TextField
                label="Expiration Date"
                name="ccexp"
                variant="filled"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    autocomplete: 'cc-exp',
                    component: CardExpiryElement,
                  },
                }}
              />

              <TextField
                label="CVC"
                name="cvc"
                variant="filled"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    autocomplete: 'cc-csc',
                    component: CardCvcElement,
                  },
                }}
              />
            </Box>
          </CardContent>

          <CardActions>
            <Box
              display="flex"
              justifyContent="flex-end"
              marginTop={2}
              width="100%"
            >
              <SaveButton
                color="primary"
                disabled={!stripe || !elements}
                isSaving={isSubmitting}
                type="submit"
                variant="contained"
              >
                Next
              </SaveButton>
            </Box>
          </CardActions>
        </form>
      </EnhancedCard>
    </Container>
  );
};

export default StripePaymentForm;
