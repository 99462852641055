// External Dependencies
import { FC } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { convertDollarsToCents } from '@presto-assistant/api_types/utils';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { useDistrictUpdateUniform } from 'gql/mutations';
import { useGetUniform } from 'gql/queries';
import UniformForm,
{
  AdminUniformFormValues,
  UniformFormValues,
} from 'pages/Uniforms/shared/UniformForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Local Variables
const navigateToShow = (id: string) => {
  navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}/${id}`);
};

// Component Definition
const AdminUniformsEdit: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const {
    data,
    error,
    loading,
  } = useGetUniform(id!);

  const [handleUpdateUniform] = useDistrictUpdateUniform(
    {
      clearCachePredicates: ['districtUniformItemsIndex', 'uniform'],
      onCompleted: () => navigateToShow(id!),
    },
  );

  const handleSubmit = async (
    values: UniformFormValues | AdminUniformFormValues,
  ) => {
    const updatedValues = {
      ...values,
      organizationTypeId: values.orgTypeId!,
      purchaseValueInCents: values.purchaseValueInCents
        ? convertDollarsToCents(Number(values.purchaseValueInCents))
        : undefined,
      uniformTypeId: values.uniformTypeId.toString(),
    };

    // The API won't like us sending in a value that doesn't exist in the model
    delete updatedValues.orgTypeId;

    await handleUpdateUniform({
      variables: {
        id: id!,
        input: {
          ...updatedValues,
          ...applyDynamicFields(updatedValues as unknown as DynamicFields),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'Uniform Detail',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}/${id}`,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <UniformForm
        canUseDynamicFields
        uniform={data?.uniform}
        isAdmin
        onSubmit={handleSubmit}
        title="Edit Uniform"
      />
    </Page>
  );
};

export default AdminUniformsEdit;
