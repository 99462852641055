// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { FRAGMENT_FINANCIAL_CREDIT_SHOW, FRAGMENT_FINANCIAL_PAYMENT_SHOW } from 'gql/queries';
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface AssignFinancialFundraiserCreditsResponse {
  id: string;
}

interface CreateFinancialItemResponse extends GQL.ICreateFinancialItemInput {
  id: string;
}

interface CreateFinancialFeeResponse {
  id: string;
}

interface CreateFinancialFundraiserCreditResponse {
  id: string;
}

interface AssignFinancialFundraiserCreditsData {
  assignFinancialFundraiserCredits: AssignFinancialFundraiserCreditsResponse;
}

interface CreateFinancialItemData {
  createFinancialItem: CreateFinancialItemResponse;
}

interface CreateFinancialFeeData {
  createFinancialFee: CreateFinancialFeeResponse;
}

interface CreateFinancialFundraiserCreditData {
  createFinancialFundraiserCredit: CreateFinancialFundraiserCreditResponse;
}

interface CreateManyFinancialFeesData {
  createManyFinancialFeesByFinancialItem: GQL.IFinancialFee[];
}

interface CreateStripePaymentData {
  createStripeFinancialPayment: GQL.IFinancialPayment;
}

interface CreateFinancialPaymentRefundData {
  createStripeFinancialPaymentRefund: GQL.IFinancialPayment;
}

interface UpdateFinancialItemResponse {
  id: string;
}

interface UpdateFinancialItemData {
  updateFinancialItem: UpdateFinancialItemResponse;
}

interface CreateFinancialPaymentResponse {
  financialPaymentType: GQL.IFinancialPaymentType;
  id: string;
}

interface CreateManyFinancialPaymentsData {
  createManyFinancialPayments: CreateFinancialPaymentResponse[];
}

interface CreateFinancialCreditResponse {
  createFinancialCredit: GQL.IFinancialCredit;
}

interface UpdateFinancialCreditResponse {
  updateFinancialCredit: GQL.IFinancialCredit;
}

interface CreateFinancialCreditTransferResponse {
  createFinancialCreditTransfer: boolean;
}

interface UpdateFinancialPaymentResponse {
  updateFinancialPayment: GQL.IFinancialPayment;
}

interface CreateFinancialDepositResponse {
  createFinancialDeposit: GQL.IFinancialDeposit;
}

interface UnwaiveFinancialFeeResponse {
  unwaiveFinancialFee: GQL.IFinancialFee;
}

// Local Variables
const ASSIGN_FINANCIAL_FUNDRAISER_CREDITS = gql`
  mutation AssignFinancialFundraiserCredits(
    $input: AssignFinancialFundraiserCreditsInput!
  ) {
    assignFinancialFundraiserCredits(
      input: $input
    ) {
      amountInCents
      financialAccount {
        id
        label
      }
      id
      label
      memberIdsAssigned
      schoolYearEnding
    }
  }
`;

export const CREATE_FINANCIAL_CREDIT = gql`
  mutation CreateFinancialCredit(
    $input: CreateFinancialCreditInput!
  ) {
    createFinancialCredit(
      input: $input
    ) {
      amountInCents
      createdBy {
        id
        email
      }
      id
      note
      user {
        id
        email
      }
    }
  }
`;

export const UPDATE_FINANCIAL_CREDIT = gql`
  mutation UpdateFinancialCredit (
    $id: ID!
    $input: UpdateFinancialCreditInput!
  ) {
    updateFinancialCredit(
      id: $id
      input: $input
    ) {
      ...FinancialCreditShowFragment
    }
  }
  ${FRAGMENT_FINANCIAL_CREDIT_SHOW}
`;

export const CLOSE_FINANCIAL_CREDIT = gql`
  mutation CloseFinancialCredit (
    $id: ID!
    $input: CloseFinancialCreditInput!
  ) {
    closeFinancialCredit(
      id: $id
      input: $input
    ) {
      ...FinancialCreditShowFragment
    }
  }
  ${FRAGMENT_FINANCIAL_CREDIT_SHOW}
`;

export const CREATE_FINANCIAL_CREDIT_TRANSFER = gql`
  mutation CreateFinancialCreditTransfer(
    $input: CreateFinancialCreditTransferInput!
  ) {
    createFinancialCreditTransfer(
      input: $input
    )
  }
`;

const CREATE_FINANCIAL_FUNDRAISER_CREDIT = gql`
  mutation CreateFinancialFundraiserCredit(
    $input: CreateFinancialFundraiserCreditInput!
  ) {
    createFinancialFundraiserCredit(
      input: $input
    ) {
      amountInCents
      financialAccount {
        id
        label
      }
      id
      label
      schoolYearEnding
    }
  }
`;

export const CREATE_FINANCIAL_ITEM = gql`
  mutation CreateFinancialItem(
    $input: CreateFinancialItemInput!
  ) {
    createFinancialItem(
      input: $input
    ) {
      createdBy {
        id
        firstName
        lastName
      }
      id
      isActive
      label
      priceInCents
      schoolYearEnding
    }
  }
`;

export const DELETE_FINANCIAL_ITEM = gql`
  mutation DeleteFinancialItem(
    $deletedNote: String!
    $id: ID!
  ) {
    deleteFinancialItem(
      deletedNote: $deletedNote
      id: $id
    )
  }
`;

export const UPDATE_FINANCIAL_ITEM = gql`
  mutation UpdateFinancialItem(
    $id: ID!
    $input: UpdateFinancialItemInput!
  ) {
    updateFinancialItem(
      id: $id
      input: $input
    ) {
      createdBy {
        id
        firstName
        lastName
      }
      id
      isActive
      label
      priceInCents
      schoolYearEnding
    }
  }
`;

export const CREATE_UNCATEGORIZED_FINANCIAL_FEE = gql`
  mutation CreateUncategorizedFinancialFee(
    $input: CreateUncategorizedFinancialFeeInput!
  ) {
    createUncategorizedFinancialFee(
      input: $input
    ) {
      createdBy {
        id
        firstName
        lastName
      }
      id
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

const CREATE_STRIPE_FINANCIAL_PAYMENT = gql`
  mutation CreateStripeFinancialPayment(
    $input: CreateStripeFinancialPaymentInput!
  ) {
    createStripeFinancialPayment(
      input: $input
    ) {
      id
    }
  }
`;

const CREATE_FINANCIAL_PAYMENT_REFUND = gql`
  mutation CreateFinancialPaymentRefund(
    $input: CreateFinancialPaymentRefundInput!
  ) {
    createFinancialPaymentRefund(
      input: $input
    ) {
      ...FinancialPaymentShowFragment
    }
  }
  ${FRAGMENT_FINANCIAL_PAYMENT_SHOW}
`;

const CREATE_FINANCIAL_ACCOUNT = gql`
  mutation CreateFinancialAccount(
    $input: CreateFinancialAccountInput!
  ) {
    createFinancialAccount(
      input: $input
    ) {
      id
      label
      onlinePaymentsEnabled
    }
  }
`;

const UPDATE_FINANCIAL_ACCOUNT = gql`
  mutation UpdateFinancialAccount(
    $id: ID!
    $input: UpdateFinancialAccountInput!
  ) {
    updateFinancialAccount(
      id: $id
      input: $input
    ) {
      id
      label
      onlinePaymentsEnabled
    }
  }
`;

export const DELETE_FINANCIAL_ACCOUNT = gql`
  mutation DeleteFinancialAccount(
    $deletedNote: String!
    $id: ID!
  ) {
    deleteFinancialAccount(
      deletedNote: $deletedNote
      id: $id
    )
  }
`;

export const CREATE_MANY_FINANCIAL_FEES_BY_FINANCIAL_ITEM_ID = gql`
  mutation CreateManyFinancialFeesByFinancialItem(
    $input: CreateManyFinancialFeesInput!
  ) {
    createManyFinancialFeesByFinancialItem(
      input: $input
    ) {
      createdBy {
        id
        firstName
        lastName
      }
      id
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const DELETE_FINANCIAL_FEE = gql`
  mutation DeleteFinancialFee (
    $deletedNote: String!
    $selection: Selection!
  ) {
    deleteFinancialFee(
      deletedNote: $deletedNote
      selection: $selection
    )
  }
`;

const DOWNLOAD_BALANCE_SHEET = gql`
  mutation DownloadBalanceSheet {
    downloadBalanceSheet
  }
`;

const DOWNLOAD_FINANCIAL_STATEMENTS = gql`
  mutation DownloadFinancialStatements(
    $input: DownloadFinancialStatementsInput!
    $withDownload: Boolean
  ) {
    downloadFinancialStatements(
      input: $input
      withDownload: $withDownload
    ) {
      base64
    }
  }
`;

const DOWNLOAD_MY_FINANCIAL_STATEMENTS = gql`
  mutation DownloadMyFinancialStatements(
    $input: DownloadFinancialStatementsInput!
    $withDownload: Boolean
  ) {
    downloadMyFinancialStatements(
      input: $input
      withDownload: $withDownload
    ) {
      base64
    }
  }
`;

const SEND_FINANCIAL_STATEMENTS = gql`
  mutation SendFinancialStatements(
    $input: SendFinancialStatementsInput!
  ) {
    sendFinancialStatements(
      input: $input
    )
  }
`;

export const CREATE_MANY_FINANCIAL_PAYMENTS = gql`
  mutation CreateManyFinancialPayments (
    $input: [CreateFinancialPaymentInput!]!
  ) {
    createManyFinancialPayments(
      input: $input
    ) {
      ...FinancialPaymentShowFragment
    }
  }
  ${FRAGMENT_FINANCIAL_PAYMENT_SHOW}
`;

export const UPDATE_FINANCIAL_PAYMENT = gql`
  mutation UpdateFinancialPayment (
    $id: ID!
    $input: UpdateFinancialPaymentInput!
  ) {
    updateFinancialPayment(
      id: $id
      input: $input
    ) {
      ...FinancialPaymentShowFragment
    }
  }
  ${FRAGMENT_FINANCIAL_PAYMENT_SHOW}
`;

export const CREATE_FINANCIAL_DEPOSIT = gql`
  mutation CreateFinancialDeposit (
    $input: CreateFinancialDepositInput!
  ) {
    createFinancialDeposit(
      input: $input
    ) {
      depositDate
      depositNumber
      id
      payments {
        amountInCents
        id
      }
    }
  }
`;

export const WAIVE_FINANCIAL_FEE = gql`
  mutation WaiveFinancialFee (
    $input: WaiveFinancialFeeInput!
  ) {
    waiveFinancialFee(
      input: $input
    ) {
      id
    }
  }
`;

const UNWAIVE_FINANCIAL_FEE = gql`
  mutation UnwaiveFinancialFee (
    $input: UnwaiveFinancialFeeInput!
  ) {
    unwaiveFinancialFee(
      input: $input
    ) {
      id
      waivedAt
      waivedBy {
        email
        firstName
        id
        lastName
      }
      waivedReason
    }
  }
`;

export const useAssignFinancialFundraiserCredits = (
  options?: MutationOptions<
    AssignFinancialFundraiserCreditsData,
    GQL.IAssignFinancialFundraiserCreditsOnMutationArguments
    >,
) => useMutationEnhanced<
  AssignFinancialFundraiserCreditsData,
  GQL.IAssignFinancialFundraiserCreditsOnMutationArguments
  >(
    ASSIGN_FINANCIAL_FUNDRAISER_CREDITS,
    {
      ...options,
      clearCachePredicates: [
        ...(options?.clearCachePredicates ?? []),
        'financialFundraiserCreditsIndex',
        'financialFees',
      ],
    },
  );

export const useCreateFinancialCredit = (
  options?: MutationOptions<
    CreateFinancialCreditResponse,
    GQL.ICreateFinancialCreditOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateFinancialCreditResponse,
  GQL.ICreateFinancialCreditOnMutationArguments
>(
  CREATE_FINANCIAL_CREDIT,
  options,
);

export const useUpdateFinancialCredit = (
  options?: MutationOptions<
    UpdateFinancialCreditResponse,
    GQL.IUpdateFinancialCreditOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateFinancialCreditResponse,
  GQL.IUpdateFinancialCreditOnMutationArguments
>(
  UPDATE_FINANCIAL_CREDIT,
  {
    ...options,
  },
);

export const useCreateFinancialCreditTransfer = (
  options?: MutationOptions<
    CreateFinancialCreditTransferResponse,
    GQL.ICreateFinancialCreditTransferOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateFinancialCreditTransferResponse,
  GQL.ICreateFinancialCreditTransferOnMutationArguments
>(
  CREATE_FINANCIAL_CREDIT_TRANSFER,
  options,
);

export const useCreateFinancialFundraiserCredit = (
  options?: MutationOptions<
    CreateFinancialFundraiserCreditData,
    GQL.ICreateFinancialFundraiserCreditOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateFinancialFundraiserCreditData,
  GQL.ICreateFinancialFundraiserCreditOnMutationArguments
>(
  CREATE_FINANCIAL_FUNDRAISER_CREDIT,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'financialFundraiserCreditsIndex',
    ],
  },
);

export const useCreateFinancialItem = (
  options?: MutationOptions<
    CreateFinancialItemData,
    GQL.ICreateFinancialItemOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateFinancialItemData,
  GQL.ICreateFinancialItemOnMutationArguments
>(
  CREATE_FINANCIAL_ITEM,
  options,
);

export const useUpdateFinancialItem = (
  options?: MutationOptions<
    UpdateFinancialItemData,
    GQL.IUpdateFinancialItemOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateFinancialItemData,
  GQL.IUpdateFinancialItemOnMutationArguments
>(
  UPDATE_FINANCIAL_ITEM,
  options,
);

export const useDownloadBalanceSheet = (
  options?: MutationOptions<
    { downloadBalanceSheet: boolean },
    { }
  >,
) =>
  useMutationEnhanced<
    { downloadBalanceSheet: boolean },
    { }
  >(
    DOWNLOAD_BALANCE_SHEET,
    options,
  );

export const useDownloadFinancialStatements = (
  options?: MutationOptions<
    { downloadFinancialStatements: GQL.IPDFContent },
    GQL.IDownloadFinancialStatementsOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { downloadFinancialStatements: GQL.IPDFContent },
    GQL.IDownloadFinancialStatementsOnMutationArguments
  >(
    DOWNLOAD_FINANCIAL_STATEMENTS,
    options,
  );

export const useDownloadMyFinancialStatements = (
  options?: MutationOptions<
    { downloadMyFinancialStatements: GQL.IPDFContent },
    GQL.IDownloadMyFinancialStatementsOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { downloadMyFinancialStatements: GQL.IPDFContent },
    GQL.IDownloadMyFinancialStatementsOnMutationArguments
  >(
    DOWNLOAD_MY_FINANCIAL_STATEMENTS,
    options,
  );

export const useSendFinancialStatements = (
  options?: MutationOptions<
    { sendFinancialStatements: boolean },
    GQL.ISendFinancialStatementsOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { sendFinancialStatements: boolean },
    GQL.ISendFinancialStatementsOnMutationArguments
  >(
    SEND_FINANCIAL_STATEMENTS,
    options,
  );

export const useCreateManyFinancialPayments = (
  options?: MutationOptions<
    CreateManyFinancialPaymentsData,
    GQL.ICreateManyFinancialPaymentsOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateManyFinancialPaymentsData,
  GQL.ICreateManyFinancialPaymentsOnMutationArguments
>(
  CREATE_MANY_FINANCIAL_PAYMENTS,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'financialPayments',
      'myFinancialPayments',
      'financialFee',
      'myFinancialFee',
      'financialCredit',
      'financialCreditsByUserId',
      'financialFeesOverview',
      'financialCreditOverview',
      'financialPaymentTotalsByDate',
      'financialTransactionReport',
    ],
  },
);

export const useUpdateFinancialPayment = (
  options?: MutationOptions<
    UpdateFinancialPaymentResponse,
    GQL.IUpdateFinancialPaymentOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateFinancialPaymentResponse,
  GQL.IUpdateFinancialPaymentOnMutationArguments
>(
  UPDATE_FINANCIAL_PAYMENT,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'financialPayments',
      'myFinancialPayments',
      'financialFee',
      'myFinancialFee',
      'financialCredit',
      'financialCreditsByUserId',
      'financialFeesOverview',
      'financialCreditOverview',
      'financialPaymentTotalsByDate',
      'financialTransactionReport',
    ],
  },
);

export const useCreateUncategorizedFinancialFee = (
  options?: MutationOptions<
    CreateFinancialFeeData,
    GQL.ICreateUncategorizedFinancialFeeOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateFinancialFeeData,
  GQL.ICreateUncategorizedFinancialFeeOnMutationArguments
>(
  CREATE_UNCATEGORIZED_FINANCIAL_FEE,
  options,
);

export const useCreateManyFinancialFees = (
  options?: MutationOptions<
    CreateManyFinancialFeesData,
    GQL.ICreateManyFinancialFeesByFinancialItemOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateManyFinancialFeesData,
  GQL.ICreateManyFinancialFeesByFinancialItemOnMutationArguments
>(
  CREATE_MANY_FINANCIAL_FEES_BY_FINANCIAL_ITEM_ID,
  options,
);

export const useCreateStripePayment = (
  options?: MutationOptions<
    CreateStripePaymentData,
    GQL.ICreateStripeFinancialPaymentOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateStripePaymentData,
  GQL.ICreateStripeFinancialPaymentOnMutationArguments
>(
  CREATE_STRIPE_FINANCIAL_PAYMENT,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'financialPayments',
      'myFinancialPayments',
      'financialFee',
      'myFinancialFee',
      'financialCredit',
      'financialCreditsByUserId',
      'financialFeesOverview',
      'financialCreditOverview',
      'financialPaymentTotalsByDate',
      'financialTransactionReport',
    ],
  },
);

export const useCreateFinancialPaymentRefund = (
  options?: MutationOptions<
    CreateFinancialPaymentRefundData,
    GQL.ICreateFinancialPaymentRefundOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateFinancialPaymentRefundData,
  GQL.ICreateFinancialPaymentRefundOnMutationArguments
>(
  CREATE_FINANCIAL_PAYMENT_REFUND,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'financialPayments',
      'myFinancialPayments',
      'financialFee',
      'myFinancialFee',
      'financialCredit',
      'financialCreditsByUserId',
      'financialFeesOverview',
      'financialCreditOverview',
      'financialPaymentTotalsByDate',
      'financialTransactionReport',
    ],
  },
);

export const useCreateFinancialAccount = (
  options?: MutationOptions<
    { createFinancialAccount: GQL.IFinancialAccount },
    GQL.ICreateFinancialAccountOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { createFinancialAccount: GQL.IFinancialAccount },
    GQL.ICreateFinancialAccountOnMutationArguments
  >(CREATE_FINANCIAL_ACCOUNT, {
    ...options,
    clearCachePredicates: ['financialAccount', ...(options?.clearCachePredicates ?? [])],
  });

export const useUpdateFinancialAccount = (
  options?: MutationOptions<
    { updateFinancialAccount: GQL.IFinancialAccount },
    GQL.IUpdateFinancialAccountOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    { updateFinancialAccount: GQL.IFinancialAccount },
    GQL.IUpdateFinancialAccountOnMutationArguments
  >(UPDATE_FINANCIAL_ACCOUNT, {
    ...options,
    clearCachePredicates: ['financialAccount', ...(options?.clearCachePredicates ?? [])],
  });

export const useCreateFinancialDeposit = (
  options?: MutationOptions<
    CreateFinancialDepositResponse,
    GQL.ICreateFinancialDepositOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateFinancialDepositResponse,
  GQL.ICreateFinancialDepositOnMutationArguments
>(
  CREATE_FINANCIAL_DEPOSIT,
  options,
);

export const useUnwaiveFinancialFee = (
  options?: MutationOptions<
    UnwaiveFinancialFeeResponse,
    GQL.IUnwaiveFinancialFeeOnMutationArguments
  >,
) => useMutationEnhanced<
  UnwaiveFinancialFeeResponse,
  GQL.IUnwaiveFinancialFeeOnMutationArguments
>(
  UNWAIVE_FINANCIAL_FEE,
  {
    ...options,
    clearCachePredicates: [
      ...options?.clearCachePredicates ?? [],
      'financialFee',
    ],
  },
);
