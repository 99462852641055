// External Dependencies
import { FC } from 'react';
import Typography from '@mui/material/Typography';

// Local Typings
interface Props {
  formBlock: GQL.IMyFormBlock;
}

// Component Definition
const ParagraphBlock: FC<Props> = ({
  formBlock,
}: Props) => {
  const { content } = formBlock.metadata as { content: string };

  return (
    <Typography variant="body1">
      {content}
    </Typography>
  );
};

export default ParagraphBlock;
