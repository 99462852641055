// External Dependencies
import {
  CircularProgress,
  List,
  Paper,
  Typography,
} from '@mui/material';
import { FC, useCallback } from 'react';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import ViewIcon from '@mui/icons-material/ArrowForward';

// Internal Dependencies
import {
  DashboardCard,
  EnhancedAlert,
  ListItemWithSecondaryAction,
  SubscriberAddButton,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { useGetFinancialAccounts } from 'gql/queries';
import { useIsOnlinePaymentsAvailable } from 'hooks/useCanMakePayments';

// Component Definition
const FinancialAccountsCard: FC = () => {
  const canWriteAccounts = useSelector(hasPermission('financialAccounts', 'write'));

  const isOnlinePaymentsAvailable = useIsOnlinePaymentsAvailable();

  const {
    data,
    loading,
  } = useGetFinancialAccounts();

  const handleNavigateToNewAccount = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_ACCOUNTS_NEW}`);
  }, []);

  const handleClickAccount = useCallback((accountId: string) => () => {
    navigate(`/${PATHS.FINANCIAL_ACCOUNTS}/${accountId}`);
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <DashboardCard
      cardActions={canWriteAccounts ? (
        <SubscriberAddButton
          label="Financial Account"
          onClick={handleNavigateToNewAccount}
        />
      ) : undefined}
      content={(
        <>
          {data?.financialAccounts.length ? (
            <Paper
              sx={{ mb: 2 }}
              variant="outlined"
            >
              <List>
                {data?.financialAccounts.map((account) => (
                  <ListItemWithSecondaryAction
                    key={account.id}
                    primaryText={account.label}
                    secondaryAction={{
                      buttonIcon: <ViewIcon />,
                      buttonText: 'View',
                      onClick: handleClickAccount(account.id),
                    }}
                    secondaryText={isOnlinePaymentsAvailable && (
                      <>
                        Online payments are
                        {' '}
                        <strong>
                          {account.onlinePaymentsEnabled ? 'enabled' : 'disabled'}
                        </strong>
                      </>
                    )}
                  />
                ))}
              </List>
            </Paper>
          ) : (
            <Typography
              color="textSecondary"
              gutterBottom={!canWriteAccounts}
              variant="body2"
            >
              No financial accounts for this organization.
            </Typography>
          )}

          {!canWriteAccounts && (
            <Typography
              color="textSecondary"
              variant="body2"
            >
              You do not have permission to add financial accounts.
              {' '}
              Please contact your district administrator.
            </Typography>
          )}

          <EnhancedAlert sx={{ mt: 2 }}>
            Financial accounts categorize fees in your organization.
            {' '}
            After setting up your ONE Stripe integration for your organization,
            {' '}
            a Financial Account must be present to accept online payments from parents.
          </EnhancedAlert>
        </>
      )}
      title="Financial Accounts"
    />
  );
};

export default FinancialAccountsCard;
