// External Dependencies
import { Divider, List } from '@mui/material';
import { FC } from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';
import AccountGroupIcon from 'mdi-material-ui/AccountGroup';
import AccountTieIcon from 'mdi-material-ui/AccountTie';
import CalendarIcon from 'mdi-material-ui/CalendarMultiselect';
import CardAccountDetailsIcon from 'mdi-material-ui/CardAccountDetails';
import CartIcon from 'mdi-material-ui/Cart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EmailIcon from '@mui/icons-material/Email';
import FolderOpen from '@mui/icons-material/FolderOpen';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from 'mdi-material-ui/Receipt';
import TableChartIcon from '@mui/icons-material/TableChart';
import TieIcon from 'mdi-material-ui/Tie';
import WidgetsIcon from '@mui/icons-material/Widgets';

// Internal Dependencies
import {
  NavMenuItem,
  Organization,
  PriceChangeSvg,
} from 'components/shared';
import {
  PATHS,
  TITLES,
} from 'utils/constants/routes';
import {
  hasFeatureFlag,
  hasPermission,
  isStudent,
} from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import SideNavSubtitle from './SideNavSubtitle';

// Local Typings
interface Props {
  onLinkClick?: () => void;
}

// Local Components
const PrinceChangeIcon = () => <PriceChangeSvg />;

// Component Definition
const StudentParentLinks: FC<Props> = ({ onLinkClick }) => {
  const schoolYearEnding = useGetOrganizationSchoolYear();

  const isStudentMember = useSelector(isStudent);

  const directorParams = useSelector(tableQueryParams('directors'));
  const emailParams = useSelector(tableQueryParams('emails'));
  const financialCreditsParams = useSelector(tableQueryParams('financialCredits'));
  const financialFeesParams = useSelector(tableQueryParams(`financialFees-${schoolYearEnding}`));
  const financialItemsParams = useSelector(tableQueryParams(`financialItems-${schoolYearEnding}`));
  const financialPaymentsParams = useSelector(tableQueryParams(`financialPayments-${schoolYearEnding}`));
  const financialTransactionsParams = useSelector(tableQueryParams('financialTransactions'));
  const groupParams = useSelector(tableQueryParams('groups'));
  const inventoryItemsParams = useSelector(tableQueryParams('inventoryItems'));
  const libraryItemsParams = useSelector(tableQueryParams('libraryItems'));
  const myCreditsParams = useSelector(tableQueryParams('myFinancialCredits'));
  const myFeesParams = useSelector(tableQueryParams('myFinancialFees'));
  const myFilesParams = useSelector(tableQueryParams('myFiles'));
  const myPaymentsParams = useSelector(tableQueryParams('myFinancialPayments'));
  const parentParams = useSelector(tableQueryParams('parents'));
  const studentParams = useSelector(tableQueryParams('students'));
  const uniformParams = useSelector(tableQueryParams('uniforms'));

  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canReadGroups = useSelector(hasPermission('groups', 'read'));
  const canReadInventory = useSelector(hasPermission('inventory', 'read'));
  const canReadLibrary = useSelector(hasPermission('library', 'read'));
  const canReadPayments = useSelector(hasPermission('payments', 'read'));
  const canReadUniforms = useSelector(hasPermission('uniforms', 'read'));
  const canReadUsers = useSelector(hasPermission('users', 'read'));

  const showInventorySubtitle = canReadInventory || canReadLibrary || canReadUniforms;

  const hasFinancesFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.Finances));

  const { self } = useSelfQuery();

  if (!self) {
    return null;
  }

  return (
    <List role="menu">
      <NavMenuItem
        icon={HomeIcon}
        onClick={onLinkClick}
        text={TITLES[PATHS.DASHBOARD]}
        to={`/${PATHS.DASHBOARD}`}
      />

      <NavMenuItem
        icon={AccountTieIcon}
        match={PATHS.DIRECTORS}
        onClick={onLinkClick}
        text={TITLES[PATHS.DIRECTORS]}
        to={`/${PATHS.DIRECTORS}${directorParams}`}
      />
      {self.currentOrgId && (
        <Organization>
          {({ organization }) => {
            const hasGoogleCalSync = !!organization.hasGoogleCalendar;

            return (
              hasGoogleCalSync && (
                <NavMenuItem
                  icon={CalendarIcon}
                  onClick={onLinkClick}
                  text={TITLES[PATHS.CALENDAR]}
                  to={`/${PATHS.CALENDAR}`}
                />
              )
            );
          }}
        </Organization>
      )}

      <NavMenuItem
        icon={EmailIcon}
        match={PATHS.COMMUNICATION}
        onClick={onLinkClick}
        text={TITLES[PATHS.COMMUNICATION]}
        to={`/${PATHS.COMMUNICATION}${emailParams}`}
      />

      <NavMenuItem
        icon={FolderOpen}
        match={PATHS.MY_FILES}
        onClick={onLinkClick}
        text={TITLES[PATHS.MY_FILES]}
        to={`/${PATHS.MY_FILES}${myFilesParams}`}
      />

      {showInventorySubtitle && (
        <>
          <Divider />
          <SideNavSubtitle subtitleText={TITLES[PATHS.INVENTORY]} />
        </>
      )}

      {canReadInventory && (
        <NavMenuItem
          icon={WidgetsIcon}
          match={PATHS.INVENTORY}
          onClick={onLinkClick}
          text={TITLES[PATHS.INVENTORY]}
          to={`/${PATHS.INVENTORY}${inventoryItemsParams}`}
        />
      )}

      {canReadLibrary && (
        <NavMenuItem
          icon={LibraryBooksIcon}
          match={PATHS.LIBRARY}
          onClick={onLinkClick}
          text={TITLES[PATHS.LIBRARY]}
          to={`/${PATHS.LIBRARY}${libraryItemsParams}`}
        />
      )}

      {canReadUniforms && (
        <NavMenuItem
          icon={TieIcon}
          match={PATHS.UNIFORMS}
          onClick={onLinkClick}
          text={TITLES[PATHS.UNIFORMS]}
          to={`/${PATHS.UNIFORMS}${uniformParams}`}
        />
      )}

      {isStudentMember && (
        <>
          <NavMenuItem
            icon={WidgetsIcon}
            match={PATHS.STUDENT_INVENTORY}
            onClick={onLinkClick}
            text={TITLES[PATHS.STUDENT_INVENTORY]}
            to={`/${PATHS.STUDENT_INVENTORY}`}
          />
          <NavMenuItem
            icon={TieIcon}
            match={PATHS.STUDENT_UNIFORMS}
            onClick={onLinkClick}
            text={TITLES[PATHS.STUDENT_UNIFORMS]}
            to={`/${PATHS.STUDENT_UNIFORMS}`}
          />
        </>
      )}

      {(canReadGroups || canReadUsers) && (
        <>
          <Divider />

          <SideNavSubtitle subtitleText={TITLES[PATHS.PEOPLE]} />
          {canReadGroups && (
            <NavMenuItem
              icon={AccountGroupIcon}
              match={PATHS.GROUPS}
              onClick={onLinkClick}
              text={TITLES[PATHS.GROUPS]}
              to={`/${PATHS.GROUPS}${groupParams}`}
            />
          )}

          {canReadUsers && (
            <NavMenuItem
              icon={CardAccountDetailsIcon}
              match={PATHS.STUDENTS}
              onClick={onLinkClick}
              text={TITLES[PATHS.STUDENTS]}
              to={`/${PATHS.STUDENTS}${studentParams}`}
            />
          )}

          {canReadUsers && (
            <NavMenuItem
              icon={PeopleIcon}
              match={PATHS.PARENTS}
              onClick={onLinkClick}
              text={TITLES[PATHS.PARENTS]}
              to={`/${PATHS.PARENTS}${parentParams}`}
            />
          )}
        </>
      )}

      {hasFinancesFeatureFlag && (
        <>
          <Divider />

          <SideNavSubtitle subtitleText={TITLES[PATHS.FINANCES]} />

          {canReadFinances && canReadPayments && (
            <NavMenuItem
              icon={DashboardIcon}
              match={PATHS.FINANCIAL_OVERVIEW}
              onClick={onLinkClick}
              text={TITLES[PATHS.FINANCIAL_OVERVIEW]}
              to={`/${PATHS.FINANCIAL_OVERVIEW}`}
            />
          )}

          {canReadFinances && (
            <NavMenuItem
              icon={ReceiptIcon}
              match={PATHS.FINANCIAL_ITEMS}
              onClick={onLinkClick}
              text={TITLES[PATHS.FINANCIAL_ITEMS]}
              to={`/${PATHS.FINANCIAL_ITEMS}${financialItemsParams}`}
            />
          )}

          {canReadFinances ? (
            <NavMenuItem
              icon={CartIcon}
              match={PATHS.FINANCIAL_FEES}
              onClick={onLinkClick}
              text={TITLES[PATHS.FINANCIAL_FEES]}
              to={`/${PATHS.FINANCIAL_FEES}${financialFeesParams}`}
            />
          ) : (
            <NavMenuItem
              icon={CartIcon}
              match={PATHS.STUDENT_FEES}
              onClick={onLinkClick}
              text={TITLES[PATHS.STUDENT_FEES]}
              to={`/${PATHS.STUDENT_FEES}${myFeesParams}`}
            />
          )}

          {canReadPayments ? (
            <NavMenuItem
              icon={LocalAtmIcon}
              match={PATHS.FINANCIAL_PAYMENTS}
              onClick={onLinkClick}
              text={TITLES[PATHS.FINANCIAL_PAYMENTS]}
              to={`/${PATHS.FINANCIAL_PAYMENTS}${financialPaymentsParams}`}
            />
          ) : (
            <NavMenuItem
              icon={LocalAtmIcon}
              match={PATHS.STUDENT_PAYMENTS}
              onClick={onLinkClick}
              text={TITLES[PATHS.STUDENT_PAYMENTS]}
              to={`/${PATHS.STUDENT_PAYMENTS}${myPaymentsParams}`}
            />
          )}

          {canReadPayments ? (
            <NavMenuItem
              icon={PrinceChangeIcon}
              match={PATHS.FINANCIAL_CREDITS}
              onClick={onLinkClick}
              text={TITLES[PATHS.FINANCIAL_CREDITS]}
              to={`/${PATHS.FINANCIAL_CREDITS}${financialCreditsParams}`}
            />
          ) : (
            <NavMenuItem
              icon={PrinceChangeIcon}
              match={PATHS.STUDENT_CREDITS}
              onClick={onLinkClick}
              text={TITLES[PATHS.STUDENT_CREDITS]}
              to={`/${PATHS.STUDENT_CREDITS}${myCreditsParams}`}
            />
          )}

          {canReadPayments && (
            <NavMenuItem
              icon={TableChartIcon}
              match={PATHS.FINANCIAL_TRANSACTIONS}
              onClick={onLinkClick}
              text={TITLES[PATHS.FINANCIAL_TRANSACTIONS]}
              to={`/${PATHS.FINANCIAL_TRANSACTIONS}${financialTransactionsParams}`}
            />
          )}
        </>
      )}

    </List>
  );
};

export default StudentParentLinks;
