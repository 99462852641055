// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';

// Internal Dependencies
import { ConfirmationDialog, TableDataGrid } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { createDataGridActionsColumn, displayCell } from 'components/shared/TableV2';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useDiscardEmailDraft } from 'gql/mutations';
import { useGetEmailDraftsIndex } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Variables
const handleTableClickRow = (id: string) => `/${PATHS.EMAIL_DRAFTS}/${id}`;

// Component Definition
const EmailDraftsTable: FC = () => {
  const [draftToDiscardId, setDraftToDiscardId] = useState<string | null>(null);

  const dispatch = useDispatch();

  const [discardEmailDraft] = useDiscardEmailDraft({
    onCompleted: () => {
      setDraftToDiscardId(null);

      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    },
  });

  const handleDiscard = useCallback(() => {
    if (draftToDiscardId) {
      discardEmailDraft({
        variables: {
          id: draftToDiscardId,
        },
      });
    }
  }, [discardEmailDraft, draftToDiscardId]);

  const handleClose = useCallback(() => {
    setDraftToDiscardId(null);
  }, []);

  const handleClickDiscard = useCallback((row: GQL.IEmailIndexItem) => {
    setDraftToDiscardId(row.id);
  }, []);

  const extraColumns = useMemo<GridColDef[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<GQL.IEmailIndexItem>([
        {
          action: handleClickDiscard,
          icon: <DeleteIcon />,
          text: 'Discard Draft',
        },
      ]);

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      handleClickDiscard,
    ],
  );

  const columns = useMemo<GridColDef<GQL.IEmailIndexItem>[]>(() => [
    {
      field: 'createdAt',
      headerName: 'Started',
      type: 'dateTime',
      valueFormatter: ({ value }) => displayCell(value, { format: 'datetime' }),
      valueGetter: ({ row }) => new Date(Number(row.createdAt)),
    },
    {
      field: 'subject',
      headerName: 'Subject',
      valueFormatter: ({ value }) => displayCell(value),
    },
    {
      field: 'attachmentCount',
      headerName: 'Attachments',
      type: 'number',
    },
    {
      field: 'recipientCount',
      headerName: 'Recipients',
      type: 'number',
    },
    ...extraColumns,
  ], [extraColumns]);

  const {
    data,
    isLoading,
  } = useGetEmailDraftsIndex();

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={{
            label: 'Email',
            to: `/${PATHS.EMAIL_NEW}`,
          }}
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          loading={isLoading}
          tableResource="emailDrafts"
          rows={data}
          withSearch
        />
      </DataGridContainer>

      <ConfirmationDialog
        confirmButtonText="Yes, discard"
        confirmButtonAction={handleDiscard}
        declineButtonAction={handleClose}
        description={"Are you sure you want to discard this draft? You won't be able to recover it."}
        handleClose={handleClose}
        open={Boolean(draftToDiscardId)}
        title="Discard Draft"
      />
    </>
  );
};

export default EmailDraftsTable;
