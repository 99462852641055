// External Dependencies
import { Button } from '@mui/material';
import { FC } from 'react';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { pluralize } from 'utils';
import {
  useGetEmailDraftsIndex,
  useGetEmailScheduledIndex,
} from 'gql/queries';

// Local Dependencies
import EmailTable from './EmailTable';
import SuppressedEmailAlert from './shared/SuppressedEmailAlert';

// Local Variables
const handleClickViewDrafts = () => {
  navigate(`/${PATHS.EMAIL_DRAFTS}`);
};
const handleClickViewScheduledEmails = () => {
  navigate(`/${PATHS.EMAIL_SCHEDULED}`);
};

// Component Definition
const Communication: FC = () => {
  const canReadUsers = useSelector(hasPermission('users', 'read'));

  const { fullCount: draftCount } = useGetEmailDraftsIndex();
  const { fullCount: scheduledSendCount } = useGetEmailScheduledIndex();

  return (
    <>
      {canReadUsers && <SuppressedEmailAlert />}

      {draftCount > 0 && (
        <EnhancedAlert
          action={(
            <Button
              key="view-drafts"
              onClick={handleClickViewDrafts}
            >
              View Drafts
            </Button>
          )}
          sx={{ marginBottom: 2 }}
        >
          You have {draftCount} {pluralize(draftCount, 'draft')} that have not been sent.
        </EnhancedAlert>
      )}

      {scheduledSendCount > 0 && (
        <EnhancedAlert
          action={(
            <Button
              key="view-scheduled-emails"
              onClick={handleClickViewScheduledEmails}
            >
              View Scheduled Emails
            </Button>
          )}
          sx={{ marginBottom: 2 }}
        >
          You have {scheduledSendCount} {pluralize(scheduledSendCount, 'email that is', 'emails that are')} scheduled to be sent.
        </EnhancedAlert>
      )}

      <Subtitle>
        Email
      </Subtitle>

      <EnhancedCard>
        <EmailTable />
      </EnhancedCard>
    </>
  );
};

export default Communication;
