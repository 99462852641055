// External Dependencies
import {
  Button,
  ButtonProps,
} from '@mui/material';
import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';

// Local Typings
interface Props extends ButtonProps {
  disabled?: boolean;
  label: string;
  marginRight?: number;
}

// Component Definition
const AddButton: FC<Props> = ({
  label,
  ...otherProps
}) => (
  <Button
    aria-label={`Add ${label}`}
    color="primary"
    size="small"
    startIcon={<AddIcon fontSize="small" />}
    variant="outlined"
    {...otherProps}
  >
    {label}
  </Button>
);

export default AddButton;
