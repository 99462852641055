// External Dependencies
import { FC, useMemo } from 'react';
import { SelectProps } from '@mui/material';

// Internal Dependencies
import { useGetOrganizationSchoolYearOptions } from 'gql/queries';

// Local Dependencies
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import CustomSelect from '../CustomSelect';

// Local Typings
interface BaseProps {
  includeAllYearsOption?: boolean;
  organizationId?: string;
}
interface PreviousYearProps extends BaseProps {
  hidePreviousYears?: never;
  previousYearsOnly?: boolean;
}
interface ExcludePreviousYearProps extends BaseProps {
  hidePreviousYears?: boolean;
  previousYearsOnly?: never;
}
interface AllYearProps extends BaseProps {
  hidePreviousYears?: never;
  previousYearsOnly?: never;
}
type Props = PreviousYearProps | ExcludePreviousYearProps | AllYearProps;
type PartialSelectProps = Omit<SelectProps, 'onChange' | 'value' | 'label' | 'options'>;

// Component Definition
const SchoolYearSelect: FC<Props & PartialSelectProps> = ({
  hidePreviousYears,
  includeAllYearsOption,
  organizationId,
  previousYearsOnly,
  ...props
}) => {
  const currentSchoolYear = useGetOrganizationSchoolYear();

  const { data } = useGetOrganizationSchoolYearOptions({
    includeAllYearsOption,
    organizationId,
  });

  const options = useMemo(() => {
    if (!data) {
      return [];
    }

    if (previousYearsOnly) {
      return data.organizationSchoolYearSelectOptions
        .filter((option) => option.id < currentSchoolYear);
    }

    if (hidePreviousYears) {
      return data.organizationSchoolYearSelectOptions
        .filter((option) => option.id >= currentSchoolYear);
    }

    return data.organizationSchoolYearSelectOptions;
  }, [currentSchoolYear, data, hidePreviousYears, previousYearsOnly]);

  return (
    <CustomSelect
      label="School Year"
      name="schoolYearEnding"
      options={options}
      {...props}
    />
  );
};

export default SchoolYearSelect;
