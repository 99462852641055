// External Dependencies
import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { convertCentsToDollars } from '@presto-assistant/api_types/utils';

// Internal Dependencies
import {
  EnhancedCard,
  Flex,
  Subtitle,
} from 'components/shared';
import { displayPriceStringFromDollarAmount } from 'utils';

// Local Dependencies
import FinancialTransactionsTable from './FinancialTransactionsTable';

// Component Definition
const FinancialTransactions: FC = () => {
  const [totalInCents, setTotalInCents] = useState(0);

  const totalPriceString = displayPriceStringFromDollarAmount(
    convertCentsToDollars(totalInCents),
  );

  return (
    <>
      <Flex justifyContent="space-between">
        <Subtitle>
          Financial Transactions
        </Subtitle>

        <Subtitle sx={{ marginRight: 2 }}>
          Filtered Total: <strong>{totalPriceString}</strong>
        </Subtitle>
      </Flex>

      <Box mb={4}>
        <EnhancedCard>
          <FinancialTransactionsTable onChangeTotalInCents={setTotalInCents} />
        </EnhancedCard>
      </Box>
    </>
  );
};

export default FinancialTransactions;
