// External Dependencies
import { Box, CircularProgress, Typography } from '@mui/material';
import { FC, useCallback, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';

// Internal Dependencies
import { logout } from 'utils/api';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Component Definition
const Logout: FC<RouteComponentProps> = () => {
  const search = useParsedSearch();

  const { redirectUrl } = search;

  const handleLogout = useCallback(async () => {
    await logout();

    // A hard refresh helps to ensure we clear all state
    window.location.href = `${window.location.origin}${redirectUrl || '/'}`;
  }, [redirectUrl]);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="80vh"
      flexDirection="column"
      gap={4}
    >
      <Typography variant="h6">
        Logging out...
      </Typography>

      <CircularProgress />
    </Box>
  );
};

export default Logout;
