// External Dependencies
import { Box, Collapse, Divider } from '@mui/material';
import { FC, useState } from 'react';

// Internal Dependencies
import { SimpleUserWithRole } from 'gql/queries/user-queries';
import { SubscriberAddButton } from 'components/shared';
import { UserTypeAhead } from 'components/shared/TypeAheads';
import { useCreateRelationshipByMemberWithPermission } from 'gql/mutations/relationship-mutations';
import { useIsOpen } from 'hooks/useIsOpen';
import RelationshipType, { RelationshipTypeFormValues } from 'pages/Relationships/New/Steps/RelationshipType';

// Local Typings
interface Props {
  memberRoleId: GQL.IUser['role']['id'];
  userId: string;
}

// Component Definition
const RelationshipCreateForm: FC<Props> = ({
  memberRoleId,
  userId,
}) => {
  const [recipient, setRecipient] = useState<SimpleUserWithRole | null>(null);

  const {
    isOpen: isFormOpen,
    toggleIsOpen: toggleIsFormOpen,
  } = useIsOpen();

  const handleClear = () => {
    toggleIsFormOpen();
    setRecipient(null);
  };

  const [
    createRelationship,
    {
      loading: isSubmitting,
    },
  ] = useCreateRelationshipByMemberWithPermission({
    onCompleted: handleClear,
  });

  const handleSubmit = (values: RelationshipTypeFormValues) => {
    if (recipient) {
      createRelationship({
        variables: {
          input: {
            recipientId: recipient.id,
            recipientRelationshipTypeId: values.recipientRelationshipTypeId,
            senderId: userId,
          },
        },
      });
    }
  };

  return isFormOpen ? (
    <>
      <Box mb={2}>
        <Divider />
      </Box>

      <Box mb={1}>
        <UserTypeAhead
          onSelectUser={setRecipient}
        />
      </Box>

      <Collapse in={Boolean(recipient)}>
        <RelationshipType
          cancelText="Cancel"
          isSubmitting={isSubmitting}
          memberRoleId={memberRoleId}
          onBack={handleClear}
          onCompleted={handleSubmit}
          relationRoleId={recipient?.role.id}
        />
      </Collapse>
    </>
  ) : (
    <Box
      display="flex"
      justifyContent="flex-end"
      mt={2}
    >
      <SubscriberAddButton
        label="Relationship"
        onClick={toggleIsFormOpen}
      />
    </Box>
  );
};

export default RelationshipCreateForm;
