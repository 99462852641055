// External Dependencies
import { AxiosResponse } from 'axios';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { navigate } from '@reach/router';
import { useEffect, useState } from 'react';

// Internal Dependencies
import {
  EnhancedAlert,
  Form,
  Input,
} from 'components/shared';
import { isPasswordValid } from 'utils/lib/validate_password';
import { parseSearch } from 'utils';
import { resetPassword } from 'utils/api';
import PasswordField from 'components/shared/PasswordField';
import PasswordStrengthGuide from 'components/shared/PasswordField/PasswordStrengthGuide';
import SaveButton from 'components/shared/SaveButton';
import useTextField from 'hooks/useTextField';

// Local Variables
const useStyles = makeStyles({
  input: {
    margin: '8px 0',
  },
});

const errorMessage = 'There was an error while resetting your password. Please try again.';

// Component Definition
const PasswordResetForm = () => {
  const classes = useStyles();

  const [userToken, setUserToken] = useState(null);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const emailField = useTextField('');
  const passwordField = useTextField('');
  const confirmPasswordField = useTextField('');
  const passwordErrors = isPasswordValid(passwordField.value);

  const passwordsMatch = passwordField.value === confirmPasswordField.value;
  const formErrors = !passwordErrors.isValid || !passwordsMatch;

  useEffect(() => {
    const { search } = window.location;
    const parsedSearch = parseSearch(search);
    const { ssoToken } = parsedSearch;
    setUserToken(ssoToken);
  }, []);

  const handleSubmit = async () => {
    if (formErrors) {
      return;
    }

    setIsSubmitting(true);

    try {
      const payload = {
        confirmPassword: confirmPasswordField.value,
        email: emailField.value,
        password: passwordField.value,
        token: userToken,
      };

      const response: AxiosResponse = await resetPassword(payload);

      setIsSubmitting(false);

      if (response.status === 200) {
        navigate('/');
      } else if (response.status > 400) {
        setError(response.data.error ?? errorMessage);
        console.error('error in resetPassword response : try', response.data);
      }
    } catch (err) {
      setError(errorMessage);
      setIsSubmitting(false);
      console.error('error in resetPassword response : catch', err);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Box
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        m={3}
        mb={1}
      >
        <Input
          {...emailField}
          className={classes.input}
          fullWidth
          label="Email"
          variant="filled"
        />
        <PasswordField
          {...passwordField}
          className={classes.input}
          displayStrengthGuide={false}
          fullWidth
          label="Password"
          variant="filled"
        />
        <Input
          {...confirmPasswordField}
          className={classes.input}
          error={!passwordsMatch}
          errorMessage="Passwords do not match"
          fullWidth
          label="Confirm Password"
          type="password"
          variant="filled"
        />

        <Box my={2}>
          <PasswordStrengthGuide strengthGuide={passwordErrors.strengthGuide} />
        </Box>

        {error && (
          <Box
            mt={2}
            mb={3}
          >
            <EnhancedAlert severity="error">
              {error}
            </EnhancedAlert>
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="center"
        >
          <SaveButton
            disabled={formErrors}
            isSaving={isSubmitting}
          >
            Update Password
          </SaveButton>
        </Box>
      </Box>
    </Form>
  );
};

export default PasswordResetForm;
