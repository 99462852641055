// External Dependencies
import {
  Button,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from '@mui/material';
import { Component, ErrorInfo, ReactElement } from 'react';
import SendIcon from '@mui/icons-material/Send';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
} from 'components/shared';
import { formatDateTime } from 'utils/lib/date_helpers';
import { getGradientBackground } from 'utils/lib/getGradientBackground';

// Local Dependencies
import { SHORT_APP_NAME } from 'utils/constants';
import Footer from './Footer';
import SkewedBackground from './SkewedBackground';

// Local Variables
const StyledContainer = styled(Container)(({ theme }) => ({
  '.MuiCardHeader-root': {
    margin: 0,
    padding: 0,
  },
  '.MuiCardHeader-title': {
    background: getGradientBackground(theme),
    color: theme.palette.mode === 'dark'
      ? theme.palette.common.black : theme.palette.common.white,
    padding: theme.spacing(2.5, 2),
    textTransform: 'uppercase',
  },
  '.emailLink': {
    marginTop: 16,
  },
  '.errorContent': {
    color: theme.palette.error.main,
    fontSize: '1rem',
  },
  '.errorMessage': {
    fontWeight: 600,
  },
  '.heading': {
    margin: '48px 0',
  },
  display: 'flex',
  flexDirection: 'column',
  main: {
    zIndex: 2,
  },
}));

// Local Typings
interface Props {
  children: ReactElement;
}
interface State {
  error: Error;
  hasError: boolean;
}

// Component Definition
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: {
        message: '',
        name: '',
        stack: '',
      },
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    console.log('ErrorBoundary : error', error);
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { error, hasError } = this.state;

    if (hasError) {
      const emailHref = `
        mailto:support@presto-assistant.com?subject=We%20uncovered%20an%20error%20in%20Presto%20Assistant%20&body=This%20error%20happened%20on%20${formatDateTime(new Date())}%20at%20${window.location.href}%20%0D%0A%0D%0A%0D%0AERROR%20MESSAGE%0D%0A%0D%0A${error.message}%0D%0A%0D%0A%0D%0AERROR%20STACK%0D%0A%0D%0A${error.stack}%0D%0A%0D%0A%0D%0AUSER%20AGENT%0D%0A%0D%0A${window.navigator?.userAgent}
      `;

      return (
        <StyledContainer maxWidth="md">
          <SkewedBackground />

          <main>
            <Typography
              className="heading"
              component="h1"
              variant="h3"
            >
              Something went wrong.
              <span
                aria-label="disappointed emoji"
                role="img"
              >
                😥
              </span>
            </Typography>

            <EnhancedAlert
              severity="warning"
              title="Oh no"
            >
              <Typography>
                We hate when this happens.
                Get in touch to help us get this fixed as soon as we can.
              </Typography>

              <Typography
                className="emailLink"
                sx={{
                  display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 3,
                }}
                variant="subtitle2"
              >
                <Button
                  href={emailHref}
                  startIcon={<SendIcon fontSize="small" />}
                  variant="outlined"
                >
                  Send Email to {SHORT_APP_NAME}
                </Button>

                <Button
                  href="/dashboard"
                  variant="outlined"
                >
                  Go to dashboard
                </Button>
              </Typography>
            </EnhancedAlert>

            <EnhancedCard sx={{ mt: 3 }}>
              <CardHeader
                component="h2"
                title="Error Details"
                titleTypographyProps={{
                  variant: 'h5',
                }}
              />

              <CardContent>
                <Typography gutterBottom>
                  The error message below might help us figure out what happened:
                </Typography>

                <blockquote className="errorContent">
                  <code className="errorMessage">{error.message}</code>
                  <div>
                    <code>{error.stack}</code>
                  </div>
                </blockquote>
              </CardContent>
            </EnhancedCard>
          </main>

          <Footer
            hideLinks
            showHelpLink
          />
        </StyledContainer>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
