// External Dependencies
import { Box, Button } from '@mui/material';
import {
  ChildLikeRelationshipTypeIds,
  ChildPeerRelationshipTypeIds,
  ParentLikeRelationshipTypeIds,
  ParentPeerRelationshipTypeIds,
  UserRoles,
} from '@presto-assistant/api_types';
import { FC } from 'react';
import { Form, Formik } from 'formik';

// Internal Dependencies
import {
  CustomSelect,
  Flex,
  SaveButton,
} from 'components/shared';
import { mapEnum } from 'utils/lib/map_enum';
import { useGetRelationshipTypes } from 'gql/queries';
import { yup } from 'utils/yup';

// Local Typings
interface Props {
  autoFocus?: boolean;
  cancelText?: string;
  isSubmitting: boolean;
  memberRoleId?: GQL.IUser['role']['id'];
  onBack: () => void;
  onCompleted: (values: RelationshipTypeFormValues) => void;
  relationRoleId?: GQL.IUser['role']['id'];
}
export interface RelationshipTypeFormValues {
  recipientRelationshipTypeId: string;
}

// Local Variables
const schema = yup.object().shape<RelationshipTypeFormValues>({
  recipientRelationshipTypeId: yup.string().required('Required'),
});

// Component Definition
const RelationshipType: FC<Props> = ({
  autoFocus,
  cancelText = 'Go Back',
  isSubmitting,
  memberRoleId,
  onBack,
  onCompleted,
  relationRoleId,
}) => {
  const { data } = useGetRelationshipTypes();

  const isMemberAdult = memberRoleId === UserRoles.Adult.toString();
  const isMemberStudent = memberRoleId === UserRoles.Student.toString();
  const isRelationAdult = relationRoleId === UserRoles.Adult.toString();
  const isRelationStudent = relationRoleId === UserRoles.Student.toString();

  const childLikeRelationshipTypeOptions = mapEnum(ChildLikeRelationshipTypeIds);
  const parentLikeRelationshipTypeOptions = mapEnum(ParentLikeRelationshipTypeIds);
  const chidlPeerRelationshipTypeOptions = mapEnum(ChildPeerRelationshipTypeIds);
  const parentPeerRelationshipTypeOptions = mapEnum(ParentPeerRelationshipTypeIds);

  /*
   * We have four types of relationship options:
   * 1. Show all options if a member is adding a family member
   * 2. Adding parent to a student
   * 3. Adding student to a parent
   * 4. Adding student to a student (child-peer)
   * 5. Adding parent to a parent (adult-peer)
   */

  // 1. Show all options if a member is adding a family member
  let relationshipTypeOptions = data?.relationshipTypes?.map((type) => ({
    id: type.id,
    label: type.label,
  })) ?? [];

  // 2. Adding parent to a student
  if (isMemberStudent && isRelationAdult) {
    relationshipTypeOptions = parentLikeRelationshipTypeOptions;
    // 3. Adding student to a parent
  } else if (isMemberAdult && isRelationStudent) {
    relationshipTypeOptions = childLikeRelationshipTypeOptions;
    // 4. Adding student to a student (child-peer)
  } else if (isMemberStudent && isRelationStudent) {
    relationshipTypeOptions = chidlPeerRelationshipTypeOptions;
    // 5. Adding parent to a parent (adult-peer)
  } else if (isMemberAdult && isRelationAdult) {
    relationshipTypeOptions = parentPeerRelationshipTypeOptions;
  }

  const handleFormikSubmit = (values: RelationshipTypeFormValues) => {
    onCompleted(values);
  };

  return (
    <Formik<RelationshipTypeFormValues>
      enableReinitialize
      initialValues={{
        recipientRelationshipTypeId: '',
      }}
      onSubmit={handleFormikSubmit}
      validationSchema={schema}
    >
      {({
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <CustomSelect
            autoFocus={autoFocus}
            label="This person is a..."
            name="recipientRelationshipTypeId"
            options={relationshipTypeOptions}
            variant="filled"
          />

          <Box mt={1}>
            <Flex justifyContent="flex-end">
              <Button
                color="primary"
                onClick={onBack}
                sx={{ marginRight: 1 }}
                variant="outlined"
              >
                {cancelText}
              </Button>

              <SaveButton isSaving={isSubmitting}>
                Add Family Member
              </SaveButton>
            </Flex>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default RelationshipType;
