// External Dependencies
import { Container } from '@mui/material';
import { FC, useCallback } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';

// Internal Dependencies
import StripePaymentForm from 'components/shared/StripePaymentForm';

// Local Dependencies
import PaymentMethodCard from '../shared/PaymentMethodCard';

// Local Typings
interface Props {
  onSetPaymentMethod: (paymentMethod: PaymentMethod | null) => void;
  paymentMethod: PaymentMethod | null;
}

// Component Definition
const EnterPaymentMethod: FC<Props> = ({
  onSetPaymentMethod,
  paymentMethod,
}) => {
  const handleClear = useCallback(() => {
    onSetPaymentMethod(null);
  }, [onSetPaymentMethod]);

  return paymentMethod ? (
    <Container maxWidth="sm">
      <PaymentMethodCard
        onEdit={handleClear}
        paymentMethod={paymentMethod}
      />
    </Container>
  ) : (
    <StripePaymentForm
      onSetPaymentMethod={onSetPaymentMethod}
    />
  );
};

export default EnterPaymentMethod;
