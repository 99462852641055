// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from 'styled-components';
import PieChartIcon from '@mui/icons-material/PieChart';

// Internal Dependencies
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';

// Local Dependencies
import UserRolesMetrics from './UserRolesMetrics';

// Local Typings
interface Props {
  userRoleCounts: GQL.IUserRoleCount[];
}

// Local Variables
const subtitle = 'User Counts';

// Component Definition
const UserRolesMetricsCard: FC<Props> = ({ userRoleCounts }) => {
  const {
    palette: {
      prestoSecondary,
    },
  } = useTheme();

  const hasUserRoles = userRoleCounts.some((userRoleCount) => userRoleCount.count > 0);

  // We show empty state when there are no members yet
  if (!hasUserRoles) {
    return (
      <DashboardCard
        content={(
          <>
            <ZeroStateIcon
              Icon={PieChartIcon}
              htmlColor={prestoSecondary}
            />
            <Typography
              color="textSecondary"
              variant="body2"
            >
              Add users to your organization to see metrics
            </Typography>
          </>
        )}
        horizontallyCenterContent
        title={subtitle}
        verticallyCenterContent
      />
    );
  }

  return (
    <DashboardCard
      content={(
        <UserRolesMetrics userRoleCounts={userRoleCounts} />
      )}
      horizontallyCenterContent
      title={subtitle}
    />
  );
};

export default UserRolesMetricsCard;
