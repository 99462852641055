// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';

// Local Dependencies
import InventoryTable from './AdminInventoryTable';
import LinkToDeletedInventoryItems from './LinkToDeletedInventoryItems';

// Component Definition
const AdminInventory: FC = () => (
  <>
    <Subtitle>
      Inventory Items
    </Subtitle>

    <Box mb={4}>
      <EnhancedCard>
        <InventoryTable />
      </EnhancedCard>
    </Box>

    <LinkToDeletedInventoryItems />
  </>
);

export default AdminInventory;
