// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { Formik } from 'formik';
import { RouteComponentProps, navigate } from '@reach/router';
import { updateFinancialCreditSchema } from '@presto-assistant/api_types/schemas/financialCredits';

// Internal Dependencies
import { ConfirmationDialog, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { convertCentsToDollars, convertDollarsToCents } from 'utils';
import { useGetFinancialCredit } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useUpdateFinancialCredit } from 'gql/mutations';

// Local Dependencies
import ConfirmationDialogContent from './ConfirmationDialogContent';
import EditForm from './EditForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Component Definition
const FinancialCreditEdit: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const [submitPayload, setSubmitPayload] = useState<GQL.IUpdateFinancialCreditInput | null>(null);

  const {
    handleClose,
    handleOpen,
    isOpen,
  } = useIsOpen();

  const {
    data,
    error,
    loading,
  } = useGetFinancialCredit(id!);

  const initialValues = useMemo<GQL.IUpdateFinancialCreditInput>(() => ({
    amountInCents: convertCentsToDollars(data?.financialCredit.amountInCents ?? 0),
    financialAccountId: data?.financialCredit.financialAccount?.id ?? '',
    note: data?.financialCredit.note ?? '',
  }), [data]);

  const showPath = `/${PATHS.FINANCIAL_CREDITS}/${id}`;

  const handleNavigateToShow = useCallback(() => {
    navigate(showPath);
  }, [showPath]);

  const [
    updateCredit,
    {
      loading: isSubmitting,
    },
  ] = useUpdateFinancialCredit({
    clearCachePredicates: ['financialCredits', 'financialCreditsIndex', 'financialCreditOverview', 'itemsNeedingAttention'],
    onCompleted: handleNavigateToShow,
  });

  const handleClearPayload = useCallback(() => {
    setSubmitPayload(null);
  }, []);

  const handleSetPayload = (values: GQL.IUpdateFinancialCreditInput) => {
    const input: GQL.IUpdateFinancialCreditInput = {
      ...values,
      amountInCents: convertDollarsToCents(values.amountInCents),
    };

    setSubmitPayload(input);
    handleOpen();
  };

  const handleSubmitForm = useCallback(() => {
    if (submitPayload) {
      updateCredit({
        variables: {
          id: id!,
          input: submitPayload,
        },
      });
    }
  }, [id, submitPayload, updateCredit]);

  return (
    <Page
      backButtonProps={{
        label: 'Credit Detail',
        path: showPath,
      }}
      error={error}
      isLoading={loading}
    >
      <Formik<GQL.IUpdateFinancialCreditInput>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSetPayload}
        validationSchema={updateFinancialCreditSchema}
      >
        {({
          handleSubmit,
          touched,
        }) => data?.financialCredit && (
          <EditForm
            initialValues={initialValues}
            isFormTouched={Object.keys(touched).length > 0}
            isSubmitting={isSubmitting}
            onClickCancel={handleNavigateToShow}
            onSubmit={handleSubmit}
          />
        )}
      </Formik>

      <ConfirmationDialog
        confirmButtonAction={handleSubmitForm}
        confirmButtonText="Submit"
        declineButtonAction={handleClose}
        description={(
          <ConfirmationDialogContent payload={submitPayload} />
        )}
        handleClose={handleClose}
        isSubmitting={isSubmitting}
        onExited={handleClearPayload}
        open={isOpen}
        title="Edit Credit Review"
        useCustomText
      />
    </Page>
  );
};

export default FinancialCreditEdit;
