// External Dependencies
import {
  Box, CardContent, Container, Typography,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { EnhancedCard, SkewedBackground } from 'components/shared';
import PrestoLogoHorizontalSvg from 'components/shared/PrestoLogoHorizontalSvg';

// Local Typings
interface Props {
  message?: ReactNode;
  title?: string;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)({
  zIndex: 1,
});

const Maintenance: FC<Props> = ({
  message = 'We are performing some scheduled maintenance. Please check back in a few hours.',
  title,
}) => {
  const theme = useTheme();

  return (
    <>
      <SkewedBackground />

      <Container maxWidth="md">
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          paddingTop={4}
        >
          <Box
            mb={3}
            width="90%"
            zIndex={1}
          >
            <Typography
              align="center"
              component="h1"
            >
              <PrestoLogoHorizontalSvg htmlColor={theme.palette.primary.main} />
            </Typography>
          </Box>

          <StyledEnhancedCard>
            <CardContent>
              <img
                alt="maintenance"
                src="https://res.cloudinary.com/presto-assistant/image/upload/v1642376166/web-app/maintenance_optimized_naesge.svg"
                width="100%"
              />

              {title && (
                <Typography
                  align="center"
                  sx={{ marginTop: 2 }}
                  variant="h5"
                >
                  {title}
                </Typography>
              )}

              <Typography
                align="center"
                sx={{ marginTop: 2 }}
              >
                {message}
              </Typography>
            </CardContent>
          </StyledEnhancedCard>
        </Box>
      </Container>
    </>
  );
};

export default Maintenance;
