// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { logoutCurrentUser } from 'state/self/actions';

// Local Dependencies
import { LottieOptions } from 'lottie-react';
import {
  closeDialogSuccess,
  openDialogSuccess,
} from './actions';

// Local Typings
export interface SuccessDialogProps {
  animationData: LottieOptions['animationData'];
  isOpen: boolean;
  title: string;
}
export interface SuccessDialogState {
  dialogProps: SuccessDialogProps;
}

const initialDialogProps: SuccessDialogProps = {
  animationData: null,
  isOpen: false,
  title: '',
};

// Reducers
const dialogProps = createReducer(initialDialogProps, (handleAction) => [
  handleAction([
    closeDialogSuccess,
    logoutCurrentUser,
  ], (state) => ({
    ...state,
    isOpen: false,
  })),
  handleAction(openDialogSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isOpen: true,
  })),
]);

export default combineReducers<SuccessDialogState>({
  dialogProps,
});
