// External Dependencies
import { Container } from '@mui/material';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { toggleOrganization } from 'utils/api';
import OnboardingDistrictInfo from 'pages/Onboarding/OnboardingDistrictInfo';

// Component Definition
const DistrictNew: FC = () => {
  const dispatch = useDispatch();

  const handleSuccess = useCallback(() => {
    toggleOrganization(null, dispatch, `/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS_CLAIM}`);
  }, [dispatch]);

  return (
    <Container maxWidth="md">
      <EnhancedCard>
        <OnboardingDistrictInfo onSuccess={handleSuccess} />
      </EnhancedCard>
    </Container>
  );
};

export default DistrictNew;
