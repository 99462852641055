// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';

// Internal Dependencies
import {
  EnhancedAlert,
  OnlinePaymentsLearnMoreLink,
} from 'components/shared';
import { useGetOrganization } from 'gql/queries';

// Local Typings
interface Props {
  showWarningAlertIfDisabled?: boolean;
}

// Component Definition
const OnlinePaymentsAlert: FC<Props> = ({
  showWarningAlertIfDisabled,
}) => {
  const { data: organizationData } = useGetOrganization();

  const organization = organizationData?.organization;
  const district = organization?.district;

  const areDistrictOnlinePaymentsEnabled = Boolean(
    organizationData?.organization.district?.areOnlinePaymentsEnabled,
  );
  const areOrganizationOnlinePaymentsEnabled = Boolean(
    organizationData?.organization.areOnlinePaymentsEnabled,
  );

  if (areDistrictOnlinePaymentsEnabled && areOrganizationOnlinePaymentsEnabled) {
    return (
      <EnhancedAlert>
        <Typography variant="body2">
          Enable online payments to allow parents to pay fees for this account online.
          {' '}
          <OnlinePaymentsLearnMoreLink />
        </Typography>
      </EnhancedAlert>
    );
  }

  if (!showWarningAlertIfDisabled) {
    return null;
  }

  return (
    <EnhancedAlert severity="warning">
      <Typography variant="body2">
        Enable &quot;Online Payments&quot; for
        {' '}
        {areDistrictOnlinePaymentsEnabled
          ? organization?.label
          : (
            <>
              {[district?.label, organization?.label].filter(Boolean).join(' and ')}
            </>
          )}
        .
        {' '}
        <OnlinePaymentsLearnMoreLink />
      </Typography>
    </EnhancedAlert>
  );
};

export default OnlinePaymentsAlert;
