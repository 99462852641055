// External Dependencies
import { Collapse, Typography } from '@mui/material';
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import clsx from 'clsx';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared/index';
import { UPLOAD_FILE_TYPES } from 'utils/constants/files';
import { useFlatFileTypes } from 'hooks/useFlatFileTypes';

// Local Typings
interface Props {
  acceptedFileTypes?: string[];
  disabled?: boolean;
  handleDrop: (acceptedFiles: File[]) => void;
  marginTop?: number;
  rejectedDropErrorMessage: string;
}

// Local Variables
const StyledSection = styled.section<{ marginTop: number }>(({ marginTop, theme }) => ({
  '&.disabledDropzone': {
    backgroundColor: theme.palette.grey[200],
  },
  '.dropzoneHover': {
    backgroundColor: theme.palette.dropzoneHover,
    border: `3px dashed ${theme.palette.dropzoneBorder}`,
  },
  '.icon': {
    height: 144,
    width: 144,
  },
  border: `3px dashed ${theme.palette.divider}`,
  borderRadius: 3,
  marginTop: theme.spacing(marginTop),
  textAlign: 'center',
}));

// Component Definition
const UploadDropzone: FC<Props> = ({
  acceptedFileTypes = [UPLOAD_FILE_TYPES.csv],
  disabled,
  handleDrop,
  marginTop = 3,
  rejectedDropErrorMessage,
}) => {
  const [hasError, setHasError] = useState(false);

  const theme = useTheme();

  const onDrop = useCallback(
    (acceptedFiles) =>
      handleDrop(acceptedFiles),
    [handleDrop],
  );

  const acceptedFileTypesArray = useFlatFileTypes(acceptedFileTypes);

  const shouldAcceptAll = useMemo(
    () => acceptedFileTypesArray.every((type) => type === '*'),
    [acceptedFileTypesArray],
  );

  const {
    getInputProps,
    getRootProps,
    isDragActive,
  } = useDropzone({
    accept: shouldAcceptAll ? undefined : acceptedFileTypesArray,
    onDrop,
    onDropAccepted: () => setHasError(false),
    onDropRejected: (err, arg) => {
      console.log('rej err : ', err);
      console.log('rej arg : ', arg);

      setHasError(true);
    },
  });

  const rootProps = getRootProps({
    onChange: () => { },
    onClick: () => { },
    tabIndex: -1,
  });

  return (
    <>
      <StyledSection
        {...rootProps}
        className={clsx(isDragActive && 'dropzoneHover', disabled && 'disabledDropzone')}
        marginTop={marginTop}
      >
        <input
          {...getInputProps({
            accept: acceptedFileTypes.join(', '),
            disabled,
          })}
        />

        <CloudUploadIcon
          className="icon"
          htmlColor={theme.palette.divider}
        />

        <Typography
          color={disabled ? theme.palette.grey[500] : theme.palette.grey[600]}
          fontSize={18}
          fontWeight={400}
          gutterBottom
        >
          {isDragActive ? 'Drop your file here' : 'Drop file or click to upload'}
        </Typography>
      </StyledSection>

      <Collapse in={hasError}>
        <EnhancedAlert
          severity="error"
          sx={{ marginTop: 1 }}
        >
          {rejectedDropErrorMessage}
        </EnhancedAlert>
      </Collapse>
    </>
  );
};

export default UploadDropzone;
