// External Dependencies
import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';

// Internal Dependencies
import { EnhancedCard, Page, Subtitle } from 'components/shared';
import { PATHS } from 'utils/constants/routes';

// Local Dependencies
import { tableQueryParams } from 'state/table/selectors';
import { useSelector } from 'react-redux';
import FilesTable from './FilesTable';

// Local Typings
interface RouteProps {
  directory: string;
}

// Component Definition
const FilesAllByDirectory: FC<RouteComponentProps<RouteProps>> = ({
  directory,
}) => {
  const directoriesParams = useSelector(tableQueryParams('fileDirectories'));

  return (
    <Page
      backButtonProps={{
        label: 'Files',
        path: `/${PATHS.FILES}${directoriesParams}`,
      }}
      isLoading={false}
    >
      <Subtitle>
        {directory!}
      </Subtitle>

      <EnhancedCard>
        <FilesTable directory={directory!} />
      </EnhancedCard>
    </Page>
  );
};

export default FilesAllByDirectory;
