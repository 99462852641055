// External Dependencies
import { FC, useEffect, useState } from 'react';
import { navigate } from '@reach/router';

// Internal Dependencies
import {
  GET_UNIFORM,
  SimpleUniform,
  SimpleUser,
  useGetSimpleUniform,
  useGetSimpleUser,
} from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import {
  Page,
  SelectUser,
} from 'components/shared';
import { parseSearch } from 'utils';
import { useCreateUniformCheckout } from 'gql/mutations';

// Local Dependencies
import SelectUniform from './SelectUniform';
import UniformCheckoutForm, { UniformCheckoutFormValues } from '../shared/UniformCheckoutForm';

// Local Variables
const navigateToUniformShow = (id: string) => {
  navigate(`/${PATHS.UNIFORMS}/${id}`);
};

// Component Definition
const UniformCheckoutNew: FC = () => {
  const search = parseSearch(window.location.search);

  const [userId] = useState<string | undefined>(search.userId);
  const [uniformId] = useState<string | undefined>(search.uniformId);

  const [user, setUser] = useState<SimpleUser | null>(null);
  const [uniform, setUniform] = useState<SimpleUniform | null>(null);

  const {
    data: initialUser,
    loading: isInitialUserLoading,
  } = useGetSimpleUser(userId);
  const {
    data: initialUniform,
    loading: isInitialUniformLoading,
  } = useGetSimpleUniform(uniformId);

  const [createUniformCheckout] = useCreateUniformCheckout(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['uniformsIndex'],
      onCompleted: (data) => navigateToUniformShow(data.createUniformCheckout.uniform.id),
      refetchQueries: () => [
        { query: GET_UNIFORM, variables: { id: uniform?.id } },
      ],
    },
  );

  const handleSubmit = async (values: UniformCheckoutFormValues) => {
    await createUniformCheckout({
      variables: values,
    });
  };

  useEffect(() => {
    if (initialUser?.user) {
      setUser(initialUser.user);
    }
  }, [initialUser, setUser]);

  useEffect(() => {
    if (initialUniform?.uniform) {
      setUniform(initialUniform.uniform);
    }
  }, [initialUniform, setUniform]);

  return (
    <Page isLoading={isInitialUserLoading || isInitialUniformLoading}>
      {!user && <SelectUser onSelectUser={setUser} />}
      {!uniform && user && <SelectUniform onSelectUniform={setUniform} />}

      {user && uniform && (
        <UniformCheckoutForm
          onSubmit={handleSubmit}
          title="New Uniform Checkout"
          uniform={uniform}
          user={user}
        />
      )}
    </Page>
  );
};

export default UniformCheckoutNew;
