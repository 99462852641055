// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const EmailRecipientsTableZeroState: FC = () => (
  <TableDataGridZeroState
    Icon={TrendingUpIcon}
    excludeNeedHelp
    maxTextWidth="90%"
    message={(
      <>
        <Typography paragraph>
          There are no insights available yet for this email.
        </Typography>

        <Typography paragraph>
          Please check back later.
        </Typography>
      </>
    )}
  />
);

export default EmailRecipientsTableZeroState;
