// External Dependencies
import { FC, useEffect } from 'react';

// Local Dependencies
import PermissionEditPageUI from './PermissionEditPageUI';

// Local Typings
interface Props {
  isDirector: boolean;
  isLoading: boolean;
  mayEditPermissions: boolean;
  onSave: () => void;
  onUpdateMayEditPermissions: (allowedToEdit: boolean) => void;
  onUpdatePermissionIds: (updatedPermissionIds: number[]) => void;
  organizationName: string | undefined;
  permissionIds: number[];
  permissions: GQL.IUser['permissions'];
}

// Component Definition
const PermissionEditPage: FC<Props> = ({
  isDirector,
  isLoading,
  mayEditPermissions,
  onSave,
  onUpdateMayEditPermissions,
  onUpdatePermissionIds,
  organizationName,
  permissionIds,
  permissions,
}) => {
  const onToggleEditPermissionsCheckbox = () => {
    onUpdateMayEditPermissions(!mayEditPermissions);
  };

  const handleChange = (permissionId: number) => {
    if (permissionIds.includes(permissionId)) {
      const index = permissionIds.findIndex((pId) => pId === permissionId);

      const permissionIdsCopy = [...permissionIds];

      permissionIdsCopy.splice(index, 1);

      onUpdatePermissionIds(permissionIdsCopy);
    } else {
      onUpdatePermissionIds([
        ...permissionIds,
        permissionId,
      ]);
    }
  };

  useEffect(() => {
    if (!permissions) {
      onUpdatePermissionIds([]);
    } else {
      const allowedPermissionsIds: number[] = [];

      Object.values(permissions).forEach((permissionSet: GQL.IPermissionActions) => {
        const permissionDetails: GQL.IPermissionDetails[] = Object.values(permissionSet);

        const newAllowedPermissionIds: number[] = permissionDetails
          .filter(({ allowed }) => allowed)
          .map(({ permissionId }) => permissionId);

        allowedPermissionsIds.push(...newAllowedPermissionIds);

        onUpdatePermissionIds(allowedPermissionsIds);
      });
    }
  }, [permissions]);

  return (
    <PermissionEditPageUI
      isDirector={isDirector}
      isLoading={isLoading}
      mayEditPermissions={mayEditPermissions}
      onChangePermission={handleChange}
      onSave={onSave}
      onToggleEditPermissionsCheckbox={onToggleEditPermissionsCheckbox}
      organizationName={organizationName}
      permissionIds={permissionIds}
      permissions={permissions}
    />
  );
};

export default PermissionEditPage;
