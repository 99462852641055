// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';

// Component Definition
const EndOfYearGroupsInfoAlert: FC = () => (
  <Box mb={3}>
    <EnhancedAlert
      severity="info"
      title="Groups"
    >
      Select which groups to carry over to the next school year.
      Choose whether to bring all returning members automatically.
    </EnhancedAlert>
  </Box>
);

export default EndOfYearGroupsInfoAlert;
