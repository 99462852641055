// External Dependencies
import { FC, useCallback } from 'react';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { OrganizationEntityTypes } from '@presto-assistant/api_types';
import styled from 'styled-components';

// Internal Dependencies
import { COLLEGE_GRADE_OPTIONS } from 'utils/constants/entityTypeOptions';
import {
  GET_RETURNING_STUDENTS,
  useGetOrganization,
  useGetPossibleFeederOrganizations,
} from 'gql/queries';
import { TableDataGrid } from 'components/shared';
import { useUpdateUser } from 'gql/mutations';

// Local Dependencies
import { useColumns } from './hooks';

// Local Typings
interface Props {
  returningStudentsData: GQL.IReturningStudent[] | null;
}

// Local Variables
const StyledDivRoot = styled.div({
  '& .table-wrapper': {
    flexGrow: 1,
  },
  display: 'flex',
  height: 'calc(100% - 54px)', // account for height of edit mode text
});

// Component Definition
const ReturningStudentsTable: FC<Props> = ({ returningStudentsData }) => {
  const {
    data: organizationData,
    loading: isLoadingOrgData,
  } = useGetOrganization();

  const {
    data: possibleFeederOrganizationData,
    loading: isLoadingFeederOrgData,
  } = useGetPossibleFeederOrganizations();

  const [updateUser] = useUpdateUser(
    {
      clearCachePredicates: ['studentsIndex'],
      refetchQueries: [{ query: GET_RETURNING_STUDENTS }],
    },
  );

  const isCollegeOrUniversity = organizationData?.organization.entityType.id
    === OrganizationEntityTypes.College.toString();

  const feederOrgData = possibleFeederOrganizationData?.possibleFeederOrganizations;

  const columns = useColumns(organizationData?.organization, feederOrgData);

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {
      const {
        email,
        firstName,
        grade, // Will be select option.label
        id,
        lastName,
        organizationLabel, // We only have access to the label, not the id
      } = newRow;

      // Using the successor org label, we find the id in the list of possibleFeederOrgs
      const newSuccessorOrgId = feederOrgData?.find((feederOrg) =>
        feederOrg.label === organizationLabel)?.id;

      const updatedGrade = isCollegeOrUniversity
        // Since college/univ doesn't use a number string as a grade,
        //  we have to search for the label match in the options array
        //  and find the id for it.
        ? parseInt(COLLEGE_GRADE_OPTIONS.find((option) => option.label === grade)?.id ?? '13', 10) - 1
        : parseInt(grade, 10) - 1;

      await updateUser({
        variables: {
          id,
          input: {
            email,
            firstName,
            grade: updatedGrade,
            lastName,
            successorOrganizationId: newSuccessorOrgId,
          },
        },
      });

      return newRow;
    },
    [],
  );

  if (!organizationData || !feederOrgData) {
    return null;
  }

  return (
    <StyledDivRoot>
      <div className="table-wrapper">
        <TableDataGrid
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          hideExport
          loading={isLoadingOrgData || isLoadingFeederOrgData}
          processRowUpdate={processRowUpdate}
          rows={returningStudentsData ?? []}
          tableResource="returningStudents"
          withSearch
        />
      </div>
    </StyledDivRoot>
  );
};

export default ReturningStudentsTable;
