// External Dependencies
import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import {
  Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import { Genders } from '@presto-assistant/api_types';
import VisiblityIcon from '@mui/icons-material/Visibility';
import VisiblityOffIcon from '@mui/icons-material/VisibilityOff';
import moment from 'moment';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  CustomCheckbox,
  CustomInput,
  DatePickerField,
  EnhancedAlert,
  Flex,
  GenderSelect,
  PrivacyAndTermsLinks,
  SaveButton,
} from 'components/shared';
import { normalizeFormErrors } from 'utils/lib/graphql_errors';
import { useValidateUserFields } from 'gql/mutations';

// Local Typings
interface Props {
  initialValues: UnderThirteenFormValues;
  onBack: () => void;
  onCompleted: (values: UnderThirteenFormValues) => void;
  readOnly?: boolean;
}
export interface UnderThirteenFormValues {
  acceptedTerms: boolean;
  dateOfBirthChild: string;
  genderId: string;
  isParent: boolean;
  otherGenderLabel?: string;
  password: string;
}

// Component Definition
const UnderThirteen: FC<Props> = ({
  initialValues,
  onBack,
  onCompleted,
  readOnly,
}) => {
  const [formValues, setFormValues] = useState<UnderThirteenFormValues>({
    acceptedTerms: false,
    dateOfBirthChild: '',
    genderId: '',
    isParent: false,
    otherGenderLabel: '',
    password: '',
  });
  const [
    errors,
    setErrors,
  ] = useState<FormikErrors<UnderThirteenFormValues> | undefined>(undefined);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((state) => !state);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleCompleted = (data: { validateUserFields: boolean }) => {
    if (data.validateUserFields) {
      onCompleted(formValues);
    }
  };

  const handleError = (error: ApolloError) => {
    setErrors(normalizeFormErrors(error));
  };

  const [
    validateUserFields,
    { loading },
  ] = useValidateUserFields(
    {
      onCompleted: handleCompleted,
      onError: handleError,
    },
  );

  const handleFormikSubmit = (
    values: UnderThirteenFormValues,
    formikProps: FormikHelpers<UnderThirteenFormValues>,
  ) => {
    const {
      acceptedTerms,
      dateOfBirthChild,
      isParent,
      ...userFields
    } = values;

    if (!acceptedTerms || !isParent) {
      formikProps.setErrors({
        ...(!acceptedTerms && { acceptedTerms: 'You must agree to terms and conditions.' }),
        ...(!isParent && { isParent: 'You must be the parent or legal guardian.' }),
      });
    } else {
      setFormValues(values);

      validateUserFields({
        variables: {
          input: {
            ...userFields,
            dateOfBirth: moment(dateOfBirthChild).format('MM/DD/YYYY'),
          },
        },
      });
    }
  };

  return (
    <Formik<UnderThirteenFormValues>
      enableReinitialize
      initialErrors={errors}
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
    >
      {({
        handleSubmit,
        values,
      }) => {
        const hasAllValues = !!(values.dateOfBirthChild && values.genderId && values.password);

        return (
          <Form
            onSubmit={handleSubmit}
          >
            <Box my={1.5}>
              <EnhancedAlert>
                Children under 13 need <strong>Parental Authorization</strong> to use {APP_NAME}.
              </EnhancedAlert>
            </Box>

            <DatePickerField
              autoFocus
              readOnly={readOnly}
              label="Date of Birth"
              name="dateOfBirthChild"
              required
            />
            <GenderSelect
              readOnly={readOnly}
              required
            />
            <Collapse in={values.genderId === Genders.Other.toString()}>
              <CustomInput
                label="Other Gender Label"
                name="otherGenderLabel"
              />
            </Collapse>
            <CustomInput
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="large"
                    >
                      {showPassword ? <VisiblityIcon /> : <VisiblityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
                readOnly,
              }}
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              required
            />

            <CustomCheckbox
              disabled={readOnly}
              inputProps={{ readOnly }}
              label={`I have read and agree to the ${APP_NAME} Privacy Policy and Terms of Service.`}
              name="acceptedTerms"
            />

            <CustomCheckbox
              disabled={readOnly}
              inputProps={{ readOnly }}
              label="I am this person's parent or legal guardian."
              name="isParent"
            />

            <Box
              mb={4}
              mt={2}
              textAlign="center"
            >
              <PrivacyAndTermsLinks withCoppa />
            </Box>

            {!readOnly && (
              <Flex justifyContent="flex-end">
                <Button
                  color="primary"
                  onClick={onBack}
                  sx={{ mr: 1 }}
                  variant="outlined"
                >
                  Go Back
                </Button>

                <SaveButton
                  color="primary"
                  disabled={!hasAllValues}
                  isSaving={loading}
                  variant="contained"
                >
                  Next
                </SaveButton>
              </Flex>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default UnderThirteen;
