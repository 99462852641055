// External Dependencies
import { FC, useEffect } from 'react';
import { Page } from 'components/shared';
import { RouteComponentProps } from '@reach/router';

// Internal Dependencies
import { redeemSso } from 'utils/api';

// Local Typings
interface RouteProps {
  token: string;
}

// Component Definition
const Sso: FC<RouteComponentProps<RouteProps>> = ({ token }) => {
  useEffect(() => {
    if (token) {
      redeemSso({ ssoToken: token });
    }
  }, [token]);

  return (
    <Page
      isLoading
    >
      <h2>Loading...</h2>
    </Page>
  );
};

export default Sso;
