// External Dependencies
import { useTheme } from 'styled-components';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { CONTACT_INFO } from 'utils/constants';
import {
  EnhancedCard, Flex, OnboardingStepTitle, StyledLink,
} from 'components/shared';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import OnboardingProgress from './OnboardingProgress';

// Component Definition
const OnboardingExtraVerificationNeeded = () => {
  const theme = useTheme();

  const { loading } = useSelfQuery();

  if (loading) {
    return <OnboardingProgress />;
  }

  return (
    <>
      <OnboardingStepTitle title="Verification Needed" />

      <Box marginTop={1}>
        <EnhancedCard sx={{ minHeight: 400 }}>
          <CardContent>
            <Flex
              justifyContent="center"
              marginBottom={4}
              marginTop={2}
            >
              <Avatar
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  height: 56,
                  width: 56,
                }}
              >
                <SafetyCheckIcon fontSize="large" />
              </Avatar>
            </Flex>

            <Typography paragraph>
              This account requires extra verification.
              {' '}
              A Presto Team member has been notified and is reviewing your account.
            </Typography>

            <Typography paragraph>
              Please email
              {' '}
              <StyledLink href={CONTACT_INFO.MAILTO_SUPPORT}>
                support
              </StyledLink>
              {' '}
              if you have any questions.
            </Typography>

            <Typography>
              Thank you for your patience.
            </Typography>
          </CardContent>
        </EnhancedCard>
      </Box>
    </>
  );
};

export default OnboardingExtraVerificationNeeded;
