// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';
import EmailAlertOutlineIcon from 'mdi-material-ui/EmailAlertOutline';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const SuppressedEmailsTableZeroState: FC = () => (
  <TableDataGridZeroState
    Icon={EmailAlertOutlineIcon}
    excludeNeedHelp
    maxTextWidth="75%"
    message={(
      <Typography>
        Any email addresses we have trouble delivering to will appear here in this table.
      </Typography>
    )}
  />
);

export default SuppressedEmailsTableZeroState;
