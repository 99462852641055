// Solution found from this comment: https://github.com/mui-org/material-ui/issues/18935#issuecomment-665835537
// This prevents the overlay from having role="none presentation"

// External Dependencies
import {
  Dialog,
  DialogProps,
} from '@mui/material';
import { FC } from 'react';

// Component Definition
const EnhancedDialog: FC<DialogProps> = ({
  children,
  PaperProps = {},
  TransitionProps = {},
  ...otherProps
}) => (
  <Dialog
    PaperProps={{
      'aria-label': 'Dialog',
      ...PaperProps,
    }}
    TransitionProps={{
      role: 'presentation',
      ...TransitionProps,
    } as any}
    {...otherProps}
  >
    {children}
  </Dialog>
);

export default EnhancedDialog;
