// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  EnhancedAlert,
  EnhancedCard,
  Subtitle,
} from 'components/shared';

// Local Dependencies
import SuppressedEmailsTable from './SuppressedEmailsTable';

// Component Definition
const SuppressedEmailsList: FC = () => (
  <>
    <Subtitle>
      Suppressed Emails
    </Subtitle>

    <EnhancedAlert
      isTip
      sx={{ mb: 2 }}
      title="Email Tips"
    >
      Suppressed email addresses cannot accept email from the {APP_NAME} email service provider.
      The most common issues are a full inbox or a typo in the email address.

      <Typography
        sx={{ color: 'inherit', mt: 1 }}
        variant="body2"
      >
        To help keep the {APP_NAME} email system going strong, please
        look at the <strong>Reason</strong> and help solve the issue for each
        email address.
      </Typography>
    </EnhancedAlert>

    <EnhancedCard>
      <SuppressedEmailsTable />
    </EnhancedCard>
  </>
);

export default SuppressedEmailsList;
