// External Dependencies
import {
  AlertProps,
  Alert as MuiAlert,
} from '@mui/material';

import Portal from '@mui/material/Portal';
import Snackbar from '@mui/material/Snackbar';

import { forwardRef, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { ContextData } from 'types/context';
import { removeNotification } from 'state/notifications/actions';

// Local Typings
interface Props {
  notification: ContextData.INotificationDetail;
}

// Local Variables
// Borrowed from the MUI docs:
// https://mui.com/material-ui/react-snackbar/#customization
const Alert = forwardRef<HTMLDivElement, AlertProps>((
  props,
  ref,
) => (
  <MuiAlert
    elevation={6}
    ref={ref}
    variant="filled"
    {...props}
  />
));

// Component Definition
const Notification = ({ notification }: Props) => {
  const dispatch = useDispatch();
  const { id, label, severity } = notification;

  const handleClose = useCallback(() => {
    dispatch(removeNotification(id));
  }, [dispatch, id]);

  const transitionProps = useMemo(() => ({
    onExited: handleClose,
  }), [handleClose]);

  return (
    <Portal>
      <Snackbar
        TransitionProps={transitionProps}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        autoHideDuration={5000}
        onClose={handleClose}
        open
      >
        <Alert
          onClose={handleClose}
          severity={severity ?? 'error'}
        >
          <span id="message-id">{label}</span>
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export default Notification;
