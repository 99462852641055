// External Dependencies
import { createActionCreator } from 'deox';

// Internal Dependencies
import { setLocalStorageItem } from 'utils/lib/local_storage';

// Local Dependencies
import { USE_DARK_MODE, USE_DENSE_TABLE } from './reducers';

// Actions
export const updateDarkMode = createActionCreator(
  'UPDATE_DARK_MODE',
  (resolve) => (updatedDarkMode: boolean) => {
    setLocalStorageItem(USE_DARK_MODE, JSON.stringify(updatedDarkMode));
    return resolve(updatedDarkMode);
  },
);

export const updateTableDensity = createActionCreator(
  'UPDATE_TABLE_DENSITY',
  (resolve) => (updatedTableDensity: boolean) => {
    setLocalStorageItem(USE_DENSE_TABLE, JSON.stringify(updatedTableDensity));
    return resolve(updatedTableDensity);
  },
);
