// External Dependencies
import { FC, useCallback, useEffect } from 'react';
import { useLocation } from '@reach/router';

// Internal Dependencies
import { navigateSearch } from 'utils/lib/navigate_search';
import { parseSearch } from 'utils';
import { useGetOrganizationTypes } from 'gql/queries';
import FilterButton from 'components/shared/FilterButton';

// Local Dependencies
import { tableRefOptions } from '../../shared/helpers';

// Local Typings
type FilterKey = 'tableRef' | 'organizationTypeId'
export type IFilters = Record<FilterKey, string | number | null>;

// Component Definition
const Filters: FC = () => {
  const { search } = useLocation();

  const parsedSearch = parseSearch(search);

  const localFilters: IFilters = {
    organizationTypeId: parsedSearch.organizationTypeId ?? null,
    tableRef: parsedSearch.tableRef ?? null,
  };

  const { data: orgTypeData } = useGetOrganizationTypes();

  const handleClickFilter = useCallback((
    key: FilterKey,
    val: string | number | null,
  ) => () => {
    localFilters[key] = val as never;
    navigateSearch({
      ...localFilters,
    });
  }, [localFilters]);

  const selectedOrgTypeId = `${localFilters.organizationTypeId}`;
  const selectedTableRefId = localFilters.tableRef;

  const selectedOrgType = orgTypeData?.organizationTypes.find(
    (orgType) => orgType.id === selectedOrgTypeId,
  );
  const selectedTableRef = tableRefOptions.find(
    (tableRef) => tableRef.id === selectedTableRefId,
  );

  useEffect(() => {
    if (!localFilters.organizationTypeId && orgTypeData?.organizationTypes) {
      handleClickFilter('organizationTypeId', orgTypeData.organizationTypes[0].id)();
    }
  }, [handleClickFilter, localFilters, orgTypeData]);

  useEffect(() => {
    if (!localFilters.tableRef) {
      handleClickFilter('tableRef', tableRefOptions[0].id)();
    }
  }, [handleClickFilter, localFilters]);

  return (
    <div>
      <FilterButton
        active={!!selectedTableRefId}
        buttonChildren={selectedTableRef?.label ?? 'Table'}
        id="tableRef"
        onClick={null}
        subButtons={tableRefOptions.map((tableRef) => ({
          onClick: handleClickFilter('tableRef', tableRef.id),
          selected: selectedTableRefId === tableRef.id,
          text: tableRef.label,
        }))}
      />

      {orgTypeData && (
        <FilterButton
          active={!!selectedOrgTypeId}
          buttonChildren={selectedOrgType?.label ?? 'Organization Type'}
          id="organization"
          onClick={null}
          subButtons={orgTypeData.organizationTypes.map((orgType) => ({
            onClick: handleClickFilter('organizationTypeId', orgType.id),
            selected: selectedOrgTypeId === orgType.id,
            text: orgType.label,
          }))}
        />
      )}
    </div>
  );
};

export default Filters;
