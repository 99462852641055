// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { navigate } from '@reach/router';
import CategoryIcon from '@mui/icons-material/Category';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import DownloadIcon from 'mdi-material-ui/Download';

// Internal Dependencies
import { DELETE_LIBRARY_ITEMS } from 'gql/mutations';
import {
  GET_DISTRICT_LIBRARY_INDEX_ITEMS,
  useDownloadDistrictLibraryItemsIndexItems,
  useGetDistrictLibraryIndexItems,
} from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { ToolbarProps } from 'components/shared/DataTable/Toolbar';
import { parseSearch } from 'utils';
import { useClearTableSelection } from 'hooks/useClearTableSelection';
import { useIsOpen } from 'hooks/useIsOpen';
import DeleteDialog from 'components/shared/DeleteDialog';
import TableV2 from 'components/shared/TableV2';

// Local Dependencies
import { useColumns } from './hooks';
import DialogLibraryFileUpload from './DialogLibraryFileUpload';
import Filters from './Filters';

// Component Definition
const AdminLibraryTable: FC = () => {
  const parsedSearch = useMemo(() =>
    parseSearch(window.location.search), [window.location.search]);

  const [
    singleItemDeleteId,
    setSingleItemDeleteId,
  ] = useState<string | null>(null);

  const {
    handleClose: closeDeleteDialog,
    isOpen: isDeleteDialogOpen,
  } = useIsOpen();

  const {
    handleClose: closeUploadDialog,
    handleOpen: openUploadDialog,
    isOpen: isUploadDialogOpen,
  } = useIsOpen();

  const {
    data,
    loading,
  } = useGetDistrictLibraryIndexItems();

  const [
    handleExportLibraryItems,
    { loading: isExporting },
  ] = useDownloadDistrictLibraryItemsIndexItems();

  const handleRemoveSelectionAll = useClearTableSelection('districtLibraryItems');

  const handleOpenSingleItemDeleteDialog = useCallback((
    row: GQL.IDistrictLibraryIndexItem,
  ) => {
    setSingleItemDeleteId(row.id);
  }, [setSingleItemDeleteId]);

  const handleCloseDeleteDialog = () => {
    setSingleItemDeleteId(null);
    closeDeleteDialog();
  };

  const handleNavigateToDynamicFields = useCallback(() => {
    const organizationTypeId = parsedSearch.organizationTypeId ?? '1';

    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}?organization_type_id=${organizationTypeId}&table_ref=library_items`);
  }, [parsedSearch]);

  const hasAtLeastOneLibraryItem = (data?.districtLibraryItemsIndex.fullCount ?? 0) > 0;

  const columns = useColumns(
    handleOpenSingleItemDeleteDialog,
    parsedSearch.organizationTypeId,
  );

  const moreActions: ToolbarProps['moreActions'] = [
    {
      action: handleExportLibraryItems,
      icon: <DownloadIcon />,
      isDisabled: isExporting || (data?.districtLibraryItemsIndex.fullCount ?? 0) < 1,
      text: 'Export library items',
    },
    {
      action: openUploadDialog,
      icon: <CloudUploadIcon />,
      // TODO: Update this to the new way the API tells us about active
      // isDisabled: !self?.currentOrgActive,
      text: 'Import library items',
    },
    {
      action: handleNavigateToDynamicFields,
      icon: <CategoryIcon />,
      text: 'Dynamic fields',
    },
  ];

  return (
    <>
      <TableV2<GQL.IDistrictLibraryIndexItem>
        actions={moreActions}
        addButtonProps={{
          label: 'Library Item',
          to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}/new`,
        }}
        Filters={<Filters />}
        clickRowTo={(row) => `/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}/${row.id}`}
        columns={columns}
        data={data?.districtLibraryItemsIndex.data ?? []}
        disableSearch={!parsedSearch.q && !hasAtLeastOneLibraryItem}
        fullCount={data?.districtLibraryItemsIndex.fullCount}
        isLoading={loading}
        reduxStateKey="districtLibraryItems"
        withSearch
        zeroStateMessage="Add data to this table by adding individually. Library items added by organizations will also appear here."
      />

      <DialogLibraryFileUpload
        isOpen={isUploadDialogOpen}
        onClose={closeUploadDialog}
      />

      <DeleteDialog
        context={['item']}
        isOpen={isDeleteDialogOpen || !!singleItemDeleteId}
        mutation={DELETE_LIBRARY_ITEMS}
        onClose={handleCloseDeleteDialog}
        onRemoveSelectionAll={handleRemoveSelectionAll}
        reduxTableKey="districtLibraryItems"
        refetchQueries={() => [
          getOperationName(GET_DISTRICT_LIBRARY_INDEX_ITEMS) as string,
        ]}
        singleItemId={singleItemDeleteId}
        size={data?.districtLibraryItemsIndex.fullCount ?? 0}
        withNote
      />
    </>
  );
};

export default AdminLibraryTable;
