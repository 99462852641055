// External Dependencies
import { FC, useMemo } from 'react';
import { FormBlockTypes } from '@presto-assistant/api_types';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { EnhancedCard, Flex, ShowPageDataDisplay } from 'components/shared';
import BlockCardMenu from 'pages/Forms/Edit/BlocksCard/BlockCardMenu';

// Local Typings
interface Props {
  block: GQL.IFormBlock;
  formId: string;
  setDeleteBlockId: (blockId: string) => void;
}

// Component Definition
const BlockCard: FC<Props> = ({
  block,
  formId,
  setDeleteBlockId,
}) => {
  const blockTypeId = Number(block.formBlockType.id);

  const content = useMemo(() => {
    if (blockTypeId === FormBlockTypes.Heading) {
      return (
        <Typography variant="h6">
          {block.metadata.content}
        </Typography>
      );
    }

    if (blockTypeId === FormBlockTypes.Paragraph) {
      return (
        <Typography>
          {block.metadata.content}
        </Typography>
      );
    }

    if (blockTypeId === FormBlockTypes.Select) {
      return (
        <>
          <Typography>
            {block.label}
          </Typography>

          <Flex
            flexWrap="wrap"
            gap={1}
          >
            {block.metadata.options.map((option: string) => (
              <Chip
                key={option}
                label={option}
                size="small"
              />
            ))}
          </Flex>
        </>
      );
    }

    return (
      <Typography>
        {block.label}
      </Typography>
    );
  }, [block, blockTypeId]);

  return (
    <EnhancedCard variant="elevation">
      <CardHeader
        action={(
          <BlockCardMenu
            blockId={block.id}
            formId={formId}
            setDeleteBlockId={setDeleteBlockId}
          />
        )}
        title={block.formBlockType.semanticLabel}
      />

      <CardContent>
        {content}

        {blockTypeId !== FormBlockTypes.Heading && blockTypeId !== FormBlockTypes.Paragraph && (
          <Box marginTop={2}>
            <ShowPageDataDisplay
              label="Required"
              type="boolean"
              value={block.isRequired}
            />
          </Box>
        )}
      </CardContent>
    </EnhancedCard>
  );
};

export default BlockCard;
