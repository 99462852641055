// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface RedeemLicenseResponse {
  redeemLicense: boolean;
}

export const REDEEM_LICENSE = gql`
  mutation RedeemLicense(
    $input: RedeemLicenseInput!
  ) {
    redeemLicense(
      input: $input
    )
  }
`;

export const useRedeemLicense = (
  options?: MutationOptions<
    RedeemLicenseResponse,
    GQL.IRedeemLicenseOnMutationArguments
  >,
) => useMutationEnhanced<
  RedeemLicenseResponse,
  GQL.IRedeemLicenseOnMutationArguments
>(REDEEM_LICENSE, {
  ...options,
  clearCachePredicates: [
    'licenses',
    'organizationSubscriptionDetails',
    'organization',
  ],
});
