// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';

// Local Variables
const StyledRoot = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginRight: theme.spacing(2),
  textTransform: 'uppercase',
})) as typeof Typography;

// Component Definition
const EditModeText: FC = () => (
  <StyledRoot component="span">
    EDIT MODE
  </StyledRoot>
);

export default EditModeText;
