// External Dependencies
import { FC, useCallback } from 'react';
import { useFormikContext } from 'formik';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { Flex } from 'components/shared';
import { getFullName } from 'utils';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
interface Props {
  formBlock: GQL.IMyFormBlock;
}

// Component Definition
const SignatureBlock: FC<Props> = ({
  formBlock,
}) => {
  const {
    self,
  } = useSelfQuery();

  const fullName = self ? getFullName(self) : '';

  const formikContext = useFormikContext<GQL.ISubmitFormBlockResponseInput>();

  const signature = formikContext.values.response;

  const handleClickSignButton = useCallback(() => {
    formikContext.setFieldValue('response', fullName);
  }, [formikContext, fullName]);

  const handleClickClearSignatureButton = useCallback(() => {
    formikContext.setFieldValue('response', '');
  }, [formikContext]);

  return (
    <>
      <Typography variant="h6">
        {formBlock.label}
      </Typography>

      <Flex gap={1}>
        {signature && (
          <Typography
            fontFamily="Calibri"
            fontSize="1.5rem"
            fontStyle="italic"
          >
            {signature}
          </Typography>
        )}

        {signature ? (
          <IconButton
            onClick={handleClickClearSignatureButton}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ) : (
          <Button onClick={handleClickSignButton}>
            Sign
          </Button>
        )}
      </Flex>
    </>
  );
};

export default SignatureBlock;
