// External Dependencies
import { FC } from 'react';
import {
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';
import { humanizeDateShort } from 'utils';
import District from 'components/shared/District';

// Local Dependencies
import ResendEmailButton from './ResendEmailButton';

// Local Variables
const useStyles = makeStyles({
  listItem: {
    justifyContent: 'space-between',
  },
  section: {
    marginBottom: 16,
    marginTop: 16,
  },
});

// Component Definition
const InvitationList: FC = () => {
  const classes = useStyles();

  return (
    <District>
      {({ district }) => {
        const { invitations } = district;

        const pendingInvitations = invitations.filter((invitation) =>
          invitation.accepted === null);

        return pendingInvitations.length ? (
          <>
            <Subtitle>
              Pending Director Invitations
            </Subtitle>
            <EnhancedCard>
              <List>
                {pendingInvitations.map((invitation) => (
                  <ListItem
                    className={classes.listItem}
                    key={invitation.createdAt}
                  >
                    <ListItemText
                      primary={invitation.recipientEmail}
                      secondary={`Sent on ${humanizeDateShort(invitation.createdAt)}`}
                    />
                    <ResendEmailButton invitationId={invitation.id} />
                  </ListItem>
                ))}
              </List>
            </EnhancedCard>
          </>
        ) : null;
      }}
    </District>
  );
};

export default InvitationList;
