// Allow IE 11 polyfills to run
import 'react-app-polyfill/ie11';

// wysiwyg css
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ReactDOM from 'react-dom';

// Internal Dependencies
import './index.css';
import * as serviceWorker from './registerServiceWorker';
import App from './App';

// Local Variables
const rootStyles = { height: '100%' };

// Render the app
ReactDOM.render(
  <div style={rootStyles}>
    <App />
  </div>,
  document.getElementById('root'),
);

serviceWorker.unregister();
