// External Dependencies
import {
  Box,
  Container,
  Grid,
} from '@mui/material';
import { FC } from 'react';
import {
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from '@presto-assistant/api_types/utils';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import BookIcon from '@mui/icons-material/Book';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';

// Internal Dependencies
import {
  DynamicFormFields,
  FormActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { hasPermission, isDistrictAdmin } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';

// Local Dependencies
import {
  districtLibraryFormSchema,
  libraryFormSchema,
} from './schema';
import { useGetGradeLevelSelectOptions } from './data';
import LibraryAdditionalFormFields from './LibraryAdditionalFormFields';
import LibraryInfoFormFields from './LibraryInfoFormFields';
import ShowLibraryAdditionalData from '../Show/ShowLibraryAdditionalData';
import ShowLibraryData from '../Show/ShowLibraryData';

// Local Typings
interface Props {
  canUseDynamicFields?: boolean;
  isAdmin?: boolean;
  libraryItem?: GQL.ILibraryItem;
  onSubmit?: (values: GQL.ICreateLibraryItemInput | GQL.IUpdateLibraryItemInput) => Promise<void>;
  readOnly?: boolean;
  title: string;
}
export interface LibraryFormValues extends GQL.ICreateLibraryItemInput {
  orgTypeId?: string;
  organizationId?: string;
  systemBarcode?: string;
}
export interface AdminLibraryFormValues extends LibraryFormValues {
  organizationId: string;
}

// Local Variables
const StyledContainer = styled(Container)({
  width: 512,
});

// Component Definition
const LibraryForm: FC<Props> = ({
  canUseDynamicFields,
  isAdmin,
  libraryItem,
  onSubmit,
  readOnly,
  title,
}) => {
  const canEditLibrary = useSelector(hasPermission('library', 'edit'));
  const isDFA = useSelector(isDistrictAdmin);

  const canEdit = isDFA || canEditLibrary;

  const libraryParams = useSelector(tableQueryParams('libraryItems'));

  const handlePressCancelOrBackButton = () => {
    navigate(`/${PATHS.LIBRARY}${libraryParams}`);
  };

  const {
    data: gradeLevelData,
  } = useGetGradeLevelSelectOptions();

  // If readonly view, we need to make sure the libraryItem data exists
  // We also will not render the form until the select options arrive
  if ((readOnly && !libraryItem)
    || (!gradeLevelData)) {
    return null;
  }

  const initialValues: LibraryFormValues = {
    ...(canUseDynamicFields ? applyDynamicFields(libraryItem) : {}),
    accompaniment: libraryItem?.accompaniment ?? '',
    arranger: libraryItem?.arranger ?? '',
    author: libraryItem?.author ?? '',
    categoryId: libraryItem?.categoryId ?? '',
    comments: libraryItem?.comments ?? '',
    composer: libraryItem?.composer ?? '',
    conditionId: libraryItem?.conditionId ?? '',
    gradeLevelId: libraryItem?.gradeLevel?.id ?? '',
    hasAccompaniment: libraryItem?.hasAccompaniment ?? null,
    instrumentationId: libraryItem?.instrumentationId ?? '',
    isMajorWork: libraryItem?.isMajorWork ?? null,
    isOutOfPrint: libraryItem?.isOutOfPrint ?? null,
    isSolo: libraryItem?.isSolo ?? null,
    language: libraryItem?.language ?? '',
    lastPerformance: libraryItem?.lastPerformance ?? '',
    majorWork: libraryItem?.majorWork ?? '',
    number: libraryItem?.number ?? '',
    numberOfCopies: libraryItem?.numberOfCopies ?? null,
    orgTypeId: libraryItem?.organization?.organizationType?.id ?? '1',
    period: libraryItem?.period ?? '',
    priceInCents: libraryItem?.priceInCents != null
      ? convertCentsToDollars(Number(libraryItem.priceInCents))
      : undefined,
    publisher: libraryItem?.publisher ?? '',
    stateCode: libraryItem?.stateCode ?? '',
    systemBarcode: libraryItem?.systemBarcode ?? '',
    title: libraryItem?.title ?? '',
    year: libraryItem?.year ?? undefined,
  };

  const adminInitialValues: AdminLibraryFormValues = {
    ...initialValues,
    organizationId: libraryItem?.organization?.id ?? '',
  };

  const handleFormikSubmit = async (
    values: LibraryFormValues | AdminLibraryFormValues,
    formikProps: FormikHelpers<GQL.ICreateLibraryItemInput>,
  ) => {
    const { setSubmitting } = formikProps;

    // We send in `null` for values if the user didn't provide/update them
    const updatedValues: LibraryFormValues | AdminLibraryFormValues = { ...values };

    if (!values.arranger) {
      updatedValues.arranger = null;
    }

    if (!values.categoryId) {
      updatedValues.categoryId = null;
    }

    if (!values.conditionId) {
      updatedValues.conditionId = null;
    }

    if (!values.gradeLevelId) {
      updatedValues.gradeLevelId = null;
    }

    if (!values.instrumentationId) {
      updatedValues.instrumentationId = null;
    }

    if (!values.number) {
      updatedValues.number = null;
    }

    if (!values.year) {
      updatedValues.year = null;
    }

    if (values.priceInCents) {
      updatedValues.priceInCents = convertDollarsToCents(Number(values.priceInCents));
    } else if ((values as any).priceInCents === '') {
      updatedValues.priceInCents = null;
    }

    // The user is not allowed to edit the system barcode
    delete updatedValues.systemBarcode;

    await onSubmit?.(updatedValues);

    setSubmitting(false);
  };

  return (
    <Formik<LibraryFormValues | AdminLibraryFormValues>
      enableReinitialize
      initialValues={isAdmin ? adminInitialValues : initialValues}
      onSubmit={handleFormikSubmit}
      validationSchema={isAdmin
        ? districtLibraryFormSchema
        : libraryFormSchema}
    >
      {({
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => {
        const isFormTouched = Object.keys(touched).length > 0;

        return (
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <StyledContainer>
                <Box mb={2}>
                  <ShowCard
                    canEdit={canEdit}
                    icon={BookIcon}
                    readOnly={readOnly}
                    title={title}
                  >
                    {readOnly && libraryItem ? (
                      <ShowLibraryData
                        isAdmin={isAdmin}
                        libraryItem={libraryItem}
                        organizationId={isAdmin ? values.organizationId : undefined}
                      />
                    ) : (
                      <LibraryInfoFormFields
                        isAdmin={Boolean(isAdmin)}
                        orgTypeId={values.orgTypeId}
                        organizationId={isAdmin ? values.organizationId : undefined}
                      />
                    )}
                  </ShowCard>
                </Box>

                <Box mb={2}>
                  <ShowCard
                    canEdit={canEdit}
                    icon={InfoIcon}
                    readOnly={readOnly}
                    title="Additional Information"
                  >
                    {readOnly && libraryItem ? (
                      <ShowLibraryAdditionalData libraryItem={libraryItem} />
                    ) : (
                      <LibraryAdditionalFormFields />
                    )}
                  </ShowCard>
                </Box>

                <DynamicFormFields
                  isAdmin={Boolean(isAdmin)}
                  item={libraryItem}
                  organizationTypeId={isAdmin ? values.orgTypeId : undefined}
                  showCardProps={{
                    canEdit: canEditLibrary,
                    readOnly,
                  }}
                  tableRef="library_items"
                />
              </StyledContainer>
            </Grid>

            {!readOnly && (
              <FormActions
                context="Library Item"
                isEditing={!!libraryItem}
                isFormTouched={isFormTouched}
                isSubmitting={isSubmitting}
                onPressCancelOrBackButton={handlePressCancelOrBackButton}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default LibraryForm;
