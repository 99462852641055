// External Dependencies
import { Button, Collapse } from '@mui/material';
import styled from 'styled-components';

// Local Dependencies
import {
  EditModeText,
  EnhancedAlert,
} from '..';

// Local Typings
 interface Props {
  isEditMode: boolean;
  onDisableEditMode: () => void;
}

// Local Variables
const StyledEnhancedAlert = styled(EnhancedAlert)({
  // We adjust the vertical padding to shrink the toolbar and line up the text
  '.MuiAlert-message': {
    padding: '6px 0',
  },
});

// Component Definition
const EditModeToolbar: React.FunctionComponent<Props> = ({
  isEditMode,
  onDisableEditMode,
}) => (
  <Collapse in={isEditMode}>
    <StyledEnhancedAlert
      action={(
        <Button
          color="primary"
          key="done-editing-button"
          onClick={onDisableEditMode}
          size="small"
          variant="outlined"
        >
          Done Editing
        </Button>
      )}
    >
      <EditModeText />
      Update data directly in the table.
    </StyledEnhancedAlert>
  </Collapse>
);

export default EditModeToolbar;
