// External Dependencies
import { convertCentsToDollars } from '@presto-assistant/api_types/utils';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { displayCell } from 'components/shared/TableV2';
import { formatDate, getDateFromInput } from 'utils/lib/date_helpers';
import { renderMoneyCell } from 'components/shared/TableDataGrid/helpers';

// Local Typings
interface ColumnArguments<T> {
  field: keyof T;
  headerName: string;
  hideable?: boolean;
  minWidth?: number;
}

export function currencyFromCentsColumn<T>(args: ColumnArguments<T>): DataGridColDef<T> {
  return {
    field: args.field,
    headerName: args.headerName,
    hideable: args.hideable,
    minWidth: args.minWidth ?? 160,
    renderCell: (params) => {
      if (params.value === null) {
        return null;
      }

      return renderMoneyCell(params.value);
    },
    type: 'number',
    valueGetter: (params) => {
      const value = params.row[args.field] as unknown as any;

      if (Array.isArray(value) || value === null || value === '' || !Number.isFinite(Number(value))) {
        return '—';
      }

      return displayCell(convertCentsToDollars(Number(value)));
    },
    width: 160,
  };
}

export function dateColumn<T>(args: ColumnArguments<T>): DataGridColDef<T> {
  return {
    field: args.field,
    headerName: args.headerName,
    hideable: args.hideable,
    minWidth: args.minWidth ?? 160,
    renderCell: ({ row }) => displayCell(row[args.field] as unknown as string, { format: 'date' }),
    type: 'date',
    valueFormatter: (params) => displayCell(params.value, { format: 'date' }),
    valueGetter: (params) => {
      return formatDate(params.value);
    },
    width: 160,
  };
}

export function dateTimeColumn<T>(args: ColumnArguments<T>): DataGridColDef<T> {
  return {
    field: args.field,
    headerName: args.headerName,
    hideable: args.hideable,
    minWidth: args.minWidth ?? 200,
    renderCell: ({ row }) => displayCell(row[args.field] as unknown as string, { format: 'datetime' }),
    type: 'dateTime',
    valueFormatter: (params) => displayCell(params.value, { format: 'datetime' }),
    valueGetter: (params) => {
      return getDateFromInput(params.value);
    },
    width: 200,
  };
}
