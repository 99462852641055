// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import {
  useQueryEnhanced,
} from 'utils/lib/graphql';

// Local Typings
interface FinancialFundraiserCreditIndexResponse {
  financialFundraiserCreditsIndex: GQL.IFinancialFundraiserCreditIndexItemAll;
}

// GraphQL Document Definitions
const GET_FINANCIAL_FUNDRAISER_CREDIT = gql`
  query FinancialFundraiserCredit($id: ID!) {
    financialFundraiserCredit(id: $id) {
      amountInCents
      financialAccount {
        id
        label
      }
      id
      items {
        financialItem {
          id
          label
          priceInCents
        }
        priority
      }
      label
      memberIdsAssigned
      schoolYearEnding
    }
  }
`;

const GET_FINANCIAL_FUNDRAISER_CREDITS_INDEX = gql`
  query FinancialFundraiserCreditsIndex(
    $queryParams: IndexQueryParams
  ) {
    financialFundraiserCreditsIndex(
      queryParams: $queryParams
    ) {
      data {
        amountInCents
        assignedCount
        financialAccountId
        financialAccountLabel
        id
        label
        schoolYearEnding
      }
      fullCount
    }
  }
`;

// Query Hook Definitions
export const useGetFinancialFundraiserCredit = (
  id: string,
) =>
  useQueryEnhanced<{
    financialFundraiserCredit: GQL.IFinancialFundraiserCredit
  }>(
    GET_FINANCIAL_FUNDRAISER_CREDIT,
    {
      variables: { id },
    },
  );

export const useGetFinancialFundraiserIndex = () => usePaginatedListQuery<
  FinancialFundraiserCreditIndexResponse,
  GQL.IFinancialFundraiserCreditIndexItem
  >({
    dataSelector: (res) => res.financialFundraiserCreditsIndex.data,
    fullCountSelector: (res) => res.financialFundraiserCreditsIndex.fullCount,
    query: GET_FINANCIAL_FUNDRAISER_CREDITS_INDEX,
  });
