// External Dependencies
import { navigate } from '@reach/router';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { stringifyQueryParams } from './stringify_query_params';
import parseSearch from './parse_search';

// Local Typings
export interface RedirectUserOptions {
  ignoreQueryString?: boolean;
}

export const getSearchWithRedirectUrl = () => {
  const searchParams = parseSearch(window.location.search);

  const redirectUrl = searchParams.redirectUrl ?? window.location.pathname;

  delete searchParams.redirectUrl;

  const existingParsedQueryString = stringifyQueryParams(searchParams);

  const queryParams = {
    ...(redirectUrl && {
      redirect_url: redirectUrl,
    }),
    ...(existingParsedQueryString && {
      search_params: encodeURIComponent(existingParsedQueryString),
    }),
  };

  return stringifyQueryParams(queryParams);
};

export const redirectToParams = () => {
  const { search } = window.location;

  const queryParams = parseSearch(search);

  if (queryParams.redirectUrl) {
    const queryString = queryParams.searchParams
      ? `?${queryParams.searchParams}`
      : '';

    navigate(`${queryParams.redirectUrl}${queryString}`);
  }
};

export const redirectUser = (
  currentOrgId: string | null,
  hasDistrict: boolean | null,
  alertFlagId: number | null,
  nextOnboardingStage?: GQL.IOnboardingStage,
  options: RedirectUserOptions = {},
  isAdminInCurrentOrg?: boolean,
) => {
  const loggedInAsDistrictAdmin = hasDistrict && !currentOrgId;
  const hasCurrentOrg = !!currentOrgId;

  const isCurrentlyInOnboarding = window.location.pathname.includes(PATHS.ONBOARDING);

  if (nextOnboardingStage) {
    let queryString: string;

    if (options.ignoreQueryString) {
      queryString = '';
    } else if (isCurrentlyInOnboarding) {
      queryString = window.location.search;
    } else {
      queryString = `?${getSearchWithRedirectUrl()}`;
    }

    if (alertFlagId) {
      return navigate(`/${PATHS.RELATIONSHIPS}/new`);
    }

    return navigate(`/${PATHS.ONBOARDING}/${nextOnboardingStage.name}${queryString}`);
  }

  const hasRedirectUrl = ['redirect_url', 'redirectUrl'].some((name) => window.location.search.includes(name));

  // If the current user if a director or assistant director/staff and they are
  //  finishing up all of the onboarding steps, then we want to send them to
  //  the Getting Started page instead of the redirect url to Dashboard
  if (isAdminInCurrentOrg && isCurrentlyInOnboarding) {
    return navigate(`/${PATHS.GETTING_STARTED}`);
  }

  if (hasRedirectUrl) {
    return redirectToParams();
  }

  if (loggedInAsDistrictAdmin) {
    return navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.DASHBOARD}`);
  }

  if (hasCurrentOrg) {
    return navigate(`/${PATHS.DASHBOARD}`);
  }

  return navigate('/');
};
