// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import {
  useQueryEnhanced,
} from 'utils/lib/graphql';

// Local Typings
export const GET_FINANCIAL_TRANSACTION_REPORT = gql`
  query FinancialTransactionReport {
    financialTransactionReport {
      createdAt
      createdByMemberFirstName
      createdByMemberId
      createdByMemberLastName
      creditAppliedInCents
      groupedTransactionTotalInCents
      groupedTransactionTotalPlusCreditAppliedInCents
      itemLabel
      itemSchoolYearEnding
      memberFirstName
      memberId
      memberLastName
      paymentAmountInCents
      paymentDate
      paymentId
      paymentType
      stripeChargeId
      stripePaymentId
      stripePaymentIntentId
      stripeTransferId
      totalInCents
      transactionId
      type
    }
  }
`;

export const useGetFinancialTransactionReport = () =>
  useQueryEnhanced<{
    financialTransactionReport: GQL.IFinancialTransactionReportItem[]
  }>(
    GET_FINANCIAL_TRANSACTION_REPORT,
  );
