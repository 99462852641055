// External Dependencies
import {
  Box,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import {
  EditorState,
  convertToRaw,
} from 'draft-js';
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { alpha } from '@mui/material/styles';
import { navigate } from '@reach/router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import draftToHtml from 'draftjs-to-html';
import isEqual from 'lodash.isequal';
import styled from 'styled-components';

// Internal Dependencies
import {
  AddButton,
  Checkbox,
  ConfirmationDialog,
  DialogPeoplePicker,
  EnhancedAlert,
  EnhancedCard,
  Flex,
  SplitButton,
} from 'components/shared';
import { EMAIL, ONE_MB } from 'utils/constants';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { clearRecipients, removeRecipient, updateRecipients } from 'state/ui/emailNew/actions';
import { formatDateTime } from 'utils';
import { hasPermission } from 'state/self/selectors';
import { htmlToDraftBlocks } from 'components/shared/WysiwygEditor';
import { isOpen } from 'state/ui/peoplePickerDialog/selectors';
import { openDialogSuccess } from 'state/ui/successDialog/actions';
import { open as openPeoplePickerDialog } from 'state/ui/peoplePickerDialog/actions';
import { recipientIds as recipientIdsSelector } from 'state/ui/emailNew/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import {
  useAddAttachmentToEmailDraftMutation,
  useCreateAttachmentPresignedUrl,
  useDiscardEmailDraft,
  useRemoveAttachmentFromEmailDraftMutation,
  useScheduledSendEmail,
  useSendEmail,
  useSubmitFeedback,
  useUpdateEmailDraft,
  useUpdateEmailDraftRecipients,
} from 'gql/mutations';
import {
  useGetAllCcEmails,
  useGetEmail,
  useGetGettingStartedCounts,
  useGetOrganization,
  useGetSelf,
  useGetUsersByIds,
} from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import calendarSuccessAnimationData from 'components/shared/Lottie/lottieFiles/calendar-success.json';
import messageSentAnimationData from 'components/shared/Lottie/lottieFiles/message-sent.json';

// Local Dependencies
import EmailEditor, { Attachment } from './EmailEditor';
import ScheduledSendDialog from './ScheduledSendDialog';
import SuppressedEmailAlert from '../shared/SuppressedEmailAlert';

// Local Typings
interface DraftProps {
  draftId: string;
  isFeedback?: never;
}
interface FeedbackProps {
  draftId?: never;
  isFeedback: true;
}
type Props = DraftProps | FeedbackProps;

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.addButton': {
    padding: theme.spacing(0.5, 2.5),
  },
  '.cardActions': {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: 0,
  },
  '.cardContent': {
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  },
  '.chip': {
    backgroundColor: alpha(theme.palette.stripeBlue['300'], 0.2),
    margin: theme.spacing(0.5),
    position: 'relative',
    zIndex: 2000,
  },
  '.chipContainer': {
    marginRight: theme.spacing(3),
    zIndex: 1,
  },
  '.chipTooltip': {
    fontSize: '0.8rem',
  },
  '.divider': {
    margin: `0 ${theme.spacing(3)}`,
  },
  '.recipientsContainer': {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.sendButton': {
    margin: '10px 8px 10px 10px',
  },
  '.subjectContent': {
    alignItems: 'baseline',
    display: 'flex',
  },
  '.toFrom': {
    fontWeight: 500,
    paddingRight: '1rem',
  },
  overflow: 'visible',
}));

// Component Definition
const EmailComposer: FC<Props> = ({
  draftId,
  isFeedback,
}) => {
  const dispatch = useDispatch();

  const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const [sendAt, setSendAt] = useState<number | null>(null);
  const [lastChangeTime, setLastChangeTime] = useState<number | null>(Date.now());
  const [isSaving, setIsSaving] = useState(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [emailError, setEmailError] = useState<string>('');
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [isToolbarHidden, setIsToolbarHidden] = useState(false);
  const [isUploadingAttachments, setIsUploadingAttachments] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string | null>(null);
  const [subject, setSubject] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(true);
  const [onlySendToAdults, setOnlySendToAdults] = useState(false);
  const {
    handleClose: handleCloseDeleteDialog,
    handleOpen: handleOpenDeleteDialog,
    isOpen: isDeleteDialogOpen,
  } = useIsOpen();
  const {
    handleClose: handleCloseSendAtDialog,
    handleOpen: handleOpenSendAtDialog,
    isOpen: isSendAtDialogOpen,
  } = useIsOpen();

  const emailParams = useSelector(tableQueryParams('emails'));

  const attachmentInputRef = useRef<HTMLInputElement>(null);

  const canEmailMembers = useSelector(hasPermission('emailMembers', 'write'));
  const canReadUsers = useSelector(hasPermission('users', 'read'));

  const isPeoplePickerDialogOpen = useSelector(isOpen);
  const recipientIds = useSelector(recipientIdsSelector, shallowEqual);

  const handleToggleOnlySendToAdults = useCallback(() => {
    setOnlySendToAdults((x) => !x);
  }, []);

  const editorStatePlainText = editorState.getCurrentContent().getPlainText();

  const [
    updateEmailDraft,
    {
      loading: isUpdatingEmailDraft,
    },
  ] = useUpdateEmailDraft();

  useEffect(() => {
    // after 1 second, clear the lastChangeTime
    const timeout = setTimeout(() => {
      setLastChangeTime(null);
    }, 1_000);

    return () => clearTimeout(timeout);
  }, [lastChangeTime]);

  useEffect(() => {
    if (draftId && !lastChangeTime && emailDraftData?.email) {
      updateEmailDraft({
        variables: {
          id: draftId,
          input: {
            body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            onlySendToAdults,
            subject,
          },
        },
      });
    }
  //   do not need to check for editor state or subject changes
  //   when the lastChangeTime is cleared, we'll have the current form values
  //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    draftId,
    lastChangeTime,
  ]);

  const totalSize = attachments.reduce((acc, curr) => acc + curr.file.size, 0);
  const totalSizeInMb = totalSize / ONE_MB;
  const hasExceededMaxAttachmentSize = totalSizeInMb > EMAIL.MAX_ATTACHMENT_SIZE;

  const handleClearAllRecipients = () => {
    dispatch(clearRecipients());
  };

  // This fetches the data for the recipient chips
  // The user can see the full name and email address for each recipient
  const skipQuery = !recipientIds.length;
  const {
    data: userIdsData,
  } = useGetUsersByIds(
    recipientIds,
    skipQuery,
  );

  const {
    data: emailDraftData,
    loading: isEmailDraftLoading,
  } = useGetEmail(draftId);

  const {
    data: gettingStartedCountsData,
  } = useGetGettingStartedCounts();

  const hasSentAtLeastOneEmail = (gettingStartedCountsData?.dashboardMetrics.emailCount ?? 0) > 0;

  // We only want this to run when the draft first loads
  useEffect(() => {
    if (emailDraftData) {
      const { email } = emailDraftData;
      const {
        attachments: draftAttachments,
        body: draftBody,
        onlySentToAdults: draftOnlySendToAdults,
        subject: draftSubject,
      } = email;

      const draftRecipientIds = email.recipients.map((r) => r.id);
      const draftBlocks = htmlToDraftBlocks(draftBody);

      const newDraftAttachments: Attachment[] = draftAttachments.map((a) => ({
        file: { name: a.fileName } as File,
        s3Urls: {
          getUrl: a.s3Url,
          putUrl: '',
        } as Attachment['s3Urls'],
      }));

      dispatch(updateRecipients(draftRecipientIds));
      setEditorState(draftBlocks);
      setOnlySendToAdults(draftOnlySendToAdults);
      setSubject(draftSubject);
      setAttachments(newDraftAttachments);
    }
  //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    emailDraftData?.email?.id,
  ]);

  // Feedback
  const [
    submitFeedback,
    { loading: isSubmittingFeedback },
  ] = useSubmitFeedback(
    {
      onCompleted: () => {
        dispatch(addNotification('Feedback submitted successfully', 'success'));
        navigate(`/${PATHS.DASHBOARD}`);
      },
    },
  );

  const {
    data: organizationData,
  } = useGetOrganization({
    skip: isFeedback,
  });

  const {
    data,
  } = useGetSelf();

  useEffect(() => {
    if (!draftId && data?.self?.emailSignature) {
      const htmlContent = `<p>${data.self.emailSignature}</p>`;

      setEditorState(htmlToDraftBlocks(htmlContent));
    } else if (draftId && emailDraftData?.email?.body) {
      setEditorState(htmlToDraftBlocks(emailDraftData.email.body));
    } else if (draftId && data?.self?.emailSignature && !emailDraftData?.email?.body) {
      const htmlContent = `<p>${data.self.emailSignature}</p>`;

      setEditorState(htmlToDraftBlocks(htmlContent));
    }
  //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.self?.emailSignature,
    draftId,
    emailDraftData?.email?.id, // we only want this to run when the draft first loads
    organizationData?.organization?.logoUrl,
  ]);

  // When the component will unmount, we reset the recipients in the state tree
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleClearAllRecipients, []);

  const validateEmail = useCallback(() => {
    setHasAttemptedSubmit(true);

    let validationError;

    if (!isFeedback && recipientIds.length === 0) {
      validationError = 'Please add recipients';
    } else if (!subject) {
      validationError = 'Please add a subject';
    } else if (subject.length > 50) {
      validationError = 'Subject must be 50 characters long at most';
    } else if (editorStatePlainText.length === 0) {
      validationError = 'Please add content to the email';
    } else if (editorStatePlainText.length > EMAIL.MAX_CHARACTER_LENGTH) {
      validationError = `The email content must be ${EMAIL.MAX_CHARACTER_LENGTH} characters long at most`;
    } else if (hasExceededMaxAttachmentSize) {
      validationError = `The total size of attachments must be under ${EMAIL.MAX_ATTACHMENT_SIZE}MB`;
    }

    return validationError;
  }, [
    editorStatePlainText.length,
    hasExceededMaxAttachmentSize,
    isFeedback,
    recipientIds.length,
    setHasAttemptedSubmit,
    subject,
  ]);

  // If there were errors, we check to see if we should clear them
  useEffect(() => {
    if (emailError) {
      const validationError = validateEmail();

      if (!validationError) {
        setEmailError('');
      } else {
        setEmailError(validationError);
      }
    }
  }, [
    attachments,
    editorStatePlainText,
    emailError,
    hasExceededMaxAttachmentSize,
    recipientIds,
    subject,
    validateEmail,
  ]);

  const [
    createAttachmentPresignedUrl,
  ] = useCreateAttachmentPresignedUrl();

  const [
    addAttachmentToEmailDraft,
    {
      loading: isAddingAttachment,
    },
  ] = useAddAttachmentToEmailDraftMutation();

  const [
    removeAttachmentFromEmailDraft,
    {
      loading: isRemovingAttachment,
    },
  ] = useRemoveAttachmentFromEmailDraftMutation();

  const {
    data: allCcEmailsData,
  } = useGetAllCcEmails();

  const handleCloseAlert = () => {
    setIsAlertOpen(false);
  };

  const handleUpdateRecipients = (newRecipientIds: string[]) => {
    dispatch(updateRecipients(newRecipientIds));
  };

  const handleRemoveRecipient = useCallback((id: string) => {
    dispatch(removeRecipient(id));
  }, [dispatch]);

  const handleToggleToolbar = () => {
    setIsToolbarHidden(!isToolbarHidden);
  };

  const handleUpdateSubject = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastChangeTime(Date.now());
    const { value } = e.target;
    setSubject(value);
  };

  const handleEditorStateChange = (newEditorState: any) => {
    const oldHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const newHtml = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));

    if (oldHtml !== newHtml) {
      setLastChangeTime(Date.now());
    }

    setEditorState(newEditorState);
  };

  const handleOpenAttachmentInput = () => {
    if (attachmentInputRef?.current) {
      return attachmentInputRef?.current.click();
    }
    return null;
  };

  const handleUpdateAttachments = async (files: FileList) => {
    setIsUploadingAttachments(true);

    // TODO: Tell user if they already added a file

    // The incoming FileList has to be transformed into an array
    const convertedFileList = [...files];

    const newAttachments: Attachment[] = [];

    const errorAttachments: Attachment[] = [];

    const urlFetchers = convertedFileList
      // eslint-disable-next-line no-async-promise-executor
      .map((file) => new Promise<GQL.IPresignedUrl>(async (resolve, reject) => {
        try {
          const result = await createAttachmentPresignedUrl({
            variables: {
              input: {
                filename: file.name,
                filetype: file.type,
              },
            },
          });

          if (!result.data?.createAttachmentPresignedUrl) {
            throw new Error('Could not get presigned URL');
          }

          if (draftId) {
            addAttachmentToEmailDraft({
              variables: {
                input: {
                  attachmentS3Url: result.data.createAttachmentPresignedUrl.getUrl,
                  emailId: draftId,
                },
              },
            });
          }

          resolve(result.data.createAttachmentPresignedUrl);
          return;
        } catch (error) {
          reject(error);
        }
      }));

    const presignedUrls = await Promise.all(urlFetchers);

    // eslint-disable-next-line no-restricted-syntax
    for (const urls of presignedUrls) {
      const index = presignedUrls.indexOf(urls);

      const file = convertedFileList[index];

      try {
        // eslint-disable-next-line no-await-in-loop
        const res = await fetch(urls.putUrl, {
          body: file,
          method: 'PUT',
        });

        if (!res.ok) {
          throw new Error('Unable to upload file.');
        }

        newAttachments.push({
          file,
          s3Urls: urls,
        });
      } catch (error) {
        errorAttachments.push({
          file,
          s3Urls: urls,
        });
      }
    }

    const updatedAttachmentList = [
      ...attachments,
      ...newAttachments,
    ];

    setAttachments(updatedAttachmentList);

    setIsUploadingAttachments(false);

    if (errorAttachments.length > 0) {
      setUploadErrorMessage(`There was a problem uploading the following attachments: ${errorAttachments.map((attachment) => attachment.file.name).join('; ')}`);
    } else {
      setUploadErrorMessage(null);
    }
  };

  const handlePressAddRecipients = useCallback(() => {
    if (isFeedback) {
      return;
    }
    dispatch(openPeoplePickerDialog());
  }, [
    dispatch,
    isFeedback,
  ]);

  const handleClickRecipientChip = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  const handleNavigateToIndex = useCallback(() => {
    navigate(`/${PATHS.COMMUNICATION}${emailParams}`);
  }, [emailParams]);

  const [
    sendEmail,
    {
      loading: isSendingEmail,
    },
  ] = useSendEmail({
    clearCachePredicates: [
      'emailDraftsIndex',
      'emailsIndex',
      'emailScheduledIndex',
      ...!hasSentAtLeastOneEmail ? ['dashboardMetrics'] : [],
    ],
    onCompleted: () => {
      dispatch(openDialogSuccess({
        animationData: messageSentAnimationData,
        title: 'Email Sent!',
      }));
      handleNavigateToIndex();
    },
  });

  const [
    scheduledSendEmail,
    {
      loading: isSendingScheduledSendEmail,
    },
  ] = useScheduledSendEmail({
    clearCachePredicates: [
      'emailDraftsIndex',
      'emailsIndex',
      'emailScheduledIndex',
      ...!hasSentAtLeastOneEmail ? ['dashboardMetrics'] : [],
    ],
    onCompleted: () => {
      dispatch(openDialogSuccess({
        animationData: calendarSuccessAnimationData,
        title: 'Your email is scheduled!',
      }));
      handleNavigateToIndex();
    },
  });

  const handleSendEmail = useCallback(async () => {
    const validationError = validateEmail();

    if (validationError) {
      setEmailError(validationError);
    } else if (draftId) {
      sendEmail({
        variables: {
          id: draftId,
        },
      });
    }
  }, [
    draftId,
    sendEmail,
    validateEmail,
  ]);

  const handleValidateAndOpenSendAtDialog = useCallback(() => {
    const validationError = validateEmail();

    if (validationError) {
      setEmailError(validationError);
    } else {
      handleOpenSendAtDialog();
    }
  }, [handleOpenSendAtDialog, validateEmail]);

  const handleSendScheduledEmail = useCallback(async () => {
    const validationError = validateEmail();

    if (validationError) {
      setEmailError(validationError);
    } else if (draftId && sendAt) {
      scheduledSendEmail({
        variables: {
          id: draftId,
          input: {
            scheduledSendAt: (sendAt + timezoneOffset) / 1000,
          },
        },
      });
    }
  }, [
    draftId,
    scheduledSendEmail,
    sendAt,
    timezoneOffset,
    validateEmail,
  ]);

  const handleRemoveAttachments = useCallback((newAttachments: Attachment[]) => {
    setAttachments(newAttachments);

    if (draftId) {
      // attachments to remove
      const attachmentsToRemove = emailDraftData?.email.attachments.filter((attachment) =>
        !newAttachments.some((newAttachment) =>
          newAttachment.s3Urls.getUrl === attachment.s3Url)) ?? [];

      attachmentsToRemove.forEach((attachment) => {
        removeAttachmentFromEmailDraft({
          variables: {
            input: {
              attachmentId: attachment.id,
              emailId: draftId,
            },
          },
        });
      });
    }
  }, [emailDraftData, draftId, removeAttachmentFromEmailDraft]);

  const handleSubmitFeedback = async () => {
    const validationError = validateEmail();

    if (validationError) {
      return setEmailError(validationError);
    }

    const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    try {
      const input = {
        attachments,
        body,
        subject,
      };

      return await submitFeedback({
        variables: { input },
      });
    } catch (error) {
      console.error('submitFeedback : error', error);
      return setEmailError('There was an error while submitting your feedback. Please try again.');
    }
  };

  const allCcEmails = useMemo(() => {
    if (!allCcEmailsData) {
      return [];
    }

    return [...new Set([
      ...(
        organizationData?.organization?.ccDirectors
          ? allCcEmailsData.directorsIndex.data.map((director) => director.email)
          : []),
      ...allCcEmailsData.ccEmailList.map((ccEmail) => ccEmail.email),
    ])];
  }, [allCcEmailsData, organizationData]);

  const [updateEmailDraftRecipients] = useUpdateEmailDraftRecipients();

  useEffect(() => {
    if (draftId && emailDraftData) {
      const emailDraftRecipientIds = emailDraftData.email.recipients
        .map((recipient) => recipient.id);

      // if recipientIds does not match emailDraftRecipientIds, update recipientIds
      if (!isEqual(recipientIds, emailDraftRecipientIds)) {
        updateEmailDraftRecipients({
          variables: {
            id: draftId,
            input: {
              recipientIds,
            },
          },
        });
      }
    }
  }, [
    draftId,
    emailDraftData,
    recipientIds,
    updateEmailDraftRecipients,
  ]);

  const recipientsContainer = useMemo(() => (
    <CardContent className="cardContent recipientsContainer">
      <div
        className="chipContainer"
        onClick={isSaving ? undefined : handlePressAddRecipients}
        onKeyPress={undefined}
        role="button"
        tabIndex={-1}
      >
        <Typography
          className="toFrom"
          color="textSecondary"
          component="span"
        >
          To
        </Typography>

        {isFeedback
          ? (
            <Chip
              className="chip"
              label="Team Presto"
              size="small"
              variant="outlined"
            />
          )
          : (
            <>
              {userIdsData?.usersByIds.map((recipient: GQL.IUser) => {
                const {
                  email,
                  firstName,
                  id,
                  lastName,
                } = recipient;

                return (
                  <Tooltip
                    arrow
                    classes={{ tooltip: 'chipTooltip' }}
                    key={`${firstName}-${lastName}-(${id})`}
                    title={email}
                  >
                    <Chip
                      className="chip"
                      label={`${firstName} ${lastName}`}
                      onClick={handleClickRecipientChip}
                      onDelete={() => handleRemoveRecipient(id)}
                      size="small"
                      variant="outlined"
                    />
                  </Tooltip>
                );
              })}
              {recipientIds?.length ? (
                <Typography
                  color="textSecondary"
                  sx={{ marginLeft: 0.5, marginTop: 1 }}
                  variant="body2"
                >
                  ({recipientIds?.length} total)
                </Typography>
              ) : null}
            </>
          )}
      </div>

      {!isFeedback && (
        <AddButton
          disabled={isSaving}
          label="Recipients"
          onClick={handlePressAddRecipients}
          // We set min-width on this instance of the button to prevent
          //  a weird spacing issue when adding recipient chips
          sx={{ minWidth: 120 }}
        />
      )}
    </CardContent>
  ), [
    handlePressAddRecipients,
    handleRemoveRecipient,
    isFeedback,
    isSaving,
    recipientIds,
    userIdsData,
  ]);

  const [
    discardEmailDraft,
    {
      loading: isDiscardingDraft,
    },
  ] = useDiscardEmailDraft({
    onCompleted: () => {
      handleCloseDeleteDialog();

      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));

      dispatch(addNotification('Draft discarded.', 'success'));

      handleNavigateToIndex();
    },
  });

  const handleDiscard = useCallback(() => {
    if (draftId) {
      discardEmailDraft({
        variables: {
          id: draftId,
        },
      });
    }
  }, [discardEmailDraft, draftId]);

  const handleClose = useCallback(() => {
    handleCloseDeleteDialog();
  }, [handleCloseDeleteDialog]);

  const handleClickDiscard = useCallback(() => {
    handleOpenDeleteDialog();
  }, [handleOpenDeleteDialog]);

  const isRowSelectable: DataGridProProps['isRowSelectable'] = useCallback((gridRowParams) => {
    const row: GQL.IMemberIndex = gridRowParams.row as GQL.IMemberIndex;

    return Boolean(row.email?.trim());
  }, []);

  useEffect(() => {
    if (isUploadingAttachments
      || isAddingAttachment
      || isRemovingAttachment
      || isUpdatingEmailDraft) {
      setIsSaving(true);
    }
  }, [
    isAddingAttachment,
    isRemovingAttachment,
    isUploadingAttachments,
    isUpdatingEmailDraft,
  ]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // if not is saving for 1 second, set isSaving to false
    if (isSaving) {
      const timeout = setTimeout(() => {
        setIsSaving(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [isSaving]);

  if (isEmailDraftLoading) {
    return (
      <Flex
        justifyContent="center"
        marginTop={4}
      >
        <CircularProgress />
      </Flex>
    );
  }

  return (
    <>
      {canReadUsers && !isFeedback && <SuppressedEmailAlert />}

      {draftId && emailDraftData && (
        <Flex justifyContent="flex-end">
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {isSaving && (
              <CircularProgress
                size={12}
                sx={{ marginRight: 1 }}
              />
            )}
            Last saved: {formatDateTime(emailDraftData.email.lastSavedAt)}
          </Typography>
        </Flex>
      )}

      <Box mt={2}>
        <StyledEnhancedCard>
          {recipientsContainer}

          {!isFeedback && canEmailMembers && allCcEmails.length > 0 && (
            <>
              <Divider className="divider" />

              <CardContent
                className="cardContent recipientsContainer"
              >
                <div className="chipContainer">
                  <Typography
                    className="toFrom"
                    color="textSecondary"
                    component="span"
                  >
                    Cc
                  </Typography>

                  {allCcEmails.map((ccEmail: string) => (
                    <Chip
                      className="chip"
                      key={ccEmail}
                      label={ccEmail}
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </div>
              </CardContent>
            </>
          )}

          <Divider className="divider" />

          <CardContent className="cardContent subjectContent">
            <Typography
              className="toFrom"
              color="textSecondary"
              component="span"
            >
              Subject
            </Typography>
            <TextField
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  'aria-label': 'Subject',
                },
              }}
              fullWidth
              onChange={handleUpdateSubject}
              value={subject}
              variant="standard"
            />
          </CardContent>

          <Divider className="divider" />

          <CardContent className="cardContent">
            <EmailEditor
              attachments={attachments}
              editorState={editorState}
              isToolbarHidden={isToolbarHidden}
              onEditorStateChange={handleEditorStateChange}
              onUpdateAttachments={handleRemoveAttachments}
              totalSize={totalSize}
              totalSizeInMb={totalSizeInMb}
            />

            {uploadErrorMessage && (
              <EnhancedAlert severity="error">
                {uploadErrorMessage}
              </EnhancedAlert>
            )}
          </CardContent>

          {isUploadingAttachments && <LinearProgress />}

          <CardActions className="cardActions">
            <Flex
              columnGap={2}
              justifyContent="space-between"
              paddingX={3}
              width="100%"
            >
              <Flex>
                <SplitButton
                  buttons={isFeedback ? [] : [
                    {
                      label: 'Schedule Send',
                      onClick: handleValidateAndOpenSendAtDialog,
                      startIcon: <ScheduleSendIcon />,
                    },
                  ]}
                  isLoading={isSendingEmail || isSendingScheduledSendEmail || isSubmittingFeedback}
                  primaryButtonProps={{
                    disabled: isUploadingAttachments
                      || (hasAttemptedSubmit && Boolean(emailError))
                      || isSaving
                      || Boolean(lastChangeTime),
                    label: `Send ${isFeedback ? 'Feedback' : 'Email'}`,
                    onClick: isFeedback ? handleSubmitFeedback : handleSendEmail,
                  }}
                  secondaryButtonAriaLabel="More options"
                />

                {/* Temporarily hiding until this Trello card is complete https://trello.com/c/qxtv4ImX */}
                {!isFeedback && (
                  <div>
                    <input
                      disabled={isUploadingAttachments}
                      hidden
                      multiple
                      onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                        const { files } = e.target;

                        if (files) {
                          await handleUpdateAttachments(files);
                        }
                      }}
                      ref={attachmentInputRef}
                      type="file"
                      value=""
                    />

                    <IconButton
                      aria-label="Attach file"
                      disabled={isUploadingAttachments}
                      onClick={handleOpenAttachmentInput}
                      size="large"
                    >
                      <AttachFileIcon />
                    </IconButton>
                  </div>
                )}

                <IconButton
                  aria-label="Toggle format tools"
                  onClick={handleToggleToolbar}
                  size="large"
                >
                  <FormatSizeIcon />
                </IconButton>
              </Flex>

              {draftId && (
                <Flex
                  justifyContent="space-between"
                  width={{
                    sm: 'auto',
                    xs: '100%',
                  }}
                >
                  <Checkbox
                    checked={onlySendToAdults}
                    label="Only send to adult relatives"
                    name="onlySendToAdults"
                    onChange={handleToggleOnlySendToAdults}
                  />

                  <div> {/* This div helps ensure the icon button maintains its aspect ratio */}
                    <IconButton onClick={handleClickDiscard}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Flex>
              )}
            </Flex>
          </CardActions>
        </StyledEnhancedCard>

        {!isFeedback && (
          <EnhancedAlert
            isAlertOpen={isAlertOpen}
            onClose={handleCloseAlert}
            sx={{ marginY: 1 }}
          >
            All emails are sent individually and will also be automatically
            sent to a student&apos;s connected parents
          </EnhancedAlert>
        )}

        <Collapse
          in={Boolean(emailError)}
          timeout="auto"
        >
          <EnhancedAlert
            severity="error"
            sx={{ marginY: 1 }}
          >
            {emailError}
          </EnhancedAlert>
        </Collapse>
      </Box>

      <DialogPeoplePicker
        canSeeAllMembers={canEmailMembers}
        isOpen={isPeoplePickerDialogOpen}
        isRowSelectable={isRowSelectable}
        onAddSelectedIds={handleUpdateRecipients}
        preSelectedIds={recipientIds}
        tableResource="peoplePickerEmail"
        title="Update Recipients"
      />

      <ConfirmationDialog
        confirmButtonAction={handleDiscard}
        confirmButtonText="Yes, discard"
        declineButtonAction={handleClose}
        description={"Are you sure you want to discard this draft? You won't be able to recover it."}
        handleClose={handleClose}
        isSubmitting={isDiscardingDraft}
        open={isDeleteDialogOpen}
        title="Discard Draft"
      />

      <ScheduledSendDialog
        isOpen={isSendAtDialogOpen}
        isSubmitting={isSendingScheduledSendEmail}
        onClickSubmit={handleSendScheduledEmail}
        onClose={handleCloseSendAtDialog}
        sendAt={sendAt}
        setSendAt={setSendAt}
        timezoneOffset={timezoneOffset}
      />
    </>
  );
};

export default EmailComposer;
