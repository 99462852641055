// External Dependencies
import { FC, ReactElement } from 'react';
import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';

// Local Typings
interface Props {
  placement?: TooltipProps['placement'];
  size?: IconButtonProps['size'];
  tooltipTitle: string | ReactElement;
  variant: 'help' | 'info';
}

// Local Variables
const StyledTooltip = styled(Tooltip)({
  '.tooltipText': {
    fontSize: '0.8rem',
    maxWidth: 150,
    textAlign: 'center',
  },
});

// Component Definition
const HelpInfoIconButton: FC<Props> = ({
  placement = 'bottom',
  size = 'medium',
  tooltipTitle,
  variant,
}) => {
  const Icon = variant === 'help' ? HelpIcon : InfoIcon;

  return (
    <StyledTooltip
      classes={{ tooltip: 'tooltipText' }}
      placement={placement}
      title={tooltipTitle}
    >
      <IconButton size={size}>
        <Icon fontSize={size === 'medium' ? 'inherit' : 'small'} />
      </IconButton>
    </StyledTooltip>
  );
};

export default HelpInfoIconButton;
