// External Dependencies
import { FC } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { useGetLibraryItem } from 'gql/queries';
import { useUpdateLibraryItem } from 'gql/mutations';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import LibraryForm,
{
  LibraryFormValues,
} from '../shared/LibraryForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Local Variables
const navigateToShow = (id: string) => {
  navigate(`/${PATHS.LIBRARY}/${id}`);
};

// Component Definition
const LibraryEdit: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const canUseDynamicFields = useCanUseDynamicFields();

  const {
    data,
    error,
    loading,
  } = useGetLibraryItem(id!);

  const [handleUpdateLibraryItem] = useUpdateLibraryItem(
    {
      clearCachePredicates: ['libraryItem', 'libraryItemsIndex'],
      onCompleted: () => navigateToShow(id!),
    },
  );

  const handleSubmit = async (values: LibraryFormValues) => {
    const updatedValues = values;

    // The API won't like us sending in a value that doesn't exist in the model
    delete updatedValues.orgTypeId;

    await handleUpdateLibraryItem({
      variables: {
        id: id!,
        input: {
          ...updatedValues,
          ...(canUseDynamicFields
            ? applyDynamicFields(updatedValues as unknown as DynamicFields)
            : {}),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'Library Item Detail',
        path: `/${PATHS.LIBRARY}/${id}`,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <LibraryForm
        canUseDynamicFields={canUseDynamicFields}
        libraryItem={data?.libraryItem}
        onSubmit={handleSubmit}
        title="Edit Library Item"
      />
    </Page>
  );
};

export default LibraryEdit;
