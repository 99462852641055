// External Dependencies
import { FC } from 'react';
import { navigate } from '@reach/router';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import AlertButton from './AlertButton';

// Local Functions
function handleClick() {
  navigate(PATHS.END_OF_YEAR);
}

// Component Definition
const ContinueEndOfYearProcess: FC = () => (
  <AlertButton
    buttonText="Continue"
    isLoading={false}
    onClick={handleClick}
  />
);

export default ContinueEndOfYearProcess;
