// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared/index';
import { isDirector } from 'state/self/selectors';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const DialogPermissionRequired: FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const isUserDirector = useSelector(isDirector);

  const personToContact = isUserDirector
    ? 'your district administrator for Presto'
    : 'your director';

  return (
    <ConfirmationDialog
      confirmButtonAction={onClose}
      handleClose={onClose}
      hideDeclineButton
      open={isOpen}
      title="Permission Required"
      description={`You do not have the required permissions needed to perform this action. Please contact ${personToContact} to enable this permission.`}
    />
  );
};

export default DialogPermissionRequired;
