// External Dependencies
import { Box } from '@mui/material';
import {
  FC, useCallback, useState,
} from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

// Internal Dependencies
import { Container } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Dependencies
import ChecklistFormStepper from './ChecklistFormStepper';
import ChecklistInfoFormWrapper from './ChecklistInfoFormWrapper';
import EditChecklistItemsForm from '../Edit/EditChecklistItemsForm';
import EditChecklistMembersForm from '../Edit/EditChecklistMembersForm';

// Local Typings
interface RouteProps {
  id: string;
}
enum ChecklistFormStep {
  Info = 1,
  Members,
  Items,
}

// Local Variables
const getChecklistRoute = (step: number, id?: string) => {
  const basePath = `/${PATHS.CHECKLISTS}/${id}/edit`;

  if (step === ChecklistFormStep.Info) {
    return basePath;
  }

  return `${basePath}${step === 2 ? '/members' : '/items'}?step=${step}`;
};

// Component Definition
const ChecklistFormWrapper: FC<RouteComponentProps<RouteProps>> = ({
  id: checklistId,
}) => {
  const parsedSearch = useParsedSearch();

  const isEditingExistingChecklist = Boolean(checklistId);

  const [activeStep, setActiveStep] = useState<number>(parsedSearch.step || 1);

  const handleNavigateToActiveStep = useCallback((newStep: number) => {
    setActiveStep(newStep);

    if (newStep === activeStep) {
      // We won't navigate anywhere if the user presses the stepper number
      //  for the step they are currently viewing
      return;
    }

    // If the user is editing an existing checklist, the id will be in the search params
    navigate(getChecklistRoute(newStep, checklistId));
  }, [activeStep, checklistId]);

  // The order of the steps in the stepper
  const checklistFormSteps = [
    <ChecklistInfoFormWrapper checklistId={checklistId} />, // step 1
    ...(checklistId ? [
      <EditChecklistMembersForm checklistId={checklistId} />, // step 2
      <EditChecklistItemsForm checklistId={checklistId} />, // step 3
    ] : []),
  ];

  return (
    <>
      <ChecklistFormStepper
        activeStep={activeStep}
        maxAvailableStep={isEditingExistingChecklist
          ? checklistFormSteps.length - 1
          : activeStep - 1}
        onSetActiveStep={handleNavigateToActiveStep}
      />

      <Container maxWidth={600}>
        <Box my={2}>
          {checklistFormSteps[activeStep - 1]}
        </Box>
      </Container>
    </>
  );
};

export default ChecklistFormWrapper;
