// External Dependencies
import {
  Autocomplete,
  CircularProgress,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useDebounce } from 'use-debounce/lib';
import TextField from '@mui/material/TextField';

// Internal Dependencies
import { ROLES, ROLE_IDS } from 'utils/constants';
import {
  SimpleUserWithRole,
  useGetUsersTypeAhead,
} from 'gql/queries';
import { getFullName, getRoleFromRoleId } from 'utils';
import useTextField from 'hooks/useTextField';

// Local Typings
interface Props {
  disabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  onSelectUser: (user: SimpleUserWithRole | null) => any;
  // We don't care what the caller does with this
  roleId?: string;
}

// Local Variables
const getSearchContext = (roleId?: string): string => {
  switch (roleId) {
    case ROLE_IDS[ROLES.ADULT]:
      return 'Adults';
    case ROLE_IDS[ROLES.STUDENT]:
      return 'Students';
    default:
      return 'Users';
  }
};

// Component Definition
const UserTypeAhead: FC<Props> = ({
  disabled,
  hasError,
  helperText,
  onSelectUser,
  roleId,
}) => {
  const textField = useTextField('');
  const [searchValue] = useDebounce(textField.value, 500);

  const {
    data,
    loading,
  } = useGetUsersTypeAhead(searchValue, roleId);

  const isLoading = loading || searchValue.trim() !== textField.value.trim();

  const handleChange = (_event: any, user: SimpleUserWithRole | null) => {
    onSelectUser(user);
  };

  const searchContext = getSearchContext(roleId);

  return (
    <Autocomplete
      disablePortal
      disabled={disabled}
      filterOptions={(user) => user}
      getOptionLabel={(user) => `${getFullName(user)}${user.email && ` - ${user.email}`}`}
      isOptionEqualToValue={(user, value) => user.email === value.email}
      loading={isLoading}
      onChange={handleChange}
      options={data?.peoplePickerIndex.data ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textField}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          error={hasError}
          helperText={helperText}
          label={`Search ${searchContext}`}
          name={`Search ${searchContext}`}
          variant="filled"
        />
      )}
      renderOption={(props, option) => (
        <ListItem
          key={option.id}
          {...props}
        >
          <ListItemText
            primary={getFullName(option)}
            secondary={(
              <>
                {option.email}

                {!roleId
                  ? (
                    <Typography
                      component="div"
                      variant="body2"
                    >
                      {getRoleFromRoleId(option.role.id)}
                    </Typography>
                  ) : null}
              </>
            )}
            secondaryTypographyProps={{
              component: 'div',
            }}
          />
        </ListItem>
      )}
    />
  );
};

export default UserTypeAhead;
