// External Dependencies
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import { FinancialPaymentTypes } from '@presto-assistant/api_types';

// Internal Dependencies
import { convertCentsToDollars, displayPriceStringFromDollarAmount, getFullName } from 'utils';
import { useGetFinancialFee } from 'gql/queries';

// Local Typings
interface Props {
  paymentInput: GQL.ICreateFinancialPaymentInput;
}

// Component Definition
const PaymentPreviewTableRow: FC<Props> = ({
  paymentInput,
}) => {
  const {
    data: financialFeeData,
  } = useGetFinancialFee(paymentInput.financialFeeId);

  const fee = financialFeeData?.financialFee;

  return (
    <TableRow>
      <TableCell>
        {fee
          ? getFullName(fee.user)
          : <CircularProgress />}
      </TableCell>
      <TableCell>
        {fee
          ? fee?.financialItem.label
          : <CircularProgress />}
      </TableCell>
      <TableCell>
        {FinancialPaymentTypes[paymentInput.financialPaymentTypeId as unknown as number]}
      </TableCell>
      <TableCell align="right">
        {displayPriceStringFromDollarAmount(
          convertCentsToDollars(paymentInput.amountInCents),
        )}
      </TableCell>
      <TableCell align="right">
        {displayPriceStringFromDollarAmount(
          convertCentsToDollars(paymentInput.creditAppliedAmountInCents ?? 0),
        )}
      </TableCell>
    </TableRow>
  );
};

export default PaymentPreviewTableRow;
