// External Dependencies
import {
  Theme,
  ThemeOptions,
  alpha,
  createTheme,
  darken,
} from '@mui/material/styles';
import {
  amber,
  blue,
  blueGrey,
  green,
  grey,
  indigo, orange,
  red,
  teal,
} from '@mui/material/colors';
import { deepmerge } from '@mui/utils';

// Local Typings
declare module '@mui/styles' {
  interface DefaultTheme extends Theme { }
}

// Local Variables
// Colors chosen by AWC on 10/21/2018
// https://color.adobe.com/My-Color-Theme-color-theme-11565024/
const prestoPrimary = '#450291';
const prestoPrimaryDark = '#280154';
const prestoPrimaryLight = '#6102CC';
const prestoPrimaryUltraLight = '#9861DB';
const prestoSecondary = '#A3DEAA';
const prestoSecondaryDark = '#73AC7B';
const prestoSecondaryLight = '#D5FFDC';

// Borrowed from the many variations in the Stripe web site
const stripeBlue300 = '#7D96F1';
const stripeBlue400 = '#6974DE';
const stripeBlue500 = '#5469D4';
const stripeBlue600 = '#2F3D89';
const stripeBlue700 = '#2d2d54';

// Stock colors
// Nice color names courtesy of https://coolors.co
const cultured = '#f7fafc';
const ghostWhite = '#F3F5FA';
const powderWhite = '#fdfffc';
const languidLavender = '#e0d6eb';
const blueCrayola = '#3772FF';

// Build the theme
const baseThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      /* eslint-disable sort-keys */
      xs: 0,
      sm: 414,
      md: 768,
      lg: 1280,
      xl: 1920,
      /* eslint-enable sort-keys */
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: blueGrey[600],
          },
          color: grey[500],
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: grey[100],
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
  },
  palette: {
    alertColors: {
      error: {
        border: red[500],
        icon: red[700],
      },
      info: {
        border: blue[500],
        icon: blue[700],
      },
      warning: {
        border: amber[500],
        icon: amber[700],
      },
    },
    appBackground: 'lavender',
    dashboardOrgName: blue[50],
    disabledButtonGradient: `linear-gradient(160deg, ${grey['300']} 40%, ${grey['400']} 95%)`,
    dropzoneBorder: green[200],
    dropzoneHover: green[50],
    files: {
      avatar: teal[600],
      fileSize: teal[700],
      folder: blue[200],
      image: teal[300],
    },
    history: {
      showMoreButton: blueCrayola,
      timelineDot: alpha(blueCrayola, 0.8),
    },
    inventory: {
      dataCard: indigo['100'],
    },
    link: languidLavender,
    loadingBackground: 'rgba(255, 255, 255, 0.7)',
    loginJoinButton: blue[700],
    logo: prestoPrimary,
    mainContentBackground: ghostWhite,
    navMenuItemIcon: green['700'],
    navMenuItemText: green['900'],
    onboarding: {
      submitButton: `linear-gradient(160deg, ${prestoPrimary} 30%, ${prestoPrimaryDark} 90%)`,
    },
    prestoPrimary,
    prestoPrimaryDark,
    prestoPrimaryLight,
    prestoSecondary,
    prestoSecondaryDark,
    prestoSecondaryLight,
    prestoSuccess: green[600],
    prestoWarning: amber[700],
    primary: {
      dark: prestoPrimaryDark,
      light: prestoPrimaryLight,
      main: prestoPrimary,
    },
    relationships: {
      avatar: alpha(stripeBlue300, 0.2),
      icon: grey['600'],
    },
    secondary: green,
    showPage: {
      groups: alpha(stripeBlue300, 0.3),
      people: indigo[300],
    },
    simpleBackground: cultured,
    skewedBackgroundBottom: powderWhite,
    stripeBlue: {
      300: stripeBlue300,
      400: stripeBlue400,
      500: stripeBlue500,
      600: stripeBlue600,
      700: stripeBlue700,
    },
    submitButtonGradient: `linear-gradient(160deg, ${prestoPrimaryLight} 30%, ${prestoPrimary} 80%)`,
  },
};

const darkThemeOptions = deepmerge(baseThemeOptions, {
  components: {
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: darken(red[900], 0.5),
        },
        standardInfo: {
          backgroundColor: darken(blue[900], 0.5),
        },
        standardSuccess: {
          backgroundColor: darken(green[900], 0.5),
        },
        standardWarning: {
          backgroundColor: darken(orange[900], 0.5),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlinedSizeLarge: {
          borderColor: prestoPrimaryUltraLight,
          color: prestoPrimaryUltraLight,
        },
        outlinedSizeMedium: {
          borderColor: prestoPrimaryUltraLight,
          color: prestoPrimaryUltraLight,
        },
        outlinedSizeSmall: {
          borderColor: prestoPrimaryUltraLight,
          color: prestoPrimaryUltraLight,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: blueGrey[300],
          },
          color: grey[500],
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: grey[900],
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(255, 255, 255, 0.6)',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: grey[500],
        },
      },
    },
  },
  palette: {
    appBackground: '#010101',
    background: {
      default: '#121519',
      paper: '#121519',
    },
    common: {
      black: '#fff',
      white: '#111',
    },
    dashboardOrgName: stripeBlue600,
    grey: {
      50: grey[900],
      // eslint-disable-next-line sort-keys
      100: grey[800],
      200: grey[700],
      300: grey[600],
      400: grey[500],
      500: grey[400],
      600: grey[300],
      700: grey[200],
      800: grey[100],
      900: grey[50],
    },
    link: prestoPrimaryUltraLight,
    loadingBackground: 'rgba(0, 0, 0, 0.7)',
    logo: grey[200],
    mainContentBackground: '#060606',
    mode: 'dark',
    navMenuItemIcon: green['300'],
    navMenuItemText: green['200'],
    prestoPrimary: prestoPrimaryLight,
    prestoPrimaryDark: prestoPrimaryUltraLight,
    prestoPrimaryLight: prestoPrimaryUltraLight,
    prestoSecondary,
    prestoSecondaryDark: prestoSecondaryLight,
    prestoSecondaryLight: prestoSecondaryDark,
    prestoSuccess: green[300],
    prestoWarning: amber[300],
    primary: {
      dark: prestoPrimaryLight,
      light: prestoPrimaryDark,
      main: prestoPrimaryUltraLight,
    },
    relationships: {
      avatar: alpha(stripeBlue300, 0.2),
      icon: grey['300'],
    },
    secondary: green,
    simpleBackground: '#111',
    skewedBackgroundBottom: grey[900],
  },
  typography: {
    allVariants: {
      color: grey[200],
    },
  },
});

export const lightTheme = createTheme(baseThemeOptions);

export const darkTheme = createTheme(darkThemeOptions);
