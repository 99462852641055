// External Dependencies
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import {
  ALERTS,
  ALERT_IDS,
} from 'utils/constants/bannerAlerts';
import { AlertButtonProps } from 'components/AlertButtons/AlertButton';
import { ContextData } from 'types/context';
import { addBannerAlert, removeBannerAlert } from 'state/bannerAlerts/actions';
import {
  bannerAlertItems as bannerAlertItemsSelector,
  hasBannerAlerts as hasBannerAlertsSelector,
} from 'state/bannerAlerts/selectors';
import { checkForStripeId } from 'utils';
import { getLocalStorageItem } from 'utils/lib/local_storage';
import { hasFeatureFlag, hasPermission, isDirector } from 'state/self/selectors';
import { updateDarkMode } from 'state/ui/quickSettingsMenu/actions';
import { useGetOrganization } from 'gql/queries';

// Local Typings
interface Props {
  self: GQL.ISelf;
}

// Component Definition
const AlertHandler: FC<Props> = ({ self }) => {
  const dispatch = useDispatch();

  const bannerAlertItems = useSelector(bannerAlertItemsSelector);
  const hasBannerAlerts = useSelector(hasBannerAlertsSelector);
  const hasOnlinePaymentsFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.OnlinePayments));
  const isUserDirector = useSelector(isDirector);

  const canEmailMembers = useSelector(hasPermission('emailMembers', 'write'));

  const {
    data: orgData,
  } = useGetOrganization({
    skip: !self.currentOrgId,
    variables: { id: self.currentOrgId },
  });

  const district = orgData?.organization.district;

  const hasDistrictEnabledOnlinePayments = district?.areOnlinePaymentsEnabled;

  // Only the organization creator will see many of the alerts
  const isOrganizationCreator = self.currentOrgId && self.isCreator;

  // Check for alerts in current redux store
  const hasContinueEndOfYearProcessAlert = hasBannerAlerts
    && bannerAlertItems.find(
      (alert: ContextData.IAlertDetail) =>
        alert.id === ALERT_IDS.CONTINUE_END_OF_YEAR_PROCESS,
    );

  const hasStripeAlert = hasBannerAlerts
    && bannerAlertItems.find(
      (alert: ContextData.IAlertDetail) =>
        alert.id === ALERT_IDS.NO_STRIPE_ALERT,
    );

  const hasGCalAlert = hasBannerAlerts
    && bannerAlertItems.find(
      (alert: ContextData.IAlertDetail) =>
        alert.id === ALERT_IDS.NO_G_CAL_ALERT,
    );

  // Check to see if the user has viewed the specific alerts on this browser
  const currentLocalStorageAlertValue = getLocalStorageItem('viewedAlerts');

  const hasViewedEndOfYearProcessAlert = currentLocalStorageAlertValue?.split(',')
    .includes(ALERT_IDS.CONTINUE_END_OF_YEAR_PROCESS);
  const hasViewedNoStripeAlert = currentLocalStorageAlertValue?.split(',')
    .includes(ALERT_IDS.NO_STRIPE_ALERT);
  const hasViewedGoogleCalAlert = currentLocalStorageAlertValue?.split(',')
    .includes(ALERT_IDS.NO_G_CAL_ALERT);
  const hasViewedEmailSignatureAlert = currentLocalStorageAlertValue?.split(',')
    .includes(ALERT_IDS.EMAIL_SIGNATURE_ALERT);
  const hasViewedEmailDeliveryInsightsAlert = currentLocalStorageAlertValue?.split(',')
    .includes(ALERT_IDS.EMAIL_DELIVERY_INSIGHTS_ALERT);

  // Logic for showing the EOY process alert
  const showEndOfYearProcessAlert = !hasContinueEndOfYearProcessAlert
    && !hasViewedEndOfYearProcessAlert
    && orgData?.organization.maxCompletedEndOfYearStep !== null;

  // Logic for showing the Stripe alert
  const hasStripeAccount = checkForStripeId(self);
  const showStripeAlert = hasOnlinePaymentsFeatureFlag
    && hasDistrictEnabledOnlinePayments
    && !hasStripeAlert
    && !hasViewedNoStripeAlert
    && isUserDirector
    && !hasStripeAccount;

  // Logic for showing the Google Calendar alert
  const hasGoogleCalSync = Boolean(orgData?.organization?.hasGoogleCalendar);
  const showGCalAlert = !hasGCalAlert
    && !hasViewedGoogleCalAlert
    && isUserDirector
    && !hasGoogleCalSync;

  const showEmailSignatureAlert = canEmailMembers
    && self
    && !self.emailSignature
    && new Date() < new Date(2023, 0, 1)
    && !hasViewedEmailSignatureAlert;

  const isBeforeMarchFirst = new Date() < new Date(2023, 2, 1);

  const showEmailDeliveryInsightsAlert = isUserDirector
    && !hasViewedEmailDeliveryInsightsAlert
    && isBeforeMarchFirst;

  const handleClickDismissEmailDeliveryInsightsButton = useCallback(() => {
    dispatch(updateDarkMode(true));
    dispatch(removeBannerAlert(ALERT_IDS.EMAIL_DELIVERY_INSIGHTS_ALERT));
  }, [dispatch]);

  const handleClickEmailDeliveryInsightsButtonProps = useMemo<AlertButtonProps>(() => ({
    buttonText: 'Dismiss',
    onClick: handleClickDismissEmailDeliveryInsightsButton,
  }), [handleClickDismissEmailDeliveryInsightsButton]);

  // Since we show only one banner alert at a time, a single
  //  useEffect hook can return our one banner
  useEffect(() => {
    if (!hasBannerAlerts
      && orgData
      && isOrganizationCreator) {
      if (showEndOfYearProcessAlert) {
        dispatch(addBannerAlert(ALERTS.CONTINUE_END_OF_YEAR_PROCESS));
      } else if (showEmailSignatureAlert) {
        dispatch(addBannerAlert(ALERTS.EMAIL_SIGNATURE_ALERT));
      } else if (showEmailDeliveryInsightsAlert) {
        dispatch(addBannerAlert(ALERTS.EMAIL_DELIVERY_INSIGHTS_ALERT(
          handleClickEmailDeliveryInsightsButtonProps,
        )));
      } else if (showStripeAlert) {
        dispatch(addBannerAlert(ALERTS.NO_STRIPE_ALERT));
      } else if (showGCalAlert) {
        dispatch(addBannerAlert(ALERTS.NO_G_CAL_ALERT));
      }
    }
  }, [
    dispatch,
    hasBannerAlerts,
    hasContinueEndOfYearProcessAlert,
    handleClickEmailDeliveryInsightsButtonProps,
    hasViewedEmailDeliveryInsightsAlert,
    hasViewedEndOfYearProcessAlert,
    isOrganizationCreator,
    orgData,
    showEmailDeliveryInsightsAlert,
    showEndOfYearProcessAlert,
    showGCalAlert,
    showStripeAlert,
    showEmailSignatureAlert,
  ]);

  return null;
};

export default AlertHandler;
