// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Dependencies
import {
  FRAGMENT_UNIFORM_CHECKOUT,
  UniformCheckoutsByIdResponse,
} from '../queries/uniform-checkout-queries';

// Local Typings
interface UniformCheckoutResponse {
  id: string;
  uniform: { id: string };
}

interface CreateData {
  createUniformCheckout: UniformCheckoutResponse;
}

interface CreateInput {
  checkoutDate: string;
  dueDate: string;
  uniformId: string;
  userId: string;
}

interface UpdateData {
  updateUniformCheckout: UniformCheckoutResponse;
}

interface UpdateInput {
  checkinDate?: string;
  checkoutDate: string;
  dueDate: string;
  id: string;
  uniformId: string;
  userId: string;
}

interface CheckInData {
  checkInUnform: boolean;
}

interface CheckInInput {
  uniformCheckoutId: string;
}

interface CheckoutUniformFormBarcodeResponse extends UniformCheckoutsByIdResponse {}

// Local Variables
const CREATE_UNIFORM_CHECKOUT = gql`
  mutation CreateUniformCheckout(
    $checkoutDate: Date!
    $dueDate: Date
    $uniformId: ID!
    $userId: ID!
  ) {
    createUniformCheckout(
      checkoutDate: $checkoutDate
      dueDate: $dueDate
      uniformId: $uniformId
      userId: $userId
    ) {
      id
      uniform {
        id
      }
    }
  }
`;

const UPDATE_UNIFORM_CHECKOUT = gql`
  mutation UpdateUniformCheckout(
    $checkoutDate: Date!
    $dueDate: Date
    $id: ID!
    $uniformId: ID!
    $userId: ID!
  ) {
    updateUniformCheckout(
      checkoutDate: $checkoutDate
      dueDate: $dueDate
      id: $id
      uniformId: $uniformId
      userId: $userId
    ) {
      id
      uniform {
        id
      }
    }
  }
`;

const CHECK_OUT_UNIFORM_FROM_BARCODE = gql`
  mutation CheckOutUniformFromBarcode(
    $input: CheckOutUniformFromBarcodeInput!
  ) {
    checkOutUniformFromBarcode (
        input: $input
    ) {
      id
      uniformCheckouts {
        ...UniformCheckoutFragment
      }
    }
  }
  ${FRAGMENT_UNIFORM_CHECKOUT}
`;

const CHECK_IN_UNIFORM = gql`
  mutation UpdateUniformCheckout($uniformCheckoutId: ID!) {
    checkInUniform(uniformCheckoutId: $uniformCheckoutId)
  }
`;

export const useCreateUniformCheckout = (
  options?: MutationOptions<CreateData, CreateInput>,
) => useMutationEnhanced<CreateData, CreateInput>(
  CREATE_UNIFORM_CHECKOUT,
  {
    ...options,
    clearCachePredicates: ['uniform'],
  },
);

export const useUpdateUniformCheckout = (
  options?: MutationOptions<UpdateData, UpdateInput>,
) => useMutationEnhanced<UpdateData, UpdateInput>(
  UPDATE_UNIFORM_CHECKOUT,
  {
    ...options,
    clearCachePredicates: ['uniform'],
  },
);

export const useCheckInUniform = (
  options?: MutationOptions<CheckInData, CheckInInput>,
) => useMutationEnhanced<CheckInData, CheckInInput>(
  CHECK_IN_UNIFORM,
  {
    ...options,
    clearCachePredicates: ['uniform'],
  },
);

export const useCheckOutUniformFromBarcode = (
  options?: MutationOptions<
    CheckoutUniformFormBarcodeResponse,
    GQL.ICheckOutUniformFromBarcodeOnMutationArguments
  >,
) => useMutationEnhanced<
  CheckoutUniformFormBarcodeResponse,
  GQL.ICheckOutUniformFromBarcodeOnMutationArguments
>(
  CHECK_OUT_UNIFORM_FROM_BARCODE,
  {
    ...options,
    clearCachePredicates: ['uniform'],
  },
);
