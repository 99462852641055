// External Dependencies
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { FinancialPaymentTypes } from '@presto-assistant/api_types';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  payload: GQL.IUpdateFinancialPaymentInput | null;
}

// Component Definition
const ConfirmationDialogContent: FC<Props> = ({
  payload,
}) => {
  const paymentTypeId = payload?.financialPaymentTypeId;

  const isCheckPayment = paymentTypeId === FinancialPaymentTypes.Check.toString();
  const isCCPayment = paymentTypeId === FinancialPaymentTypes.CreditCard.toString();
  const isOtherPaymentType = paymentTypeId === FinancialPaymentTypes.Other.toString();

  return (
    <>
      <Box mb={2}>
        <Typography color="textSecondary">
          Please review the following changes.
        </Typography>
      </Box>

      <ShowPageDataDisplay
        label="Amount"
        type="currency"
        value={payload?.amountInCents}
      />

      <ShowPageDataDisplay
        label="Date Paid"
        type="date"
        value={payload?.datePaid}
      />

      <ShowPageDataDisplay
        label="Payment Type"
        value={payload?.financialPaymentTypeId
          && FinancialPaymentTypes[Number(payload.financialPaymentTypeId)]}
      />

      {isCheckPayment && (
        <ShowPageDataDisplay
          label="Check Number"
          value={payload?.checkNumber}
        />
      )}

      {isCCPayment && (
        <>
          <ShowPageDataDisplay
            label="Credit Card Account Holder"
            value={payload?.creditCardAccountHolder}
          />

          <ShowPageDataDisplay
            label="Credit Card Expiration"
            value={payload?.creditCardExp}
          />

          <ShowPageDataDisplay
            label="Credit Card Last Four"
            value={payload?.creditCardLastFour}
          />
        </>
      )}

      {isOtherPaymentType && (
        <>
          <ShowPageDataDisplay
            label="Payment Type Name"
            value={payload?.otherLabel}
          />

          <ShowPageDataDisplay
            label="Reference Number"
            value={payload?.referenceNumber}
          />
        </>
      )}

      <ShowPageDataDisplay
        label="Note"
        value={payload?.note}
      />
    </>
  );
};

export default ConfirmationDialogContent;
