// External Dependencies
import { MutationEnhancedOptions, useMutationEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

// Local Typings
interface CreateFormResponseData {
  createForm: GQL.IForm;
}

interface CreateFormBlockResponseData {
  createFormBlock: GQL.IForm;
}

interface SubmitFormBlockResponseData {
  submitFormBlockResponse: GQL.IMyForm;
}

interface PublishFormResponseData {
  publishForm: GQL.IForm;
}

interface UpdateFormBlockResponseData {
  updateFormBlock: GQL.IForm;
}

interface UpdateFormDetailsResponseData {
  updateFormDetails: GQL.IForm;
}

interface UpdateFormMemberAssignmentsResponseData {
  updateFormMemberAssignments: GQL.IForm;
}

const CREATE_FORM = gql`
  mutation CreateForm(
    $input: CreateFormInput!
  ) {
    createForm(
      input: $input
    ) {
      id
    }
  }
`;

const CREATE_FORM_BLOCK = gql`
  mutation CreateFormBlock(
    $input: CreateFormBlockInput!
  ) {
    createFormBlock(
      input: $input
    ) {
      formBlocks {
        formBlockType {
          id
          label
        }
        isRequired
        label
        metadata
        position
      }
      id
    }
  }
`;

export const DELETE_FORM_BLOCK = gql`
  mutation DeleteFormBlock(
    $formBlockId: ID!
    $formId: ID!
  ) {
    deleteFormBlock(
      formBlockId: $formBlockId
      formId: $formId
    ) {
      formBlocks {
        formBlockType {
          id
          label
        }
        id
        isRequired
        label
        metadata
        position
      }
      id
    }
  }
`;

const PUBLISH_FORM = gql`
  mutation PublishForm(
    $id: ID!
  ) {
    publishForm(
      id: $id
    ) {
      id
      publishedAt
      publishedBy {
        email
        firstName
        id
        lastName
      }
    }
  }
`;

const SUBMIT_FORM_BLOCK_RESPONSE = gql`
  mutation SubmitFormBlockResponse(
    $input: SubmitFormBlockResponseInput!
  ) {
    submitFormBlockResponse(
      input: $input
    ) {
      formBlocks {
        formBlockType {
          id
          label
        }
        id
        isRequired
        label
        metadata
        position
        response
      }
      id
      title
    }
  }
`;

const UPDATE_FORM_BLOCK = gql`
  mutation UpdateFormBlockContent(
    $formBlockId: ID!
    $formId: ID!
    $input: UpdateFormBlockInput!
  ) {
    updateFormBlock(
      formBlockId: $formBlockId
      formId: $formId
      input: $input
    ) {
      formBlocks {
        formBlockType {
          id
          label
        }
        id
        isRequired
        label
        metadata
        position
      }
      id
    }
  }
`;

export const UPDATE_FORM_DETAILS = gql`
  mutation UpdateFormDetails(
    $id: ID!
    $input: UpdateFormDetailsInput!
  ) {
    updateFormDetails(
      id: $id
      input: $input
    ) {
      id
      title
      schoolYearEnding
      updatedAt
    }
  }
`;

const UPDATE_FORM_MEMBER_ASSIGNMENTS = gql`
  mutation UpdateFormMemberAssignments(
    $input: UpdateFormMemberAssignmentsInput!
  ) {
    updateFormMemberAssignments(
      input: $input
    ) {
      assignedMembers {
        email
        id
      }
      id
      title
      schoolYearEnding
      updatedAt
    }
  }
`;

export const useCreateForm = (
  options?: MutationEnhancedOptions<
    CreateFormResponseData,
    GQL.ICreateFormOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateFormResponseData,
  GQL.ICreateFormOnMutationArguments
>(
  CREATE_FORM,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
    ],
  },
);

export const useCreateFormBlock = (
  options?: MutationEnhancedOptions<
    CreateFormBlockResponseData,
    GQL.ICreateFormBlockOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateFormBlockResponseData,
  GQL.ICreateFormBlockOnMutationArguments
>(
  CREATE_FORM_BLOCK,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
    ],
  },
);

export const usePublishForm = (
  options?: MutationEnhancedOptions<
    PublishFormResponseData,
    GQL.IPublishFormOnMutationArguments
  >,
) => useMutationEnhanced<
  PublishFormResponseData,
  GQL.IPublishFormOnMutationArguments
>(
  PUBLISH_FORM,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
    ],
  },
);

export const useSubmitFormBlockResponse = (
  options?: MutationEnhancedOptions<
    SubmitFormBlockResponseData,
    GQL.ISubmitFormBlockResponseOnMutationArguments
  >,
) => useMutationEnhanced<
  SubmitFormBlockResponseData,
  GQL.ISubmitFormBlockResponseOnMutationArguments
>(
  SUBMIT_FORM_BLOCK_RESPONSE,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
    ],
  },
);

export const useUpdateFormBlock = (
  options?: MutationEnhancedOptions<
    UpdateFormBlockResponseData,
    GQL.IUpdateFormBlockOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateFormBlockResponseData,
  GQL.IUpdateFormBlockOnMutationArguments
>(
  UPDATE_FORM_BLOCK,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
    ],
  },
);

export const useUpdateFormDetails = (
  options?: MutationEnhancedOptions<
    UpdateFormDetailsResponseData,
    GQL.IUpdateFormDetailsOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateFormDetailsResponseData,
  GQL.IUpdateFormDetailsOnMutationArguments
>(
  UPDATE_FORM_DETAILS,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
    ],
  },
);

export const useUpdateFormMemberAssignments = (
  options?: MutationEnhancedOptions<
    UpdateFormMemberAssignmentsResponseData,
    GQL.IUpdateFormMemberAssignmentsOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateFormMemberAssignmentsResponseData,
  GQL.IUpdateFormMemberAssignmentsOnMutationArguments
>(
  UPDATE_FORM_MEMBER_ASSIGNMENTS,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
    ],
  },
);
