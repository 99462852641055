// External Dependencies
import {
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
} from '@mui/x-data-grid-pro';
import {
  Switch,
} from '@mui/material';
import { useMemo } from 'react';

// Internal Dependencies
import { GroupsInput } from 'pages/People/Students/All/StudentsTable/hooks';

// Local Typings
interface UseColumnsArguments {
  onPressSwitch: (
    id: string,
    shouldRetainUsers: boolean,
  ) => void;
}

// Local Variables
export const useColumns = ({ onPressSwitch }: UseColumnsArguments): GridColumns =>
  useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: 'label',
        filterOperators: [
          {
            InputComponent: GroupsInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const groupId = params.row.id;

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroups.length) {
                  return true;
                }

                return selectedGroupIds.includes(groupId);
              };
            },
            label: 'is any of',
            value: 'isAnyOf',
          },
          {
            InputComponent: GroupsInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const groupId = params.row.id;

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroups.length) {
                  return true;
                }

                return selectedGroupIds.every((id) => groupId !== id);
              };
            },
            label: 'is not',
            value: 'isNotIn',
          },
        ],
        flex: 1,
        headerName: 'Group Name',
      },
      {
        field: 'memberCount',
        flex: 0.5,
        headerName: 'Size',
      },
      {
        field: 'shouldRetainUsers',
        flex: 1,
        headerName: 'Retain Returning Members?',
        renderCell: (params: GridRenderCellParams) => {
          const {
            api: {
              state: {
                // currentSelection: { 'id': id }
                // same value for the key and the value in the object literal
                selection: currentSelection,
              },
            },
            row,
          } = params;

          const currentGroupIdsSelected: GridRowId[] | [] = Object.values(currentSelection);

          return (
            <Switch
              checked={row.shouldRetainUsers}
              color="primary"
              // The switch is disabled if the row is not selected
              disabled={!(currentGroupIdsSelected.length > 0
                && currentGroupIdsSelected.includes(row.id))}
              inputProps={{ 'aria-label': 'retain users' }}
              onChange={(event) => {
                onPressSwitch(row.id, event.target.checked);
              }}
              onClick={(event) => { event.stopPropagation(); }}
            />
          );
        },
        resizable: false,
        sortable: false,
      },
    ];

    return columns;
    // We listen for changes to returningGroups so that the
    //  `onPressSwitch` callback function has the latest values
  }, [onPressSwitch]);
