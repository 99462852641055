// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { hasPermission } from 'state/self/selectors';
import {
  useGetFinancialPaymentsIndex,
} from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import FinancialPaymentsTableZeroState from './FinancialPaymentsTableZeroState';

// Local Typings
interface Props {
  schoolYearEnding: number;
}

// Local Variables
const clickRowTo = (id: string) => `/${PATHS.FINANCIAL_PAYMENTS}/${id}`;

// Component Definition
const FinancialPaymentsTable: FC<Props> = ({
  schoolYearEnding,
}) => {
  const canWriteFinances = useSelector(hasPermission('payments', 'write'));

  const {
    data,
    isLoading,
  } = useGetFinancialPaymentsIndex(schoolYearEnding);

  const columns = useColumns(schoolYearEnding);

  return (
    <DataGridContainer>
      <TableDataGrid
        addButtonProps={canWriteFinances ? {
          label: 'Payment',
          to: `/${PATHS.FINANCIAL_PAYMENTS_NEW}`,
        } : null}
        clickRowTo={clickRowTo}
        columns={columns}
        components={{
          NoRowsOverlay: FinancialPaymentsTableZeroState,
        }}
        key={schoolYearEnding}
        loading={isLoading}
        tableResource={`financialPayments-${schoolYearEnding}`}
        rows={data}
        withSearch
      />
    </DataGridContainer>
  );
};

export default FinancialPaymentsTable;
