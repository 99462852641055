// External Dependencies
import {
  Box, CardContent, CircularProgress, Divider, IconButton,
} from '@mui/material';
import { FC, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard, ShowCardHeader, ShowPageDataDisplay } from 'components/shared';
import { convertCentsToDollars, convertDollarsToCents, displayPriceStringFromDollarAmount } from 'utils';
import { useGetStripeFeeInCents } from 'gql/queries';

// Local Dependencies
import { PaymentsFormValues } from '../PaymentTable';

// Local Typings
interface Props {
  hasFees: boolean;
  onEdit: () => void;
  paymentAmounts: PaymentsFormValues;
}

// Local Variables
const StyledStrong = styled.strong({ fontSize: '1.2em' });

const useGetSubtotalInCents = (
  paymentAmounts: PaymentsFormValues | null,
) => useMemo(
  () => {
    const subtotalAmountInDollars = paymentAmounts?.payments
      .reduce((prev, curr) => prev + Number(curr.amountInDollars), 0) ?? 0;

    return convertDollarsToCents(subtotalAmountInDollars);
  },
  [paymentAmounts],
);

export const useGetStripeFeeFromPaymentAmounts = (
  paymentAmounts: PaymentsFormValues | null,
) => {
  const subtotalAmountInCents = useGetSubtotalInCents(paymentAmounts);

  return useGetStripeFeeInCents(subtotalAmountInCents);
};

// Component Definition
const PaymentAmountsCard: FC<Props> = ({
  hasFees,
  onEdit,
  paymentAmounts,
}) => {
  const subtotalAmountInCents = useGetSubtotalInCents(paymentAmounts);

  const {
    data: stripeFeeData,
    loading: isLoadingStripeFee,
  } = useGetStripeFeeFromPaymentAmounts(paymentAmounts);

  const stripeFeeInCents = hasFees ? stripeFeeData?.stripeFeeInCents : 0;

  return (
    <EnhancedCard>
      <ShowCardHeader
        action={(
          <IconButton
            aria-label="Edit payment amount"
            onClick={onEdit}
            size="large"
          >
            <EditIcon />
          </IconButton>
        )}
        title="Payment Amount"
      />

      <CardContent>
        <Box
          alignItems="flex-end"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          {paymentAmounts.payments.map((payment) => (
            <ShowPageDataDisplay
              align="right"
              label={`${payment.userName} — ${payment.itemLabel}`}
              key={payment.financialFeeId}
              type="currency"
              value={convertDollarsToCents(payment.amountInDollars)}
            />
          ))}

          <Box width="100%">
            <Divider />
          </Box>

          <ShowPageDataDisplay
            align="right"
            label="Subtotal"
            value={(
              <strong>
                {displayPriceStringFromDollarAmount(
                  convertCentsToDollars(subtotalAmountInCents),
                )}
              </strong>
            )}
          />

          {hasFees && (
            <ShowPageDataDisplay
              align="right"
              label="Non-refundable Processing Fee"
              value={isLoadingStripeFee ? (
                <CircularProgress />
              ) : (
                <strong>
                  {displayPriceStringFromDollarAmount(
                    convertCentsToDollars(stripeFeeInCents ?? 0),
                  )}
                </strong>
              )}
            />
          )}

          <ShowPageDataDisplay
            align="right"
            label="Total Payment Amount (USD)"
            value={isLoadingStripeFee ? (
              <CircularProgress />
            ) : (
              <StyledStrong>
                {displayPriceStringFromDollarAmount(
                  convertCentsToDollars(subtotalAmountInCents + (stripeFeeInCents ?? 0)),
                )}
              </StyledStrong>
            )}
          />
        </Box>
      </CardContent>
    </EnhancedCard>
  );
};

export default PaymentAmountsCard;
