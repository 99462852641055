import { JWTPayload } from '@presto-assistant/api_types';
import { getCookieToken } from 'utils/cookies';
import { logout, refreshToken } from 'utils/api';

export const LOGOUT_TIMEOUT_LIMIT = 30;

export function parseJwt(token: string): JWTPayload {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

function getTokenThresholdInSeconds(): number {
  const thresholdInMinutes = Number(process.env.REACT_APP_TOKEN_EXP_THRESHOLD_IN_MINUTES || '10');

  return thresholdInMinutes * 60;
}

export function getTokenLifeRemainingInSeconds(): number | null {
  const token = getCookieToken();

  if (!token) {
    return null;
  }

  const nowInSeconds = Date.now() / 1000;

  const { exp } = parseJwt(token);

  const tokenLifeRemainingInSeconds = Math.floor(exp - nowInSeconds);

  return tokenLifeRemainingInSeconds;
}

export function isTokenAboutToExpire() {
  const tokenLifeRemainingInSeconds = getTokenLifeRemainingInSeconds();

  if (tokenLifeRemainingInSeconds === null) {
    return false;
  }

  if (tokenLifeRemainingInSeconds < 0) {
    logout();
    return false;
  }

  return tokenLifeRemainingInSeconds < getTokenThresholdInSeconds();
}

export function refreshTokenIfAboutToExpire() {
  const tokenLifeRemainingInSeconds = getTokenLifeRemainingInSeconds();

  if (tokenLifeRemainingInSeconds === null) {
    return;
  }

  // Log them out if the dialog opens with less time than the countdown
  if (tokenLifeRemainingInSeconds < LOGOUT_TIMEOUT_LIMIT) {
    logout();
  } else if (tokenLifeRemainingInSeconds < getTokenThresholdInSeconds()) {
    refreshToken();
  }
}
