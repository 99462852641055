// External Dependencies
import { FC, useMemo } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableRow,
} from '@mui/material';

// Internal Dependencies
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
} from 'utils';

// Local Dependencies
import { PaymentsByUserFormValues } from '../PaymentFormByUserForm';
import {
  StyledFooterCell, StyledTableCell, StyledTableHead, StyledTotalCell,
} from '../styles';
import PaymentFeeTableRow from './PaymentFeeTableRow';

// Local Typings
interface Props {
  formValues: PaymentsByUserFormValues;
  userId: string;
}

// Local Variables
const baseWidth = 100;
const columnWidths = [
  baseWidth * 1.75,
  baseWidth / 2,
  baseWidth * 1.5,
  baseWidth * 1.15,
  baseWidth * 1.5,
  baseWidth * 1.5,
  baseWidth * 1.5,
  baseWidth * 1.5,
  baseWidth * 3,
];
const totalWidth = columnWidths.reduce((prev, curr) => prev + curr, 0);

// Component Definition
const PaymentsByUserTable: FC<Props> = ({
  formValues,
  userId,
}) => {
  const { isPayingAllFees } = formValues;

  const runningTotalInCents = useMemo(
    () => formValues.payments.reduce((prev, curr) =>
      prev + Number(curr.amountInCents), 0),
    [formValues],
  );

  const runningCreditAppliedTotalInCents = useMemo(
    () => formValues.payments.reduce((prev, curr) =>
      prev + Number(curr.creditAppliedAmountInCents), 0),
    [formValues],
  );

  const totalOwedInCents = useMemo(
    () => formValues.payments.reduce((prev, curr) => prev + curr.remainingBalanceInCents, 0),
    [formValues],
  );

  const runningTotalInDollars = displayPriceStringFromDollarAmount(
    runningTotalInCents,
  );

  const runningCreditAppliedTotalInDollars = displayPriceStringFromDollarAmount(
    runningCreditAppliedTotalInCents,
  );

  const totalOwedInDollars = displayPriceStringFromDollarAmount(
    convertCentsToDollars(totalOwedInCents),
  );

  const allFeesRows = formValues.payments.map((
    payment,
    index,
  ) => (
    <PaymentFeeTableRow
      financialFeeId={payment.financialFeeId}
      isPayingAllFees={isPayingAllFees}
      // eslint-disable-next-line react/no-array-index-key
      key={`${payment.financialFeeId}-${index}-${isPayingAllFees}`}
      rowIndex={index}
      rowValues={payment}
      userId={userId}
    />
  ));

  const totalRow = (
    <TableRow>
      <StyledFooterCell />
      <StyledFooterCell />
      <StyledFooterCell />
      <StyledTotalCell align="right">
        {totalOwedInDollars}
      </StyledTotalCell>
      <StyledFooterCell>
        {runningTotalInDollars}
      </StyledFooterCell>
      <StyledTotalCell />
      <StyledTotalCell />
      <StyledFooterCell>
        {isPayingAllFees
          ? 0
          : runningCreditAppliedTotalInDollars}
      </StyledFooterCell>
      <StyledFooterCell />
    </TableRow>
  );

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table
          aria-label="Payment fees table"
          size="small"
          style={{ minWidth: totalWidth }}
        >
          <StyledTableHead>
            <TableRow>
              <StyledTableCell width={columnWidths[0]}>Fee</StyledTableCell>
              <StyledTableCell width={columnWidths[1]}>Year</StyledTableCell>
              <StyledTableCell
                align="right"
                width={columnWidths[2]}
              >
                Original Amount
              </StyledTableCell>
              <StyledTableCell
                align="right"
                width={columnWidths[3]}
              >
                Balance
              </StyledTableCell>
              <StyledTableCell width={columnWidths[4]}>
                Payment
              </StyledTableCell>
              <StyledTableCell
                width={columnWidths[5]}
              >
                Account
              </StyledTableCell>
              <StyledTableCell
                align="right"
                width={columnWidths[6]}
              >
                Credit Available
              </StyledTableCell>
              <StyledTableCell width={columnWidths[7]}>
                Credit Applied
              </StyledTableCell>
              <StyledTableCell width={columnWidths[8]}>
                Note
              </StyledTableCell>
            </TableRow>
          </StyledTableHead>

          <TableBody>
            {allFeesRows}
          </TableBody>

          <TableFooter>
            {totalRow}
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PaymentsByUserTable;
