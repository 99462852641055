// External Dependencies
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  Container,
  EnhancedAlert,
  EnhancedCard,
  OnboardingOrganizationInfoForm,
  Page,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetDistrict } from 'gql/queries';

// Local Variables
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9em',
    margin: `${theme.spacing(1.5)} auto`,
  },
  fontSize: '1em',
  margin: '16px auto',
  textAlign: 'center',
}));

const pathToAdminDashboard = `/${PATHS.DISTRICT_ADMIN}/${PATHS.DASHBOARD}`;

// Component Definition
const AdminCreateOrganization: FC = () => {
  const {
    data,
    error,
    loading,
  } = useGetDistrict();

  return (
    <Page
      backButtonProps={{
        label: 'Dashboard',
        path: pathToAdminDashboard,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <Container maxWidth={500}>
        <Subtitle>
          Create New Organization
        </Subtitle>

        <StyledCard variant="outlined">
          <CardContent>
            <StyledTypography variant="h6">Who is this for?</StyledTypography>
            <List dense>
              <ListItem>
                <ListItemText primary="• You are a college program." />
              </ListItem>
              <ListItem>
                <ListItemText primary="• The district administrator is also the director of an organization." />
              </ListItem>
            </List>
            <EnhancedAlert>
              In most cases, the directors of each organization should
              create their own {APP_NAME} organization.
            </EnhancedAlert>
          </CardContent>
        </StyledCard>

        <EnhancedCard>
          <OnboardingOrganizationInfoForm
            districtStateId={data?.district.state.id}
          />
        </EnhancedCard>
      </Container>
    </Page>
  );
};

export default AdminCreateOrganization;
