// External Dependencies
import { Box, CircularProgress, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { UserRoles } from '@presto-assistant/api_types';

// Internal Dependencies
import { EnhancedCard, TableDataGrid } from 'components/shared';
import { getFullName } from 'utils';
import { useGetAllMembers, useGetFinancialItem } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { TableRow, useColumns } from './hooks';

// Local Typings
interface Props {
  financialItemId: string;
}

// Local Variables
const getRowId = (row: TableRow) => row.memberId;

// Component Definition
const FinancialItemMemberFeesTable: FC<Props> = ({
  financialItemId,
}) => {
  const {
    data: allMembersData,
    loading: isMemberDataLoading,
  } = useGetAllMembers();

  const {
    data: financialItemData,
    loading: isFinancialItemLoading,
  } = useGetFinancialItem(financialItemId);

  const tableData = useMemo<TableRow[]>(() => {
    const feeMemberIds = financialItemData?.financialItem.feesAssigned
      .map((fee) => fee.user.id) ?? [];

    return allMembersData?.allMembers.map((member) => {
      const isFeeAssigned = feeMemberIds.includes(member.id);

      return {
        groups: member.groups,
        isFeeAssigned,
        memberEmail: member.email,
        memberId: member.id,
        memberName: getFullName(member),
        numberOfFeesAssigned: isFeeAssigned
          ? feeMemberIds.filter((memberId) => memberId === member.id).length
          : 0,
        primaryRoleLabel: member.primaryRole?.label ?? null,
        roleLabel: member.roleLabel,
      };
    }) ?? [];
  }, [
    allMembersData,
    financialItemData,
  ]);

  const isLoading = isMemberDataLoading || isFinancialItemLoading;

  const columns = useColumns();

  const studentMembers = useMemo(
    () => allMembersData?.allMembers
      .filter((member) => member.roleLabel === UserRoles[UserRoles.Student]) ?? [],
    [allMembersData],
  );

  const uniqueStudentsAssignedCount = useMemo(() => {
    const studentMemberIds = studentMembers.map((member) => member.id);
    const feeStudentMemberIds = financialItemData?.financialItem.feesAssigned
      .filter((fee) => studentMemberIds.includes(fee.user.id))
      .map((fee) => fee.user.id) ?? [];

    return new Set(feeStudentMemberIds).size;
  }, [
    financialItemData,
    studentMembers,
  ]);

  const studentCount = studentMembers.length;

  return (
    <EnhancedCard>
      <Box
        paddingLeft={2}
        paddingTop={2}
      >
        {isLoading
          ? <CircularProgress size={26} />
          : (
            <Typography
              color="textSecondary"
              gutterBottom
            >
              Assigned to {uniqueStudentsAssignedCount} of {studentCount} students
            </Typography>
          )}
      </Box>

      <DataGridContainer>
        <TableDataGrid
          columns={columns}
          getRowId={getRowId}
          loading={isLoading}
          rows={tableData}
          tableResource="financialItemMemberFees"
          withSearch
        />
      </DataGridContainer>
    </EnhancedCard>
  );
};

export default FinancialItemMemberFeesTable;
