// External Dependencies
import { FC } from 'react';

// Local Dependencies
import BasicMenuItem, { BasicMenuItemProps } from './BasicMenuItem';
import NavMenuItem, { NavMenuItemProps } from './NavMenuItem';
import RadioGroup, { RadioGroupProps } from './RadioGroup';

// Local Typings
type PropsWithType<T, P> = { id: string, props: P, type: T };

export type MenuItemProps =
  | PropsWithType<'menuItem', BasicMenuItemProps>
  | PropsWithType<'navItem', NavMenuItemProps>
  | PropsWithType<'radio', RadioGroupProps>

type Props = {
  onClose: () => void;
} & MenuItemProps;

// Component Definition
const MenuItem: FC<Props> = ({
  onClose,
  props,
  type,
}) => {
  if (type === 'navItem') {
    return (
      <NavMenuItem
        {...props as NavMenuItemProps}
        onClose={onClose}
      />
    );
  }

  if (type === 'radio') {
    return <RadioGroup {...props as RadioGroupProps} />;
  }

  if (type === 'menuItem') {
    return <BasicMenuItem {...props as BasicMenuItemProps} />;
  }

  return null;
};

export default MenuItem;
