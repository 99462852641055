// External Dependencies
import { FC } from 'react';
import { PATHS } from 'utils/constants/routes';
import { useLocation, useParams } from '@reach/router';

// Internal Dependencies
import { Page } from 'components/shared';
import { useGetDynamicField } from 'gql/queries';

// Local Dependencies
import DynamicFieldForm from '../shared/DynamicFieldForm';

// Component Definition
const DynamicFieldsEdit: FC = () => {
  const { search } = useLocation();

  const { id } = useParams();

  const {
    data,
    loading,
  } = useGetDynamicField({ id });

  const dynamicField = data?.dynamicField;

  return (
    <Page
      backButtonProps={{
        label: 'Dynamic Fields',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}${search}`,
      }}
      notFound={!loading && !dynamicField}
      isLoading={loading}
    >
      <DynamicFieldForm dynamicField={dynamicField} />
    </Page>
  );
};

export default DynamicFieldsEdit;
