import {
  TableColumn, displayCell, displayDynamicFieldCell,
} from 'components/shared/TableV2';
import { useGetDynamicFields } from 'gql/queries';
import { useMemo } from 'react';

export const useColumns = (organizationTypeId: string | undefined) => {
  const { data: dynamicFieldData } = useGetDynamicFields({
    organizationTypeId,
    tableRef: 'inventory_items',
  }, {
    skip: !organizationTypeId,
  });

  return useMemo(() => {
    const columns: TableColumn<GQL.IDistrictDeletedInventoryIndexItem>[] = [
      {
        Header: 'Category',
        accessor: (row) => displayCell(row.category?.label),
        sortBy: 'categoryLabel',
      },
      {
        Header: 'Name',
        accessor: (row) => displayCell(row.label),
        sortBy: 'label',
      },
      {
        Header: 'Assigned To',
        accessor: (row) => displayCell(row.organization?.label),
        sortBy: 'organizationLabel',
      },
      {
        Header: 'Brand',
        accessor: (row) => displayCell(row.brand),
        sortBy: 'brand',
      },
      {
        Header: 'Model',
        accessor: (row) => displayCell(row.model),
        sortBy: 'model',
      },
      {
        Header: 'Serial #',
        accessor: (row) => displayCell(row.serialNumber),
        sortBy: 'serialNumber',
      },
      {
        Header: 'Barcode',
        accessor: (row) => displayCell(row.customBarcode),
      },
      {
        Header: 'Condition',
        accessor: (row) => displayCell(row.condition?.label),
        sortBy: 'conditionLabel',
      },
      {
        Header: 'Date Deleted',
        accessor: (row) => displayCell(row.deletedAt, { format: 'datetime' }),
        sortBy: 'deletedAt',
      },
      {
        Header: 'Deleted By',
        accessor: (row) => displayCell(row.deletedBy),
        sortBy: 'deletedBy',
      },
      {
        Header: 'Deleted Note',
        accessor: (row) => displayCell(row.deletedNote),
      },
      {
        Header: 'Case #',
        accessor: (row) => displayCell(row.caseNumber),
        sortBy: 'caseNumber',
      },
      ...(dynamicFieldData?.dynamicFields ?? [])
        .map<TableColumn<GQL.IDistrictDeletedInventoryIndexItem>>((field) => ({
          Header: field.label,
          accessor: (row) => displayDynamicFieldCell(field, row),
          sortBy: field.dynamicFieldRef,
        })),
    ];

    return columns;
  }, [dynamicFieldData]);
};
