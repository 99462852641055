// External Dependencies
import { CheckCircle } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { Flex } from 'components/shared';
import { getSuccessDialogState } from 'state/ui/successDialog/selectors';

// Local Dependencies
import CloseButton from 'components/shared/SuccessDialog/CloseButton';

// Component Definition
const SuccessDialog: FC = () => {
  const { dialogProps } = useSelector(getSuccessDialogState);

  return (
    <Dialog open={dialogProps.isOpen}>
      <DialogTitle align="center">
        {dialogProps.title}
      </DialogTitle>

      <DialogContent>
        <>
          <Flex justifyContent="center">
            <CheckCircle
              color="success"
              sx={{ fontSize: 100 }}
            />
          </Flex>

          <Flex
            justifyContent="center"
            marginTop={4}
          >
            <CloseButton />
          </Flex>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessDialog;
