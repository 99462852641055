// External Dependencies
import { Divider, List } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import CertificateOutline from 'mdi-material-ui/CertificateOutline';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import HomeIcon from '@mui/icons-material/Home';
import KeyIcon from 'mdi-material-ui/Key';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import TieIcon from 'mdi-material-ui/Tie';
import WidgetsIcon from '@mui/icons-material/Widgets';

// Internal Dependencies
import { NavMenuItem } from 'components/shared';
import {
  PATHS,
  TITLES,
} from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Local Dependencies
import SideNavSubtitle from './SideNavSubtitle';

// Local Typings
interface Props {
  onLinkClick?: () => void;
}

// Component Definition
const DistrictAdminLinks: FC<Props> = ({ onLinkClick }) => {
  const directorPermissionParams = useSelector(tableQueryParams('directorPermissions'));
  const districtOrgParams = useSelector(tableQueryParams('districtOrganizations'));
  const districtInventoryParams = useSelector(tableQueryParams('districtInventoryItems'));
  const districtLibraryParams = useSelector(tableQueryParams('districtLibraryItems'));
  const districtUniformParams = useSelector(tableQueryParams('districtUniforms'));
  const dynamicFieldsParams = useSelector(tableQueryParams('dynamicFields'));
  const licensesParams = useSelector(tableQueryParams('licenses'));

  return (
    <List role="menu">
      <NavMenuItem
        icon={HomeIcon}
        onClick={onLinkClick}
        text={TITLES[PATHS.DASHBOARD]}
        to={`/${PATHS.DISTRICT_ADMIN}/${PATHS.DASHBOARD}`}
      />

      <NavMenuItem
        icon={BusinessIcon}
        match={`/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS}`}
        onClick={onLinkClick}
        text={TITLES[PATHS.ORGANIZATIONS]}
        to={`/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS}${districtOrgParams}`}
      />

      <Divider />

      <SideNavSubtitle subtitleText={TITLES[PATHS.INVENTORY]} />

      <NavMenuItem
        icon={WidgetsIcon}
        match={`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}`}
        onClick={onLinkClick}
        text={TITLES[PATHS.INVENTORY]}
        to={`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}${districtInventoryParams}`}
      />

      <NavMenuItem
        icon={LibraryBooksIcon}
        match={`/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}`}
        onClick={onLinkClick}
        text={TITLES[PATHS.LIBRARY]}
        to={`/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}${districtLibraryParams}`}
      />

      <NavMenuItem
        icon={TieIcon}
        match={`/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}`}
        onClick={onLinkClick}
        text={TITLES[PATHS.UNIFORMS]}
        to={`/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}${districtUniformParams}`}
      />

      <Divider />

      <SideNavSubtitle subtitleText={TITLES[PATHS.OTHER]} />

      <NavMenuItem
        icon={CategoryIcon}
        match={PATHS.DYNAMIC_FIELDS}
        onClick={onLinkClick}
        text={TITLES[PATHS.DYNAMIC_FIELDS]}
        to={`/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}${dynamicFieldsParams}`}
      />

      <NavMenuItem
        icon={CertificateOutline}
        match={PATHS.LICENSES}
        onClick={onLinkClick}
        text={TITLES[PATHS.LICENSES]}
        to={`/${PATHS.DISTRICT_ADMIN}/${PATHS.LICENSES}${licensesParams}`}
      />

      <NavMenuItem
        icon={KeyIcon}
        match={PATHS.DIRECTOR_PERMISSIONS}
        onClick={onLinkClick}
        text={TITLES[PATHS.DIRECTOR_PERMISSIONS]}
        to={`/${PATHS.DISTRICT_ADMIN}/${PATHS.DIRECTOR_PERMISSIONS}${directorPermissionParams}`}
      />

      <NavMenuItem
        icon={FeedbackOutlinedIcon}
        match={PATHS.FEEDBACK}
        onClick={onLinkClick}
        text={TITLES[PATHS.FEEDBACK]}
        to={`/${PATHS.DISTRICT_ADMIN}/${PATHS.FEEDBACK}`}
      />
    </List>
  );
};

export default DistrictAdminLinks;
