// External Dependencies
import { FC } from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { List } from '@mui/material';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { hasFeatureFlag } from 'state/self/selectors';
import IntegrationsCard from 'components/shared/IntegrationsCard';

// Local Dependencies
import OnlinePaymentsIntegrationSetting from './OnlinePaymentsIntegrationSetting';

// Component Definition
const DistrictIntegrationSettings: FC = () => {
  const hasOnlinePaymentsFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.OnlinePayments));

  if (!hasOnlinePaymentsFeatureFlag) {
    return null;
  }

  return (
    <IntegrationsCard>
      <List>
        <OnlinePaymentsIntegrationSetting />
      </List>
    </IntegrationsCard>
  );
};

export default DistrictIntegrationSettings;
