// External Dependencies
import { FC, useEffect, useState } from 'react';
import { FormBlockTypes, FormBlockTypesSemantic } from '@presto-assistant/api_types';
import { useFormikContext } from 'formik';
import isEqual from 'lodash.isequal';

// Internal Dependencies
import { CustomCheckbox, CustomInput, ShowPageDataDisplay } from 'components/shared';
import FormBlockTypeSelect from 'components/shared/Selectors/FormBlockTypeSelect';
import InputWithChips from 'components/shared/InputWithChips';

// Local Typings
interface Props {
hasSelectedType: boolean;
  values: FormValues;
}

export interface FormValues {
  content: string;
  formBlockTypeId: number;
  formId: string;
  isRequired: boolean;
  label: string;
  options: string[];
  position: number;
}

// Local Variables
const shouldRenderLabel = (formBlockTypeId: number) => {
  switch (formBlockTypeId) {
    case FormBlockTypes.Heading:
    case FormBlockTypes.Paragraph:
      return false;
    default:
      return true;
  }
};

// Component Definition
const NewBlockFormInputs: FC<Props> = ({
  hasSelectedType,
  values,
}) => {
  const formBlockTypeId = Number(values.formBlockTypeId);

  const [chips, setChips] = useState<string[]>([]);

  const formikContext = useFormikContext<FormValues>();

  useEffect(() => {
    if (!isEqual(chips, values.options)) {
      formikContext.setFieldValue('options', chips);
    }
  }, [chips, formikContext, values.options]);

  return hasSelectedType ? (
    <>
      <ShowPageDataDisplay
        label="Type"
        value={FormBlockTypesSemantic[formBlockTypeId]}
      />

      {shouldRenderLabel(formBlockTypeId) && (
        <CustomInput
          label="Label"
          name="label"
        />
      )}

      {formBlockTypeId === FormBlockTypes.Heading && (
        <CustomInput
          label="Heading"
          name="content"
        />
      )}

      {formBlockTypeId === FormBlockTypes.Paragraph && (
        <CustomInput
          label="Paragraph"
          multiline
          name="content"
          rows={4}
        />
      )}

      {formBlockTypeId === FormBlockTypes.Select && (
        <InputWithChips
          chips={chips}
          label="Options"
          name="options"
          onUpdateChips={setChips}
        />
      )}

      {formBlockTypeId !== FormBlockTypes.Heading
        && formBlockTypeId !== FormBlockTypes.Paragraph && (
          <CustomCheckbox
            label="Required"
            name="isRequired"
          />
      )}
    </>
  ) : (
    <FormBlockTypeSelect required />
  );
};

export default NewBlockFormInputs;
