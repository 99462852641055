// External Dependencies
import { ApolloError } from '@apollo/client';
import {
  Box,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { Form, Formik, FormikErrors } from 'formik';

// Internal Dependencies
import {
  CustomInput,
  Flex,
  SaveButton,
} from 'components/shared';
import { normalizeFormErrors } from 'utils/lib/graphql_errors';
import { trimValues } from 'utils/lib/trimValues';
import { useCheckIfAccountExists } from 'gql/mutations';

// Local Typings
export interface OnCompletedEmailStepArgs {
  doesAccountExist: boolean;
  email: string;
}
interface Props {
  initialValues: FormValues;
  onCompleted: (args: OnCompletedEmailStepArgs) => void;
  readOnly?: boolean;
}
interface FormValues {
  email: string;
}

// Component Definition
const Email: FC<Props> = ({
  initialValues,
  onCompleted,
  readOnly,
}) => {
  const [errors, setErrors] = useState<FormikErrors<FormValues> | undefined>(undefined);
  const [email, setEmail] = useState('');

  const handleCompleted = (data: { checkIfAccountExists: boolean }) => {
    const args: OnCompletedEmailStepArgs = {
      doesAccountExist: data.checkIfAccountExists,
      email,
    };

    onCompleted(args);
  };

  const handleError = (error: ApolloError) => {
    setErrors(normalizeFormErrors(error));
  };

  const [
    checkIfAccountExists,
    { loading },
  ] = useCheckIfAccountExists(
    {
      onCompleted: handleCompleted,
      onError: handleError,
    },
  );

  const handleFormikSubmit = (values: FormValues) => {
    setEmail(values.email.trim().toLowerCase());
    checkIfAccountExists({ variables: trimValues(values) as FormValues });
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialErrors={errors}
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
    >
      {({
        handleSubmit,
        values,
      }) => (
        <Form
          onSubmit={handleSubmit}
        >
          <Box m={1.5}>
            <Typography>
              Enter the email address for the new family member.
            </Typography>
          </Box>

          <CustomInput
            autoFocus
            InputProps={{ readOnly }}
            name="email"
            label="Email"
            required
          />

          {!readOnly && (
            <Flex justifyContent="flex-end">
              <SaveButton
                color="primary"
                disabled={!values.email}
                isSaving={loading}
                variant="contained"
              >
                Next
              </SaveButton>
            </Flex>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default Email;
