// External Dependencies
import {
  Box,
  Divider,
  IconButton,
  Popover,
} from '@mui/material';
import {
  FC, useState,
} from 'react';
import { useSelector } from 'react-redux';
import HelpIcon from '@mui/icons-material/Help';
import OpenInNew from '@mui/icons-material/OpenInNew';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedMenuItem,
  NavMenuItem,
} from 'components/shared';
import {
  NEW_RELEASES_HREF,
  SUPPORT_SITE_HREF,
} from 'utils/constants';
import { PATHS } from 'utils/constants/routes';
import {
  isDirector,
  isDistrictAdmin,
} from 'state/self/selectors';
import { isMobileScreenSize } from 'state/device/selectors';

// Local Variables
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: '1.25rem',
  padding: theme.spacing(1),
}));

const StyledOpenInNewTabIcon = styled(OpenInNew)({
  color: 'inherit',
  fontSize: '1rem',
  marginLeft: '0.5em',
}) as typeof OpenInNew;

const handlePressHelp = () => window.open(SUPPORT_SITE_HREF);
const handlePressUpdates = () => window.open(NEW_RELEASES_HREF);

// Component Definition
const HelpMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isDistrictAdminUser = useSelector(isDistrictAdmin);
  const isDirectorUser = useSelector(isDirector);
  const isMobileScreen = useSelector(isMobileScreenSize);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'table-settings-popover' : undefined;

  return (
    <>
      <StyledIconButton
        aria-describedby={id}
        aria-label="Help menu icon"
        onClick={handleClick}
      >
        <HelpIcon fontSize={isMobileScreen ? 'small' : 'medium'} />
      </StyledIconButton>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <EnhancedMenuItem
          onClick={handlePressHelp}
          text={(
            <Box
              alignItems="center"
              display="flex"
            >
              Help
              <StyledOpenInNewTabIcon />
            </Box>
          )}
        />

        <EnhancedMenuItem
          onClick={handlePressUpdates}
          text={(
            <Box
              alignItems="center"
              display="flex"
            >
              Updates
              <StyledOpenInNewTabIcon />
            </Box>
          )}
        />

        {(isDirectorUser || isDistrictAdminUser) && (
          <>
            <Divider />
            <NavMenuItem
              onClick={handleClose}
              text="Send Feedback to Presto"
              to={isDistrictAdminUser
                ? `/${PATHS.DISTRICT_ADMIN}/${PATHS.FEEDBACK}`
                : `/${PATHS.FEEDBACK}`}
            />
          </>
        )}
      </Popover>
    </>
  );
};

export default HelpMenu;
