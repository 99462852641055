// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  Subtitle,
} from 'components/shared';
import EmailComposer from 'pages/Communication/EmailComposer';

// Local Dependencies
import FeedbackAlert from './FeedbackAlert';

// Component Definition
const Feedback: FC = () => (
  <>
    <Subtitle>
      Let us know how it&apos;s going
    </Subtitle>

    <EmailComposer isFeedback />

    <FeedbackAlert />
  </>
);

export default Feedback;
