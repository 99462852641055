// External Dependencies
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Internal Dependencies
import { isMobileScreenSize } from 'state/device/selectors';

// Local Typings
export interface AlertButtonProps {
  buttonText?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

// Local Variables
const StyledBox = styled(Box)({
  verticalAlign: 'middle',
});

// Component Definition
const AlertButton: FC<AlertButtonProps> = ({
  buttonText = 'Sync',
  disabled,
  isLoading,
  onClick,
}) => {
  const isMobileScreen = useSelector(isMobileScreenSize);

  return isLoading ? (
    <StyledBox
      display="inline-block"
      // Vertical padding keeps the spinner the same size as the button
      py="7px"
      textAlign="center"
      width={64}
    >
      <CircularProgress
        size={20}
        thickness={6}
      />
    </StyledBox>
  ) : (
    <Button
      color="primary"
      disabled={disabled}
      onClick={onClick}
      size={isMobileScreen ? 'small' : 'medium'}
      sx={{
        whiteSpace: 'nowrap',
      }}
    >
      {buttonText}
    </Button>
  );
};

export default AlertButton;
