// External Dependencies
import { FC, useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';

// Internal Dependencies
import { useSubmitFormBlockResponse } from 'gql/mutations/form-mutations';

// Local Dependencies
import FormContent from './FormContent';

// Local Typings
interface Props {
  formAssignmentId: string;
  formBlock: GQL.IMyFormBlock;
  setIsFormDirty: (isFormDirty: boolean) => void;
}

// Local Variables
const StyledForm = styled(Form)({ width: '100%' });

// Component Definition
const MyFormBlockForm: FC<Props> = ({
  formAssignmentId,
  formBlock,
  setIsFormDirty,
}) => {
  const [
    submitFormBlockResponse,
  ] = useSubmitFormBlockResponse();

  const initialValues = useMemo<GQL.ISubmitFormBlockResponseInput>(() => {
    return {
      formAssignmentId,
      formBlockId: formBlock.id,
      response: formBlock.response ?? '',
    };
  }, [formAssignmentId, formBlock]);

  const handleFormikSubmit = useCallback((values: GQL.ISubmitFormBlockResponseInput) => {
    submitFormBlockResponse({
      variables: {
        input: {
          ...values,
          response: values.response.toString(),
        },
      },
    });
  }, [submitFormBlockResponse]);

  return (
    <Formik<GQL.ISubmitFormBlockResponseInput>
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
    >
      {({
        handleSubmit,
        values,
      }) => (
        <StyledForm onSubmit={handleSubmit}>
          <FormContent
            formBlock={formBlock}
            onFormBlockSubmit={handleFormikSubmit}
            setIsFormDirty={setIsFormDirty}
            values={values}
          />
        </StyledForm>
      )}
    </Formik>
  );
};

export default MyFormBlockForm;
