// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { pluralize } from 'utils';
import { useGetSelf } from 'gql/queries';
import { useParsedSearch } from 'hooks/useParsedSearch';
import UnclaimedMembersList from 'pages/Profile/UnclaimedMembersCard/UnclaimedMembersList';

// Local Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  StyledLink,
} from '..';
import ClaimMemberForm from './ClaimMemberForm';
import JoinOrganizationForm from './JoinOrganizationForm';
import MemberInfoFormListItem from './MemberInfoFormListItem';

// Local Typings
export type MemberInfoFormType = 'joining' | 'claiming' | 'pending';

interface Props {
  isOnboarding?: boolean;
}

// Local Variables
const StyledList = styled(List)({
  '.medium': {
    fontSize: 15,
    fontWeight: 600,
  },
});

// Component Definition
const MemberInfoForm: FC<Props> = ({
  isOnboarding = false,
}) => {
  const search = useParsedSearch();

  const { data: selfData } = useGetSelf();

  const matchedUnclaimedMembers = useMemo(
    () => selfData?.self?.matchedUnclaimedMembers ?? [],
    [selfData],
  );

  const [formType, setFormType] = useState<MemberInfoFormType>(search.formType ?? 'pending');

  const handleToggleHasMemberClaimCode = useCallback(() => {
    setFormType(formType === 'claiming' ? 'joining' : 'claiming');
  }, [formType]);

  const handleClickFormType = (newFormType: MemberInfoFormType) => () => {
    setFormType(newFormType);
  };

  const content = useMemo(() => {
    if (formType === 'pending') {
      return (
        <>
          <Typography
            component="h6"
            sx={{ marginY: 3 }}
            variant="h6"
          >
            Choose how to join your organization
          </Typography>

          <EnhancedCard>
            <StyledList disablePadding>
              <MemberInfoFormListItem
                buttonText="Member claim code"
                buttonVariant="contained"
                divider
                onClickCTA={handleClickFormType('claiming')}
                primary="Use member claim code (most common)"
                secondary={(
                  <>
                    Your director sent you a welcome email that contains a Member Claim Code like this:{' '}
                    <code className="medium">abc123</code>.
                  </>
                )}
              />

              <MemberInfoFormListItem
                buttonText="Organization code"
                buttonVariant="outlined"
                onClickCTA={handleClickFormType('joining')}
                primary="Use organization code"
                secondary={(
                  <>
                    <Typography
                      paragraph
                      variant="body2"
                    >
                      Your director asked you to join with an Organization Code like this:{' '}
                      <code className="medium">prestohsband</code>.
                    </Typography>

                    <Typography variant="body2">
                      You will be a member of the organization when the director
                      accepts your request to join and sends you a Welcome Email.
                    </Typography>
                  </>
                )}
              />
            </StyledList>
          </EnhancedCard>
        </>
      );
    }

    const hasMemberClaimCode = formType === 'claiming';

    return (
      <>
        {hasMemberClaimCode
          ? (
            <ClaimMemberForm
              isOnboarding={isOnboarding}
              onResetFormType={handleClickFormType('pending')}
            />
          )
          : (
            <JoinOrganizationForm
              isOnboarding={isOnboarding}
              onResetFormType={handleClickFormType('pending')}
            />
          )}

        <Typography sx={{ mt: 3 }}>
          {hasMemberClaimCode ? 'Do you have an organization code instead?' : 'Do you have a 6-digit member claim code instead?'}
        </Typography>

        <StyledLink onClick={handleToggleHasMemberClaimCode}>
          {hasMemberClaimCode ? 'Join with organization code' : 'Join with a member claim code'}
        </StyledLink>
      </>
    );
  }, [
    formType,
    handleToggleHasMemberClaimCode,
    isOnboarding,
  ]);

  const memberCount = matchedUnclaimedMembers.length;
  const hasUnclaimedMembers = memberCount > 0;

  return (
    <CardContent>
      {hasUnclaimedMembers && (
        <>
          <Typography
            component="h6"
            sx={{ marginBottom: 3 }}
            variant="h6"
          >
            We found {memberCount} {pluralize(memberCount, 'member')}<br />matching your email address!
          </Typography>

          <EnhancedCard sx={{ marginBottom: 4 }}>
            <UnclaimedMembersList
              shouldSwitchToMember
              unclaimedMembers={matchedUnclaimedMembers}
            />
          </EnhancedCard>
        </>
      )}

      {!hasUnclaimedMembers && (
        <>
          <EnhancedAlert
            isTip
            sx={{
              mt: 2,
              textAlign: 'left',
            }}
            title="Welcome Email"
          >
            If your director sent you a Welcome Email,
            you should enter the <strong>Member Claim Code</strong> you received in the email.
          </EnhancedAlert>

          {content}
        </>
      )}
    </CardContent>
  );
};

export default MemberInfoForm;
