// External Dependencies
import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import {
  CustomInput,
  DatePickerField,
} from 'components/shared';

// Local Typings
interface Props {
  inputSize: 'small' | 'medium';
}

// Local Variables
const useStyles = makeStyles({
  content: {
    '&:last-child': {
      paddingBottom: 16,
    },
  },
  firstCard: {
    marginBottom: 24,
  },
  input: {
    marginBottom: 16,
  },
});

// Component Definition
const InventoryPurchaseInfoFormFields: FC<Props> = ({ inputSize }) => {
  const classes = useStyles();

  return (
    <>
      <CustomInput
        label="Purchase Order Number"
        name="purchaseOrderNumber"
        rootStyles={classes.input}
        size={inputSize}
      />
      <DatePickerField
        disableFuture={false}
        label="Purchase Date"
        name="purchasedAt"
      />
      <CustomInput
        label="Purchase Value"
        name="purchaseValue"
        rootStyles={classes.input}
        size={inputSize}
        type="number"
      />
      {/* <CustomInput
          disabled
          label="Vendor"
          name="vendor"
          rootStyles={classes.input}
        /> */}
      {/* <ul>
          <li>Vendor Address: rendered by selected vendor</li>
          <li>Vendor City: rendered by selected vendor</li>
          <li>Vendor Phone: rendered by selected vendor</li>
        </ul> */}
    </>
  );
};

export default InventoryPurchaseInfoFormFields;
