// Internal Dependencies
import { State } from 'state/reducers';

// Local Dependencies
import { TableResource, TableSelection } from './actions';

// Local Variables
export const tableQueryParams = (tableResource: TableResource) =>
  (state: State): string =>
    state.table.tableParams[tableResource] ?? '';
export const tableSelection = (tableResource: TableResource) =>
  (state: State): TableSelection =>
    state.table.tableSelections[tableResource] ?? { ids: [], selectionType: 'SelectedOne' };
export const isTableSelectionActive = (tableResource: TableResource) => (state: State) => {
  const selection = tableSelection(tableResource)(state);

  return selection.ids.length > 0 || selection.selectionType === 'SelectedAll' || selection.selectionType === 'SelectedAllExcept';
};
export const isPaginatedListDataLoaded = (state: State): boolean =>
  state.table.isPaginatedListDataLoaded;
