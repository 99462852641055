// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface InventoryCheckoutResponse {
  id: string;
  item: { id: string };
}

interface CreateData {
  createInventoryCheckout: InventoryCheckoutResponse;
}

interface CreateInput {
  checkoutDate: string;
  dueDate: string;
  itemId: string;
  userId: string;
}

interface UpdateData {
  updateInventoryCheckout: InventoryCheckoutResponse;
}

interface UpdateInput {
  checkinDate?: string;
  checkoutDate: string;
  dueDate: string;
  id: string;
  itemId: string;
  userId: string;
}

interface CheckInData {
  checkInInventoryItem: boolean;
}

interface CheckInInput {
  inventoryCheckoutId: string;
}

// Local Variables
const CREATE_INVENTORY_CHECKOUT = gql`
  mutation CreateInventoryCheckout(
    $checkinDate: Date
    $checkoutDate: Date!
    $dueDate: Date!
    $itemId: ID!
    $userId: ID!
  ) {
    createInventoryCheckout(
      checkinDate: $checkinDate
      checkoutDate: $checkoutDate
      dueDate: $dueDate
      itemId: $itemId
      userId: $userId
    ) {
      active
      checkoutDate
      checkinDate
      dueDate
      id
      item {
        id
      }
      user {
          id
      }
    }
  }
`;

const UPDATE_INVENTORY_CHECKOUT = gql`
  mutation UpdateInventoryCheckout(
    $checkoutDate: Date!
    $dueDate: Date!
    $id: ID!
    $itemId: ID!
    $userId: ID!
  ) {
    updateInventoryCheckout(
      checkoutDate: $checkoutDate
      dueDate: $dueDate
      id: $id
      itemId: $itemId
      userId: $userId
    ) {
      id
      item {
        id
      }
      user {
          id
      }
    }
  }
`;

const CHECK_IN_INVENTORY_ITEM = gql`
  mutation CheckInInventory($inventoryCheckoutId: ID!) {
    checkInInventory(inventoryCheckoutId: $inventoryCheckoutId)
  }
`;

export const useCreateInventoryCheckout = (
  options?: MutationOptions<CreateData, CreateInput>,
) => useMutationEnhanced<CreateData, CreateInput>(CREATE_INVENTORY_CHECKOUT, {
  ...options,
  clearCachePredicates: [
    'inventoryCheckoutsByMemberId',
    'user',
    ...(options?.clearCachePredicates ?? []),
  ],
});

export const useUpdateInventoryCheckout = (
  options?: MutationOptions<UpdateData, UpdateInput>,
) => useMutationEnhanced<UpdateData, UpdateInput>(UPDATE_INVENTORY_CHECKOUT, {
  ...options,
  clearCachePredicates: ['inventoryCheckoutsByMemberId', ...(options?.clearCachePredicates ?? [])],
});

export const useCheckInInventoryItem = (
  options?: MutationOptions<CheckInData, CheckInInput>,
) => useMutationEnhanced<CheckInData, CheckInInput>(CHECK_IN_INVENTORY_ITEM, {
  ...options,
  clearCachePredicates: ['inventory', 'inventoryCheckoutsByMemberId', 'user', ...(options?.clearCachePredicates ?? [])],
});
