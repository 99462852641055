// Internal Dependencies
import { State } from 'state/reducers';

// Selectors
export const useDarkMode = (state: State) => {
  const shouldUseDarkMode = state.ui.quickSettingsMenu.useDarkMode;

  return typeof shouldUseDarkMode === 'string'
    ? JSON.parse(shouldUseDarkMode)
    : shouldUseDarkMode;
};
export const useDenseTable = (state: State) => {
  const shouldUseDenseTable = state.ui.quickSettingsMenu.useDenseTable;

  return typeof shouldUseDenseTable === 'string'
    ? JSON.parse(shouldUseDenseTable)
    : shouldUseDenseTable;
};
