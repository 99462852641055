// External Dependencies
import {
  Card,
  CardProps,
} from '@mui/material';
import { Ref, forwardRef } from 'react';

// Local Dependencies
import EnhancedCardContent from './EnhancedCardContent';

// Local Typings
interface Props extends CardProps {
  useCardContent?: boolean;
}

// Component Definition
const EnhancedCard = forwardRef((
  props: Props,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    children,
    useCardContent,
    ...otherProps
  } = props;

  const childrenNode = useCardContent ? (
    <EnhancedCardContent>
      {children}
    </EnhancedCardContent>
  ) : children;

  return (
    <Card
      {...otherProps}
      ref={ref}
      variant="outlined"
    >
      {childrenNode}
    </Card>
  );
});

export default EnhancedCard;
