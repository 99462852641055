// External Dependencies
import { FC, useEffect, useMemo } from 'react';

// Internal Dependencies
import {
  useGetFees,
} from 'gql/queries';

// Local Dependencies
import { PaymentsByUserFormValues } from './PaymentFormByUserForm';
import PaymentFormEmptyState from './PaymentFormEmptyState';
import PaymentFormFees from './PaymentFormFees';
import PaymentFormPayment from './PaymentFormPayment';

// Local Typings
interface Props {
  formValues: PaymentsByUserFormValues;
  onResetFormikForm: () => void;
  paymentTypeId: string;
  studentId: string;
}

// Component Definition
const PaymentFormUI: FC<Props> = ({
  formValues,
  onResetFormikForm,
  paymentTypeId,
  studentId,
}) => {
  const {
    getFees,
    results: {
      data: allFeesData,
      loading: isLoadingAllFees,
    },
  } = useGetFees();

  const isLoading = isLoadingAllFees;

  // For "new" flow we need all financial fees data for this student
  useEffect(() => {
    getFees({ variables: { where: { userId: studentId } } });
  }, [getFees, studentId]);

  const filteredFees = useMemo(
    () => allFeesData?.financialFees.data
      .filter((fee) => fee.remainingBalanceInCents > 0),
    [allFeesData],
  );

  const hasFees = Boolean(filteredFees?.length);

  if (isLoading || !allFeesData) {
    return null;
  }

  if (!hasFees && !isLoading) {
    return (
      <PaymentFormEmptyState />
    );
  }

  return (
    <>
      <PaymentFormPayment
        formValues={formValues}
        hasStudentFees={hasFees}
        onResetFormikForm={onResetFormikForm}
        paymentTypeId={paymentTypeId}
        studentId={studentId}
      />

      <PaymentFormFees
        formValues={formValues}
        userId={studentId}
      />
    </>
  );
};

export default PaymentFormUI;
