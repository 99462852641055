// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

// Internal Dependencies
import { displayCell } from 'components/shared/TableV2';
import { getFullName } from 'utils';

// Local Variables
export const useColumns = () =>
  useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: 'label',
        headerName: 'Organization Name',
      },
      {
        field: 'directorFirstName',
        headerName: 'Director Name',
        valueGetter: (params) => {
          const {
            directorFirstName,
            directorLastName,
          } = params.row;

          const hasDirectorData = Boolean(directorFirstName && directorLastName);

          const directorData = hasDirectorData
            ? getFullName({
              firstName: directorFirstName,
              lastName: directorLastName,
              middleName: '',
            })
            : undefined;

          return displayCell(directorData);
        },
      },
      {
        field: 'directorEmail',
        headerName: 'Director Email',
        valueGetter: (params) => {
          return displayCell(params.row.directorEmail || undefined);
        },
      },
    ];

    return columns;
  }, []);
