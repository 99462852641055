// External Dependencies
import { FC, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Internal Dependencies
import { EnhancedDialog, OrganizationSelect, SaveButton } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { isDistrictAdmin } from 'state/self/selectors';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useGetOrganization } from 'gql/queries';
import { useRedeemLicense } from 'gql/mutations';
import CustomInput from 'components/shared/CustomInput';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
  onExited?: () => void;
  redemptionCode?: string;
}

// Component Definition
const RedeemLicenseDialog: FC<Props> = ({
  isOpen,
  onClose,
  onExited,
  redemptionCode,
}) => {
  const dispatch = useDispatch();

  const isDistrictAdminUser = useSelector(isDistrictAdmin);

  const {
    data,
  } = useGetOrganization();

  const handleCompleted = useCallback(() => {
    dispatch(updateIsPaginatedListDataLoaded({
      isPaginatedListDataLoaded: false,
    }));

    dispatch(addNotification('License redeemed!', 'success'));

    onClose();
  }, [dispatch, onClose]);

  const handleClickInviteDFA = useCallback(() => {
    onClose();
    navigate(`/${PATHS.SETTINGS}#invite-district-admin-form`);
  }, [onClose]);

  const [
    redeemLicense,
    { loading: isSubmitting },
  ] = useRedeemLicense({
    onCompleted: handleCompleted,
  });

  const handleSubmit = (values: GQL.IRedeemLicenseInput) => {
    redeemLicense({
      variables: {
        input: values,
      },
    });
  };

  const doesBelongToDistrict = !!data?.organization.district || !!isDistrictAdminUser;

  return (
    <EnhancedDialog
      TransitionProps={{ onExited }}
      onClose={onClose}
      open={isOpen}
    >
      <div>
        <DialogTitle>
          Redeem License
        </DialogTitle>

        {doesBelongToDistrict ? (
          <Formik<GQL.IRedeemLicenseInput>
            initialValues={{
              organizationId: isDistrictAdminUser ? '' : undefined,
              redemptionCode: redemptionCode ?? '',
            }}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <DialogContent>
                  <DialogContentText>
                    {isDistrictAdminUser
                      ? 'Select an organization that should redeem this license code.'
                      : 'Please provide a valid license code you received from your district administrator.'}
                  </DialogContentText>

                  {isDistrictAdminUser && (
                    <OrganizationSelect orgTypeId={null} />
                  )}

                  {!redemptionCode && (
                    <CustomInput
                      label="License code"
                      name="redemptionCode"
                    />
                  )}
                </DialogContent>

                <DialogActions>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    onClick={onClose}
                    sx={{ mr: 1 }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>

                  <SaveButton
                    isSaving={isSubmitting}
                    type="submit"
                  >
                    Redeem
                  </SaveButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        ) : (
          <DialogContent>
            <DialogContentText>
              {/* eslint-disable-next-line max-len */}
              Please invite your district administrator to create a district account. Your administrator may then purchase licenses.
            </DialogContentText>
          </DialogContent>
        )}

        {!doesBelongToDistrict && (
          <DialogActions>
            <Button
              color="primary"
              onClick={onClose}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              Cancel
            </Button>

            <Button
              color="primary"
              onClick={handleClickInviteDFA}
              variant="contained"
            >
              Invite
            </Button>
          </DialogActions>
        )}
      </div>
    </EnhancedDialog>
  );
};

export default RedeemLicenseDialog;
