// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  inventoryItemData: GQL.IInventoryItem;
}

// Component Definition
const ShowInventoryPurchaseData: FC<Props> = ({ inventoryItemData }) => {
  if (!inventoryItemData) {
    return null;
  }

  const {
    purchaseOrderNumber,
    purchaseValueInCents,
    purchasedAt,
  } = inventoryItemData;

  return (
    <>
      <ShowPageDataDisplay
        label="Purchase Order Number"
        value={purchaseOrderNumber}
      />
      <ShowPageDataDisplay
        label="Purchase Date"
        type="date"
        value={purchasedAt}
      />
      <ShowPageDataDisplay
        label="Purchase Value"
        type="currency"
        value={purchaseValueInCents}
      />
    </>
  );
};

export default ShowInventoryPurchaseData;
