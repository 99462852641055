// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  inventoryItemData: GQL.IInventoryItem;
}

// Component Definition
const ShowInventoryStatusData: FC<Props> = ({
  inventoryItemData,
}) => {
  if (!inventoryItemData) {
    return null;
  }

  const {
    accessories,
    comments,
    condition,
    currentValueInCents,
    quality,
  } = inventoryItemData;

  return (
    <>
      <ShowPageDataDisplay
        label="Condition"
        value={condition?.label}
      />
      <ShowPageDataDisplay
        label="Current Value"
        type="currency"
        value={currentValueInCents}
      />
      <ShowPageDataDisplay
        label="Accessories"
        value={accessories}
      />
      <ShowPageDataDisplay
        label="Quality"
        value={quality?.label}
      />
      <ShowPageDataDisplay
        label="Comments"
        value={comments}
      />
    </>
  );
};

export default ShowInventoryStatusData;
