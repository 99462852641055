// External Dependencies
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import Box from '@mui/material/Box';

// Internal Dependencies
import {
  Page,
  Stepper,
} from 'components/shared';

// Local Dependencies
import { PaymentsFormValues } from './PaymentTable';
import EnterPaymentAmounts from './Steps/EnterPaymentAmounts';
import EnterPaymentMethod from './Steps/EnterPaymentMethod';
import PaymentReview from './Steps/PaymentReview';
import StripeBillingAlert from '../shared/StripeBillingAlert';

// Local Variables
const stepLabels = ['Payment Amount', 'Payment Method', 'Review'];

// Component Definition
const MyFinancialPaymentsNew: FC = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [paymentAmountValues, setPaymentAmountValues] = useState<PaymentsFormValues | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);

  const {
    allNotCovered,
  } = useMemo(() => {
    if (!paymentAmountValues) {
      return {
        allCovered: false,
        allNotCovered: false,
        isMixed: false,
      };
    }

    const paymentsWithValue = paymentAmountValues
      .payments?.filter((payment) => payment.amountInDollars > 0) ?? [];

    const areAllCovered = paymentsWithValue
      .every((payment) => payment.isOrganizationCoveringStripeFee);

    const areAllNotCovered = paymentsWithValue
      .every((payment) => !payment.isOrganizationCoveringStripeFee);

    return {
      allCovered: areAllCovered,
      allNotCovered: areAllNotCovered,
      isMixed: !areAllCovered && !areAllNotCovered,
    };
  }, [paymentAmountValues]);

  const maxAvailableStep = useMemo(() => {
    if (!paymentAmountValues) {
      return 1;
    }

    if (!paymentMethod) {
      return 2;
    }

    return 3;
  }, [paymentMethod, paymentAmountValues]);

  const handleAdvanceStep = useCallback(() => {
    setActiveStep((step) => Math.max(step + 1, maxAvailableStep));
  }, [maxAvailableStep]);

  const handleSetPaymentAmountValues = useCallback(
    (value: PaymentsFormValues | null) => {
      if (value) {
        handleAdvanceStep();
      }

      setPaymentAmountValues(value);
    },
    [handleAdvanceStep],
  );

  const handleSetPaymentMethod = useCallback(
    (value: PaymentMethod | null) => {
      setPaymentMethod(value);
    },
    [],
  );

  useEffect(() => {
    setActiveStep(maxAvailableStep);
  }, [maxAvailableStep, paymentMethod, paymentAmountValues]);

  const handleClearPaymentMethod = useCallback(() => {
    setPaymentMethod(null);
  }, []);

  const handleClearPaymentAmounts = useCallback(() => {
    setPaymentAmountValues(null);
  }, []);

  return (
    <Page>
      <Box marginBottom={2}>
        <Stepper
          activeStep={activeStep}
          maxAvailableStep={maxAvailableStep}
          onSetActiveStep={setActiveStep}
          stepLabels={stepLabels}
        />
      </Box>

      {allNotCovered && (
        <StripeBillingAlert />
      )}

      {activeStep === 1 && (
        <EnterPaymentAmounts
          hasFees={allNotCovered}
          onSetPaymentValues={handleSetPaymentAmountValues}
          paymentAmountValues={paymentAmountValues}
        />
      )}

      {activeStep === 2 && (
        <EnterPaymentMethod
          onSetPaymentMethod={handleSetPaymentMethod}
          paymentMethod={paymentMethod}
        />
      )}

      {activeStep === 3 && (
        <PaymentReview
          hasFees={allNotCovered}
          onEditPaymentAmounts={handleClearPaymentAmounts}
          onEditPaymentMethod={handleClearPaymentMethod}
          paymentMethod={paymentMethod}
          paymentAmountValues={paymentAmountValues}
        />
      )}
    </Page>
  );
};

export default MyFinancialPaymentsNew;
