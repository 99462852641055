// External Dependencies
import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { useGetFile } from 'gql/queries';

// Local Dependencies
import FileEditForm from '../shared/FileEditForm';

// Local Typings
interface RouteProps {
  directory: string;
  id: string;
}

// Component Definition
const FilesEdit: FC<RouteComponentProps<RouteProps>> = ({
  directory,
  id,
}) => {
  const {
    data,
    error,
    loading: fileLoading,
  } = useGetFile(id!);

  return (
    <Page
      backButtonProps={{
        label: 'File',
        path: `/${PATHS.FILES}/${directory}/${id}`,
      }}
      error={error}
      isLoading={fileLoading}
      notFound={!data?.file}
    >
      <FileEditForm
        directory={directory!}
        file={data?.file}
        id={id!}
      />
    </Page>
  );
};

export default FilesEdit;
