// External Dependencies
import {
  EnhancedAlert,
  Subtitle,
} from 'components/shared';
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { hasPermission } from 'state/self/selectors';

// Local Dependencies
import LinkToInactiveUsers from '../../shared/LinkToInactiveUsers';
import LinkToPreviousYearMembers from '../../shared/LinkToPreviousYearMembers';
import StudentTableV2 from './StudentsTable';

// Component Definition
const Students: FC = () => {
  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canReadPayments = useSelector(hasPermission('payments', 'read'));

  const shouldSeeAlert = canReadFinances || canReadPayments;

  return (
    <>
      <Subtitle>
        Students
      </Subtitle>

      {shouldSeeAlert && (
        <EnhancedAlert sx={{ marginBottom: 2 }}>
          * Financial data in this table is updated hourly
        </EnhancedAlert>
      )}

      <StudentTableV2 />

      <LinkToInactiveUsers />

      <LinkToPreviousYearMembers />
    </>
  );
};

export default Students;
