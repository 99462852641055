// External Dependencies
import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Internal Dependencies
import { DashboardCard, FinancialStatementDialog, ShowPageDataDisplay } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils';
import { isStudent as isStudentSelector } from 'state/self/selectors';
import { open } from 'state/ui/paymentAlertDialog/actions';
import { useCanMakePayments } from 'hooks/useCanMakePayments';
import { useGetMyFinancialFeesOverview, useGetMyRelativeIds } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import DialogPaymentAlert from 'components/shared/DialogPaymentAlert';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Variables
const StyledStrong = styled.strong({
  fontSize: '2.25rem',
});

// Component Definition
const MyFinancialOverviewCard: FC = () => {
  const dispatch = useDispatch();

  const { self } = useSelfQuery();
  const isStudent = useSelector(isStudentSelector);

  const {
    isOpen: isFinancialStatementDialogOpen,
    toggleIsOpen: toggleIsFinancialStatementDialogOpen,
  } = useIsOpen();

  const {
    data,
    loading: areFeesLoading,
  } = useGetMyFinancialFeesOverview();

  const canMakePayments = useCanMakePayments();

  const handleClickMakeAPayment = useCallback(() => {
    if (isStudent) {
      return dispatch(open(false));
    }
    return navigate(`/${PATHS.STUDENT_PAYMENTS_NEW}`);
  }, []);

  const relativeIds = useGetMyRelativeIds({ confirmedOnly: true, includeSelfId: true });

  const userIds = useMemo<string[]>(() => {
    if (isStudent && self?.id) {
      return [self.id];
    }

    return relativeIds;
  }, [isStudent, relativeIds, self]);

  return (
    <>
      <DashboardCard
        content={(
          <>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              marginBottom={2}
            >
              <Typography>
                Total outstanding fees
              </Typography>

              <Typography>
                {areFeesLoading
                  ? (
                    <Box
                      component="span"
                      mt={1}
                    >
                      <CircularProgress />
                    </Box>
                  )
                  : (
                    <StyledStrong>
                      {displayPriceStringFromDollarAmount(
                        convertCentsToDollars(
                          data?.myFinancialFeesOverview.balanceDueInCentsThisYear,
                        ),
                      )}
                    </StyledStrong>
                  )}
              </Typography>
            </Box>

            {!areFeesLoading
              && Boolean(data?.myFinancialFeesOverview.balanceDueInCentsPreviousYears)
              && (
                <ShowPageDataDisplay
                  label="Previous Years"
                  type="currency"
                  value={data?.myFinancialFeesOverview.balanceDueInCentsPreviousYears}
                />
              )}

            <Box
              alignItems="center"
              display="flex"
              gap={2}
              justifyContent="center"
              flexWrap="wrap"
            >
              <Button
                color="primary"
                disabled={areFeesLoading}
                onClick={toggleIsFinancialStatementDialogOpen}
                size="small"
                variant="outlined"
              >
                Download statement
              </Button>

              {canMakePayments && (
                <Button
                  color="primary"
                  disabled={areFeesLoading}
                  onClick={handleClickMakeAPayment}
                  size="small"
                  variant="outlined"
                >
                  Make a payment
                </Button>
              )}
            </Box>

            {self && (
              <FinancialStatementDialog
                downloadFileName="My financial statement"
                isGettingFamilyStatements
                isOpen={isFinancialStatementDialogOpen}
                onClose={toggleIsFinancialStatementDialogOpen}
                userIds={userIds}
              />
            )}
          </>
        )}
        horizontallyCenterContent
        title="Financial Overview"
      />
      <DialogPaymentAlert />
    </>
  );
};

export default MyFinancialOverviewCard;
