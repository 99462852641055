// External Dependencies
import { FC, useEffect, useMemo } from 'react';
import { InputAdornment } from '@mui/material';

// Internal Dependencies
import { MoneyInput } from 'components/shared';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
} from 'utils';
import { useFormikTextField } from 'hooks/useFormikTextField';
import { useGetCreditsByUserId, useGetFees } from 'gql/queries';

// Local Dependencies
import { PaymentsByUserPaymentRowValues } from '../PaymentFormByUserForm';
import { StyledTableCell, StyledTableCellTextBox, StyledTableRow } from '../styles';
import PaymentFormTableInput from '../PaymentFormTableInput';

// Local Typings
interface Props {
  financialFeeId: string;
  isPayingAllFees?: boolean;
  rowIndex: number;
  rowValues: PaymentsByUserPaymentRowValues;
  userId: string;
}

// Component Definition
const PaymentFeeTableRow: FC<Props> = ({
  financialFeeId,
  isPayingAllFees,
  rowIndex,
  rowValues,
  userId,
}) => {
  const {
    getFees,
    results: { data: allFeesData },
  } = useGetFees({ fetchPolicy: 'cache-first' });

  useEffect(() => {
    getFees({ variables: { where: { userId } } });
  }, [getFees, userId]);

  const { data: creditsByUserIdData } = useGetCreditsByUserId(userId);

  const fee = useMemo(() => allFeesData?.financialFees.data.find(
    (f) => f.id === financialFeeId,
  ), [allFeesData, financialFeeId]);

  const financialAccountId = fee?.financialItem.financialAccount?.id;

  const creditAvailableInCents = useMemo(() => {
    const totalAvailable = creditsByUserIdData?.financialCreditsByUserId
      .filter((c) => !!c.financialAccount
        && c.financialAccount.id === financialAccountId
        && !c.isRefunded
        && !c.closedAt)
      .reduce((prev, curr) => prev + curr.creditRemainingInCents, 0);

    return totalAvailable ?? 0;
  }, [creditsByUserIdData, financialAccountId]);

  const amountInCentsField = useFormikTextField(
    `payments[${rowIndex}].amountInCents`,
    rowValues.amountInCents.toLocaleString() ?? '0.00',
  );
  const creditAppliedInCentsField = useFormikTextField(
    `payments[${rowIndex}].creditAppliedAmountInCents`,
    rowValues.creditAppliedAmountInCents.toLocaleString() ?? '0.00',
  );
  const noteField = useFormikTextField(
    `payments[${rowIndex}].note`,
    rowValues.note,
  );

  const startAdornment = <InputAdornment position="start">$</InputAdornment>;

  const label = fee?.financialItem.label;
  const financialAccountLabel = fee?.financialItem.financialAccount?.label;
  const schoolYear = fee?.financialItem.schoolYearEnding;
  const originalAmountInCents = fee?.financialItem.priceInCents;
  const balanceInCents = fee?.remainingBalanceInCents;

  return (
    <StyledTableRow>
      <StyledTableCell>
        <StyledTableCellTextBox>
          {label}
        </StyledTableCellTextBox>
      </StyledTableCell>

      <StyledTableCell>
        <StyledTableCellTextBox>
          {schoolYear || 'All'}
        </StyledTableCellTextBox>
      </StyledTableCell>

      <StyledTableCell align="right">
        <StyledTableCellTextBox>
          {displayPriceStringFromDollarAmount(
            convertCentsToDollars(originalAmountInCents),
          )}
        </StyledTableCellTextBox>
      </StyledTableCell>

      <StyledTableCell align="right">
        <StyledTableCellTextBox>
          {displayPriceStringFromDollarAmount(
            convertCentsToDollars(balanceInCents),
          )}
        </StyledTableCellTextBox>
      </StyledTableCell>

      <StyledTableCell>
        <PaymentFormTableInput
          InputProps={{
            inputComponent: MoneyInput as any,
            inputProps: {
              'aria-label': `Payment for ${label}`,
            },
            readOnly: isPayingAllFees,
            startAdornment,
          }}
          {...amountInCentsField}
        />
      </StyledTableCell>

      <StyledTableCell>
        <StyledTableCellTextBox>
          {financialAccountLabel}
        </StyledTableCellTextBox>
      </StyledTableCell>

      <StyledTableCell align="right">
        <StyledTableCellTextBox>
          {displayPriceStringFromDollarAmount(
            convertCentsToDollars(creditAvailableInCents ?? 0),
          )}
        </StyledTableCellTextBox>
      </StyledTableCell>

      <StyledTableCell>
        <PaymentFormTableInput
          InputProps={{
            inputComponent: MoneyInput as any,
            inputProps: {
              'aria-label': `Credit applied for ${label}`,
            },
            readOnly: isPayingAllFees,
            startAdornment,
          }}
          {...creditAppliedInCentsField}
        />
      </StyledTableCell>

      <StyledTableCell>
        <PaymentFormTableInput
          InputProps={{
            inputProps: {
              'aria-label': `Note for ${label}`,
            },
          }}
          {...noteField}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default PaymentFeeTableRow;
