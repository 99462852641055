// External Dependencies
import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import { CustomInput } from 'components/shared';
import { useGetDistrictOrganizations } from 'gql/queries';
import DistrictOrganizationSelect from 'components/shared/Selectors/DistrictOrganizationSelect';

// Local Typings
interface Props {
  excludeLoanToOrganizationId?: string;
  inputSize: 'small' | 'medium';
}

// Local Variables
const useStyles = makeStyles({
  content: {
    '&:last-child': {
      paddingBottom: 16,
    },
  },
  firstCard: {
    marginBottom: 24,
  },
  input: {
    marginBottom: 16,
  },
});

// Component Definition
const InventoryLocationInfoFormFields: FC<Props> = ({
  excludeLoanToOrganizationId,
  inputSize,
}) => {
  const classes = useStyles();

  const {
    data,
  } = useGetDistrictOrganizations();

  const organizations = data?.districtOrganizations ?? [];

  return (
    <>
      <CustomInput
        label="Location"
        name="location"
        rootStyles={classes.input}
        size={inputSize}
      />
      <CustomInput
        label="Case Number"
        name="caseNumber"
        rootStyles={classes.input}
        size={inputSize}
      />
      <CustomInput
        label="Locker"
        name="locker"
        rootStyles={classes.input}
        size={inputSize}
      />
      <CustomInput
        label="Lock Number"
        name="lockNumber"
        rootStyles={classes.input}
        size={inputSize}
      />
      <CustomInput
        label="Combination"
        name="combination"
        rootStyles={classes.input}
        size={inputSize}
      />

      {organizations.length > 1 && (
        <DistrictOrganizationSelect
          allowUnassignedOption
          excludeOrganizationId={excludeLoanToOrganizationId}
          label="On Loan To"
          name="onLoanToOrganizationId"
          orgTypeId={null}
        />
      )}
    </>
  );
};

export default InventoryLocationInfoFormFields;
