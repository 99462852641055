// External Dependencies
import {
  Box,
  Collapse,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import WidgetsIcon from '@mui/icons-material/Widgets';
import styled from 'styled-components';

// Internal Dependencies
// import { PATHS } from 'utils/constants/routes';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import CheckoutCount from './CheckoutCount';
import InventoryDataCard from './InventoryDataCard';
import ShowCard from './ShowCard';
import StyledLink from './StyledLink';
// import SubscriberAddButton from './SubscriberAddButton';

// Local Typings
interface Props {
  inventoryCheckoutsData: GQL.IInventoryCheckout[];
  // memberId?: string;
  name?: string;
}

// Local Variables
const StyledShowCard = styled(ShowCard)(({ theme }) => ({
  '.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

// Component Definition
const ShowStudentInventoryCheckoutUI: FC<Props> = ({
  inventoryCheckoutsData,
  // memberId,
  name,
}) => {
  const {
    isOpen: isViewingUniformData,
    toggleIsOpen: toggleViewUniformData,
  } = useIsOpen();

  const inventoryCheckoutsCount = inventoryCheckoutsData.length;
  const hasInventoryCheckouts = inventoryCheckoutsCount > 0;

  return (
    <Box mb={2}>
      <StyledShowCard
        canEdit={false}
        icon={WidgetsIcon}
        title="Inventory"
      >
        {!hasInventoryCheckouts && (
          <Typography color="textSecondary">
            No inventory items checked out
          </Typography>
        )}

        {hasInventoryCheckouts && (
          <>
            <Box
              alignItems="baseline"
              display="flex"
              mb={2}
            >
              <CheckoutCount
                count={inventoryCheckoutsCount}
                name={name}
                resource="inventory"
              />
              <Box ml={1}>
                <StyledLink onClick={toggleViewUniformData}>
                  {isViewingUniformData ? 'Hide' : 'View'}
                </StyledLink>
              </Box>
            </Box>

            <Collapse
              in={isViewingUniformData}
              mountOnEnter
            >
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                {inventoryCheckoutsData.map((inventoryCheckout) => inventoryCheckout.active && (
                  <InventoryDataCard
                    key={inventoryCheckout.id}
                    inventoryCheckoutData={inventoryCheckout}
                  />
                ))}
              </Box>
            </Collapse>
          </>
        )}

        {/* TODO: Add the section below when add UI to check out inventory with barcode scan */}
        {/* {memberId && (
          <Box
            display="flex"
            justifyContent="flex-end"
            mt={2}
          >
            <SubscriberAddButton
              label="Inventory Checkout"
              to={`/${PATHS.INVENTORY_CHECKOUTS}/${PATHS.BARCODE_SCAN}?memberId=${memberId}`}
            />
          </Box>
        )} */}
      </StyledShowCard>
    </Box>
  );
};

export default ShowStudentInventoryCheckoutUI;
