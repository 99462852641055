// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';

// Internal Dependencies
import { ConfirmationDialog, TableDataGrid } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import { useActivateUser } from 'gql/mutations';
import { useGetInactiveIndex } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import InactiveMembersTableZeroState from './InactiveMembersTableZeroState';

// Local Dependencies
import { useColumns } from './hooks';

// Component Definition
const InactiveTable: FC = () => {
  const dispatch = useDispatch();

  const [activateUserOrgId, setActivateUserOrgId] = useState<string | null>(null);

  const {
    data,
    isLoading,
  } = useGetInactiveIndex();

  const canAddMembers = useSelector(hasPermission('users', 'write'));

  const handleSetActivateUserId = (row: GQL.IInactiveUserIndex) =>
    setActivateUserOrgId(row.userOrganizationId);

  const handleClose = useCallback(() => setActivateUserOrgId(null), []);

  const handleCompleted = useCallback(() => {
    dispatch(addNotification('User activated!', 'success'));
    handleClose();
  }, [dispatch, handleClose]);

  const [
    activateUser,
    {
      loading: isSubmitting,
    },
  ] = useActivateUser({
    onCompleted: handleCompleted,
  });

  const handleConfirm = () => {
    if (activateUserOrgId) {
      activateUser({
        variables: { userOrganizationId: activateUserOrgId },
      });
    }
  };

  const extraColumns = useMemo<GridColDef[]>(() => {
    const actionsColumn = createDataGridActionsColumn<GQL.IInactiveUserIndex>([
      ...(canAddMembers
        ? [
          {
            action: handleSetActivateUserId,
            icon: <AddIcon />,
            text: 'Activate',
          },
        ]
        : []),
    ]);

    return actionsColumn ? [actionsColumn] : [];
  }, [canAddMembers]);

  const columns = useColumns(extraColumns);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={null}
          columns={columns}
          components={{
            NoRowsOverlay: InactiveMembersTableZeroState,
          }}
          loading={isLoading}
          rows={data}
          withSearch={false}
          tableResource="inactiveUsers"
        />
      </DataGridContainer>

      <ConfirmationDialog
        canBeUndone
        confirmButtonAction={handleConfirm}
        declineButtonAction={handleClose}
        description="Are you sure you want to activate this member?"
        handleClose={handleClose}
        isSubmitting={isSubmitting}
        open={!!activateUserOrgId}
        title="Activate member"
      />
    </>
  );
};

export default InactiveTable;
