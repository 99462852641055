// External Dependencies
import {
  FC, useCallback, useState,
} from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { Form, Formik } from 'formik';
import { Typography } from '@mui/material';
import {
  createFinancialAccountSchema,
  updateFinancialAccountSchema,
} from '@presto-assistant/api_types/schemas/financialAccounts';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import AccountBalance from '@mui/icons-material/AccountBalance';

// Internal Dependencies
import {
  ConfirmationDialog,
  CustomCheckbox,
  CustomInput,
  FormActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasFeatureFlag } from 'state/self/selectors';
import { useIsOnlinePaymentsAvailable } from 'hooks/useCanMakePayments';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import OnlinePaymentsAlert from './OnlinePaymentsAlert';

// Local Typings
interface Props {
  initialValues?: GQL.ICreateFinancialAccountInput;
  onSubmit: (values: GQL.ICreateFinancialAccountInput) => void;
}

// Component Definition
const AccountForm: FC<Props> = ({
  initialValues,
  onSubmit,
}) => {
  const hasOnlinePaymentsFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.OnlinePayments));

  const isOnlinePaymentsAvailable = useIsOnlinePaymentsAvailable();

  const [
    localFormValues,
    setLocalFormValues,
  ] = useState<GQL.ICreateFinancialAccountInput>(
    initialValues ?? {
      canApplyFundraiserCredits: false,
      label: '',
      onlinePaymentsEnabled: false,
    },
  );

  const {
    isOpen: isAddAccountConfirmationDialogOpen,
    toggleIsOpen: toggleAddAccountConfirmationDialog,
  } = useIsOpen();

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_OVERVIEW}`);
  }, []);

  const handleFormikSubmit = useCallback(async (
    values: GQL.ICreateFinancialAccountInput,
  ) => {
    setLocalFormValues(values);

    toggleAddAccountConfirmationDialog();
  }, [toggleAddAccountConfirmationDialog]);

  const handleConfirmSubmit = useCallback(() => {
    onSubmit(localFormValues);
  }, [localFormValues, onSubmit]);

  const isEditing = Boolean(initialValues?.label);

  return (
    <Formik<GQL.ICreateFinancialAccountInput>
      enableReinitialize
      initialValues={localFormValues}
      onSubmit={handleFormikSubmit}
      validationSchema={isEditing ? updateFinancialAccountSchema : createFinancialAccountSchema}
    >
      {({
        handleSubmit,
        isSubmitting,
        touched,
      }) => {
        const isFormTouched = Object.keys(touched).length > 0;

        return (
          <Form onSubmit={handleSubmit}>
            <ShowCard
              icon={AccountBalance}
              title={isEditing ? 'Update Financial Account' : 'New Financial Account'}
            >
              <Typography
                color="textSecondary"
                paragraph
              >
                Enter details for the financial account.
              </Typography>

              <CustomInput
                label="Name"
                name="label"
              />

              <CustomCheckbox
                disabled={!hasOnlinePaymentsFeatureFlag || !isOnlinePaymentsAvailable}
                label={hasOnlinePaymentsFeatureFlag ? 'Allow Online Payments' : 'Allow Online Payments (coming soon)'}
                name="onlinePaymentsEnabled"
              />

              <CustomCheckbox
                label="Can Apply Fundraiser Credits"
                name="canApplyFundraiserCredits"
              />

              {hasOnlinePaymentsFeatureFlag && <OnlinePaymentsAlert showWarningAlertIfDisabled />}
            </ShowCard>

            <ConfirmationDialog
              confirmButtonAction={handleConfirmSubmit}
              confirmButtonText={isEditing
                ? 'Yes, update account'
                : 'Yes, add account'}
              declineButtonAction={toggleAddAccountConfirmationDialog}
              description={isEditing
                ? 'Your changes will be saved and you can continue assigning items to this account.'
                : 'You will be able to begin assigning items to this account.'}
              handleClose={toggleAddAccountConfirmationDialog}
              isSubmitting={isSubmitting}
              open={isAddAccountConfirmationDialogOpen}
              title={isEditing ? 'Update Account?' : 'Add Account?'}
            />

            <FormActions
              context="Account"
              isEditing={isEditing}
              isFormTouched={isFormTouched}
              isSubmitting={isSubmitting}
              onPressCancelOrBackButton={handlePressCancelOrBackButton}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AccountForm;
