// External Dependencies
import { FC, useCallback, useMemo } from 'react';
import { FormBlockTypes } from '@presto-assistant/api_types';

// Local Dependencies
import HeadingBlock from './HeadingBlock';
import MyFormBlockForm from './MyFormBlockForm';
import ParagraphBlock from './ParagraphBlock';

// Local Typings
interface Props {
  formAssignmentId: string;
  formBlock: GQL.IMyFormBlock;
  index: number;
  setIsFormDirtyWithIndex: (isFormDirty: boolean, index: number) => void;
}

// Component Definition
const MyFormBlock: FC<Props> = ({
  formAssignmentId,
  formBlock,
  index,
  setIsFormDirtyWithIndex,
}) => {
  const handleSetIsFormDirty = useCallback((isFormDirty: boolean) => {
    setIsFormDirtyWithIndex(isFormDirty, index);
  }, [index, setIsFormDirtyWithIndex]);

  const readOnlyContent = useMemo(() => {
    const formBlockTypeId = Number(formBlock.formBlockType.id);

    switch (formBlockTypeId) {
      case FormBlockTypes.Heading:
        return <HeadingBlock formBlock={formBlock} />;
      case FormBlockTypes.Paragraph:
        return <ParagraphBlock formBlock={formBlock} />;
      default:
        return null;
    }
  }, [formBlock]);

  if (readOnlyContent) {
    return readOnlyContent;
  }

  return (
    <MyFormBlockForm
      formAssignmentId={formAssignmentId}
      formBlock={formBlock}
      setIsFormDirty={handleSetIsFormDirty}
    />
  );
};

export default MyFormBlock;
