// External Dependencies
import {
  Drawer,
  Paper,
} from '@mui/material';
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import styled from 'styled-components';

// Internal Dependencies
import { ContextData } from 'types/context';
import { FullAppMessageBanner } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { addSelfData } from 'state/self/actions';
import {
  bannerAlertItems as bannerAlertItemsSelector,
  hasBannerAlerts as hasBannerAlertsSelector,
} from 'state/bannerAlerts/selectors';
import { getAdminCookie, getToken } from 'utils/cookies';
import {
  isDesktopScreenSize,
  isMobileScreenSize,
} from 'state/device/selectors';
import { onboardingRedirectUser } from 'utils/lib/onboarding_redirect_user';
import { updateDocumentTitle } from 'utils';
import { useIsAliasUser } from 'hooks/useIsAliasUser';
import AlertBanner from 'components/shared/AlertBanner';
import Footer from 'components/shared/Footer';
import UpdatePasswordInterstitial from 'components/shared/Interstitials/UpdatePasswordInterstitial';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import { useLocation } from '@reach/router';
import ReadonlyBanner from './ReadonlyBanner';
import SideNav from './SideNav';
import TitleBar from './TitleBar';

// Local Typings
interface Props {
  title: string;
}

// Local Variables
const StyledRoot = styled.main(({ theme }) => ({
  '.childrenContainer': {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 1.5, 3),
    },
    borderRadius: `0 0 ${theme.shape.borderRadius}px 0`,
    flex: '1 0 auto',
    overflowY: 'auto',
    padding: '16px 16px 24px',
  },
  '.innerContainer': {
    display: 'flex',
    margin: '0 auto',
  },
  '.innerDesktopContainer': {
    boxSizing: 'border-box',
    flexGrow: 1,
    padding: '16px 16px 4px',
    width: '100%',
    zIndex: 2,
  },
  '.innerMobileContainer': {
    boxSizing: 'border-box',
    flexGrow: 1,
    width: '100%',
  },
  '.paper': {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  '.rightPanel': {
    backgroundColor: theme.palette.mainContentBackground,
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  background: theme.palette.appBackground,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}));

// Component Definition
const PrivateRouteContainer: FC<Props> = ({
  children,
  title,
}) => {
  const dispatch = useDispatch();

  const bannerAlertItems = useSelector(bannerAlertItemsSelector);
  const hasBannerAlerts = useSelector(hasBannerAlertsSelector);
  const isMobileScreen = useSelector(isMobileScreenSize);
  const isDesktopScreen = useSelector(isDesktopScreenSize);

  const isAliasUser = useIsAliasUser();

  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const { loading: isLoadingSelf, self } = useSelfQuery();

  const { pathname } = useLocation();

  useEffect(() => {
    updateDocumentTitle(title);
  }, [title]);

  useEffect(() => {
    if (self) {
      dispatch(addSelfData(self));
    }

    // Check to see if user should be redirected to onboarding
    // This could happen if the user is signed in
    //  and their data has been changed
    if (self && !self.onboarding.isComplete) {
      onboardingRedirectUser(
        self.currentOrgId,
        self.hasDistrict,
        self.onboarding.stages,
        self.alertFlagId,
        pathname,
      );
    }
  }, [dispatch, pathname, self]);

  const isLoggedIn = Boolean(getToken());

  const toggleSideNav = useCallback(() => {
    setIsSideNavOpen((v) => !v);
  }, []);

  if (!isLoggedIn || !self || isLoadingSelf) {
    return null;
  }

  const renderAlerts = () => {
    // We only show the app-level alerts on dashboard and settings pages
    if ([`/${PATHS.DASHBOARD}`, `/${PATHS.SETTINGS}`].includes(window.location.pathname)) {
      return (
        hasBannerAlerts && (
          <>
            {bannerAlertItems.map((alert: ContextData.IAlertDetail) => (
              <AlertBanner
                alert={alert}
                key={alert.id}
              />
            ))}
          </>
        )
      );
    }
    return null;
  };

  const isPrestoAdmin = Boolean(getAdminCookie());

  const isActingAsAnotherUser = isPrestoAdmin || isAliasUser;

  const deviceContainerClass = isMobileScreen
    ? 'innerMobileContainer'
    : 'innerDesktopContainer';

  return (
    <>
      {!self.hasCustomPassword && !isActingAsAnotherUser && <UpdatePasswordInterstitial />}

      {isActingAsAnotherUser && <ReadonlyBanner isPrestoAdmin={isPrestoAdmin} />}

      <StyledRoot>
        <FullAppMessageBanner />

        <div className={clsx('innerContainer', deviceContainerClass)}>
          <Paper
            className="paper"
            variant="outlined"
          >
            {isDesktopScreen ? (
              <SideNav />
            ) : (
              <Drawer
                onClose={toggleSideNav}
                open={isSideNavOpen}
              >
                <SideNav onLinkClick={toggleSideNav} />
              </Drawer>
            )}

            <section className="rightPanel">
              <TitleBar
                onClickHamburger={toggleSideNav}
                self={self}
                title={title}
              />

              {renderAlerts()}

              <div className="childrenContainer">
                {children}
              </div>
            </section>
          </Paper>
        </div>

        <Footer hideLinks />
      </StyledRoot>
    </>
  );
};

export default PrivateRouteContainer;
