// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import AdminIcon from 'mdi-material-ui/ShieldAccount';
import OrgIcon from '@mui/icons-material/MusicNote';

// Internal Dependencies
import { EnhancedMenuItem } from 'components/shared';
import {
  currentDistrictAdminOrgId as currentDistrictAdminOrgIdSelector,
  isDistrictAdmin as isDistrictAdminSelector,
} from 'state/self/selectors';
import { getFullName } from 'utils';
import { toggleOrganization } from 'utils/api';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
interface Props {
  closeParent: () => void;
  currentOrgId?: string | null;
  onClickCreateOrg: () => void;
  onClickJoinOrg: () => void;
  shouldShowMemberProfileInfo: boolean;
  userOrgData: GQL.IUserOrganization[];
}

// Component Definition
const OrgSubMenu: FC<Props> = ({
  closeParent,
  currentOrgId,
  onClickCreateOrg,
  onClickJoinOrg,
  shouldShowMemberProfileInfo,
  userOrgData,
}) => {
  const dispatch = useDispatch();

  const { self } = useSelfQuery();

  const isDistrictAdmin = useSelector(isDistrictAdminSelector);
  const currentDistrictAdminOrgId = useSelector(currentDistrictAdminOrgIdSelector);

  if (!self) {
    return null;
  }

  const { districtMemberships } = self;

  const hasDistrictMembership = districtMemberships.length > 0;

  const handleClickOrg = (memberId: string | null) => {
    if (memberId !== self.id) {
      toggleOrganization(memberId, dispatch);
    }

    closeParent();
  };

  return (
    <>
      {hasDistrictMembership && districtMemberships.map((districtMembership) => (
        <EnhancedMenuItem
          key={districtMembership.id}
          icon={AdminIcon}
          isActive={!currentOrgId && currentDistrictAdminOrgId === districtMembership?.district?.id}
          isOrgName
          onClick={() => handleClickOrg(null)}
          secondaryText={districtMembership?.district?.label && (
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {districtMembership?.district?.label}
            </Typography>
          )}
          text="Admin"
        />
      ))}
      {userOrgData.map((userOrg) => {
        const {
          admin,
          id,
          member,
          organization: {
            entityType: {
              label: entityTypeLabel,
            },
            id: organizationId,
            label: orgName,
            organizationType: {
              label: organizationTypeLabel,
            },
          },
        } = userOrg;

        const roleLabel = admin ? 'Director' : member?.role?.label ?? '';

        const typeInfo = (
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {entityTypeLabel} {organizationTypeLabel}{roleLabel ? ` (${roleLabel})` : ''}
          </Typography>
        );

        const primaryText = member?.loggedInAt ? orgName : `${orgName} (NEW)`;

        return (
          <EnhancedMenuItem
            icon={OrgIcon}
            key={id}
            isActive={member ? member.id === self.id : currentOrgId === organizationId}
            isOrgName
            onClick={() => handleClickOrg(member?.id ?? null)}
            secondaryText={shouldShowMemberProfileInfo && (member || self)
              ? (
                <>
                  {typeInfo}
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {getFullName(member ?? self!)}
                  </Typography>
                </>
              )
              : typeInfo}
            text={primaryText}
          />
        );
      })}

      <EnhancedMenuItem
        icon={AddIcon}
        key="join-new"
        onClick={onClickJoinOrg}
        text="Join Organization"
      />
      {isDistrictAdmin && (
        <EnhancedMenuItem
          icon={AddIcon}
          key="create-new"
          onClick={onClickCreateOrg}
          text="Create New Organization"
        />
      )}
    </>
  );
};

export default OrgSubMenu;
