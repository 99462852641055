// External Dependencies
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { ForwardButton } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetOrganization, useGetOrganizationSchoolYearOptions } from 'gql/queries';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';

// Component Definition
const LinkToPreviousYearMembers: FC = () => {
  const schoolYearEnding = useGetOrganizationSchoolYear();

  const organization = useGetOrganization();

  const schoolYears = useGetOrganizationSchoolYearOptions({
    includeAllYearsOption: false,
    organizationId: organization.data?.organization.id,
  });

  const previousMemberParams = useSelector(tableQueryParams('previousMembers'));

  const hasPreviousSchoolYears = schoolYears.data?.organizationSchoolYearSelectOptions
    .some((option) => option.id < schoolYearEnding);

  if (!hasPreviousSchoolYears) {
    return null;
  }

  return (
    <Box
      ml={2}
      mt={4}
    >
      <Typography
        role="heading"
        sx={{ mb: 2 }}
        variant="body2"
      >
        View members from previous years
      </Typography>

      <ForwardButton navigateTo={`/${PATHS.PREVIOUS_YEAR_MEMBERS}/${schoolYearEnding - 1}${previousMemberParams}`}>
        View previous members
      </ForwardButton>
    </Box>
  );
};

export default LinkToPreviousYearMembers;
