// External Dependencies
import { FC, ReactElement, useEffect } from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import { getIsMaintenanceMode } from 'utils/lib/get_is_maintenance_mode';
import { getPageTitle } from 'utils';
import { getToken } from 'utils/cookies';
import Maintenance from 'pages/Maintenance';

// Local Dependencies
import PrivateRouteContainer from './PrivateRouteContainer';
import SimpleContainer from './SimpleContainer';

// Local Typings
interface MainContentContainerProps extends RouteComponentProps {
  '*'?: string;
  children: ReactElement;
}

// Local Variables
const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
  },
});

// Component Definition
const MainContentContainer: FC<MainContentContainerProps> = ({
  '*': titleKey,
  children,
}) => {
  const classes = useStyles();

  const { pathname } = useLocation();

  // If the pathname changes, we want to scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isMaintenanceMode = getIsMaintenanceMode();

  if (isMaintenanceMode) {
    return (
      <div className={classes.root}>
        <SimpleContainer>
          <Maintenance />
        </SimpleContainer>
      </div>
    );
  }

  const showSimpleContainer = !getToken();

  const title = getPageTitle(titleKey || '');

  return (
    <div className={classes.root}>
      {showSimpleContainer
        ? <SimpleContainer>{children}</SimpleContainer>
        : (
          <PrivateRouteContainer title={title}>
            {children}
          </PrivateRouteContainer>
        )}
    </div>
  );
};

export default MainContentContainer;
