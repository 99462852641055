export const calculateStripeFee = (subTotalInCents) => {
  // 30 cents
  const stripeBaseFeeInCents = 30;

  // the 2.9% that stripe takes
  const stripePercntageCut = 0.029;

  // 97.1% that is retained
  const percentageDiff = 1 - stripePercntageCut;

  // add the 30 cents, then divide it by 0.971
  const totalInCents = (subTotalInCents + stripeBaseFeeInCents) / percentageDiff;

  // return the difference
  return Math.round(totalInCents) - subTotalInCents;
};

export const convertDollarsToCents = (priceInDollars) => Math.round(priceInDollars * 100);

export const convertCentsToDollars = (priceInCents) => priceInCents / 100;

export const getNumFromPriceString = (priceString) => Number(priceString.replace(/[^0-9.-]+/g, ''));

export const displayPriceStringFromDollarAmount = (priceInDollars, options) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });

  let formattedString = formatter.format(priceInDollars);

  if (options?.hideDollarSign) {
    formattedString = formattedString.replace('$', '');
  }

  if (options?.hideCents) {
    [formattedString] = formattedString.split('.');
  }

  return formattedString;
};
