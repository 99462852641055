// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { NavMenuItem as MUINavMenuItem } from 'components/shared';

// Local Typings
export interface NavMenuItemProps {
  text: string;
  to: string;
}
type Props = {
  onClose: () => void;
} & NavMenuItemProps;

// Component Definition
const NavMenuItem: FC<Props> = ({
  onClose,
  text,
  to,
}) => (
  <Box
    display="flex"
    justifyContent="center"
    role="menu"
  >
    <MUINavMenuItem
      isActive
      isFullWidth
      onClick={onClose}
      text={text}
      to={to}
    />
  </Box>
);

export default NavMenuItem;
