// External Dependencies
import {
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';

// Internal Dependencies
import { EnhancedDialog } from 'components/shared';
import useTextField from 'hooks/useTextField';

// Local Dependencies
import ResultsList from './ResultsList';
import SearchForm from './SearchForm';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const FindOrganizationDialog: FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const emailField = useTextField('');
  const [organizations, setOrganizations] = useState<GQL.IOrganization[]>([]);

  const handleClose = () => {
    onClose();
  };

  const handleExit = () => {
    setOrganizations([]);
    const evt = { target: { value: '' } } as React.ChangeEvent<HTMLInputElement>;
    emailField.onChange(evt);
  };

  return (
    <EnhancedDialog
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      open={isOpen}
      TransitionProps={{
        onExited: handleExit,
      }}
    >
      <DialogTitle>
        Find an Organization
      </DialogTitle>

      <SearchForm
        onClose={onClose}
        setOrganizations={setOrganizations}
      />
      {organizations.length > 0 && (
        <DialogContent>
          <ResultsList organizations={organizations} />
        </DialogContent>
      )}
    </EnhancedDialog>
  );
};

export default FindOrganizationDialog;
