// External Dependencies
import { FC, useCallback } from 'react';
import Button from '@mui/material/Button';

// Local Typings
interface Props {
  license: GQL.ILicensesIndex;
  onSelectLicense: (license: GQL.ILicensesIndex) => void;
}

const RedeemLicenseButton: FC<Props> = ({
  license,
  onSelectLicense,
}) => {
  const handleDialogOpen = useCallback(() => {
    onSelectLicense(license);
  }, [license, onSelectLicense]);

  return (
    <Button
      onClick={handleDialogOpen}
      variant="text"
    >
      Redeem License
    </Button>
  );
};

export default RedeemLicenseButton;
