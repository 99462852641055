// External Dependencies
import { FC } from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { EnhancedAlert, StyledLink } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasFeatureFlag, hasPermission, isDirector } from 'state/self/selectors';
import { useGetOrganization } from 'gql/queries';

// Local Variables
const handleClickSettingsLink = () => {
  navigate(`/${PATHS.SETTINGS}#integrations`);
};

// Component Definition
const OnlinePaymentsAlert: FC = () => {
  const canReadPayments = useSelector(hasPermission('payments', 'read'));
  const isUserDirector = useSelector(isDirector);
  const isOnlinePaymentsFeatureFlagOn = useSelector(hasFeatureFlag(FeatureFlag.OnlinePayments));

  const {
    data: orgData,
  } = useGetOrganization();

  const organization = orgData?.organization;

  const district = organization?.district;

  const areDistrictOnlinePaymentsEnabled = district?.areOnlinePaymentsEnabled;
  const areOrganizationOnlinePaymentsEnabled = organization?.areOnlinePaymentsEnabled;

  const hasStripeId = organization?.hasStripeId;

  const areOnlinePaymentsEnabled = areDistrictOnlinePaymentsEnabled
    && areOrganizationOnlinePaymentsEnabled
    && hasStripeId;

  if (!canReadPayments) {
    return null;
  }

  if (!isOnlinePaymentsFeatureFlagOn) {
    return (
      <EnhancedAlert sx={{ mb: 2 }}>
        Online payments are coming soon!
      </EnhancedAlert>
    );
  }

  if (!isUserDirector) {
    return null;
  }

  if (areDistrictOnlinePaymentsEnabled && !areOnlinePaymentsEnabled) {
    return (
      <EnhancedAlert sx={{ mb: 2 }}>
        Online payments are here! Go to
        {' '}
        <StyledLink
          onClick={handleClickSettingsLink}
        >
          settings
        </StyledLink>
        {' '}
        to get started.
      </EnhancedAlert>
    );
  }

  if (district && !areDistrictOnlinePaymentsEnabled) {
    return (
      <EnhancedAlert sx={{ mb: 2 }}>
        {/* eslint-disable-next-line max-len */}
        Online payments are available! Ask your administrator to enable online payments for your district via their settings page if you wish to enable this feature.
      </EnhancedAlert>
    );
  }

  return null;
};

export default OnlinePaymentsAlert;
