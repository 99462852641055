// External Dependencies
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { TableResource, updateTableQueryParams } from 'state/table/actions';
import { useGetGradeOptions } from 'hooks/useGetGradeOptions';
import { useIsCollegeOrUniversity } from 'hooks/useIsCollegeOrUniversity';

// Local Dependencies
import { renderSelectEditInputCell } from './helpers';

// Hook Definitions
export const useUpdateParams = (
  tableResource: TableResource | undefined,
  search: string,
) => {
  const dispatch = useDispatch();

  return useEffect(() => {
    if (tableResource) {
      dispatch(updateTableQueryParams({
        key: tableResource,
        value: search,
      }));
    }
  }, [dispatch, search, tableResource]);
};

export const useGradeColDef: <T>(args: {
  editable?: boolean;
  field: keyof T;
  valueGetter?: DataGridColDef<T>['valueGetter'];
}) => DataGridColDef<T> = ({
  editable,
  field,
  valueGetter,
}) => {
  const isCollegeOrUniversity = useIsCollegeOrUniversity();

  const gradeOptions = useGetGradeOptions();
  const gradeOptionsWithEmptyOption = useMemo(() => [
    {
      id: '',
      label: '',
      value: '',
    },
    ...gradeOptions.options,
  ], [gradeOptions.options]);

  const getValue = valueGetter ?? ((params) => params.row[field]);

  return {
    editable: editable ?? false,
    field,
    headerName: 'Grade',
    renderEditCell: (params) => renderSelectEditInputCell({
      options: gradeOptionsWithEmptyOption,
      params,
    }),
    type: 'singleSelect',
    valueGetter: (params) => (isCollegeOrUniversity
      ? gradeOptions.options.find((option) => option.id === String(getValue(params)))?.label ?? ''
      : getValue(params) ?? ''),
    valueOptions: gradeOptions.options.map((option) => ({
      label: option.label,
      value: isCollegeOrUniversity ? option.label : Number(option.id),
    })),
    valueSetter: (params) => ({
      ...params.row,
      // For college/university, we need check against the label string
      // eslint-disable-next-line no-nested-ternary
      grade: isCollegeOrUniversity
        ? gradeOptions.options?.find((option) =>
          option.label === params.value ?? '')?.id ?? ''
        // For K-12, we can use the value unless selected the empty option
        : params.value ? Number(params.value) : null,
    }),
    width: 80,
  };
};
