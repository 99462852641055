// External Dependencies
import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { FC, useCallback } from 'react';
import { navigate } from '@reach/router';
import { useTheme } from 'styled-components';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetRelationships } from 'gql/queries';
import SubscriberOnlyButton from 'components/shared/SubscriberOnlyButton';

// Component Definition
const DashboardFamilyRelationshipsInfo: FC = () => {
  const {
    palette: {
      primary,
    },
  } = useTheme();

  const { data, loading } = useGetRelationships();

  const handleClickViewFamilyRelationships = useCallback(() =>
    navigate(`/${PATHS.PROFILE}#settings-family-card`), []);

  const handleClickInviteFamily = useCallback(() =>
    navigate(`/${PATHS.RELATIONSHIPS}/new`), []);

  const pendingRelationships = data?.userRelationships.filter((rel) => rel.isPending) ?? [];
  const pendingRelationshipsCount = pendingRelationships.length;

  const confirmedRelationships = data?.userRelationships.filter((rel) => rel.isConfirmed) ?? [];
  const confirmedRelationshipsCount = confirmedRelationships.length;

  const zeroStateIcon = (
    <ZeroStateIcon
      Icon={confirmedRelationshipsCount ? GroupIcon : GroupAddIcon}
      htmlColor={primary.light}
    />
  );

  const emptyStateElement = (
    <>
      {zeroStateIcon}

      <Box px={2}>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          Invite your family members to link with your {APP_NAME} organization.
        </Typography>
      </Box>

      <SubscriberOnlyButton
        color="primary"
        onClick={handleClickInviteFamily}
        size="small"
        variant="outlined"
      >
        Invite Family
      </SubscriberOnlyButton>
    </>
  );

  const pendingElement = (
    <>
      {zeroStateIcon}

      <Box px={2}>
        <Typography variant="body2">
          You have
          {' '}
          {pendingRelationshipsCount} pending relationship invitation{pendingRelationshipsCount > 1 ? 's' : ''}.
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handleClickViewFamilyRelationships}
        size="small"
        variant="outlined"
      >
        View Family
      </Button>
    </>
  );

  const confirmedElement = (
    <>
      {zeroStateIcon}

      <Box px={2}>
        <Typography variant="body2">
          You have
          {' '}
          {confirmedRelationshipsCount} family relationship{confirmedRelationshipsCount > 1 ? 's' : ''}.
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handleClickViewFamilyRelationships}
        size="small"
        variant="outlined"
      >
        View Family
      </Button>
    </>
  );

  const getFamilyContent = () => {
    let content;

    if (pendingRelationships && pendingRelationshipsCount) {
      content = pendingElement;
    } else if (confirmedRelationships && confirmedRelationshipsCount) {
      content = confirmedElement;
    } else {
      content = emptyStateElement;
    }

    return content;
  };

  return (
    <DashboardCard
      content={loading ? <CircularProgress /> : getFamilyContent()}
      horizontallyCenterContent
      title="Family"
      verticallyCenterContent
    />
  );
};

export default DashboardFamilyRelationshipsInfo;
