// External Dependencies
import { FC, useMemo, useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTheme } from 'styled-components';
import EventIcon from '@mui/icons-material/Event';

// Internal Dependencies
import { CalendarEvent, convertGoogleEventToCalendarEvent } from 'pages/Calendar/EventCalendar';
import { CalendarListItem, GoogleCalendarEvent } from 'gql/queries';
import { humanizeDateShort } from 'utils';
import EventDialog from 'pages/Calendar/EventDialog';

// Local Typings
interface Props {
  calendarList: CalendarListItem[];
  events: GoogleCalendarEvent[];
}

// Local Variables
const NUMBER_OF_SHOWN_EVENTS = 3;

// Component Definition
const EventList: FC<Props> = ({
  calendarList,
  events,
}) => {
  const [calendarEvent, setCalendarEvent] = useState<CalendarEvent | null>(null);

  const theme = useTheme();

  const handleSetEvent = (gCalEvent: GoogleCalendarEvent) => () =>
    setCalendarEvent(convertGoogleEventToCalendarEvent(gCalEvent));

  const handleClearEvent = () => setCalendarEvent(null);

  const shortEventList = useMemo(() => events.slice(0, NUMBER_OF_SHOWN_EVENTS), [events]);

  return (
    <>
      {!events.length && (
        <Typography
          color="textSecondary"
          sx={{ ml: 3 }}
          variant="body2"
        >
          No events scheduled in the upcoming two weeks
        </Typography>
      )}

      {shortEventList.map((event) => {
        const evtCalendar = calendarList
          .find((cal) => cal.id === event.googleCalendarId);

        return (
          <ListItem
            alignItems="flex-start"
            button
            key={event.id}
            onClick={handleSetEvent(event)}
          >
            <ListItemIcon>
              <EventIcon
                fontSize="small"
                htmlColor={evtCalendar?.backgroundColor ?? theme.palette.prestoPrimary}
              />
            </ListItemIcon>

            <ListItemText
              primary={event.summary}
              secondary={`${humanizeDateShort(
                event.start.dateTime ?? event.start.date ?? '',
                { includeTime: !!event.start.dateTime },
              )}${event.start.dateTime ? '' : ' - All Day'}`}
            />
          </ListItem>
        );
      })}

      {calendarEvent && (
        <EventDialog
          event={calendarEvent}
          onClose={handleClearEvent}
        />
      )}
    </>
  );
};

export default EventList;
