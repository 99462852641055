// External Dependencies
import { Box, InputAdornment, Typography } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import {
  CustomCheckbox,
  CustomInput, EnhancedAlert,
  MoneyInput,
} from 'components/shared';
import FinancialAccountSelect from 'components/shared/FinancialAccountSelect';
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';

// Local Typings
export interface FormValues {
  isActive: boolean;
  label: string;
  priceInCents: string | number;
  schoolYearEnding: number;
}

interface Props {
  isOrganizationCoveringStripeFee: boolean;
  organizationName: string;
  showCoverStripeFeesError: boolean;
  showFundraiserCreditError: boolean;
}

// Local Variables
const startAdornment = <InputAdornment position="start">$</InputAdornment>;

// Component Definition
const FormInputs: FC<Props> = ({
  isOrganizationCoveringStripeFee,
  organizationName,
  showCoverStripeFeesError,
  showFundraiserCreditError,
}) => (
  <>
    <CustomInput
      label="Name"
      name="label"
    />

    <FinancialAccountSelect />

    <CustomInput
      InputProps={{
        inputComponent: MoneyInput as any,
        startAdornment,
      }}
      label="Price"
      name="priceInCents"
    />

    <SchoolYearSelect
      fullWidth={false}
      name="schoolYearEnding"
      required
      variant="filled"
    />

    <Box mt={1.5}>
      <CustomCheckbox
        className="checkbox"
        label={(
          <>
            Active{' '}
            <Typography
              color="textSecondary"
              component="span"
              variant="body2"
            >
              (can assign to people)
            </Typography>
          </>
        )}
        name="isActive"
      />
    </Box>

    <Box mt={1.5}>
      <CustomCheckbox
        className="checkbox"
        label="Can Apply Fundraiser Credits"
        name="canApplyFundraiserCredits"
      />

      {showFundraiserCreditError && (
        <EnhancedAlert severity="error">
          The selected account cannot apply fundraiser credits.
          {' '}
          This item cannot override that setting.
        </EnhancedAlert>
      )}
    </Box>

    <Box mt={1.5}>
      <CustomCheckbox
        className="checkbox"
        label={(
          <>
            Cover Stripe Fee{' '}
            <Typography
              color="textSecondary"
              component="span"
              variant="body2"
            >
              (organization pays online payment fee)
            </Typography>
          </>
        )}
        name="isOrganizationCoveringStripeFee"
      />

      {showCoverStripeFeesError && (
        <EnhancedAlert severity="error">
          The selected account does not have online payments enabled.
        </EnhancedAlert>
      )}

      {!showCoverStripeFeesError && isOrganizationCoveringStripeFee && (
        <EnhancedAlert severity="info">
          <Typography
            color="inherit"
            fontSize="inherit"
          >
            {organizationName} covers the online payment fee of
            {' '}
            <Typography
              color="inherit"
              component="strong"
              fontSize="inherit"
              fontWeight={600}
              sx={{ whiteSpace: 'pre' }}
            >
              2.9% + $0.30
            </Typography>
            {' '}
            per transaction for this item. Members pay the
            {' '}
            shown price, and the organization receives a reduced amount.
          </Typography>
        </EnhancedAlert>
      )}
    </Box>
  </>
);

export default FormInputs;
