// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Component Definition
const PendingMemberTableZeroState: FC = () => (
  <TableDataGridZeroState
    message="No members have requested to join your organization."
  />
);

export default PendingMemberTableZeroState;
