// External Dependencies
import { LazyQueryResult } from '@apollo/client';
import { useEffect } from 'react';
import gql from 'graphql-tag';

// Internal Dependencies
import {
  IFilters as DistrictFilters,
} from 'pages/DistrictAdmin/AdminLibrary/All/AdminLibraryTable/Filters';
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface LibraryIndexResponse {
  libraryItemsIndex: GQL.ILibraryIndexItemAll;
}
interface DistrictLibraryIndexResponse {
  districtLibraryItemsIndex: GQL.IDistrictLibraryIndexItemAll;
}
interface DistrictLibraryIndexVariables {
  queryParams?: GQL.IIndexQueryParams;
  where?: GQL.IDistrictLibraryIndexWhereConditions;
}

const getDistrictAdditionalVariables: UseGetTableQueryArgs['getAdditionalVariables'] = (
  search,
) => {
  const filters: DistrictFilters = {
    organizationId: search.organizationId ?? null,
    organizationTypeId: search.organizationTypeId ?? null,
  };

  const where = {
    organizationId: filters.organizationId ?? undefined,
    organizationTypeId: filters.organizationTypeId
      ? Number(filters.organizationTypeId)
      : undefined,
  };

  return { where };
};

export const GET_LIBRARY_ITEM = gql`
  query LibraryItem($id: ID!) {
    libraryItem(id: $id) {
      arranger
      categoryId
      comments
      composer
      gradeLevel {
        description
        id
        value
      }
      id
      instrumentationId
      number
      organization {
        id
        label
        organizationType {
          id
          label
        }
      }
      systemBarcode
      title
      year
      
      accompaniment
      author
      conditionId
      hasAccompaniment
      isMajorWork
      isOutOfPrint
      isSolo
      language
      lastPerformance
      majorWork
      numberOfCopies
      period
      priceInCents
      publisher
      stateCode

      dynamicField01
      dynamicField02
      dynamicField03
      dynamicField04
      dynamicField05
      dynamicField06
      dynamicField07
      dynamicField08
      dynamicField09
      dynamicField10
      dynamicField11
      dynamicField12
      dynamicField13
      dynamicField14
      dynamicField15
      dynamicField16
      dynamicField17
      dynamicField18
      dynamicField19
      dynamicField20
      dynamicField21
      dynamicField22
      dynamicField23
      dynamicField24
      dynamicField25
      dynamicField26
      dynamicField27
      dynamicField28
      dynamicField29
      dynamicField30
      dynamicField31
      dynamicField32
      dynamicField33
      dynamicField34
      dynamicField35
      dynamicField36
      dynamicField37
      dynamicField38
      dynamicField39
      dynamicField40
      dynamicField41
      dynamicField42
      dynamicField43
      dynamicField44
      dynamicField45
      dynamicField46
      dynamicField47
      dynamicField48
      dynamicField49
      dynamicField50
    }
  }
`;

// @deprecated No longer used
export const GET_LIBRARY_ITEMS = gql`
  query LibraryItems($queryParams: QueryParams) {
    libraryItems(queryParams: $queryParams) {
      data {
        arranger
        categoryId
        comments
        composer
        gradeLevel {
          description
          id
          value
        }
        id
        instrumentationId
        number
        title
        year

        author
        conditionId
        hasAccompaniment
        isMajorWork
        isOutOfPrint
        isSolo
        language
        lastPerformance
        numberOfCopies
        period
        priceInCents
        publisher
        stateCode
      }
      fullCount
    }
  }
`;

export const GET_LIBRARY_INDEX_ITEMS = gql`
  query LibraryItemsIndex($queryParams: IndexQueryParams) {
    libraryItemsIndex(queryParams: $queryParams) {
      data {
        arranger
        categoryId
        categoryLabel
        comments
        composer
        grade
        id
        instrumentationId
        instrumentationLabel
        number
        title
        year

        accompaniment
        author
        conditionId
        conditionLabel
        hasAccompaniment
        isMajorWork
        isOutOfPrint
        isSolo
        language
        lastPerformance
        majorWork
        numberOfCopies
        period
        priceInCents
        publisher
        stateCode

        dynamicField01
        dynamicField02
        dynamicField03
        dynamicField04
        dynamicField05
        dynamicField06
        dynamicField07
        dynamicField08
        dynamicField09
        dynamicField10
        dynamicField11
        dynamicField12
        dynamicField13
        dynamicField14
        dynamicField15
        dynamicField16
        dynamicField17
        dynamicField18
        dynamicField19
        dynamicField20
        dynamicField21
        dynamicField22
        dynamicField23
        dynamicField24
        dynamicField25
        dynamicField26
        dynamicField27
        dynamicField28
        dynamicField29
        dynamicField30
        dynamicField31
        dynamicField32
        dynamicField33
        dynamicField34
        dynamicField35
        dynamicField36
        dynamicField37
        dynamicField38
        dynamicField39
        dynamicField40
        dynamicField41
        dynamicField42
        dynamicField43
        dynamicField44
        dynamicField45
        dynamicField46
        dynamicField47
        dynamicField48
        dynamicField49
        dynamicField50
      }
      fullCount
    }
  }
`;

export const GET_DISTRICT_LIBRARY_INDEX_ITEMS = gql`
  query DistrictLibraryItemsIndex(
    $queryParams: IndexQueryParams
    $where: DistrictLibraryIndexWhereConditions
  ) {
    districtLibraryItemsIndex(
      queryParams: $queryParams
      where: $where
    ) {
      data {
        arranger
        categoryId
        categoryLabel
        comments
        composer
        grade
        id
        instrumentationId
        instrumentationLabel
        number
        organization {
          id
          label
        }
        title
        year

        accompaniment
        author
        conditionId
        conditionLabel
        hasAccompaniment
        isMajorWork
        isOutOfPrint
        isSolo
        language
        lastPerformance
        majorWork
        numberOfCopies
        period
        priceInCents
        publisher
        stateCode

        dynamicField01
        dynamicField02
        dynamicField03
        dynamicField04
        dynamicField05
        dynamicField06
        dynamicField07
        dynamicField08
        dynamicField09
        dynamicField10
        dynamicField11
        dynamicField12
        dynamicField13
        dynamicField14
        dynamicField15
        dynamicField16
        dynamicField17
        dynamicField18
        dynamicField19
        dynamicField20
        dynamicField21
        dynamicField22
        dynamicField23
        dynamicField24
        dynamicField25
        dynamicField26
        dynamicField27
        dynamicField28
        dynamicField29
        dynamicField30
        dynamicField31
        dynamicField32
        dynamicField33
        dynamicField34
        dynamicField35
        dynamicField36
        dynamicField37
        dynamicField38
        dynamicField39
        dynamicField40
        dynamicField41
        dynamicField42
        dynamicField43
        dynamicField44
        dynamicField45
        dynamicField46
        dynamicField47
        dynamicField48
        dynamicField49
        dynamicField50
      }
      fullCount
    }
  }
`;

export const GET_LIBRARY_INDEX_ITEMS_REPORT = gql`
  query LibraryItemsIndexReport($queryParams: IndexQueryParams) {
    libraryItemsIndex(queryParams: $queryParams) {
      fullCount
    }
  }
`;

export const GET_DISTRICT_LIBRARY_ITEMS_INDEX_REPORT = gql`
  query DistrictLibraryIndexReport(
    $queryParams: IndexQueryParams
    $where: DistrictLibraryIndexWhereConditions
  ) {
    districtLibraryItemsIndex(
      queryParams: $queryParams
      where: $where
    ) {
      fullCount
    }
  }
`;

export const useGetLibraryItem = (
  id: string,
) =>
  useQueryEnhanced<{
    libraryItem: GQL.ILibraryItem;
  }>(GET_LIBRARY_ITEM, { variables: { id } });

export const useGetLibraryItems = () => usePaginatedListQuery<
  LibraryIndexResponse,
  GQL.ILibraryIndexItem
>({
  dataSelector: (data) => data.libraryItemsIndex.data,
  fullCountSelector: (data) => data.libraryItemsIndex.fullCount,
  query: GET_LIBRARY_INDEX_ITEMS,
});

export const useGetDistrictLibraryIndexItemsQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<
      DistrictLibraryIndexResponse,
      DistrictLibraryIndexVariables
    >
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<
    DistrictLibraryIndexResponse,
    DistrictLibraryIndexVariables
  >({
    getAdditionalVariables: getDistrictAdditionalVariables,
    gqlQuery,
    options,
    tableResource: 'districtLibraryItems',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetDistrictLibraryIndexItems = () => {
  const [query, values] = useGetDistrictLibraryIndexItemsQuery(
    GET_DISTRICT_LIBRARY_INDEX_ITEMS,
    { exportReport: false },
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useDownloadDistrictLibraryItemsIndexItems = () =>
  useGetDistrictLibraryIndexItemsQuery(
    GET_DISTRICT_LIBRARY_ITEMS_INDEX_REPORT,
    { exportReport: true },
  );
