// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import EnhancedBanner from './EnhancedBanner';

// Local Variables
const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.8rem',
    paddingLeft: 0,
  },
  paddingLeft: theme.spacing(3),
}));

// Local Variables
const fullAppBannerMessage = process.env.REACT_APP_FULL_APP_BANNER_MESSAGE;

// Component Definition
const FullAppMessageBanner: FC = () => {
  const theme = useTheme();

  const {
    handleClose,
    isOpen,
  } = useIsOpen(Boolean(fullAppBannerMessage));

  return fullAppBannerMessage
    ? (
      <EnhancedBanner
        isAlertOpen={isOpen}
        onClose={handleClose}
      >
        <StyledBox
          alignItems="center"
          display="flex"
        >
          <Box
            alignItems="center"
            display="flex"
            marginRight={3}
          >
            <InfoIcon
              fontSize="large"
              htmlColor={theme.palette.alertColors.info.border}
            />
          </Box>

          {fullAppBannerMessage}
        </StyledBox>
      </EnhancedBanner>
    ) : null;
};

export default FullAppMessageBanner;
