// External Dependencies
import { FC } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { useDistrictUpdateLibraryItem } from 'gql/mutations';
import { useGetLibraryItem } from 'gql/queries';
import LibraryForm,
{
  AdminLibraryFormValues,
  LibraryFormValues,
} from 'pages/Library/shared/LibraryForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Local Variables
const navigateToShow = (id: string) => {
  navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}/${id}`);
};

// Component Definition
const AdminLibraryEdit: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const {
    data,
    error,
    loading,
  } = useGetLibraryItem(id!);

  const [handleUpdateDistrictLibraryItem] = useDistrictUpdateLibraryItem(
    {
      clearCachePredicates: ['districtLibraryItemsIndex', 'libraryItem'],
      onCompleted: () => navigateToShow(id!),
    },
  );

  const handleSubmit = async (
    values: LibraryFormValues | AdminLibraryFormValues,
  ) => {
    const updatedValues = {
      ...values,
      organizationTypeId: values.orgTypeId!,
    };

    // The API won't like us sending in a value that doesn't exist in the model
    delete updatedValues.orgTypeId;

    await handleUpdateDistrictLibraryItem({
      variables: {
        id: id!,
        input: {
          ...updatedValues,
          ...applyDynamicFields(updatedValues as unknown as DynamicFields),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'Library Item Detail',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}/${id}`,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <LibraryForm
        canUseDynamicFields
        libraryItem={data?.libraryItem}
        isAdmin
        onSubmit={handleSubmit}
        title="Edit LibraryItem"
      />
    </Page>
  );
};

export default AdminLibraryEdit;
