// External Dependencies
import { FC } from 'react';
import { navigate } from '@reach/router';

// Internal Dependencies
import { useAdvanceEndOfYearStep } from 'gql/mutations';

// Local Dependencies
import EndOfYearActionButtons from '../shared/EndOfYearActionButtons';
import EndOfYearGroupsContent from './EndOfYearGroupsContent';
import EndOfYearGroupsInfoAlert from './EndOfYearGroupsInfoAlert';

// Component Definition
const EndOfYearGroups: FC = () => {
  const [
    advanceEndOfYearStep,
    {
      loading: isAdvancingToNextStep,
    },
  ] = useAdvanceEndOfYearStep(
    {
      onCompleted: (response) => {
        // We send the user back to their current step
        navigate(`?step=${response.advanceEndOfYearStep.maxCompletedEndOfYearStep + 1}`);
      },
    },
  );

  const handleAdvanceToNextStep = () => {
    advanceEndOfYearStep({
      variables: {
        step: 3,
      },
    });
  };

  return (
    <>
      <EndOfYearGroupsInfoAlert />

      <EndOfYearGroupsContent />

      <EndOfYearActionButtons
        isSubmitting={isAdvancingToNextStep}
        onPressNextButton={handleAdvanceToNextStep}
      />
    </>
  );
};

export default EndOfYearGroups;
