// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  GridColDef, GridRowId, GridSelectionModel,
} from '@mui/x-data-grid-pro';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { waiveFinancialFeeSchema } from '@presto-assistant/api_types/schemas/financialFees';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AttachMoney from '@mui/icons-material/AttachMoney';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from 'mdi-material-ui/Receipt';
import TextBoxCheckIcon from 'mdi-material-ui/TextBoxCheck';
import TextBoxIcon from 'mdi-material-ui/TextBox';
import TextBoxSearchIcon from 'mdi-material-ui/TextBoxSearch';

// Internal Dependencies
import { ConfirmationDialog, TableDataGrid } from 'components/shared';
import { DELETE_FINANCIAL_FEE, WAIVE_FINANCIAL_FEE, useUnwaiveFinancialFee } from 'gql/mutations';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import {
  createDataGridActionsColumn,
} from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import { open } from 'state/ui/uncategorizedFinancialFeeUploadDialog/actions';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import {
  useGetFinancialFeesIndex,
} from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialog from 'components/shared/DeleteDialog';
import DeleteDialogV2, { DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';
import DialogUncategorizedFinancialFeeFileUpload
  from 'components/shared/DialogUncategorizedFinancialFeeFileUpload';
import SendFinancialStatementDialog from 'components/shared/SendFinancialStatementDialog';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Dependencies
import { useColumns } from './hooks';

// Local Typings
interface Props {
  schoolYearEnding: number;
}

// Local Variables
const waiveFeeClearCachePredicates = ['financialFeesIndex', 'financialFeesOverview'];
const waiveFeeContext: [string] = ['fee'];

// Component Definition
const FinancialFeesTable: FC<Props> = ({
  schoolYearEnding,
}) => {
  const dispatch = useDispatch();

  const [singleItemDeleteId, setSingleItemDeleteId] = useState<string | null>(null);
  const [itemToWaiveId, setItemToWaiveId] = useState<string | null>(null);
  const [itemToUnwaiveId, setItemToUnwaiveId] = useState<string | null>(null);
  const [memberIdsToEmail, setMemberIdsToEmail] = useState<string[] | null>(null);

  const canWritePayments = useSelector(hasPermission('payments', 'write'));
  const canDeleteFinances = useSelector(hasPermission('finances', 'delete'));
  const canReadMembers = useSelector(hasPermission('users', 'read'));
  const canWaiveFees = useSelector(hasPermission('finances', 'edit'));
  const canWriteFinances = useSelector(hasPermission('finances', 'write'));

  const [
    filteredRowIds,
    setFilteredRowIds,
  ] = useState<GridRowId[]>([]);
  const [
    selectedFeeIds,
    setSelectedFeeIds,
  ] = useState<GridSelectionModel>([]);

  const {
    isOpen: isDeleteDialogOpen,
    toggleIsOpen: toggleDeleteDialog,
  } = useIsOpen();
  const {
    isOpen: isWaiveDialogOpen,
    toggleIsOpen: toggleWaiveDialog,
  } = useIsOpen();
  const {
    isOpen: isUnwaiveDialogOpen,
    toggleIsOpen: toggleUnwaiveDialog,
  } = useIsOpen();
  const {
    handleOpen: handleOpenSendStatementDialog,
    isOpen: isSendStatementDialogOpen,
    toggleIsOpen: toggleSendStatementDialog,
  } = useIsOpen();

  const handleClickSendStatements = useCallback((memberIds: string[] | null) => () => {
    setMemberIdsToEmail(memberIds);
    handleOpenSendStatementDialog();
  }, [handleOpenSendStatementDialog]);

  const {
    data,
    isLoading,
  } = useGetFinancialFeesIndex(schoolYearEnding);

  const [
    unwaiveFinancialFee,
    {
      loading: isUnwaivingFinancialFee,
    },
  ] = useUnwaiveFinancialFee({
    onCompleted: () => {
      toggleUnwaiveDialog();
      setItemToUnwaiveId(null);
      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    },
  });

  const handleClickViewFinancialItem = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    navigate(`/${PATHS.FINANCIAL_ITEMS}/${row.financialItemId}`);
  }, []);

  const handleClickViewMember = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    navigate(`/${PATHS.MEMBERS}/${row.userId}`);
  }, []);

  const handleNavigateToPaymentPage = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    navigate(`/${PATHS.FINANCIAL_PAYMENTS_NEW}?user_id=${row.userId}`);
  }, []);

  const handleOpenDialogUncategorizedFinancialFeeFileUpload = useCallback(() => {
    dispatch(open());
  }, [dispatch]);

  const handleOpenSingleItemDeleteDialog = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    setSingleItemDeleteId(row.id);
    toggleDeleteDialog();
  }, [toggleDeleteDialog]);

  const handleOpenWaiveFeeDialog = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    setItemToWaiveId(row.id);
    toggleWaiveDialog();
  }, [toggleWaiveDialog]);

  const handleOpenUnwaiveFeeDialog = useCallback((row: GQL.IFinancialFeeIndexItem) => {
    setItemToUnwaiveId(row.id);
    toggleUnwaiveDialog();
  }, [toggleUnwaiveDialog]);

  const handleUnwaiveFee = useCallback(() => {
    if (itemToUnwaiveId) {
      unwaiveFinancialFee({
        variables: {
          input: {
            financialFeeId: itemToUnwaiveId,
          },
        },
      });
    }
  }, [
    itemToUnwaiveId,
    unwaiveFinancialFee,
  ]);

  const formatWaiveFeePayload = useCallback<DeleteDialogV2Props<GQL.IWaiveFinancialFeeOnMutationArguments>['formatPayload']>((note) => ({
    input: {
      financialFeeId: itemToWaiveId ?? '',
      waivedReason: note ?? '',
    },
  }), [itemToWaiveId]);

  const filteredMemberIds = useMemo(
    () => [
      ...new Set(data?.filter((row) =>
        filteredRowIds.includes(row.id)).map((row) => row.userId) ?? []),
    ],
    [data, filteredRowIds],
  );

  const selectedMemberIds = useMemo(
    () => [
      ...new Set(data?.filter((row) =>
        selectedFeeIds.includes(row.id)).map((row) => row.userId) ?? []),
    ],
    [data, selectedFeeIds],
  );

  const toolbarActions = useMemo<IToolbarAction[]>(() => [
    ...(canWriteFinances ? [{
      action: handleOpenDialogUncategorizedFinancialFeeFileUpload,
      icon: <CloudUploadIcon />,
      // TODO: Update this to the new way the API tells us about active
      // isDisabled: !self?.currentOrgActive,
      text: 'Import fees',
    }] : []),
    {
      action: handleClickSendStatements(null),
      icon: <TextBoxIcon />,
      text: 'Send statements to everyone',
    },
    {
      action: handleClickSendStatements(selectedMemberIds),
      icon: <TextBoxCheckIcon />,
      isDisabled: selectedMemberIds.length === 0,
      text: `Send statements to selected (${selectedMemberIds.length})`,
    },
    {
      action: handleClickSendStatements(filteredMemberIds),
      icon: <TextBoxSearchIcon />,
      isDisabled: filteredMemberIds.length === 0,
      text: `Send statements to filtered (${filteredMemberIds.length})`,
    },
  ], [
    canWriteFinances,
    filteredMemberIds,
    handleClickSendStatements,
    handleOpenDialogUncategorizedFinancialFeeFileUpload,
    selectedMemberIds,
  ]);

  const extraColumns = useMemo<GridColDef<GQL.IFinancialFeeIndexItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<GQL.IFinancialFeeIndexItem>([
        ...(canWritePayments ? [{
          action: handleNavigateToPaymentPage,
          icon: <AttachMoney />,
          text: 'Add a Payment',
        }] : []),
        ...(canReadMembers ? [{
          action: handleClickViewMember,
          icon: <PersonIcon />,
          text: 'View Member',
        }] : []),
        {
          action: handleClickViewFinancialItem,
          icon: <ReceiptIcon />,
          text: 'View Item',
        },
        ...(canWaiveFees ? [
          {
            action: handleOpenWaiveFeeDialog,
            icon: <DoDisturbIcon />,
            shouldRender: (row: GQL.IFinancialFeeIndexItem) => !row.isWaived,
            text: 'Waive Fee',
          },
          {
            action: handleOpenUnwaiveFeeDialog,
            icon: <AddShoppingCartIcon />,
            shouldRender: (row: GQL.IFinancialFeeIndexItem) => row.isWaived,
            text: 'Unwaive Fee',
          },
        ] : []),
        ...(canDeleteFinances ? [{
          action: handleOpenSingleItemDeleteDialog,
          icon: <DeleteIcon />,
          text: 'Delete',
        }] : []),
      ]);

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      canDeleteFinances,
      canReadMembers,
      canWaiveFees,
      canWritePayments,
      handleClickViewFinancialItem,
      handleClickViewMember,
      handleNavigateToPaymentPage,
      handleOpenSingleItemDeleteDialog,
      handleOpenUnwaiveFeeDialog,
      handleOpenWaiveFeeDialog,
    ],
  );

  const columnArgs = useMemo(() => ({
    extraColumns,
    schoolYearEnding,
  }), [
    extraColumns,
    schoolYearEnding,
  ]);

  const columns = useColumns(columnArgs);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={canWriteFinances ? {
            label: 'Fee',
            to: `/${PATHS.FINANCIAL_FEES_NEW}`,
          } : null}
          checkboxSelection
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          key={schoolYearEnding}
          loading={isLoading}
          onFilter={setFilteredRowIds}
          onSelectionModelChange={setSelectedFeeIds}
          rows={data}
          selectionModel={selectedFeeIds}
          tableResource={`financialFees-${schoolYearEnding}`}
          toolbarActions={toolbarActions}
          withSearch
        />
      </DataGridContainer>

      <SendFinancialStatementDialog
        isOpen={isSendStatementDialogOpen}
        memberIds={memberIdsToEmail}
        onClose={toggleSendStatementDialog}
      />

      <DialogUncategorizedFinancialFeeFileUpload />

      <DeleteDialogV2<GQL.IWaiveFinancialFeeOnMutationArguments>
        actionVerb="Waive"
        clearCachePredicates={waiveFeeClearCachePredicates}
        context={waiveFeeContext}
        descriptionText="The member will no longer be able to make payments for this fee."
        formatPayload={formatWaiveFeePayload}
        isOpen={isWaiveDialogOpen}
        mutation={WAIVE_FINANCIAL_FEE}
        onClose={toggleWaiveDialog}
        validationSchemaProps={{
          selectPayloadToValidate: (mutationPayload) => mutationPayload.input,
          validationSchema: waiveFinancialFeeSchema as any,
        }}
        withNote
      />

      <ConfirmationDialog
        confirmButtonAction={handleUnwaiveFee}
        description="This member will be reassigned the fee."
        handleClose={toggleUnwaiveDialog}
        isSubmitting={isUnwaivingFinancialFee}
        open={isUnwaiveDialogOpen}
        title="Unwaive Fee?"
      />

      <DeleteDialog
        clearCachePredicates={['financialFeesIndex']}
        context={['fee']}
        isOpen={isDeleteDialogOpen}
        mutation={DELETE_FINANCIAL_FEE}
        onClose={toggleDeleteDialog}
        reduxTableKey={`financialFees-${schoolYearEnding}`}
        singleItemId={singleItemDeleteId}
        size={data?.length ?? 0}
        withNote
      />
    </>
  );
};

export default FinancialFeesTable;
