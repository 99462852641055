// External Dependencies
import { Button } from '@mui/material';
import { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';

// Local Dependencies
import FindOrganizationDialog from './FindOrganizationDialog';
import InviteOrganizationDialog from './InviteOrganizationDialog';

// Local Variables
const useStyles = makeStyles({
  button: {
    marginRight: 8,
  },
});

// Component Definition
const AddOrganization: FC = () => {
  const classes = useStyles();

  const [isFindOrgDialogOpen, setIsFindOrgDialogOpen] = useState(false);
  const [isCreateOrgDialogOpen, setIsCreateOrgDialogOpen] = useState(false);

  const toggleFindOrgDialog = () => {
    setIsFindOrgDialogOpen(!isFindOrgDialogOpen);
  };

  const toggleCreateOrgDialog = () => {
    setIsCreateOrgDialogOpen(!isCreateOrgDialogOpen);
  };

  return (
    <>
      <Button
        className={classes.button}
        color="primary"
        onClick={toggleCreateOrgDialog}
        variant="outlined"
      >
        Invite New Organization
      </Button>
      <Button
        color="primary"
        onClick={toggleFindOrgDialog}
        variant="outlined"
      >
        Find Existing Organization
      </Button>

      <InviteOrganizationDialog
        isOpen={isCreateOrgDialogOpen}
        onClose={toggleCreateOrgDialog}
      />

      <FindOrganizationDialog
        isOpen={isFindOrgDialogOpen}
        onClose={toggleFindOrgDialog}
      />
    </>
  );
};

export default AddOrganization;
