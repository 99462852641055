// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';

// Local Dependencies
import DirectorPermissionsTable from './DirectorPermissionsTable';

// Component Definition
const AdminDirectorPermissions: FC = () => (
  <>
    <Subtitle>
      Director Permissions
    </Subtitle>

    <EnhancedCard>
      <DirectorPermissionsTable />
    </EnhancedCard>
  </>
);

export default AdminDirectorPermissions;
