// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';
import { navigate } from '@reach/router';
import { useTheme } from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { PATHS } from 'utils/constants/routes';

// Local Dependencies
import SignupCard from './SignupCard';
import SignupContainer from './SignupContainer';
import SignupRoleCardsContainer from './SignupRoleCardsContainer';
import StudentParentSignUpCards from './StudentParentSignUpCards';

// Local Variables
const rolesWithMoreChoices: DB.User.OnboardingRole[] = [
  'administrator',
  'director',
];

// Clicking on the CTA will send the user to a search-params-driven url
const handleClickCreateUser = (role: DB.User.OnboardingRole) => {
  // Admin and Director roles need to choose a further role
  if (rolesWithMoreChoices.includes(role)) {
    navigate(`/${PATHS.SIGNUP_CHOOSE_ROLE}?onboarding_role=${role}`);
  } else {
    navigate(`/${PATHS.SIGNUP}/${PATHS.CREATE_USER_PROFILE}?onboarding_role=${role}`);
  }
};

// Component Definition
const Signup: FC = () => {
  const theme = useTheme();
  const TRANSITION_DURATION = theme.transitions.duration.standard;

  return (
    <SignupContainer>
      <SignupRoleCardsContainer>
        <Typography
          className="title"
          component="h1"
          gutterBottom
          variant="h4"
        >
          Who are you?
        </Typography>

        <section className="card-container">
          {/* ADMINISTRATOR */}
          <SignupCard
            buttonText="Start for free"
            listItem1="You're a Fine Arts Administrator or Assistant"
            listItem2="You need a modern way to track inventory, uniforms, and more across the district"
            listItem3="You want to start your 30-day free trial"
            onClickButton={() => handleClickCreateUser('administrator')}
            subheader={`Set up your ${APP_NAME} district`}
            title="Administrator"
            transitionTimeout={TRANSITION_DURATION}
          />

          {/* DIRECTOR */}
          <SignupCard
            buttonText="Start for free"
            listItem1="You're a director for band, choir, dance, guitar, mariachi, orchestra, or theater"
            listItem2="You need a tool to track inventory, student data,  emails, and more"
            listItem3="You want to start your 30-day free trial"
            onClickButton={() => handleClickCreateUser('director')}
            subheader={`Set up your ${APP_NAME} organization`}
            title="Director"
            transitionTimeout={TRANSITION_DURATION * 2}
          />

          {/* PARENT and STUDENT */}
          <StudentParentSignUpCards />
        </section>
      </SignupRoleCardsContainer>
    </SignupContainer>
  );
};

export default Signup;
