// External Dependencies
import {
  Box,
  Container,
  Grid,
} from '@mui/material';
import { FC } from 'react';
import {
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { createUniformSchema } from '@presto-assistant/api_types/schemas/uniform';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';

// Internal Dependencies
import {
  DynamicFormFields,
  FormActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { UniformShowResponse } from 'gql/queries';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { hasPermission, isDistrictAdmin } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';

// Local Dependencies
import { convertCentsToDollars } from '@presto-assistant/api_types/utils';
import { createDistrictUniformSchema } from './schema';
import ShowUniformData from '../Show/ShowUniformData';
import UniformInfoFormFields from './UniformInfoFormFields';

// Local Typings
interface Props {
  canUseDynamicFields?: boolean;
  isAdmin?: boolean;
  onSubmit?: (values: UniformFormValues) => Promise<void>;
  readOnly?: boolean;
  title: string;
  uniform?: UniformShowResponse;
}
export interface UniformFormValues {
  categoryId: string;
  color: string;
  comments: string;
  conditionId: string | null;
  customBarcode?: string;
  orgTypeId?: string;
  purchaseValueInCents: number | null;
  purchasedAt: string | null;
  size: string;
  uniformNumber: string;
  uniformStyleId: string;
  uniformTypeId: number | string;
}
export interface AdminUniformFormValues extends UniformFormValues {
  organizationId: string;
}

// Local Variables
const StyledContainer = styled(Container)({
  width: 512,
});

// Component Definition
const UniformForm: FC<Props> = ({
  canUseDynamicFields,
  isAdmin,
  onSubmit,
  readOnly,
  title,
  uniform,
}) => {
  const canEditUniforms = useSelector(hasPermission('uniforms', 'edit'));
  const isDFA = useSelector(isDistrictAdmin);

  const canEdit = isDFA || canEditUniforms;

  const districtUniformsParams = useSelector(tableQueryParams('districtUniforms'));
  const uniformsParams = useSelector(tableQueryParams('uniforms'));

  const handlePressCancelOrBackButton = () => {
    if (isAdmin) {
      navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}${districtUniformsParams}`);
    } else {
      navigate(`/${PATHS.UNIFORMS}${uniformsParams}`);
    }
  };

  if (readOnly && !uniform) {
    return null;
  }

  const initialValues: UniformFormValues = {
    categoryId: uniform?.category?.id ?? '',
    color: uniform?.color ?? '',
    comments: uniform?.comments ?? '',
    conditionId: uniform?.condition?.id ?? null,
    customBarcode: uniform?.customBarcode ?? '',
    orgTypeId: uniform?.organization?.organizationType?.id ?? '1',
    purchaseValueInCents: uniform?.purchaseValueInCents
      ? convertCentsToDollars(uniform.purchaseValueInCents)
      : null,
    purchasedAt: uniform?.purchasedAt ?? '',
    size: uniform?.size ?? '',
    uniformNumber: uniform?.uniformNumber ?? '',
    uniformStyleId: uniform?.uniformStyle?.id ?? '',
    uniformTypeId: uniform?.uniformType.id ?? '',
    ...(canUseDynamicFields ? applyDynamicFields(uniform) : {}),
  };

  const adminInitialValues: AdminUniformFormValues = {
    ...initialValues,
    organizationId: uniform?.organization?.id ?? '',
  };

  const handleFormikSubmit = async (
    values: UniformFormValues | AdminUniformFormValues,
    formikProps: FormikHelpers<UniformFormValues>,
  ) => {
    if (readOnly) {
      return;
    }

    const { setSubmitting } = formikProps;

    await onSubmit?.(values);

    setSubmitting(false);
  };

  return (
    <Formik<UniformFormValues | AdminUniformFormValues>
      initialValues={isAdmin ? adminInitialValues : initialValues}
      onSubmit={handleFormikSubmit}
      validationSchema={isAdmin
        ? createDistrictUniformSchema
        : createUniformSchema}
    >
      {({
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => {
        const isFormTouched = Object.keys(touched).length > 0;

        return (
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <StyledContainer maxWidth={false}>
                <Box mb={2}>
                  <ShowCard
                    canEdit={canEdit}
                    icon={InfoIcon}
                    readOnly={readOnly}
                    title={title}
                  >
                    {readOnly && uniform ? (
                      <ShowUniformData
                        isAdmin={isAdmin}
                        uniform={uniform}
                      />
                    ) : (
                      <UniformInfoFormFields
                        isAdmin={isAdmin}
                        orgTypeId={values.orgTypeId}
                      />
                    )}
                  </ShowCard>
                </Box>

                <DynamicFormFields
                  isAdmin={Boolean(isAdmin)}
                  item={uniform}
                  organizationTypeId={isAdmin ? values.orgTypeId : undefined}
                  showCardProps={{
                    canEdit: canEditUniforms,
                    readOnly,
                  }}
                  tableRef="uniforms"
                />
              </StyledContainer>
            </Grid>

            {!readOnly && (
              <FormActions
                context="Uniform"
                isEditing={!!uniform}
                isFormTouched={isFormTouched}
                isSubmitting={isSubmitting}
                onPressCancelOrBackButton={handlePressCancelOrBackButton}
              />
            )}

          </Form>
        );
      }}
    </Formik>
  );
};

export default UniformForm;
