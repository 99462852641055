// External Dependencies
import { FC } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { useGetInventoryItem } from 'gql/queries';
import { useUpdateInventoryItem } from 'gql/mutations';
import InventoryForm,
{
  AdminInventoryPayloadValues,
  InventoryPayloadValues,
} from 'pages/Inventory/shared/InventoryForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Local Variables
const navigateToShow = (id: string) => {
  navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}/${id}`);
};

// Component Definition
const AdminInventoryEdit: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const {
    data,
    error,
    loading,
  } = useGetInventoryItem(id!);

  const [handleUpdateInventoryItem] = useUpdateInventoryItem(
    {
      clearCachePredicates: ['districtInventoryItemsIndex'],
      onCompleted: () => navigateToShow(id!),
    },
  );

  const handleSubmit = async (
    values: InventoryPayloadValues | AdminInventoryPayloadValues,
  ) => {
    await handleUpdateInventoryItem({
      variables: {
        id: id!,
        input: {
          ...values,
          onLoanToOrganizationId: (values.onLoanToOrganizationId === '-1' || values.onLoanToOrganizationId === '')
            ? null
            : values.onLoanToOrganizationId,
          ...applyDynamicFields(values as unknown as DynamicFields),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'Inventory Item Detail',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}/${id}`,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <InventoryForm
        canUseDynamicFields
        inventoryItem={data?.inventoryItem}
        isAdmin
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

export default AdminInventoryEdit;
