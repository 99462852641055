// External Dependencies
import {
  Box, Button, CardContent, Typography,
} from '@mui/material';
import { FC } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';

// Internal Dependencies
import {
  Container,
  EnhancedCard,
  Flex,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { getFullName } from 'utils';
import { useGetOrganization } from 'gql/queries';

// Local Typings
interface Props {
  inventoryItem: GQL.IInventoryItem;
}

// Local Variables
const StyledSpan = styled.span(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

// Component Definition
const InventoryCheckoutDetails: FC<Props> = ({
  inventoryItem,
}) => {
  const { data } = useGetOrganization();

  const organizationId = data?.organization?.id;

  const ownsInventoryItem = inventoryItem?.organization?.id === organizationId;

  const handleNavigateToCreateCheckoutPage = () => {
    navigate(`/${PATHS.INVENTORY_CHECKOUTS}/new?inventoryItem_id=${inventoryItem.id}`);
  };

  const handleNavigateToEditCheckoutPage = (checkoutId: string) => () => {
    navigate(`/${PATHS.INVENTORY_CHECKOUTS}/${checkoutId}/edit`);
  };

  const activeCheckouts = inventoryItem.checkouts
    .filter((item) => item.active);

  return (
    <Box mt={2}>
      <Container maxWidth={500}>
        <Subtitle>
          Checkout Status
        </Subtitle>

        <EnhancedCard>
          <CardContent>
            {activeCheckouts.map((checkout) => (
              <Flex
                justifyContent="space-between"
                key={checkout.id}
                sx={{ marginBottom: 2 }}
              >
                <Typography>
                  Checked out to{' '}
                  <StyledSpan>
                    {getFullName(checkout.user)}
                  </StyledSpan>
                </Typography>

                {(checkout.organization.id === organizationId || ownsInventoryItem) && (
                  <Button
                    color="primary"
                    onClick={handleNavigateToEditCheckoutPage(checkout.id)}
                    variant="outlined"
                  >
                    Edit Checkout
                  </Button>
                )}
              </Flex>
            ))}

            {activeCheckouts.length === 0 && (
              <Typography
                color="textSecondary"
                gutterBottom
              >
                No active checkouts
              </Typography>
            )}

            <Flex
              justifyContent="flex-end"
            >
              <Button
                color="primary"
                onClick={handleNavigateToCreateCheckoutPage}
                variant="contained"
              >
                New Checkout
              </Button>
            </Flex>
          </CardContent>
        </EnhancedCard>
      </Container>
    </Box>
  );
};

export default InventoryCheckoutDetails;
