// External Dependencies
import { FC, useCallback } from 'react';
import { navigate } from '@reach/router';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ViewIcon from '@mui/icons-material/ArrowForward';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  ListItemWithSecondaryAction,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
  pluralize,
} from 'utils';
import { useAdvanceEndOfYearStep } from 'gql/mutations';
import { useGetEndOfYearOutstandingItems, useGetOrganization } from 'gql/queries';

// Local Dependencies
import { StyledStrong } from './shared/styles';
import EndOfYearActionButtons from './shared/EndOfYearActionButtons';

// Local Variables
const inventoryTableCheckedOutFilter = `?${encodeURI('dataGridFilters={"items":[{"columnField":"checkedOutTo","operatorValue":"isNotEmpty","id":57787}],"linkOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}')}`;
const uniformsTableCheckedOutFilter = `?${encodeURI('dataGridFilters={"items":[{"columnField":"checkedOutTo","operatorValue":"isNotEmpty","id":26703}],"linkOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}')}`;
const feesTableBalanceDueNotWaivedFilter = `?${encodeURI('dataGridFilters={"items":[{"columnField":"balanceDueInCents","operatorValue":">","id":82664,"value":"0"},{"columnField":"isWaived","operatorValue":"is","id":36145,"value":"false"}],"linkOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}')}`;

// Component Definition
const EndOfYearItems: FC = () => {
  const { data: orgData, loading: isLoadingOrganization } = useGetOrganization();

  const {
    data: outstandingItemData,
    loading: isLoadingEoyOutstandingItems,
  } = useGetEndOfYearOutstandingItems({
    schoolYearEnding: orgData?.organization.currentSchoolYearEnding,
  });

  const [
    advanceEndOfYearStep,
    {
      loading: isAdvancingToNextStep,
    },
  ] = useAdvanceEndOfYearStep(
    {
      onCompleted: (response) => {
        // We send the user back to their current step
        navigate(`?step=${response.advanceEndOfYearStep.maxCompletedEndOfYearStep + 1}`);
      },
    },
  );

  const handlePressViewInventory = useCallback(() => {
    navigate(`/${PATHS.INVENTORY}${inventoryTableCheckedOutFilter}`);
  }, []);

  const handlePressViewUniforms = useCallback(() => {
    navigate(`/${PATHS.UNIFORMS}${uniformsTableCheckedOutFilter}`);
  }, []);

  const handlePressViewFees = useCallback(() => {
    navigate(`/${PATHS.FINANCIAL_FEES}${feesTableBalanceDueNotWaivedFilter}&schoolYearEnding=${orgData?.organization.currentSchoolYearEnding}`);
  }, [orgData]);

  if (isLoadingOrganization || isLoadingEoyOutstandingItems) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        marginTop={8}
      >
        <CircularProgress size={64} />
      </Box>
    );
  }

  if (!outstandingItemData) {
    return null;
  }

  const {
    financialFeesOverview: {
      totalNumberOfOutstandingFees,
      totalNumberOfUsersWithOutstandingBalance,
      totalOutstandingBalanceInCents,
    },
    inventoryCheckoutCount,
    uniformCheckoutCount,
  } = outstandingItemData;

  const totalOutstandingBalanceInDollars = displayPriceStringFromDollarAmount(
    convertCentsToDollars(totalOutstandingBalanceInCents),
  );

  const handlePressNextButton = () => {
    advanceEndOfYearStep({
      variables: {
        step: 1,
      },
    });
  };

  return (
    <>
      <Box mb={3}>
        <EnhancedAlert
          severity="info"
          title="Outstanding Items"
        >
          This list can help you track down items
          before switching to the next school year.

          <Box mt={1.5}>
            <Typography variant="body2">
              All outstanding items and fees can be taken care of next school year, too.
            </Typography>
          </Box>
        </EnhancedAlert>
      </Box>

      <Box mb={2}>
        <Subtitle>Inventory Items</Subtitle>

        <EnhancedCard>
          <List>
            <ListItemWithSecondaryAction
              primaryText={(
                <Typography>
                  You have{' '}
                  <StyledStrong>{inventoryCheckoutCount}</StyledStrong>{' '}
                  checked out inventory {pluralize(inventoryCheckoutCount, 'item')}.
                </Typography>
              )}
              secondaryAction={inventoryCheckoutCount > 0 ? {
                buttonIcon: <ViewIcon />,
                buttonText: 'View',
                onClick: handlePressViewInventory,
              } : undefined}
              secondaryText={
                inventoryCheckoutCount > 0
                  ? 'All checked out inventory items will carry over to the new school year.'
                  : undefined
              }
            />
          </List>
        </EnhancedCard>
      </Box>

      <Box mb={2}>
        <Subtitle>Uniforms</Subtitle>

        <EnhancedCard>
          <List>
            <ListItemWithSecondaryAction
              primaryText={(
                <Typography>
                  You have{' '}
                  <StyledStrong>{uniformCheckoutCount}</StyledStrong>{' '}
                  checked out uniform {pluralize(uniformCheckoutCount, 'item')}.
                </Typography>
              )}
              secondaryAction={uniformCheckoutCount > 0 ? {
                buttonIcon: <ViewIcon />,
                buttonText: 'View',
                onClick: handlePressViewUniforms,
              } : undefined}
              secondaryText={
                uniformCheckoutCount > 0
                  ? 'All checked out uniforms items will carry over to the new school year.'
                  : undefined
              }
            />
          </List>
        </EnhancedCard>
      </Box>

      <Box mb={2}>
        <Subtitle>Fees</Subtitle>

        <EnhancedCard>
          <List>
            <ListItemWithSecondaryAction
              primaryText={(
                <Typography>
                  You have{' '}
                  <StyledStrong>{totalNumberOfOutstandingFees}</StyledStrong>{' '}
                  outstanding {pluralize(totalNumberOfOutstandingFees, 'fee')} totaling{' '}
                  <StyledStrong>{totalOutstandingBalanceInDollars}</StyledStrong>{' '}
                  for{' '}
                  <StyledStrong>{totalNumberOfUsersWithOutstandingBalance}</StyledStrong>{' '}
                  {pluralize(totalNumberOfUsersWithOutstandingBalance, 'user')}.
                </Typography>
              )}
              secondaryAction={totalNumberOfOutstandingFees > 0 ? {
                buttonIcon: <ViewIcon />,
                buttonText: 'View',
                onClick: handlePressViewFees,
              } : undefined}
              secondaryText={
                totalNumberOfOutstandingFees > 0
                  ? 'All fees will carry over to the new school year. You can optionally waive fees in either school year.'
                  : undefined
              }
            />
          </List>
        </EnhancedCard>
      </Box>

      <EndOfYearActionButtons
        isSubmitting={isAdvancingToNextStep}
        onPressNextButton={handlePressNextButton}
      />
    </>
  );
};

export default EndOfYearItems;
