// External Dependencies
import { Box, CircularProgress, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { UserRoles } from '@presto-assistant/api_types';

// Internal Dependencies
import { EnhancedCard, TableDataGrid } from 'components/shared';
import { getFullName } from 'utils';
import {
  useGetAllMembers,
  useGetFinancialFundraiserCredit,
} from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { TableRow, useColumns } from './hooks';

// Local Typings
interface Props {
  financialFundraiserCreditId: string;
}

// Local Variables
const getRowId = (row: TableRow) => row.memberId;

// Component Definition
const FinancialFundraiserCreditMembersTable: FC<Props> = ({
  financialFundraiserCreditId,
}) => {
  const {
    data: allMembersData,
    loading: isMemberDataLoading,
  } = useGetAllMembers();

  const {
    data: financialFundraiserCreditData,
    loading: isFinancialFundraiserCreditLoading,
  } = useGetFinancialFundraiserCredit(financialFundraiserCreditId);

  const tableData = useMemo<TableRow[]>(() => {
    const memberIdsAssigned = financialFundraiserCreditData?.financialFundraiserCredit
      .memberIdsAssigned;

    return allMembersData?.allMembers.map((member) => {
      const isFundraiserCreditAssigned = memberIdsAssigned?.includes(member.id) ?? false;

      return {
        groups: member.groups,
        isFundraiserCreditAssigned,
        memberEmail: member.email,
        memberId: member.id,
        memberName: getFullName(member),
        primaryRoleLabel: member.primaryRole?.label ?? null,
        roleLabel: member.roleLabel,
      };
    }) ?? [];
  }, [
    allMembersData,
    financialFundraiserCreditData,
  ]);

  const isLoading = isMemberDataLoading || isFinancialFundraiserCreditLoading;

  const columns = useColumns();

  const studentMembers = useMemo(
    () => allMembersData?.allMembers
      .filter((member) => member.roleLabel === UserRoles[UserRoles.Student]) ?? [],
    [allMembersData],
  );

  const uniqueStudentsAssignedCount = useMemo(() => {
    const studentMemberIds = studentMembers.map((member) => member.id);
    const feeStudentMemberIds = financialFundraiserCreditData?.financialFundraiserCredit
      .memberIdsAssigned
      .filter((memberId) => studentMemberIds.includes(memberId)) ?? [];

    return new Set(feeStudentMemberIds).size;
  }, [
    financialFundraiserCreditData,
    studentMembers,
  ]);

  const studentCount = studentMembers.length;

  return (
    <EnhancedCard>
      <Box
        paddingLeft={2}
        paddingTop={2}
      >
        {isLoading
          ? <CircularProgress size={26} />
          : (
            <Typography
              color="textSecondary"
              gutterBottom
            >
              Assigned to {uniqueStudentsAssignedCount} of {studentCount} students
            </Typography>
          )}
      </Box>

      <DataGridContainer>
        <TableDataGrid
          columns={columns}
          getRowId={getRowId}
          loading={isLoading}
          rows={tableData}
          tableResource="financialItemMemberFees"
          withSearch
        />
      </DataGridContainer>
    </EnhancedCard>
  );
};

export default FinancialFundraiserCreditMembersTable;
