// External Dependencies
import {
  CardContent,
  CardHeader,
  IconButton,
} from '@mui/material';
import { FC } from 'react';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import {
  ShowPageDataDisplay,
} from 'components/shared';
import { hasPermission } from 'state/self/selectors';

// Local Dependencies
import EnhancedDataCard from './EnhancedDataCard';

// Local Typings
interface Props {
  inventoryCheckoutData: GQL.IInventoryCheckout;
}

/*
 *  Use this component in a flex box container with `flex-wrap: wrap`
 *  This will render 2 cards per row in our normal 500-600px columns
 */

// Component Definition
const InventoryDataCard: FC<Props> = ({
  inventoryCheckoutData,
}) => {
  const theme = useTheme();

  const canEditInventoryCheckouts = useSelector(hasPermission('inventoryCheckouts', 'edit'));

  const {
    checkoutDate,
    dueDate,
    id: inventoryCheckoutId,
    item: {
      brand,
      label,
      model,
      serialNumber,
    },
  } = inventoryCheckoutData;

  const handleClickEditIconButton = () => {
    navigate(`/${PATHS.INVENTORY_CHECKOUTS}/${inventoryCheckoutId}/edit`);
  };

  const action = inventoryCheckoutId && canEditInventoryCheckouts && (
    <IconButton
      aria-label="Edit"
      onClick={handleClickEditIconButton}
      size="large"
    >
      <EditIcon fontSize="small" />
    </IconButton>
  );

  return (
    <EnhancedDataCard
      backgroundColor={theme.palette.inventory.dataCard}
    >
      <CardHeader
        action={action}
        title="Inventory item"
      />
      <CardContent>
        <ShowPageDataDisplay
          label="Name"
          value={label}
        />
        <ShowPageDataDisplay
          label="Brand"
          value={brand}
        />
        <ShowPageDataDisplay
          label="Model"
          value={model}
        />
        <ShowPageDataDisplay
          label="Serial Number"
          value={serialNumber}
        />
        <ShowPageDataDisplay
          label="Checkout Date"
          value={checkoutDate}
        />
        <ShowPageDataDisplay
          label="Due Date"
          value={dueDate}
        />
      </CardContent>
    </EnhancedDataCard>
  );
};

export default InventoryDataCard;
