// External Dependencies
import { FinancialPaymentTypes, StripeStatus } from '@presto-assistant/api_types';
import { convertCentsToDollars } from '@presto-assistant/api_types/utils';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { dateColumn, dateTimeColumn } from 'utils/lib/tableColumns';
import { displayCell } from 'components/shared/TableV2';
import { useGetFinancialAccounts, useGetFinancialItems } from 'gql/queries';

// Local Dependencies
import { renderPaymentType } from '../../shared/StripeStatusChip';
import { useGetPaymentTypeSelectOptions } from './data';

// Local Variables
export const getRefundText = (stripeRefundStatusId: number | null) => {
  if (stripeRefundStatusId === StripeStatus.Failed) {
    return 'Refund Failed';
  }

  if (stripeRefundStatusId === StripeStatus.Pending) {
    return 'Pending Refund';
  }

  return 'Refunded';
};

export const useColumns = (
  schoolYearEnding: number,
) => {
  const {
    data: financialItemData,
  } = useGetFinancialItems(schoolYearEnding);

  const {
    data: financialAccountData,
  } = useGetFinancialAccounts();

  const {
    data: financialPaymentTypeSelectOptions,
  } = useGetPaymentTypeSelectOptions();

  const financialItemOptions = useMemo(() => {
    return (financialItemData?.financialItems.data.map((item) => ({
      label: item.label,
      // we must match on label because we can have duplicates of labels thanks to one-off fees
      value: item.label,
    })) ?? [])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [financialItemData?.financialItems.data]);

  const financialAccountOptions = useMemo(() => {
    return (financialAccountData?.financialAccounts.map((account) => ({
      label: account.label,
      value: account.label,
    })) ?? [])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [financialAccountData?.financialAccounts]);

  const financialPaymentTypeOptions = useMemo(() => {
    return (financialPaymentTypeSelectOptions?.financialPaymentTypes.map((option) => ({
      label: Number(option.id) === FinancialPaymentTypes.Stripe ? 'Online' : option.label,
      value: option.label,
    })) ?? [])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [financialPaymentTypeSelectOptions]);

  return useMemo<DataGridColDef<GQL.IFinancialPaymentIndexItem>[]>(() => {
    return [
      {
        field: 'userFirstName',
        headerName: 'First',
      },
      {
        field: 'userLastName',
        headerName: 'Last',
      },
      {
        field: 'isMemberActive',
        headerName: 'Active Member',
        type: 'boolean',
      },
      {
        field: 'financialItemLabel',
        headerName: 'Item',
        renderCell: ({ row }) => displayCell(row.financialItemLabel),
        type: 'singleSelect',
        valueOptions: financialItemOptions,
      },
      {
        field: 'financialAccountLabel',
        headerName: 'Account',
        renderCell: ({ row }) => displayCell(row.financialAccountLabel),
        type: 'singleSelect',
        valueOptions: financialAccountOptions,
      },
      dateTimeColumn({
        field: 'createdAt',
        headerName: 'Date Added',
      }),
      dateColumn({
        field: 'datePaid',
        headerName: 'Date Paid',
      }),
      {
        field: 'amountInCents',
        headerName: 'Payment Amount',
        renderCell: ({ row }) => displayCell(row.amountInCents, { format: 'money' }),
        type: 'number',
        valueGetter: ({ row }) => convertCentsToDollars(row.amountInCents),
      },
      {
        field: 'creditGeneratedInCents',
        headerName: 'Overpayment',
        renderCell: ({ row }) => displayCell(row.creditGeneratedInCents, { format: 'money' }),
        type: 'number',
        valueGetter: ({ row }) => convertCentsToDollars(row.creditGeneratedInCents),
      },
      {
        field: 'amountApplied' as keyof GQL.IFinancialPaymentIndexItem, // cannot have two columns with same field name
        headerName: 'Amount Applied',
        renderCell: ({ row }) =>
          displayCell(row.amountInCents + row.creditAppliedInCents - row.creditGeneratedInCents, {
            format: 'money',
          }),
        type: 'number',
        valueGetter: ({ row }) =>
          convertCentsToDollars(
            row.amountInCents + row.creditAppliedInCents - row.creditGeneratedInCents,
          ),
      },
      {
        field: 'creditAppliedInCents',
        headerName: 'Credit Applied',
        renderCell: ({ row }) => displayCell(row.creditAppliedInCents, { format: 'money' }),
        type: 'number',
        valueGetter: ({ row }) => convertCentsToDollars(row.creditAppliedInCents),
      },
      {
        field: 'financialPaymentTypeLabel',
        headerName: 'Payment Type',
        minWidth: 150,
        renderCell: ({ row }) => renderPaymentType({
          financialPaymentTypeId: Number(row.financialPaymentTypeId),
          financialPaymentTypeLabel: row.financialPaymentTypeLabel,
          stripePaymentStatusId: row.stripePaymentStatusId,
        }),
        type: 'singleSelect',
        valueGetter: ({ row }) => row.financialPaymentTypeLabel,
        valueOptions: financialPaymentTypeOptions,
      },
      {
        field: 'checkNumber',
        headerName: 'Check #',
      },
      {
        field: 'note',
        headerName: 'Note',
      },
    ];
  }, [financialAccountOptions, financialItemOptions, financialPaymentTypeOptions]);
};
