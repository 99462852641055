// External Dependencies
import { Box, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { FC } from 'react';

// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';
import { getFullName } from 'utils';

// Local Typings
interface Props {
  inventoryItem: GQL.IInventoryItem;
}

// Local Variables
const NoRowsOverlay: FC = () => (
  <Box
    alignItems="center"
    display="flex"
    justifyContent="center"
    height="100%"
    width="100%"
  >
    <Typography
      color="textSecondary"
      variant="body2"
    >
      This item has never been checked out.
    </Typography>
  </Box>
);

const columns: GridColDef[] = [
  { field: 'checkoutDate', flex: 1, headerName: 'Checkout Date' },
  { field: 'checkinDate', flex: 1, headerName: 'Checkin Date' },
  {
    field: 'active', flex: 1, headerName: 'Is Checked Out', type: 'boolean',
  },
  {
    field: 'user', flex: 1, headerName: 'Checked Out To', valueGetter: ({ row }) => getFullName(row.user),
  },
];

// Component Definition
const InventoryCheckoutHistoryList: FC<Props> = ({
  inventoryItem,
}) => (
  <Box marginTop={2}>
    <Subtitle>
      Checkout History
    </Subtitle>

    <EnhancedCard>
      <DataGridPro
        autoHeight
        columns={columns}
        disableSelectionOnClick
        rows={inventoryItem.checkouts}
        components={{
          NoRowsOverlay,
        }}
      />
    </EnhancedCard>
  </Box>
);

export default InventoryCheckoutHistoryList;
