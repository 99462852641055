// External Dependencies
import { FC, useEffect, useMemo } from 'react';
import { FormBlockTypes } from '@presto-assistant/api_types';

// Local Dependencies
import { useDebounce } from 'use-debounce';
import CheckboxBlock from './CheckboxBlock';
import SelectBlock from './SelectBlock';
import SignatureBlock from './SignatureBlock';
import TextBlock from './TextBlock';

// Local Typings
interface Props {
  formBlock: GQL.IMyFormBlock;
  onFormBlockSubmit: (values: GQL.ISubmitFormBlockResponseInput) => void;
  setIsFormDirty: (isFormDirty: boolean) => void;
  values: GQL.ISubmitFormBlockResponseInput;
}

// Component Definition
const FormContent: FC<Props> = ({
  formBlock,
  onFormBlockSubmit,
  setIsFormDirty,
  values,
}) => {
  const initialResponse = useMemo(() => {
    return formBlock.response ?? '';
    // we don't want to update the initial response when the form block changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [debouncedResponse] = useDebounce(values.response, 500);

  const isFormDirty = useMemo(() => {
    return values.response.toString() !== formBlock.response?.toString();
  }, [formBlock, values]);

  useEffect(() => {
    setIsFormDirty(isFormDirty);
  }, [isFormDirty, setIsFormDirty]);

  useEffect(() => {
    if (debouncedResponse !== initialResponse) {
      onFormBlockSubmit({
        ...values,
        response: debouncedResponse.toString(),
      });
    }
  // we don't want to submit on every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedResponse, initialResponse]);

  const content = useMemo(() => {
    const formBlockTypeId = Number(formBlock.formBlockType.id);

    switch (formBlockTypeId) {
      case FormBlockTypes.Text:
        return <TextBlock formBlock={formBlock} />;
      case FormBlockTypes.TextArea:
        return (
          <TextBlock
            formBlock={formBlock}
            isTextArea
          />
        );
      case FormBlockTypes.Select:
        return <SelectBlock formBlock={formBlock} />;
      case FormBlockTypes.Boolean:
        return <CheckboxBlock formBlock={formBlock} />;
      case FormBlockTypes.Date:
        return (
          <TextBlock
            formBlock={formBlock}
            type="date"
          />
        );
      case FormBlockTypes.Number:
        return (
          <TextBlock
            formBlock={formBlock}
            type="number"
          />
        );
      case FormBlockTypes.Signature:
        return <SignatureBlock formBlock={formBlock} />;
      default:
        return null;
    }
  }, [formBlock]);

  return content;
};

export default FormContent;
