// External Dependencies
import {
  GridRenderEditCellParams,
} from '@mui/x-data-grid-pro';
import { convertDollarsToCents } from '@presto-assistant/api_types/utils';
import isEqual from 'lodash.isequal';

// Internal Dependencies
import { displayCell } from 'components/shared/TableV2';

// Local Dependencies
import EditInputCell from './EditInputCell';
import SelectEditInputCell, { SelectOption } from './SelectEditInputCell';

/*
 * Must be used in a MUI DataGrid context.
 *
 * Use the renderEditCell function for a cell that uses yup validation.
 */
export const renderEditCell = (
  params: GridRenderEditCellParams,
) => <EditInputCell {...params} />;

export const renderSelectEditInputCell = ({
  options,
  params,
}: {
  options: SelectOption[] | undefined,
  params: GridRenderEditCellParams,
}) => (
  <SelectEditInputCell
    options={options}
    params={params}
  />
);

export const renderMoneyCell = (value: string | number) => (Number.isFinite(Number(value))
  ? displayCell(convertDollarsToCents(value as number), { format: 'money' })
  : value);

export function computeDataMutation<T>({
  newRow,
  oldRow,
  optionalKeysToCompare,
  requiredKeysToCompare,
} : {
  newRow: T,
  oldRow: T,
  optionalKeysToCompare: (keyof T)[],
  requiredKeysToCompare: (keyof T)[],
}): boolean | null {
  let hasChangedValue = false;
  let hasClearedValue = false;
  let missingRequiredValue = false;

  const allKeys = [...new Set([
    ...requiredKeysToCompare,
    ...optionalKeysToCompare,
  ])];

  allKeys.forEach((key) => {
    const isRequired = !optionalKeysToCompare.includes(key);

    // If the values are changing from null to undefined or vice versa,
    //  we will not consider that a change
    const areBothValuesNullOrUndefined = (oldRow[key] === null && newRow[key] === undefined)
      || (oldRow[key] === undefined && newRow[key] === null);

    const doesValueExist = Boolean(oldRow[key] || newRow[key]);

    // We don't consider a change from null to undefined or vice versa,
    //  so we call that a "match"
    const doValuesMatch = areBothValuesNullOrUndefined || isEqual(oldRow[key], newRow[key]);

    if (!doValuesMatch) {
      hasChangedValue = true;
    }

    if (isRequired && !newRow[key]) {
      missingRequiredValue = true;
    }

    if (!isRequired && !doesValueExist) {
      hasClearedValue = true;
    }
  });

  if (hasChangedValue || missingRequiredValue) {
    return true;
  }

  if (hasClearedValue) {
    return false;
  }

  return null;
}
