import { CustomSelect } from 'components/shared';
import { FC } from 'react';

interface Props {
  formBlock: GQL.IMyFormBlock;
}

const SelectBlock: FC<Props> = ({
  formBlock,
}) => {
  const options: string[] = formBlock.metadata?.options ?? [];

  return (
    <CustomSelect
      label={formBlock.label}
      name="response"
      options={options.map((option) => ({
        id: option,
        label: option,
      }))}
    />
  );
};

export default SelectBlock;
