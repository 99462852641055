// External Dependencies
import DeleteIcon from '@mui/icons-material/Delete';

// Internal Dependencies
import {
  TableColumn, createActionsColumn, displayCell, displayDynamicFieldCell,
} from 'components/shared/TableV2';
import { useGetDynamicFields } from 'gql/queries';
import { useMemo } from 'react';

export const useColumns = (
  onDelete: (row: GQL.IDistrictLibraryIndexItem) => void,
  organizationTypeId: string | undefined,
) => {
  const { data: dynamicFieldData } = useGetDynamicFields({
    organizationTypeId,
    tableRef: 'library_items',
  }, {
    skip: !organizationTypeId,
  });

  const hasDynamicFieldNamedComments = dynamicFieldData?.dynamicFields.some((dynamicField) => dynamicField.label === 'Comments');

  return useMemo(() => {
    const columns: TableColumn<GQL.IDistrictLibraryIndexItem>[] = [
      {
        Header: 'Category',
        accessor: (row) => displayCell(row.categoryLabel),
        sortBy: 'categoryLabel',
      },
      {
        Header: 'Voice/Instrumentation',
        accessor: (row) => displayCell(row.instrumentationLabel),
        sortBy: 'instrumentationLabel',
      },
      {
        Header: 'Title',
        accessor: (row) => displayCell(row.title),
        sortBy: 'title',
      },
      {
        Header: 'Composer',
        accessor: (row) => displayCell(row.composer),
        sortBy: 'composer',
      },
      {
        Header: 'Arranger',
        accessor: (row) => displayCell(row.arranger),
        sortBy: 'arranger',
      },
      {
        Header: 'Number',
        accessor: (row) => displayCell(row.number),
        sortBy: 'number',
      },
      {
        Header: 'Publication Date',
        accessor: (row) => displayCell(row.year),
        sortBy: 'year',
      },
      {
        Header: 'Grade',
        accessor: (row) => displayCell(row.grade),
        sortBy: 'grade',
      },
      ...(!hasDynamicFieldNamedComments ? [{
        Header: 'Comments',
        accessor: (row: GQL.IDistrictLibraryIndexItem) => displayCell(row.comments),
      }] : []),
      ...(dynamicFieldData?.dynamicFields ?? [])
        .map<TableColumn<GQL.IDistrictLibraryIndexItem>>((field) => ({
          Header: field.label,
          accessor: (row) => displayDynamicFieldCell(field, row),
          sortBy: field.dynamicFieldRef,
        })),
    ];

    const actionsColumn = createActionsColumn<GQL.IDistrictLibraryIndexItem>([
      {
        action: onDelete,
        icon: <DeleteIcon />,
        text: 'Delete',
      },
    ]);

    if (actionsColumn) {
      columns.push(actionsColumn);
    }

    return columns;
  }, [
    hasDynamicFieldNamedComments,
    onDelete,
    dynamicFieldData,
  ]);
};
