// External Dependencies
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  DialogActions,
  DialogTitle,
  Typography,
  Zoom,
} from '@mui/material';
import {
  FC,
  useEffect,
} from 'react';
import { navigate } from '@reach/router';

// Internal Dependencies
import {
  CalendarSvg,
  Container,
  EnhancedCard,
  Page,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetOrganization } from 'gql/queries';

// Local Typings
interface Props {
  fullNextSchoolYear: string;
  isOpen: boolean;
  toggleIsOpen: () => void;
}

// Local Variables
const handlePressGoToDashboard = () => {
  navigate(PATHS.DASHBOARD);
};

const handleRedirectToEndOfYearProcess = () => {
  navigate(PATHS.END_OF_YEAR);
};

// Component Definition
const EndOfYearSuccess: FC<Props> = () => {
  const {
    data: orgData,
    loading: isLoadingOrganization,
  } = useGetOrganization();

  // If a user goes directly to this route but has started the EOY process,
  //  then we redirect them back to the EOY process steps
  useEffect(() => {
    if (orgData?.organization
      && orgData?.organization?.maxCompletedEndOfYearStep !== null) {
      handleRedirectToEndOfYearProcess();
    }
  }, [orgData]);

  if (isLoadingOrganization) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        mt={3}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!orgData?.organization) {
    return null;
  }

  const { currentSchoolYearEnding } = orgData.organization;

  // The "current" school year is the "next" one now
  const fullCurrentSchoolYear = `${currentSchoolYearEnding - 1}-${currentSchoolYearEnding}`;

  return (
    <Page>
      <Container maxWidth={500}>
        <EnhancedCard>
          <DialogTitle>Moving on up</DialogTitle>

          <CardContent>
            <Box
              display="flex"
              justifyContent="center"
              mb={3}
            >
              <Zoom in>
                <div>
                  <CalendarSvg
                    height="160px"
                    width="160px"
                  />
                </div>
              </Zoom>
            </Box>

            <Typography sx={{ mb: 3 }}>
              <strong>End of Year Process is complete!</strong>
            </Typography>

            <Typography sx={{ mb: 3 }}>
              Make sure to add any new students, parents, and directors.
            </Typography>

            <Typography>
              It&apos;s time to kick off the{' '}
              <strong>{fullCurrentSchoolYear}</strong>{' '}
              school year.
            </Typography>
          </CardContent>

          <DialogActions>
            <Button
              color="primary"
              onClick={handlePressGoToDashboard}
              variant="contained"
            >
              Back to the Dashboard
            </Button>
          </DialogActions>
        </EnhancedCard>
      </Container>
    </Page>
  );
};

export default EndOfYearSuccess;
