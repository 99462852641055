// External Dependencies
import { Box, Divider, InputAdornment } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import {
  CustomCheckbox,
  CustomInput,
  MoneyInput,
} from 'components/shared';

// Local Variables
const startAdornment = <InputAdornment position="start">$</InputAdornment>;

// Component Definition
const LibraryAdditionalFormFields: FC = () => (
  <>
    <CustomInput
      label="Author"
      name="author"
    />

    <CustomInput
      label="Language"
      name="language"
    />

    <CustomInput
      label="Last Performance"
      name="lastPerformance"
    />

    <CustomInput
      label="Number of Copies"
      name="numberOfCopies"
      type="number"
    />

    <CustomInput
      label="Period"
      name="period"
    />

    <CustomInput
      InputProps={{
        inputComponent: MoneyInput as any,
        startAdornment,
      }}
      label="Price"
      name="priceInCents"
    />

    <CustomInput
      label="Publisher"
      name="publisher"
    />

    <CustomInput
      label="State Code"
      name="stateCode"
    />

    <CustomInput
      label="Major Work"
      name="majorWork"
    />

    <CustomInput
      label="Accompaniment"
      name="accompaniment"
    />

    <Divider sx={{ marginY: 1 }} />

    <Box>
      <CustomCheckbox
        label="Out of Print"
        name="isOutOfPrint"
      />
    </Box>

    <Box>
      <CustomCheckbox
        label="Solo"
        name="isSolo"
      />
    </Box>
  </>
);

export default LibraryAdditionalFormFields;
