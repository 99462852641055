// External Dependencies
import { FC, useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { DashboardCard } from 'components/shared';
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils';

// Local Typings
interface Props {
  itemsWithHighestBalance: GQL.IItemWithBalance[] | undefined;
}

// Local Variables
const StyledStrong = styled.strong({
  fontWeight: 'bold',
});
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  backgroundColor: `${theme.palette.prestoPrimary}44`,
  borderRadius: 6,
  height: 12,
}));

const INITIAL_DISPLAY_COUNT = 4;

interface IHighestBalanceItemProps {
  isLast: boolean;
  item: GQL.IItemWithBalance;
}

const HighestBalanceItem: FC<IHighestBalanceItemProps> = ({ isLast, item }) => {
  const totalBalanceInCents = item.balancePaidInCents + item.balanceDueInCents;

  const percentagePaid = Math.floor(
    (item.balancePaidInCents / totalBalanceInCents) * 100,
  );

  return (
    <Box
      key={item.id}
      paddingY={1}
    >
      <Box
        alignItems="flex-end"
        display="flex"
        justifyContent="space-between"
        marginBottom={1}
      >
        <Typography>
          {item.label}
        </Typography>

        <Typography>
          <StyledStrong>
            {displayPriceStringFromDollarAmount(
              convertCentsToDollars(item.balanceDueInCents),
            )}
          </StyledStrong>
        </Typography>
      </Box>

      <StyledLinearProgress
        aria-label={`${item.label} - ${percentagePaid} percent paid`}
        value={percentagePaid}
        variant="determinate"
      />

      <Box
        display="flex"
        justifyContent="flex-end"
        marginTop={0.5}
      >
        <Typography variant="caption">
          {displayPriceStringFromDollarAmount(
            convertCentsToDollars(item.balancePaidInCents),
          )}
          {' '}/{' '}
          {displayPriceStringFromDollarAmount(
            convertCentsToDollars(totalBalanceInCents),
          )}
          {' '}
          ({percentagePaid}%)
        </Typography>
      </Box>

      <Box marginTop={1}>
        {!isLast && (
          <Divider />
        )}
      </Box>
    </Box>
  );
};

// Component Definition
const ItemWithHighestBalanceCard: FC<Props> = ({
  itemsWithHighestBalance,
}) => {
  const [showMore, setShowMore] = useState(false);

  const itemsToShow = useMemo(() => {
    return showMore
      ? itemsWithHighestBalance
      : itemsWithHighestBalance?.slice(0, INITIAL_DISPLAY_COUNT);
  }, [itemsWithHighestBalance, showMore]);

  return (
    <DashboardCard
      content={itemsToShow?.length ? (
        <>
          <TransitionGroup component={List}>
            {itemsToShow?.map((item, index) => {
              const isLast = (index === itemsToShow.length - 1);

              return (
                <Collapse
                  component="li"
                  in
                  key={item.id}
                >
                  <HighestBalanceItem
                    isLast={isLast}
                    item={item}
                  />
                </Collapse>
              );
            })}
          </TransitionGroup>
          {(itemsWithHighestBalance?.length ?? 0) > INITIAL_DISPLAY_COUNT && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => setShowMore(!showMore)}
                size="small"
              >
                {showMore ? 'Show Less' : 'Show More'}
              </Button>
            </Box>
          )}
        </>

      ) : (
        <Typography
          color="textSecondary"
          variant="body2"
        >
          No items with outstanding fees
        </Typography>
      )}
      title="Items with Highest Balance"
    />
  );
};

export default ItemWithHighestBalanceCard;
