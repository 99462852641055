// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { UserRoles } from '@presto-assistant/api_types';
import { useMemo } from 'react';

// Internal Dependencies
import { GroupsInput } from 'pages/People/Students/All/StudentsTable/hooks';
import { mapEnum } from 'utils/lib/map_enum';
import { useGetOrganizationRoles } from 'gql/queries';

// Local Typings
export interface TableRow {
  groups: GQL.IMemberIndex['groups'];
  isFeeAssigned: boolean;
  memberEmail: string;
  memberId: string;
  memberName: string;
  numberOfFeesAssigned: number;
  primaryRoleLabel: string | null;
  roleLabel: string;
}

export const useColumns = () => {
  const {
    data: organizationRoleData,
  } = useGetOrganizationRoles();

  return useMemo<GridColDef<TableRow>[]>(() => [
    {
      field: 'memberEmail',
      headerName: 'Member Email',
    },
    {
      field: 'memberName',
      headerName: 'Member Name',
    },
    {
      field: 'roleLabel',
      headerName: 'Role',
      type: 'singleSelect',
      valueOptions: mapEnum(UserRoles).map((option) => ({
        label: option.label,
        value: option.label,
      })),
      width: 80,
    },
    {
      field: 'primaryRoleLabel',
      headerName: 'Primary Role', // TODO: make this say "instrument" for band, orch; "part" for choir; "role" for theater, dance
      type: 'singleSelect',
      valueGetter: (params) => (params.row as TableRow).primaryRoleLabel,
      valueOptions: organizationRoleData?.organizationRoles.map((role) => ({
        label: role.label,
        value: role.label,
      })),
    },
    {
      field: 'groups',
      filterOperators: [
        {
          InputComponent: GroupsInput,
          getApplyFilterFn: (filterItem) => {
            if (
              !filterItem.columnField
              || !filterItem.value
              || !filterItem.operatorValue
            ) {
              return null;
            }

            return (params): boolean => {
              const groupIds = (params.row as TableRow).groups.map((g) => g.id);

              const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
              const selectedGroupIds = selectedGroups.map((g) => g.id);

              if (!selectedGroups.length) {
                return true;
              }

              return groupIds.some((id) => selectedGroupIds.includes(id));
            };
          },
          label: 'is any of',
          value: 'isAnyOf',
        },
        {
          InputComponent: GroupsInput,
          getApplyFilterFn: (filterItem) => {
            if (
              !filterItem.columnField
              || !filterItem.value
              || !filterItem.operatorValue
            ) {
              return null;
            }

            return (params): boolean => {
              const groupIds = (params.row as TableRow).groups.map((g) => g.id);

              const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
              const selectedGroupIds = selectedGroups.map((g) => g.id);

              if (!selectedGroupIds.length) {
                return true;
              }

              return selectedGroupIds.every((id) => groupIds.includes(id));
            };
          },
          label: 'is all of',
          value: 'isAllOf',
        },
        {
          InputComponent: GroupsInput,
          getApplyFilterFn: (filterItem) => {
            if (
              !filterItem.columnField
              || !filterItem.value
              || !filterItem.operatorValue
            ) {
              return null;
            }

            return (params): boolean => {
              const groupIds = (params.row as TableRow).groups.map((g) => g.id);

              const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
              const selectedGroupIds = selectedGroups.map((g) => g.id);

              if (!selectedGroupIds.length) {
                return true;
              }

              return selectedGroupIds.every((id) => !groupIds.includes(id));
            };
          },
          label: 'is not in',
          value: 'isNotIn',
        },
      ],
      headerName: 'Groups',
      valueGetter: (params) => (params.row as TableRow).groups
        .map((group) => group.label).join('; '),
    },
    {
      field: 'isFeeAssigned',
      headerName: 'Is Fee Assigned?',
      type: 'boolean',
    },
    {
      field: 'numberOfFeesAssigned',
      headerName: '# Assigned',
      type: 'number',
    },
  ], [organizationRoleData]);
};
