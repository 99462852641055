// External Dependencies
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { useGetOrganization } from 'gql/queries';
import { useUpdateOrganization } from 'gql/mutations';

// Component Definition
const CoverStripeFeeSetting: FC = () => {
  const { data: organizationData } = useGetOrganization();

  const organization = organizationData?.organization;

  const [
    isCoveringStripeFeesByDefault,
    setIsCoveringStripeFeesByDefault,
  ] = useState(Boolean(organization?.isCoveringStripeFeeByDefault));

  useEffect(() => {
    setIsCoveringStripeFeesByDefault(Boolean(organization?.isCoveringStripeFeeByDefault));
  }, [organization?.isCoveringStripeFeeByDefault]);

  const [updateOrganization] = useUpdateOrganization();

  const handleChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const isChecked = evt.target.checked;

    setIsCoveringStripeFeesByDefault(isChecked);

    if (organization) {
      updateOrganization({
        variables: {
          addressOne: organization.addressOne,
          addressTwo: organization.addressTwo,
          ccDirectors: organization.ccDirectors,
          city: organization.city,
          code: organization.code,
          entityTypeId: organization.entityType.id,
          isCoveringStripeFeeByDefault: isChecked,
          label: organization.label,
          phoneNumber: organization.phoneNumber,
          stateId: organization.stateId,
          zipcode: organization.zipcode,
        },
      });
    }
  }, [organization, updateOrganization]);

  return (
    <>
      <Typography>
        Stripe Settings
      </Typography>

      <FormControlLabel
        control={(
          <Switch
            checked={isCoveringStripeFeesByDefault}
            color="primary"
            inputProps={{ 'aria-label': 'Cover Stripe fees', name: 'isCoveringStripeFeeByDefault' }}
            onChange={handleChange}
          />
        )}
        label="Cover Stripe fees by default"
        labelPlacement="start"
      />
    </>
  );
};

export default CoverStripeFeeSetting;
