// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

const GET_FINANCIAL_PAYMENT_TYPES = gql`
  query FinancialPaymentTypes {
    financialPaymentTypes {
      id
      label
    }
  }
`;

export const useGetPaymentTypeSelectOptions = () =>
  useQueryEnhanced<{
    financialPaymentTypes: GQL.IFinancialPaymentType[]
  }>(GET_FINANCIAL_PAYMENT_TYPES);
