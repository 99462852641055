// External Dependencies
import { FC, useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { convertDollarsToCents } from '@presto-assistant/api_types/utils';
import {
  createFinancialFundraiserCreditSchema,
} from '@presto-assistant/api_types/schemas/financialFundraiserCredits';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import { Container, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useCreateFinancialFundraiserCredit } from 'gql/mutations';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';

// Local Dependencies
import FinancialFundraiserCreditForm from '../shared/FinancialFundraiserCreditForm';

// Component Definition
const FinancialFundraiserCreditsNew: FC = () => {
  const schoolYearEnding = useGetOrganizationSchoolYear();

  const [
    createFundraiserCredit,
    {
      loading: isSubmitting,
    },
  ] = useCreateFinancialFundraiserCredit({
    onCompleted: (data) => {
      navigate(`/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}/${data.createFinancialFundraiserCredit.id}`);
    },
  });

  const initialValues = useMemo<GQL.ICreateFinancialFundraiserCreditInput>(() => ({
    amountInCents: 0,
    financialAccountId: '',
    items: [],
    label: '',
    schoolYearEnding,
  }), [schoolYearEnding]);

  const handleSubmit = useCallback((values: GQL.ICreateFinancialFundraiserCreditInput) => {
    createFundraiserCredit({
      variables: {
        input: {
          ...values,
          amountInCents: convertDollarsToCents(values.amountInCents),
          schoolYearEnding: Number(values.schoolYearEnding),
        },
      },
    });
  }, [createFundraiserCredit]);

  const fundraiserCreditsParams = useSelector(
    tableQueryParams('financialFundraiserCredits'),
  );

  const handlePressCancelOrBackButton = () => {
    navigate(`/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}${fundraiserCreditsParams}`);
  };

  return (
    <Page
      backButtonProps={{
        label: 'Financial Fundraiser Credits',
        path: `/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}${fundraiserCreditsParams}`,
      }}
    >
      <Container maxWidth={CONTAINER_WIDTH}>
        <Formik<GQL.ICreateFinancialFundraiserCreditInput>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={createFinancialFundraiserCreditSchema}
        >
          {({
            errors,
            touched,
            values,
          }) => (
            <FinancialFundraiserCreditForm
              errors={errors}
              financialAccountIdFormikValue={values.financialAccountId}
              isSubmitting={isSubmitting}
              itemsFormikValue={values.items}
              onPressCancelOrBackButton={handlePressCancelOrBackButton}
              schoolYearEndingFormikValue={Number(values.schoolYearEnding)}
              touched={touched}
            />
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default FinancialFundraiserCreditsNew;
