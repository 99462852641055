// External Dependencies
import {
  GridRowModel,
} from '@mui/x-data-grid-pro';
import { useCallback } from 'react';

// Internal Dependencies
import {
  GET_ALL_DISTRICT_DIRECTOR_PERMISSIONS,
  useGetAllDistrictDirectorPermissions,
} from 'gql/queries/permission-queries';
import { TableDataGrid } from 'components/shared';
import { useUpdateUserPermissionsByDistrictAdmin } from 'gql/mutations';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import DirectorPermissionsTableZeroState from './DirectorPermissionsTableZeroState';

// Local Variables
const getAllowedPermissionIds = (permissions: GQL.IPermissions) => {
  const permissionSets: GQL.IPermissionActions[] = Object.values(permissions);

  const allowedPermissionIds: number[] = permissionSets.reduce<number[]>((prev, curr) => {
    const permissionItems: GQL.IPermissionDetails[] = Object.values(curr);

    const newPermissionIds = permissionItems
      .filter((pd) => pd.allowed)
      .map((pd) => pd.permissionId);

    prev.push(...newPermissionIds);

    return prev;
  }, []);

  return allowedPermissionIds;
};

const leftPinnedColumns = ['email'];

// Component Definition
const DirectorPermissionsTable = () => {
  const {
    data,
    loading: isLoading,
  } = useGetAllDistrictDirectorPermissions();

  const [updateUserPermissions] = useUpdateUserPermissionsByDistrictAdmin(
    {
      refetchQueries: [{ query: GET_ALL_DISTRICT_DIRECTOR_PERMISSIONS }],
    },
  );

  const columns = useColumns();

  const processRowUpdate = useCallback(
    (newRow: GridRowModel<GQL.IMemberPermission>, oldRow: GridRowModel<GQL.IMemberPermission>) =>
      new Promise<GridRowModel>((resolve) => {
        const oldAllowedPermissionIds = getAllowedPermissionIds(oldRow.permissions);
        const allowedPermissionIds = getAllowedPermissionIds(newRow.permissions);

        const permissionsChanged = oldAllowedPermissionIds.sort().toString()
          !== allowedPermissionIds.sort().toString();
        const mayEditChanged = oldRow.mayEditPermissions !== newRow.mayEditPermissions;

        const dataWasMutated = permissionsChanged || mayEditChanged;

        if (dataWasMutated) {
          updateUserPermissions({
            variables: {
              mayEditPermissions: newRow.mayEditPermissions,
              organizationId: newRow.organization.id,
              permissionIds: allowedPermissionIds,
              userId: newRow.member.id,
            },
          });

          resolve(newRow); // Update the row with the new values
        }

        resolve(oldRow); // Nothing was changed
      }),
    [updateUserPermissions],
  );

  return (
    <DataGridContainer>
      <TableDataGrid
        columns={columns}
        components={{
          NoRowsOverlay: DirectorPermissionsTableZeroState,
        }}
        getRowId={(row: GQL.IMemberPermission) => row.member.id}
        leftPinnedColumns={leftPinnedColumns}
        loading={isLoading}
        persistColumnVisibility={false}
        processRowUpdate={processRowUpdate}
        tableResource="directorPermissions"
        rows={data?.allDistrictDirectorPermissions ?? []}
        withEditMode
        withSearch
      />
    </DataGridContainer>
  );
};

export default DirectorPermissionsTable;
