// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

// Internal Dependencies
import { EnhancedIconButton, TableV2 } from 'components/shared';
import { GET_GROUP, useGetGroupUsers } from 'gql/queries';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { REMOVE_MEMBERS_FROM_GROUP } from 'gql/mutations';
import { SELECTION_TYPES } from 'utils/constants';
import { createActionsColumn } from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import {
  isTableSelectionActive,
  tableSelection,
} from 'state/table/selectors';
import { updateRecipients } from 'state/ui/emailNew/actions';
import { useClearTableSelection } from 'hooks/useClearTableSelection';
import { useIsOpen } from 'hooks/useIsOpen';
import DeleteDialog from 'components/shared/DeleteDialog';

// Local Dependencies
import { useColumns } from './hooks';

// Local Typings
interface Props {
  groupData: GQL.IGroup;
  groupId: string;
  isLoading: boolean;
}

// Component Definition
const GroupTableUI: FC<Props> = ({
  groupData,
  groupId,
  isLoading,
}) => {
  const [singleItemDeleteId, setSingleItemDeleteId] = useState<string | null>(null);

  const dispatch = useDispatch();

  const canEditGroups = useSelector(hasPermission('groups', 'edit'));
  const canWriteEmail = useSelector(hasPermission('emailMembers', 'write'));
  const canReadUsers = useSelector(hasPermission('users', 'read'));

  const handleRemoveSelectionAll = useClearTableSelection('group');
  const isSelectionActive = useSelector(isTableSelectionActive('group'));

  const selection = useSelector(tableSelection('group'));

  const {
    data,
    loading,
  } = useGetGroupUsers(groupId);

  const {
    isOpen: isRemoveGroupMemberDialogOpen,
    toggleIsOpen: toggleRemoveGroupMemberDialog,
  } = useIsOpen();

  const handleOpenSingleItemDeleteDialog = useCallback(
    (row: GQL.IUser) => {
      setSingleItemDeleteId(row.id);
      toggleRemoveGroupMemberDialog();
    },
    [toggleRemoveGroupMemberDialog],
  );

  const listOfUserIds = groupData.users.userList.map((user: GQL.IUser) => user?.id);

  const handleClickEmailSelectedMembers = () => {
    const recipientsToEmail = selection.selectionType === SELECTION_TYPES.SELECTED_ALL
      ? listOfUserIds
      : selection.ids;

    // Add selected ids for the current group to the recipient ids for a new email
    dispatch(updateRecipients(recipientsToEmail));
    navigate(`/${PATHS.EMAIL_NEW}`);
  };

  const selectionIconElements: React.ReactElement[] = [];

  if (canEditGroups) {
    selectionIconElements.unshift(
      <EnhancedIconButton
        icon={<RemoveIcon fontSize="small" />}
        key="remove-icon-toolbar"
        onClick={toggleRemoveGroupMemberDialog}
        tooltip="Remove"
      />,
    );
  }
  if (canWriteEmail) {
    selectionIconElements.push(
      <EnhancedIconButton
        icon={<EmailIcon fontSize="small" />}
        key="email-icon-toolbar"
        onClick={handleClickEmailSelectedMembers}
        tooltip="Email"
      />,
    );
  }

  const handleClickEmailGroupListItem = () => {
    // Add the ids for the current group to the recipient ids for a new email
    dispatch(updateRecipients(listOfUserIds));
    navigate(`/${PATHS.EMAIL_NEW}`);
  };

  const handleNavigateToEditPage = () => {
    navigate(`/groups/${groupId}/edit`);
  };

  const moreActions: IToolbarAction[] = [];

  if (canEditGroups) {
    moreActions.push({
      action: handleNavigateToEditPage,
      icon: <EditIcon fontSize="small" />,
      text: 'Edit group',
    });
  }

  if (canReadUsers && canWriteEmail) {
    moreActions.push({
      action: handleClickEmailGroupListItem,
      icon: <EmailIcon fontSize="small" />,
      text: 'Email group',
    });
  }

  const extraColumns = useMemo(
    () => {
      const actionColumn = createActionsColumn<GQL.IUser>([
        ...(canEditGroups ? [{
          action: handleOpenSingleItemDeleteDialog,
          icon: <RemoveIcon fontSize="small" />,
          isDisabled: isSelectionActive,
          text: 'Remove',
        }] : []),
      ]);

      return actionColumn ? [actionColumn] : [];
    },
    [canEditGroups, handleOpenSingleItemDeleteDialog, isSelectionActive],
  );

  const columns = useColumns(extraColumns);

  return (
    <>
      <TableV2<GQL.IUser>
        actions={moreActions}
        columns={columns}
        data={data?.groupUsers.data ?? []}
        fullCount={data?.groupUsers.fullCount}
        isLoading={loading || isLoading}
        reduxStateKey="group"
        selectionProps={{
          rightIconElements: selectionIconElements,
        }}
        withSearch
        zeroStateMessage="Add new members to see them here."
      />

      <DeleteDialog
        actionVerb="Remove"
        additionalVariables={{ id: groupId }}
        clearCachePredicates={['groupUsers', 'user']}
        context={['user', 'users', 'group']}
        isOpen={isRemoveGroupMemberDialogOpen}
        mutation={REMOVE_MEMBERS_FROM_GROUP}
        onClose={toggleRemoveGroupMemberDialog}
        onRemoveSelectionAll={handleRemoveSelectionAll}
        reduxTableKey="group"
        refetchQueries={() => [
          getOperationName(GET_GROUP) as string,
        ]}
        singleItemId={singleItemDeleteId}
        size={data?.groupUsers.fullCount ?? 0}
      />
    </>
  );
};

export default GroupTableUI;
