// External Dependencies
import { Box } from '@mui/material';
import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { displayCell } from 'components/shared/TableV2';

// Local Variables
const StyledBox = styled(Box)({
  wordWrap: 'break-word',
});

// Hook Definition
export const useColumns = (
  extraColumns?: GridColDef[],
) => useMemo(() => {
  const columns: GridColDef<GQL.ISuppressedEmailUserIndex>[] = [
    {
      field: 'email',
      headerName: 'Email',
      hideable: false,
      minWidth: 160,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 120,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 120,
    },
    {
      field: 'suppressedAt',
      headerName: 'Suppressed On',
      minWidth: 160,
      type: 'dateTime',
      valueFormatter: ({ value }) => displayCell(value, { format: 'datetime' }),
    },
    {
      field: 'reason',
      headerName: 'Reason',
      minWidth: 420,
      renderCell: ({ value }) => (
        <StyledBox>{value}</StyledBox>
      ),
    },
    ...(extraColumns ?? []),
  ];

  return columns;
}, [extraColumns]);
