// External Dependencies
import {
  CardHeader,
  CardHeaderProps,
  IconButton,
  Typography,
} from '@mui/material';
import { FC, useCallback } from 'react';
import { navigate } from '@reach/router';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';

// Local Dependencies
import Flex from './Flex';
import HelpInfoIconButton from './HelpInfoIcon';

// Local Typings
interface Props {
  editPath?: string | undefined;
  onClickEditIconButton?: (() => void) | null;
  tooltip?: string;
}

// Local Variables
const StyledCardHeader = styled(CardHeader)({
  paddingBottom: 0,
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '1.1rem',
  },
  fontSize: '1.2rem',
  marginRight: theme.spacing(1),
}));

// Component Definition
const ShowCardHeader: FC<Props & CardHeaderProps> = ({
  editPath,
  onClickEditIconButton,
  subheader,
  title,
  tooltip,
  ...otherProps
}) => {
  const titleElement = tooltip
    ? (
      <Flex>
        <StyledTypography>{title}</StyledTypography>

        <HelpInfoIconButton
          tooltipTitle={tooltip}
          variant="info"
        />
      </Flex>
    )
    : <StyledTypography>{title}</StyledTypography>;

  const handleClickEditIconButton = useCallback(() => {
    if (editPath) {
      navigate(editPath);
    }
  }, [editPath]);

  const action = (editPath || onClickEditIconButton) ? (
    <IconButton
      aria-label="Edit"
      data-testid="edit-icon"
      onClick={onClickEditIconButton ?? handleClickEditIconButton}
      size="large"
    >
      <EditIcon />
    </IconButton>
  ) : null;

  return (
    <StyledCardHeader
      action={action}
      {...otherProps}
      subheader={subheader}
      title={titleElement}
    />
  );
};

export default ShowCardHeader;
