// External Dependencies
import { useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

// Internal Dependencies
import {
  TableColumn, createActionsColumn, displayCell, displayDynamicFieldCell,
} from 'components/shared/TableV2';
import { useGetDistrictOrganizations, useGetDynamicFields } from 'gql/queries';

export const useColumns = (
  onDelete: (row: GQL.IDistrictInventoryIndexItem) => void,
  organizationTypeId: string | undefined,
) => {
  const { data: dynamicFieldData } = useGetDynamicFields({
    organizationTypeId,
    tableRef: 'inventory_items',
  }, {
    skip: !organizationTypeId,
  });

  const {
    data,
  } = useGetDistrictOrganizations();

  const organizations = data?.districtOrganizations ?? [];

  return useMemo(() => {
    const columns: TableColumn<GQL.IDistrictInventoryIndexItem>[] = [
      {
        Header: 'Category',
        accessor: (row) => displayCell(row.category?.label),
        sortBy: 'categoryLabel',
      },
      {
        Header: 'Name',
        accessor: (row) => displayCell(row.label),
        sortBy: 'label',
      },
      {
        Header: 'Assigned To',
        accessor: (row) => displayCell(row.organization?.label),
        sortBy: 'organizationLabel',
      },
      {
        Header: 'Brand',
        accessor: (row) => displayCell(row.brand),
        sortBy: 'brand',
      },
      {
        Header: 'Model',
        accessor: (row) => displayCell(row.model),
        sortBy: 'model',
      },
      {
        Header: 'Serial #',
        accessor: (row) => displayCell(row.serialNumber),
        sortBy: 'serialNumber',
      },
      {
        Header: 'Barcode',
        accessor: (row) => displayCell(row.customBarcode),
        sortBy: 'customBarcode',
      },
      {
        Header: 'Condition',
        accessor: (row) => displayCell(row.condition?.label),
        sortBy: 'conditionLabel',
      },
      {
        Header: 'Checked Out To',
        accessor: (row) => displayCell(row.checkedOutTo),
        sortBy: 'checkedOutTo',
      },
      ...(organizations.length > 1 ? [{
        Header: 'On Loan To',
        accessor: (row: GQL.IDistrictInventoryIndexItem) =>
          displayCell(row.onLoanToOrganization?.label),
        sortBy: 'onLoanToOrganizationLabel',
      }] : []),
      {
        Header: 'Case #',
        accessor: (row) => displayCell(row.caseNumber),
        sortBy: 'caseNumber',
      },
      ...(dynamicFieldData?.dynamicFields ?? [])
        .map<TableColumn<GQL.IDistrictInventoryIndexItem>>((field) => ({
          Header: field.label,
          accessor: (row) => displayDynamicFieldCell(field, row),
          sortBy: field.dynamicFieldRef,
        })),
    ];

    const actionsColumn = createActionsColumn<GQL.IDistrictInventoryIndexItem>([
      {
        action: onDelete,
        icon: <DeleteIcon />,
        text: 'Delete',
      },
    ]);

    if (actionsColumn) {
      columns.push(actionsColumn);
    }

    return columns;
  }, [
    data?.districtOrganizations,
    dynamicFieldData,
    onDelete,
  ]);
};
