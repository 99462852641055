// External Dependencies
import { EventProps } from 'react-big-calendar';
import { FC, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { useGetCalendarList } from 'gql/queries';

// Local Dependencies
import { CalendarEvent } from '.';

// Local Typings
interface EventWrapperProps {
  backgroundColor?: string;
  color?: string;
}

// Local Variables
const EventWrapper = styled.div<EventWrapperProps>(({
  backgroundColor,
  color,
  theme,
}) => ({
  backgroundColor: backgroundColor ?? theme.palette.prestoPrimary,
  border: 'none',
  borderRadius: theme.shape.borderRadius,
  color: color ?? theme.palette.common.white,
  height: '100%',
  padding: `0 ${theme.spacing(0.5)}`,

  // eslint-disable-next-line sort-keys
  '& span': {
    whiteSpace: 'nowrap',
  },
}));

// Component Definition
const EventUI: FC<EventProps<CalendarEvent>> = ({
  event,
  title,
}) => {
  const [
    getCalendarList,
    {
      called: hasCalledCalendarList,
      data: calendarList,
      loading: isLoadingCalendarList,
    },
  ] = useGetCalendarList();

  useEffect(() => {
    getCalendarList();
  }, [getCalendarList]);

  const evtCalendar = calendarList?.googleCalendarList
    .find((cal) => cal.id === event.googleCalendarId);

  if (isLoadingCalendarList || !hasCalledCalendarList) {
    return null;
  }

  return (
    <EventWrapper
      backgroundColor={evtCalendar?.backgroundColor}
      color={evtCalendar?.foregroundColor}
    >
      <Typography
        component="span"
        variant="caption"
      >
        {title}
      </Typography>
    </EventWrapper>
  );
};

export default EventUI;
