// External Dependencies
import { gql } from '@apollo/client';
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
export interface FormResponseData {
  form: GQL.IForm;
}

interface FormsIndexResponse {
  formIndex: GQL.IFormIndexItemAll;
}

interface MyFormsIndexResponse {
  myFormsIndex: GQL.IMyFormsIndexItemAll;
}

interface MyFormResponseData {
  myForm: GQL.IMyForm;
}

export const GET_FORM = gql`
  query Form($id: ID!) {
    form(id: $id) {
      assignedMembers {
        email
        id
      }
      formBlocks {
        formBlockType {
          id
          label
          semanticLabel
        }
        id
        isRequired
        label
        metadata
        position
      }
      id
      publishedAt
      publishedBy {
        email
        firstName
        id
        lastName
      }
      title
      schoolYearEnding
      updatedAt
    }
  }
`;

const GET_MY_FORM = gql`
  query MyForm($id: ID!) {
    myForm(id: $id) {
      assignmentId
      formBlocks {
        id
        formBlockType {
          id
          label
        }
        isRequired
        label
        metadata
        position
        response
      }
      id
      title
    }
  }
`;

const FORMS_INDEX = gql`
  query FormIndex(
    $queryParams: IndexQueryParams
  ) {
    formIndex(
      queryParams: $queryParams
    ) {
      data {
        id
        publishedAt
        title
        schoolYearEnding
        updatedAt
      }
      fullCount
    }
  }
`;

const MY_FORMS_INDEX = gql`
  query MyFormsIndex(
    $queryParams: IndexQueryParams
  ) {
    myFormsIndex(
      queryParams: $queryParams
    ) {
      data {
        assignedToMemberEmail
        assignedToMemberFirstName
        assignedToMemberId
        assignedToMemberLastName
        id
        publishedAt
        schoolYearEnding
        title
        updatedAt
      }
      fullCount
    }
  }
`;

export const useGetFormsIndex = () => usePaginatedListQuery<
  FormsIndexResponse,
  GQL.IFormIndexItem>({
    dataSelector: (res) => res.formIndex.data,
    fullCountSelector: (res) => res.formIndex.fullCount,
    query: FORMS_INDEX,
  });

export const useGetMyFormsIndex = () => usePaginatedListQuery<
  MyFormsIndexResponse,
  GQL.IMyFormsIndexItem>({
    dataSelector: (res) => res.myFormsIndex.data,
    fullCountSelector: (res) => res.myFormsIndex.fullCount,
    query: MY_FORMS_INDEX,
  });

export const useGetForm = (
  id?: string,
  onCompleted?: (data: FormResponseData) => void,
) =>
  useQueryEnhanced<FormResponseData>(
    GET_FORM,
    {
      onCompleted,
      skip: !id,
      variables: {
        id,
      },
    },
  );

export const useGetMyForm = (
  id?: string,
) =>
  useQueryEnhanced<MyFormResponseData>(
    GET_MY_FORM,
    {
      skip: !id,
      variables: {
        id,
      },
    },
  );
