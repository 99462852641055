// External Dependencies
import {
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox as MUICheckbox,
} from '@mui/material';

// Local Typings
interface Props {
  checked: boolean;
  error?: string;
  label: string;
}

// Local Variables
const defaultProps = {
  error: null,
};

// Component Definition
const Checkbox = (props: Props & CheckboxProps) => {
  const {
    checked,
    error,
    label,
    ...other
  } = props;

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      required
    >
      <FormControlLabel
        control={(
          <MUICheckbox
            checked={checked}
            color="primary"
            {...other}
          />
        )}
        label={label}
      />

      {!!error && (
        <FormHelperText>
          {!!error && error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

Checkbox.defaultProps = defaultProps;

export default Checkbox;
