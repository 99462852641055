// External Dependencies
import {
  FC, FocusEvent, KeyboardEvent, useCallback,
} from 'react';
import Chip from '@mui/material/Chip';
import TextField, { FilledTextFieldProps } from '@mui/material/TextField';
import useTextField from 'hooks/useTextField';

// Local Typings
interface Props extends Partial<FilledTextFieldProps> {
  chips: string[];
  onUpdateChips: (chips: string[]) => void;
}

// Component Definition
const InputWithChips: FC<Props> = ({
  chips,
  onUpdateChips,
  ...textFieldProps
}) => {
  const textField = useTextField('');

  const handleDelete = useCallback((item: string) => () => {
    onUpdateChips(chips.filter((chip) => chip !== item));
  }, [chips, onUpdateChips]);

  const handleBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value.trim()) {
      onUpdateChips([...chips, value.trim()]);
    }

    textField.onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chips, onUpdateChips, textField.onReset]);

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const { value } = event.currentTarget;

      if (value && !chips.includes(value)) {
        onUpdateChips([...chips, value]);
      }

      textField.onReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chips, onUpdateChips, textField.onReset]);

  return (
    <TextField
      InputProps={{
        onBlur: handleBlur,
        onKeyDown: handleKeyDown,
        startAdornment: chips.map((chip) => (
          <Chip
            key={chip}
            label={chip}
            onDelete={handleDelete(chip)}
            tabIndex={-1}
          />
        )),
        ...textField,
      }}
      helperText="Press enter to add a chip."
      variant="filled"
      {...textFieldProps}
    />
  );
};

export default InputWithChips;
