// External Dependencies
import { FC } from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';
import AccountGroupIcon from 'mdi-material-ui/AccountGroup';
import AccountTieIcon from 'mdi-material-ui/AccountTie';
import BankTransferInIcon from 'mdi-material-ui/BankTransferIn';
import CalendarIcon from 'mdi-material-ui/CalendarMultiselect';
import CardAccountDetailsIcon from 'mdi-material-ui/CardAccountDetails';
import CartIcon from 'mdi-material-ui/Cart';
import ChecklistsIcon from 'mdi-material-ui/CheckboxMultipleOutline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Divider from '@mui/material/Divider';
import EmailIcon from '@mui/icons-material/Email';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import List from '@mui/material/List';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from 'mdi-material-ui/Receipt';
import TableChartIcon from '@mui/icons-material/TableChart';
import TieIcon from 'mdi-material-ui/Tie';
import WidgetsIcon from '@mui/icons-material/Widgets';

// Internal Dependencies
import {
  NavMenuItem,
  Organization,
  PriceChangeSvg,
} from 'components/shared';
import {
  PATHS,
  TITLES,
} from 'utils/constants/routes';
import {
  hasFeatureFlag,
  hasPermission,
} from 'state/self/selectors';
import { showChecklistsFeature, showFinancialDeposits } from 'utils/constants';
import { tableQueryParams } from 'state/table/selectors';
import { useDashboardOrgData } from 'hooks/useDashboardOrgData';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import SideNavSubtitle from './SideNavSubtitle';

// Local Typings
interface Props {
  onLinkClick?: () => void;
}

// Local Components
const PrinceChangeIcon = () => <PriceChangeSvg />;

// Component Definition
const DirectorLinks: FC<Props> = ({ onLinkClick }) => {
  const schoolYearEnding = useGetOrganizationSchoolYear();
  const orgData = useDashboardOrgData();

  const directorParams = useSelector(tableQueryParams('directors'));
  const emailParams = useSelector(tableQueryParams('emails'));
  const fileDirectoryParams = useSelector(tableQueryParams('fileDirectories'));
  const financialCreditsParams = useSelector(tableQueryParams('financialCredits'));
  const financialFundraiserCreditsParams = useSelector(tableQueryParams('financialFundraiserCredits'));
  const financialDepositsParams = useSelector(tableQueryParams('financialDeposits'));
  const financialFeesParams = useSelector(tableQueryParams(`financialFees-${schoolYearEnding}`));
  const financialItemsParams = useSelector(tableQueryParams(`financialItems-${schoolYearEnding}`));
  const financialPaymentsParams = useSelector(tableQueryParams(`financialPayments-${schoolYearEnding}`));
  const financialTransactionsParams = useSelector(tableQueryParams('financialTransactions'));
  const groupParams = useSelector(tableQueryParams('groups'));
  const inventoryItemsParams = useSelector(tableQueryParams('inventoryItems'));
  const parentParams = useSelector(tableQueryParams('parents'));
  const studentParams = useSelector(tableQueryParams('students'));
  const libraryItemsParams = useSelector(tableQueryParams('libraryItems'));
  const uniformParams = useSelector(tableQueryParams('uniforms'));

  const hasFinancesFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.Finances));

  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canReadGroups = useSelector(hasPermission('groups', 'read'));
  const canReadInventory = useSelector(hasPermission('inventory', 'read'));
  const canReadLibrary = useSelector(hasPermission('library', 'read'));
  const canReadPayments = useSelector(hasPermission('payments', 'read'));
  const canReadUniforms = useSelector(hasPermission('uniforms', 'read'));
  const canReadUsers = useSelector(hasPermission('users', 'read'));

  const showInventorySubtitle = canReadInventory || canReadLibrary || canReadUniforms;
  const showFinancesSubtitle = hasFinancesFeatureFlag && (canReadFinances || canReadPayments);

  const { self } = useSelfQuery();

  if (!self) {
    return null;
  }

  return (
    <List role="menu">
      {/* check for orgData first to make sure there is not a */}
      {/* flash of a "getting started" link while data is loading */}
      {(orgData && (!orgData?.active || orgData?.isFreeTrial)) && (
        <>
          <NavMenuItem
            icon={LightbulbIcon}
            onClick={onLinkClick}
            text={TITLES[PATHS.GETTING_STARTED]}
            to={`/${PATHS.GETTING_STARTED}`}
          />

          <Divider />
        </>
      )}

      <NavMenuItem
        icon={HomeIcon}
        onClick={onLinkClick}
        text={TITLES[PATHS.DASHBOARD]}
        to={`/${PATHS.DASHBOARD}`}
      />

      {self.currentOrgId && (
        <Organization>
          {({ organization }) => {
            const hasGoogleCalSync = !!organization.hasGoogleCalendar;

            return (
              (hasGoogleCalSync) && (
                <NavMenuItem
                  icon={CalendarIcon}
                  onClick={onLinkClick}
                  text={TITLES[PATHS.CALENDAR]}
                  to={`/${PATHS.CALENDAR}`}
                />
              )
            );
          }}
        </Organization>
      )}

      <NavMenuItem
        icon={EmailIcon}
        match={PATHS.COMMUNICATION}
        onClick={onLinkClick}
        text={TITLES[PATHS.COMMUNICATION]}
        to={`/${PATHS.COMMUNICATION}${emailParams}`}
      />

      <NavMenuItem
        icon={FolderIcon}
        match={PATHS.FILES}
        onClick={onLinkClick}
        text={TITLES[PATHS.FILES]}
        to={`/${PATHS.FILES}${fileDirectoryParams}`}
      />

      {showInventorySubtitle && (
        <>
          <Divider />
          <SideNavSubtitle subtitleText={TITLES[PATHS.INVENTORY]} />
        </>
      )}

      {canReadInventory && (
        <NavMenuItem
          icon={WidgetsIcon}
          match={PATHS.INVENTORY}
          onClick={onLinkClick}
          text={TITLES[PATHS.INVENTORY]}
          to={`/${PATHS.INVENTORY}${inventoryItemsParams}`}
        />
      )}

      {canReadLibrary && (
        <NavMenuItem
          icon={LibraryBooksIcon}
          match={PATHS.LIBRARY}
          onClick={onLinkClick}
          text={TITLES[PATHS.LIBRARY]}
          to={`/${PATHS.LIBRARY}${libraryItemsParams}`}
        />
      )}

      {canReadUniforms && (
        <NavMenuItem
          icon={TieIcon}
          match={PATHS.UNIFORMS}
          onClick={onLinkClick}
          text={TITLES[PATHS.UNIFORMS]}
          to={`/${PATHS.UNIFORMS}${uniformParams}`}
        />
      )}

      <Divider />

      <SideNavSubtitle subtitleText={TITLES[PATHS.PEOPLE]} />

      {canReadGroups && (
        <NavMenuItem
          icon={AccountGroupIcon}
          match={PATHS.GROUPS}
          onClick={onLinkClick}
          text={TITLES[PATHS.GROUPS]}
          to={`/${PATHS.GROUPS}${groupParams}`}
        />
      )}

      {canReadUsers && (
        <NavMenuItem
          icon={CardAccountDetailsIcon}
          match={PATHS.STUDENTS}
          onClick={onLinkClick}
          text={TITLES[PATHS.STUDENTS]}
          to={`/${PATHS.STUDENTS}${studentParams}`}
        />
      )}

      {canReadUsers && (
        <NavMenuItem
          icon={PeopleIcon}
          match={PATHS.PARENTS}
          onClick={onLinkClick}
          text={TITLES[PATHS.PARENTS]}
          to={`/${PATHS.PARENTS}${parentParams}`}
        />
      )}

      <NavMenuItem
        icon={AccountTieIcon}
        match={PATHS.DIRECTORS}
        onClick={onLinkClick}
        text={TITLES[PATHS.DIRECTORS]}
        to={`/${PATHS.DIRECTORS}${directorParams}`}
      />

      {showFinancesSubtitle && (
        <>
          <Divider />

          <SideNavSubtitle subtitleText={TITLES[PATHS.FINANCES]} />
        </>
      )}

      {hasFinancesFeatureFlag && canReadPayments && (
        <NavMenuItem
          icon={DashboardIcon}
          match={PATHS.FINANCIAL_OVERVIEW}
          onClick={onLinkClick}
          text={TITLES[PATHS.FINANCIAL_OVERVIEW]}
          to={`/${PATHS.FINANCIAL_OVERVIEW}`}
        />
      )}

      {hasFinancesFeatureFlag && canReadFinances && (
        <>
          <NavMenuItem
            icon={ReceiptIcon}
            match={PATHS.FINANCIAL_ITEMS}
            onClick={onLinkClick}
            text={TITLES[PATHS.FINANCIAL_ITEMS]}
            to={`/${PATHS.FINANCIAL_ITEMS}${financialItemsParams}`}
          />

          <NavMenuItem
            icon={CartIcon}
            match={PATHS.FINANCIAL_FEES}
            onClick={onLinkClick}
            text={TITLES[PATHS.FINANCIAL_FEES]}
            to={`/${PATHS.FINANCIAL_FEES}${financialFeesParams}`}
          />
        </>
      )}

      {hasFinancesFeatureFlag && canReadPayments && (
        <>
          <NavMenuItem
            icon={LocalAtmIcon}
            match={PATHS.FINANCIAL_PAYMENTS}
            onClick={onLinkClick}
            text={TITLES[PATHS.FINANCIAL_PAYMENTS]}
            to={`/${PATHS.FINANCIAL_PAYMENTS}${financialPaymentsParams}`}
          />

          <NavMenuItem
            icon={PrinceChangeIcon}
            match={PATHS.FINANCIAL_CREDITS}
            onClick={onLinkClick}
            text={TITLES[PATHS.FINANCIAL_CREDITS]}
            to={`/${PATHS.FINANCIAL_CREDITS}${financialCreditsParams}`}
          />

          <NavMenuItem
            icon={CurrencyExchangeIcon}
            match={PATHS.FINANCIAL_FUNDRAISER_CREDITS}
            onClick={onLinkClick}
            text={TITLES[PATHS.FINANCIAL_FUNDRAISER_CREDITS]}
            to={`/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}${financialFundraiserCreditsParams}`}
          />

          {showFinancialDeposits && (
            <NavMenuItem
              icon={BankTransferInIcon}
              match={PATHS.FINANCIAL_DEPOSITS}
              onClick={onLinkClick}
              text={TITLES[PATHS.FINANCIAL_DEPOSITS]}
              to={`/${PATHS.FINANCIAL_DEPOSITS}${financialDepositsParams}`}
            />
          )}

          <NavMenuItem
            icon={TableChartIcon}
            match={PATHS.FINANCIAL_TRANSACTIONS}
            onClick={onLinkClick}
            text={TITLES[PATHS.FINANCIAL_TRANSACTIONS]}
            to={`/${PATHS.FINANCIAL_TRANSACTIONS}${financialTransactionsParams}`}
          />
        </>
      )}

      <Divider />

      <SideNavSubtitle subtitleText={TITLES[PATHS.OTHER]} />

      {showChecklistsFeature && (
        <NavMenuItem
          icon={ChecklistsIcon}
          match={PATHS.CHECKLISTS}
          onClick={onLinkClick}
          showNewChip={new Date() < new Date(2023, 10, 1)}
          text={TITLES[PATHS.CHECKLISTS]}
          to={`/${PATHS.CHECKLISTS}`}
        />
      )}
      <NavMenuItem
        icon={FeedbackOutlinedIcon}
        match={PATHS.FEEDBACK}
        onClick={onLinkClick}
        text={TITLES[PATHS.FEEDBACK]}
        to={`/${PATHS.FEEDBACK}`}
      />
    </List>
  );
};

export default DirectorLinks;
