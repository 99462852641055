// External Dependencies
import { FC } from 'react';
import CardActions from '@mui/material/CardActions';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import {
  Container,
  EnhancedAlert,
  Page,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useGetOrganization } from 'gql/queries';
import RevokeStripeButton from 'components/shared/RevokeStripeButton';
import StripeOrgSignUpButton from 'components/AlertButtons/StripeOrgSignUpButton';

// Local Dependencies
import CoverStripeFeeSetting from './CoverStripeFeeSetting';

// Component Definition
const StripeSettingsPage: FC = () => {
  // EmailSettings uses organization, so we want to be sure this loads before rendering
  const { data: organizationData, loading: isLoadingOrganization } = useGetOrganization();

  const hasStripeId = organizationData?.organization?.hasStripeId;

  return (
    <Page
      backButtonProps={{
        label: 'Settings',
        path: `/${PATHS.SETTINGS}`,
      }}
      isLoading={isLoadingOrganization}
    >
      <Container maxWidth={CONTAINER_WIDTH}>
        <ShowCard
          cardActions={(
            <CardActions>
              {hasStripeId
                ? <RevokeStripeButton />
                : <StripeOrgSignUpButton />}
            </CardActions>
          )}
          icon={PriceChangeIcon}
          title="Stripe Settings"
        >
          {hasStripeId ? (
            <>
              <EnhancedAlert
                sx={{ mb: 2 }}
                title="Cover Stripe fees"
              >
                <Typography
                  color="inherit"
                  fontSize="inherit"
                  paragraph
                >
                  Your organization can cover Stripe fees for members, eliminating their
                  {' '}
                  payment processing fee. You can override this on a per item basis.
                </Typography>

                <Typography
                  color="inherit"
                  fontSize="inherit"
                  paragraph
                >
                  When covering the fees, incorporate them into your pricing.
                  {' '}
                  The fees amount to 2.9% + $0.30 per transaction.
                </Typography>
              </EnhancedAlert>

              <CoverStripeFeeSetting />
            </>
          ) : (
            <Typography>
              You have not connected your organization to Stripe.
            </Typography>
          )}
        </ShowCard>
      </Container>
    </Page>
  );
};

export default StripeSettingsPage;
