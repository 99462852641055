// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationEnhancedOptions, MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';
import { USER_FRAGMENT } from 'gql/queries';

// Local Typings
interface CheckIfAccountExistsData {
  checkIfAccountExists: boolean;
}
interface ValidateUserFieldsData {
  validateUserFields: boolean;
}
interface RemoveSuppressedEmailsData {
  removeSuppressedEmail: boolean;
}
interface SendWelcomeEmailData {
  sendWelcomeEmail: boolean;
}

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $input: UpdateUserInput!
  ) {
    updateUser(
      id: $id
      input: $input
    ) {
      ...UserFragment
    }
  },
  ${USER_FRAGMENT}
`;

export const UPDATE_USER_PERMISSIONS = gql`
  mutation UpdateUserPermissions(
    $mayEditPermissions: Boolean!
    $permissionIds: [Int!]!
    $userId: ID!
  ) {
    updateUserPermissions(
      mayEditPermissions: $mayEditPermissions
      permissionIds: $permissionIds
      userId: $userId
    ) {
      id
    }
  }
`;

export const UPDATE_USER_PERMISSIONS_BY_DISTRICT_ADMIN = gql`
  mutation UpdateUserPermissionsByDistrictAdmin(
    $mayEditPermissions: Boolean!
    $organizationId: ID!
    $permissionIds: [Int!]!
    $userId: ID!
  ) {
    updateUserPermissionsByDistrictAdmin(
      mayEditPermissions: $mayEditPermissions
      organizationId: $organizationId
      permissionIds: $permissionIds
      userId: $userId
    ) {
      id
    }
  }
`;

export const UPDATE_USER_PERMISSIONS_BY_DIRECTOR = gql`
  mutation UpdateUserPermissions(
    $mayEditPermissions: Boolean!
    $permissionIds: [Int!]!
    $userId: ID!
  ) {
    updateUserPermissions(
      mayEditPermissions: $mayEditPermissions
      permissionIds: $permissionIds
      userId: $userId
    ) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $input: CreateUserInput!
  ) {
    createUser(
      input: $input
    ) {
      email
      firstName
      gender {
        id
        label
      }
      id
      isEligible
      lastName
      loggedInAt
      otherGenderLabel
      primarySpokenLanguage {
        id
        label
      }
      userOrgData {
        member {
          id
          loggedInAt
        }
        roles {
          id
          isPrimary
          organizationRole {
            id
            label
          }
        }
      }
    }
}
`;

const CHECK_IF_ACCOUNT_EXISTS = gql`
  mutation CheckIfAccountExists($email: String!) {
    checkIfAccountExists(email: $email)
  }
`;

const VALIDATE_USER_FIELDS = gql`
  mutation ValidateUserFIelds($input: UserFields!) {
    validateUserFields(input: $input)
  }
`;

const CREATE_CHILD = gql`
  mutation CreateChild(
    $acceptedTerms: Boolean!
    $input: CreateChildInput!
  ) {
    createChild(
      acceptedTerms: $acceptedTerms
      input: $input
    ) {
      currentOrgId
      email
      id
      firstName
      lastName
      loggedInAt
    }
  }
`;

const REMOVE_SUPPRESSED_EMAILS = gql`
  mutation RemoveSuppressedEmails(
    $input: RemoveSuppressedEmailsInput!
  ) {
    removeSuppressedEmails(
      input: $input
    )
  }
`;

const SEND_WELCOME_EMAIL = gql`
  mutation SendWelcomeEmail(
    $userOrgIds: [ID!]
  ) {
    sendWelcomeEmail(
      userOrgIds: $userOrgIds
    )
  }
`;

interface UserOrgRole {
  id: string;
  isPrimary: boolean;
  organizationRole: {
    id: string;
    label: string;
  }
}

export interface CreateUserData {
  createUser: {
    email: string;
    firstName: string;
    id: string;
    isEligible: boolean;
    lastName: string;
    loggedInAt: string | null;
    userOrgData: {
      roles: UserOrgRole[];
    }
  }
}

interface UpdateUserData {
  addressOne: GQL.IUser['addressOne'];
  addressTwo: GQL.IUser['addressTwo'];
  city: GQL.IUser['city'];
  email: GQL.IUser['email'];
  firstName: GQL.IUser['firstName'];
  grade: GQL.IUser['grade'];
  id: GQL.IUser['id'];
  lastName: GQL.IUser['lastName'];
  loggedInAt: GQL.IUser['loggedInAt'];
  phoneNumber: GQL.IUser['phoneNumber'];
  stateId: GQL.IUser['stateId'];
  userOrgData: { roles: UserOrgRole[]; };
  zipcode: GQL.IUser['zipcode'];
}

interface CreateChildData {
  currentOrgId: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  loggedInAt: string | null;
}

export const useCreateUser = (
  options: MutationOptions<
    CreateUserData,
    GQL.ICreateUserOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    CreateUserData,
    GQL.ICreateUserOnMutationArguments
  >(
    CREATE_USER,
    {
      ...options,
      clearCachePredicates: [
        ...(options.clearCachePredicates ?? []),
        ...[
          'allMembers',
          'allDirectors',
          'peoplePickerIndex',
          'returningStudents',
          'user',
        ],
      ],
    },
  );

export const useUpdateUser = (
  options: MutationOptions<
    UpdateUserData,
    GQL.IUpdateUserOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    UpdateUserData,
    GQL.IUpdateUserOnMutationArguments
  >(
    UPDATE_USER,
    {
      ...options,
      clearCachePredicates: [
        ...(options.clearCachePredicates ?? []),
        ...[
          'allMembers',
          'allDirectors',
          'history',
          'peoplePickerIndex',
          'returningStudents',
          'user',
        ],
      ],
    },
  );

// Used by district admin and director
interface UpdateUserPermissionsData {
  id: string;
}

export const useUpdateUserPermissionsByDistrictAdmin = (
  options?: MutationOptions<
    UpdateUserPermissionsData,
    GQL.IUpdateUserPermissionsByDistrictAdminOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateUserPermissionsData,
  GQL.IUpdateUserPermissionsByDistrictAdminOnMutationArguments
>(
  UPDATE_USER_PERMISSIONS_BY_DISTRICT_ADMIN,
  options,
);

export const useUpdateUserPermissionsByDirector = (
  options?: MutationOptions<
    UpdateUserPermissionsData,
    GQL.IUpdateUserPermissionsOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateUserPermissionsData,
  GQL.IUpdateUserPermissionsOnMutationArguments
>(
  UPDATE_USER_PERMISSIONS_BY_DIRECTOR,
  options,
);

export const useCheckIfAccountExists = (
  options?: MutationOptions<
    CheckIfAccountExistsData,
    GQL.ICheckIfAccountExistsOnMutationArguments
  >,
) => useMutationEnhanced<
  CheckIfAccountExistsData,
  GQL.ICheckIfAccountExistsOnMutationArguments
>(
  CHECK_IF_ACCOUNT_EXISTS,
  options,
);

export const useValidateUserFields = (
  options?: MutationOptions<
    ValidateUserFieldsData,
    GQL.IValidateUserFieldsOnMutationArguments
  >,
) => useMutationEnhanced<
  ValidateUserFieldsData,
  GQL.IValidateUserFieldsOnMutationArguments
>(
  VALIDATE_USER_FIELDS,
  options,
);

export const useCreateChild = (
  options?: MutationOptions<
    CreateChildData,
    GQL.ICreateChildOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateChildData,
  GQL.ICreateChildOnMutationArguments
>(
  CREATE_CHILD,
  options,
);

export const useRemoveSuppressedEmails = (
  options: MutationEnhancedOptions<
    RemoveSuppressedEmailsData,
    GQL.IRemoveSuppressedEmailsOnMutationArguments
  >,
) => useMutationEnhanced<
    RemoveSuppressedEmailsData,
    GQL.IRemoveSuppressedEmailsOnMutationArguments
  >(
    REMOVE_SUPPRESSED_EMAILS,
    {
      ...options,
      clearCachePredicates: [
        ...(options.clearCachePredicates ?? []),
        ...['suppressedEmails', 'user'],
      ],
    },
  );

export const useSendWelcomeEmail = (
  options: MutationEnhancedOptions<
    SendWelcomeEmailData,
    GQL.ISendWelcomeEmailOnMutationArguments
  >,
) => useMutationEnhanced<
  SendWelcomeEmailData,
  GQL.ISendWelcomeEmailOnMutationArguments
>(
  SEND_WELCOME_EMAIL,
  {
    ...options,
    clearCachePredicates: [
      ...(options.clearCachePredicates ?? []),
      ...['user'],
    ],
  },
);
