// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useDispatch } from 'react-redux';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';

// Internal Dependencies
import { ConfirmationDialog, TableDataGrid } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { createDataGridActionsColumn, displayCell } from 'components/shared/TableV2';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useCancelScheduledSendEmail } from 'gql/mutations';
import { useGetEmailScheduledIndex } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Variables
const handleTableClickRow = (id: string) => `/${PATHS.EMAIL}/${id}`;

// Component Definition
const ScheduledEmailsTable: FC = () => {
  const [emailToCancelId, setEmailToCancelId] = useState<string | null>(null);

  const dispatch = useDispatch();

  const [
    cancelScheduledSendEmail,
    {
      loading: isCancelingScheduledSendEmail,
    },
  ] = useCancelScheduledSendEmail({
    onCompleted: () => {
      setEmailToCancelId(null);

      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    },
  });

  const handleCancelScheduledSendEmail = useCallback(() => {
    if (emailToCancelId) {
      cancelScheduledSendEmail({
        variables: {
          id: emailToCancelId,
        },
      });
    }
  }, [cancelScheduledSendEmail, emailToCancelId]);

  const handleClose = useCallback(() => {
    setEmailToCancelId(null);
  }, []);

  const handleClickCancelScheduledEmail = useCallback((row: GQL.IEmailIndexItem) => {
    setEmailToCancelId(row.id);
  }, []);

  const extraColumns = useMemo<GridColDef[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<GQL.IEmailIndexItem>([
        {
          action: handleClickCancelScheduledEmail,
          icon: <CancelScheduleSendIcon />,
          text: 'Cancel Scheduled Email',
        },
      ]);

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      handleClickCancelScheduledEmail,
    ],
  );

  const columns = useMemo<GridColDef<GQL.IEmailIndexItem>[]>(() => [
    {
      field: 'submittedAt',
      headerName: 'Submitted',
      minWidth: 190,
      type: 'dateTime',
      valueFormatter: ({ value }) => displayCell(value, { format: 'datetime' }),
    },
    {
      field: 'scheduledSendAt',
      headerName: 'Scheduled Delivery',
      minWidth: 190,
      type: 'dateTime',
      valueFormatter: ({ value }) => displayCell(value, { format: 'datetime' }),
    },
    {
      field: 'subject',
      headerName: 'Subject',
      valueFormatter: ({ value }) => displayCell(value),
    },
    {
      field: 'attachmentCount',
      headerName: 'Attachments',
      type: 'number',
    },
    {
      field: 'recipientCount',
      headerName: 'Recipients',
      type: 'number',
    },
    ...extraColumns,
  ], [extraColumns]);

  const {
    data,
    isLoading,
  } = useGetEmailScheduledIndex();

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={{
            label: 'Email',
            to: `/${PATHS.EMAIL_NEW}`,
          }}
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          loading={isLoading}
          tableResource="scheduledSendEmails"
          rows={data}
          withSearch
        />
      </DataGridContainer>

      <ConfirmationDialog
        confirmButtonText="Yes, cancel"
        confirmButtonAction={handleCancelScheduledSendEmail}
        declineButtonAction={handleClose}
        description="This will cancel your scheduled send. Are you sure you want to cancel?"
        handleClose={handleClose}
        isSubmitting={isCancelingScheduledSendEmail}
        open={Boolean(emailToCancelId)}
        title="Cancel Scheduled Email?"
      />
    </>
  );
};

export default ScheduledEmailsTable;
