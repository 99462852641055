// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { DashboardCard, ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  feesAssignedInCents: number;
  outstandingFeesInCents: number;
}

// Component Definition
const AcademicYTDTotalsCard: FC<Props> = ({
  feesAssignedInCents,
  outstandingFeesInCents,
}) => (
  <DashboardCard
    content={(
      <>
        <ShowPageDataDisplay
          label="Fees Assigned"
          type="currency"
          value={feesAssignedInCents}
        />

        <ShowPageDataDisplay
          label="Fees Paid"
          type="currency"
          value={feesAssignedInCents - outstandingFeesInCents}
        />
      </>
    )}
    title="Current Year Fees"
  />
);

export default AcademicYTDTotalsCard;
