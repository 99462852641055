// External Dependencies
import {
  Box,
  IconButton,
  Input,
  InputAdornment,
} from '@mui/material';
import {
  GridFilterModel,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
  gridFilteredSortedRowIdsSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';

// Internal Dependencies
import useTextField from 'hooks/useTextField';

// Local Dependencies
import {
  EnhancedIconButton,
  Flex,
  RefreshingIcon,
} from '..';
import { IToolbarAction } from '../DataTable/Toolbar';
import EditModeToolbar from './EditModeToolbar';
import SubscriberAddButton, { SubscriberAddButtonProps } from '../SubscriberAddButton';
import ToolbarMoreActionsIconMenu from '../DataTable/ToolbarMoreActionsIconMenu';

// Local Typings
export interface EnhancedGridToolbarProps {
  addButtonProps?: SubscriberAddButtonProps | null;
  gridFilterModel: GridFilterModel;
  hideExport?: boolean;
  isEditMode?: boolean;
  isHydrating: boolean;
  onFilter?: (rowIds: GridRowId[]) => void;
  onToggleEditMode: () => void;
  onUpdateParams: (updatedQueryParams: object) => void;
  rows: any[] | null;
  search?: string;
  shouldNavigateOnSearch?: boolean;
  toolbarActions?: IToolbarAction[];
  withEditMode?: boolean;
  withSearch?: boolean;
}

// Local Variables
const StyledRoot = styled(Box)(({ theme }) => ({
  '.editIconButton': {
    fontSize: '1.25rem',
    margin: theme.spacing(0, 0.5),
    padding: 8,
  },
  '.searchAndActionsContainer': {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },

  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  left: 0,
  minHeight: 56,
  padding: 12,
  position: 'sticky',
  right: 0,
}));

const EMPTY_SEARCH_STATE = [''];

// Component Definition
const EnhancedGridToolbar: React.FunctionComponent<EnhancedGridToolbarProps & GridToolbarProps> = ({
  addButtonProps,
  gridFilterModel,
  hideExport,
  isEditMode,
  isHydrating,
  onFilter,
  onToggleEditMode,
  onUpdateParams,
  rows,
  search,
  setFilterButtonEl,
  toolbarActions,
  withEditMode,
  withSearch,
}) => {
  const quickFieldProps = useTextField(search);

  const [searchValue] = useDebounce(quickFieldProps.value, 300);

  const apiContext = useGridApiContext();

  const currentApiContext = apiContext.current;

  useEffect(() => {
    const newValue = searchValue ? searchValue?.toString().trim().split(' ') : EMPTY_SEARCH_STATE;

    currentApiContext.setQuickFilterValues(newValue);

    onUpdateParams({ q: searchValue });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () =>
      currentApiContext.setQuickFilterValues(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApiContext, onUpdateParams, searchValue]);

  useEffect(() => {
    // I don't know why, but we need the setTimeout to put this at the end of the event loop
    // otherwise, the onFilter does not get called on the first render
    setTimeout(() => {
      onFilter?.(gridFilteredSortedRowIdsSelector(apiContext));
    }, 0);
  }, [apiContext, gridFilterModel, onFilter, rows]);

  const handleClearQuickFilterValue = useCallback(() => {
    quickFieldProps.onReset();
  }, [quickFieldProps]);

  const shouldShowMoreActionsMenu = toolbarActions && toolbarActions.length > 0;

  const handleDisableEditMode = () => {
    const cellWithEditState = Object.keys(apiContext.current.state.editRows);

    onToggleEditMode();

    // we wrap this in a setTimeout because we need to wait for any changes to be sent to the API
    setTimeout(() => {
      // If we have edit data but are trying to dismiss,
      // we need to throw away the edit data
      if (apiContext.current.state.tabIndex.cell && cellWithEditState.length) {
        apiContext.current.stopCellEditMode({
          field: apiContext.current.state.tabIndex.cell.field,
          id: cellWithEditState[0],
          ignoreModifications: true,
        });
      }
    }, 0);
  };

  return (
    <>
      <EditModeToolbar
        isEditMode={Boolean(isEditMode)}
        onDisableEditMode={handleDisableEditMode}
      />

      <StyledRoot>
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Flex>
            {addButtonProps && (
              <SubscriberAddButton
                {...addButtonProps}
                disabled={isEditMode}
                marginRight={24}
              />
            )}
          </Flex>

          <Flex className="searchAndActionsContainer">
            {isHydrating && <RefreshingIcon sx={{ marginX: 2 }} />}

            {withSearch && (
              <Input
                {...quickFieldProps}
                endAdornment={(
                  <InputAdornment
                    position="end"
                    sx={{ width: 30 }} // width of the clear button
                  >
                    {quickFieldProps.value && (
                      <IconButton
                        onClick={handleClearQuickFilterValue}
                        size="small"
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </InputAdornment>
                )}
                placeholder="Search..."
                size="small"
                startAdornment={(
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )}
              />
            )}

            {withEditMode && (
              <EnhancedIconButton
                className="editIconButton"
                icon={<EditIcon fontSize="small" />}
                onClick={isEditMode
                  ? handleDisableEditMode
                  : onToggleEditMode}
                size="small"
                tooltip={`${isEditMode ? 'Disable' : 'Enable'} Edit Mode`}
                tooltipPlacement={shouldShowMoreActionsMenu
                  ? 'bottom'
                  : 'bottom-end'}
              />
            )}

            {shouldShowMoreActionsMenu && (
              <ToolbarMoreActionsIconMenu moreActions={toolbarActions} />
            )}
          </Flex>
        </Flex>

        <Box paddingTop={1}>
          <GridToolbarContainer>
            <GridToolbarColumnsButton
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
            />

            <GridToolbarFilterButton
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
              ref={setFilterButtonEl}
            />
            {!hideExport && <GridToolbarExport />}
          </GridToolbarContainer>
        </Box>
      </StyledRoot>
    </>
  );
};

export default EnhancedGridToolbar;
