// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface DistrictAdminData {
  district: {
    admin: {
      email: string;
      firstName: string;
      id: string;
      lastName: string;
    };
    id: string;
    label: string;
  }
}

export const DISTRICT_FRAGMENT = gql`
  fragment DistrictFragment on District {
    addressOne
    addressTwo
    admin {
      email
      firstName
      id
      lastName
    }
    areOnlinePaymentsEnabled
    city
    customBarcodeCursor
    id
    invitations {
      accepted
      activationCode
      createdAt
      id
      recipientEmail
    }
    label
    organizations {
      creator {
        email
        firstName
        id
        lastName
      }
      id
      label
      organizationType {
        id
        label
      }
    }
    phoneNumber
    state {
      id
      label
    }
    zipcode
  }
`;

export const GET_DISTRICT = gql`
  query District {
    district {
      ...DistrictFragment
    }
  },
  ${DISTRICT_FRAGMENT}
`;

const GET_DISTRICT_NAME = gql`
  query DistrictName {
    district {
      label
    }
  }
`;

const GET_DISTRICT_ADMIN = gql`
  query DistrictAdminId {
    district {
      admin {
        email
        id
        firstName
        lastName
      }
      id
      label
    }
  }
`;

export const useGetDistrict = (options?: { skip: boolean }) =>
  useQueryEnhanced<{
    district: GQL.IDistrict;
  }>(GET_DISTRICT, { skip: options?.skip });

export const useGetDistrictName = () =>
  useQueryEnhanced<{
    district: GQL.IDistrict;
  }>(GET_DISTRICT_NAME);

export const useGetDistrictAdmin = () =>
  useQueryEnhanced<DistrictAdminData>(GET_DISTRICT_ADMIN);
