// External Dependencies
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { FC, useEffect } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { useGetOrganizationsByDirectorEmail } from 'gql/queries';
import Form from 'components/shared/Form';
import Input from 'components/shared/Input';
import SaveButton from 'components/shared/SaveButton';
import useTextField from 'hooks/useTextField';

// Local Typings
interface Props {
  onClose: () => void;
  setOrganizations: React.Dispatch<React.SetStateAction<GQL.IOrganization[]>>;
}

// Local Variables
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  '&:first-child': {
    paddingTop: theme.spacing(1),
  },
}));

// Component Definition
const SearchForm: FC<Props> = ({
  onClose,
  setOrganizations,
}) => {
  const emailField = useTextField('');

  const [
    getOrganizationsByDirectorEmail,
    {
      data: orgsByDirectorEmailData,
      loading: isSubmitting,
    },
  ] = useGetOrganizationsByDirectorEmail();

  useEffect(() => {
    if (orgsByDirectorEmailData?.organizationsByDirectorEmail) {
      setOrganizations(orgsByDirectorEmailData.organizationsByDirectorEmail);
    }
  }, [orgsByDirectorEmailData]);

  const handleSubmit = async () => {
    setOrganizations([]);

    getOrganizationsByDirectorEmail({
      variables: { email: emailField.value },
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <StyledDialogContent>
        <DialogContentText>
          If a director in your district has already created
          a {APP_NAME} organization, then you can enter their
          email to invite them to join your {APP_NAME} district.
        </DialogContentText>
        <Input
          fullWidth
          helperText="Organization Creator Email"
          label="Email"
          variant="filled"
          {...emailField}
        />
      </StyledDialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <SaveButton
          disabled={emailField.value.trim().length < 5}
          isSaving={isSubmitting}
          type="submit"
        >
          Search
        </SaveButton>
      </DialogActions>
    </Form>
  );
};

export default SearchForm;
