// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { navigate, useLocation } from '@reach/router';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { TableV2 } from 'components/shared';
import { createActionsColumn } from 'components/shared/TableV2/helpers';
import { parseSearch } from 'utils';
import { useGetDynamicFields } from 'gql/queries';

// Local Dependencies
import { useColumns } from './hooks';
import DialogDeleteDynamicField from './DialogDeleteDynamicField';
import Filters from './Filters';

// Component Definition
const DynamicFieldsTable: FC = () => {
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const { search } = useLocation();

  const parsedSearch = parseSearch(search);

  const hasParams = !!parsedSearch.organizationTypeId && !!parsedSearch.tableRef;

  const {
    data,
    loading,
  } = useGetDynamicFields({
    organizationTypeId: parsedSearch.organizationTypeId,
    tableRef: parsedSearch.tableRef,
  }, {
    skip: !hasParams,
  });

  const handleNavigateToEdit = useCallback((row: GQL.IDynamicField) => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}/${row.id}/edit`);
  }, [search]);

  const handleOpenDeleteDialog = useCallback((row: GQL.IDynamicField) => {
    setDeleteId(row.id);
  }, []);

  const actionsColumn = useMemo(() => createActionsColumn<GQL.IDynamicField>([
    {
      action: handleNavigateToEdit,
      icon: <EditIcon />,
      text: 'Edit',
    },
    {
      action: handleOpenDeleteDialog,
      icon: <DeleteIcon />,
      text: 'Delete',
    },
  ]), [handleOpenDeleteDialog]);

  const extraColumns = useMemo(
    () => (actionsColumn ? [actionsColumn] : []),
    [actionsColumn],
  );

  const columns = useColumns(extraColumns);

  const handleClearDynamicFieldId = useCallback(() => {
    setDeleteId(null);
  }, []);

  return (
    <>
      <TableV2<GQL.IDynamicField>
        Filters={<Filters />}
        addButtonProps={{
          disabled: !hasParams,
          label: 'Dynamic Field',
          to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}/new${search}`,
        }}
        columns={columns}
        data={data?.dynamicFields ?? []}
        fullCount={data?.dynamicFields.length}
        isLoading={loading}
        reduxStateKey="dynamicFields"
        withPagination={false}
        zeroStateMessage="No dynamic fields for this table and organization type."
      />

      <DialogDeleteDynamicField
        dynamicFieldId={deleteId}
        onClearDynamicFieldId={handleClearDynamicFieldId}
      />
    </>
  );
};

export default DynamicFieldsTable;
