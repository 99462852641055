// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';
import { useGetDistrictOrganizations } from 'gql/queries';

// Local Typings
interface Props {
  inventoryItemData: GQL.IInventoryItem;
}

// Component Definition
const ShowInventoryLocationData: FC<Props> = ({ inventoryItemData }) => {
  const {
    data,
  } = useGetDistrictOrganizations();

  const organizations = data?.districtOrganizations ?? [];

  if (!inventoryItemData) {
    return null;
  }

  const {
    caseNumber,
    combination,
    location,
    lockNumber,
    locker,
    onLoanToOrganization,
  } = inventoryItemData;

  return (
    <>
      <ShowPageDataDisplay
        label="Location"
        value={location}
      />
      <ShowPageDataDisplay
        label="Case Number"
        value={caseNumber}
      />
      <ShowPageDataDisplay
        label="Locker"
        value={locker}
      />
      <ShowPageDataDisplay
        label="Lock Number"
        value={lockNumber}
      />
      <ShowPageDataDisplay
        label="Combination"
        value={combination}
      />

      {organizations.length > 1 && (
        <ShowPageDataDisplay
          label="On Loan To"
          value={onLoanToOrganization?.label}
        />
      )}
    </>
  );
};

export default ShowInventoryLocationData;
