// External Dependencies
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { formatDateTime, sanitizeDate } from 'utils';

// Local Dependencies
export const useColumns = () => {
  return useMemo(() => {
    const columns: DataGridColDef<GQL.IFormIndexItem>[] = [
      {
        field: 'title',
        headerName: 'Title',
        width: 160,
      },
      {
        field: 'updatedAt',
        headerName: 'Updated',
        type: 'date',
        valueFormatter: ({ value }) => formatDateTime(value),
        valueGetter: ({ row }) => sanitizeDate(row.updatedAt),
        width: 160,
      },
      {
        field: 'publishedAt',
        headerName: 'Published',
        type: 'date',
        valueFormatter: ({ value }) => (value ? formatDateTime(value) : null),
        valueGetter: ({ row }) => (row.publishedAt ? sanitizeDate(row.publishedAt) : null),
        width: 160,
      },
    ];

    return columns;
  }, []);
};
