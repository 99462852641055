// External Dependencies
import {
  Box,
  Button,
  CardContent,
  Typography,
} from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import {
  useGetReturningStudents,
} from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import DialogReturningStudents from './DialogReturningStudents';

// Local Typings
interface Props {
  organizationType: string;
}

// Component Definition
const EndOfYearReturningStudents: FC<Props> = ({
  organizationType,
}) => {
  const {
    handleClose,
    isOpen: isDialogStudentSuccessorOrgsOpen,
    toggleIsOpen,
  } = useIsOpen();

  // Get non-returning students in case user has not completed entire process
  const { data } = useGetReturningStudents();

  if (!data) {
    return null;
  }

  return (
    <>
      <Box mb={2}>
        <Subtitle>Returning Students</Subtitle>

        <EnhancedCard>
          <CardContent>
            <Typography>
              For students returning to{' '}
              {organizationType.toLocaleLowerCase()}{' '}
              next school year, check their
              data and update directly in the table.
            </Typography>

            <Box
              display="flex"
              justifyContent="flex-end"
              marginTop={2}
            >
              <Button
                onClick={toggleIsOpen}
                size="small"
                variant="outlined"
              >
                Update Students
              </Button>
            </Box>

            <EnhancedAlert
              isTip
              sx={{ mt: 2.5 }}
            >
              You can also update this information on each
              student&apos;s details page.
            </EnhancedAlert>
          </CardContent>
        </EnhancedCard>
      </Box>

      <DialogReturningStudents
        isOpen={isDialogStudentSuccessorOrgsOpen}
        onClose={handleClose}
      />
    </>
  );
};

export default EndOfYearReturningStudents;
