// External Dependencies
import { ApolloError } from '@apollo/client';
import {
  Box,
  CardContent,
  CircularProgress,
  Collapse,
  Grow,
  Typography,
} from '@mui/material/';
import {
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { updateOnboardingContactInfoSchema } from '@presto-assistant/api_types/schemas/self';
import { useRef, useState } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  CustomFormButton,
  CustomInput,
  EnhancedAlert,
  Flex,
  OnboardingStepTitle,
  StateSelector,
} from 'components/shared';
import { GET_SELF } from 'gql/queries';
import { UPDATE_ONBOARDING_CONTACT } from 'gql/mutations';
import { normalizeFormErrors } from 'utils/lib/graphql_errors';
import { trimValues } from 'utils/lib/trimValues';
import { useMutationEnhanced } from 'utils/lib/graphql';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import OnboardingProgress from './OnboardingProgress';

// Local Typings
interface OnboardingContactValues {
  addressOne: string;
  addressTwo?: string;
  city: string;
  email: string;
  phoneNumber: string;
  stateId: string;
  zipcode: string;
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: 12,
  },
  '.button': {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    margin: '16px 0 0',
  },
  '.buttonText': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
    },
    fontSize: '1.1em',
    textTransform: 'none',
  },
  '.description': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
      margin: theme.spacing(1.5),
    },
    margin: 16,
  },
  '.zipcode': {
    marginLeft: 16,
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}));

// Component Definition
const OnboardingUserContactInfo = () => {
  const [submitError, setSubmitError] = useState('');
  const [changedEmail, setChangedEmail] = useState(false);

  const { loading, self } = useSelfQuery();

  // If the email already exists, we track it to know when it changes
  const emailRef = useRef(self?.email);

  const [
    updateOnboardingContact,
    { loading: isSubmittingUpdateSelf },
  ] = useMutationEnhanced(UPDATE_ONBOARDING_CONTACT);

  if (loading) {
    return <OnboardingProgress />;
  }

  if (self && emailRef.current === undefined) {
    emailRef.current = self.email;
  }

  let isOnboardingStudentOrParent: boolean;

  if (self?.onboarding.onboardingRole) {
    isOnboardingStudentOrParent = ([
      'parent',
      'student',
    ] as DB.User.OnboardingRole[])
      .includes(self?.onboarding?.onboardingRole as DB.User.OnboardingRole);
  }

  const onboardingContactInitialValues: OnboardingContactValues = {
    addressOne: self?.addressOne || '',
    addressTwo: self?.addressTwo || '',
    city: self?.city || '',
    email: self?.email || '',
    phoneNumber: self?.phoneNumber || '',
    stateId: self?.state?.id || '',
    zipcode: self?.zipcode || '',
  };

  const handleSubmitForm = async (
    values: OnboardingContactValues,
    { setErrors }: FormikHelpers<OnboardingContactValues>,
  ) => {
    try {
      await updateOnboardingContact({
        refetchQueries: [{ query: GET_SELF }],
        variables: trimValues(values) as OnboardingContactValues,
      });
    } catch (err) {
      setSubmitError(
        'There was an error updating contact data. Please try again.',
      );
      setErrors(normalizeFormErrors(err as ApolloError));
    }
  };

  return (
    <>
      <OnboardingStepTitle title="Contact Info" />

      <Formik<OnboardingContactValues>
        initialValues={onboardingContactInitialValues}
        onSubmit={handleSubmitForm}
        validationSchema={updateOnboardingContactInfoSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          if (touched.email && values.email !== emailRef.current) {
            setChangedEmail(true);
          } else if (values.email === emailRef.current) {
            setChangedEmail(false);
          }

          return (
            <Form onSubmit={handleSubmit}>
              <Grow in={!loading}>
                <StyledCardContent>
                  <Box
                    component="section"
                    textAlign="left"
                  >
                    <Typography
                      component="h3"
                      variant="h6"
                    >
                      Login Info
                    </Typography>
                    <Typography className="description">
                      Use this email address to sign in to Presto.
                    </Typography>

                    <Flex>
                      <CustomInput
                        label="Email"
                        name="email"
                      />

                      <Collapse in={isOnboardingStudentOrParent && changedEmail}>
                        <Box
                          mb={submitError ? 2 : 0}
                          mt={1}
                        >
                          <EnhancedAlert
                            isTip
                            title="Reminder"
                          >
                            Students and any Adult relatives should
                            use separate email addresses.
                            This allows each of you to sign
                            in and see a different view of {APP_NAME}.
                          </EnhancedAlert>
                        </Box>
                      </Collapse>
                    </Flex>
                  </Box>

                  <Box
                    component="section"
                    mt={2}
                    textAlign="left"
                  >
                    <Box mb={1}>
                      <Typography
                        component="h3"
                        variant="h6"
                      >
                        Contact Info
                      </Typography>
                    </Box>

                    <Flex>
                      <CustomInput
                        label="Address 1"
                        name="addressOne"
                      />
                      <CustomInput
                        label="Address 2"
                        name="addressTwo"
                      />
                      <CustomInput
                        label="City"
                        name="city"
                      />

                      <Flex
                        alignItems="baseline"
                        flexWrap="nowrap"
                        flex="1 0 100%"
                      >
                        <StateSelector />
                        <CustomInput
                          label="Zip Code"
                          name="zipcode"
                          rootStyles="zipcode"
                        />
                      </Flex>

                      <CustomInput
                        label="Phone Number"
                        name="phoneNumber"
                        type="tel"
                      />
                    </Flex>
                  </Box>

                  <Collapse in={Boolean(submitError)}>
                    <EnhancedAlert severity="error">{submitError}</EnhancedAlert>
                  </Collapse>

                  {isSubmitting || isSubmittingUpdateSelf ? (
                    <CircularProgress
                      className="button"
                      size={24}
                      thickness={6}
                    />
                  ) : (
                    <CustomFormButton
                      buttonText="Update Contact"
                      className="button"
                      disabled={isSubmitting || isSubmittingUpdateSelf}
                      key="onboarding-contact-submit-button"
                      labelClasses="buttonText"
                      size="large"
                    />
                  )}
                </StyledCardContent>
              </Grow>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default OnboardingUserContactInfo;
