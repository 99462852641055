// External Dependencies
import {
  FC, useEffect, useRef, useState,
} from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { updateUserSchema } from '@presto-assistant/api_types/schemas/user';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { hasPermission } from 'state/self/selectors';
import { trimValues } from 'utils/lib/trimValues';
import { useGetOrganization, useGetStudent, useMemberLookup } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useUpdateUser } from 'gql/mutations';
import DialogConfirmExistingMember from 'pages/People/shared/DialogConfirmExistingMember';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import StudentForm, { StudentValues } from '../shared/StudentForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Component Definition
const StudentsEdit: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const [payload, setPayload] = useState<GQL.IUpdateUserOnMutationArguments | null>(null);

  const canEditUsers = useSelector(hasPermission('users', 'edit'));
  const originalEmailRef = useRef('');

  const {
    isOpen: isConfirmationDialogOpen,
    toggleIsOpen: handleToggleIsConfirmationDialogOpen,
  } = useIsOpen();

  const { data, loading } = useGetStudent(id);

  const canUseDynamicFields = useCanUseDynamicFields();

  const {
    data: organizationData,
  } = useGetOrganization();

  const organizationSuccessOrganizationId = organizationData?.organization
    .successorOrganization?.id;

  const navigateToShow = () => {
    navigate(`/${PATHS.STUDENTS}/${id}`);
  };

  // We track the original email value and only do a member lookup if it was changed
  useEffect(() => {
    if (data) {
      originalEmailRef.current = data.user.email;
    }
  }, [data]);

  const [updateStudent, { error }] = useUpdateUser(
    {
      clearCachePredicates: ['dashboardMetrics', 'studentsIndex'],
      onCompleted: navigateToShow,
    },
  );

  const checkForExistingMemberInThisOrganization = useMemberLookup();

  const handleEditStudent = () => {
    if (payload) {
      updateStudent({
        variables: payload,
      });
    }
  };

  const handleSubmit = async (
    {
      grade,
      primarySpokenLanguageId,
      ...values
    }: StudentValues,
  ) => {
    const updatedValues = { ...values };

    delete updatedValues.admin;
    delete updatedValues.roleId;

    const localPayload = id
      ? {
        id,
        input: {
          ...trimValues(updatedValues) as unknown as GQL.ICreateUserInput,
          grade: grade ? Number(grade) : null,
          isEligible: values.isEligible !== undefined
          // value will be '0' or '1' so we coerce the type to Number to check for truthiness
            ? Boolean(Number(values.isEligible))
            : undefined,
          primarySpokenLanguageId: primarySpokenLanguageId
            ? Number(primarySpokenLanguageId)
            : 1,
          // intentionally using || instead of ??
          successorOrganizationId: values.successorOrganizationId
            && values.successorOrganizationId !== organizationSuccessOrganizationId
            ? values.successorOrganizationId
            : null,
        },
      }
      : null;

    if (id) {
      setPayload(localPayload);
    }

    const emailWasUpdated = values.email !== originalEmailRef.current;

    let isExistingMember = true;

    if (emailWasUpdated) {
      const { data: existingMemberData } = await checkForExistingMemberInThisOrganization({
        memberEmail: values.email,
      });

      isExistingMember = Boolean(existingMemberData.memberLookup.length);
    }

    if (emailWasUpdated && isExistingMember) {
      handleToggleIsConfirmationDialogOpen();
    } else if (id && localPayload) {
      updateStudent({
        variables: localPayload,
      });
    }
  };

  const primaryRole = data?.user.userOrgData?.roles.find(({ isPrimary }) => isPrimary);
  const secondaryRoles = data?.user.userOrgData?.roles.filter(({ isPrimary }) => !isPrimary);

  const primaryRoleId = primaryRole?.organizationRole.id ?? '';
  const secondaryRoleIds = secondaryRoles?.map((role) => role.organizationRole.id) ?? [];

  return (
    <Page
      backButtonProps={{
        label: 'Student Detail',
        path: `/${PATHS.STUDENTS}/${id}`,
      }}
      isLoading={loading}
      notFound={!data?.user}
    >
      <StudentForm
        error={error}
        initialValues={{
          // family members cannot edit membership data or dynamic fields
          ...((canUseDynamicFields && canEditUsers) ? applyDynamicFields(data?.user) : {}),
          ...(canEditUsers ? {
            grade: data?.user.grade?.toString() ?? '',
            isEligible: data?.user.isEligible ? '1' : '0',
            primaryRoleId,
            secondaryRoleIds,
          } : {}),
          addressOne: data?.user.addressOne ?? '',
          addressTwo: data?.user.addressTwo ?? '',
          allergies: data?.user.allergies ?? '',
          city: data?.user.city ?? '',
          dateOfBirth: data?.user.dateOfBirth ?? '',
          email: data?.user.email ?? '',
          firstName: data?.user.firstName ?? '',
          genderId: data?.user.gender?.id ?? '',
          lastName: data?.user.lastName ?? '',
          middleName: data?.user.middleName ?? '',
          otherGenderLabel: data?.user.otherGenderLabel ?? '',
          phoneNumber: data?.user.phoneNumber ?? '',
          primarySpokenLanguageId:
            Number(data?.user.primarySpokenLanguage?.id) ?? 1,
          shirtSize: data?.user.shirtSize ?? '',
          stateId: data?.user.stateId ?? '',
          studentInfo: {
            studentId: data?.user.studentInfo?.studentId ?? '',
          },
          successorOrganizationId: data?.user.successorOrganization?.id
            ?? organizationSuccessOrganizationId
            ?? '',
          zipcode: data?.user.zipcode ?? '',
        }}
        onSubmit={handleSubmit}
        userId={id}
        userOrganizationId={data?.user.userOrgData?.id}
        validationSchema={updateUserSchema}
      />

      <DialogConfirmExistingMember
        isEditing
        isOpen={isConfirmationDialogOpen}
        onSubmit={handleEditStudent}
        onToggle={handleToggleIsConfirmationDialogOpen}
      />
    </Page>
  );
};

export default StudentsEdit;
