// External Dependencies
import {
  Button,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { FC } from 'react';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { PATHS } from 'utils/constants/routes';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';

const useStyles = makeStyles({
  secondaryListItemText: {
    maxWidth: '70%',
  },
});

// Component Definition
const ManageDistrictAssistantsListItem: FC = () => {
  const classes = useStyles();
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const navigateToDistrictAssistants = () =>
    navigate(`${PATHS.DISTRICT_ASSISTANTS}`);

  return (
    <ListItem>
      <ListItemText
        primary="Manage District Assistants"
        secondary={`Manage assistants that help oversee your ${APP_NAME} district.`}
        secondaryTypographyProps={{ classes: { root: classes.secondaryListItemText } }}
      />
      <ListItemSecondaryAction>
        <Button
          aria-label="View District Assistants"
          color="primary"
          onClick={navigateToDistrictAssistants}
          size={isMobileOrTabletScreen ? 'small' : 'medium'}
          variant="outlined"
        >
          View
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ManageDistrictAssistantsListItem;
