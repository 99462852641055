// External Dependencies
import { FC, useCallback } from 'react';
import { TrashCanOutline } from 'mdi-material-ui';
import ArrowDown from '@mui/icons-material/ArrowDownward';
import ArrowUp from '@mui/icons-material/ArrowUpward';
import Edit from '@mui/icons-material/Edit';

// Internal Dependencies
import { CopyAll } from '@mui/icons-material';
import ActionsIconMenu from 'components/shared/ActionsIconMenu';

// Local Typings
interface Props {
  blockId: string;
  formId: string;
  setDeleteBlockId: (blockId: string) => void;
}

// Component Definition
const BlockCardMenu: FC<Props> = ({
  blockId,
  formId,
  setDeleteBlockId,
}) => {
  const handleClickDelete = useCallback(() => {
    setDeleteBlockId(blockId);
  }, [blockId, setDeleteBlockId]);

  const handleClickEdit = useCallback(() => {
    console.log('Edit', {
      blockId,
      formId,
    });
  }, [blockId, formId]);

  const handleClickMoveUp = useCallback(() => {
    console.log('Move Up', {
      blockId,
      formId,
    });
  }, [blockId, formId]);

  const handleClickMoveDown = useCallback(() => {
    console.log('Move Down', {
      blockId,
      formId,
    });
  }, [blockId, formId]);

  const handleClickDuplicate = useCallback(() => {
    console.log('Duplicate', {
      blockId,
      formId,
    });
  }, [blockId, formId]);

  return (
    <ActionsIconMenu
      moreActions={[
        {
          action: handleClickMoveUp,
          icon: <ArrowUp />,
          isDisabled: true,
          text: 'Move Up',
        },
        {
          action: handleClickMoveDown,
          icon: <ArrowDown />,
          isDisabled: true,
          text: 'Move Down',
        },
        {
          action: handleClickDuplicate,
          icon: <CopyAll />,
          isDisabled: true,
          text: 'Duplicate',
        },
        {
          action: handleClickEdit,
          icon: <Edit />,
          isDisabled: true,
          text: 'Edit',
        },
        {
          action: handleClickDelete,
          icon: <TrashCanOutline />,
          text: 'Delete',
        },
      ]}
      tooltipTitle="Block Actions"
    />
  );
};

export default BlockCardMenu;
