// External Dependencies
import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { useGetOrganizationById } from 'gql/queries';

// Local Dependencies
import AdminDirectorsTable from './AdminDirectorsTable';
import LinkToAdultsWithPermissions from './shared/LinkToAdultsWithPermissions';
import OrganizationInfoCard from './OrganizationInfoCard';

// Local Typings
interface RouteParams {
  id: string;
}

// Component Definition
const AdminOrganization: FC<RouteParams> = ({ id }) => {
  const {
    data,
    loading,
  } = useGetOrganizationById({
    id,
  });

  return (
    <>
      <Box
        component="section"
        mb={2}
      >
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <OrganizationInfoCard
            organization={data?.organization}
          />
        )}
      </Box>

      <Box
        component="section"
        my={2}
      >
        <AdminDirectorsTable />
      </Box>

      <LinkToAdultsWithPermissions organizationId={id} />
    </>
  );
};

export default AdminOrganization;
