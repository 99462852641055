// External Dependencies
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import {
  FC,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

// Internal Dependencies
import {
  EditModeText,
  EnhancedAlert,
  EnhancedDialog,
  SlideUpTransition,
} from 'components/shared';
import { useGetReturningStudents } from 'gql/queries';

// Local Dependencies
import ReturningStudentsTable from './ReturningStudentsTable';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export interface ReturningGroupsState {
  groupId: string;
  shouldRetainUsers: boolean;
}

const StyledDialogRoot = styled(EnhancedDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(2, 2, 0, 0),
    bottom: 0,
    height: 720,
    margin: theme.spacing(0, 1.5),
    maxHeight: '95%',
    maxWidth: 1250,
    position: 'absolute',
  },

  '& .MuiDialogContent-root': {
    height: 400,
    padding: 0,
    width: '100%',
  },

  '& .MuiDialogTitle-root': {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

// Component Definition
const DialogReturningStudents: FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const { data } = useGetReturningStudents();

  if (!data) {
    return null;
  }

  return (
    <StyledDialogRoot
      TransitionComponent={SlideUpTransition}
      fullScreen
      maxWidth={false}
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle>
        <Typography
          component="div"
          variant="h6"
        >
          Update Returning Students
        </Typography>

        <IconButton
          aria-label="Close Update Returning Students dialog"
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <EnhancedAlert>
          <EditModeText />
          Update student data directly in the table.
        </EnhancedAlert>
        <ReturningStudentsTable
          returningStudentsData={data?.returningStudents}
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
          variant="contained"
        >
          Done
        </Button>
      </DialogActions>
    </StyledDialogRoot>
  );
};

export default DialogReturningStudents;
