// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';

// Local Dependencies
import GroupsTable from './GroupsTable';

// Component Definition
const Groups: FC = () => (
  <>
    <Subtitle>
      Groups
    </Subtitle>

    <EnhancedCard>
      <GroupsTable />
    </EnhancedCard>
  </>
);

export default Groups;
