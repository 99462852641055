// External Dependencies
import { UserRoles } from '@presto-assistant/api_types';
import { useMemo } from 'react';

// Internal Dependencies
import { SelectProps } from 'components/shared/Select';
import { isChildRelationshipType } from 'utils';
import { useGetRelationships } from 'gql/queries';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import { RelationshipTypeLabel } from '.';

// Local Typings
interface ChildOptionsArgs {
  excludeSelf: boolean;
  userId: string | null;
}

export const useChildOptions = ({ excludeSelf, userId }: ChildOptionsArgs): SelectProps['options'] => {
  const { self: selfData } = useSelfQuery();

  const { data: relationshipData } = useGetRelationships();

  // Transform our child relationships into options that the select can use
  const childOptions = useMemo<SelectProps['options']>(() => {
    const relationships = relationshipData?.userRelationships.filter(
      (rel) => rel.isConfirmed,
    ) ?? [];

    // The sender could have been the parent instead of the child
    // We look at sender and recipient data to be thorough
    // Filter the children into their own special list
    const childRelationships = relationships?.filter((rel) => {
      const selfIsSender = rel.sender.id === selfData?.id;

      const isSenderChild = isChildRelationshipType(
        rel.senderType?.label as RelationshipTypeLabel,
      );
      const isRecipientChild = isChildRelationshipType(
        rel.recipientType?.label as RelationshipTypeLabel,
      );

      return selfIsSender ? isRecipientChild : isSenderChild;
    });

    const options = childRelationships.map((rel) => {
      const person = rel.sender.id === selfData?.id ? rel.recipient : rel.sender;

      if (!person) {
        return null;
      }

      return {
        id: person.id,
        label: `${person.firstName} ${person.lastName}`,
      };
    });

    if (selfData && !excludeSelf) {
      const self = {
        id: selfData.id,
        label: `${selfData.firstName} ${selfData.lastName}`,
      };
      options.unshift(self);
    }

    return options.filter((opt) => !!opt) as SelectProps['options'];
  }, [
    excludeSelf,
    relationshipData,
    selfData,
  ]);

  // Not using optional chaining here because of number operator
  const hasRelationships = !!(relationshipData
    && relationshipData.userRelationships.length > 0);

  if (!hasRelationships || !userId) {
    return [];
  }

  if (selfData?.role.id !== UserRoles.Adult.toString()) {
    return [];
  }

  return childOptions;
};
