// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';

// Internal Dependencies
import { getLocalStorageItem } from 'utils/lib/local_storage';

// Local Dependencies
import { updateDarkMode, updateTableDensity } from './actions';

// Local Typings
export interface QuickSettingsMenuState {
  useDarkMode: boolean;
  useDenseTable: boolean;
}

// Local Variables
export const USE_DARK_MODE = 'useDarkMode';
export const USE_DENSE_TABLE = 'useDenseTable';
const getInitialDarkMode = () => {
  const darkMode = getLocalStorageItem(USE_DARK_MODE);

  const isInBeta = new Date().getFullYear() === 2022;
  const devicePrefersDarkMode = window.matchMedia?.('(prefers-color-scheme: dark)').matches;

  const darkModeDefault = devicePrefersDarkMode && !isInBeta;

  return darkMode ?? darkModeDefault ?? false;
};
const getInitialTableDensity = () => {
  const tableDensity = getLocalStorageItem(USE_DENSE_TABLE);

  return tableDensity ?? false;
};

// Reducers
const useDarkMode = createReducer(
  getInitialDarkMode(),
  (handleAction) => [
    handleAction(
      updateDarkMode,
      (_state, { payload }: ReduxData.IAction) => payload,
    ),
  ],
);
const useDenseTable = createReducer(
  getInitialTableDensity(),
  (handleAction) => [
    handleAction(
      updateTableDensity,
      (_state, { payload }: ReduxData.IAction) => payload,
    ),
  ],
);

export default combineReducers<QuickSettingsMenuState>({ useDarkMode, useDenseTable });
