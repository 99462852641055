// External Dependencies
import gql from 'graphql-tag';

// Internal Dependencies
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface InsertInventoryCsvToDbData {
  insertCsvFileToDB: boolean;
}

interface CreateResponse {
  id: string;
}

interface CreateData {
  createInventoryItem: CreateResponse;
}

interface CreateInventoryCategoryRespone {
  createInventoryCategory: {
    id: string;
    label: string;
  };
}

interface UpdateResponse {
  id: string;
}

interface UpdateData {
  updateInventoryItem: UpdateResponse;
}

export const CREATE_INVENTORY_ITEM = gql`
  mutation CreateInventoryItem(
    $input: CreateInventoryItemInput!
  ) {
    createInventoryItem(
      input: $input
    ) {
      id
    }
  }
`;

export const DELETE_INVENTORY_ITEM = gql`
  mutation DeleteInventoryItem(
    $deletedNote: String!
    $selection: Selection!
  ) {
    deleteInventoryItem(
      deletedNote: $deletedNote
      selection: $selection
    )
  }
`;

export const UPDATE_INVENTORY_ITEM = gql`
  mutation UpdateInventoryItem(
    $id: ID!
    $input: UpdateInventoryItemInput!
  ) {
    updateInventoryItem(
      id: $id
      input: $input
    ) {
      accessories
      caseNumber
      combination
      comments
      currentValueInCents
      customBarcode
      itemNumber
      location
      lockNumber
      locker
      onLoanToOrganization {
        id
        label
      }
      purchaseOrderNumber
      purchaseValueInCents
      quantity
      quality {
        id
      }
      brand
      category {
        id
        label
      }
      condition {
        id
        label
      }
      id
      label
      model
      organization {
        id
        label
      }
      organizationType {
        id
        label
      }
      purchasedAt
      serialNumber

      dynamicField01
      dynamicField02
      dynamicField03
      dynamicField04
      dynamicField05
      dynamicField06
      dynamicField07
      dynamicField08
      dynamicField09
      dynamicField10
      dynamicField11
      dynamicField12
      dynamicField13
      dynamicField14
      dynamicField15
      dynamicField16
      dynamicField17
      dynamicField18
      dynamicField19
      dynamicField20
      dynamicField21
      dynamicField22
      dynamicField23
      dynamicField24
      dynamicField25
      dynamicField26
      dynamicField27
      dynamicField28
      dynamicField29
      dynamicField30
      dynamicField31
      dynamicField32
      dynamicField33
      dynamicField34
      dynamicField35
      dynamicField36
      dynamicField37
      dynamicField38
      dynamicField39
      dynamicField40
      dynamicField41
      dynamicField42
      dynamicField43
      dynamicField44
      dynamicField45
      dynamicField46
      dynamicField47
      dynamicField48
      dynamicField49
      dynamicField50
    }
  }
`;

export const UPLOAD_INVENTORY_ITEMS_CSV = gql`
  mutation CsvInventoryUpload(
    $file: Upload!
  ) {
    csvInventoryUpload(
      file: $file
    ) {
        acceptableCSVHeaders
        badRows {
          errorMessages
          row
          rowNumber 
        }
        fileId
        goodRowCount
        headers
        rows
    }
  }
`;

export const INSERT_INVENTORY_CSV_TO_DB = gql`
  mutation InsertInventoryCsvFileToDB(
    $fileId: String!
    $headers: [String!]!
  ) {
    insertInventoryCsvFileToDB(
      fileId: $fileId
      headers: $headers
    )
  }
`;

const CREATE_INVENTORY_CATEGORY = gql`
  mutation CreateInventoryCategory (
    $input: CreateInventoryCategoryInput!
) {
  createInventoryCategory(
    input: $input
  ) {
    id
    label
  }
}
`;

export const useCreateInventoryItem = (
  options?: MutationOptions<
    CreateData,
    GQL.ICreateInventoryItemOnMutationArguments
  >,
) => useMutationEnhanced<
  CreateData,
  GQL.ICreateInventoryItemOnMutationArguments
>(CREATE_INVENTORY_ITEM, {
  ...options,
  clearCachePredicates: [
    ...(options?.clearCachePredicates ?? []),
    'history',
    'inventoryItems',
  ],
});

export const useUpdateInventoryItem = (
  options?: MutationOptions<
    UpdateData,
    GQL.IUpdateInventoryItemOnMutationArguments
  >,
) => useMutationEnhanced<
  UpdateData,
  GQL.IUpdateInventoryItemOnMutationArguments
>(
  UPDATE_INVENTORY_ITEM,
  {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'history',
      'inventoryItems',
    ],
  },
);

export const useInsertInventoryCsvToDb = (
  options?: MutationOptions<
    InsertInventoryCsvToDbData,
    GQL.IInsertInventoryCsvFileToDBOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    InsertInventoryCsvToDbData,
    GQL.IInsertInventoryCsvFileToDBOnMutationArguments
  >(INSERT_INVENTORY_CSV_TO_DB, {
    ...options,
    clearCachePredicates: [
      ...(options?.clearCachePredicates ?? []),
      'history',
      'inventoryItems',
    ],
  });

export const useCreateInventoryCategory = (
  options?: MutationOptions<
    CreateInventoryCategoryRespone,
    GQL.ICreateInventoryCategoryOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    CreateInventoryCategoryRespone,
    GQL.ICreateInventoryCategoryOnMutationArguments
  >(CREATE_INVENTORY_CATEGORY, options);
