// External Dependencies
import { DialogContentText } from '@mui/material';
import { FC } from 'react';
import { GridOverlay } from '@mui/x-data-grid-pro';
import styled from 'styled-components';

// Internal Dependencies
import { ZeroStateIcon } from 'components/shared';

// Local Variables
const StyledGridOverlayRoot = styled(GridOverlay)(({ theme }) => ({
  '& .MuiDialogContentText-root': {
    marginTop: theme.spacing(2.5),
    textAlign: 'center',
  },

  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(4),
}));

// Component Definition
const ReturningGroupsTableEmptyState: FC = () => (
  <StyledGridOverlayRoot>
    <ZeroStateIcon />

    <DialogContentText>
      No Groups data for the current school year.
    </DialogContentText>
  </StyledGridOverlayRoot>
);

export default ReturningGroupsTableEmptyState;
