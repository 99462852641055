// External Dependencies
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomInput,
  DialogForm,
} from 'components/shared';
import {
  useCreateUniformCategory,
} from 'gql/mutations';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  category: string;
}

// Local Variables
const initialValues: FormValues = {
  category: '',
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

// Component Definition
const DialogAddUniformCategory: FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const [
    handleCreateUniformCategory,
    {
      loading,
    },
  ] = useCreateUniformCategory({
    clearCachePredicates: ['uniformCategories'],
    onCompleted: onClose,
  });

  const handleSubmit = async (values: FormValues) => {
    await handleCreateUniformCategory({
      variables: {
        input: {
          label: values.category,
        },
      },
    });
  };

  return (
    <DialogForm<FormValues>
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
        open: isOpen,
      }}
      initialValues={initialValues}
      isSubmitting={loading}
      onClose={onClose}
      onSubmit={handleSubmit}
      title="Add Uniform Category"
    >
      <StyledTypography color="textSecondary">
        An uniform category will be available when adding or editing uniform items.
      </StyledTypography>

      <Box mb={2}>
        <CustomInput
          label="New Category"
          name="category"
        />
      </Box>
    </DialogForm>
  );
};

export default DialogAddUniformCategory;
