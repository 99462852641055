// External Dependencies
import { Box, InputAdornment } from '@mui/material';
import { FC } from 'react';
import { InventoryConditions } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  CustomInput,
  CustomInputWithTooltip,
  CustomSelect,
  DatePickerField,
  MoneyInput,
  OrganizationSelect,
  StyledLink,
} from 'components/shared';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';
import { mapEnum } from 'utils/lib/map_enum';
import { useGetOrganizationTypes } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import { useGetUniformSelectOptions } from './data';
import DialogAddUniformCategory from './DialogAddUniformCategory';
import DialogAddUniformStyle from './DialogAddUniformStyle';
import UniformCategorySelect from './UniformCategorySelect';
import UniformStyleSelect from './UniformStyleSelect';

// Local Typings
interface Props {
  isAdmin?: boolean;
  orgTypeId?: string;
}

// Local Variables
const conditionData = mapEnum(InventoryConditions);
const startAdornment = <InputAdornment position="start">$</InputAdornment>;

// Component Definition
const UniformInfoFormFields: FC<Props> = ({
  isAdmin,
  orgTypeId,
}) => {
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  // For tiny phone screens, we use less padding on the inputs
  const inputSize = isMobileOrTabletScreen ? 'small' : 'medium';

  const {
    isOpen: isAddUniformCategoryDialogOpen,
    toggleIsOpen: toggleAddUniformCategoryDialog,
  } = useIsOpen();

  const {
    isOpen: isAddUniformStyleDialogOpen,
    toggleIsOpen: toggleAddUniformStyleDialog,
  } = useIsOpen();

  const { data } = useGetUniformSelectOptions({
    where: {
      organizationTypeId: orgTypeId,
    },
  });

  // TODO: skip this query if not district admin
  const { data: orgTypeData } = useGetOrganizationTypes();

  return (
    <>
      {isAdmin && orgTypeId && orgTypeData && (
        <CustomSelect
          fullWidth
          label="Organization Type *"
          name="orgTypeId"
          options={orgTypeData.organizationTypes}
          required
          variant="filled"
        />
      )}

      {isAdmin && orgTypeId && (
        <OrganizationSelect orgTypeId={orgTypeId} />
      )}

      {orgTypeId && (
        <UniformCategorySelect orgTypeId={orgTypeId} />
      )}

      {/* for now, only directors can add categories */}
      {!isAdmin && (
        <Box
          mx={1.5}
          my={1}
        >
          <StyledLink onClick={toggleAddUniformCategoryDialog}>
            Add Uniform Category
          </StyledLink>
        </Box>
      )}

      {orgTypeId && (
        <UniformStyleSelect orgTypeId={orgTypeId} />
      )}

      {/* for now, only directors can add categories */}
      {!isAdmin && (
        <Box
          mx={1.5}
          my={1}
        >
          <StyledLink onClick={toggleAddUniformStyleDialog}>
            Add Uniform Style
          </StyledLink>
        </Box>
      )}

      <CustomSelect
        label="Condition"
        name="conditionId"
        options={conditionData}
      />

      <CustomInput
        label="Size"
        name="size"
      />
      <CustomInput
        label="Uniform Number *"
        name="uniformNumber"
      />
      {data?.uniformTypes && (
        <CustomSelect
          label="Uniform Type *"
          name="uniformTypeId"
          options={data.uniformTypes}
        />
      )}
      <CustomInput
        label="Color"
        name="color"
      />
      <CustomInput
        InputProps={{
          inputComponent: MoneyInput as any,
          startAdornment,
        }}
        label="Purchase Amount"
        name="purchaseValueInCents"
      />
      <DatePickerField
        disableFuture
        label="Purchase Date"
        name="purchasedAt"
      />
      <CustomInputWithTooltip
        label="Custom Barcode"
        name="customBarcode"
        size={inputSize}
        tooltipTitle="Scan an item now to add this barcode to the form"
      />
      <CustomInput
        label="Comments"
        multiline
        name="comments"
      />

      {!isAdmin && (
        <DialogAddUniformCategory
          isOpen={isAddUniformCategoryDialogOpen}
          onClose={toggleAddUniformCategoryDialog}
        />
      )}

      {!isAdmin && (
        <DialogAddUniformStyle
          isOpen={isAddUniformStyleDialogOpen}
          onClose={toggleAddUniformStyleDialog}
        />
      )}
    </>
  );
};

export default UniformInfoFormFields;
