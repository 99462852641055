// External Dependencies
import {
  Box,
  InputAdornment,
} from '@mui/material';
import { FC, FormEvent } from 'react';
import { FinancialPaymentTypes } from '@presto-assistant/api_types';
import { Form } from 'formik';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

// Internal Dependencies
import {
  CustomInput,
  CustomSelect,
  EnhancedCard,
  EnhancedContainer,
  FormActions,
  MoneyInput,
  ShowCard,
} from 'components/shared';

// Local Dependencies
import { useGetPaymentTypeSelectOptions } from '../All/FinancialPaymentsTable/data';
import FinancialItemShowCard from '../shared/FinancialFeeShowCard';

// Local Typings
interface Props {
  financialItemId: string;
  financialItemLabel: string;
  isFormTouched: boolean;
  isSubmitting: boolean;
  onClickCancel: () => void;
  onSubmit: (evt: FormEvent<HTMLFormElement>) => void;
  originalPriceInCents: number;
  paymentTypeId: string;
  remainingBalanceInCents: number;
  user: GQL.IUser;
}

// Local Variables
const startAdornment = <InputAdornment position="start">$</InputAdornment>;

// Component Definition
const EditForm: FC<Props> = ({
  financialItemId,
  financialItemLabel,
  isFormTouched,
  isSubmitting,
  onClickCancel,
  onSubmit,
  originalPriceInCents,
  paymentTypeId,
  remainingBalanceInCents,
  user,
}) => {
  const { data: typeOptionsData } = useGetPaymentTypeSelectOptions();

  let cashAndCheckOptions;
  if (typeOptionsData?.financialPaymentTypes) {
    // Directors are not allowed to enter a Stripe payment for a student
    // We remove the first option (Stripe) from the types
    cashAndCheckOptions = typeOptionsData.financialPaymentTypes.filter(
      (type) => type.id !== FinancialPaymentTypes.Stripe.toString(),
    );
  }

  const isCheckPayment = paymentTypeId === FinancialPaymentTypes.Check.toString();
  const isCCPayment = paymentTypeId === FinancialPaymentTypes.CreditCard.toString();
  const isOtherPaymentType = paymentTypeId === FinancialPaymentTypes.Other.toString();

  return (
    <Form onSubmit={onSubmit}>
      <EnhancedContainer>
        <ShowCard
          icon={LocalAtmIcon}
          title="Payment Info"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
          >
            <CustomInput
              InputProps={{
                inputComponent: MoneyInput as any,
                inputProps: {
                  'aria-label': 'Amount',
                },
                startAdornment,
              }}
              fullWidth
              label="Amount *"
              name="amountInCents"
              variant="filled"
            />

            <CustomInput
              fullWidth
              label="Date Paid"
              name="datePaid"
              type="date"
              variant="filled"
            />

            {typeOptionsData?.financialPaymentTypes && (
              <CustomSelect
                name="financialPaymentTypeId"
                options={cashAndCheckOptions}
                label="Payment Type"
                required
                size="small"
              />
            )}

            {isCheckPayment && (
              <CustomInput
                label="Check Number"
                name="checkNumber"
                variant="filled"
              />
            )}

            {isCCPayment && (
              <>
                <CustomInput
                  label="Account Holder"
                  name="creditCardAccountHolder"
                  variant="filled"
                />

                <CustomInput
                  label="Expiration"
                  name="creditCardExp"
                  variant="filled"
                />

                <CustomInput
                  label="Last Four"
                  name="creditCardLastFour"
                  variant="filled"
                />
              </>
            )}

            {isOtherPaymentType && (
              <>
                <CustomInput
                  label="Payment Type Name"
                  name="otherLabel"
                  placeholder="Online store"
                  variant="filled"
                />

                <CustomInput
                  label="Reference Number"
                  name="referenceNumber"
                  variant="filled"
                />
              </>
            )}

            <CustomInput
              label="Note"
              multiline
              name="note"
              rows={4}
              variant="filled"
            />
          </Box>
        </ShowCard>

        <Box my={2}>
          <FinancialItemShowCard
            financialItemId={financialItemId}
            financialItemLabel={financialItemLabel}
            originalPriceInCents={originalPriceInCents}
            remainingBalanceInCents={remainingBalanceInCents}
            user={user}
          />
        </Box>

        <EnhancedCard>
          <FormActions
            context="Payment"
            isEditing
            isFormTouched={isFormTouched}
            isSubmitting={isSubmitting}
            onPressCancelOrBackButton={onClickCancel}
          />
        </EnhancedCard>
      </EnhancedContainer>
    </Form>
  );
};

export default EditForm;
