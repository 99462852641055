// External Dependencies
import {
  CardContent,
  Collapse,
} from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';

// Component Definition
const PaymentFormEmptyState: FC = () => (
  <Collapse
    in
    timeout="auto"
  >
    <CardContent sx={{ mx: 3, my: 1.5 }}>
      <EnhancedAlert severity="warning">
        This person has no outstanding fees. Please assign a fee before applying a payment.
      </EnhancedAlert>
    </CardContent>
  </Collapse>
);

export default PaymentFormEmptyState;
