// External Dependencies
import { CardContent } from '@mui/material';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  EnhancedCard,
  Page,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { isCreator } from 'state/self/selectors';
import GoogleCalendarSettings from 'pages/Settings/OrganizationIntegrationsCard/GoogleCalendarSettings';

// Local Dependencies
import EventCalendar from './EventCalendar';

// Local Variables
const handleNavigateToDashboard = () => {
  navigate(`/${PATHS.DASHBOARD}`);
};

// Component Definition
const Calendar = () => {
  const isUserCreator = useSelector(isCreator);

  return (
    <Page>
      <EnhancedCard>
        <CardContent>
          <EventCalendar />
        </CardContent>
      </EnhancedCard>

      {isUserCreator && (
        <EnhancedCard sx={{ mt: 2 }}>
          <CardContent>
            <GoogleCalendarSettings onSuccess={handleNavigateToDashboard} />
          </CardContent>
        </EnhancedCard>
      )}
    </Page>
  );
};

export default Calendar;
