// External Dependencies
import { FC, useEffect } from 'react';
import { Form } from 'formik';
import { navigate } from '@reach/router';

// Internal Dependencies
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';

// Local Typings
export interface SchoolYearFormValues {
  schoolYearEnding: number;
}

interface Props {
  getPathOnFilterChange: (schoolYearEnding: number) => string;
  previousYearsOnly?: boolean;
  schoolYearFormValues: SchoolYearFormValues;
}

// Component Definition
const SchoolYearForm: FC<Props> = ({
  getPathOnFilterChange,
  previousYearsOnly,
  schoolYearFormValues,
}) => {
  useEffect(() => {
    navigate(getPathOnFilterChange(schoolYearFormValues.schoolYearEnding));
  }, [getPathOnFilterChange, schoolYearFormValues.schoolYearEnding]);

  return (
    <Form>
      <SchoolYearSelect
        previousYearsOnly={previousYearsOnly}
        required
      />
    </Form>
  );
};

export default SchoolYearForm;
