// External Dependencies
import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

// Internal Dependencies
import { displayCell } from 'components/shared/TableV2';

// Local Dependencies
import { renderAttachmentCell } from './helpers';

export const useColumns = () => useMemo(() => {
  const columns: GridColDef<GQL.IEmailIndexItem>[] = [
    {
      field: 'sentAt',
      headerName: 'Date Sent',
      type: 'dateTime',
      valueFormatter: ({ value }) => displayCell(value, { format: 'datetime' }),
    },
    {
      field: 'subject',
      headerName: 'Subject',
      renderCell: (row) => displayCell(row.value),
    },
    {
      field: 'attachmentCount',
      headerName: 'Attachments',
      renderCell: (row) => renderAttachmentCell(row.value),
    },
    {
      field: 'recipientCount',
      headerName: 'Recipients',
      renderCell: (row) => displayCell(row.value),
    },
  ];

  return columns;
}, []);
