import { TableResource } from 'state/table/actions';

export type LocalStorageItem =
  | `${TableResource}PageSize`
  | 'useDarkMode'
  | 'useDenseTable'
  | 'useV2StudentsTable'
  | 'viewedAlerts';

export function getLocalStorageItem(item: LocalStorageItem): string | null {
  return localStorage.getItem(item);
}

export function setLocalStorageItem(item: LocalStorageItem, value: string) {
  localStorage.setItem(item, value);
}

export function removeLocalStorageItem(item: LocalStorageItem) {
  localStorage.removeItem(item);
}
