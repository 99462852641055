// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';

// Local Dependencies
import DynamicFieldsTable from './DynamicFieldsTable';

// Component Definition
const AdminDynamicFields: FC = () => (
  <>
    <Subtitle>
      Dynamic Fields
    </Subtitle>
    <EnhancedCard>
      <DynamicFieldsTable />
    </EnhancedCard>
  </>
);

export default AdminDynamicFields;
