// External Dependencies
import { FC, useEffect, useState } from 'react';
import { MutationResult } from '@apollo/client';
import { updateOrganizationSchema } from '@presto-assistant/api_types/schemas/organization';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomCheckbox,
  CustomInput,
  CustomSelect,
  DialogForm,
} from 'components/shared';
import {
  GET_ORGANIZATION,
  GET_SELF,
} from 'gql/queries';
import { OrganizationEntityTypes } from '@presto-assistant/api_types';
import { UPDATE_ORGANIZATION } from 'gql/mutations';
import { close } from 'state/ui/editOrganizationDialog/actions';
import { getStates } from 'utils/api';
import { isOpen } from 'state/ui/editOrganizationDialog/selectors';
import { updateById } from 'gql/actions';
import { useMutationEnhanced, useQueryEnhanced } from 'utils/lib/graphql';
import SuccessorOrganizationSelect from 'components/shared/Selectors/SuccessorOrganizationSelect';

// Local Variables
const StyledDialogForm = styled(DialogForm)(({ theme }) => ({
  '.firstInRow': {
    marginRight: theme.spacing(1.5),
  },
  '.orgCodeText': {
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  },
  '.rowContainer': {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
}));

// Component Definition
const DialogEditOrganization: FC = () => {
  const dispatch = useDispatch();

  const isDialogOpen = useSelector(isOpen);

  const [stateOptions, setStateOptions] = useState(undefined);

  const getStateOptions = async () => {
    const response = await getStates();
    const { data } = response;

    if (data?.states) {
      setStateOptions(data.states);
    }
  };

  useEffect(() => {
    getStateOptions();
  }, []);

  const handleClose = () => {
    dispatch(close());
  };

  const {
    data,
    error: orgDataError,
    loading: isLoadingOrgData,
  } = useQueryEnhanced<{ organization: GQL.IOrganization }>(GET_ORGANIZATION);

  const [
    updateOrganization,
    { loading: isUpdatingOrganization },
  ] = useMutationEnhanced<MutationResult>(UPDATE_ORGANIZATION, {
    clearCachePredicates: ['financial'],
    onCompleted: handleClose,
    refetchQueries: [
      { query: GET_SELF },
      { query: GET_ORGANIZATION },
    ],
  });

  if (orgDataError || isLoadingOrgData || !data?.organization) {
    return null;
  }

  const {
    organization: {
      addressOne,
      addressTwo,
      city,
      code,
      entityType,
      id: organizationId,
      isIgnoringPreviousYearsFinances,
      label,
      phoneNumber,
      stateId,
      successorOrganization,
      zipcode,
    },
  } = data;

  const isCollegeOrUniversity = entityType.id === OrganizationEntityTypes.College.toString();

  return (
    <StyledDialogForm
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
        open: isDialogOpen,
      }}
      initialValues={{
        addressOne,
        addressTwo: addressTwo || '',
        city,
        code,
        isIgnoringPreviousYearsFinances,
        label,
        phoneNumber,
        stateId,
        successorOrganizationId: successorOrganization?.id ?? '',
        zipcode,
      } as GQL.IUpdateOrganizationOnMutationArguments}
      isSubmitting={isUpdatingOrganization}
      nonRequiredValues={['addressTwo', 'successorOrganizationId']}
      onClose={handleClose}
      onSubmit={(values) =>
        updateById({
          id: organizationId,
          mutation: updateOrganization,
          onCloseDialog: handleClose,
          values: {
            ...values as GQL.IUpdateOrganizationOnMutationArguments,
            // intentionally using || instead of ??
            successorOrganizationId: (values as GQL.IUpdateOrganizationOnMutationArguments)
              .successorOrganizationId || null,
          },
        })}
      title="Update Organization Info"
      validationSchema={updateOrganizationSchema}
    >
      <CustomInput
        label="Organization Name"
        name="label"
      />
      <CustomInput
        label="Address 1"
        name="addressOne"
      />
      <CustomInput
        label="Address 2"
        name="addressTwo"
      />

      <div className="rowContainer">
        <CustomInput
          label="City"
          name="city"
          rootStyles="firstInRow"
        />
        {stateOptions && (
          <CustomSelect
            label="State"
            name="stateId"
            options={stateOptions}
            required
            variant="filled"
          />
        )}
      </div>

      <div className="rowContainer">
        <CustomInput
          label="Zip Code"
          name="zipcode"
          rootStyles="firstInRow"
          variant="filled"
        />
        <CustomInput
          label="Phone Number"
          name="phoneNumber"
          type="tel"
          variant="filled"
        />
      </div>

      <Divider sx={{ marginY: 2 }} />

      <Typography className="orgCodeText">
        {`Provide this code to parents, students, and
          staff for use when joining this organization.`}
      </Typography>

      <CustomInput
        label="Organization Code"
        name="code"
        variant="filled"
      />

      <Divider sx={{ marginY: 2 }} />

      <CustomCheckbox
        helperText="This will ignore all financial fees from previous years. Payments will not be able to be made for previous years."
        label="Ignore financial data from previous years"
        name="isIgnoringPreviousYearsFinances"
      />

      {!isCollegeOrUniversity && (
        <>
          <Divider sx={{ marginY: 2 }} />

          <SuccessorOrganizationSelect />
        </>
      )}
    </StyledDialogForm>
  );
};

export default DialogEditOrganization;
