// External Dependencies
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useGetDistrictOrganizationIndex } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import AdminOrganizationsTableZeroState from './AdminOrganizationsTableZeroState';

// Component Definition
const AdminOrganizationsTable = () => {
  const districtDirectorsParams = useSelector(
    tableQueryParams('districtOrganizationsDirectors'),
  );

  const {
    data,
    loading,
  } = useGetDistrictOrganizationIndex({
    isUsingDataGrid: true,
  });

  const columns = useColumns();

  const handleTableClickRow = (id: string) => `/${PATHS.DISTRICT_ADMIN}/${PATHS.ORGANIZATIONS}/${id}${districtDirectorsParams}`;

  return (
    <DataGridContainer>
      <TableDataGrid
        clickRowTo={handleTableClickRow}
        columns={columns}
        components={{
          NoRowsOverlay: AdminOrganizationsTableZeroState,
        }}
        loading={loading}
        tableResource="districtOrganizations"
        rows={data?.districtOrganizationsIndex.data ?? []}
        withSearch
      />
    </DataGridContainer>
  );
};

export default AdminOrganizationsTable;
