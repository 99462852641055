// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

// Internal Dependencies
import { capitalize } from 'utils';

// Local Variables
const getPermissionCell = (args: {
  field: string;
  headerName: string;
  minWidth?: number;
  valueGetter: GridColDef<GQL.IMemberPermission>['valueGetter'];
  valueSetter: GridColDef<GQL.IMemberPermission>['valueSetter'];
}): GridColDef<GQL.IMemberPermission> => ({
  editable: true,
  field: args.field,
  headerName: args.headerName,
  hideable: true,
  minWidth: args.minWidth ?? 200,
  type: 'boolean',
  valueGetter: args.valueGetter,
  valueSetter: args.valueSetter,
  width: 250,
});

const getPermissionCellGroup = (args: {
  actions: (keyof GQL.IPermissionActions)[];
  headerName: string;
  minWidth?: number;
  permissionName: keyof GQL.IPermissions,
}) => args.actions.map((action) => getPermissionCell({
  field: `permissions.${args.permissionName}.${action}.permissionId`,
  headerName: `${args.headerName} — ${capitalize(action)}`,
  // cast to any is necessary because of possibly providing "__typename"
  minWidth: args.minWidth,
  valueGetter: (params) =>
    (params.row.permissions as any)[args.permissionName as any][action].allowed,
  valueSetter: (params) => ({
    ...params.row,
    permissions: {
      ...params.row.permissions,
      [args.permissionName]: {
        ...(params.row.permissions as any)[args.permissionName],
        [action]: {
          ...(params.row.permissions as any)[args.permissionName as any][action],
          allowed: params.value,
        },
      },
    },
  }),
}));

export const useColumns = () => useMemo(() => {
  const columns: GridColDef<GQL.IMemberPermission>[] = [
    {
      editable: false,
      field: 'email',
      headerName: 'Email',
      hideable: false,
      minWidth: 150,
      valueGetter: (params) => params.row.member.email,
    },
    {
      editable: false,
      field: 'firstName',
      headerName: 'First Name',
      hideable: false,
      valueGetter: (params) => params.row.member.firstName,
    },
    {
      editable: false,
      field: 'lastName',
      headerName: 'Last Name',
      hideable: false,
      valueGetter: (params) => params.row.member.lastName,
    },
    {
      editable: false,
      field: 'organization',
      headerName: 'Organization',
      hideable: false,
      minWidth: 150,
      valueGetter: (params) => params.row.organization.label,
    },
    {
      editable: true,
      field: 'mayEditPermissions',
      headerName: 'May Edit Permissions',
      hideable: false,
      minWidth: 180,
      type: 'boolean',
      valueGetter: (params) => params.row.mayEditPermissions,
    },
    ...getPermissionCellGroup({
      actions: ['write'],
      headerName: 'Email',
      permissionName: 'emailMembers',
    }),
    ...getPermissionCellGroup({
      actions: ['write', 'read', 'edit', 'delete'],
      headerName: 'File Uploads',
      permissionName: 's3Uploads',
    }),
    ...getPermissionCellGroup({
      actions: ['write', 'read', 'edit', 'delete'],
      headerName: 'Finances',
      permissionName: 'finances',
    }),
    ...getPermissionCellGroup({
      actions: ['write', 'read', 'edit', 'delete'],
      headerName: 'Financial Accounts',
      permissionName: 'financialAccounts',
    }),
    ...getPermissionCellGroup({
      actions: ['write', 'read', 'edit', 'delete'],
      headerName: 'Groups',
      permissionName: 'groups',
    }),
    ...getPermissionCellGroup({
      actions: ['write', 'read', 'delete'],
      headerName: 'Inventory (All Fields)',
      minWidth: 220,
      permissionName: 'inventory',
    }),
    ...getPermissionCellGroup({
      actions: ['edit'],
      headerName: 'Inventory Basic Info',
      permissionName: 'inventory',
    }),
    ...getPermissionCellGroup({
      actions: ['edit'],
      headerName: 'Inventory Status Info',
      permissionName: 'inventoryStatusInfo',
    }),
    ...getPermissionCellGroup({
      actions: ['edit'],
      headerName: 'Inventory Location Info',
      minWidth: 220,
      permissionName: 'inventoryLocationInfo',
    }),
    ...getPermissionCellGroup({
      actions: ['edit'],
      headerName: 'Inventory Purchase Info',
      minWidth: 230,
      permissionName: 'inventoryPurchaseInfo',
    }),
    ...getPermissionCellGroup({
      actions: ['edit'],
      headerName: 'Inventory Dynamic Fields',
      minWidth: 230,
      permissionName: 'inventoryDynamicFields',
    }),
    ...getPermissionCellGroup({
      actions: ['write', 'read', 'edit'],
      headerName: 'Inventory Checkouts',
      permissionName: 'inventoryCheckouts',
    }),
    ...getPermissionCellGroup({
      actions: ['write', 'read', 'edit', 'delete'],
      headerName: 'Library',
      permissionName: 'library',
    }),
    ...getPermissionCellGroup({
      actions: ['write', 'read', 'edit'],
      headerName: 'Payments',
      permissionName: 'payments',
    }),
    ...getPermissionCellGroup({
      actions: ['write', 'read', 'edit', 'delete'],
      headerName: 'Uniform Checkouts',
      permissionName: 'uniformCheckouts',
    }),
    ...getPermissionCellGroup({
      actions: ['write', 'read', 'edit', 'delete'],
      headerName: 'Uniforms',
      permissionName: 'uniforms',
    }),
    ...getPermissionCellGroup({
      actions: ['write', 'read', 'edit', 'delete'],
      headerName: 'Users',
      permissionName: 'users',
    }),
  ];

  return columns;
}, []);
