// External Dependencies
import {
  Box, InputAdornment, TextField,
} from '@mui/material';
import { FC, FormEvent } from 'react';
import { Form } from 'formik';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

// Internal Dependencies
import {
  EnhancedCard, EnhancedContainer, FormActions, MoneyInput, ShowCard,
} from 'components/shared';
import { useFormikTextField } from 'hooks/useFormikTextField';
import FinancialAccountSelect from 'components/shared/FinancialAccountSelect';

// Local Typings
interface Props {
  initialValues: GQL.IUpdateFinancialCreditInput;
  isFormTouched: boolean;
  isSubmitting: boolean;
  onClickCancel: () => void;
  onSubmit: (evt: FormEvent<HTMLFormElement>) => void;
}

// Local Variables
const startAdornment = <InputAdornment position="start">$</InputAdornment>;

// Component Definition
const EditForm: FC<Props> = ({
  initialValues,
  isFormTouched,
  isSubmitting,
  onClickCancel,
  onSubmit,
}) => {
  const amountField = useFormikTextField('amountInCents', initialValues.amountInCents.toString());
  const noteField = useFormikTextField('note', initialValues.note ?? '');

  return (
    <Form onSubmit={onSubmit}>
      <EnhancedContainer>
        <ShowCard
          icon={LocalAtmIcon}
          title="Credit Info"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
          >
            <TextField
              InputProps={{
                inputComponent: MoneyInput as any,
                inputProps: {
                  'aria-label': 'Amount',
                },
                startAdornment,
              }}
              fullWidth
              label="Amount *"
              variant="filled"
              {...amountField}
            />

            <FinancialAccountSelect />

            <TextField
              label="Note"
              multiline
              rows={4}
              variant="filled"
              {...noteField}
            />
          </Box>
        </ShowCard>

        <Box mt={2}>
          <EnhancedCard>
            <FormActions
              context="Credit"
              isEditing
              isFormTouched={isFormTouched}
              isSubmitting={isSubmitting}
              onPressCancelOrBackButton={onClickCancel}
            />
          </EnhancedCard>
        </Box>
      </EnhancedContainer>
    </Form>
  );
};

export default EditForm;
