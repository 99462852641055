import { FC } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

const PaymentFormTableInput: FC<TextFieldProps> = (props) => (
  <TextField
    margin="none"
    size="small"
    variant="outlined"
    {...props}
  />
);

export default PaymentFormTableInput;
