// External Dependencies
import { Box, List } from '@mui/material';
import { FC } from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';

// Internal Dependencies
import {
  EnhancedCard,
  ListItemWithSecondaryAction,
  Subtitle,
} from 'components/shared';
import { navigate } from '@reach/router';

// Local Typings
interface Props {
  itemsNeedingAttention: GQL.IItemNeedingAttention[];
}

// Local Variables
const handleNavigate = (url: string) => () => {
  navigate(url);
};

// Component Definition
const ItemsNeedingAttentionCard: FC<Props> = ({
  itemsNeedingAttention,
}) => (
  <Box marginTop={2}>
    <Subtitle>
      Items Needing Attention
    </Subtitle>

    <EnhancedCard>
      <List>
        {itemsNeedingAttention.map((item) => (
          <ListItemWithSecondaryAction
            key={item.message}
            primaryText={item.message}
            secondaryAction={item.url ? ({
              buttonIcon: <ArrowRightIcon />,
              buttonText: 'View',
              onClick: handleNavigate(item.url),
            }) : undefined}
          />
        ))}
      </List>
    </EnhancedCard>
  </Box>
);

export default ItemsNeedingAttentionCard;
