// External Dependencies
import { FC } from 'react';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Internal Dependencies
import { SUPPORT_SITE_HREF } from 'utils/constants';

// Local Dependencies
import StyledLink from './StyledLink';

// Local Typings
type Slug =
  | 'add-assistant-directors-and-staff'
  | 'communication-walkthrough/#send-emails'
  | 'create-a-new-district'
  | 'district-it-checklist'
  | 'finances-walkthrough/#sync-with-stripe'
  | 'import-student-data#troubleshooting'
  | 'import-student-data'
  | 'inventory-walkthrough/#manage-inventory-items'
  | 'inventory-walkthrough/#manage-library-items'
  | 'inventory-walkthrough/#manage-uniforms'
  | 'members-walkthrough'
  | 'join-an-organization-with-member-claim-code'
  | 'purchase-a-presto-license'
  | 'send-welcome-email-to-all'
  | 'send-welcome-email-to-one-member'
  | 'set-up-online-payments';
interface Props {
  button?: boolean;
  buttonText?: string;
  isVideo?: boolean;
  slug: Slug;
}

/*
 * Used to link to a specific support article.
 *
 */

// Component Definition
const SupportLink: FC<Props> = ({
  button,
  buttonText = 'Learn more',
  isVideo = false,
  slug,
  ...otherProps
}) => {
  const route = isVideo ? 'videos' : 'articles';

  return button ? (
    <Button
      endIcon={<OpenInNewIcon />}
      href={`${SUPPORT_SITE_HREF}/${route}/${slug}/`}
      size="small"
      target="_blank"
      variant="outlined"
      {...otherProps}
    >
      {buttonText}
    </Button>
  ) : (
    <StyledLink
      href={`${SUPPORT_SITE_HREF}/${route}/${slug}`}
      noWrap
      openInNewTab
      {...otherProps}
    >
      {buttonText}
    </StyledLink>
  );
};

export default SupportLink;
