// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { CustomCheckbox } from 'components/shared';

// Local Typings
interface Props {
  formBlock: GQL.IMyFormBlock;
}

// Component Definition
const CheckboxBlock: FC<Props> = ({
  formBlock,
}) => {
  return (
    <CustomCheckbox
      label={formBlock.label}
      name="response"
    />
  );
};

export default CheckboxBlock;
