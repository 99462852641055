// External Dependencies
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';

// Internal Dependencies
import { DeleteRelationshipButton, UserAvatar } from 'components/shared';
import { Relationship } from 'gql/queries';
import { getFullName } from 'utils';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
interface Props {
  relationship: Relationship;
}

// Local Variables
const StyledTypography = styled(Typography)({
  // We are setting a `<span>` to block to avoid HTML warnings
  //  since a `<p>` cannot have a nested `<div>`
  display: 'block',
  lineHeight: 1.6,
}) as typeof Typography;

const handleClickRelationship = (memberId: string) => () => {
  navigate(`/members/${memberId}`);
};

// Component Definition
const RelationshipListItem: FC<Props> = ({
  relationship,
}) => {
  const { self } = useSelfQuery();

  const {
    recipient,
    recipientType,
    sender,
    senderType,
  } = relationship;

  const isSender = self?.id === sender.id;
  const otherUser = isSender ? recipient : sender;
  const otherUserRelationshipType = isSender ? recipientType : senderType;
  const otherUserEmail = isSender ? relationship.recipientEmail : sender.email;

  return (
    <ListItem
      alignItems="flex-start"
      button
      key={relationship.id}
      onClick={handleClickRelationship(otherUser?.id ?? '')}
    >
      <ListItemAvatar>
        <UserAvatar iconName="face" />
      </ListItemAvatar>

      <ListItemText
        primary={otherUserRelationshipType.label}
        secondary={(
          <Box
            component="span"
            pt={0.5}
          >
            <StyledTypography
              color="textPrimary"
              component="span"
              variant="body2"
            >
              {otherUser ? getFullName(otherUser) : ''}
            </StyledTypography>
            {otherUserEmail}
          </Box>
        )}
      />

      <ListItemSecondaryAction>
        <DeleteRelationshipButton relationshipId={relationship.id} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default RelationshipListItem;
