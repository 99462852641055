// External Dependencies
import { FC } from 'react';
import { navigate } from '@reach/router';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';

// Local Dependencies
import AlertButton from './AlertButton';

// Local Variables
const handlePressButton = () => {
  navigate(`/${PATHS.STRIPE_VERIFICATION}`);
};

// Component Definition
const StripeOrgSignUpButton: FC = () => (
  <AlertButton
    buttonText="Manage"
    onClick={handlePressButton}
  />
);

export default StripeOrgSignUpButton;
