// External Dependencies
import { Box, IconButton, TableCell } from '@mui/material';
import { FC } from 'react';
import AddIcon from '@mui/icons-material/AddCircle';

// Local Dependencies
import { StyledTableRow } from '../styles';

// Local Typings
interface Props {
  onClickAdd: () => void;
}

// Component Definition
const AddNewRowTableRow: FC<Props> = ({
  onClickAdd,
}) => (
  <StyledTableRow>
    <TableCell>
      <Box marginY={0.5}>
        <IconButton
          color="primary"
          onClick={onClickAdd}
          size="small"
        >
          <AddIcon />
        </IconButton>
      </Box>
    </TableCell>
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
  </StyledTableRow>
);

export default AddNewRowTableRow;
