// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { navigate } from '@reach/router';
import CategoryIcon from '@mui/icons-material/Category';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import DownloadIcon from 'mdi-material-ui/Download';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SettingsIcon from '@mui/icons-material/Settings';

// Internal Dependencies
import { DELETE_INVENTORY_ITEM } from 'gql/mutations';
import {
  GET_DISTRICT_INVENTORY_ITEMS_INDEX,
  useDownloadDistrictInventoryIndexItems,
  useGetDistrictInventoryIndexItems,
} from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { ToolbarProps } from 'components/shared/DataTable/Toolbar';
import { useClearTableSelection } from 'hooks/useClearTableSelection';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParsedSearch } from 'hooks/useParsedSearch';
import DeleteDialog from 'components/shared/DeleteDialog';
import TableV2 from 'components/shared/TableV2';

// Local Dependencies
import { useColumns } from './hooks';
import { useGetDistrictInventoryReport } from '../../../../../gql/queries/report-queries';
import DialogInventoryFileUpload from './DialogInventoryFileUpload';
import Filters from './Filters';

// Component Definition
const AdminInventoryTable: FC = () => {
  const parsedSearch = useParsedSearch();

  const [singleItemDeleteId, setSingleItemDeleteId] = useState<string | null>(null);

  const {
    handleClose: closeDeleteDialog,
    isOpen: isDeleteDialogOpen,
  } = useIsOpen();
  const {
    handleClose: closeUploadDialog,
    handleOpen: openUploadDialog,
    isOpen: isUploadDialogOpen,
  } = useIsOpen();

  const {
    data,
    loading,
  } = useGetDistrictInventoryIndexItems();

  const [
    handleExportInventory,
    { loading: isExporting },
  ] = useDownloadDistrictInventoryIndexItems();

  const districtInventoryReportParams = useMemo<GQL.IDistrictInventoryReportQueryParams>(() => ({
    isCheckedOut: parsedSearch.isCheckedOut,
    isDeleted: parsedSearch.isDeleted,
    organizationId: parsedSearch.organizationId,
    organizationTypeId: parsedSearch.organizationTypeId,
    q: parsedSearch.q,
  }), [parsedSearch]);

  const [
    handleExportFullReport,
    { loading: isExportingFullReport },
  ] = useGetDistrictInventoryReport(districtInventoryReportParams);

  const handleRemoveSelectionAll = useClearTableSelection('districtInventoryItems');

  const handleOpenSingleItemDeleteDialog = useCallback((row: GQL.IDistrictInventoryIndexItem) => {
    setSingleItemDeleteId(row.id);
  }, [setSingleItemDeleteId]);

  const handleCloseDeleteDialog = () => {
    setSingleItemDeleteId(null);
    closeDeleteDialog();
  };

  const handleNavigateToDynamicFields = useCallback(() => {
    const organizationTypeId = parsedSearch.organizationTypeId ?? '1';

    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.DYNAMIC_FIELDS}?organization_type_id=${organizationTypeId}&table_ref=inventory_items`);
  }, [parsedSearch]);

  const hasAtLeastOneInventoryItem = (data?.districtInventoryItemsIndex.fullCount ?? 0) > 0;

  const columns = useColumns(
    handleOpenSingleItemDeleteDialog,
    parsedSearch.organizationTypeId,
  );

  const moreActions: ToolbarProps['moreActions'] = [
    {
      action: handleExportInventory,
      icon: <DownloadIcon />,
      isDisabled: isExporting || (data?.districtInventoryItemsIndex.fullCount ?? 0) < 1,
      text: 'Export inventory',
    },
    {
      action: handleExportFullReport,
      icon: <EqualizerIcon />,
      isDisabled: isExportingFullReport || data?.districtInventoryItemsIndex.fullCount === 0,
      text: 'Export full report',
    },
    {
      action: openUploadDialog,
      icon: <CloudUploadIcon />,
      // TODO: Update this to the new way the API tells us about active
      // isDisabled: !self?.currentOrgActive,
      text: 'Import inventory',
    },
    {
      action: handleNavigateToDynamicFields,
      icon: <CategoryIcon />,
      text: 'Dynamic fields',
    },
  ];

  const handleClickSettings = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY_SETTINGS}`);
  }, []);

  return (
    <>
      <TableV2<GQL.IDistrictInventoryIndexItem>
        actions={moreActions}
        addButtonProps={{
          label: 'Inventory Item',
          to: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}/new`,
        }}
        Filters={<Filters />}
        clickRowTo={(row) => `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}/${row.id}`}
        columns={columns}
        data={data?.districtInventoryItemsIndex.data ?? []}
        disableSearch={!parsedSearch.q && !hasAtLeastOneInventoryItem}
        fullCount={data?.districtInventoryItemsIndex.fullCount}
        isLoading={loading}
        reduxStateKey="districtInventoryItems"
        settingsMenuProps={{
          IconButtonIcon: SettingsIcon,
          iconProps: {
            fontSize: 'small',
          },
          settingsItems: [
            {
              id: 'inventory-settings',
              props: {
                onClick: handleClickSettings,
                text: 'All inventory settings',
              },
              type: 'menuItem',
            },
          ],
        }}
        withSearch
        zeroStateMessage="Add data to this table by adding individually. Inventory added by organizations will also appear here."
      />

      <DialogInventoryFileUpload
        isOpen={isUploadDialogOpen}
        onClose={closeUploadDialog}
      />

      <DeleteDialog
        clearCachePredicates={['districtDeletedInventoryItemsIndex']}
        context={['item']}
        isOpen={isDeleteDialogOpen || !!singleItemDeleteId}
        mutation={DELETE_INVENTORY_ITEM}
        onClose={handleCloseDeleteDialog}
        onRemoveSelectionAll={handleRemoveSelectionAll}
        reduxTableKey="districtInventoryItems"
        refetchQueries={() => [
          getOperationName(GET_DISTRICT_INVENTORY_ITEMS_INDEX) as string,
        ]}
        singleItemId={singleItemDeleteId}
        size={data?.districtInventoryItemsIndex.fullCount ?? 0}
        withNote
      />
    </>
  );
};

export default AdminInventoryTable;
