// External Dependencies
import { Box, Grid } from '@mui/material';
import { FC } from 'react';
import {
  InventoryConditions,
  InventoryQualities,
} from '@presto-assistant/api_types';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import {
  CustomInput,
  CustomSelect,
} from 'components/shared';
import { mapEnum } from 'utils/lib/map_enum';

// Local Typings
interface Props {
  inputSize: 'small' | 'medium';
}

// Local Variables
const useStyles = makeStyles({
  input: {
    marginBottom: 16,
  },
});

const conditionData = mapEnum(InventoryConditions);
const qualityData = mapEnum(InventoryQualities);

// Component Definition
const InventoryStatusInfoFormFields: FC<Props> = ({
  inputSize,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
    >
      <Box my={1}>
        <CustomSelect
          label="Condition"
          name="conditionId"
          options={conditionData}
        />
      </Box>
      <CustomInput
        label="Current Value"
        name="currentValue"
        rootStyles={classes.input}
        size={inputSize}
        type="number"
      />
      <CustomInput
        label="Accessories"
        name="accessories"
        rootStyles={classes.input}
        size={inputSize}
      />
      <CustomSelect
        label="Quality"
        name="qualityId"
        options={qualityData}
      />
      <CustomInput
        label="Comments"
        multiline
        name="comments"
        rootStyles={classes.input}
        rows={2}
        size={inputSize}
      />
    </Grid>
  );
};

export default InventoryStatusInfoFormFields;
