// External Dependencies
import { Divider } from '@mui/material';
import { FC } from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { hasFeatureFlag } from 'state/self/selectors';
import IntegrationsCard from 'components/shared/IntegrationsCard';

// Local Dependencies
import GoogleCalendarSettings from './GoogleCalendarSettings';
import StripeSettings from './StripeSettings';

// Component Definition
const OrganizationIntegrationsCard: FC = () => {
  const hasOnlinePaymentsFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.OnlinePayments));

  return (
    <IntegrationsCard>
      <GoogleCalendarSettings />

      {hasOnlinePaymentsFeatureFlag && (
        <>
          <Divider />

          <StripeSettings />
        </>
      )}
    </IntegrationsCard>
  );
};

export default OrganizationIntegrationsCard;
