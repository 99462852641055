// External Dependencies
import { Chip, lighten } from '@mui/material';
import { FinancialPaymentTypes, StripeStatus } from '@presto-assistant/api_types';
import styled from 'styled-components';

// Internal Dependencies
import { PropsWithTheme } from 'types/styled';
import { darken } from '@mui/material/styles';
import { displayPaymentType } from 'utils/lib/displayPaymentType';

// Local Typings
interface StyledChipProps {
  stripePaymentStatusId: number | null;
}

// Local Variables
const getBackgroundColorFromStatus = ({
  stripePaymentStatusId,
  theme,
}: PropsWithTheme<StyledChipProps>) => {
  if (stripePaymentStatusId === StripeStatus.Succeeded) {
    return theme.palette.mode === 'dark'
      ? darken(theme.palette.success.light, 0.5)
      : lighten(theme.palette.success.light, 0.5);
  }

  if (stripePaymentStatusId === StripeStatus.Failed) {
    return theme.palette.mode === 'dark'
      ? darken(theme.palette.error.light, 0.5)
      : lighten(theme.palette.error.light, 0.5);
  }

  return undefined;
};

export const renderPaymentType = ({
  financialPaymentTypeId,
  financialPaymentTypeLabel,
  stripePaymentStatusId,
}: {
  financialPaymentTypeId: number;
  financialPaymentTypeLabel: string;
  stripePaymentStatusId: number | null;
}) => {
  const normalizedPaymentType = displayPaymentType(financialPaymentTypeLabel);

  if (financialPaymentTypeId !== FinancialPaymentTypes.Stripe) {
    return normalizedPaymentType;
  }

  return (
    <>
      {normalizedPaymentType}

      {stripePaymentStatusId ? (
        <StripeStatusChip
          stripePaymentStatusId={stripePaymentStatusId}
          label={StripeStatus[stripePaymentStatusId]}
          size="small"
          sx={{ ml: 1 }}
        />
      ) : null}
    </>
  );
};

// Component Definition
const StripeStatusChip = styled(Chip)((props: PropsWithTheme<StyledChipProps>) => ({
  backgroundColor: getBackgroundColorFromStatus(props),
  fontWeight: 500,
}));

export default StripeStatusChip;
