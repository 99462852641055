// External Dependencies
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { Subtitle } from 'components/shared';

// Local Dependencies
import MyFinancialCreditsTable from './MyFinancialCreditsTable';

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

// Component Definition
const MyFinancialCredits: FC = () => (
  <>
    <Subtitle>
      My Financial Credits
    </Subtitle>
    <Box
      mb={1.5}
      ml={2}
    >
      <StyledTypography
        color="textSecondary"
        variant="body2"
      >
        Credits will appear here when you overpay for fees.
      </StyledTypography>
    </Box>

    <MyFinancialCreditsTable />
  </>
);

export default MyFinancialCredits;
