// External Dependencies
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  PieLabelRenderProps,
} from 'recharts';
import { FC, useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';
import styled, { useTheme } from 'styled-components';

// Local Typings
interface Props {
  userRoleCounts: GQL.IUserRoleCount[];
}
interface ChartDatum {
  name: string;
  value: number;
}

// Local Variables
const RADIAN = Math.PI / 180;

const StyledTypography = styled(Typography)({
  verticalAlign: 'middle',
}) as typeof Typography;

// Component Definition
const UserMetrics: FC<Props> = ({
  userRoleCounts,
}) => {
  const {
    palette: {
      prestoSecondary,
      stripeBlue,
      text: {
        secondary: textSecondary,
      },
    },
  } = useTheme();

  const colors = [
    stripeBlue['400'],
    prestoSecondary,
  ];

  const chartData = useMemo(() => userRoleCounts.reduce((prev: ChartDatum[], curr) => [
    ...prev,
    {
      name: curr.role.label,
      value: curr.count,
    },
  ], []), [userRoleCounts]);

  const percentageOfTotal = useMemo(() => {
    if (chartData.length > 1) {
      const sortedChartData = [...chartData].sort((a, b) => {
        return a.value - b.value;
      });
      const percentage = sortedChartData[0].value / sortedChartData[1].value;
      return (percentage * 100);
    }
    return 0;
  }, [chartData]);

  const renderCustomizedLabel = useCallback(({
    cx,
    cy,
    midAngle,
    middleRadius,
    value,
  }: PieLabelRenderProps) => {
    // Increase distance from center of circle for text placement
    // note: Distance from center for bottom text is too close but looks fine
    // When a user group takes up less than 13% of the total,
    // distance added should only be 15, otherwise add 25 to avoid clipping

    const additionToDistance = (percentageOfTotal <= 13 ? 15 : 25);
    const distanceFromCenterOfCircle = middleRadius + additionToDistance;

    const x = Number(cx) + distanceFromCenterOfCircle * Math.cos(-midAngle! * RADIAN);
    const y = Number(cy) + distanceFromCenterOfCircle * Math.sin(-midAngle! * RADIAN);

    return (
      <text
        dominantBaseline="central"
        fill={textSecondary}
        textAnchor={x > middleRadius ? 'start' : 'end'}
        x={x}
        y={y}
      >
        {value}
      </text>
    );
  }, [textSecondary, percentageOfTotal]);

  const legendFormatter = (value: any) => (
    <StyledTypography
      color="textPrimary"
      component="span"
    >
      {value}
    </StyledTypography>
  );

  return (
    <PieChart
      height={240}
      width={240}
    >
      <Legend formatter={legendFormatter} />

      <Pie
        cornerRadius={2}
        data={chartData}
        dataKey="value"
        innerRadius={64}
        label={renderCustomizedLabel}
        labelLine={false}
        outerRadius={80}
        paddingAngle={4}
      >
        {chartData.map(({ name }, index) => (
          <Cell
            fill={colors[index % colors.length]}
            key={name}
          />
        ))}
      </Pie>
    </PieChart>
  );
};

export default UserMetrics;
