// External Dependencies
import { navigate } from '@reach/router';

// Local Dependencies
import { stringifyQueryParams } from './stringify_query_params';
import parseSearch from './parse_search';

export const navigateSearch = (searchOverrides: object) => {
  const { pathname, search } = window.location;

  const parsedSearch = parseSearch(search);

  const newSearch = stringifyQueryParams({
    ...parsedSearch,
    ...searchOverrides,
  });

  navigate(`${pathname}?${newSearch}`);
};
