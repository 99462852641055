// External Dependencies
import {
  Button,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import {
  Ref, forwardRef, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedDialog, SlideUpTransition } from 'components/shared';
import { getPaymentIds } from 'state/ui/paymentPickerDepositForm/selectors';
import { updatePaymentIds } from 'state/ui/paymentPickerDepositForm/actions';
import PaymentPickerTable from 'components/shared/PaymentPickerTable';

// Local Typings
interface Props {
  isLoading?: boolean;
  isOpen: boolean;
  isShow?: boolean;
  onClose: () => void;
  paymentsData: GQL.IFinancialPaymentIndexItem[] | GQL.IFinancialPayment[] | undefined;
  title: string;
}

// Local Variables
const StyledEnhancedDialog = styled(EnhancedDialog)(({ theme }) => ({
  '.dialogTitle': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.paper': {
    [theme.breakpoints.down('lg')]: {
      maxWidth: '97%',
    },
    borderRadius: theme.spacing(2, 2, 0, 0),
    bottom: 0,
    height: 878, // perfect height for page size 10 with filter row
    margin: theme.spacing(0, 1.5),
    maxHeight: '95%',
    maxWidth: '90%',
    position: 'absolute',
  },
}));

// Component Definition
const DialogPaymentPicker = forwardRef((
  props: Props,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    isLoading,
    isOpen,
    isShow,
    onClose,
    paymentsData,
    title,
  } = props;

  const dispatch = useDispatch();

  const paymentIds = useSelector(getPaymentIds);

  // We track the ids for selected rows in local state to send
  //  upstream when the user presses the dialog's submit button
  const [
    dialogPaymentIds,
    setDialogPaymentIds,
  ] = useState<GridSelectionModel>(paymentIds);

  useEffect(() => {
    if (isOpen) {
      setDialogPaymentIds(paymentIds as GridSelectionModel);
    }
  }, [isOpen, paymentIds]);

  const handleSetPaymentIds = (updatedPaymentIds: GridSelectionModel) => {
    setDialogPaymentIds(updatedPaymentIds);
  };

  const handleAddIds = () => {
    dispatch(updatePaymentIds(dialogPaymentIds as string[]));
    onClose();
  };

  return (
    <StyledEnhancedDialog
      TransitionComponent={SlideUpTransition}
      classes={{
        paper: 'paper',
      }}
      fullScreen
      maxWidth={false}
      onClose={onClose}
      open={isOpen}
      ref={ref}
    >
      <DialogTitle className="dialogTitle">
        <Typography
          component="span"
          variant="h6"
        >
          {title}
        </Typography>

        <IconButton
          aria-label={`Close ${title} dialog`}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <PaymentPickerTable
        dialogPaymentIds={dialogPaymentIds}
        isLoading={isLoading}
        isShow={isShow}
        onSetPaymentIds={handleSetPaymentIds}
        paymentsData={paymentsData}
      />

      <DialogActions>
        {!isShow ? (
          <Button
            color="primary"
            onClick={onClose}
          >
            Discard
          </Button>
        ) : null}

        <Button
          color="primary"
          onClick={isShow ? onClose : handleAddIds}
          variant="contained"
        >
          {isShow ? 'Ok' : 'Select Payments'}
        </Button>
      </DialogActions>
    </StyledEnhancedDialog>
  );
});

export default DialogPaymentPicker;
