// External Dependencies
import { LazyQueryResult, gql } from '@apollo/client';
import { useEffect } from 'react';

// Internal Dependencies
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import {
  useLazyQueryEnhanced,
  useQueryEnhanced,
} from 'utils/lib/graphql';
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';

// Local Typings
interface GroupsUserItem {
  email: GQL.IUser['email'];
  firstName: GQL.IUser['firstName'];
  id: GQL.IUser['id'];
  lastName: GQL.IUser['lastName'];
}

interface GroupsIndexResponse {
  groupsIndex: GQL.IGroupsIndexAll;
}
interface GroupsIndexVariables {
  queryParams?: GQL.IIndexQueryParams;
}
export interface GroupsUsersResponse {
  groupsUsers: GroupsUserItem[];
}
export interface SimpleGroup {
  id: string;
  label: string;
  userCount: number;
}
interface GroupsAllResponse {
  groups: {
    data: SimpleGroup[];
  };
}
interface GroupUsersResponse {
  groupUsers: GQL.IGroupUsersAll;
}
interface GroupUsersVariables {
  groupId: string;
  queryParams?: GQL.IIndexQueryParams;
}
export interface GroupResponseData {
  group: GQL.IGroup;
}

export const GET_GROUPS = gql`
  query Groups($queryParams: QueryParams) {
    groups(queryParams: $queryParams) {
      fullCount
      data {
        id
        label
        userCount
      }
    }
  }
`;

const GET_GROUPS_ALL = gql`
  query GroupsAll {
    groups(queryParams: {
      orderBy: "label"
    }) {
      data {
        id
        label
        userCount
      }
    }
  }
`;

export const GET_GROUP = gql`
  query Group($id: ID!) {
    group(id: $id) {
      id
      label
      organization {
        label
      }
      users {
        userList {
          id
        }
      }
    }
  }
`;

export const GET_GROUPS_INDEX = gql`
  query GroupsIndex(
    $queryParams: IndexQueryParams
  ) {
    groupsIndex(
      queryParams: $queryParams
    ) {
      data {
        id
        label
        memberCount
      }
      fullCount
    }
  }
`;

export const GET_GROUPS_INDEX_REPORT = gql`
  query GroupsIndexReport(
    $queryParams: IndexQueryParams
  ) {
    groupsIndex(
      queryParams: $queryParams
    ) {
      fullCount
    }
  }
`;

export const GET_GROUPS_USERS = gql`
  query GroupsUsers($selection: Selection!) {
    groupsUsers(selection: $selection) {
      email
      id
      firstName
      lastName
    }
  }
`;

export const GET_GROUP_USERS = gql`
  query GroupUsers(
    $groupId: ID!
    $queryParams: IndexQueryParams
  ) {
    groupUsers(
      groupId: $groupId
      queryParams: $queryParams
    ) {
      data {
        email
        id
        firstName
        lastName
        role {
          id
          label
        }
      }
      fullCount
    }
  }
`;

export const useGetGroupsIndexItemsQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<GroupsIndexResponse, GroupsIndexVariables>
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<GroupsIndexResponse, GroupsIndexVariables>({
    gqlQuery,
    options,
    tableResource: 'groups',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetGroupUsersIndexItemsQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
  groupId: string,
): [
    (
    ) => void,
    LazyQueryResult<GroupUsersResponse, GroupUsersVariables>
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<GroupUsersResponse, GroupUsersVariables>({
    getAdditionalVariables: () => ({ groupId }),
    gqlQuery,
    options,
    tableResource: 'group',
  });

  return [
    apiRequest,
    values,
  ];
};

// Deprecated, but used by EOY Groups Table
export const useGetGroupsIndexAll = () => {
  const [query, values] = useGetGroupsIndexItemsQuery(
    GET_GROUPS_INDEX,
    { exportReport: false },
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useGetGroupsIndex = () => usePaginatedListQuery<
  GroupsIndexResponse,
  GQL.IGroupIndexItem
  >({
    dataSelector: (data) => data.groupsIndex.data,
    fullCountSelector: (data) => data.groupsIndex.fullCount,
    query: GET_GROUPS_INDEX,
  });

export const useDownloadGroupsIndexItems = () =>
  useGetGroupsIndexItemsQuery(
    GET_GROUPS_INDEX_REPORT,
    { exportReport: true },
  );

export const useGetGroupsUsers = (onCompleted: (data: GroupsUsersResponse) => void) =>
  useLazyQueryEnhanced<GroupsUsersResponse, GQL.IGroupsUsersOnQueryArguments>(
    GET_GROUPS_USERS,
    {
      onCompleted,
    },
  );

export const useGetGroupsAll = () =>
  useQueryEnhanced<GroupsAllResponse>(GET_GROUPS_ALL);

export const useGetGroupUsers = (groupId: string) => {
  const [query, values] = useGetGroupUsersIndexItemsQuery(
    GET_GROUP_USERS,
    { exportReport: false },
    groupId,
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useGetGroup = (
  id?: string,
  onCompleted?: (data: GroupResponseData) => void,
) =>
  useQueryEnhanced<GroupResponseData>(
    GET_GROUP,
    {
      onCompleted,
      skip: !id,
      variables: {
        id,
      },
    },
  );
