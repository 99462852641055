// External Dependencies
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';
import { MutationFunction } from '@apollo/client';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import Form from 'components/shared/Form';
import Input from 'components/shared/Input';
import SaveButton from 'components/shared/SaveButton';
import useTextField from 'hooks/useTextField';

// Local Typings
interface Props {
  mutation: MutationFunction;
  onClose: () => void;
}

// Local Variables
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  '&:first-child': {
    paddingTop: theme.spacing(1),
  },
}));

// Component Definition
const InvitationForm: FC<Props> = ({
  mutation,
  onClose,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const emailField = useTextField('');

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await mutation({
        variables: { email: emailField.value },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <DialogTitle>Invite Director</DialogTitle>
      <StyledDialogContent>
        <DialogContentText>
          Invite a director to create a {APP_NAME} organization.
          This new organization will be in your {APP_NAME} district
          if the director accepts the invitation.
        </DialogContentText>
        <Input
          autoFocus
          disabled={isSubmitting}
          fullWidth
          helperText="This is the head of the organization"
          id="director-email"
          label="Director Email"
          type="email"
          variant="filled"
          {...emailField}
        />
      </StyledDialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <SaveButton isSaving={isSubmitting}>Invite</SaveButton>
      </DialogActions>
    </Form>
  );
};

export default InvitationForm;
