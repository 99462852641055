// External Dependencies
import {
  Box,
  Button,
  CircularProgress,
  Paper,
} from '@mui/material';
import { FC, KeyboardEvent, useState } from 'react';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomFormButton,
  EnhancedAlert,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';

// Local Dependencies
import FinishLaterConfirmDialog from './FinishLaterConfirmDialog';

// Local Typings
interface Props {
  error?: string;
  isSubmitting: boolean;
  onPressNextButton?: () => void;
}
interface StyledPaperProps {
  $isSubmitting: boolean;
}

// Local Variables
const StyledPaper = styled(Paper)<StyledPaperProps>(({
  $isSubmitting,
  theme,
}) => ({
  '& .MuiCircularProgress-root': {
    width: '25%',
  },

  '& .cancelButton': {
    marginRight: theme.spacing(1.5),
  },

  '& .progressContainer': {
    [theme.breakpoints.down('md')]: {
    },
    paddingRight: theme.spacing(3),
  },

  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: $isSubmitting ? theme.spacing(2) : 'initial',
}));

const handlePressFinishLaterButton = () => {
  navigate(PATHS.SETTINGS);
};

// Component Definition
const EndOfYearActionButtons: FC<Props> = ({
  error,
  isSubmitting,
  onPressNextButton,
}) => {
  const [isFinishLaterConfirmDialogOpen, setIsFinishLaterConfirmDialogOpen] = useState(false);

  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const handleToggleFinishLaterConfirmDialog = () => {
    setIsFinishLaterConfirmDialogOpen(!isFinishLaterConfirmDialogOpen);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (['Enter', ' '].includes(event.key)) {
      event.stopPropagation();
    }
  };

  return (
    <>
      <StyledPaper
        $isSubmitting={isSubmitting}
        variant="outlined"
      >
        {isSubmitting ? (
          <div className="progressContainer">
            <CircularProgress
              size={28}
              thickness={4}
            />
          </div>
        ) : (
          <>
            <Button
              className="cancelButton"
              color="primary"
              onClick={handleToggleFinishLaterConfirmDialog}
              onKeyDown={handleKeyDown}
              size={isMobileOrTabletScreen ? 'small' : 'large'}
            >
              Finish Later
            </Button>

            <CustomFormButton
              buttonText="Next"
              onClick={onPressNextButton}
              onKeyDown={handleKeyDown}
              disabled={Boolean(isSubmitting || error)}
              size={isMobileOrTabletScreen ? 'small' : 'large'}
            />
          </>
        )}
      </StyledPaper>

      {error && (
        <Box mt={2}>
          <EnhancedAlert severity="error">
            {error}
          </EnhancedAlert>
        </Box>
      )}

      <FinishLaterConfirmDialog
        isOpen={isFinishLaterConfirmDialogOpen}
        onConfirm={handlePressFinishLaterButton}
        onRequestClose={handleToggleFinishLaterConfirmDialog}
      />
    </>
  );
};

export default EndOfYearActionButtons;
