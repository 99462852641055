// External Dependencies
import {
  Box,
  Typography,
} from '@mui/material';
import {
  FC, useCallback, useMemo,
} from 'react';
import { navigate } from '@reach/router';
import KeyIcon from 'mdi-material-ui/Key';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { ShowCard } from 'components/shared';
import {
  enhancedValuesFromObject,
} from 'utils';
import useCanEditPermissions from 'hooks/useCanEditPermissions';
import useIsSelf from 'hooks/useIsSelf';

// Local Dependencies
import { useIsOpen } from 'hooks/useIsOpen';
import EditPermissionsDialog from 'pages/People/Directors/Show/EditPermissionsDialog';
import PermissionDataDisplay from './PermissionDataDisplay';

// Local Typings
interface Props {
  adultId: string;
  permissions: Exclude<GQL.IPermissions, '__typename'>;
}

// Component Definition
const PermissionsCard: FC<Props> = ({
  adultId,
  permissions,
}) => {
  const canEditPermissions = useCanEditPermissions();

  const isSelf = useIsSelf(adultId);

  const { handleClose, handleOpen, isOpen } = useIsOpen();

  const hasPermissions = useMemo(() => Object.entries(permissions)
    .some((entry) =>
      enhancedValuesFromObject<GQL.IPermissionDetails>()(entry[1])
        .some((permissionDetails: GQL.IPermissionDetails) =>
          permissionDetails.allowed)), [permissions]);

  const handleClickEditIcon = useCallback(() => {
    if (!isSelf && canEditPermissions) {
      navigate(`${adultId}/edit/${PATHS.PERMISSIONS}`);
    } else {
      handleOpen();
    }
  }, [adultId, isSelf, canEditPermissions, handleOpen]);

  return (
    <Box mb={2}>
      <ShowCard
        canEdit
        icon={KeyIcon}
        onClickEditIconButton={handleClickEditIcon}
        readOnly
        title="Permissions"
      >
        {hasPermissions
          ? (
            <>
              <PermissionDataDisplay
                permission="emailMembers"
                permissions={permissions}
              />
              <PermissionDataDisplay
                label="File Uploads"
                permission="s3Uploads"
                permissions={permissions}
              />
              <PermissionDataDisplay
                permission="finances"
                permissions={permissions}
              />
              <PermissionDataDisplay
                permission="financialAccounts"
                permissions={permissions}
              />
              <PermissionDataDisplay
                permission="groups"
                permissions={permissions}
              />
              <PermissionDataDisplay
                actions={['write', 'read', 'delete']}
                label="Inventory (All Fields)"
                permission="inventory"
                permissions={permissions}
              />
              <Box marginLeft={2}>
                <PermissionDataDisplay
                  actions={['edit']}
                  label="Inventory Basic Info"
                  permission="inventory"
                  permissions={permissions}
                />
                <PermissionDataDisplay
                  actions={['edit']}
                  permission="inventoryStatusInfo"
                  permissions={permissions}
                />
                <PermissionDataDisplay
                  actions={['edit']}
                  permission="inventoryLocationInfo"
                  permissions={permissions}
                />
                <PermissionDataDisplay
                  actions={['edit']}
                  permission="inventoryPurchaseInfo"
                  permissions={permissions}
                />
                <PermissionDataDisplay
                  actions={['edit']}
                  permission="inventoryDynamicFields"
                  permissions={permissions}
                />
              </Box>
              <PermissionDataDisplay
                permission="library"
                permissions={permissions}
              />
              <PermissionDataDisplay
                permission="payments"
                permissions={permissions}
              />
              <PermissionDataDisplay
                permission="uniformCheckouts"
                permissions={permissions}
              />
              <PermissionDataDisplay
                permission="uniforms"
                permissions={permissions}
              />
              <PermissionDataDisplay
                permission="users"
                permissions={permissions}
              />
            </>
          ) : (
            <Typography color="textSecondary">No permissions</Typography>
          )}
      </ShowCard>
      <EditPermissionsDialog
        onClose={handleClose}
        openPermissionDialog={isOpen}
      />
    </Box>
  );
};

export default PermissionsCard;
