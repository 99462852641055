// External Dependencies
import { FC, useMemo } from 'react';
import { FieldArray } from 'formik';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableRow,
} from '@mui/material';

// Internal Dependencies
import {
  StyledFooterCell, StyledTableCell, StyledTableHead, StyledTotalCell,
} from 'pages/Finances/FinancialPayments/shared/styles';
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils';

// Local Dependencies
import PaymentTableRow, { RowValues } from './PaymentTableRow';

// Local Typings
export interface PaymentsFormValues {
  payments: RowValues[];
}
interface Props {
  financialFees: GQL.IFinancialFeeIndexItem[];
  formValues: PaymentsFormValues;
  hasMixedFees: boolean;
}

// Local Variables
const baseWidth = 100;
const columnWidths = [
  baseWidth * 2,
  baseWidth * 2,
  baseWidth * 1.5,
  baseWidth * 2,
];
const totalWidth = columnWidths.reduce((prev, curr) => prev + curr, 0);

// Component Definition
const PaymentTable: FC<Props> = ({
  financialFees,
  formValues,
  hasMixedFees,
}) => {
  const totalRow = useMemo(() => {
    const totalAmountInDollars = formValues.payments
      .reduce((prev, curr) => prev + Number(curr.amountInDollars), 0);
    const balanceTotalInCents = financialFees
      .reduce((prev, curr) => prev + curr.balanceDueInCents, 0);

    return (
      <TableRow>
        <StyledFooterCell />
        <StyledFooterCell />

        {hasMixedFees && <StyledFooterCell />}

        <StyledFooterCell align="right">
          {displayPriceStringFromDollarAmount(convertCentsToDollars(balanceTotalInCents))}
        </StyledFooterCell>
        <StyledTotalCell>
          {displayPriceStringFromDollarAmount(totalAmountInDollars)}
        </StyledTotalCell>
      </TableRow>
    );
  }, [financialFees, formValues, hasMixedFees]);

  const tableBody = useMemo(() => (
    <FieldArray name="payments">
      {() => (
        <TableBody>
          {formValues.payments.map((formValue, rowIndex) => (
            <PaymentTableRow
              amountInDollars={formValue.amountInDollars}
              balanceDueInCents={formValue.balanceDueInCents}
              financialFeeId={formValue.financialFeeId}
              hasMixedFees={hasMixedFees}
              isOrganizationCoveringStripeFee={formValue.isOrganizationCoveringStripeFee}
              itemLabel={formValue.itemLabel}
              // eslint-disable-next-line react/no-array-index-key
              key={`${formValue.financialFeeId}-${rowIndex}`}
              rowIndex={rowIndex}
              userName={formValue.userName}
            />
          ))}
        </TableBody>
      )}
    </FieldArray>
  ), [formValues.payments, hasMixedFees]);

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table
          aria-label="Payments table"
          size="small"
          style={{ minWidth: totalWidth }}
        >
          <StyledTableHead>
            <TableRow>
              <StyledTableCell width={columnWidths[0]}>
                Member
              </StyledTableCell>

              <StyledTableCell width={columnWidths[1]}>
                Fee
              </StyledTableCell>

              {hasMixedFees && (
                <StyledTableCell width={columnWidths[1]}>
                  Processing Fee
                </StyledTableCell>
              )}

              <StyledTableCell
                align="right"
                width={columnWidths[2]}
              >
                Balance Remaining
              </StyledTableCell>

              <StyledTableCell width={columnWidths[3]}>
                Payment Amount
              </StyledTableCell>
            </TableRow>
          </StyledTableHead>

          {tableBody}

          <TableFooter>
            {totalRow}
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PaymentTable;
