// External Dependencies
import { FC } from 'react';
import { MutationFunction } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// Internal Dependencies
import { CREATE_DISTRICT_REQUEST } from 'gql/mutations';
import { GET_DISTRICT_REQUESTS } from 'gql/queries';
import { useMutationEnhanced, useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
interface Props {
  organizations: GQL.IOrganization[];
}

// Local Variables
const useStyles = makeStyles({
  button: {
    marginLeft: 12,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

// Local Functions
const handleSendRequest = (mutation: MutationFunction, organizationId: string) => () => {
  mutation({
    variables: { organizationId },
  });
};

// Component Definition
const ResultsList: FC<Props> = ({
  organizations,
}) => {
  const classes = useStyles();

  const {
    data,
    error,
    loading,
  } = useQueryEnhanced<{ districtRequests: GQL.IDistrictRequest[] }>(GET_DISTRICT_REQUESTS);

  const [mutation] = useMutationEnhanced(CREATE_DISTRICT_REQUEST, {
    refetchQueries: [{ query: GET_DISTRICT_REQUESTS }],
  });

  if (error || loading) {
    return null;
  }

  return (
    <>
      <Divider />
      <List>
        {organizations.map((org) => {
          const request = data?.districtRequests.find((req) => req.orgId === `${org.id}`);
          const isRequested = !!request;

          return (
            <ListItem
              className={classes.listItem}
              key={org.id}
            >
              <ListItemText
                primary={org.label}
                secondary={org?.creator?.email ?? 'No director found'}
              />
              <Button
                className={classes.button}
                color="primary"
                disabled={isRequested}
                onClick={handleSendRequest(mutation, org.id)}
                variant="outlined"
              >
                {isRequested ? 'Request Sent' : 'Send Request'}
              </Button>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default ResultsList;
