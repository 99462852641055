import { Autocomplete } from '@mui/material';
import { FC } from 'react';
import { useField } from 'formik';

// Internal Dependencies
import { Input } from 'components/shared';

// Local Typings
interface Props {
  disableClearable?: boolean;
  freeSolo?: boolean;
  fullWidth?: boolean;
  label: string;
  name: string;
  options: string[];
  readOnly?: boolean;
}

// Component Definition
const CustomAutoComplete: FC<Props> = ({
  disableClearable,
  freeSolo,
  fullWidth,
  label,
  name,
  options,
  readOnly = false,
}) => {
  const [field, meta] = useField(name);

  const handleChange = (evt: any, value: string | null = '') => {
    field.onChange({
      ...evt,
      target: {
        ...evt.target,
        name,
        value,
      },
    });
  };

  return (
    <Autocomplete
      disablePortal
      disableClearable={disableClearable}
      freeSolo={freeSolo}
      fullWidth={fullWidth}
      id={`${name}-auto-complete`}
      onChange={handleChange}
      options={readOnly ? [] : options}
      renderInput={(params) => (
        <Input
          {...params}
          InputProps={{
            ...(params.InputProps ?? {}),
            readOnly,
          }}
          error={!!meta.error}
          helperText={meta.error}
          onChange={field.onChange}
          label={label}
          name={name}
          variant="filled"
          value={meta.value}
        />
      )}
      value={meta.value}
    />
  );
};

export default CustomAutoComplete;
