// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';
import {
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { UserRoles } from '@presto-assistant/api_types';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CellphoneWirelessIcon from 'mdi-material-ui/CellphoneWireless';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import {
  Container, DynamicFormFields,
  FormRouteActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import {
  hasPermission,
} from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetSelf } from 'gql/queries';
import ContactInfoFields from 'pages/People/shared/ContactInfoFields';
import ProfileInfoFields from 'pages/People/shared/ProfileInfoFields';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import PeopleDangerZone from './PeopleDangerZone';

// Local Typings
interface Props {
  initialValues?: Partial<GQL.ICreateUserInput>;
  isDirector?: boolean;
  onSubmit?: (values: GQL.ICreateUserInput) => Promise<void>;
  returnToOnRemoveUser?: string;
  user?: GQL.IUser;
  validationSchema?: any;
}

// Component Definition
const AdultForm: FC<Props> = ({
  initialValues = {},
  isDirector = false,
  onSubmit,
  returnToOnRemoveUser,
  user,
  validationSchema,
}) => {
  const userId = user?.id;
  const userOrganizationId = user?.userOrgData?.id;

  const { data: selfData } = useGetSelf();

  const isSelf = selfData?.self?.id === userId;

  const defaultInitialValues = {
    addressOne: '',
    addressTwo: '',
    admin: isDirector,
    allergies: '',
    city: '',
    dateOfBirth: '',
    email: '',
    firstName: '',
    genderId: '',
    lastName: '',
    phoneNumber: '',
    primarySpokenLanguageId: 1,
    roleId: UserRoles.Adult,
    shirtSize: '',
    stateId: '',
    title: '',
    zipcode: '',
  };

  const canUseDynamicFields = useCanUseDynamicFields();
  const directorsParams = useSelector(tableQueryParams('directors'));
  const parentsParams = useSelector(tableQueryParams('parents'));

  const canEditUsers = useSelector(hasPermission('users', 'edit'));

  const directorsTablePath = `/${PATHS.DIRECTORS}${directorsParams}`;
  const parentsTablePath = `/${PATHS.PARENTS}${parentsParams}`;

  const isEditing = Boolean(user);

  const handlePressCancelOrBackButton = () => {
    navigate(isDirector ? directorsTablePath : parentsTablePath);
  };

  const handleFormikSubmit = async (
    values: GQL.ICreateUserInput,
    formikProps: FormikHelpers<GQL.ICreateUserInput>,
  ) => {
    const { setSubmitting } = formikProps;

    await onSubmit?.(values);

    setSubmitting(false);
  };

  return (
    <Container maxWidth={CONTAINER_WIDTH}>
      <Formik<GQL.ICreateUserInput>
        initialValues={{
          ...((canUseDynamicFields && canEditUsers) ? applyDynamicFields(undefined) : {}),
          ...defaultInitialValues,
          ...initialValues,
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          touched,
        }) => {
          const isFormTouched = Object.keys(touched).length > 0;

          return (
            <Form onSubmit={handleSubmit}>
              <Box mb={2}>
                <ShowCard
                  icon={AccountCircleIcon}
                  title="Profile Info"
                >
                  <ProfileInfoFields isDirector={isDirector} />
                </ShowCard>
              </Box>

              <Box mb={2}>
                <ShowCard
                  icon={CellphoneWirelessIcon}
                  title="Contact Info"
                >
                  <ContactInfoFields />
                </ShowCard>
              </Box>

              {canEditUsers && (
                <DynamicFormFields
                  isAdmin={false}
                  item={user ?? undefined}
                  organizationTypeId={undefined}
                  showCardProps={{
                    canEdit: isEditing,
                    readOnly: false,
                  }}
                  tableRef="members"
                />
              )}

              <FormRouteActions
                context={isDirector ? 'Director' : 'Parent'}
                isEditing={isEditing}
                isFormTouched={isFormTouched}
                isSubmitting={isSubmitting}
                onPressCancelOrBackButton={handlePressCancelOrBackButton}
              />
            </Form>
          );
        }}
      </Formik>

      {userOrganizationId
        && returnToOnRemoveUser
        && !isSelf
        && (
          <PeopleDangerZone
            memberId={userId}
            memberRoleId={!isDirector ? UserRoles.Adult : undefined}
            organizationMembershipId={userOrganizationId}
            returnToOnSuccess={returnToOnRemoveUser}
          />
        )}
    </Container>
  );
};

export default AdultForm;
