// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  DistrictCard,
  Flex,
  PurchaseALicenseCard,
} from 'components/shared';
import { StyledDashboardDiv } from 'components/shared/Dashboard/styles';
import {
  useGetDistrictName,
  useGetLicensesCount,
} from 'gql/queries';

// Local Dependencies
import { CircularProgress } from '@mui/material';
import InvitationsToDfaNotification from './InvitationsToDfaNotification';

// Component Definition
const AdminDashboard: FC = () => {
  const { data, error, loading } = useGetDistrictName();

  const {
    data: licenseCountData,
    error: licenseCountError,
    loading: licenseCountLoading,
  } = useGetLicensesCount();

  const isLoading = loading || licenseCountLoading;

  if (isLoading) {
    return (
      <Flex justifyContent="center">
        <CircularProgress
          size={28}
          sx={{ m: 2 }}
        />
      </Flex>
    );
  }

  if (error || licenseCountError) {
    return null;
  }

  const hasAtLeastOneLicense = licenseCountData && licenseCountData?.licenses.fullCount > 0;

  return (
    <>
      <DistrictCard districtName={data?.district?.label} />

      <StyledDashboardDiv>
        <PurchaseALicenseCard
          hasLicense={hasAtLeastOneLicense}
          isDistrictAdmin
        />
        <InvitationsToDfaNotification />
      </StyledDashboardDiv>
    </>
  );
};

export default AdminDashboard;
