// External Dependencies
import {
  CircularProgress,
  InputBaseComponentProps,
} from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { CustomSelect } from 'components/shared';
import { useGetUniformStyles } from 'gql/queries/uniform-style-queries';

// Local Typings
interface Props {
  inputProps?: InputBaseComponentProps;
  orgTypeId: string;
}

/*
*
* This select should be used in a Formik context
*
*/

// Component Definition
const UniformStyleSelect: FC<Props> = ({
  inputProps,
  orgTypeId,
}) => {
  const {
    data,
    error,
    loading,
  } = useGetUniformStyles(orgTypeId);

  if (loading) {
    return (
      <CircularProgress
        size={24}
        sx={{ mb: 3, mt: 3 }}
        thickness={4}
      />
    );
  }

  if (!data) {
    return null;
  }

  if (error) {
    console.log('There was an error loading uniform style data');
  }

  return (
    <CustomSelect
      inputProps={inputProps}
      label="Style"
      name="uniformStyleId"
      options={data?.uniformStyles}
      required={false}
    />
  );
};

export default UniformStyleSelect;
