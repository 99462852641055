// External Dependencies
import { FC } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { UserRoles } from '@presto-assistant/api_types';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { useGetMemberWithRelatives } from 'gql/queries';

// Local Typings
interface RouteProps {
  id: string;
}

// Component Definition
const MembersEdit: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const { data } = useGetMemberWithRelatives(id);

  const role = data?.user.role;
  const userId = data?.user.id;

  if (userId && role) {
    // We don't have a good way to determine if the member is a director right now
    // We should not use this page to navigate for directors yet
    const basePath = role.id === UserRoles.Student.toString()
      ? PATHS.STUDENTS
      : PATHS.PARENTS;

    navigate(`/${basePath}/${userId}/edit`, { replace: true });
  }

  return (
    <Page isLoading>
      {/* Page requires children */}
      <div />
    </Page>
  );
};

export default MembersEdit;
