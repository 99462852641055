// External Dependencies
import {
  Box,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styled, { useTheme } from 'styled-components';

// Local Typings
interface Props {
  action?: ReactNode;
  children: any;
  isAlertOpen?: boolean;
  onClose?: () => void;
}

// Local Variables
const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mainContentBackground,
}));

const StyledCollapse = styled(Collapse)({
  zIndex: 2,
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  fontWeight: 500,
  marginLeft: theme.spacing(2),
  maxWidth: '65%',
}));

const StyledSection = styled.section(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

// Component Definition
const EnhancedBanner: FC<Props> = ({
  action,
  children,
  isAlertOpen,
  onClose,
}) => {
  const theme = useTheme();

  const actionElements = [action];

  const closeActionElement = (
    <StyledIconButton
      aria-label="close"
      key="close-button"
      onClick={onClose}
      size="small"
    >
      <CloseIcon fontSize="small" />
    </StyledIconButton>
  );

  if (onClose) {
    actionElements.push(closeActionElement);
  }

  const bannerElement = (
    <StyledBox
      py={0.5}
      zIndex={2}
    >
      <StyledSection>
        <Typography
          component="p"
          variant="subtitle1"
        >
          {children}
        </Typography>
        <Box display="flex">{actionElements}</Box>
      </StyledSection>
    </StyledBox>
  );

  return onClose
    ? (
      <StyledCollapse
        in={isAlertOpen}
        timeout={theme.transitions.duration.shortest}
      >
        {bannerElement}
      </StyledCollapse>
    ) : bannerElement;
};

export default EnhancedBanner;
