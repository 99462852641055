// External Dependencies
import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button, { ButtonProps } from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import styled from 'styled-components';

// Internal Dependencies
import Flex from 'components/shared/Flex';

// Local Typings
interface Props {
  buttonText: string;
  buttonVariant: ButtonProps['variant'];
  divider?: boolean;
  onClickCTA: () => void;
  primary: string;
  secondary: string | JSX.Element;
}

// Local Variables
const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '.MuiListItemText-primary': {
    fontSize: '1.1rem',
  },
  '.MuiListItemText-secondary': {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

// Component Definition
const MemberInfoFormListItem: FC<Props> = ({
  buttonText,
  buttonVariant,
  divider,
  onClickCTA,
  primary,
  secondary,
}) => {
  return (
    <ListItem
      divider={divider}
      sx={{ flexDirection: 'column', paddingBottom: 2.5 }}
    >
      <StyledListItemText
        primary={primary}
        secondary={secondary}
      />

      <Flex
        justifyContent="flex-end"
        width="100%"
      >
        <Button
          color="primary"
          onClick={onClickCTA}
          startIcon={<AddIcon />}
          variant={buttonVariant}
        >
          {buttonText}
        </Button>
      </Flex>
    </ListItem>
  );
};
export default MemberInfoFormListItem;
