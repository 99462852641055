// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';
import cloneDeep from 'lodash.clonedeep';

// Internal Dependencies
import { LocalStorageItem, getLocalStorageItem } from 'utils/lib/local_storage';
import { getEndingSchoolYear } from 'utils';

// Local Dependencies
import {
  TableResource,
  TableSelection,
  updateIsPaginatedListDataLoaded,
  updateTableQueryParams,
  updateTableSelections,
} from './actions';

// Local Typings
interface Action<T> {
  payload: Partial<T>;
  type: string;
}
export interface TableState {
  isPaginatedListDataLoaded: boolean;
  tableParams: Record<TableResource, string | undefined>;
  tableSelections: Record<TableResource, TableSelection | undefined>;
}

// Local Variables
const initialSelection: TableSelection = {
  ids: [],
  selectionType: 'SelectedOne',
};

const getInitialPageSize = (localStorageItem: LocalStorageItem) => {
  const pageSize = getLocalStorageItem(localStorageItem);

  return pageSize ? parseInt(pageSize, 10) : 10;
};

const studentRoleDefaultFilter = `?${encodeURI('dataGridFilters={"items":[{"columnField":"roleLabel","operatorValue":"is","id":66281,"value":"Student"}],"linkOperator":"and"}')}`;
const paymentsDefaultFilter = `${encodeURI('dataGridFilters={"items":[{"columnField":"isMemberActive","operatorValue":"is","id":45403,"value":"true"}],"linkOperator":"and"}')}`;

// Reducers
const schoolYearEnding = getEndingSchoolYear();

const TABLE_PARAMS_INITIAL_STATE: TableState = {
  isPaginatedListDataLoaded: false,
  tableParams: {
    checklist: '',
    checklists: '',
    directorPermissions: '',
    directors: `?asc=true&orderBy=firstName&limit=${getInitialPageSize('directorsPageSize')}&page=1`,
    districtAssistants: '?asc=true&orderBy=lastName',
    districtDeletedInventoryItems: `?asc=true&orderBy=label&limit=${getInitialPageSize('districtDeletedInventoryItemsPageSize')}&page=1`,
    districtInventoryItems: `?asc=true&orderBy=label&limit=${getInitialPageSize('districtInventoryItemsPageSize')}&page=1`,
    districtLibraryItems: `?asc=true&orderBy=title&limit=${getInitialPageSize('districtLibraryItemsPageSize')}&page=1`,
    districtLicenses: '',
    districtOrganizations: `?asc=true&orderBy=label&limit=${getInitialPageSize('districtOrganizationsPageSize')}&page=1`,
    districtOrganizationsDirectors: `?asc=true&orderBy=firstName&limit=${getInitialPageSize('districtOrganizationsDirectorsPageSize')}&page=1`,
    districtOrganizationsParentsWithPermissions: `?asc=true&orderBy=firstName&limit=${getInitialPageSize('districtOrganizationsParentsWithPermissionsPageSize')}&page=1`,
    districtUniforms: `?asc=true&orderBy=number&limit=${getInitialPageSize('districtUniformsPageSize')}&page=1`,
    dynamicFields: '?asc=true&orderBy=label',
    emailDrafts: '',
    emails: `?dataGridSort=${encodeURIComponent(JSON.stringify([{ field: 'sentAt', sort: 'desc' }]))}`,
    fileDirectories: `?asc=true&orderBy=directory&limit=${getInitialPageSize('fileDirectoriesPageSize')}&page=1`,
    files: `?asc=false&orderBy=createdAt&limit=${getInitialPageSize('filesPageSize')}&page=1`,
    financialCredits: `?asc=false&orderBy=createdAt&limit=${getInitialPageSize('financialCreditsPageSize')}&page=1`,
    financialDeposits: `?asc=false&orderBy=createdAt&limit=${getInitialPageSize('financialDepositsPageSize')}&page=1`,
    [`financialFees-${schoolYearEnding - 1}`]: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize(`financialFees-${schoolYearEnding - 1}PageSize`)}&page=1`,
    [`financialFees-${schoolYearEnding}`]: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize(`financialFees-${schoolYearEnding}PageSize`)}&page=1`,
    [`financialFees-${schoolYearEnding + 1}`]: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize(`financialFees-${schoolYearEnding + 1}PageSize`)}&page=1`,
    financialFundraiserCredits: '',
    financialItemMemberFees: '?dataGridFilters=%7B"items"%3A%5B%7B"columnField"%3A"roleLabel"%2C"operatorValue"%3A"is"%2C"id"%3A16130%2C"value"%3A"Student"%7D%5D%2C"linkOperator"%3A"and"%2C"quickFilterValues"%3A%5B""%5D%2C"quickFilterLogicOperator"%3A"and"%7D',
    [`financialItems-${schoolYearEnding - 1}`]: `?asc=true&orderBy=label&limit=${getInitialPageSize(`financialItems-${schoolYearEnding - 1}PageSize`)}&page=1`,
    [`financialItems-${schoolYearEnding}`]: `?asc=true&orderBy=label&limit=${getInitialPageSize(`financialItems-${schoolYearEnding}PageSize`)}&page=1`,
    [`financialItems-${schoolYearEnding + 1}`]: `?asc=true&orderBy=label&limit=${getInitialPageSize(`financialItems-${schoolYearEnding + 1}PageSize`)}&page=1`,
    [`financialPayments-${schoolYearEnding - 1}`]: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize(`financialPayments-${schoolYearEnding - 1}PageSize`)}&page=1&${paymentsDefaultFilter}`,
    [`financialPayments-${schoolYearEnding}`]: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize(`financialPayments-${schoolYearEnding}PageSize`)}&page=1&${paymentsDefaultFilter}`,
    [`financialPayments-${schoolYearEnding + 1}`]: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize(`financialPayments-${schoolYearEnding + 1}PageSize`)}&page=1&${paymentsDefaultFilter}`,
    financialTransactions: '',
    forms: `?asc=true&orderBy=title&limit=${getInitialPageSize('formsPageSize')}&page=1`,
    group: `?asc=true&orderBy=last_name&limit=${getInitialPageSize('groupPageSize')}&page=1`,
    groups: `?asc=true&orderBy=label&limit=${getInitialPageSize('groupsPageSize')}&page=1`,
    inactiveUsers: `?asc=true&orderBy=firstName&limit=${getInitialPageSize('inactiveUsersPageSize')}&page=1`,
    inventoryItems: `?asc=true&orderBy=label&limit=${getInitialPageSize('inventoryItemsPageSize')}&page=1`,
    libraryItems: `?asc=true&orderBy=title&limit=${getInitialPageSize('libraryItemsPageSize')}&page=1`,
    licenses: `?asc=true&orderBy=expiresAt&limit=${getInitialPageSize('licensesPageSize')}&page=1`,
    myFiles: `?asc=true&orderBy=fileName&limit=${getInitialPageSize('myFilesPageSize')}&page=1`,
    myFinancialCredits: `?asc=false&orderBy=createdAt&limit=${getInitialPageSize('myFinancialCreditsPageSize')}&page=1`,
    myFinancialFees: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize('myFinancialFeesPageSize')}&page=1`,
    myFinancialPayments: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize(`financialPayments-${schoolYearEnding}PageSize`)}&page=1`,
    parents: `?asc=true&orderBy=firstName&limit=${getInitialPageSize('parentsPageSize')}&page=1`,
    paymentPickerDepositForm: '?isDeposited=false',
    pendingMembers: '',
    peoplePickerChecklistMembers: studentRoleDefaultFilter,
    peoplePickerEmail: studentRoleDefaultFilter,
    peoplePickerEndOfYearNonReturningStudents: studentRoleDefaultFilter,
    peoplePickerFeeForm: studentRoleDefaultFilter,
    peoplePickerForm: studentRoleDefaultFilter,
    peoplePickerGroups: studentRoleDefaultFilter,
    peoplePickerSelectOneUser: studentRoleDefaultFilter,
    peoplePickerStatementCard: studentRoleDefaultFilter,
    previousMembers: '',
    returningStudents: '',
    scheduledSendEmails: '',
    students: `?asc=true&orderBy=firstName&limit=${getInitialPageSize('studentsPageSize')}&page=1`,
    suppressedEmails: `?dataGridSort=${encodeURIComponent(JSON.stringify([{ field: 'firstName', sort: 'asc' }]))}`,
    uniforms: `?asc=true&orderBy=number&limit=${getInitialPageSize('uniformsPageSize')}&page=1`,
  },
  tableSelections: {
    checklist: cloneDeep(initialSelection),
    checklists: cloneDeep(initialSelection),
    directorPermissions: cloneDeep(initialSelection),
    directors: cloneDeep(initialSelection),
    districtAssistants: cloneDeep(initialSelection),
    districtDeletedInventoryItems: cloneDeep(initialSelection),
    districtInventoryItems: cloneDeep(initialSelection),
    districtLibraryItems: cloneDeep(initialSelection),
    districtLicenses: cloneDeep(initialSelection),
    districtOrganizations: cloneDeep(initialSelection),
    districtOrganizationsDirectors: cloneDeep(initialSelection),
    districtOrganizationsParentsWithPermissions: cloneDeep(initialSelection),
    districtUniforms: cloneDeep(initialSelection),
    dynamicFields: cloneDeep(initialSelection),
    emailDrafts: cloneDeep(initialSelection),
    emails: cloneDeep(initialSelection),
    fileDirectories: cloneDeep(initialSelection),
    files: cloneDeep(initialSelection),
    financialCredits: cloneDeep(initialSelection),
    financialDeposits: cloneDeep(initialSelection),
    [`financialFees-${schoolYearEnding - 1}`]: cloneDeep(initialSelection),
    [`financialFees-${schoolYearEnding}`]: cloneDeep(initialSelection),
    [`financialFees-${schoolYearEnding + 1}`]: cloneDeep(initialSelection),
    financialFundraiserCredits: cloneDeep(initialSelection),
    financialItemMemberFees: cloneDeep(initialSelection),
    [`financialItems-${schoolYearEnding - 1}`]: cloneDeep(initialSelection),
    [`financialItems-${schoolYearEnding}`]: cloneDeep(initialSelection),
    [`financialItems-${schoolYearEnding + 1}`]: cloneDeep(initialSelection),
    [`financialPayments-${schoolYearEnding - 1}`]: cloneDeep(initialSelection),
    [`financialPayments-${schoolYearEnding}`]: cloneDeep(initialSelection),
    [`financialPayments-${schoolYearEnding + 1}`]: cloneDeep(initialSelection),
    financialTransactions: cloneDeep(initialSelection),
    forms: cloneDeep(initialSelection),
    group: cloneDeep(initialSelection),
    groups: cloneDeep(initialSelection),
    inactiveUsers: cloneDeep(initialSelection),
    inventoryItems: cloneDeep(initialSelection),
    libraryItems: cloneDeep(initialSelection),
    licenses: cloneDeep(initialSelection),
    myFiles: cloneDeep(initialSelection),
    myFinancialCredits: cloneDeep(initialSelection),
    myFinancialFees: cloneDeep(initialSelection),
    myFinancialPayments: cloneDeep(initialSelection),
    parents: cloneDeep(initialSelection),
    paymentPickerDepositForm: cloneDeep(initialSelection),
    pendingMembers: cloneDeep(initialSelection),
    peoplePickerChecklistMembers: cloneDeep(initialSelection),
    peoplePickerEmail: cloneDeep(initialSelection),
    peoplePickerEndOfYearNonReturningStudents: cloneDeep(initialSelection),
    peoplePickerFeeForm: cloneDeep(initialSelection),
    peoplePickerForm: cloneDeep(initialSelection),
    peoplePickerGroups: cloneDeep(initialSelection),
    peoplePickerSelectOneUser: cloneDeep(initialSelection),
    peoplePickerStatementCard: cloneDeep(initialSelection),
    previousMembers: cloneDeep(initialSelection),
    returningStudents: cloneDeep(initialSelection),
    scheduledSendEmails: cloneDeep(initialSelection),
    students: cloneDeep(initialSelection),
    suppressedEmails: cloneDeep(initialSelection),
    uniforms: cloneDeep(initialSelection),
  },
};

const isPaginatedListDataLoaded = createReducer(
  TABLE_PARAMS_INITIAL_STATE.isPaginatedListDataLoaded,
  (handleAction) => [
    handleAction(updateIsPaginatedListDataLoaded, (state, { payload }: Action<TableState['isPaginatedListDataLoaded']>) =>
      payload),
  ],
);
const tableParams = createReducer(
  TABLE_PARAMS_INITIAL_STATE.tableParams,
  (handleAction) => [
    handleAction(updateTableQueryParams, (state, { payload }: Action<TableState['tableParams']>) => ({
      ...state,
      ...payload,
    })),
  ],
);
const tableSelections = createReducer(
  TABLE_PARAMS_INITIAL_STATE.tableSelections,
  (handleAction) => [
    handleAction(updateTableSelections, (state, { payload }: Action<TableState['tableSelections']>) => ({
      ...state,
      ...payload,
    })),
  ],
);

export default combineReducers<TableState>({
  isPaginatedListDataLoaded,
  tableParams,
  tableSelections,
});
