// External Dependencies
import { LazyQueryResult } from '@apollo/client';
import gql from 'graphql-tag';

// Internal Dependencies
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import { useQueryEnhanced } from 'utils/lib/graphql';

// Local Typings
export interface LicensesIndexResponse {
  licenses: GQL.ILicensesIndexAll;
}

// Local Variables
const GET_LICENSES = gql`
  query LicensesIndex($queryParams: IndexQueryParams) {
    licenses(queryParams: $queryParams) {
      data {
        authorizedBy
        expiresAt
        id
        numberOfMonths
        organizationLabel
        purchasedAt
        redeemedAt
        redemptionCode
      }
      fullCount
    }
  }
`;

const GET_LICENSES_COUNT = gql`
  query LicensesCount {
    licenses {
      fullCount
    }
  }
`;

export const useGetLicensesIndexQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<LicensesIndexResponse, GQL.ILicensesOnQueryArguments>
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<LicensesIndexResponse, GQL.ILicensesOnQueryArguments>({
    gqlQuery,
    options,
    tableResource: 'licenses',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetLicensesIndex = () => {
  return usePaginatedListQuery<LicensesIndexResponse, GQL.ILicensesIndex>({
    dataSelector: (data) => data.licenses.data,
    fullCountSelector: (data) => data.licenses.fullCount,
    query: GET_LICENSES,
  });
};

export const useGetLicensesCount = () =>
  useQueryEnhanced<LicensesIndexResponse>(GET_LICENSES_COUNT);
