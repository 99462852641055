// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';
import {
  gridFilterModelSelector, gridQuickFilterValuesSelector, useGridApiContext, useGridSelector,
} from '@mui/x-data-grid-pro';

// Internal Dependencies
import { pluralize } from 'utils';

// Local Dependencies
import { Props as ZeroStateProps } from '../ZeroState';
import TableDataGridZeroState from './TableDataGridZeroState';

// When the user has no results using filter or search, we use the shared
//  TableDataGridZeroState, without a contact us link, adding a custom message

// Component Definition
const TableDataGridZeroResultsState: FC<ZeroStateProps> = ({
  ...otherProps
}) => {
  const apiRef = useGridApiContext();

  // Current content in the quick filter i.e. search bar
  const currentQuickFilterValue = useGridSelector(apiRef, gridQuickFilterValuesSelector);
  const hasQuickFilterValue = currentQuickFilterValue && currentQuickFilterValue.length > 0;

  // Current state for all applied filters
  const currentFilterModelValue = useGridSelector(apiRef, gridFilterModelSelector);
  const hasFilterApplied = currentFilterModelValue.items.length > 0;
  const appliedFilterCount = currentFilterModelValue.items.length;

  const message = (
    <Typography>
      No results found
      {hasQuickFilterValue ? (
        <>
          {' '}for <strong>{currentQuickFilterValue}</strong>
        </>
      ) : ''}
      {hasFilterApplied ? ` for selected ${pluralize(appliedFilterCount, 'filter')}` : ''}
    </Typography>
  );

  return (
    <TableDataGridZeroState
      excludeNeedHelp
      message={message}
      {...otherProps}
    />
  );
};

export default TableDataGridZeroResultsState;
