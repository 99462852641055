// External Dependencies
import { Box, Collapse } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { EnhancedAlert } from 'components/shared';

// Local Typings
interface Props {
  hasHighSchoolSeniors: boolean;
  isCollegeOrUniversity: boolean;
}

const graduatingGradeListItems = (
  <>
    <li>Exclude any students in the graduating grade</li>
    <li>Send graduating students to their successor organization</li>
  </>
);

// Component Definition
const EndOfYearMembersInfoAlert: FC<Props> = ({
  hasHighSchoolSeniors,
  isCollegeOrUniversity,
}) => (
  <Box mb={3}>
    <EnhancedAlert
      severity="info"
      title="Members"
    >
      When you complete the <strong>End of Year</strong> process,{' '}
      {APP_NAME} will automatically handle these member actions for{' '}
      next year&apos;s organization data:
      <ul>
        <Collapse in={!isCollegeOrUniversity && !hasHighSchoolSeniors}>
          {graduatingGradeListItems}
        </Collapse>
        <li>Exclude any non-returning students</li>
        <li>Include parents of all returning students</li>
        <li>All returning students will increase grade level</li>
      </ul>
    </EnhancedAlert>
  </Box>
);

export default EndOfYearMembersInfoAlert;
