// External Dependencies
import {
  Divider, List,
} from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';

// Internal Dependencies
import {
  ListItemWithSecondaryAction,
  ShowPageDataDisplay,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { displaySchoolYearString } from 'utils/lib/displaySchoolYearString';
import { tableQueryParams } from 'state/table/selectors';

// Local Typings
interface Props {
  financialItemData: GQL.IFinancialItem | undefined;
}

// Component Definition
const ShowFinancialItemData: FC<Props> = ({ financialItemData }) => {
  const financialItemShowParams = useSelector(tableQueryParams('financialItemMemberFees'));

  if (!financialItemData) {
    return null;
  }

  const {
    canApplyFundraiserCredits,
    financialAccount,
    id,
    isActive,
    isOrganizationCoveringStripeFee,
    label,
    priceInCents,
    schoolYearEnding,
  } = financialItemData;

  const fullSchoolYear = displaySchoolYearString(schoolYearEnding);

  return (
    <>
      <ShowPageDataDisplay
        label="Name"
        value={label}
      />
      <ShowPageDataDisplay
        label="Account"
        value={financialAccount?.label}
      />
      <ShowPageDataDisplay
        label="Price"
        type="currency"
        value={priceInCents}
      />
      <ShowPageDataDisplay
        label="School Year"
        type="number"
        value={fullSchoolYear}
      />
      <ShowPageDataDisplay
        label="Active"
        type="boolean"
        value={isActive}
      />
      <ShowPageDataDisplay
        label="Cover Stripe Fee"
        type="boolean"
        value={isOrganizationCoveringStripeFee}
      />
      <ShowPageDataDisplay
        label="Can Apply Fundraiser Credits"
        type="boolean"
        value={canApplyFundraiserCredits}
      />

      <Divider />

      <List>
        <ListItemWithSecondaryAction
          primaryText="Payment"
          secondaryAction={{
            buttonIcon: <AddIcon />,
            buttonText: 'Payment',
            to: `/${PATHS.FINANCIAL_PAYMENTS_NEW}?financial_item_id=${id}`,
          }}
          secondaryText="Add a payment for this item."
        />
        <ListItemWithSecondaryAction
          primaryText="Fee"
          secondaryAction={{
            buttonIcon: <AddIcon />,
            buttonText: 'Fee',
            to: `/${PATHS.FINANCIAL_FEES_NEW}${financialItemShowParams}&financial_item_id=${id}`,
          }}
          secondaryText="Assign this item as a fee."
        />
      </List>
    </>
  );
};

export default ShowFinancialItemData;
