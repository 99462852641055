// External Dependencies
import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { History, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetLibraryItem } from 'gql/queries';
import LibraryForm from 'pages/Library/shared/LibraryForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Component Definition
const AdminLibraryShow: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const {
    data,
    error,
    loading,
  } = useGetLibraryItem(id!);

  const districtLibraryParams = useSelector(tableQueryParams('districtLibraryItems'));

  return (
    <Page
      backButtonProps={{
        label: 'All Library Items',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}${districtLibraryParams}`,
      }}
      editButtonPath={`/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}/${id}/edit`}
      error={error}
      isLoading={!data || loading}
    >
      <LibraryForm
        canUseDynamicFields
        libraryItem={data?.libraryItem}
        isAdmin
        readOnly
        title="Library Item Details"
      />

      {data?.libraryItem && (
        <History
          id={data.libraryItem.id}
          resource="libraryItems"
        />
      )}
    </Page>
  );
};

export default AdminLibraryShow;
