// External Dependencies
import {
  Box,
  FormHelperText,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import {
  FieldArray,
  Form,
  FormikErrors,
  FormikTouched,
} from 'formik';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

// Internal Dependencies
import {
  CustomInput,
  EnhancedAlert,
  FormRouteActions,
  ShowCard,
} from 'components/shared';
import FinancialAccountSelect from 'components/shared/FinancialAccountSelect';
import ItemsFieldArray from 'pages/Finances/FinancialFundraiserCredits/shared/ItemsFieldArray';
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';

// Local Typings
interface Props {
  errors: FormikErrors<GQL.ICreateFinancialFundraiserCreditInput>;
  financialAccountIdFormikValue: string;
  isSubmitting: boolean;
  itemsFormikValue: GQL.IFinancialFundraiserCreditItemPriorityInput[];
  onPressCancelOrBackButton: () => void;
  schoolYearEndingFormikValue: number;
  touched: FormikTouched<GQL.ICreateFinancialFundraiserCreditInput>;
}

// Component Definition
const FinancialFundraiserCreditForm: FC<Props> = ({
  errors,
  financialAccountIdFormikValue,
  isSubmitting,
  itemsFormikValue,
  onPressCancelOrBackButton,
  schoolYearEndingFormikValue,
  touched,
}) => {
  const isFormTouched = Object.keys(touched).length > 0;

  return (
    <Form>
      <Box mb={2}>
        <ShowCard
          icon={CurrencyExchangeIcon}
          title="New Fundraiser Credit"
        >
          <CustomInput
            label="Name"
            name="label"
          />

          <CustomInput
            helperText="The amount reduced for each student assigned the selected fees"
            isCurrency
            label="Amount"
            name="amountInCents"
          />

          <FinancialAccountSelect readOnly={!!itemsFormikValue.length} />

          <SchoolYearSelect
            fullWidth={false}
            inputProps={{
              readOnly: !!itemsFormikValue.length,
            }}
            required
            variant="filled"
          />

          {itemsFormikValue.length > 0 && (
            <EnhancedAlert
              sx={{ marginTop: 2 }}
            >
              To change financial account or school year, remove all selected financial items.
            </EnhancedAlert>
          )}

          <Box marginY={2}>
            <Typography>
              Financial Items
            </Typography>

            <FormHelperText>
              Fundraiser credits are applied to members&apos;
              {' '}
              fees in the order of priority selected.
            </FormHelperText>

            {(errors?.items?.length ?? 0) > 0 && touched.items && (
              <EnhancedAlert
                severity="error"
                sx={{ marginY: 1 }}
              >
                {errors.items}
              </EnhancedAlert>
            )}

            <FieldArray name="items">
              {(arrayHelpers) => (
                <ItemsFieldArray
                  arrayHelpers={arrayHelpers}
                  financialAccountIdFormikValue={financialAccountIdFormikValue}
                  itemsFormikValue={itemsFormikValue}
                  schoolYearEndingFormikValue={schoolYearEndingFormikValue}
                />
              )}
            </FieldArray>
          </Box>

          <EnhancedAlert>
            You will be able to assign this fundraiser credit to members on the next page.
          </EnhancedAlert>
        </ShowCard>
      </Box>

      <FormRouteActions
        context="Fundraiser Credit"
        isFormTouched={isFormTouched}
        isSubmitting={isSubmitting}
        onPressCancelOrBackButton={onPressCancelOrBackButton}
      />
    </Form>
  );
};

export default FinancialFundraiserCreditForm;
