// External Dependencies
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import PeopleIcon from '@mui/icons-material/People';
import styled from 'styled-components';

// Internal Dependencies
import {
  Container,
  ShowCard,
} from 'components/shared';

// Local Typings
interface Props {
  assignments?: GQL.IFile['assignments'];
  canEditFiles: boolean;
  editPath: string;
}

// Local Variables
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.stripeBlue['400'], 0.4),
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  '.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

// Component Definition
const FileShowAssignments: FC<Props> = ({
  assignments,
  canEditFiles,
  editPath,
}) => {
  const theme = useTheme();

  if (!assignments) {
    return null;
  }

  return (
    <StyledContainer maxWidth={500}>
      <Box mb={2}>
        <ShowCard
          canEdit={canEditFiles}
          editPath={editPath}
          icon={FolderSharedIcon}
          title="File Assignments"
        >
          {!assignments.length
            ? (
              <Typography color="textSecondary">
                No assignments yet
              </Typography>
            ) : (
              <List>
                {
                  assignments.map((assignment) => (
                    <ListItem>
                      <ListItemAvatar>
                        <StyledAvatar>
                          <PeopleIcon htmlColor={theme.palette.common.white} />
                        </StyledAvatar>
                      </ListItemAvatar>

                      <ListItemText
                        primary={assignment.group
                          ? assignment.group.label
                          : assignment?.organizationRole?.label}
                        secondary={!assignment.group
                          ? null
                          : assignment?.organizationRole?.label}

                      />
                    </ListItem>
                  ))
                }
              </List>
            )}
        </ShowCard>
      </Box>
    </StyledContainer>
  );
};

export default FileShowAssignments;
