// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { PATHS, TITLES } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import PageHeader from 'components/shared/Page/PageHeader';

// Local Dependencies
import AdminDeletedInventoryTable from './AdminDeletedInventoryTable';

// Component Definition
const AdminDeletedInventory: FC = () => {
  const districtInventoryParams = useSelector(tableQueryParams('districtInventoryItems'));

  return (
    <>
      <Box mb={2}>
        <PageHeader
          backButtonProps={{
            label: 'Inventory Items',
            path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}${districtInventoryParams}`,
          }}
        />
      </Box>

      <Subtitle>
        {TITLES[PATHS.INVENTORY_DELETED]} Items
      </Subtitle>

      <Box mb={4}>
        <EnhancedCard>
          <AdminDeletedInventoryTable />
        </EnhancedCard>
      </Box>
    </>
  );
};

export default AdminDeletedInventory;
