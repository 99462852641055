// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { Subtitle } from 'components/shared';

// Local Dependencies
import MyFinancialFeesTable from './MyFinancialFeesTable';

// Component Definition
const MyFinancialFees: FC = () => (
  <>
    <Subtitle>
      My Fees
    </Subtitle>

    <MyFinancialFeesTable />
  </>
);

export default MyFinancialFees;
