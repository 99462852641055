// External Dependencies
import { Button } from '@mui/material';
import { FC } from 'react';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styled from 'styled-components';

// Internal Dependencies
import { isMobileScreenSize } from 'state/device/selectors';

// Local Typings
interface Props {
  context: string;
  navigateTo?: string;
  onClickButton?: () => void;
}

// Local Variables
const StyledButton = styled(Button)(({ theme }) => ({
  '.backIcon': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2),
    },
  },
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  backgroundColor: theme.palette.common.white,
}));

// Component Definition
const BackToButton: FC<Props> = ({
  context,
  navigateTo,
  onClickButton,
  ...otherProps
}) => {
  const isMobileScreen = useSelector(isMobileScreenSize);

  const handleClick = () => {
    if (onClickButton) {
      onClickButton();
    } else if (navigateTo) {
      navigate(navigateTo);
    }
  };

  const buttonText = isMobileScreen
    ? 'Back'
    : `Back to ${context}`;

  return (
    <StyledButton
      color="primary"
      onClick={handleClick}
      onKeyPress={handleClick}
      size="small"
      startIcon={(
        <ArrowBackIcon
          className="backIcon"
          fontSize="small"
        />
      )}
      variant="outlined"
      {...otherProps}
    >
      {buttonText}
    </StyledButton>
  );
};

export default BackToButton;
