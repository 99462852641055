// External Dependencies
import { FC } from 'react';
import { InputBaseComponentProps } from '@mui/material';

// Internal Dependencies
import {
  CustomSelect,
} from 'components/shared';
import { SelectOption } from 'components/shared/CustomSelect';
import { useGetDistrictOrganizations } from 'gql/queries';

// Local Typings
interface Props {
  allowUnassignedOption?: boolean;
  excludeOrganizationId?: string;
  inputProps?: InputBaseComponentProps;
  label?: string;
  name?: string;
  orgTypeId: string | null;
}

/*
*
* This select should be used in a Formik context
*
*/

// Component Definition
const DistrictOrganizationSelect: FC<Props> = ({
  allowUnassignedOption,
  excludeOrganizationId,
  inputProps,
  label = 'Organization *',
  name = 'organizationId',
  orgTypeId,
}) => {
  const {
    data,
  } = useGetDistrictOrganizations();

  const organizations = data?.districtOrganizations ?? [];

  const organizationOptions: SelectOption[] = organizations
    .filter(
      (org) =>
        org?.organizationType?.id
      && (orgTypeId === null || org.organizationType.id === orgTypeId)
      && org.id !== excludeOrganizationId,
    ).map((organization) => ({
      id: organization.id,
      label: organization.label,
    }));

  if (allowUnassignedOption) {
    organizationOptions.unshift({
      id: '-1',
      label: '(Unassigned)',
    });
  }

  return (
    <CustomSelect
      inputProps={inputProps}
      label={label}
      name={name}
      options={organizationOptions}
      required
    />
  );
};

export default DistrictOrganizationSelect;
