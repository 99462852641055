// External Dependencies
import { GridColDef, ValueOptions } from '@mui/x-data-grid-pro';
import { InventoryConditions } from '@presto-assistant/api_types';
import { UniformIndexResponseItem } from '@presto-assistant/api_types/api/v1/uniform';
import { convertCentsToDollarsAllowNull, getFullName } from '@presto-assistant/api_types/utils';
import { useMemo } from 'react';

// Internal Dependencies
import { displayCell, displayDynamicFieldCell } from 'components/shared/TableV2';
import { mapEnum } from 'utils/lib/map_enum';
import { renderMoneyCell } from 'components/shared/TableDataGrid/helpers';
import { useGetDynamicFields, useGetOrganization } from 'gql/queries';
import { useGetUniformStyles } from 'gql/queries/uniform-style-queries';

// Local Dependencies
import QuickCheckoutButton from './QuickCheckoutButton';

// Local Variables
const conditionOptions: ValueOptions[] = mapEnum(InventoryConditions)
  .map((condition) => ({
    label: condition.label,
    value: condition.label,
  }));

export const useColumns = ({
  extraColumns,
  onClickQuickCheckout,
}: {
    extraColumns?: GridColDef<UniformIndexResponseItem>[];
    onClickQuickCheckout: (uniformItemId: string) => void;
  }) => {
  const { data: dynamicFieldData } = useGetDynamicFields({
    tableRef: 'uniforms',
  });

  const {
    data: organizationData,
  } = useGetOrganization();

  const organizationTypeId = organizationData?.organization?.organizationType?.id;

  const {
    data: uniformStyleData,
  } = useGetUniformStyles(organizationTypeId);

  const uniformStyleOptions = useMemo<ValueOptions[]>(() =>
    uniformStyleData?.uniformStyles?.map((style) => ({
      label: style.label,
      value: style.label,
    })) ?? [], [uniformStyleData]);

  return useMemo(() => {
    const columns: GridColDef<UniformIndexResponseItem>[] = [
      {
        field: 'type',
        headerName: 'Type',
        width: 292,
      },
      {
        field: 'categoryLabel',
        headerName: 'Category',
        width: 160,
      },
      {
        field: 'number',
        headerName: 'Uniform #',
        width: 292,
      },
      {
        field: 'style',
        headerName: 'Style',
        type: 'singleSelect',
        valueOptions: uniformStyleOptions,
        width: 160,
      },
      {
        field: 'size',
        headerName: 'Size',
        width: 292,
      },
      {
        field: 'checkedOutTo',
        headerName: 'Checked Out To',
        minWidth: 200,
        renderCell: (params) => params.value
          || (
            <QuickCheckoutButton
              onClick={onClickQuickCheckout}
              uniformItemId={params.row.id}
            />
          ),
        valueGetter: ((params) => {
          if (params.row.checkedOutToMemberId) {
            const fullName = getFullName({
              firstName: params.row.checkedOutToFirstName ?? '',
              lastName: params.row.checkedOutToLastName ?? '',
            });

            return params.row.checkedOutToEmail ? `${fullName} (${params.row.checkedOutToEmail})` : fullName;
          }

          return null;
        }),
      },
      {
        field: 'color',
        headerName: 'Color',
        width: 160,
      },
      {
        field: 'condition',
        headerName: 'Condition',
        type: 'singleSelect',
        valueOptions: conditionOptions,
        width: 160,
      },
      {
        field: 'purchasedAt',
        headerName: 'Purchase Date',
        renderCell: (params) => displayCell(params.value, { format: 'date' }),
        type: 'date',
        width: 160,
      },
      {
        field: 'purchaseValueInCents',
        headerName: 'Purchase Amount',
        minWidth: 160,
        renderCell: (params) => renderMoneyCell(params.value),
        type: 'number',
        valueGetter: (params) => displayCell(convertCentsToDollarsAllowNull(params.value)),
        width: 160,
      },
      {
        field: 'customBarcode',
        headerName: 'Barcode',
        width: 292,
      },
      {
        field: 'comments',
        headerName: 'Comments',
        width: 160,
      },
      ...(dynamicFieldData?.dynamicFields ?? [])
        .map<GridColDef<UniformIndexResponseItem>>((field) => ({
          field: field.dynamicFieldRef as keyof UniformIndexResponseItem,
          headerName: field.label,
          valueGetter: (params) => displayDynamicFieldCell(field, params.row),
        })),
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    dynamicFieldData,
    extraColumns,
    uniformStyleOptions,
    onClickQuickCheckout,
  ]);
};
