// External Dependencies
import { FC, useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { createFormSchema } from '@presto-assistant/api_types/schemas/form';
import { navigate } from '@reach/router';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';

// Internal Dependencies
import {
  CustomInput,
  EnhancedCard,
  Flex,
  Page,
  SaveButton,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useCreateForm } from 'gql/mutations/form-mutations';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';

// Local Typings
interface FormValues {
  schoolYearEnding: number;
  title: string;
}

// Component Definition
const FormsNew: FC = () => {
  const schoolYearEnding = useGetOrganizationSchoolYear();

  const [createForm, {
    loading: isCreatingForm,
  }] = useCreateForm({
    onCompleted: async (data) => {
      const formId = data.createForm.id;

      await navigate(`/${PATHS.FORMS}/${formId}/edit`, { replace: true });
    },
  });

  const handleFormikSubmit = useCallback((values: FormValues) => {
    createForm({
      variables: {
        input: {
          ...values,
          schoolYearEnding: Number(values.schoolYearEnding),
        },
      },
    });
  }, [createForm]);

  const initialValues = useMemo<FormValues>(() => {
    return {
      schoolYearEnding,
      title: '',
    };
  }, [schoolYearEnding]);

  return (
    <Page>
      <Container maxWidth="md">
        <Subtitle>
          Start a new form
        </Subtitle>

        <Formik
          initialValues={initialValues}
          onSubmit={handleFormikSubmit}
          validationSchema={createFormSchema}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <EnhancedCard>
                <CardContent>
                  <CustomInput
                    label="Title"
                    name="title"
                  />

                  <SchoolYearSelect
                    hidePreviousYears
                    name="schoolYearEnding"
                  />

                </CardContent>

                <CardActions>
                  <Flex
                    justifyContent="flex-end"
                    width="100%"
                  >
                    <SaveButton isSaving={isCreatingForm}>
                      Next
                    </SaveButton>
                  </Flex>
                </CardActions>
              </EnhancedCard>
            </Form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default FormsNew;
