// External Dependencies
import { FC } from 'react';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { tableQueryParams } from 'state/table/selectors';
import { useDistrictCreateLibraryItem } from 'gql/mutations';
import LibraryForm,
{
  AdminLibraryFormValues,
  LibraryFormValues,
} from 'pages/Library/shared/LibraryForm';

// Local Variables
const navigateToShow = (id: string) => {
  navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}/${id}`);
};

// Component Definition
const AdminLibraryNew: FC = () => {
  const [handleCreateDistrictLibraryItem] = useDistrictCreateLibraryItem(
    {
      clearCachePredicates: ['districtLibraryItemsIndex', 'libraryItem'],
      onCompleted: (data) => navigateToShow(data.districtCreateLibraryItem.id),
    },
  );

  const districtLibraryParams = useSelector(tableQueryParams('districtLibraryItems'));

  const handleSubmit = async (
    values: LibraryFormValues | AdminLibraryFormValues,
  ) => {
    const updatedValues = {
      ...values,
      organizationTypeId: values.orgTypeId!,
    };

    // The API won't like us sending in a value that doesn't exist in the model
    delete updatedValues.orgTypeId;

    await handleCreateDistrictLibraryItem({
      variables: {
        input: {
          ...updatedValues,
          ...applyDynamicFields(updatedValues as unknown as DynamicFields),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'All Library Items',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.LIBRARY}${districtLibraryParams}`,
      }}
    >
      <LibraryForm
        canUseDynamicFields
        isAdmin
        onSubmit={handleSubmit}
        title="New Library Item"
      />
    </Page>
  );
};

export default AdminLibraryNew;
