// External Dependencies
import {
  Button, CardActions, List, ListItem, ListItemText,
} from '@mui/material';
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import isEqual from 'lodash.isequal';

// Internal Dependencies
import {
  ConfirmationDialog,
  DialogPeoplePicker,
  EnhancedAlert,
  EnhancedContainer,
  Page,
  ShowCard,
  ShowPageDataDisplay,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { close as closePeoplePickerDialog, open as openPeoplePickerDialog } from 'state/ui/peoplePickerDialog/actions';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount, pluralize,
} from 'utils';
import { isOpen } from 'state/ui/peoplePickerDialog/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetFinancialFundraiserCredit } from 'gql/queries';

// Local Dependencies
import { useAssignFinancialFundraiserCredits } from 'gql/mutations';
import { useIsOpen } from 'hooks/useIsOpen';
import FinancialFundraiserCreditMembersTable from '../shared/FinancialFundraiserCreditMembersTable';

// Local Typings
interface RouteProps {
  id: string;
}

// Component Definition
const FundraiserCreditsShowPage: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const dispatch = useDispatch();

  const isPeoplePickerDialogOpen = useSelector(isOpen);

  const {
    handleClose: handleCloseConfirmationDialog,
    handleOpen: handleOpenConfirmationDialog,
    isOpen: isConfirmationDialogOpen,
  } = useIsOpen();

  // Used to pre-populate the DialogPeoplePicker
  const [rawMemberIds, setRawMemberIds] = useState<string[]>([]);
  // Used to track selected members from DialogPeoplePicker
  const [memberIds, setMemberIds] = useState<string[]>([]);

  const handleClickSelectMembers = useCallback(() => {
    dispatch(openPeoplePickerDialog());
  }, [dispatch]);

  const handleClosePeoplePickerDialog = useCallback(() => {
    dispatch(closePeoplePickerDialog());
  }, [dispatch]);

  const [
    assignFinancialFundraiserCredits,
    {
      loading: isSubmitting,
    },
  ] = useAssignFinancialFundraiserCredits({
    onCompleted: () => {
      handleCloseConfirmationDialog();
    },
  });

  const handleAssignFundraiserCredits = useCallback(() => {
    if (id) {
      assignFinancialFundraiserCredits({
        variables: {
          input: {
            action: 'update',
            financialFundraiserCreditId: id,
            memberIds,
          },
        },
      });
    }
  }, [assignFinancialFundraiserCredits, id, memberIds]);

  const handleUpdateSelectedMembers = useCallback((selectedIds: string[]) => {
    if (!isEqual([...rawMemberIds].sort(), [...selectedIds].sort())) {
      setMemberIds(selectedIds);
      handleOpenConfirmationDialog();
    }

    handleClosePeoplePickerDialog();
  }, [handleClosePeoplePickerDialog, handleOpenConfirmationDialog, rawMemberIds]);

  // We want to ensure the dialog text to not change while closing
  const handleExited = useCallback(() => {
    setMemberIds([]);
  }, []);

  const {
    data,
    error,
    loading,
  } = useGetFinancialFundraiserCredit(id!);

  useEffect(() => {
    if (data?.financialFundraiserCredit.memberIdsAssigned) {
      setRawMemberIds(data.financialFundraiserCredit.memberIdsAssigned);
    }
  }, [data?.financialFundraiserCredit.memberIdsAssigned]);

  const financialFundraiserCreditsParams = useSelector(tableQueryParams('financialFundraiserCredits'));

  const selectedMemberCount = memberIds.length;

  const confirmationDialogAction = selectedMemberCount ? 'Update' : 'Remove';

  return (
    <Page
      backButtonProps={{
        label: 'Fundraiser Credits',
        path: `/${PATHS.FINANCIAL_FUNDRAISER_CREDITS}${financialFundraiserCreditsParams}`,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <EnhancedContainer sx={{ marginBottom: 2 }}>
        <ShowCard
          cardActions={(
            <CardActions>
              <Button
                onClick={handleClickSelectMembers}
                size="small"
                variant="outlined"
              >
                Assign to Members
              </Button>
            </CardActions>
          )}
          icon={CurrencyExchangeIcon}
          title="Fundraiser Credit"
        >
          <ShowPageDataDisplay
            label="Name"
            value={data?.financialFundraiserCredit.label}
          />

          <ShowPageDataDisplay
            label="Amount"
            value={displayPriceStringFromDollarAmount(
              convertCentsToDollars(data?.financialFundraiserCredit.amountInCents ?? 0),
            )}
          />

          <ShowPageDataDisplay
            label="Account"
            value={data?.financialFundraiserCredit.financialAccount.label}
          />

          <ShowPageDataDisplay
            label="Items"
            value={(
              <List>
                {data?.financialFundraiserCredit.items.map((item) => {
                  const priceDisplay = displayPriceStringFromDollarAmount(
                    convertCentsToDollars(item.financialItem.priceInCents),
                  );

                  return (
                    <ListItem
                      key={item.financialItem.id}
                      dense
                    >
                      <ListItemText
                        primary={`${item.financialItem.label} — ${priceDisplay}`}
                        secondary={`Priority ${item.priority}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            )}
          />

          <EnhancedAlert>
            Fundraiser credits are applied to members&apos;
            {' '}
            fees in the order of priority selected.
          </EnhancedAlert>
        </ShowCard>
      </EnhancedContainer>

      <FinancialFundraiserCreditMembersTable financialFundraiserCreditId={id!} />

      <DialogPeoplePicker
        isOpen={isPeoplePickerDialogOpen}
        onAddSelectedIds={handleUpdateSelectedMembers}
        preSelectedIds={rawMemberIds}
        tableResource="peoplePickerFeeForm"
        title="Select Members"
      />

      <ConfirmationDialog
        confirmButtonAction={handleAssignFundraiserCredits}
        confirmButtonText={`Yes, ${confirmationDialogAction}`}
        declineButtonAction={handleCloseConfirmationDialog}
        description={selectedMemberCount
          ? `This will update the fundraiser credit to be assigned to ${selectedMemberCount} ${pluralize(selectedMemberCount, 'member')}.`
          : 'This will remove all members from this fundraiser credit.'}
        handleClose={handleCloseConfirmationDialog}
        isSubmitting={isSubmitting}
        onExited={handleExited}
        open={isConfirmationDialogOpen}
        title={`${confirmationDialogAction} Assignments?`}
      />
    </Page>
  );
};

export default FundraiserCreditsShowPage;
