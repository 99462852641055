// External Dependencies
import {
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard, StyledLink } from 'components/shared';
import {
  PATHS,
  TITLES,
} from 'utils/constants/routes';
import { isMobileScreenSize } from 'state/device/selectors';

// Local Variables
const StyledListItem = styled.li(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));

// Component Definition
const FourOhFour: FC<RouteComponentProps> = () => {
  const isMobileScreen = useSelector(isMobileScreenSize);

  return (
    <EnhancedCard>
      <Typography
        sx={{ p: 3 }}
        variant={isMobileScreen ? 'h6' : 'h4'}
      >
        This is not the page you are looking for...
      </Typography>

      <Divider />

      <Box p={3}>
        <Typography>
          Try these popular pages instead:
        </Typography>
        <ul>
          <StyledListItem>
            <StyledLink href={`/${PATHS.DASHBOARD}`}>
              {TITLES[PATHS.DASHBOARD]}
            </StyledLink>
          </StyledListItem>
          <li>
            <StyledLink href={`/${PATHS.SETTINGS}`}>
              {TITLES[PATHS.SETTINGS]}
            </StyledLink>
          </li>
        </ul>
      </Box>
    </EnhancedCard>
  );
};

export default FourOhFour;
