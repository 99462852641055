// External Dependencies
import { FC, useMemo } from 'react';
import { FieldArray } from 'formik';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableRow,
} from '@mui/material';

// Internal Dependencies
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils';

// Local Dependencies
import { PaymentsByItemFormValues, defaultRow } from '../PaymentFormByItem';
import {
  StyledFooterCell, StyledTableCell, StyledTableHead, StyledTotalCell,
} from '../styles';
import AddNewRowTableRow from './AddNewRowTableRow';
import PaymentsByItemTableRow from './PaymentsByItemTableRow';

// Local Typings
interface Props {
  financialAccountId: string;
  financialFees: GQL.IFinancialFeeAll | undefined;
  financialItemId: string;
  formValues: PaymentsByItemFormValues;
}

// Local Variables
const baseWidth = 100;
const columnWidths = [
  baseWidth / 2,
  baseWidth * 3,
  baseWidth * 1.5,
  baseWidth * 2,
  baseWidth * 2,
  baseWidth * 1.5,
  baseWidth * 1.5,
  baseWidth * 1.5,
  baseWidth * 2,
  baseWidth * 1.5,
  baseWidth,
  baseWidth * 3,
  baseWidth * 3,
  baseWidth * 3,
  baseWidth * 3,
];
const totalWidth = columnWidths.reduce((prev, curr) => prev + curr, 0);

// Component Definition
const PaymentsByItemTable: FC<Props> = ({
  financialAccountId,
  financialFees,
  financialItemId,
  formValues,
}) => {
  const remainingFeeCount = useMemo(() => (financialFees?.data ?? [])
    .filter((fee) => fee.remainingBalanceInCents > 0).length, [financialFees]);

  const totalRow = useMemo(() => {
    const totalAmountInDollars = formValues.payments
      .reduce((prev, curr) => prev + Number(curr.amountInCents), 0);
    const totalCreditInDollars = formValues.payments
      .reduce((prev, curr) => prev + Number(curr.creditAppliedAmountInCents ?? 0), 0);
    const selectedIds = formValues.payments.map((payment) => payment.financialFeeId);
    const selectedFees = (financialFees?.data ?? [])
      .filter((fee) => selectedIds.includes(fee.id));
    const balanceTotalInCents = selectedFees
      .reduce((prev, curr) => prev + curr.remainingBalanceInCents, 0);

    return (
      <TableRow>
        <StyledFooterCell />
        <StyledFooterCell />
        <StyledFooterCell />
        <StyledFooterCell />
        <StyledFooterCell align="right">
          {displayPriceStringFromDollarAmount(convertCentsToDollars(balanceTotalInCents))}
        </StyledFooterCell>
        <StyledTotalCell>
          {displayPriceStringFromDollarAmount(totalAmountInDollars)}
        </StyledTotalCell>
        <StyledFooterCell />
        <StyledTotalCell>
          {displayPriceStringFromDollarAmount(totalCreditInDollars)}
        </StyledTotalCell>
        <StyledFooterCell />
        <StyledFooterCell />
        <StyledFooterCell />
        <StyledFooterCell />
        <StyledFooterCell />
        <StyledFooterCell />
        <StyledFooterCell />
      </TableRow>
    );
  }, [financialFees, formValues]);

  const selectedFeeIds = useMemo(() => formValues.payments
    .map((payment) => payment.financialFeeId), [formValues]);

  const tableBody = useMemo(() => (
    <FieldArray name="payments">
      {(arrayHelpers) => (
        <TableBody>
          {formValues.payments.map((formValue, rowIndex) => (
            <PaymentsByItemTableRow
              // eslint-disable-next-line react/no-array-index-key
              key={`${formValue.financialFeeId}-${rowIndex}`}
              financialAccountId={financialAccountId}
              financialFeeId={formValue.financialFeeId}
              financialItemId={financialItemId}
              onClickRemove={arrayHelpers.handleRemove(rowIndex)}
              rowIndex={rowIndex}
              selectedFeeIds={selectedFeeIds}
            />
          ))}

          {remainingFeeCount > formValues.payments.length && (
            <AddNewRowTableRow onClickAdd={arrayHelpers.handlePush(defaultRow)} />
          )}
        </TableBody>
      )}
    </FieldArray>
  ), [financialItemId, formValues.payments, selectedFeeIds]);

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table
          aria-label="Payments table"
          size="small"
          style={{ minWidth: totalWidth }}
        >
          <StyledTableHead>
            <TableRow>
              <StyledTableCell width={columnWidths[0]} />
              <StyledTableCell width={columnWidths[1]}>
                Member
              </StyledTableCell>
              <StyledTableCell width={columnWidths[2]}>
                Date Paid
              </StyledTableCell>
              <StyledTableCell width={columnWidths[3]}>
                Payment Type
              </StyledTableCell>
              <StyledTableCell
                align="right"
                width={columnWidths[4]}
              >
                Balance Remaining
              </StyledTableCell>
              <StyledTableCell width={columnWidths[5]}>
                Payment Amount
              </StyledTableCell>
              <StyledTableCell
                align="right"
                width={columnWidths[6]}
              >
                Available Credit
              </StyledTableCell>
              <StyledTableCell width={columnWidths[7]}>
                Credit Applied
              </StyledTableCell>
              <StyledTableCell width={columnWidths[8]}>
                Check #
              </StyledTableCell>
              <StyledTableCell width={columnWidths[9]}>
                CC Last Four
              </StyledTableCell>
              <StyledTableCell width={columnWidths[10]}>
                CC Exp
              </StyledTableCell>
              <StyledTableCell width={columnWidths[11]}>
                CC Acct Holder
              </StyledTableCell>
              <StyledTableCell width={columnWidths[12]}>
                Other Payment Type
              </StyledTableCell>
              <StyledTableCell width={columnWidths[13]}>
                Reference Number
              </StyledTableCell>
              <StyledTableCell width={columnWidths[14]}>
                Note
              </StyledTableCell>
            </TableRow>
          </StyledTableHead>

          {tableBody}

          <TableFooter>
            {totalRow}
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PaymentsByItemTable;
