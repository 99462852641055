// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  GridColDef,
  GridRowId,
  GridSelectionModel,
} from '@mui/x-data-grid-pro';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import EmailCheckIcon from 'mdi-material-ui/EmailCheck';
import EmailIcon from '@mui/icons-material/Email';
import EmailSearchIcon from 'mdi-material-ui/EmailSearch';

// Internal Dependencies
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import {
  isNonParentOrStudent as isNonParentOrStudentSelector,
} from 'state/self/selectors';
import { updateRecipients } from 'state/ui/emailNew/actions';
import { useGetDirectorsIndex } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';

// Component Definition
const DirectorsTable: FC = () => {
  const dispatch = useDispatch();

  const [
    filteredRowIds,
    setFilteredRowIds,
  ] = useState<GridRowId[]>([]);
  const [
    selectedMemberIds,
    setSelectedMemberIds,
  ] = useState<GridSelectionModel>([]);

  const {
    data,
    isLoading: isLoadingDirectorsData,
  } = useGetDirectorsIndex();

  const hasMoreThanOneDirector = Boolean(data && data?.length > 1);

  const isNonParentOrStudent = useSelector(isNonParentOrStudentSelector);

  const handleClickEmailMembers = useCallback((memberIds: string[]) => () => {
    // Add ids to the recipient ids for a new email
    dispatch(updateRecipients(memberIds));
    navigate(`/${PATHS.EMAIL_NEW}`);
  }, [dispatch]);

  const toolbarActions = useMemo<IToolbarAction[]>(() => ([
    {
      action: handleClickEmailMembers(selectedMemberIds as string[]),
      icon: <EmailCheckIcon />,
      isDisabled: selectedMemberIds.length === 0,
      sectionTitle: 'Email',
      text: `Email selected (${selectedMemberIds.length})`,
    },
    {
      action: handleClickEmailMembers(filteredRowIds as string[]),
      icon: <EmailSearchIcon />,
      isDisabled: filteredRowIds.length === 0,
      text: `Email filtered (${filteredRowIds.length})`,
    },
  ]), [filteredRowIds, handleClickEmailMembers, selectedMemberIds]);

  const handleClickRowTo = (id: string) =>
    `/${PATHS.DIRECTORS}/${id}`;

  const extraColumns = useMemo<GridColDef[]>(
    () => {
      const handleClickEmailDirector = (row: GQL.IDirectorIndex) => {
        dispatch(updateRecipients([row.id]));
        navigate(`/${PATHS.EMAIL_NEW}`);
      };

      const actionsColumn = createDataGridActionsColumn<GQL.IDirectorIndex>([
        {
          action: handleClickEmailDirector,
          icon: <EmailIcon />,
          text: 'Email Director',
        },
      ]);

      return actionsColumn ? [actionsColumn] : [];
    },
    [dispatch],
  );

  const columns = useColumns(extraColumns);

  return (
    <DataGridContainer>
      <TableDataGrid
        addButtonProps={isNonParentOrStudent ? {
          label: 'Director',
          to: `/${PATHS.DIRECTORS}/new`,
        } : null}
        checkboxSelection={hasMoreThanOneDirector}
        clickRowTo={isNonParentOrStudent ? handleClickRowTo : undefined}
        columns={columns}
        loading={isLoadingDirectorsData}
        onFilter={setFilteredRowIds}
        onSelectionModelChange={hasMoreThanOneDirector
          ? setSelectedMemberIds
          : undefined}
        rows={data || []}
        tableResource="directors"
        toolbarActions={toolbarActions}
        withSearch
      />
    </DataGridContainer>
  );
};

export default DirectorsTable;
