// External Dependencies
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';

// Internal Dependencies
import { RevokeStripeConfirmationDialog } from 'components/shared';
import { isMobileScreenSize } from 'state/device/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import { useUpdateStripeId } from 'gql/mutations';

// Component Definition
const RevokeStripeButton: FC = () => {
  const {
    isOpen: isRevokeDialogOpen,
    toggleIsOpen: toggleIsRevokeDialogOpen,
  } = useIsOpen();

  const isMobileScreen = useSelector(isMobileScreenSize);

  const [
    updateStripeId,
    {
      loading: isRevoking,
    },
  ] = useUpdateStripeId({
    awaitRefetchQueries: true,
    clearCachePredicates: ['organization'],
    onCompleted: toggleIsRevokeDialogOpen,
  });

  const handleRevokeStripe = useCallback(() => {
    updateStripeId({
      variables: { input: { stripeCode: null } },
    });
  }, [updateStripeId]);

  return (
    <>
      <Button
        color="primary"
        onClick={toggleIsRevokeDialogOpen}
        size={isMobileScreen ? 'small' : 'medium'}
        variant="text"
      >
        {isMobileScreen ? 'Revoke' : 'Revoke Access'}
      </Button>

      <RevokeStripeConfirmationDialog
        confirmButtonAction={handleRevokeStripe}
        isSubmitting={isRevoking}
        onClose={toggleIsRevokeDialogOpen}
        open={isRevokeDialogOpen}
      />
    </>
  );
};

export default RevokeStripeButton;
