// External Dependencies
import { FC, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { useTheme } from 'styled-components';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';

// Internal Dependencies
import {
  EnhancedCard, Flex, SaveButton, ShowCardHeader,
} from 'components/shared';
import { useIsOpen } from 'hooks/useIsOpen';
import { useUpdateFormDetails } from 'gql/mutations/form-mutations';

// Local Dependencies
import FormInputs from './FormInputs';
import ReadOnlyFields from './ReadOnlyFields';

// Local Typings
interface Props {
  form: GQL.IForm;
  isEditMode: boolean;
}

interface FormValues {
  schoolYearEnding: number;
  title: string;
}

const DetailsCard: FC<Props> = ({
  form,
  isEditMode,
}) => {
  const {
    isOpen: isEditing,
    toggleIsOpen: toggleIsEditing,
  } = useIsOpen();

  const [
    updateFormDetails,
    {
      loading: isUpdatingFormDetails,
    },
  ] = useUpdateFormDetails({
    onCompleted: () => {
      toggleIsEditing();
    },
  });

  const theme = useTheme();

  const handleClickCancel = useCallback((resetForm: () => void) => () => {
    toggleIsEditing();
    resetForm();
  }, [toggleIsEditing]);

  const handleFormikSubmit = useCallback((values: FormValues) => {
    updateFormDetails({
      variables: {
        id: form.id,
        input: {
          schoolYearEnding: Number(values.schoolYearEnding),
          title: values.title.trim(),
        },
      },
    });
  }, [form.id, updateFormDetails]);

  return (
    <Formik<FormValues>
      initialValues={{
        schoolYearEnding: form.schoolYearEnding,
        title: form.title,
      }}
      onSubmit={handleFormikSubmit}
    >
      {({
        handleSubmit,
        resetForm,
      }) => (
        <Form onSubmit={handleSubmit}>
          <EnhancedCard>
            <ShowCardHeader
              avatar={(
                <Avatar
                  sx={{
                    backgroundColor: theme.palette.showPage.people,
                  }}
                >
                  <AssignmentIcon />
                </Avatar>
              )}
              onClickEditIconButton={(isEditing || !isEditMode) ? undefined : toggleIsEditing}
              title="Form Details"
            />

            <Collapse in>
              <CardContent>
                {isEditing ? <FormInputs /> : <ReadOnlyFields form={form} />}
              </CardContent>
            </Collapse>

            <Collapse in={isEditing}>
              <CardActions sx={{ visibility: isEditing ? 'inherit' : 'hidden' }}>
                <Flex
                  gap={1}
                  justifyContent="flex-end"
                  width="100%"
                >
                  <Button onClick={handleClickCancel(resetForm)}>
                    Cancel
                  </Button>

                  <SaveButton isSaving={isUpdatingFormDetails}>
                    Save
                  </SaveButton>
                </Flex>
              </CardActions>
            </Collapse>
          </EnhancedCard>
        </Form>
      )}
    </Formik>
  );
};

export default DetailsCard;
