// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomFormButton,
  OnboardingStepTitle,
  StyledLink,
} from 'components/shared';
import { isAdminInCurrentOrg as isAdminInCurrentOrgSelector } from 'state/self/selectors';
import { redirectUser } from 'utils/lib/redirect_user';
import { useCompleteOnboarding } from 'gql/mutations';
import Tada from 'assets/images/emojione_tada.png';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: 12,
  },
  '.buttonText': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
    },
    fontSize: '1.1em',
    textTransform: 'none',
  },
}));

// Component Definition
const OnboardingComplete: FC = () => {
  const { loading: isLoadingSelf, self } = useSelfQuery();

  const isAdminInCurrentOrg = useSelector(isAdminInCurrentOrgSelector);

  const [completeOnboarding] = useCompleteOnboarding();

  if (isLoadingSelf) {
    // Loading user or redirecting
    return <CircularProgress />;
  }

  const isDirector = self?.isCreator;

  const handleFinishStep = () => {
    if (!self) {
      return null;
    }
    const {
      currentOrgId,
      hasDistrict,
      onboarding,
    } = self;

    const nextOnboardingStage = onboarding.stages.find(
      (stage: GQL.IOnboardingStage) => {
        const { isComplete } = stage;
        return !isComplete;
      },
    );

    completeOnboarding();

    const emptyOptions = {};

    return redirectUser(
      currentOrgId,
      hasDistrict,
      self.alertFlagId,
      nextOnboardingStage,
      emptyOptions,
      isAdminInCurrentOrg,
    );
  };

  return (
    <>
      <OnboardingStepTitle title="All done!" />

      <Grow in={!isLoadingSelf}>
        <StyledCardContent>
          <Box m={4}>
            <img
              alt="Tada emoji"
              src={Tada}
              width="50%"
            />
          </Box>

          <Box m={4}>
            <Typography>
              Your user profile {isDirector ? 'and organization are' : 'is'}{' '}
              set up and ready to be used.
            </Typography>

            <Box mt={2}>
              <Typography>
                Questions? Please{' '}
                <StyledLink href="mailto:support@presto-assistant.com">
                  contact us
                </StyledLink>{' '}
                any time. We&apos;re glad to help!
              </Typography>
            </Box>
          </Box>

          <CustomFormButton
            buttonText="Continue to Presto"
            key="onboarding-complete-submit-button"
            labelClasses="buttonText"
            onClick={handleFinishStep}
            size="large"
          />
        </StyledCardContent>
      </Grow>
    </>
  );
};

export default OnboardingComplete;
