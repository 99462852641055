// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { Subtitle } from 'components/shared';

// Local Dependencies
import MyFormsTable from 'pages/Forms/MyForms/All/MyFormsTable';

// Component Definition
const MyForms: FC = () => (
  <>
    <Subtitle>
      My Forms
    </Subtitle>

    <MyFormsTable />
  </>
);

export default MyForms;
