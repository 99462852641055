// External Dependencies
import { FC } from 'react';
import { darken, lighten } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';

// Local Typings
interface Props {
  context: string;
  previewData: GQL.ICSVUploadPreview;
}

// Local Variables
const StyledRoot = styled.section(({ theme }) => ({
  '.badRowErrorCell': {
    whiteSpace: 'pre',
  },
  '.container': {
    maxHeight: theme.spacing(30),
  },
  '.headerCell': {
    backgroundColor: theme.palette.mode === 'dark'
      ? darken(theme.palette.error.light, 0.5)
      : lighten(theme.palette.error.light, 0.7),
  },
  '.paper': {
    width: '100%',
  },
  margin: theme.spacing(2, 0),
}));

// Component Definition
const BadRowTable: FC<Props> = ({
  context,
  previewData: {
    badRows,
    headers,
  },
}) => {
  return (
    <StyledRoot>
      <Paper
        className="paper"
        variant="outlined"
      >
        <TableContainer className="container">
          <Table
            aria-label={`${context} bad row preview table`}
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell
                  classes={{ head: 'headerCell' }}
                  sx={{ whiteSpace: 'pre' }}
                >
                  Row Number
                </TableCell>

                <TableCell
                  classes={{ head: 'headerCell' }}
                >
                  Errors
                </TableCell>

                {headers.map((header: string) => (
                  <TableCell
                    classes={{ head: 'headerCell' }}
                    key={header}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {badRows.map((badRow) => {
                // We want to display the error messages as a bulleted list
                // The `white-space: pre` style allows each error message to be on its own line
                const rawBadErrorMessages = `• ${badRow.errorMessages.join('\n• ')}`;

                return (
                  <TableRow key={badRow.errorMessages[0]}>
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      {badRow.rowNumber}
                    </TableCell>

                    <TableCell
                      className="badRowErrorCell"
                      component="th"
                      scope="row"
                    >
                      {rawBadErrorMessages}
                    </TableCell>

                    {badRow.row.map((cell: any, index: number) => (index === 0 ? (
                      <TableCell
                        component="th"
                        key={cell}
                        scope="row"
                      >
                        {cell}
                      </TableCell>
                    ) : (
                      <TableCell key={cell}>{cell}</TableCell>
                    )))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </StyledRoot>
  );
};

export default BadRowTable;
