// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';

// Local Typings
interface Props {
  confirmButtonAction: () => void;
  isSubmitting: boolean;
  onClose: () => void;
  open: boolean;
}

// Component Definition
const RevokeStripeConfirmationDialog: FC<Props> = ({
  confirmButtonAction,
  isSubmitting,
  onClose,
  open,
}) => (
  <ConfirmationDialog
    confirmButtonAction={confirmButtonAction}
    confirmButtonText="Yes, Revoke"
    declineButtonAction={onClose}
    description="Parents in this organization will no longer be able to make payments online."
    handleClose={onClose}
    isSubmitting={isSubmitting}
    open={open}
    title="Revoke access to Stripe?"
  />
);

export default RevokeStripeConfirmationDialog;
