// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { useGetFormsIndex } from 'gql/queries/form-queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import FormsTableZeroState from './FormsTableZeroState';

// Local Variables
const handleTableClickRow = (id: string) => `/${PATHS.FORMS}/${id}`;

// Component Definition
const FormTable = () => {
  const {
    data,
    isLoading,
  } = useGetFormsIndex();

  const columns = useColumns();

  return (
    <DataGridContainer>
      <TableDataGrid
        addButtonProps={{
          label: 'Form',
          to: `/${PATHS.FORMS_NEW}`,
        }}
        clickRowTo={handleTableClickRow}
        columns={columns}
        components={{
          NoRowsOverlay: FormsTableZeroState,
        }}
        loading={isLoading}
        rows={data}
        tableResource="forms"
        withSearch
      />
    </DataGridContainer>
  );
};

export default FormTable;
