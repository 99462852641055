// External Dependencies
import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
} from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import { UserRoles } from '@presto-assistant/api_types';
import PeopleIcon from '@mui/icons-material/People';
import useResizeObserver from 'use-resize-observer/polyfilled';

// Internal Dependencies
import {
  AddButton, ConfirmRelationships, Subtitle, ZeroState,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { navigate } from '@reach/router';
import { useGetRelationships } from 'gql/queries';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import ChildrenUnderThirteen from './ChildrenUnderThirteen';
import RelationshipListItem from './RelationshipListItem';

// Local Variables
const navigateToRelationshipsNew = () => {
  navigate(`/${PATHS.RELATIONSHIPS}/new`);
};

// Component Definition
const FamilyCard: FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  const { data } = useGetRelationships();
  const { self } = useSelfQuery();

  const isAdult = Boolean(Number(self?.role.id) === UserRoles.Adult);

  const { width = 1 } = useResizeObserver<HTMLElement>({ ref });

  useEffect(() => {
    const locationHash = window.location.hash;

    // If the user was sent here to view the family relationships,
    //  then we focus that card
    if (locationHash === '#settings-family-card' && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref]);

  // Not using optional chaining here because of number operator
  const hasRelationships = !!(data && data.userRelationships.length > 0);
  const relationships = data?.userRelationships.filter((rel) => rel.isConfirmed) ?? [];
  const pendingRelationships = data?.userRelationships.filter((rel) => rel.isPending) ?? [];
  const sentPendingRelationships = pendingRelationships.filter(
    (rel) => rel.sender.id === self?.id,
  );
  const receivedPendingRelationships = pendingRelationships.filter(
    (rel) => rel.sender.id !== self?.id,
  );

  return (
    <Card
      id="settings-family-card"
      ref={ref}
      variant="outlined"
    >
      <CardContent>
        <AddButton
          label="Family"
          onClick={navigateToRelationshipsNew}
        />

        <Box mt={2}>
          {!hasRelationships && (
            <ZeroState
              Icon={PeopleIcon}
              maxTextWidth="80%"
              message="If you have family members in this organization, add them by clicking the button above."
              padding={0}
            />
          )}

          {!!relationships.length && (
            <List>
              {relationships.map((relationship) => (
                <RelationshipListItem
                  key={relationship.id}
                  relationship={relationship}
                />
              ))}
            </List>
          )}

          {!!sentPendingRelationships?.length && (
            <>
              {!!relationships?.length && (
                <Box mb={2}>
                  <Divider />
                </Box>
              )}

              <Subtitle>
                Pending ({sentPendingRelationships.length})
              </Subtitle>

              <List>
                {sentPendingRelationships.map((relationship) => (
                  <RelationshipListItem
                    key={relationship.id}
                    relationship={relationship}
                  />
                ))}
              </List>
            </>
          )}

          {!!receivedPendingRelationships?.length && (
            <>
              {!!(relationships?.length || sentPendingRelationships.length) && (
                <Box my={1}>
                  <Divider />
                </Box>
              )}

              <Subtitle>
                Awaiting Your Response ({receivedPendingRelationships.length})
              </Subtitle>

              <ConfirmRelationships
                relationshipData={receivedPendingRelationships as GQL.IUserRelationship[]}
                width={width}
              />
            </>
          )}

          {isAdult && <ChildrenUnderThirteen />}
        </Box>
      </CardContent>
    </Card>
  );
};

export default FamilyCard;
