// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  SettingsCardWrapper,
  SettingsWrapper,
} from 'components/shared/Settings/styles';

// Local Dependencies
import AdminSettingsDistrictData from './AdminSettingsDistrictData';
import DistrictIntegrationSettings from './DistrictIntegrationSettings';

// Component Definition
const AdminSettings: FC = () => (
  <SettingsWrapper>
    <SettingsCardWrapper>
      <AdminSettingsDistrictData />
    </SettingsCardWrapper>

    <SettingsCardWrapper>
      <DistrictIntegrationSettings />
    </SettingsCardWrapper>
  </SettingsWrapper>
);

export default AdminSettings;
