/* eslint-disable sort-keys */
// External Dependencies
import {
  DataGridPro,
  DataGridProProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridColumnVisibilityModel,
  GridColumns,
  GridEnrichedColDef,
  GridFilterModel,
  GridRowId,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Theme, darken } from '@mui/material/styles';
import { lighten } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import moment from 'moment';
import styled from 'styled-components';

// Internal Dependencies
import { TableResource } from 'state/table/actions';
import { addNotification } from 'state/notifications/actions';
import { navigateSearch } from 'utils/lib/navigate_search';
import { readFromDb, writeToDb } from 'utils/lib/indexed_db';
import { useDenseTable as useDenseTableSelector } from 'state/ui/quickSettingsMenu/selectors';
import { useParsedSearch } from 'hooks/useParsedSearch';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import { IToolbarAction } from '../DataTable/Toolbar';
import { SubscriberAddButtonProps } from '../SubscriberAddButton';
import {
  handleClickRow,
} from '../TableV2/eventHandlers';
import { useUpdateParams } from './hooks';
import EnhancedGridToolbar, { EnhancedGridToolbarProps } from './EnhancedGridToolbar';
import TableDataGridZeroResultsState from './TableDataGridZeroResultsState';

// Local Typings
interface Props {
  addButtonProps?: SubscriberAddButtonProps | null;
  autoHeight?: DataGridProProps['autoHeight'];
  checkboxSelection?: DataGridProProps['checkboxSelection'];
  clickRowTo?: (id: string) => string;
  columns: GridColDef[];
  components?: DataGridProProps['components'];
  componentsProps?: DataGridProProps['componentsProps'];
  experimentalFeatures?: DataGridProProps['experimentalFeatures'];
  getRowHeight?: DataGridProProps['getRowHeight'];
  getRowId?: DataGridProProps['getRowId'];
  hideCheckAll?: boolean;
  hideExport?: boolean;
  hideToolbar?: boolean;
  isRowSelectable?: DataGridProProps['isRowSelectable'];
  leftPinnedColumns?: string[];
  loading?: boolean;
  onFilter?: (rowIds: GridRowId[]) => void;
  onProcessRowUpdateError?: DataGridProProps['onProcessRowUpdateError'];
  onSelectionModelChange?: DataGridProProps['onSelectionModelChange'];
  onUpdateParams?: (updatedQueryParams: object) => void;
  params?: string; // Some "picker" tables send their own params
  persistColumnVisibility?: boolean;
  processRowUpdate?: DataGridProProps['processRowUpdate'];
  rows: any[] | null;
  selectionModel?: DataGridProProps['selectionModel'];
  shouldNavigateOnSearch?: boolean;
  skipLocalDataFromIndexedDb?: boolean;
  tableResource?: TableResource;
  toolbarActions?: IToolbarAction[];
  withEditMode?: boolean;
  withSearch?: boolean;
}
interface StyledRootProps {
  $isEditMode: boolean;
}

// Local Variables
const getEditBackgroundColor = (theme: Theme) =>
  (theme.palette.mode === 'dark'
    ? darken(theme.palette.info.dark, 0.5)
    : lighten(theme.palette.info.light, 0.92));

const StyledRoot = styled.div<StyledRootProps>(({
  $isEditMode,
  theme,
}) => ({
  '.MuiDataGrid-root': {
    '& .MuiDataGrid-cell--editable': {
      // A cell that is editable will be light blue
      backgroundColor: $isEditMode
        ? getEditBackgroundColor(theme)
        : 'initial',
    },

    '& .MuiDataGrid-cell--editing': {
      '& > div': {
        height: '100%',
      },

      '& .MuiInputBase-root': {
        height: '100%',
      },
    },

    '& .MuiDataGrid-cell.bold': {
      fontWeight: 'bold',
    },

    // We change the colors to show the user when an error occurs during editing
    '& .Mui-error': {
      backgroundColor: theme.palette.mode === 'dark'
        ? darken(theme.palette.error.dark, 0.2)
        : lighten(theme.palette.error.light, 0.9),
      color: theme.palette.mode === 'dark'
        ? theme.palette.error.contrastText
        : theme.palette.error.dark,
    },

    border: $isEditMode
      ? `1px solid ${theme.palette.info.main}`
      : 'inherit',
    boxSizing: $isEditMode ? 'border-box' : 'inherit',
  },

  '.tableWrapper': {
    flexGrow: 1,
    height: '100%', // Needed for Safari < v15 to correctly display table data
  },

  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const dataGridFilterKey = 'dataGridFilters';
const dataGridSortKey = 'dataGridSort';

// Component Definition
const TableDataGrid: FC<Props> = ({
  addButtonProps,
  autoHeight,
  checkboxSelection,
  clickRowTo,
  columns,
  components,
  componentsProps,
  experimentalFeatures,
  getRowHeight,
  getRowId,
  hideCheckAll,
  hideExport,
  hideToolbar,
  isRowSelectable,
  loading,
  leftPinnedColumns,
  onFilter,
  onProcessRowUpdateError,
  onSelectionModelChange,
  onUpdateParams = navigateSearch,
  params,
  persistColumnVisibility = true,
  processRowUpdate,
  rows,
  selectionModel,
  shouldNavigateOnSearch,
  skipLocalDataFromIndexedDb = false,
  tableResource,
  toolbarActions,
  withEditMode,
  withSearch,
}) => {
  const [localRows, setLocalRows] = useState(rows);

  const hasLocalRows = Boolean(localRows?.length);

  const { self } = useSelfQuery();

  const setLocalDataFromIndexedDb = useCallback(async (rowData: any[] | null) => {
    const data = tableResource ? await readFromDb({
      encryptionKey: self?.encryptionKey ?? '',
      memberId: self?.id ?? '',
      resource: tableResource,
    }) : null;

    if (data) {
      setLocalRows(data);
    } else if (rowData) {
      setLocalRows(rowData);
    }
  }, [self?.encryptionKey, self?.id, tableResource]);

  const updateIndexedDb = useCallback(async (rowData: any[] | null) => {
    if (rowData && tableResource) {
      await writeToDb({
        data: rowData,
        encryptionKey: self?.encryptionKey ?? '',
        memberId: self?.id ?? '',
        resource: tableResource,
      });
    }

    await setLocalDataFromIndexedDb(rowData);
  }, [self?.encryptionKey, self?.id, setLocalDataFromIndexedDb, tableResource]);

  useEffect(() => {
    if (skipLocalDataFromIndexedDb) {
      setLocalRows(rows);
    } else {
      updateIndexedDb(rows);
    }
  }, [rows, skipLocalDataFromIndexedDb, updateIndexedDb]);

  const dispatch = useDispatch();

  const columnLocalStorageKey = `${tableResource}-columns`;
  const [isEditMode, setIsEditMode] = useState(false);

  // TODO: change selector to use one of the three options standard, comfortable, or compact
  // We can update that once all tables are using the MUI DataGrid
  const useDenseTable = useSelector(useDenseTableSelector);

  const handleToggleEditMode = useCallback(() => {
    setIsEditMode(!isEditMode);
  }, [isEditMode]);

  // We grab the row values and pass them along to the onRowClick callback function
  const handleRowClick = async ({ row }: GridRowParams) => {
    if (!clickRowTo) {
      return undefined;
    }

    const path = clickRowTo(row.id);

    return handleClickRow(path)();
  };

  const { search } = useLocation();

  const searchString = params ?? search;
  const searchParams = useParsedSearch(searchString);

  // We only care about this on mount
  const initialFilters = useMemo(
    () => {
      const dataGridFilters = searchParams[dataGridFilterKey];

      return dataGridFilters ? JSON.parse(dataGridFilters) : undefined;
    },
    // Not including searchParam in the dep array, as we only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // We only care about this on mount
  const initialSearchParam = useMemo(
    () => {
      const searchParam = searchParams.q as string | undefined;

      return searchParam ?? undefined;
    },
    // Not including searchParam in the dep array, as we only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [gridFilterModel, setGridFilterModel] = useState<GridFilterModel>(initialFilters);

  // We only care about this on mount
  const initialSort = useMemo(
    () => {
      const dataGridSort = searchParams[dataGridSortKey];

      return dataGridSort ? JSON.parse(dataGridSort) : undefined;
    },
    // Not including searchParam in the dep array, as we only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleChangeColumnVisibility = useCallback((model: GridColumnVisibilityModel) => {
    if (persistColumnVisibility) {
      localStorage.setItem(columnLocalStorageKey, JSON.stringify(model));
    }
  }, [columnLocalStorageKey, persistColumnVisibility]);

  const initialColumns = useMemo(() => {
    const localStorageValue = localStorage.getItem(columnLocalStorageKey);

    if (localStorageValue) {
      return JSON.parse(localStorageValue);
    }

    return {};
  }, [columnLocalStorageKey]);

  const handleChangeFilters = useCallback((model: GridFilterModel) => {
    const filterModel = encodeURIComponent(JSON.stringify(model));

    setGridFilterModel(model);

    onUpdateParams({
      [dataGridFilterKey]: filterModel,
    });
  }, [onUpdateParams]);

  const handleChangeSort = useCallback((model: GridSortModel) => {
    const sortModel = encodeURIComponent(JSON.stringify(model));

    onUpdateParams({
      [dataGridSortKey]: sortModel,
    });
  }, [onUpdateParams]);

  const withCheckboxSelection = !isEditMode && checkboxSelection;

  const flexColumns = useMemo<GridColumns>(() => {
    const localColumns = columns.map<GridEnrichedColDef>((column) => ({
      flex: 1,
      minWidth: 120,
      // custom sort to allow empty values at end when asc
      sortComparator: (v1, v2) => {
        if (typeof v1 === 'undefined' && typeof v2 === 'undefined') {
          return 0;
        }

        if (!Number.isNaN(Number(v1 || 0)) && !Number.isNaN(Number(v2 || 0))) {
          return Number(v1 ?? Infinity) - Number(v2 ?? Infinity);
        }

        if (typeof v1 === 'string' || typeof v2 === 'string') {
          return (v1 as string || 'zz').localeCompare(v2 as string || 'zzz');
        }

        if (typeof v1 === 'boolean' && typeof v2 === 'boolean') {
          return Number(v1) - Number(v2);
        }

        // TODO:When we add dates to a table later, we need to handle sorting formatted date strings
        if (typeof v1 === 'object' && typeof v2 === 'object' && moment(v1).isValid() && moment(v2).isValid()) {
          return (v1 as Date).getDate() - (v2 as Date).getDate();
        }

        return 1;
      },
      ...column,
    }));

    if (withCheckboxSelection) {
      localColumns.unshift({
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hideable: false,
      });
    }

    return localColumns;
  }, [columns, withCheckboxSelection]);

  // MUI will throw a console error if we don't handle DataGrid edit errors
  const handleProcessRowUpdateError = useCallback((error: Error) => {
    dispatch(addNotification(error.message, 'error'));
  }, [dispatch]);

  useUpdateParams(tableResource, searchString);

  const componentValues = {
    NoResultsOverlay: TableDataGridZeroResultsState,
    ...components,
    ...(!hideToolbar ? {
      Toolbar: EnhancedGridToolbar,
    } : undefined),
  };

  const initialState = useMemo(() => ({
    columns: {
      columnVisibilityModel: initialColumns,
    },
    filter: {
      filterModel: initialFilters,
    },
    pinnedColumns: {
      left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...(leftPinnedColumns ?? [])],
    },
    sorting: {
      sortModel: initialSort,
    },
  }), [
    initialColumns,
    initialFilters,
    initialSort,
    leftPinnedColumns,
  ]);

  const componentsPropsValues = useMemo(() => ({
    toolbar: {
      addButtonProps,
      gridFilterModel,
      hideExport,
      isEditMode,
      isHydrating: loading && hasLocalRows,
      onFilter,
      onToggleEditMode: handleToggleEditMode,
      onUpdateParams,
      rows: localRows,
      showQuickFilter: true,
      toolbarActions,
      search: initialSearchParam,
      shouldNavigateOnSearch,
      withEditMode,
      withSearch,
    } as EnhancedGridToolbarProps,
    ...componentsProps,
  }), [
    addButtonProps,
    componentsProps,
    gridFilterModel,
    handleToggleEditMode,
    hasLocalRows,
    hideExport,
    initialSearchParam,
    isEditMode,
    loading,
    localRows,
    onFilter,
    onUpdateParams,
    shouldNavigateOnSearch,
    toolbarActions,
    withEditMode,
    withSearch,
  ]);

  return (
    <StyledRoot $isEditMode={isEditMode}>
      <div className="tableWrapper">
        <DataGridPro
          autoHeight={hasLocalRows && autoHeight}
          checkboxSelection={withCheckboxSelection}
          columns={flexColumns}
          components={componentValues}
          componentsProps={componentsPropsValues}
          density={useDenseTable ? 'compact' : 'standard'}
          disableSelectionOnClick={isEditMode}
          experimentalFeatures={withEditMode
            ? { newEditingApi: true }
            : experimentalFeatures}
          getRowHeight={getRowHeight}
          getRowId={getRowId}
          hideFooter={!hasLocalRows}
          initialState={initialState}
          isRowSelectable={isRowSelectable}
          loading={loading && !hasLocalRows}
          onColumnVisibilityModelChange={handleChangeColumnVisibility}
          onFilterModelChange={handleChangeFilters}
          onProcessRowUpdateError={onProcessRowUpdateError ?? handleProcessRowUpdateError}
          onRowClick={isEditMode ? undefined : handleRowClick}
          onSelectionModelChange={loading
            ? undefined
            : onSelectionModelChange}
          onSortModelChange={handleChangeSort}
          processRowUpdate={processRowUpdate}
          rowBuffer={isEditMode ? 75 : 10}
          rowThreshold={isEditMode ? 75 : 10}
          rows={localRows ?? []}
          selectionModel={isEditMode || loading ? undefined : selectionModel}
          sx={hideCheckAll ? {
            // inspired by https://github.com/mui/mui-x/issues/1904#issuecomment-862827127
            '& .MuiDataGrid-columnHeaderCheckbox > div': {
              display: 'none',
            },
          } : {}}
        />
      </div>
    </StyledRoot>
  );
};

export default TableDataGrid;
