// External Dependencies
import {
  Box,
  CardContent,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { navigate } from '@reach/router';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { StyledLink } from 'components/shared';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Variables
interface Props {
  currentMode: 'scanner' | 'manual';
}

// Component Definition
const ToggleCheckoutModeCard: FC<Props> = ({ currentMode }) => {
  const parsedSearch = useParsedSearch();

  const {
    userId,
  } = parsedSearch;

  const handleNavigateToManualUniformCheckoutForm = () => {
    navigate(`/${PATHS.UNIFORM_CHECKOUTS}/new?user_id=${userId}`);
  };

  const isScanning = currentMode === 'scanner';

  return (
    <CardContent>
      <Box
        alignItems="baseline"
        display="flex"
      >
        <Typography>Not {isScanning ? 'scanning' : 'entering manually'}?</Typography>
        <Box ml={1}>
          <StyledLink onClick={handleNavigateToManualUniformCheckoutForm}>
            Add {isScanning ? 'manually' : 'with scanner'}
          </StyledLink>
        </Box>
      </Box>
    </CardContent>
  );
};

export default ToggleCheckoutModeCard;
