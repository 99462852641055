// External Dependencies
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { FC } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { createAndUpdateInventoryItemSchema } from '@presto-assistant/api_types/schemas/inventoryItem';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BarChartIcon from '@mui/icons-material/BarChart';
import InfoIcon from '@mui/icons-material/Info';
import PlaceIcon from '@mui/icons-material/Place';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import {
  DynamicFormFields,
  FormActions,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { SYSTEM_ITEM_NUMBER_PREFIX } from 'utils/constants/inventory';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from 'utils/lib/price_conversions';
import { hasPermission, isDistrictAdmin } from 'state/self/selectors';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';
import { open } from 'state/ui/inventoryMissingSerialNumberDialog/actions';
import { tableQueryParams } from 'state/table/selectors';
import { useGetOrganization } from 'gql/queries';

// Local Dependencies
import InventoryBasicInfoFormFields from './InventoryBasicInfoFormFields';
import InventoryItemMissingSerialNumberConfirmDialog from './InventoryItemMissingSerialNumberConfirmDialog';
import InventoryLocationInfoFormFields from './InventoryLocationInfoFormFields';
import InventoryPurchaseInfoFormFields from './InventoryPurchaseInfoFormFields';
import InventoryStatusInfoFormFields from './InventoryStatusInfoFormFields';
import ShowInventoryBasicData from './ShowInventoryBasicData';
import ShowInventoryDeletedData from './ShowInventoryDeletedData';
import ShowInventoryLocationData from './ShowInventoryLocationData';
import ShowInventoryPurchaseData from './ShowInventoryPurchaseData';
import ShowInventoryStatusData from './ShowInventoryStatusData';

// Local Typings
interface Props {
  canUseDynamicFields?: boolean;
  inventoryItem?: GQL.IInventoryItem;
  isAdmin?: boolean;
  isDeleted?: boolean;
  onSubmit?: (values: InventoryPayloadValues | AdminInventoryPayloadValues) => Promise<void>;
  readOnly?: boolean;
  showEdit?: boolean; // Deleted items show page cannot edit yet
}
interface BaseInventoryFormValues {
  accessories?: string;
  brand: string;
  caseNumber?: string;
  categoryId: string;
  combination?: string;
  comments?: string;
  conditionId?: string | null;
  customBarcode?: string;
  label: string;
  location: string;
  lockNumber?: string;
  locker?: string;
  model: string;
  onLoanToOrganizationId: string | null;
  orgTypeId?: string;
  purchaseOrderNumber?: string;
  purchasedAt: Date | null;
  qualityId?: string | null;
  serialNumber?: string | null;
  systemBarcode?: string;
}

export interface InventoryFormValues extends BaseInventoryFormValues {
  currentValue?: string | number;
  purchaseValue?: string | number;
}

export interface AdminInventoryFormValues extends InventoryFormValues {
  organizationId: string;
}

export interface InventoryPayloadValues extends BaseInventoryFormValues {
  currentValueInCents?: number;
  purchaseValueInCents?: number;
}

export interface AdminInventoryPayloadValues extends InventoryPayloadValues {
  organizationId: string;
}

// Local Variables
const useStyles = makeStyles({
  container: {
    width: 512,
  },
});

// Component Definition
const InventoryForm: FC<Props> = ({
  canUseDynamicFields,
  inventoryItem,
  isAdmin,
  isDeleted = false,
  onSubmit,
  readOnly,
  showEdit = true,
}) => {
  const { data } = useGetOrganization({
    skip: isAdmin,
  });

  const organizationId = data?.organization?.id;

  const isDFA = useSelector(isDistrictAdmin);

  const ownsInventoryItem = organizationId === inventoryItem?.organization?.id || isDFA;

  const classes = useStyles();
  const dispatch = useDispatch();
  const canEditInventoryBasicInfo = useSelector(hasPermission('inventory', 'edit'))
    && ownsInventoryItem;
  const canEditInventoryStatusInfo = useSelector(hasPermission('inventoryStatusInfo', 'edit'))
    && ownsInventoryItem;
  const canEditInventoryLocationInfo = useSelector(hasPermission('inventoryLocationInfo', 'edit'))
    && ownsInventoryItem;
  const canEditInventoryPurchaseInfo = useSelector(hasPermission('inventoryPurchaseInfo', 'edit'))
    && ownsInventoryItem;
  const canEditInventoryDynamicFields = useSelector(hasPermission('inventoryDynamicFields', 'edit'))
    && ownsInventoryItem;

  const canEditInventory = canEditInventoryBasicInfo
    || canEditInventoryStatusInfo
    || canEditInventoryLocationInfo
    || canEditInventoryPurchaseInfo
    || canEditInventoryDynamicFields;

  const canEdit = showEdit && (isDFA || canEditInventory);

  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const inventoryParams = useSelector(tableQueryParams('inventoryItems'));

  // For tiny phone screens, we use less padding on the inputs
  const inputSize = isMobileOrTabletScreen ? 'small' : 'medium';

  const handlePressCancelOrBackButton = () => {
    if (isAdmin) {
      navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}`);
    } else {
      navigate(`/${PATHS.INVENTORY}${inventoryParams}`);
    }
  };

  if (readOnly && !inventoryItem) {
    return null;
  }

  const prestoEnhancedSerialNumber = `${SYSTEM_ITEM_NUMBER_PREFIX}-${inventoryItem?.itemNumber}`;

  const initialValues: InventoryFormValues = {
    ...(canUseDynamicFields ? applyDynamicFields(inventoryItem) : {}),
    accessories: inventoryItem?.accessories ?? '',
    brand: inventoryItem?.brand ?? '',
    caseNumber: inventoryItem?.caseNumber ?? '',
    categoryId: inventoryItem?.category?.id ?? '1',
    combination: inventoryItem?.combination ?? '',
    comments: inventoryItem?.comments ?? '',
    conditionId: inventoryItem?.condition?.id ?? '',
    currentValue: inventoryItem?.currentValueInCents
      ? convertCentsToDollars(inventoryItem.currentValueInCents)
      : '',
    customBarcode: inventoryItem?.customBarcode ?? '',
    label: inventoryItem?.label ?? '',
    location: inventoryItem?.location ?? '',
    lockNumber: inventoryItem?.lockNumber ?? '',
    locker: inventoryItem?.locker ?? '',
    model: inventoryItem?.model ?? '',
    onLoanToOrganizationId: inventoryItem?.onLoanToOrganization?.id ?? '',
    orgTypeId: inventoryItem?.organizationType?.id ?? '1',
    purchaseOrderNumber: inventoryItem?.purchaseOrderNumber ?? '',
    purchaseValue: inventoryItem?.purchaseValueInCents
      ? convertCentsToDollars(inventoryItem.purchaseValueInCents)
      : '',
    purchasedAt: inventoryItem?.purchasedAt ?? null,
    qualityId: inventoryItem?.quality?.id ?? '',
    serialNumber: !inventoryItem
      ? ''
      : inventoryItem?.serialNumber || prestoEnhancedSerialNumber,
    systemBarcode: !inventoryItem
      ? ''
      : inventoryItem?.systemBarcode,
  };

  const adminInitialValues: AdminInventoryFormValues = {
    ...initialValues,
    organizationId: inventoryItem?.organization?.id ?? '',
  };

  const handleFormikSubmit = async (
    values: AdminInventoryFormValues | InventoryFormValues,
    formikProps: FormikHelpers<InventoryFormValues | AdminInventoryFormValues>,
  ) => {
    // We pull out the price data to convert
    //  dollars to cents before sending to API
    const {
      currentValue,
      purchaseValue,
      serialNumber: currentSerialNumber,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      systemBarcode, // This value cannot be updated
      ...otherValues
    } = values;

    // If there is no serial number, and the user didn't modify
    //  the serial number field, then we want to send the
    //  original empty serial number to the API
    const isSerialNumberMissing = !inventoryItem?.serialNumber
      && currentSerialNumber === prestoEnhancedSerialNumber;

    const updatedValues = {
      ...otherValues,
      // If the user did not input either "value",
      //  then we avoid sending a 0 to the API
      currentValueInCents: currentValue
        ? convertDollarsToCents(currentValue)
        : undefined,
      onLoanToOrganizationId: (values.onLoanToOrganizationId === '-1' || values.onLoanToOrganizationId === '')
        ? null
        : values.onLoanToOrganizationId,
      organizationTypeId: (values as AdminInventoryFormValues).orgTypeId,
      purchaseValueInCents: purchaseValue
        ? convertDollarsToCents(purchaseValue)
        : undefined,
      serialNumber: isSerialNumberMissing
        ? inventoryItem?.serialNumber
        : currentSerialNumber,
      ...isAdmin && {
        organizationId: (values as AdminInventoryFormValues).organizationId === '-1'
          ? ''
          : (values as AdminInventoryFormValues).organizationId,
      },
    };

    // The API won't like us sending in a value that doesn't exist in the model
    delete (updatedValues as AdminInventoryFormValues).orgTypeId;

    if (isSerialNumberMissing) {
      delete (updatedValues as AdminInventoryFormValues).serialNumber;
    }

    const { setSubmitting } = formikProps;

    await onSubmit?.(updatedValues);

    setSubmitting(false);
  };

  const handlePressSubmitButton = (
    values: InventoryFormValues | AdminInventoryFormValues,
    formikProps: FormikHelpers<InventoryFormValues | AdminInventoryFormValues>,
  ) => {
    const { setSubmitting } = formikProps;

    // We pop up a dialog to tell the user we will add a unique identifier
    //  if they have removed the serial number
    const isSerialNumberRemoved = inventoryItem?.serialNumber
      && !values.serialNumber;

    if (isSerialNumberRemoved) {
      setSubmitting(false);
      dispatch(
        open({
          handleSubmitForm: () => handleFormikSubmit(values, formikProps),
        }),
      );
    } else {
      handleFormikSubmit(values, formikProps);
    }
  };

  return (
    <>
      <Formik<InventoryFormValues | AdminInventoryFormValues>
        initialValues={isAdmin ? adminInitialValues : initialValues}
        onSubmit={handlePressSubmitButton}
        validationSchema={createAndUpdateInventoryItemSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          const isFormTouched = Object.keys(touched).length > 0;

          return (
            <Form onSubmit={handleSubmit}>
              <Grid container>
                <Container
                  className={classes.container}
                  maxWidth={false}
                >
                  {inventoryItem && isDeleted && (
                    <Box mb={2}>
                      <ShowInventoryDeletedData
                        deletedAt={inventoryItem.deletedAt}
                        deletedBy={inventoryItem.deletedBy}
                        deletedNote={inventoryItem.deletedNote}
                      />
                    </Box>
                  )}
                  <Box mb={2}>
                    <ShowCard
                      canEdit={canEdit && canEditInventoryBasicInfo}
                      icon={InfoIcon}
                      readOnly={readOnly}
                      title="Basic Info"
                    >
                      {inventoryItem && (readOnly || !canEditInventoryBasicInfo) ? (
                        <ShowInventoryBasicData
                          inventoryItemData={inventoryItem}
                          isAdmin={isAdmin}
                          prestoEnhancedSerialNumber={prestoEnhancedSerialNumber}
                        />
                      ) : (
                        <InventoryBasicInfoFormFields
                          hasNoSerialNumber={!inventoryItem?.serialNumber}
                          inputSize={inputSize}
                          isAdmin={isAdmin}
                          isNewItem={!inventoryItem}
                          orgTypeId={values?.orgTypeId || '1'}
                        />
                      )}
                    </ShowCard>
                  </Box>

                  <Box mb={2}>
                    <ShowCard
                      canEdit={canEdit && canEditInventoryStatusInfo}
                      icon={BarChartIcon}
                      readOnly={readOnly}
                      title="Status Info"
                    >
                      {inventoryItem && (readOnly || !canEditInventoryStatusInfo) ? (
                        <ShowInventoryStatusData
                          inventoryItemData={inventoryItem}
                        />
                      ) : (
                        <InventoryStatusInfoFormFields
                          inputSize={inputSize}
                        />
                      )}
                    </ShowCard>
                  </Box>
                </Container>

                <Container
                  className={classes.container}
                  maxWidth={false}
                >
                  <Box mb={2}>
                    <ShowCard
                      canEdit={canEdit && canEditInventoryLocationInfo}
                      icon={PlaceIcon}
                      readOnly={readOnly}
                      title="Location Info"
                    >
                      {inventoryItem && (readOnly || !canEditInventoryLocationInfo) ? (
                        <ShowInventoryLocationData
                          inventoryItemData={inventoryItem}
                        />
                      ) : (
                        <InventoryLocationInfoFormFields
                          excludeLoanToOrganizationId={organizationId}
                          inputSize={inputSize}
                        />
                      )}
                    </ShowCard>
                  </Box>
                  <Box mb={2}>
                    <ShowCard
                      canEdit={canEdit && canEditInventoryPurchaseInfo}
                      icon={AttachMoneyIcon}
                      readOnly={readOnly}
                      title="Purchase Info"
                    >
                      {inventoryItem && (readOnly || !canEditInventoryPurchaseInfo) ? (
                        <ShowInventoryPurchaseData
                          inventoryItemData={inventoryItem}
                        />
                      ) : (
                        <InventoryPurchaseInfoFormFields
                          inputSize={inputSize}
                        />
                      )}
                    </ShowCard>
                  </Box>

                  <DynamicFormFields
                    isAdmin={!!isAdmin}
                    item={inventoryItem}
                    organizationTypeId={isAdmin ? values.orgTypeId : undefined}
                    showCardProps={{
                      canEdit: canEdit && canEditInventoryDynamicFields,
                      readOnly: readOnly || !canEditInventoryDynamicFields,
                    }}
                    tableRef="inventory_items"
                  />
                </Container>
              </Grid>

              {!readOnly && (
                <FormActions
                  context="Inventory Item"
                  isEditing={!!inventoryItem}
                  isFormTouched={isFormTouched}
                  isSubmitting={isSubmitting}
                  onPressCancelOrBackButton={handlePressCancelOrBackButton}
                />
              )}
            </Form>
          );
        }}
      </Formik>
      <InventoryItemMissingSerialNumberConfirmDialog isEditing />
    </>
  );
};

export default InventoryForm;
