// External Dependencies
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
} from 'utils';
import { displaySchoolYearString } from 'utils/lib/displaySchoolYearString';
import { useGetFinancialAccounts } from 'gql/queries';
import {
  useMemo,
} from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';

// Local Variables
export const useColumns = ({
  extraColumns,
}: {
  extraColumns: DataGridColDef<GQL.IFinancialItemIndexItem>[];
}) => {
  const {
    data: financialAccountsData,
  } = useGetFinancialAccounts();

  return useMemo(() => {
    const columns: DataGridColDef<GQL.IFinancialItemIndexItem>[] = [
      {
        field: 'label',
        headerName: 'Name',
        width: 160,
      },
      {
        field: 'financialAccountId',
        headerName: 'Account',
        type: 'singleSelect',
        valueGetter: (params) => (params.row as GQL.IFinancialItemIndexItem).financialAccountLabel,
        valueOptions: financialAccountsData?.financialAccounts.map((account) => ({
          label: account.label,
          value: account.id,
        })),
        valueParser: (value) => financialAccountsData?.financialAccounts
          .find((account) => account.id === value)?.label,
        width: 160,
      },
      {
        field: 'priceInCents',
        headerName: 'Price',
        type: 'number',
        valueFormatter: (params) =>
          displayPriceStringFromDollarAmount(params.value),
        valueGetter: (params) =>
          convertCentsToDollars((params.row as GQL.IFinancialItemIndexItem).priceInCents),
        width: 160,
      },
      {
        field: 'isActive',
        headerName: 'Active',
        type: 'boolean',
        width: 160,
      },
      {
        field: 'isOrganizationCoveringStripeFee',
        headerName: 'Cover Stripe Fee',
        type: 'boolean',
        width: 160,
      },
      {
        field: 'canApplyFundraiserCredits',
        headerName: 'Can Apply Fundraiser Credits',
        minWidth: 220,
        type: 'boolean',
        width: 200,
      },
      {
        field: 'schoolYearEnding',
        filterable: false, // We have a custom filter for school year
        headerName: 'School Year',
        minWidth: 100,
        valueFormatter: (params) => displaySchoolYearString(params.value),
        width: 100,
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    extraColumns,
    financialAccountsData,
  ]);
};
