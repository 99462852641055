// External Dependencies
import { FC } from 'react';
import {
  Stepper as MUIStepper,
  Step,
  StepButton,
  Theme,
  darken,
  lighten,
} from '@mui/material';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';

// Local Typings
interface Props {
  activeStep: number;
  maxAvailableStep: number;
  onSetActiveStep: (step: number) => void;
  stepLabels: string[];
}
interface StyledStepRootProps {
  $canAccessStep: boolean;
  $isActive: boolean;
}

// Local Variables
const getStepHoverBackgroundColor = (theme: Theme) =>
  (theme.palette.mode === 'dark'
    ? darken(theme.palette.link, 0.5)
    : lighten(theme.palette.link, 0.5)
  );

const StyledStepperRoot = styled(MUIStepper)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: theme.spacing(1.5),
}));

const StyledStepRoot = styled(Step)<StyledStepRootProps>(({
  $canAccessStep,
  $isActive,
  theme,
}) => ({
  '& .MuiStepButton-root': {
    '&:hover': {
      backgroundColor: $canAccessStep ? getStepHoverBackgroundColor(theme) : 'initial',
      borderRadius: theme.shape.borderRadius,
    },
  },

  '& .MuiStepConnector-line': {
    position: 'relative',
    zIndex: 1000,
  },

  '& .MuiStepLabel-label': {
    fontWeight: $isActive ? theme.typography.fontWeightBold : 'initial',
  },

  '.MuiStepIcon-root': {
    height: '1.2em',
    width: '1.2em',
  },
}));

// Component Definition
const Stepper: FC<Props> = ({
  activeStep,
  maxAvailableStep,
  onSetActiveStep,
  stepLabels,
}) => (
  <EnhancedCard>
    <StyledStepperRoot
      // MUI Stepper expects a zero-based index but we track steps
      //  with positive integers to make it easier to talk about
      activeStep={activeStep ? activeStep - 1 : 1}
      alternativeLabel
    >
      {stepLabels.map((label, index) => {
        const canAccessStep = index <= maxAvailableStep;

        return (
          <StyledStepRoot
            $canAccessStep={canAccessStep}
            $isActive={activeStep === index + 1}
            active={canAccessStep}
            disabled={!canAccessStep}
            key={label}
            onClick={() => {
              if (canAccessStep) {
                onSetActiveStep(index + 1);
              }
            }}
          >
            <StepButton>
              {label}
            </StepButton>
          </StyledStepRoot>
        );
      })}
    </StyledStepperRoot>
  </EnhancedCard>
);

export default Stepper;
