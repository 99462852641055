// External Dependencies
import {
  ApolloClient,
  ApolloLink,
  from,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import apolloLogger from 'apollo-link-logger';

// Internal Dependencies
import {
  clearTokens,
  getToken,
  setTokenInStorage,
  shouldRememberUser,
} from 'utils/cookies';
import { downloadFile } from 'utils/lib/download_file';
import { downloadUrl } from 'utils/lib/download_url';

// Local Dependencies
import { cache } from './cache';
// We cannot destructure package.json import
import packageJson from '../../package.json';

const responseMiddleware = new ApolloLink((operation, forward) =>
  forward(operation).map((res: any) => {
    const context = operation.getContext();
    const { response } = context;
    const tokenValue = response.headers?.get('x-access-token');
    const downloadData = response.headers?.get('x-download');
    const downloadUrlHeader = response.headers?.get('x-download-url');
    const redirectUrlHeader = response.headers?.get('x-redirect-url');

    if (tokenValue) {
      setTokenInStorage(tokenValue);
    }

    if (downloadData) {
      const downloadJson = JSON.parse(downloadData) as GQL.IDownload;
      downloadFile(downloadJson);
    }

    if (downloadUrlHeader) {
      downloadUrl(downloadUrlHeader);
    }

    if (redirectUrlHeader) {
      window.location.href = redirectUrlHeader;
    }

    return res;
  }));

// Maybe we can fix this type casting one day, but we found this solution worked for now
// https://github.com/jaydenseric/apollo-upload-client/issues/221#issuecomment-693126384
const httpLink = (createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
}) as unknown) as ApolloLink;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if ((networkError as any)?.statusCode === 401) {
    clearTokens();
    window.location.href = window.location.origin;
  }

  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => console.log(message));
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookies if it exists
  const token = getToken();
  const rememberMe = shouldRememberUser();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-access-token': token || '',
      'x-app-name': packageJson.name,
      'x-app-version': packageJson.version,
      'x-remember-me': rememberMe,
    },
  };
});

responseMiddleware.concat(httpLink);

const link = process.env.NODE_ENV === 'development'
  ? from([authLink, apolloLogger, errorLink, responseMiddleware, httpLink])
  : from([authLink, errorLink, responseMiddleware, httpLink]);

// We bring in our custom "InMemoryCache" here
const client = new ApolloClient({
  cache,
  connectToDevTools: process.env.NODE_ENV === 'development',
  link,
});

export default client;
