// External Dependencies
import {
  FC, useCallback, useMemo,
} from 'react';
import { Formik } from 'formik';
import { createFinancialCreditSchema } from '@presto-assistant/api_types/schemas/financialCredits';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import {
  Container,
} from 'components/shared';
import {
  convertDollarsToCents,
  getFullName,
} from 'utils';
import { useGetSimpleUser } from 'gql/queries';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Dependencies
import CreditFormFormikForm from './CreditFormFormikForm';

// Local Typings
interface Props {
  isLoading: boolean;
  onSubmit?: (values: GQL.ICreateFinancialCreditInput) => void;
}
export interface CreditFormValues {
  amountInCents: number;
  financialAccountId: string;
  note: GQL.ICreateFinancialCreditInput['note'];
  userId: GQL.ICreateFinancialCreditInput['userId'];
}

// Component Definition
const CreditForm: FC<Props> = ({
  isLoading,
  onSubmit,
}) => {
  const parsedSearch = useParsedSearch();

  const { userId } = parsedSearch;

  const initialValues: CreditFormValues = {
    amountInCents: 0,
    financialAccountId: '',
    note: '',
    userId: userId ?? '',
  };

  // We use this data to display the user's name in the confirmation dialog
  const { data: simpleUserData } = useGetSimpleUser(userId);

  const userName = useMemo(() => getFullName({
    firstName: simpleUserData?.user?.firstName,
    lastName: simpleUserData?.user?.lastName,
    middleName: simpleUserData?.user?.middleName,
  }), [simpleUserData]);

  const handleFormikSubmit = useCallback((
    values: CreditFormValues,
  ) => {
    // User input of amount is in dollars
    const updatedValues = {
      ...values,
      amountInCents: convertDollarsToCents(values.amountInCents),
    };

    // We use `amount` to keep it simple, but the API expects `amountInCents`
    delete (updatedValues as any).amount;

    onSubmit?.(updatedValues);
  }, [onSubmit]);

  return (
    <Container maxWidth={CONTAINER_WIDTH}>
      <Formik<CreditFormValues>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleFormikSubmit}
        validationSchema={createFinancialCreditSchema}
      >
        {({
          errors,
          handleSubmit,
          touched,
          validateForm,
          values: formikValues,
        }) => (
          <CreditFormFormikForm
            errors={errors}
            formikValues={formikValues}
            isSubmitting={isLoading}
            onSubmit={handleSubmit}
            touched={touched}
            userName={userName}
            validateForm={validateForm}
          />
        )}
      </Formik>
    </Container>
  );
};

export default CreditForm;
