// External Dependencies
import { InventoryIndexResponseItem } from '@presto-assistant/api_types/api/v1/inventory';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { currencyFromCentsColumn, dateColumn } from 'utils/lib/tableColumns';
import { displayDynamicFieldCell } from 'components/shared/TableV2';
import { useGetDistrictOrganizations, useGetDynamicFields } from 'gql/queries';

// Local Dependencies
import QuickCheckoutButton from './QuickCheckoutButton';

export const useColumns = ({
  extraColumns,
  onClickQuickCheckout,
}: {
  extraColumns?: DataGridColDef<InventoryIndexResponseItem>[];
  onClickQuickCheckout: (inventoryItemId: string) => void;
}) => {
  const { data: dynamicFieldData } = useGetDynamicFields({
    tableRef: 'inventory_items',
  });

  const {
    data,
  } = useGetDistrictOrganizations();

  return useMemo(() => {
    const organizations = data?.districtOrganizations ?? [];

    const columns: DataGridColDef<InventoryIndexResponseItem>[] = [
      {
        field: 'categoryLabel',
        headerName: 'Category',
        width: 292,
      },
      {
        field: 'label',
        headerName: 'Name',
        width: 160,
      },
      {
        field: 'brand',
        headerName: 'Brand',
        width: 292,
      },
      {
        field: 'model',
        headerName: 'Model',
        width: 292,
      },
      {
        field: 'serialNumber',
        headerName: 'Serial #',
        width: 160,
      },
      {
        field: 'customBarcode',
        headerName: 'Barcode',
        width: 292,
      },
      {
        field: 'conditionLabel',
        headerName: 'Condition',
        width: 160,
      },
      {
        field: 'checkedOutTo',
        headerName: 'Checked Out To',
        minWidth: 200,
        renderCell: (params) => {
          return params.value || (
            <QuickCheckoutButton
              inventoryItemId={params.row.id}
              onClick={onClickQuickCheckout}
            />
          );
        },
        valueGetter: ((params) => {
          // This handles the case where a student does not have an email address
          // ex John Smith () will become John Smith
          return params.row.checkedOutTo?.replaceAll('()', '').trim();
        }),
      },
      ...(organizations.length > 1 ? [{
        field: 'onLoanToOrganizationLabel',
        headerName: 'On Loan To',
        type: 'singleSelect',
        valueOptions: organizations
          .map((o) => ({
            label: o.label,
            value: o.label,
          })) ?? [],
      }] : []) as DataGridColDef<InventoryIndexResponseItem>[],
      {
        field: 'caseNumber',
        headerName: 'Case #',
        width: 160,
      },
      {
        field: 'location',
        headerName: 'Location',
        width: 160,
      },
      {
        field: 'accessories',
        headerName: 'Accessories',
        width: 160,
      },
      {
        field: 'comments',
        headerName: 'Comments',
        width: 160,
      },
      {
        field: 'combination',
        headerName: 'Combination',
        width: 160,
      },
      {
        field: 'locker',
        headerName: 'Locker',
        width: 160,
      },
      {
        field: 'lockNumber',
        headerName: 'Lock Number',
        width: 160,
      },
      {
        field: 'qualityLabel',
        headerName: 'Quality',
        width: 160,
      },
      {
        field: 'itemNumber',
        headerName: 'Item Number',
        width: 160,
      },
      dateColumn({
        field: 'purchasedAt',
        headerName: 'Purchase Date',
      }),
      {
        field: 'purchaseOrderNumber',
        headerName: 'Purchase Order Number',
        width: 160,
      },
      currencyFromCentsColumn({
        field: 'purchaseValueInCents',
        headerName: 'Purchase Value',
      }),
      currencyFromCentsColumn({
        field: 'currentValueInCents',
        headerName: 'Current Value',
      }),
      ...(dynamicFieldData?.dynamicFields ?? [])
        .map<DataGridColDef<InventoryIndexResponseItem>>((field) => ({
          field: field.dynamicFieldRef as keyof InventoryIndexResponseItem,
          headerName: field.label,
          valueGetter: (params) => displayDynamicFieldCell(field, params.row),
        })),
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    data?.districtOrganizations,
    dynamicFieldData,
    extraColumns,
    onClickQuickCheckout,
  ]);
};
