// External Dependencies
import {
  Box,
  Button,
  CardContent,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { navigate } from '@reach/router';
import moment from 'moment';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmCheckInDialog,
  Container,
  DatePickerField,
  EnhancedCard,
  FormActions,
  Subtitle,
  UniformSimpleDataCard,
} from 'components/shared';
import {
  GET_UNIFORM,
  SimpleUniform,
  SimpleUser,
} from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { UniformCheckoutResponse } from 'gql/queries/uniform-checkout-queries';
import { getFullName } from 'utils';
import { useCheckInUniform } from 'gql/mutations';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Variables
const handlePressCancelOrBackButton = () => {
  navigate(`/${PATHS.UNIFORMS}`);
};
const navigateToUniformShow = (id: string) => {
  navigate(`/${PATHS.UNIFORMS}/${id}`);
};

export const StyledStrong = styled.strong({
  fontSize: '1.25rem',
  whiteSpace: 'pre',
});

// Local Typings
interface Props {
  onSubmit?: (values: UniformCheckoutFormValues) => Promise<void>;
  readOnly?: boolean;
  title: string;
  uniform: SimpleUniform;
  uniformCheckout?: UniformCheckoutResponse;
  user: SimpleUser;
}
export interface UniformCheckoutFormValues {
  checkoutDate: string;
  dueDate: string;
  uniformId: string;
  userId: string;
}

// Component Definition
const UniformCheckoutForm: FC<Props> = ({
  onSubmit,
  readOnly,
  title,
  uniform,
  uniformCheckout,
  user,
}) => {
  const {
    isOpen: isCheckingIn,
    toggleIsOpen: toggleIsCheckingIn,
  } = useIsOpen();

  const intialValues: UniformCheckoutFormValues = {
    checkoutDate: uniformCheckout?.checkoutDate ?? moment().toISOString(),
    dueDate: uniformCheckout?.dueDate ?? '',
    uniformId: uniform.id,
    userId: user.id,
  };

  const handleFormikSubmit = async (
    values: UniformCheckoutFormValues,
    formikProps: FormikHelpers<UniformCheckoutFormValues>,
  ) => {
    if (readOnly) {
      return;
    }

    const { setSubmitting } = formikProps;

    await onSubmit?.(values);

    setSubmitting(false);
  };

  const [checkInUniform, {
    loading: isCheckInUniformSubmitting,
  }] = useCheckInUniform(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['uniformsIndex'],
      onCompleted: () => {
        navigateToUniformShow(uniform.id);
      },
      refetchQueries: () => [
        { query: GET_UNIFORM, variables: { id: uniform.id } },
      ],
    },
  );

  const handleCheckInUniform = async () => {
    if (uniformCheckout) {
      await checkInUniform({
        variables: {
          uniformCheckoutId: uniformCheckout.id,
        },
      });
    }
  };

  return (
    <>
      <Container maxWidth={500}>
        <Subtitle>
          {title}
        </Subtitle>

        <EnhancedCard>
          <CardContent>
            <UniformSimpleDataCard simpleUniform={uniform} />
          </CardContent>

          <Box
            display="flex"
            justifyContent="space-between"
            mb={1}
            mt={2}
            mx={3}
          >
            <div>
              <Typography component="span">
                Check{!uniformCheckout ? 'ing' : 'ed'} out to
              </Typography>
              <StyledStrong>
                {' '}{getFullName(user)}
              </StyledStrong>
            </div>

            {uniformCheckout && (
              <Button
                color="primary"
                onClick={toggleIsCheckingIn}
                size="small"
                variant="outlined"
              >
                Check In
              </Button>
            )}
          </Box>

          <Formik<UniformCheckoutFormValues>
            initialValues={intialValues}
            onSubmit={handleFormikSubmit}
          >
            {({
              handleSubmit,
              isSubmitting,
              touched,
            }) => {
              const isFormTouched = Object.keys(touched).length > 0;

              return (
                <Form onSubmit={handleSubmit}>
                  <CardContent>
                    <DatePickerField
                      ariaLabel="Change check out date"
                      readOnly={!!uniformCheckout}
                      label="Check Out"
                      name="checkoutDate"
                      required
                    />
                    <DatePickerField
                      ariaLabel="Change due date"
                      disableFuture={false}
                      label="Due Date"
                      name="dueDate"
                    />
                  </CardContent>

                  {!readOnly && (
                    <FormActions
                      context="Uniform Checkout"
                      isEditing={!!uniformCheckout}
                      isFormTouched={isFormTouched}
                      isSubmitting={isSubmitting}
                      onPressCancelOrBackButton={handlePressCancelOrBackButton}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </EnhancedCard>
      </Container>

      <ConfirmCheckInDialog
        context="uniform"
        isOpen={isCheckingIn}
        isSubmitting={isCheckInUniformSubmitting}
        onClickCancel={toggleIsCheckingIn}
        onClickConfirm={handleCheckInUniform}
      />
    </>
  );
};

export default UniformCheckoutForm;
