// External Dependencies
import { FC } from 'react';
import { useLocation } from '@reach/router';

// Internal Dependencies
import { navigateSearch } from 'utils/lib/navigate_search';
import { parseSearch } from 'utils';
import { useGetOrganizationTypes } from 'gql/queries';
import District from 'components/shared/District';
import FilterButton from 'components/shared/FilterButton';

// Local Typings
export interface IFilters {
  isCheckedOut: boolean | null;
  organizationId: string | null;
  organizationTypeId: number | null;
}
interface Option {
  id: string;
  label: string;
}

// Local Variables
const checkedOutOptions: Option[] = [
  {
    id: 'true',
    label: 'Checked Out',
  },
  {
    id: 'false',
    label: 'Unassigned',
  },
];

// Component Definition
const Filters: FC = () => {
  const { search } = useLocation();

  const parsedSearch = parseSearch(search);

  const localFilters: IFilters = {
    isCheckedOut: parsedSearch.isCheckedOut ?? null,
    organizationId: parsedSearch.organizationId ?? null,
    organizationTypeId: parsedSearch.organizationTypeId ?? null,
  };

  const { data: orgTypeData } = useGetOrganizationTypes();

  const handleClickFilter = (
    key: 'isCheckedOut' | 'organizationId' | 'organizationTypeId',
    val: string | number | null,
  ) => () => {
    localFilters[key] = val as never;
    navigateSearch({
      ...localFilters,
      page: 1,
    });
  };

  const getIsCheckedOutLabel = () => {
    const status = checkedOutOptions
      .find((option) => option.id === localFilters.isCheckedOut?.toString());

    return status?.label ?? 'Checkout Status';
  };

  return (
    <District>
      {({ district }) => {
        const { organizations } = district;
        const selectedOrg = organizations.find((org) => org.id === localFilters.organizationId);
        const selectedOrgType = orgTypeData?.organizationTypes.find(
          (orgType) => Number(orgType.id) === localFilters.organizationTypeId,
        );

        return (
          <div>
            {orgTypeData && (
              <FilterButton
                active={!!localFilters.organizationTypeId}
                buttonChildren={selectedOrgType ? selectedOrgType.label : 'Organization Type'}
                id="organization"
                onClick={selectedOrgType
                  ? handleClickFilter('organizationTypeId', null)
                  : null}
                subButtons={orgTypeData.organizationTypes.map((orgType) => ({
                  onClick: handleClickFilter('organizationTypeId', orgType.id),
                  selected: localFilters.organizationTypeId === Number(orgType.id),
                  text: orgType.label,
                }))}
              />
            )}

            <FilterButton
              active={!!localFilters.organizationId}
              buttonChildren={selectedOrg ? selectedOrg.label : 'Organization'}
              id="organization"
              onClick={selectedOrg
                ? handleClickFilter('organizationId', null)
                : null}
              subButtons={organizations.map((org) => ({
                onClick: handleClickFilter('organizationId', org.id),
                selected: localFilters.organizationId === org.id,
                text: org.label,
              }))}
            />

            <FilterButton
              active={localFilters.isCheckedOut !== null}
              buttonChildren={getIsCheckedOutLabel()}
              id="checkout-status"
              onClick={localFilters.isCheckedOut !== null
                ? handleClickFilter('isCheckedOut', null)
                : null}
              subButtons={checkedOutOptions.map((option) => ({
                onClick: handleClickFilter('isCheckedOut', option.id),
                selected: localFilters.isCheckedOut?.toString() === option.id.toString(),
                text: option.label,
              }))}
            />
          </div>
        );
      }}
    </District>
  );
};

export default Filters;
