// External Dependencies
import { createActionCreator } from 'deox';

// Internal Dependencies
import { LocalStorageItem, setLocalStorageItem } from 'utils/lib/local_storage';
import { MutationFlag } from 'types/api';
import { parseSearch } from 'utils';

// Local Typings
export interface TableSelection {
  ids: string[];
  selectionType: keyof typeof MutationFlag;
}
export type PeoplePickerTableResource =
  | 'peoplePickerChecklistMembers'
  | 'peoplePickerEmail'
  | 'peoplePickerEndOfYearNonReturningStudents'
  | 'peoplePickerFeeForm'
  | 'peoplePickerForm'
  | 'peoplePickerGroups'
  | 'peoplePickerSelectOneUser'
  | 'peoplePickerStatementCard';

export type PaymentPickerTableResource = 'paymentPickerDepositForm';

export type TableResource =
  | 'checklist'
  | 'checklists'
  | 'directorPermissions'
  | 'directors'
  | 'districtAssistants'
  | 'districtDeletedInventoryItems'
  | 'districtInventoryItems'
  | 'districtOrganizationsDirectors'
  | 'districtOrganizationsParentsWithPermissions'
  | 'districtOrganizations'
  | 'districtLibraryItems'
  | 'districtLicenses'
  | 'districtUniforms'
  | 'dynamicFields'
  | 'emailDrafts'
  | 'emails'
  | 'fileDirectories'
  | 'files'
  | 'financialCredits'
  | 'financialDeposits'
  | `financialFees-${number}`
  | 'financialFundraiserCredits'
  | `financialItems-${number}`
  | 'financialItemMemberFees'
  | `financialPayments-${number}`
  | 'financialTransactions'
  | 'forms'
  | 'group'
  | 'groups'
  | 'inactiveUsers'
  | 'inventoryItems'
  | 'libraryItems'
  | 'licenses'
  | 'myFiles'
  | 'myFinancialCredits'
  | 'myFinancialFees'
  | 'myFinancialPayments'
  | 'parents'
  | 'pendingMembers'
  | 'previousMembers'
  | 'returningStudents'
  | 'scheduledSendEmails'
  | 'students'
  | 'suppressedEmails'
  | 'uniforms'
  | PeoplePickerTableResource
  | PaymentPickerTableResource;

// Actions
export const updateIsPaginatedListDataLoaded = createActionCreator(
  'UPDATE_IS_PAGINATED_LIST_DATA_LOADED',
  (resolve) => ({
    isPaginatedListDataLoaded,
  }: {
    isPaginatedListDataLoaded: boolean;
  }) => resolve(isPaginatedListDataLoaded),
);

export const updateTableQueryParams = createActionCreator(
  'UPDATE_TABLE_QUERY_PARAMS',
  (resolve) => ({
    key,
    value = '',
  }: {
    key: TableResource;
    value: string | undefined;
  }) => {
    const parsedQueryParams = parseSearch(value);

    // We want to keep the user's page size in local storage
    if (parsedQueryParams.limit) {
      const localStorageName: LocalStorageItem = `${key}PageSize` as LocalStorageItem;
      setLocalStorageItem(localStorageName, parsedQueryParams.limit.toString());
    }

    return resolve({ [key]: value });
  },
);

export const updateTableSelections = createActionCreator(
  'UPDATE_TABLE_SELECTIONS',
  (resolve) => ({
    key,
    value,
  }: {
    key: TableResource;
    value: TableSelection;
  }) => resolve({ [key]: value }),
);
