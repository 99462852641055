// External Dependencies
import {
  Box,
  Button,
  CardContent,
  Grow,
  LinearProgress,
  Typography,
} from '@mui/material';
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';

// Internal Dependencies
import {
  APP_NAME,
  CONTACT_INFO,
} from 'utils/constants';
import {
  OnboardingContainer,
  OnboardingStepTitle,
  SadCatSvg,
  SaveButton,
  SmileyCatSvg,
  StyledLink,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { parseSearch } from 'utils';
import { verifyEmail, verifyRenew } from 'utils/api';

// Local Typings
interface Props {
  path: string;
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: 12,
  },
  '.bar': {
    borderRadius: theme.shape.borderRadius,
    height: 12,
  },
  '.emailContainer': {
    whiteSpace: 'pre',
  },
  '.linearColorPrimary': {
    backgroundColor: theme.palette.stripeBlue[600],
  },
  '.progress': {
    backgroundColor: theme.palette.stripeBlue[300],
    margin: '48px 24px',
  },
  '.svgContainer': {
    backgroundColor: theme.palette.skewedBackgroundBottom,
    borderRadius: 12,
    margin: '48px auto',
    maxWidth: '60%',
    padding: theme.spacing(2),
  },
  '.text': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
      margin: theme.spacing(1.5),
    },
    margin: '16px 24px',
  },
}));

const redirectToSuccess = () => {
  navigate(`/${PATHS.ONBOARDING}/${PATHS.ONBOARDING_COMPLETE}`);
};

const SUPPORT_EMAIL_HREF = `
  ${CONTACT_INFO.MAILTO_SUPPORT}?subject=Verify%20email%20in%20onboarding%20in%20Presto%20Assistant
`;

// Component Definition
const Verify: FC<Props> = () => {
  const { search } = window.location;
  const parsedSearch = parseSearch(search);

  const {
    activationCode,
    token,
  } = parsedSearch;

  const [isGettingNewLink, setIsGettingNewLink] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [hasInvalidLink, setHasInvalidLink] = useState(false);
  const [hasRenewedLink, setHasRenewedLink] = useState(false);
  const [autoRedirectOnSuccess, setAutoRedirectOnSuccess] = useState(false);

  const handleClickGetNewLink = useCallback(() => {
    setIsGettingNewLink(true);

    const requestNewEmail = async () => {
      try {
        const res = await verifyRenew(token);

        if (res.status >= 400) {
          throw new Error('error while renewing email verification');
        }

        setHasRenewedLink(true);
        setHasError(false);
      } catch (error) {
        setHasInvalidLink(true);
      } finally {
        setIsGettingNewLink(false);
      }
    };

    // We add 2 second delay to give the user a perceived load time
    setTimeout(requestNewEmail, 2000);
  }, [token]);

  // Use the data from the url to determine the state of the content UI
  useEffect(() => {
    const verifyUser = async () => {
      if (activationCode && token) {
        try {
          const res = await verifyEmail({
            activationCode,
            token,
          });

          if (res.status >= 400) {
            throw new Error('error while verifying email');
          }
          setIsLoading(false);
          setAutoRedirectOnSuccess(true);
        } catch (error) {
          setIsLoading(false);
          setHasError(true);
        }
      } else {
        setIsLoading(false);
        setHasInvalidLink(true);
      }
    };

    // We add 3 second delay to give the user a perceived load time
    const verifyTimeout: number = window.setTimeout(() => {
      verifyUser();
    }, 3000);

    return () => clearTimeout(verifyTimeout);
  }, []);

  // If a user has a successful token, we will automatically redirect them
  //  to onboarding after an 8 second delay
  // The user can also press the "Next" button to achieve the same result
  useEffect(() => {
    let autoRedirectTimeout: number;

    if (autoRedirectOnSuccess) {
      autoRedirectTimeout = window.setTimeout(() => {
        redirectToSuccess();
      }, 8000);
    }

    return () => clearTimeout(autoRedirectTimeout);
  }, [autoRedirectOnSuccess]);

  const emailLinkElement = (
    <StyledLink href={SUPPORT_EMAIL_HREF}>{CONTACT_INFO.SUPPORT}</StyledLink>
  );

  const topErrorInvalidContent = (
    <div className="svgContainer">
      <SadCatSvg />
    </div>
  );

  const loadingContent = (
    <Grow in>
      <div>
        <Box width="100%">
          <LinearProgress
            className="progress"
            classes={{
              barColorPrimary: 'linearColorPrimary',
              root: 'bar',
            }}
            color="primary"
            variant="query"
          />
        </Box>

        <Typography className="text">
          {isGettingNewLink ? "We're sending you a new email." : "We're verifying your email address."}
        </Typography>

        <Typography className="text">
          Hang tight, this should only take a few seconds.
        </Typography>
      </div>
    </Grow>
  );

  const successContent = (
    <Grow in>
      <div>
        <div className="svgContainer">
          <SmileyCatSvg />
        </div>

        <Typography className="text">
          Your email address has been verified.
        </Typography>

        <Typography className="text">
          Click Next to continue to set up your {APP_NAME} account.
        </Typography>

        <Button
          color="primary"
          onClick={redirectToSuccess}
          variant="contained"
        >
          Next
        </Button>
      </div>
    </Grow>
  );

  const renewContent = (
    <Grow in>
      <div>
        <div className="svgContainer">
          <SmileyCatSvg />
        </div>
        <Typography className="text">
          Check your email for a new verification link!
        </Typography>
      </div>
    </Grow>
  );

  const expiredContent = (
    <Grow in>
      <div>
        {topErrorInvalidContent}
        <Typography className="text">
          Your email verification link has expired.
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
        >
          <SaveButton
            isSaving={isGettingNewLink}
            onClick={handleClickGetNewLink}
          >
            Get a new link
          </SaveButton>
        </Box>

        <Typography className="text">
          Still need help? Contact us at
          {' '}
          <span className="emailContainer">{emailLinkElement}.</span>
        </Typography>
      </div>
    </Grow>
  );

  const invalidContent = (
    <Grow in>
      <div>
        {topErrorInvalidContent}

        <Typography className="text">
          There was a problem verifying your email address.
        </Typography>

        <Typography className="text">
          Please contact us at <span className="emailContainer">{emailLinkElement}.</span>
        </Typography>
      </div>
    </Grow>
  );

  const title = useMemo(() => {
    if (isLoading) {
      return 'Verifying Email';
    }

    if (isGettingNewLink) {
      return 'Resending Email';
    }

    if (hasInvalidLink) {
      return 'Oh no!';
    }

    if (hasError) {
      return 'Link Expired';
    }

    if (hasRenewedLink) {
      return 'Email Resent';
    }

    return 'Email Verified';
  }, [
    hasError,
    hasInvalidLink,
    hasRenewedLink,
    isGettingNewLink,
    isLoading,
  ]);

  const content = useMemo(() => {
    if (isLoading || isGettingNewLink) {
      return loadingContent;
    }

    if (hasInvalidLink) {
      return invalidContent;
    }

    if (hasError) {
      return expiredContent;
    }

    if (hasRenewedLink) {
      return renewContent;
    }

    return successContent;
  }, [
    expiredContent,
    hasError,
    hasInvalidLink,
    hasRenewedLink,
    invalidContent,
    isGettingNewLink,
    isLoading,
    loadingContent,
    renewContent,
    successContent,
  ]);

  return (
    <OnboardingContainer>
      <OnboardingStepTitle title={title} />

      <StyledCardContent>
        {content}
      </StyledCardContent>
    </OnboardingContainer>
  );
};

export default Verify;
