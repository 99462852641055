// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared';

// Component Definition
const FeedbackAlert: FC = () => (
  <Box mt={2}>
    <EnhancedAlert>
      Please include any steps to reproduce an issue you found. Thanks!
    </EnhancedAlert>
  </Box>
);

export default FeedbackAlert;
