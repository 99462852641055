// External Dependencies
import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import { FC } from 'react';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import styled from 'styled-components';

// Internal Dependencies
import { DashboardCard, ShowPageDataDisplay } from 'components/shared';
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils';
import { useGetFeesOverview, useGetOrganization } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import DownloadBalanceSheetButton from 'components/shared/DownloadBalanceSheetButton';
import SendFinancialStatementDialog from 'components/shared/SendFinancialStatementDialog';

// Local Variables
const StyledStrong = styled.strong({
  fontSize: '2.25rem',
});

// Component Definition
const FinancialOverviewCard: FC = () => {
  const {
    isOpen: isFinancialStatementDialogOpen,
    toggleIsOpen: toggleIsFinancialStatementDialogOpen,
  } = useIsOpen();

  const {
    data: orgData,
    loading: isOrganizationLoading,
  } = useGetOrganization();

  const {
    data,
    loading: areFeesLoading,
  } = useGetFeesOverview(orgData?.organization.currentSchoolYearEnding ?? 0);

  const isLoading = isOrganizationLoading || areFeesLoading;

  // eslint-disable-next-line max-len
  const outstandingFeesFromCurrentYearInCents = data?.financialFeesOverview?.totalOutstandingBalanceInCents ?? 0;
  // eslint-disable-next-line max-len
  const outstandingFeesFromPriorYearsInCents = data?.financialFeesOverview?.previousYearsTotalFeesInCents ?? 0;

  const totalOutstandingFeesInCents = outstandingFeesFromCurrentYearInCents
    + outstandingFeesFromPriorYearsInCents;

  // Some directors don't keep financial records from year to year
  // We can hide the "current year" for those directors
  const hideCurrentYearFees = outstandingFeesFromPriorYearsInCents === 0;

  return (
    <DashboardCard
      content={(
        <>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            marginBottom={2}
          >
            <Typography>
              Total outstanding fees
            </Typography>

            <Typography component="span">
              {isLoading
                ? (
                  <Box mt={1}>
                    <CircularProgress />
                  </Box>
                )
                : (
                  <StyledStrong>
                    {displayPriceStringFromDollarAmount(
                      convertCentsToDollars(totalOutstandingFeesInCents),
                    )}
                  </StyledStrong>
                )}
            </Typography>

            {!hideCurrentYearFees && (
              <ShowPageDataDisplay
                align="center"
                label="Current Year"
                type="currency"
                value={outstandingFeesFromCurrentYearInCents}
              />
            )}
          </Box>

          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap={1.5}
          >
            <DownloadBalanceSheetButton
              size="small"
              variant="outlined"
            />

            <Button
              color="primary"
              disabled={isLoading}
              onClick={toggleIsFinancialStatementDialogOpen}
              size="small"
              startIcon={<EmailIcon />}
              variant="outlined"
            >
              Email statements
            </Button>
          </Box>

          <SendFinancialStatementDialog
            isOpen={isFinancialStatementDialogOpen}
            memberIds={null}
            onClose={toggleIsFinancialStatementDialogOpen}
          />
        </>
      )}
      horizontallyCenterContent
      title="Financial Overview"
    />
  );
};

export default FinancialOverviewCard;
