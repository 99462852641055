// External Dependencies
import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { History, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetUniform } from 'gql/queries';
import UniformForm from 'pages/Uniforms/shared/UniformForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Component Definition
const AdminUniformsShow: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const {
    data,
    error,
    loading,
  } = useGetUniform(id!);

  const districtUniformsParams = useSelector(tableQueryParams('districtUniforms'));

  return (
    <Page
      backButtonProps={{
        label: 'All Uniforms',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}${districtUniformsParams}`,
      }}
      editButtonPath={`/${PATHS.DISTRICT_ADMIN}/${PATHS.UNIFORMS}/${id}/edit`}
      error={error}
      isLoading={!data || loading}
    >
      <UniformForm
        canUseDynamicFields
        uniform={data?.uniform}
        isAdmin
        readOnly
        title="Uniform Details"
      />

      {data?.uniform && (
        <History
          id={data.uniform.id}
          resource="uniforms"
        />
      )}
    </Page>
  );
};

export default AdminUniformsShow;
