// Internal Dependencies
import { DashboardCard } from 'components/shared';
import { FC } from 'react';
import { useGetOrganization } from 'gql/queries';

// Local Dependencies
import Events from './Events';
import GoogleSyncMessage from './GoogleSyncMessage';

// Component Definition
const UpcomingCalendarEvents: FC = () => {
  const { data } = useGetOrganization();

  if (!data) {
    return null;
  }

  const { hasGoogleCalendar } = data.organization;

  const content = hasGoogleCalendar
    ? <Events />
    : <GoogleSyncMessage />;

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent={!hasGoogleCalendar}
      title="Upcoming Events"
      verticallyCenterContent={!hasGoogleCalendar}
    />
  );
};

export default UpcomingCalendarEvents;
