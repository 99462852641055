// External Dependencies
import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { useHasRelationships } from 'hooks/useHasRelationships';

// Local Dependencies
import FinancialCreditsTable from './FinancialCreditsTable';
import LinkToMyCredits from './LinkToMyCredits';

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

// Component Definition
const FinancialCredits: FC = () => {
  // We only show the link to MyCredits if the user has relationships
  const hasRelationships = useHasRelationships();

  return (
    <>
      <Subtitle>
        Financial Credits
      </Subtitle>
      <Box
        mb={1.5}
        ml={2}
      >
        <StyledTypography
          color="textSecondary"
          variant="body2"
        >
          Credits will appear here when members overpay for fees.
        </StyledTypography>
      </Box>

      <Box mb={4}>
        <EnhancedCard>
          <FinancialCreditsTable />
        </EnhancedCard>
      </Box>

      {hasRelationships && <LinkToMyCredits />}
    </>
  );
};

export default FinancialCredits;
