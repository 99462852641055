// External Dependencies
import {
  Autocomplete, Chip, TextField,
} from '@mui/material';
import {
  Genders,
  OrganizationEntityTypes,
} from '@presto-assistant/api_types';
import {
  GridColDef,
  GridFilterInputValueProps,
  GridPreProcessEditCellProps,
} from '@mui/x-data-grid-pro';
import {
  convertCentsToDollarsAllowNull,
  range,
} from '@presto-assistant/api_types/utils';
import {
  createUserSchema,
  updateUserSchema,
} from '@presto-assistant/api_types/schemas/user';
import {
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { SHIRT_SIZE_OPTIONS } from 'utils/constants/user';
import {
  displayCell,
  displayDynamicFieldCell,
} from 'components/shared/TableV2';
import { formatDate, runValidationSchema } from 'utils';
import { hasPermission } from 'state/self/selectors';
import {
  renderEditCell,
  renderMoneyCell,
  renderSelectEditInputCell,
} from 'components/shared/TableDataGrid/helpers';
import {
  useGetDynamicFields,
  useGetGroupsAll,
  useGetOrganization,
  useGetOrganizationRoles,
  useGetPossibleFeederOrganizations,
} from 'gql/queries';
import { useGetGenderOptions } from 'hooks/useGetGenderOptions';
import { useGetStateOptions } from 'hooks/useGetStateOptions';
import { useGradeColDef } from 'components/shared/TableDataGrid/hooks';

// Local Typings
type UpdateSchemaFields = GQL.IUpdateUserInput | GQL.ICreateUserInput;

// Local Variables
export const GroupsInput = (props: GridFilterInputValueProps) => {
  const { applyValue, focusElementRef, item } = props;

  const inputRef = useRef<HTMLInputElement>();

  useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      inputRef.current?.querySelector<HTMLInputElement>('input[id="group-filter"]')?.focus();
    },
  }));

  const handleFilterChange = (_event: any, selectedGroups: GQL.ISimpleGroup[]) => {
    applyValue({ ...item, value: selectedGroups });
  };

  const {
    data: groupsData,
  } = useGetGroupsAll();

  return (
    <Autocomplete
      defaultValue={item.value}
      getOptionLabel={(group: GQL.ISimpleGroup) => group.label}
      id="group-filter"
      multiple
      onChange={handleFilterChange}
      options={groupsData?.groups.data ?? [] as any}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Groups"
          placeholder="Groups"
          size="medium"
          variant="standard"
        />
      )}
      // Match the default styles of chips for datagrid singleSelect
      renderTags={(value: readonly GQL.ISimpleGroup[], getTagProps) =>
        value.map((option: GQL.ISimpleGroup, index: number) => (
          <Chip
            label={option.label}
            size="small"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))}
      size="small"
    />
  );
};

const PrimaryRoleInput = (props: GridFilterInputValueProps) => {
  const { applyValue, focusElementRef, item } = props;

  const inputRef = useRef<HTMLInputElement>();

  useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      inputRef.current?.querySelector<HTMLInputElement>('input[id="primary-role-filter"]')?.focus();
    },
  }));

  const handleFilterChange = (_event: any, selectedPrimaryRoles: GQL.IPrimaryRole[]) => {
    applyValue({ ...item, value: selectedPrimaryRoles });
  };

  const {
    data: rolesData,
  } = useGetOrganizationRoles();

  return (
    <Autocomplete
      defaultValue={item.value}
      getOptionLabel={(role: GQL.IPrimaryRole) => role.label}
      id="primary-role-filter"
      multiple
      onChange={handleFilterChange}
      options={rolesData?.organizationRoles ?? [] as any}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Roles"
          placeholder="Roles"
          size="medium"
          variant="standard"
        />
      )}
      // Match the default styles of chips for datagrid singleSelect
      renderTags={(value: readonly GQL.IPrimaryRole[], getTagProps) =>
        value.map((option: GQL.IPrimaryRole, index: number) => (
          <Chip
            label={option.label}
            size="small"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))}
      size="small"
    />
  );
};

const SecondaryRolesInput = (props: GridFilterInputValueProps) => {
  const { applyValue, focusElementRef, item } = props;

  const inputRef = useRef<HTMLInputElement>();

  useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      inputRef.current?.querySelector<HTMLInputElement>('input[id="secondary-roles-filter"]')?.focus();
    },
  }));

  const handleFilterChange = (_event: any, selectedSecondaryRoles: GQL.ISecondaryRole[]) => {
    applyValue({ ...item, value: selectedSecondaryRoles });
  };

  const {
    data: rolesData,
  } = useGetOrganizationRoles();

  return (
    <Autocomplete
      defaultValue={item.value}
      getOptionLabel={(role: GQL.ISecondaryRole) => role.label}
      id="secondary-roles-filter"
      multiple
      onChange={handleFilterChange}
      options={rolesData?.organizationRoles ?? [] as any}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Roles"
          placeholder="Roles"
          size="medium"
          variant="standard"
        />
      )}
      // Match the default styles of chips for datagrid singleSelect
      renderTags={(value: readonly GQL.ISecondaryRole[], getTagProps) =>
        value.map((option: GQL.ISecondaryRole, index: number) => (
          <Chip
            label={option.label}
            size="small"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))}
      size="small"
    />
  );
};

const generateAdultColumns = (maxAdultIndex: number) => {
  const adultIndexRange = range(0, maxAdultIndex, { excludeUpperBoundary: true });

  const adultColumns: GridColDef<GQL.IStudentIndex>[] = adultIndexRange.flatMap((adultIndex) => [
    {
      editable: false,
      field: `adults[${adultIndex}]?.firstName`,
      headerName: `Adult${adultIndex + 1} First Name`,
      minWidth: 150,
      valueGetter: (params) => params.row.adults[adultIndex]?.firstName,
    },
    {
      editable: false,
      field: `adults[${adultIndex}]?.lastName`,
      headerName: `Adult${adultIndex + 1} Last Name`,
      minWidth: 150,
      valueGetter: (params) => params.row.adults[adultIndex]?.lastName,
    },
    {
      editable: false,
      field: `adults[${adultIndex}]?.email`,
      headerName: `Adult${adultIndex + 1} Email`,
      valueGetter: (params) => params.row.adults[adultIndex]?.email,
    },
    {
      editable: false,
      field: `adults[${adultIndex}]?.phoneNumber`,
      headerName: `Adult${adultIndex + 1} Phone`,
      valueGetter: (params) => params.row.adults[adultIndex]?.phoneNumber,
    },
    {
      editable: false,
      field: `adults[${adultIndex}]?.addr1`,
      headerName: `Adult${adultIndex + 1} Addr 1`,
      valueGetter: (params) => params.row.adults[adultIndex]?.addressOne,
    },
    {
      editable: false,
      field: `adults[${adultIndex}]?.addr2`,
      headerName: `Adult${adultIndex + 1} Addr 2`,
      valueGetter: (params) => params.row.adults[adultIndex]?.addressTwo,
    },
    {
      editable: false,
      field: `adults[${adultIndex}]?.city`,
      headerName: `Adult${adultIndex + 1} City`,
      valueGetter: (params) => params.row.adults[adultIndex]?.city,
    },
    {
      editable: false,
      field: `adults[${adultIndex}]?.zipcode`,
      headerName: `Adult${adultIndex + 1} Zip`,
      valueGetter: (params) => params.row.adults[adultIndex]?.zipcode,
    },
    {
      editable: false,
      field: `adults[${adultIndex}]?.state?.label`,
      headerName: `Adult${adultIndex + 1} State`,
      valueGetter: (params) => params.row.adults[adultIndex]?.state?.label,
    },
    {
      editable: false,
      field: `adults[${adultIndex}]?.relationshipType?.label`,
      headerName: `Adult${adultIndex + 1} Relationship`,
      minWidth: 175,
      valueGetter: (params) => params.row.adults[adultIndex]?.relationshipType?.label,
    },
  ]);

  return adultColumns;
};

export const useColumns = (extraColumns: GridColDef[], maxNumberOfAdults: number) => {
  const {
    data: organizationRoleData,
  } = useGetOrganizationRoles();

  const { data: organizationData } = useGetOrganization();

  // Select options
  const genderOptions = useGetGenderOptions();
  const genderOptionsWithEmptyOption = useMemo(() => [
    {
      id: '',
      label: '',
      value: '',
    },
    ...genderOptions.options,
  ], [genderOptions.options]);

  const gradeCol = useGradeColDef<GQL.IStudentIndex>({
    editable: true,
    field: 'grade',
  });

  const primaryRoleOptionsWithEmptyOption = useMemo(() => [
    {
      id: '',
      label: '',
      value: '',
    },
    ...organizationRoleData?.organizationRoles ?? [],
  ], [organizationRoleData?.organizationRoles]);

  const shirtSizeOptionsWithEmptyOption = useMemo(() => [
    {
      id: '',
      label: '',
      value: '',
    },
    ...SHIRT_SIZE_OPTIONS,
  ], []);

  const stateOptions = useGetStateOptions();
  const mappedStateOptions = stateOptions?.options.map((option) => ({
    id: option.id.toString(),
    label: option.label,
    value: option.label,
  }));

  const {
    data: possibleFeederOrganizationData,
  } = useGetPossibleFeederOrganizations();

  const { data: dynamicFieldData } = useGetDynamicFields({
    tableRef: 'members',
  });

  const preProcessEditCellProps = async (
    params: GridPreProcessEditCellProps,
    fieldName: keyof UpdateSchemaFields,
  ) => {
    let value = params.props.value!.toString();

    // The studentInfo schema expects an object that contains studentId
    if (fieldName === 'studentInfo') {
      value = {
        studentId: params.props.value!.toString(),
      };
    }

    const schema = (['email', 'firstName', 'lastName'] as (keyof UpdateSchemaFields)[]).includes(fieldName)
      ? createUserSchema
      : updateUserSchema;

    // Validate like Formik does
    const errorMessage: any = await runValidationSchema<UpdateSchemaFields>(
      value,
      fieldName,
      schema as any,
    );

    return {
      ...params.props,
      error: errorMessage[fieldName as keyof UpdateSchemaFields],
    };
  };

  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canReadPayments = useSelector(hasPermission('payments', 'read'));

  return useMemo(() => {
    const isCollegeOrUniversity = organizationData?.organization
      .entityType.id === OrganizationEntityTypes.College.toString();

    const hasSuccessorOrganization = !!organizationData?.organization.successorOrganization;

    const adultColumns: GridColDef<GQL.IStudentIndex>[] = [
      ...generateAdultColumns(maxNumberOfAdults),
    ];

    const readFinancesColumns: GridColDef[] = [
      {
        align: 'right',
        field: 'creditsTotalInCents',
        headerName: 'Credit Available *',
        minWidth: 130,
        // Convert back to cents to pass to displayCell correctly
        renderCell: (params) => renderMoneyCell(params.value),
        type: 'number',
        // Convert to dollars for intuitive filtering
        valueGetter: (params) => displayCell(convertCentsToDollarsAllowNull(params.value)),
      },
      {
        align: 'right',
        field: 'feesAssignedInCents',
        headerName: 'Fees Assigned (YTD) *',
        minWidth: 170,
        renderCell: (params) => renderMoneyCell(params.value),
        type: 'number',
        valueGetter: (params) => displayCell(convertCentsToDollarsAllowNull(params.value)),
      },
    ];

    const readPaymentsColumns: GridColDef[] = [
      {
        align: 'right',
        field: 'paymentsTotalInCents',
        headerName: 'Fees Paid (YTD) *',
        minWidth: 150,
        renderCell: (params) => renderMoneyCell(params.value),
        type: 'number',
        valueGetter: (params) => displayCell(convertCentsToDollarsAllowNull(params.value)),
      },
      {
        align: 'right',
        field: 'balanceDueInCents',
        headerName: 'Fees Due (YTD) *',
        minWidth: 150,
        renderCell: (params) => renderMoneyCell(params.value),
        type: 'number',
        valueGetter: (params) => displayCell(convertCentsToDollarsAllowNull(params.value)),
      },
      {
        align: 'right',
        field: 'previousYearsBalanceDueInCents',
        headerName: 'Fees Due (Previous Years) *',
        minWidth: 200,
        renderCell: (params) => renderMoneyCell(params.value),
        type: 'number',
        valueGetter: (params) => displayCell(convertCentsToDollarsAllowNull(params.value)),
      },
    ];

    const columns: GridColDef[] = [
      {
        editable: true,
        field: 'firstName',
        headerName: 'First Name',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'firstName',
        ),
        renderEditCell,
        width: 160,
      },
      {
        editable: true,
        field: 'lastName',
        headerName: 'Last Name',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'lastName',
        ),
        renderEditCell,
        width: 160,
      },
      {
        editable: true,
        field: 'middleName',
        headerName: 'Middle Name',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'middleName',
        ),
        renderEditCell,
        width: 160,
      },
      {
        editable: true,
        field: 'email',
        headerName: 'Email',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'email',
        ),
        renderEditCell,
        width: 292,
      },
      ...(canReadFinances ? readFinancesColumns : []),
      ...(canReadPayments ? readPaymentsColumns : []),
      {
        editable: true,
        field: 'phoneNumber',
        headerName: 'Phone Number',
        width: 292,
      },
      {
        editable: true,
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        type: 'date',
        valueFormatter: (params) => formatDate(params.value),
        width: 248,
      },
      {
        editable: true,
        field: 'gender',
        headerName: 'Gender',
        renderEditCell: (params) => renderSelectEditInputCell({
          options: genderOptionsWithEmptyOption,
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => (params.row.gender?.id === Genders.Other.toString()
          ? `Other ${params.row.otherGenderLabel
            ? ` - ${params.row.otherGenderLabel}`
            : ''}`
          : params.row.gender?.label),
        valueOptions: genderOptions.options,
        valueSetter: (params) => ({
          ...params.row,
          gender: genderOptions.options.find((option) => option.value === params.value),
        }),
      },
      { ...gradeCol },
      {
        editable: true,
        field: 'shirtSize',
        headerName: 'Shirt Size',
        renderEditCell: (params) => renderSelectEditInputCell({
          options: shirtSizeOptionsWithEmptyOption,
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => (params.row.shirtSize) ?? '',
        valueOptions: SHIRT_SIZE_OPTIONS.map((shirtSize) => ({
          label: shirtSize.label,
          value: shirtSize.id,
        })),
        width: 80,
      },
      {
        editable: true,
        field: 'primaryRole',
        filterOperators: [
          {
            InputComponent: PrimaryRoleInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const primaryRoleId = (params.row as GQL.IStudentIndex).primaryRole?.id;

                if (!primaryRoleId) {
                  return false;
                }

                const selectedPrimaryRoles: GQL.IPrimaryRole[] = filterItem.value;
                const selectedPrimaryRoleIds = selectedPrimaryRoles.map((g) => Number(g.id));

                if (!selectedPrimaryRoleIds.length) {
                  return false;
                }

                return selectedPrimaryRoleIds.includes(primaryRoleId);
              };
            },
            label: 'is any of',
            value: 'isAnyOf',
          },
          {
            InputComponent: PrimaryRoleInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const primaryRoleId = (params.row as GQL.IStudentIndex).primaryRole?.id;

                if (!primaryRoleId) {
                  return true;
                }

                const selectedPrimaryRoles: GQL.IPrimaryRole[] = filterItem.value;
                const selectedPrimaryRoleIds = selectedPrimaryRoles.map((g) => Number(g.id));

                if (!selectedPrimaryRoleIds.length) {
                  return false;
                }

                return !selectedPrimaryRoleIds.includes(primaryRoleId);
              };
            },
            label: 'is not in',
            value: 'isNotIn',
          },
        ],
        headerName: 'Primary Role', // TODO: make this say "instrument" for band, orch; "part" for choir; "role" for theater, dance
        renderEditCell: (params) => renderSelectEditInputCell({
          options: primaryRoleOptionsWithEmptyOption,
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => (params.row as GQL.IStudentIndex).primaryRole?.label,
        valueOptions: organizationRoleData?.organizationRoles.map((role) => ({
          label: role.label,
          value: role.label,
        })),
        valueSetter: (params) => ({
          ...params.row,
          primaryRole: organizationRoleData?.organizationRoles.find((role) =>
            role.label === params.value),
        }),
      },
      {
        field: 'secondaryRoles',
        filterOperators: [
          {
            InputComponent: SecondaryRolesInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const secondaryRoleIds = (params.row as GQL.IStudentIndex)
                  .secondaryRoles?.map((role) => role.id);

                const selectedRoles: GQL.ISecondaryRole[] = filterItem.value;
                const selectedRoleIds = selectedRoles.map((role) => Number(role.id));

                if (!selectedRoles.length) {
                  return true;
                }

                return secondaryRoleIds.some((id) => selectedRoleIds.includes(id));
              };
            },
            label: 'is any of',
            value: 'isAnyOf',
          },
          {
            InputComponent: SecondaryRolesInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const secondaryRoleIds = (params.row as GQL.IStudentIndex)
                  .secondaryRoles?.map((role) => role.id);

                const selectedRoles: GQL.ISecondaryRole[] = filterItem.value;
                const selectedRoleIds = selectedRoles.map((role) => Number(role.id));

                if (!selectedRoles.length) {
                  return true;
                }

                return selectedRoleIds.every((id) => secondaryRoleIds.includes(id));
              };
            },
            label: 'is all of',
            value: 'isAllOf',
          },
          {
            InputComponent: SecondaryRolesInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const secondaryRoleIds = (params.row as GQL.IStudentIndex)
                  .secondaryRoles?.map((role) => role.id);

                const selectedRoles: GQL.ISecondaryRole[] = filterItem.value;
                const selectedRoleIds = selectedRoles.map((role) => Number(role.id));

                if (!selectedRoles.length) {
                  return true;
                }

                return selectedRoleIds.every((id) => !secondaryRoleIds.includes(id));
              };
            },
            label: 'is not in',
            value: 'isNotIn',
          },
        ],
        headerName: 'Secondary Roles', // TODO: make this say "instrument" for band, orch; "part" for choir; "role" for theater, dance
        valueGetter: (params) => (params.row as GQL.IStudentIndex).secondaryRoles
          .map((secondaryRole) => secondaryRole.label).join('; '),
        valueOptions: organizationRoleData?.organizationRoles.map((role) => ({
          label: role.label,
          value: role.label,
        })),
      },
      {
        editable: true,
        field: 'studentId',
        headerName: 'Student ID',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'studentInfo',
        ),
        renderEditCell,
      },
      {
        editable: true,
        field: 'isEligible',
        headerName: 'Is Eligible',
        type: 'boolean',
      },
      {
        editable: false,
        field: 'primarySpokenLanguage.id',
        headerName: 'Primary Spoken Language',
        // doing optional chaining here due to indexed db cache
        valueGetter: (params) => params.row.primarySpokenLanguage?.label,
      },
      {
        editable: false,
        field: 'loggedInAt',
        headerName: 'Last Logged In',
        renderCell: (params) => displayCell(params.value, { format: 'datetime' }),
        type: 'dateTime',
      },
      {
        field: 'groups',
        filterOperators: [
          {
            InputComponent: GroupsInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const groupIds = (params.row as GQL.IStudentIndex).groups.map((g) => g.id);

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroups.length) {
                  return true;
                }

                return groupIds.some((id) => selectedGroupIds.includes(id));
              };
            },
            label: 'is any of',
            value: 'isAnyOf',
          },
          {
            InputComponent: GroupsInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const groupIds = (params.row as GQL.IStudentIndex).groups.map((g) => g.id);

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroupIds.length) {
                  return true;
                }

                return selectedGroupIds.every((id) => groupIds.includes(id));
              };
            },
            label: 'is all of',
            value: 'isAllOf',
          },
          {
            InputComponent: GroupsInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const groupIds = (params.row as GQL.IStudentIndex).groups.map((g) => g.id);

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroupIds.length) {
                  return true;
                }

                return selectedGroupIds.every((id) => !groupIds.includes(id));
              };
            },
            label: 'is not in',
            value: 'isNotIn',
          },
        ],
        headerName: 'Groups',
        valueGetter: (params) => (params.row as GQL.IStudentIndex).groups
          .map((group) => group.label).join('; '),
      },
      ...(!isCollegeOrUniversity && hasSuccessorOrganization ? [{
        editable: true,
        field: 'successorOrganization',
        headerName: 'Successor Organization',
        renderEditCell: (params) => renderSelectEditInputCell({
          options: possibleFeederOrganizationData?.possibleFeederOrganizations,
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => ((params.row as GQL.IStudentIndex)
          .successorOrganization?.label)
            ?? organizationData?.organization.successorOrganization?.label,
        valueOptions: possibleFeederOrganizationData?.possibleFeederOrganizations
          .map((possibleOrganization) => ({
            label: possibleOrganization.label,
            value: possibleOrganization.label,
          })) ?? [],
        valueSetter: (params) => ({
          ...params.row,
          successorOrganization: (params.row as GQL.IStudentIndex)
            .successorOrganization?.label
            ?? possibleFeederOrganizationData?.possibleFeederOrganizations
              .find((possibleOrganization) =>
                possibleOrganization.label === params.value),
        }),
        width: 200,
      } as GridColDef] : []),
      {
        editable: true,
        field: 'addressOne',
        headerName: 'Address 1',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'addressOne',
        ),
        renderEditCell,
      },
      {
        editable: true,
        field: 'addressTwo',
        headerName: 'Address 2',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'addressTwo',
        ),
        renderEditCell,
      },
      {
        editable: true,
        field: 'city',
        headerName: 'City',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'city',
        ),
        renderEditCell,
      },
      {
        editable: true,
        field: 'state',
        headerName: 'State',
        renderEditCell: (params) => renderSelectEditInputCell({
          options: mappedStateOptions,
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => (params.row as GQL.IStudentIndex).state?.label
          ?? '',
        valueOptions: mappedStateOptions,
        valueSetter: (params) => ({
          ...params.row,
          state: {
            id: mappedStateOptions?.find((option) => option.label === params.value)?.id ?? '',
            label: params.value,
          },
        }),
      },
      {
        editable: true,
        field: 'zipcode',
        headerName: 'Zip Code',
      },
      {
        editable: true,
        field: 'allergies',
        headerName: 'Allergies',
      },
      ...adultColumns,
      ...(dynamicFieldData?.dynamicFields ?? [])
        .map<GridColDef<GQL.IUniformIndexItem>>((field) => ({
          field: field.dynamicFieldRef,
          headerName: field.label,
          valueGetter: (params) => displayDynamicFieldCell(field, params.row),
        })),
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    canReadFinances,
    canReadPayments,
    dynamicFieldData?.dynamicFields,
    extraColumns,
    genderOptions.options,
    genderOptionsWithEmptyOption,
    gradeCol,
    mappedStateOptions,
    maxNumberOfAdults,
    organizationData?.organization.entityType.id,
    organizationData?.organization.successorOrganization,
    organizationRoleData?.organizationRoles,
    possibleFeederOrganizationData?.possibleFeederOrganizations,
    primaryRoleOptionsWithEmptyOption,
    shirtSizeOptionsWithEmptyOption,
  ]);
};
