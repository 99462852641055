// External Dependencies
import { FC } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { applyDynamicFields } from 'utils/lib/applyDynamicFields';
import { useGetInventoryItem } from 'gql/queries';
import { useUpdateInventoryItem } from 'gql/mutations';
import useCanUseDynamicFields from 'hooks/useCanUseDynamicFields';

// Local Dependencies
import InventoryForm, { InventoryPayloadValues } from '../shared/InventoryForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Local Variables
const navigateToShow = (id: string) => {
  navigate(`/${PATHS.INVENTORY}/${id}`);
};

// Component Definition
const InventoryEdit: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const canUseDynamicFields = useCanUseDynamicFields();

  const {
    data,
    error,
    loading,
  } = useGetInventoryItem(id!);

  const [handleUpdateInventoryItem] = useUpdateInventoryItem(
    {
      clearCachePredicates: ['inventoryItemsIndex'],
      onCompleted: () => navigateToShow(id!),
    },
  );

  const handleSubmit = async (values: InventoryPayloadValues) => {
    await handleUpdateInventoryItem({
      variables: {
        id: id!,
        input: {
          ...values,
          onLoanToOrganizationId: (values.onLoanToOrganizationId === '-1' || values.onLoanToOrganizationId === '')
            ? null
            : values.onLoanToOrganizationId,
          ...(canUseDynamicFields
            ? applyDynamicFields(values as unknown as DynamicFields)
            : {}),
        },
      },
    });
  };

  return (
    <Page
      backButtonProps={{
        label: 'Inventory Item Detail',
        path: `/${PATHS.INVENTORY}/${id}`,
      }}
      error={error}
      isLoading={!data || loading}
    >
      <InventoryForm
        canUseDynamicFields={canUseDynamicFields}
        inventoryItem={data?.inventoryItem}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

export default InventoryEdit;
