// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';
import { displaySchoolYearString } from 'utils/lib/displaySchoolYearString';
import GridWrapper from 'pages/Forms/components/DetailsCard/GridWrapper';

// Local Typings
interface Props {
  form: GQL.IForm;
}

// Component Definition
const ReadOnlyFields: FC<Props> = ({
  form,
}) => {
  return (
    <GridWrapper>
      <ShowPageDataDisplay
        label="Title"
        value={form?.title}
      />

      <ShowPageDataDisplay
        label="School Year"
        value={form?.schoolYearEnding
          ? displaySchoolYearString(form.schoolYearEnding) : null}
      />
    </GridWrapper>
  );
};

export default ReadOnlyFields;
