// External Dependencies
import { InventoryIndexResponseItem, InventoryItemsResponse } from '@presto-assistant/api_types/api/v1/inventory';
import { getOperationName } from '@apollo/client/utilities';
import { navigate } from '@reach/router';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import TieIcon from 'mdi-material-ui/Tie';

// Internal Dependencies
import {
  ConfirmationDialog,
  DialogPermissionRequired,
  TableDataGrid,
} from 'components/shared';
import {
  DELETE_INVENTORY_ITEM,
  useCheckInInventoryItem,
} from 'gql/mutations';
import { DataGridColDef } from 'types/dataGrid';
import {
  GET_INVENTORY_ITEMS_INDEX,
  useGetInventoryItem,
} from 'gql/queries';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { apiClient } from 'utils/apiClient';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import { open } from 'state/ui/inventoryFileUploadDialog/actions';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useInfinitePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialog from 'components/shared/DeleteDialog';
import DialogInventoryFileUpload from 'components/shared/DialogInventoryFileUpload';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Dependencies
import { useColumns } from './hooks';
import QuickCheckoutDialog from './QuickCheckoutDialog';

// Local Variables
const handleNavigateToInventoryCheckout = (row: InventoryIndexResponseItem) => {
  navigate(`/${PATHS.INVENTORY_CHECKOUTS}/new?inventoryItem_id=${row.id}`);
};
const handleTableClickRow = (id: string) => `/${PATHS.INVENTORY}/${id}`;

// Component Definition
const InventoryTable = () => {
  const [checkInInventoryItemId, setCheckInInventoryItemId] = useState<string | null>(null);
  const [deleteInventoryItemId, setDeleteInventoryItemId] = useState<string | null>(null);
  const [
    quickCheckoutInventoryItemId,
    setQuickCheckoutInventoryItemId,
  ] = useState<string | null>(null);

  const handleCloseQuickCheckoutDialog = useCallback(() => {
    setQuickCheckoutInventoryItemId(null);
  }, []);

  const {
    isOpen: isDeletePermissionDialogOpen,
    toggleIsOpen: toggleIsDeletePermissionDialogOpen,
  } = useIsOpen();

  const dispatch = useDispatch();

  const canCreateInventoryCheckouts = useSelector(hasPermission('inventoryCheckouts', 'write'));
  const canEditInventoryCheckouts = useSelector(hasPermission('inventoryCheckouts', 'edit'));
  const canCreateInventory = useSelector(hasPermission('inventory', 'write'));
  const canDeleteInventory = useSelector(hasPermission('inventory', 'delete'));

  const {
    data: inventoryCheckinItemData,
  } = useGetInventoryItem(checkInInventoryItemId);

  const inventoryItemToCheckIn = inventoryCheckinItemData?.inventoryItem;

  const fetchData = useCallback(async (queryParams: {
    limit: number;
    page: number;
  }) => {
    return apiClient.v1.inventory.index(queryParams);
  }, []);

  const {
    data,
    fullCount,
    isLoading,
  } = useInfinitePaginatedListQuery<InventoryItemsResponse, InventoryIndexResponseItem>({
    dataSelector: (res) => res?.data,
    fullCountSelector: (res) => res?.fullCount,
    pageSize: 500,
    queryKey: 'inventoryItemsIndex',
    request: fetchData,
  });

  const [checkInInventoryItem, {
    loading: isCheckingInInventoryItem,
  }] = useCheckInInventoryItem({
    onCompleted: () => {
      setCheckInInventoryItemId(null);

      dispatch(
        addNotification('Inventory item checked in!', 'success'),
      );

      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    },
  });

  const handleOpenDialogInventoryFileUpload = useCallback(() => {
    dispatch(open());
  }, [dispatch]);

  const handleQuickCheckIn = useCallback((row: InventoryIndexResponseItem) => {
    setCheckInInventoryItemId(row.id);
  }, []);

  const handleConfirmCheckIn = useCallback(async () => {
    if (!inventoryItemToCheckIn) {
      return;
    }

    const activeCheckouts = inventoryItemToCheckIn.checkouts.filter((checkout) => checkout.active);

    if (!activeCheckouts.length) {
      dispatch(
        addNotification('Inventory item is not checked out!', 'error'),
      );
    }

    if (activeCheckouts.length > 1) {
      dispatch(
        addNotification('This item has multiple checkouts. Please check it in from the checkout page.', 'error'),
      );

      return;
    }

    checkInInventoryItem({
      variables: {
        inventoryCheckoutId: activeCheckouts[0].id,
      },
    });
  }, [
    checkInInventoryItem,
    dispatch,
    inventoryItemToCheckIn,
  ]);

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [];

    if (canCreateInventory) {
      actions.push({
        action: handleOpenDialogInventoryFileUpload,
        icon: <CloudUploadIcon />,
        // TODO: Update this to the new way the API tells us about active
        // isDisabled: !self?.currentOrgActive,
        text: 'Import Inventory',
      });
    }

    return actions;
  }, [
    handleOpenDialogInventoryFileUpload,
    canCreateInventory,
  ]);

  const handleDeleteInventoryItem = useCallback((row: InventoryIndexResponseItem) => {
    if (!canDeleteInventory) {
      toggleIsDeletePermissionDialogOpen();
    } else {
      setDeleteInventoryItemId(row.id);
    }
  }, [canDeleteInventory, toggleIsDeletePermissionDialogOpen]);

  const handleCloseDeleteDialog = useCallback(() => {
    setDeleteInventoryItemId(null);
  }, []);

  const handleCloseCheckInDialog = useCallback(() => {
    setCheckInInventoryItemId(null);
  }, []);

  const extraColumns = useMemo<DataGridColDef<InventoryIndexResponseItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<InventoryIndexResponseItem>([
        ...(canCreateInventoryCheckouts ? [{
          action: handleNavigateToInventoryCheckout,
          icon: <TieIcon />,
          text: 'Add inventory checkout',
        }] : []),
        ...(canEditInventoryCheckouts ? [{
          action: handleQuickCheckIn,
          icon: <RemoveIcon />,
          isDisabled: (row: InventoryIndexResponseItem) => !row.checkedOutTo,
          text: 'Quick check-in',
        }] : []),
        {
          action: handleDeleteInventoryItem,
          icon: <DeleteIcon />,
          text: 'Delete inventory item',
        },
      ]);

      return (actionsColumn ? [actionsColumn] : []) as DataGridColDef<InventoryIndexResponseItem>[];
    },
    [
      canCreateInventoryCheckouts,
      canEditInventoryCheckouts,
      handleQuickCheckIn,
      handleDeleteInventoryItem,
    ],
  );

  const columnsArgs = useMemo(() => ({
    extraColumns,
    onClickQuickCheckout: setQuickCheckoutInventoryItemId,
  }), [extraColumns]);

  const columns = useColumns(columnsArgs);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={canCreateInventory ? {
            label: 'Inventory',
            to: `/${PATHS.INVENTORY}/new`,
          } : null}
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          loading={isLoading}
          rows={data}
          tableResource="inventoryItems"
          toolbarActions={toolbarActions}
          withSearch
        />
      </DataGridContainer>

      <DialogInventoryFileUpload />

      <DialogPermissionRequired
        isOpen={isDeletePermissionDialogOpen}
        onClose={toggleIsDeletePermissionDialogOpen}
      />

      <DeleteDialog
        clearCachePredicates={['inventory']}
        context={['inventory']}
        isOpen={Boolean(deleteInventoryItemId)}
        mutation={DELETE_INVENTORY_ITEM}
        onClose={handleCloseDeleteDialog}
        reduxTableKey="inventoryItems"
        refetchQueries={() => [getOperationName(GET_INVENTORY_ITEMS_INDEX) as string]}
        singleItemId={deleteInventoryItemId}
        size={fullCount}
        withNote
      />

      <ConfirmationDialog
        confirmButtonAction={handleConfirmCheckIn}
        confirmButtonText="Yes, check in"
        declineButtonAction={handleCloseCheckInDialog}
        description="Are you sure you want to check in this inventory item?"
        handleClose={handleCloseCheckInDialog}
        isSubmitting={isCheckingInInventoryItem}
        open={Boolean(checkInInventoryItemId)}
        title="Check in inventory item"
      />

      <QuickCheckoutDialog
        inventoryItemId={quickCheckoutInventoryItemId}
        onClose={handleCloseQuickCheckoutDialog}
      />
    </>
  );
};

export default InventoryTable;
