// External Dependencies
import { Box, Typography } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  payload: GQL.IUpdateFinancialCreditInput | null;
}

// Component Definition
const ConfirmationDialogContent: FC<Props> = ({
  payload,
}) => (
  <>
    <Box mb={2}>
      <Typography color="textSecondary">
        Please review the following changes.
      </Typography>
    </Box>

    <ShowPageDataDisplay
      label="Amount"
      type="currency"
      value={payload?.amountInCents}
    />

    <ShowPageDataDisplay
      label="Note"
      value={payload?.note}
    />
  </>
);

export default ConfirmationDialogContent;
