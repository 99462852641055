// External Dependencies
import { FC, useMemo } from 'react';
import { useParams } from '@reach/router';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// Internal Dependencies
import { Page } from 'components/shared';
import { useGetForm } from 'gql/queries/form-queries';

// Local Dependencies
import AssignmentDetailsCard from './AssignmentDetailsCard';
import DetailsCard from '../components/DetailsCard';

const FormShow: FC = () => {
  const params = useParams<{ id: string; }>();

  const {
    data: formData,
  } = useGetForm(params.id);

  const form = useMemo(() => {
    return formData?.form;
  }, [formData]);

  return (
    <Page>
      <Container maxWidth="md">
        {form && (
          <>
            <Box marginTop={2}>
              <DetailsCard
                form={form}
                isEditMode={false}
              />
            </Box>

            <Box marginTop={2}>
              <AssignmentDetailsCard
                form={form}
              />
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
};

export default FormShow;
