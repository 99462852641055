// External Dependencies
import { useMemo } from 'react';

// Internal Dependencies
import { TableColumn, displayCell } from 'components/shared/TableV2';

export const useColumns = (
  extraColumns?: TableColumn<GQL.IDynamicField>[],
) => useMemo<TableColumn<GQL.IDynamicField>[]>(() => [
  {
    Header: 'Name',
    accessor: (row) => displayCell(row.label),
  },
  {
    Header: 'Type',
    accessor: (row) => displayCell(row.type.label),
  },
  ...(extraColumns ?? []),
], [extraColumns]);
