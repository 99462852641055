// External Dependencies
import {
  Box,
  Typography,
  TypographyProps,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import momemt from 'moment';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { HelpInfoIconButton } from 'components/shared';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
} from 'utils';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';

// Local Typings
interface Props {
  align?: TypographyProps['align'];
  label: string;
  tooltipTitle?: string;
  type?: 'currency' | 'date' | 'datetime' | 'number' | 'text' | 'boolean';
  value?: string | number | boolean | ReactNode | null;
}

const StyledWrapper = styled.div(({ theme }) => ({
  margin: `${theme.spacing(1)} 0`,
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.7rem',
  },
  fontSize: '0.8rem',
}));

const StyledValue = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9rem',
  },
  fontSize: '1rem',
  // Helps on smaller screens
  overflowWrap: 'break-word',
}));

const StyledEmDash = styled.span(({ theme }) => ({
  color: theme.palette.grey[700],
}));

// Component Definition
const ShowPageDataDisplay: FC<Props> = ({
  align,
  label,
  tooltipTitle,
  type = 'text',
  value,
}: Props) => {
  const theme = useTheme();

  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const tooltipElement = !isMobileOrTabletScreen && tooltipTitle && (
    <Box ml={1.5}>
      <HelpInfoIconButton
        size="small"
        tooltipTitle={tooltipTitle}
        variant="info"
      />
    </Box>
  );

  const emDashElement = <StyledEmDash>—</StyledEmDash>;

  let displayValue;
  if (type === 'text') {
    displayValue = value || null;
  } else if (type === 'number') {
    displayValue = value;
  } else if (type === 'currency') {
    displayValue = value
      ? displayPriceStringFromDollarAmount(convertCentsToDollars(value))
      : '';
  } else if (value && typeof value !== 'boolean' && type === 'date') {
    displayValue = momemt(value as string).format('M-D-YYYY');
  } else if (value && typeof value !== 'boolean' && type === 'datetime') {
    displayValue = momemt(value as string).format('MM-DD-YYYY h:MM:SS A');
  } else if (value && typeof value === 'boolean' && type === 'boolean') {
    displayValue = <CheckIcon htmlColor={theme.palette.success.dark} />;
  } else if (!value && typeof value === 'boolean' && type === 'boolean') {
    displayValue = 'No';
  }

  return (
    <StyledWrapper>
      <Box display="flex">
        <StyledLabel
          align={align}
          className="showPageLabel"
          color="textSecondary"
        >
          {label}
        </StyledLabel>
        {tooltipElement}
      </Box>
      <StyledValue
        align={align}
        className="showPageValue"
      >
        {displayValue ?? emDashElement}
      </StyledValue>
    </StyledWrapper>
  );
};

export default ShowPageDataDisplay;
