// External Dependencies
import { Box, Typography } from '@mui/material';
import { FC, ReactNode, useCallback } from 'react';
import { navigate } from '@reach/router';

// Internal Dependencies
import { StyledLink } from 'components/shared';

// Local Typings
interface Props {
  label: ReactNode;
  linkText?: string;
  to: string;
}

// Component Definition
const LabelWithLink: FC<Props> = ({
  label,
  linkText = 'View',
  to,
}) => {
  const handleClickLink = useCallback(() => {
    navigate(to);
  }, [to]);

  return (
    <Typography component="span">
      {label}

      <Box
        component="span"
        marginLeft={1.5}
      >
        <StyledLink onClick={handleClickLink}>
          {linkText}
        </StyledLink>
      </Box>
    </Typography>
  );
};

export default LabelWithLink;
