// External Dependencies
import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';

// Local Dependencies
import EditAdultPermissions from '../../shared/EditAdultPermissions';

// Local Typings
interface RouteProps {
  id: string;
}

// Componeant Definition
const EditDirectorPermissions: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  if (!id) {
    return null;
  }

  return (
    <EditAdultPermissions
      adultUserId={id}
      context="Director"
      pathToShowPage={`/${PATHS.DIRECTORS}/${id}`}
    />
  );
};

export default EditDirectorPermissions;
