// External Dependencies
import { Avatar } from '@mui/material';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import BusinessIcon from '@mui/icons-material/Business';

// Internal Dependencies
import { Flex, SubscriberAddButton } from 'components/shared/index';
import { open } from 'state/ui/organizationLogoUploadDialog/actions';
import { useGetOrganization } from 'gql/queries';
import { useTheme } from 'styled-components';

// Local Typings
interface Props {
  withUploadButton?: boolean;
}

// Local Variables
const avatarSize = 100;

// Component Definition
const OrganizationLogo: FC<Props> = ({
  withUploadButton,
}) => {
  const dispatch = useDispatch();

  const theme = useTheme();

  const openDialog = useCallback(() => {
    dispatch(open());
  }, [open]);

  const {
    data: organizationData,
  } = useGetOrganization();

  const organization = organizationData?.organization;

  const logoUrl = organization?.logoUrl;

  if (!logoUrl) {
    return withUploadButton ? (
      <Flex
        alignContent="center"
        flexDirection="column"
        gap={2}
        justifyContent="center"
        width="100%"
        marginTop={2}
      >
        <Avatar
          alt="organization-logo"
          sx={{
            height: avatarSize,
            width: avatarSize,
          }}
        >
          <BusinessIcon fontSize="large" />
        </Avatar>

        <SubscriberAddButton
          label="Organization Logo"
          onClick={openDialog}
          size="small"
        />
      </Flex>
    ) : null;
  }

  return (
    <Flex
      justifyContent="center"
    >
      <Avatar
        alt="organization-logo"
        src={logoUrl}
        sx={{
          border: `1px solid ${theme.palette.grey[300]}`,
          height: avatarSize,
          marginBottom: 2,
          width: avatarSize,
        }}
      />
    </Flex>
  );
};

export default OrganizationLogo;
