// External Dependencies
import { DialogContentText, Typography } from '@mui/material';
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  GridColDef,
  GridSelectionModel,
} from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import ThumbsDownIcon from '@mui/icons-material/ThumbDown';
import ThumbsUpIcon from '@mui/icons-material/ThumbUp';
import startCase from 'lodash.startcase';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedIconButton,
  TableDataGrid,
} from 'components/shared';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { addNotification } from 'state/notifications/actions';
import { createDataGridActionsColumn, displayCell } from 'components/shared/TableV2';
import { getFullName, pluralize } from 'utils';
import { hasPermission } from 'state/self/selectors';
import {
  isTableSelectionActive,
  tableSelection,
} from 'state/table/selectors';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useApprovePendingMembers } from 'gql/mutations';
import { useClearTableSelection } from 'hooks/useClearTableSelection';
import {
  useGetPendingMembersIndex,
} from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import PendingMembersTableZeroState from './PendingMembersTableZeroState';

// Component Definition
const PendingMembersTable: FC = () => {
  const [isApproving, setIsApproving] = useState<boolean | null>(null);
  const [memberIds, setMemberIds] = useState<GridSelectionModel>([]);

  const {
    handleClose,
    isOpen,
    toggleIsOpen,
  } = useIsOpen();

  const selection = useSelector(tableSelection('pendingMembers'));
  const isSelectionActive = useSelector(isTableSelectionActive('pendingMembers'));
  const handleRemoveSelectionAll = useClearTableSelection('pendingMembers');

  const dispatch = useDispatch();

  const {
    data,
    isLoading,
  } = useGetPendingMembersIndex();

  const canAddUsers = useSelector(hasPermission('users', 'write'));

  const handleSetMemberIds = useCallback(
    (memberIdList: string[], isApproved: boolean) => {
      setIsApproving(isApproved);
      setMemberIds([...memberIdList]);
      toggleIsOpen();
    },
    [setIsApproving, setMemberIds, toggleIsOpen],
  );

  const handleCompleted = () => {
    dispatch(addNotification(
      `${memberIds.length} ${pluralize(memberIds.length, 'member')} ${isApproving ? 'approved' : 'rejected'}`,
      'success',
    ));
    dispatch(updateIsPaginatedListDataLoaded({
      isPaginatedListDataLoaded: false,
    }));
    setMemberIds([]);
    handleRemoveSelectionAll();
    handleClose();
  };

  const [
    approvePendingMember,
    {
      loading: isSubmitting,
    },
  ] = useApprovePendingMembers({
    onCompleted: handleCompleted,
  });

  const handleConfirm = () => {
    const stringIds = memberIds.map((id) => id.toString());
    approvePendingMember({
      variables: {
        input: {
          isApproved: Boolean(isApproving),
          memberIds: stringIds,
        },
      },
    });
  };

  const columns: GridColDef<GQL.ISimpleUser>[] = [
    {
      field: 'role',
      headerName: 'Role',
      valueGetter: (params) => displayCell(params.row.role.label),
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      valueGetter: (params) => displayCell(params.row.firstName),
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      valueGetter: (params) => displayCell(params.row.lastName),
    },
    {
      field: 'email',
      headerName: 'Email',
      valueGetter: (params) => displayCell(params.row.email),
    },
    {
      field: 'familyMembers',
      headerName: 'Family Members',
      valueGetter: (params) => {
        const members = params.row.relationships.map((rel) => {
          const { recipient, sender } = rel;

          if (recipient?.id === params.row.id) {
            return sender;
          }

          return recipient;
        });

        const memberNames = members
          .filter(Boolean)
          .map((member) => getFullName(member!))
          .join('; ');

        return displayCell(memberNames);
      },
    },
  ];

  const toolbarActions: IToolbarAction[] = useMemo(() => [
    {
      action: () => handleSetMemberIds(memberIds.map((id) => id.toString()), true),
      icon: <ThumbsUpIcon fontSize="small" />,
      isDisabled: isSelectionActive,
      text: `Approve selected ${pluralize(memberIds.length, 'member')}`,
    },
    {
      action: () => handleSetMemberIds(memberIds.map((id) => id.toString()), false),
      icon: <ThumbsDownIcon fontSize="small" />,
      isDisabled: isSelectionActive,
      text: `Reject selected ${pluralize(memberIds.length, 'member')}`,
    },
  ], [handleSetMemberIds, isSelectionActive, memberIds]);

  const actionColumn = useMemo<GridColDef[]>(
    () => {
      const handleSetMemberId = (row: GQL.ISimpleUser, isApproved: boolean) => {
        setIsApproving(isApproved);
        setMemberIds([row.id]);
        toggleIsOpen();
      };

      const extraColumn = createDataGridActionsColumn<GQL.ISimpleUser>([
        {
          action: (row) => handleSetMemberId(row, true),
          icon: <ThumbsUpIcon fontSize="small" />,
          isDisabled: isSelectionActive,
          text: 'Approve',
        },
        {
          action: (row) => handleSetMemberId(row, false),
          icon: <ThumbsDownIcon fontSize="small" />,
          isDisabled: isSelectionActive,
          text: 'Reject',
        },
      ]);

      return extraColumn ? [extraColumn] : [];
    },
    [isSelectionActive, toggleIsOpen],
  );

  if (actionColumn) {
    columns.push(...actionColumn);
  }

  // These are toolbar actions available when selection is active
  const selectionIconElements: React.ReactElement[] = [];

  if (canAddUsers) {
    selectionIconElements.push(
      <EnhancedIconButton
        icon={<ThumbsUpIcon fontSize="small" />}
        key="approve-icon-button"
        onClick={() => handleSetMemberIds(selection.ids, true)}
        tooltip="Approve"
      />,
      <EnhancedIconButton
        icon={<ThumbsDownIcon fontSize="small" />}
        key="reject-icon-button"
        onClick={() => handleSetMemberIds(selection.ids, false)}
        tooltip="Reject"
      />,
    );
  }

  const approveAction = isApproving ? 'approve' : 'reject';

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          checkboxSelection={canAddUsers}
          columns={columns}
          components={{
            NoRowsOverlay: PendingMembersTableZeroState,
          }}
          loading={isLoading}
          onSelectionModelChange={setMemberIds}
          rows={data}
          selectionModel={memberIds}
          skipLocalDataFromIndexedDb
          tableResource="pendingMembers"
          toolbarActions={toolbarActions}
          withSearch
        />
      </DataGridContainer>

      <ConfirmationDialog
        canBeUndone
        confirmButtonAction={handleConfirm}
        confirmButtonText={isApproving ? 'Yes, Approve' : 'Yes, Reject'}
        declineButtonAction={handleClose}
        description={(
          <DialogContentText component="div">
            <Typography>
              Do you want to {approveAction} {pluralize(memberIds.length, 'this member', `these ${memberIds.length} members`)}?
            </Typography>

            <Typography sx={{ mt: 1.5 }}>
              Approved members will be visible in the Students or Parents tables.
            </Typography>
          </DialogContentText>
        )}
        handleClose={handleClose}
        isSubmitting={isSubmitting}
        maxWidth="sm"
        open={isOpen}
        title={`${startCase(approveAction)} Pending Members?`}
        useCustomText
      />
    </>
  );
};

export default PendingMembersTable;
