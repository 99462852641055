// External Dependencies
import {
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
} from '@mui/material';
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldArrayRenderProps } from 'formik';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { TransitionGroup } from 'react-transition-group';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedDialog,
  ListItemWithSecondaryAction,
  Select,
} from 'components/shared';
import { useGetFinancialItems } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Typings
interface Props {
  arrayHelpers: FieldArrayRenderProps;
  financialAccountIdFormikValue: string;
  itemsFormikValue: GQL.IFinancialFundraiserCreditItemPriorityInput[];
  schoolYearEndingFormikValue: number;
}

const ItemsFieldArray: FC<Props> = ({
  arrayHelpers,
  financialAccountIdFormikValue,
  itemsFormikValue,
  schoolYearEndingFormikValue,
}) => {
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  useEffect(() => {
    setSelectedItemId(null);
  }, [
    financialAccountIdFormikValue,
    schoolYearEndingFormikValue,
  ]);

  const {
    isOpen: isAddingItem,
    toggleIsOpen: toggleIsAddingItem,
  } = useIsOpen();

  const addItem = useCallback(() => {
    const objectToAdd: GQL.IFinancialFundraiserCreditItemPriorityInput = {
      financialItemId: selectedItemId ?? '',
      priority: itemsFormikValue.length + 1,
    };

    arrayHelpers.push(objectToAdd);

    toggleIsAddingItem();

    setSelectedItemId(null);
  }, [arrayHelpers, itemsFormikValue.length, toggleIsAddingItem, selectedItemId]);

  const handleChangeItem: SelectInputProps['onChange'] = useCallback((evt) => {
    setSelectedItemId(evt.target.value);
  }, []);

  const {
    data: financialItemsData,
  } = useGetFinancialItems(schoolYearEndingFormikValue);

  const selectableItems = useMemo(
    () => {
      const selectedItemIds = itemsFormikValue.map((item) => item.financialItemId);

      return financialItemsData?.financialItems.data.filter(
        (item) =>
          item.financialAccount?.id === financialAccountIdFormikValue
          && !selectedItemIds.includes(item.id),
      );
    },
    [
      financialAccountIdFormikValue,
      financialItemsData,
      itemsFormikValue,
    ],
  );

  const areAllItemsSelected = (selectableItems?.length ?? 0) === 0 && itemsFormikValue.length > 0;

  return (
    <>
      <List>
        <TransitionGroup>
          {itemsFormikValue.map((item, index) => {
            const matchedItem = financialItemsData?.financialItems.data?.find(
              (i) => i.id === item.financialItemId,
            );

            return (
              <Collapse key={item.financialItemId}>
                <ListItemWithSecondaryAction
                  primaryText={matchedItem?.label}
                  secondaryText={`Priority ${item.priority}`}
                  secondaryAction={{
                    buttonIcon: <RemoveIcon />,
                    buttonText: 'Remove',
                    onClick: arrayHelpers.handleRemove(index),
                  }}
                />
              </Collapse>
            );
          })}
        </TransitionGroup>
      </List>

      {!areAllItemsSelected && (
        <Button
          disabled={(selectableItems?.length ?? 0) === 0}
          onClick={toggleIsAddingItem}
          size="small"
          variant="outlined"
        >
          Add Item
        </Button>
      )}

      <Collapse
        in={selectableItems?.length === 0 && itemsFormikValue.length === 0}
      >
        <EnhancedAlert
          severity="error"
          sx={{ marginTop: 2 }}
        >
          The selected account has no associated items.
        </EnhancedAlert>
      </Collapse>

      <EnhancedDialog open={isAddingItem}>
        <DialogTitle>
          Select an item
        </DialogTitle>

        <DialogContent>
          <DialogContentText gutterBottom>
            Choose from the items for the selected financial account and school year.
          </DialogContentText>

          <Select
            label="Item"
            name="item"
            onChange={handleChangeItem}
            options={selectableItems ?? []}
            value={selectedItemId ?? ''}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={toggleIsAddingItem}>
            Cancel
          </Button>

          <Button
            onClick={addItem}
            variant="contained"
          >
            Add Item
          </Button>
        </DialogActions>
      </EnhancedDialog>
    </>
  );
};

export default ItemsFieldArray;
