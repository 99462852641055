// External Dependencies
import {
  Box,
  Button,
  CardContent,
  Collapse,
  Typography,
} from '@mui/material';
import {
  FC, useCallback, useState,
} from 'react';
import { Form, Formik } from 'formik';
import { claimMemberInputSchema } from '@presto-assistant/api_types/schemas/member';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { SupportLink } from 'components/shared';
import { getFullName } from 'utils';
import { useClaimMember } from 'gql/mutations';
import { useGetClaimMemberLookup } from 'gql/queries';

// Local Dependencies
import {
  ConfirmationDialog,
  CustomInput,
  EnhancedCard,
  SaveButton,
  ShowPageDataDisplay,
} from '..';
import { MemberInfoFormType } from '.';

// Local Typings
interface Props {
  isOnboarding: boolean;
  onResetFormType: () => void;
}

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1.5, 3, 3),
}));

const formType: MemberInfoFormType = 'claiming';

const supportArticleHref = 'join-an-organization-with-member-claim-code';

// Component Definition
const ClaimMemberForm: FC<Props> = ({
  isOnboarding,
  onResetFormType,
}) => {
  const theme = useTheme();

  const [input, setInput] = useState<GQL.IClaimMemberInput | null>();

  const [
    // Used to submit the form
    lookupMember,
    {
      data: lookupMemberData,
      error,
      loading: isLookingUpMember,
    },
  ] = useGetClaimMemberLookup();

  const organizationLabel = lookupMemberData?.claimMemberLookup.organization?.label
    ?? lookupMemberData?.claimMemberLookup.district?.label
    ?? '';

  const handleNavigateToSuccess = useCallback(() => {
    const queryString = `organization=${organizationLabel}&form_type=${formType}`;
    const fullPath = `/${PATHS.JOIN_NEW_ORGANIZATION_SUCCESS}?${queryString}`;

    // We need to clear a ton of state here when switching organizations. Let's just reload.
    window.location.href = fullPath;
  }, [organizationLabel]);

  const [
    // Used to submit the confirmation dialog
    claimMember,
    {
      loading: isClaiming,
    },
  ] = useClaimMember({
    onCompleted: () => {
      if (!isOnboarding) {
        handleNavigateToSuccess();
      }
    },
  });

  const handleSubmit = (values: GQL.IClaimMemberInput) => {
    setInput(values);

    lookupMember({
      variables: {
        input: values,
      },
    });
  };

  const handleConfirm = useCallback(() => {
    if (input) {
      claimMember({
        variables: {
          input,
        },
      });
    }
  }, [claimMember, input]);

  const handleDecline = useCallback(() => {
    setInput(null);
  }, []);

  return (
    <>
      <Formik<GQL.IClaimMemberInput>
        initialValues={{
          claimCode: '',
          memberEmail: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={claimMemberInputSchema}
      >
        {() => (
          <>
            <StyledTypography>
              Your director sent you a welcome email that contains a{' '}
              <strong>Member Claim Code</strong>.
              You will be added as a member of the organization immediately.
            </StyledTypography>

            <Form>
              <EnhancedCard>
                <CardContent>
                  <CustomInput
                    label="Member Code"
                    name="claimCode"
                  />

                  <CustomInput
                    label="Email"
                    name="memberEmail"
                  />
                </CardContent>

                <Collapse in={Boolean(error?.message)}>
                  <Box marginBottom={1.5}>
                    <Typography variant="body2">
                      Need help?{' '}
                      <SupportLink slug={supportArticleHref} />
                    </Typography>
                  </Box>
                </Collapse>
              </EnhancedCard>

              <Box
                display="flex"
                gap={theme.spacing(2)}
                justifyContent={isOnboarding ? 'center' : 'flex-end'}
                marginTop={theme.spacing(3)}
              >
                <Button
                  color="primary"
                  onClick={onResetFormType}
                  variant="outlined"
                >
                  Go Back
                </Button>

                <SaveButton
                  isSaving={isLookingUpMember}
                  type="submit"
                >
                  Claim
                </SaveButton>
              </Box>
            </Form>
          </>
        )}
      </Formik>

      <ConfirmationDialog
        confirmButtonText="Yes, claim"
        confirmButtonAction={handleConfirm}
        declineButtonAction={handleDecline}
        description={(
          <>
            <ShowPageDataDisplay
              label={lookupMemberData?.claimMemberLookup.organization ? 'Organization' : 'District'}
              value={organizationLabel}
            />

            <ShowPageDataDisplay
              label="Name"
              value={lookupMemberData?.claimMemberLookup ? getFullName(lookupMemberData.claimMemberLookup) : ''}
            />

            <ShowPageDataDisplay
              label="Email"
              value={lookupMemberData?.claimMemberLookup.email}
            />

            <ShowPageDataDisplay
              label="Role"
              value={lookupMemberData?.claimMemberLookup.role.label}
            />
          </>
        )}
        handleClose={handleDecline}
        isSubmitting={isClaiming}
        open={!!input && !!lookupMemberData?.claimMemberLookup}
        title="Claim this member?"
        useCustomText
      />
    </>
  );
};

export default ClaimMemberForm;
