// External Dependencies
import { FC } from 'react';
import { navigate } from '@reach/router';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';

// Local Dependencies
import AlertButton from './AlertButton';

// Local Variables
const handlePressButton = () => {
  navigate(`/${PATHS.EMAIL_SIGNATURE}`);
};

// Component Definition
const EmailSignatureButton: FC = () => (
  <AlertButton
    buttonText="Update"
    onClick={handlePressButton}
  />
);

export default EmailSignatureButton;
