// External Dependencies
import { Box, Container } from '@mui/material';
import { FC, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { PaymentMethod } from '@stripe/stripe-js';
import { createStripePaymentSchema } from '@presto-assistant/api_types/schemas/financialPayments';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { APP_NAME, TERMS_OF_SERVICE_HREF } from 'utils/constants';
import { CustomCheckbox, SaveButton, StyledLink } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { convertDollarsToCents } from 'utils';
import { openDialogSuccess } from 'state/ui/successDialog/actions';
import { useCreateStripePayment } from 'gql/mutations';
import paymentSuccessAnimationData from 'components/shared/Lottie/lottieFiles/payment-success.json';

// Local Dependencies
import { PaymentsFormValues } from '../PaymentTable';
import PaymentAmountsCard, { useGetStripeFeeFromPaymentAmounts } from '../shared/PaymentAmountsCard';
import PaymentMethodCard from '../shared/PaymentMethodCard';

// Local Typings
interface Props {
  hasFees: boolean;
  onEditPaymentAmounts: () => void;
  onEditPaymentMethod: () => void;
  paymentAmountValues: PaymentsFormValues | null;
  paymentMethod: PaymentMethod | null;
}

// Component Definition
const PaymentReview: FC<Props> = ({
  hasFees,
  onEditPaymentAmounts,
  onEditPaymentMethod,
  paymentAmountValues,
  paymentMethod,
}) => {
  const dispatch = useDispatch();

  const [
    createStripePayment,
    {
      loading: isSubmitting,
    },
  ] = useCreateStripePayment({
    clearCachePredicates: ['myFinancialPayments', 'myFinancialFees'],
    onCompleted: () => {
      dispatch(openDialogSuccess({
        animationData: paymentSuccessAnimationData,
        title: 'Payment Successful',
      }));

      navigate(`/${PATHS.STUDENT_PAYMENTS}`);
    },
  });

  const {
    data: stripeFeeData,
    loading: isLoadingStripeFee,
  } = useGetStripeFeeFromPaymentAmounts(paymentAmountValues);

  const hasCalculatedStripeFee = Boolean(stripeFeeData?.stripeFeeInCents && !isLoadingStripeFee);

  const handleFormikSubmit = useCallback((input: GQL.ICreateStripeFinancialPaymentInput) => {
    createStripePayment({
      variables: { input },
    });
  }, [createStripePayment]);

  if (!paymentMethod || !paymentAmountValues?.payments.length) {
    return null;
  }

  return (
    <Container maxWidth="sm">
      <Formik<GQL.ICreateStripeFinancialPaymentInput>
        initialValues={{
          hasAcceptedTermsOfService: false,
          lineItems: paymentAmountValues.payments.map((payment) => ({
            amountInCents: convertDollarsToCents(payment.amountInDollars),
            financialFeeId: payment.financialFeeId,
          })),
          stripeToken: paymentMethod.id,
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={createStripePaymentSchema}
      >
        {({
          handleSubmit,
        }) => (
          <Form
            onSubmit={handleSubmit}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={3}
            >
              <PaymentAmountsCard
                hasFees={hasFees}
                onEdit={onEditPaymentAmounts}
                paymentAmounts={paymentAmountValues}
              />

              <PaymentMethodCard
                onEdit={onEditPaymentMethod}
                paymentMethod={paymentMethod}
              />

              <CustomCheckbox
                label={(
                  <>
                    I confirm the above amount is correct and I agree to the {APP_NAME}
                    {' '}
                    <StyledLink
                      href={TERMS_OF_SERVICE_HREF}
                      noWrap
                      openInNewTab
                    >
                      Terms of Service
                    </StyledLink>

                  </>
                )}
                name="hasAcceptedTermsOfService"
              />

              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <SaveButton
                  color="primary"
                  disabled={!hasCalculatedStripeFee}
                  isSaving={isSubmitting}
                  variant="contained"
                >
                  Submit
                </SaveButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default PaymentReview;
