// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';
import OpenInNew from '@mui/icons-material/OpenInNew';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedMenuItem } from 'components/shared';

// Local Typings
export interface BasicMenuItemProps {
  isExternalLink?: boolean;
  onClick: () => void;
  text: string;
}

// Local Variables
const StyledOpenInNewTabIcon = styled(OpenInNew)({
  color: 'inherit',
  fontSize: '1rem',
  marginLeft: '0.5em',
}) as typeof OpenInNew;

// Component Definition
const BackMenuItem: FC<BasicMenuItemProps> = ({
  isExternalLink,
  onClick,
  text,
}) => (
  <EnhancedMenuItem
    onClick={onClick}
    text={(
      <Box
        alignItems="center"
        display="flex"
      >
        {text}

        {isExternalLink && <StyledOpenInNewTabIcon />}
      </Box>
    )}
  />
);

export default BackMenuItem;
