// External Dependencies
import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { History, Page } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import { useGetInventoryItem } from 'gql/queries';
import InventoryForm from 'pages/Inventory/shared/InventoryForm';

// Local Typings
interface RouteProps {
  id: string;
}

// Component Definition
const AdminInventoryShow: FC<RouteComponentProps<RouteProps>> = ({
  id,
}) => {
  const {
    data,
    error,
    loading,
  } = useGetInventoryItem(id!);

  const districtInventoryParams = useSelector(tableQueryParams('districtInventoryItems'));

  return (
    <Page
      backButtonProps={{
        label: 'All Inventory Items',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}${districtInventoryParams}`,
      }}
      editButtonPath={`/${PATHS.DISTRICT_ADMIN}/${PATHS.INVENTORY}/${id}/edit`}
      error={error}
      isLoading={!data || loading}
    >
      <InventoryForm
        canUseDynamicFields
        inventoryItem={data?.inventoryItem}
        isAdmin
        readOnly
      />

      {data?.inventoryItem && (
        <History
          id={data.inventoryItem.id}
          resource="inventoryItems"
        />
      )}
    </Page>
  );
};

export default AdminInventoryShow;
