// External Dependencies
import {
  Box,
  CardContent,
  Collapse,
  Typography,
} from '@mui/material';
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { UserAlertFlags } from '@presto-assistant/api_types';
import { navigate } from '@reach/router';

// Internal Dependencies
import {
  APP_NAME,
  ROLES,
  ROLE_IDS,
  SHORT_APP_NAME,
} from 'utils/constants';
import {
  Container,
  EnhancedAlert,
  EnhancedCard,
  Page,
  Subtitle,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import {
  useCreateChild,
  useCreateRelationship,
} from 'gql/mutations';
import { useGetOrganization } from 'gql/queries';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import Age, { AgeFormValues } from './Steps/Age';
import Email, { OnCompletedEmailStepArgs } from './Steps/Email';
import Name, { NameFormValues } from './Steps/Name';
import RelationshipType, { RelationshipTypeFormValues } from './Steps/RelationshipType';
import UnderThirteen, { UnderThirteenFormValues } from './Steps/UnderThirteen';

// Local Typings
type Step = 'age' | 'email' | 'name' | 'underThirteen' | 'relationshipType';

// Local Variables
const navigateToProfile = () => {
  navigate(`/${PATHS.PROFILE}`);
};

// Component Definition
const RelationshipsNew: FC = () => {
  const [emailStepArgs, setEmailStepArgs] = useState<OnCompletedEmailStepArgs>({
    doesAccountExist: false,
    email: '',
  });
  const [nameStepArgs, setNameStepArgs] = useState<NameFormValues>({
    firstName: '',
    lastName: '',
  });
  const [ageStepArgs, setAgeStepArgs] = useState<AgeFormValues>({
    isAtLeast13: '',
  });
  const [
    underThirteenStepArgs,
    setUnderThirteenStepArgs,
  ] = useState<UnderThirteenFormValues>({
    acceptedTerms: false,
    dateOfBirthChild: '',
    genderId: '',
    isParent: false,
    otherGenderLabel: '',
    password: '',
  });
  const [
    relationshipTypeStepArgs,
    setRelationshipTypeStepArgs,
  ] = useState<RelationshipTypeFormValues>({
    recipientRelationshipTypeId: '',
  });
  const isUserAtLeast13 = ageStepArgs.isAtLeast13 === 'yes';

  const [steps, setSteps] = useState<Step[]>(['email']);

  const { self } = useSelfQuery();
  const { data: orgData } = useGetOrganization();

  const isAdult = self?.role.id === ROLE_IDS[ROLES.ADULT];

  const [
    createRelationship,
    { loading: createRelationshipLoading },
  ] = useCreateRelationship(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['userRelationships'],
      onCompleted: navigateToProfile,
    },
  );

  const [
    createChild,
    { loading: createChildLoading },
  ] = useCreateChild(
    {
      awaitRefetchQueries: true,
      clearCachePredicates: ['myChildrenUnderThirteen', 'userRelationships'],
      onCompleted: navigateToProfile,
    },
  );

  const handleGoBack = () => {
    const currentSteps = [...steps];
    if (currentSteps.length > 1) {
      currentSteps.pop();

      setSteps(currentSteps);
    }
  };

  const addStep = (nextStep: Step) => {
    setSteps((currentSteps) => [...currentSteps, nextStep]);
  };

  const handleCompletedEmailStep = (args: OnCompletedEmailStepArgs) => {
    setEmailStepArgs(args);
    addStep(args.doesAccountExist ? 'relationshipType' : 'name');
  };

  const handleCompletedNameStep = (args: NameFormValues) => {
    setNameStepArgs(args);
    addStep(isAdult ? 'age' : 'relationshipType');
  };

  const handleCompletedAgeStep = (args: AgeFormValues) => {
    setAgeStepArgs(args);
    addStep(args.isAtLeast13 === 'yes' ? 'relationshipType' : 'underThirteen');
  };

  const handleCompletedUnderThirteenStep = (args: UnderThirteenFormValues) => {
    setUnderThirteenStepArgs(args);
    addStep('relationshipType');
  };

  const handleCompletedRelationshipTypeStep = (args: RelationshipTypeFormValues) => {
    setRelationshipTypeStepArgs(args);
  };

  const handleSubmit = useCallback(async () => {
    const { doesAccountExist, email } = emailStepArgs;
    const { firstName, lastName } = nameStepArgs;
    const {
      acceptedTerms,
      dateOfBirthChild: dateOfBirth,
      genderId,
      isParent,
      otherGenderLabel,
      password: temporaryPassword,
    } = underThirteenStepArgs;
    const { recipientRelationshipTypeId } = relationshipTypeStepArgs;

    if (!isAdult || isUserAtLeast13 || doesAccountExist) {
      createRelationship({
        variables: {
          recipientEmail: email,
          recipientFirstName: firstName,
          recipientLastName: lastName,
          recipientRelationshipTypeId,
        },
      });
    } else {
      createChild({
        variables: {
          acceptedTerms: acceptedTerms && isParent,
          input: {
            dateOfBirth,
            email,
            firstName,
            genderId,
            lastName,
            otherGenderLabel,
            recipientRelationshipTypeId,
            temporaryPassword,
          },
        },
      });
    }
  }, [
    createChild,
    createRelationship,
    emailStepArgs,
    isAdult,
    isUserAtLeast13,
    nameStepArgs,
    relationshipTypeStepArgs,
    underThirteenStepArgs,
  ]);

  useEffect(() => {
    if (relationshipTypeStepArgs.recipientRelationshipTypeId) {
      handleSubmit();
    }
  }, [handleSubmit, relationshipTypeStepArgs]);

  const step = steps[steps.length - 1];

  return (
    <Page>
      <Container maxWidth={500}>
        <Subtitle>
          Add Family Member
        </Subtitle>

        {self?.alertFlagId === UserAlertFlags.DOBViolation && (
          <EnhancedAlert
            severity="error"
            sx={{ marginBottom: 2 }}
            title="Parent Permission Required"
          >
            You need approval from a parent to use {SHORT_APP_NAME}.
            Please add a parent, and ask them to authorize your account.
          </EnhancedAlert>
        )}

        <EnhancedCard>
          <CardContent>
            <Collapse in>
              <Email
                initialValues={emailStepArgs}
                onCompleted={handleCompletedEmailStep}
                readOnly={step !== 'email'}
              />

              <Collapse in={!emailStepArgs.doesAccountExist}>
                {step !== 'email' && (
                  <Box m={1.5}>
                    <Box mb={1.5}>
                      <Typography>
                        Create your family member&apos;s {APP_NAME} profile.
                      </Typography>
                    </Box>

                    <ul>
                      <li>
                        <Typography variant="body2">
                          This member&apos;s profile will be added
                          to {orgData?.organization.label}.
                        </Typography>
                      </li>

                      <li>
                        <Typography variant="body2">
                          The relationship will be linked to your member profile.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                )}

                {step !== 'email' && (
                  <Name
                    onBack={handleGoBack}
                    onCompleted={handleCompletedNameStep}
                    readOnly={step !== 'name'}
                  />
                )}

                {step !== 'email' && step !== 'name' && isAdult && (
                  // Intentionally not passing initial values
                  // to force a selection each time
                  <Age
                    onBack={handleGoBack}
                    onCompleted={handleCompletedAgeStep}
                    readOnly={step !== 'age'}
                  />
                )}

                <Collapse in={step !== 'email' && step !== 'name' && step !== 'age' && !isUserAtLeast13 && isAdult}>
                  <UnderThirteen
                    initialValues={underThirteenStepArgs}
                    onBack={handleGoBack}
                    onCompleted={handleCompletedUnderThirteenStep}
                    readOnly={step !== 'underThirteen'}
                  />
                </Collapse>
              </Collapse>

              <Collapse in={step === 'relationshipType'}>
                <Box m={1.5}>
                  <Typography>
                    How is this family member related to you?
                  </Typography>
                </Box>

                <RelationshipType
                  autoFocus
                  isSubmitting={createChildLoading || createRelationshipLoading}
                  onBack={handleGoBack}
                  onCompleted={handleCompletedRelationshipTypeStep}
                />
              </Collapse>
            </Collapse>
          </CardContent>
        </EnhancedCard>
      </Container>
    </Page>
  );
};

export default RelationshipsNew;
