// External Dependencies
import moment from 'moment';

// Local Typings
type FlexibleDate = Date | string;

interface HumanizeDateShortOptions {
  includeTime?: boolean;
}
interface FormatCalendarStartTimeArgs {
  endDate?: FlexibleDate;
  startDate: FlexibleDate;
}
interface FormatDateOptions {
  isUTC?: boolean;
}

// Local Variables
export const sanitizeDate = (date: Date | string) => {
  const dateArg = Number.isNaN(Number(date)) ? date : Number(date);
  return new Date(dateArg);
};

const getPaddedDateValues = (d: Date) => {
  const zeroPad = (value: number) =>
    value.toString().padStart(2, '0');

  const year = d.getFullYear();
  const month = zeroPad(d.getMonth() + 1);
  const date = zeroPad(d.getDate());

  return {
    date,
    month,
    year,
  };
};

export const getParamFormattedDate = (d: Date) => {
  const {
    date,
    month,
    year,
  } = getPaddedDateValues(d);

  return `${year}-${month}-${date}`;
};

export const getFormattedDate = (d: Date) => {
  const {
    date,
    month,
    year,
  } = getPaddedDateValues(d);

  return `${month}/${date}/${year}`;
};

export const formatDate = (
  date: Date | string | null,
  options?: FormatDateOptions,
) => {
  if (!date) {
    return null;
  }
  const dateArg = Number.isNaN(Number(date)) ? date : Number(date);
  const d = new Date(dateArg);

  // If the date does not have a time value
  // Ignore the local time
  const hasTime = typeof date === 'object'
    || (typeof date === 'string' && date.includes(':'));

  const shouldUseUtc = options?.isUTC || !hasTime;

  return shouldUseUtc
    ? moment(d).utc(hasTime).format('l')
    : moment(d).format('l');
};

export const formatParamDate = (
  date: Date | string | null,
  options?: FormatDateOptions,
) => {
  if (!date) {
    return null;
  }
  const dateArg = Number.isNaN(Number(date)) ? date : Number(date);
  const d = new Date(dateArg);

  return options?.isUTC ? moment(d).utc().format('YYYY-MM-DD') : moment(d).format('YYYY-MM-DD');
};

export const formatTime = (date: Date | string) => {
  if (!date) {
    return null;
  }
  const dateArg = Number.isNaN(Number(date)) ? date : Number(date);
  const d = new Date(dateArg);
  return moment(d).format('h:mm a');
};

export const formatDateTime = (date: Date | string) => {
  if (!date) {
    return null;
  }
  const dateArg = Number.isNaN(Number(date)) ? date : Number(date);
  const d = new Date(dateArg);
  return moment(d).format('M/D/YYYY, h:mm:ss a');
};

export const getDateFromInput = (date: Date | string) => {
  if (!date) {
    return null;
  }
  const dateArg = Number.isNaN(Number(date)) ? date : Number(date);
  return new Date(dateArg);
};

export const formatCalendarStartDate = (date: Date | string) => {
  if (!date) {
    return null;
  }
  const startDate = sanitizeDate(date);

  // e.g., Monday, August 2
  return moment(startDate).format('dddd, MMMM D');
};

export const formatCalendarStartTime = ({
  endDate,
  startDate,
}: FormatCalendarStartTimeArgs) => {
  if (!startDate) {
    return null;
  }
  const sanitizedStartDate = sanitizeDate(startDate);

  const sanitizedMomentStartDate = moment(sanitizedStartDate);
  const startDateWithAMPM = sanitizedMomentStartDate.format('h:mma');

  // We have to do some calculations if there is an endDate
  //  to make sure the start date's AM/PM is correct
  let sanitizedEndDate;
  if (endDate) {
    sanitizedEndDate = sanitizeDate(endDate);
    const sanitizedMomentEndDate = moment(sanitizedEndDate);
    const endDateWithAMPM = sanitizedMomentEndDate.format('h:mma');

    // The 'am' in the start time is only
    //  needed if the time is < 12pm
    const isStartDateBeforeNoon = startDateWithAMPM.endsWith('am');
    const isEndDateBeforeNoon = endDateWithAMPM.endsWith('am');

    const areBothDatesBeforeNoon = isStartDateBeforeNoon && isEndDateBeforeNoon;
    const areBothDatesAfterNoon = !isStartDateBeforeNoon && !isEndDateBeforeNoon;

    if (areBothDatesBeforeNoon || areBothDatesAfterNoon) {
      // e.g., 5:45
      return moment(sanitizedStartDate).format('h:mm');
    }
  }

  // e.g., 5:45am
  return moment(sanitizedStartDate).format('h:mma');
};

export const formatCalendarEndTime = (date: Date | string) => {
  if (!date) {
    return null;
  }
  const endDate = sanitizeDate(date);
  // e.g., 6:00am
  return moment(endDate).format('h:mma');
};

export const humanizeDateShort = (
  date: Date | string,
  options?: HumanizeDateShortOptions,
) => {
  if (!date) {
    return null;
  }

  const dateArg = Number.isNaN(Number(date)) ? date : Number(date);

  const d = new Date(dateArg);

  if (options?.includeTime) {
    return moment(d).format('MMM D h:mm a');
  }

  return moment(d).utc().format('MMM D');
};

export const getEndingSchoolYear = (date: Date = new Date()) => {
  const thisMonth = date.getMonth();
  const thisYear = date.getFullYear();
  const lastMonthOfSchoolYear = 5; // June
  return thisMonth > lastMonthOfSchoolYear ? thisYear + 1 : thisYear;
};

export const getMomentDateFromString = (input: string) => {
  const numberInput = Number(input);

  return Number.isNaN(numberInput) ? moment(input) : moment(numberInput);
};

export const getNextWeekdayAfterDate = (fromDate: Date) => {
  const daysOfWeek = [1, 2, 3, 4, 5];

  const todayDayOfWeek = fromDate.getDay();
  let nextWeekday = (todayDayOfWeek + 1) % 7;

  if (!daysOfWeek.includes(nextWeekday)) {
    [nextWeekday] = daysOfWeek;
  }

  let daysUntilNextWeekday = nextWeekday - todayDayOfWeek;

  if (daysUntilNextWeekday < 0) {
    daysUntilNextWeekday += 7;
  }

  const nextWeekdayDate = new Date(fromDate);
  nextWeekdayDate.setDate(fromDate.getDate() + daysUntilNextWeekday);

  return nextWeekdayDate;
};
