// External Dependencies
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { FormBlockTypes } from '@presto-assistant/api_types';
import { useParams } from '@reach/router';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Internal Dependencies
import {
  ConfirmationDialog,
  Page,
  ShowCard,
} from 'components/shared';
import { formatDate } from 'utils';
import { useGetMyForm } from 'gql/queries/form-queries';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import MyFormBlock from './MyFormBlock';

// Local Variables
const formatResponse = (row: GQL.IMyFormBlock) => {
  const formBlockType = Number(row.formBlockType.id);

  switch (formBlockType) {
    case FormBlockTypes.Boolean:
      return row.response === 'true' ? 'Yes' : 'No';
    case FormBlockTypes.Date:
      if (!row.response) {
        return '';
      }

      return formatDate(row.response);
    default:
      return row.response;
  }
};

// Component Definition
const MyFormsShow: FC = () => {
  const {
    isOpen: isConfirmPublishDialogOpen,
    toggleIsOpen: toggleConfirmPublishDialog,
  } = useIsOpen();

  const [dirtyFormBlockByIndex, setDirtyFormBlockByIndex] = useState<boolean[]>([]);

  const {
    id,
  } = useParams<{ id: string }>();

  const {
    data,
  } = useGetMyForm(id);

  useEffect(() => {
    if (data?.myForm?.formBlocks) {
      setDirtyFormBlockByIndex(data.myForm.formBlocks.map(() => false));
    }
  }, [data?.myForm?.formBlocks]);

  const isFormDirty = useMemo(() => {
    return dirtyFormBlockByIndex.some((isDirty) => isDirty);
  }, [dirtyFormBlockByIndex]);

  const handleSetIsFormDirtyWithIndex = useCallback((isDirty: boolean, index: number) => {
    setDirtyFormBlockByIndex((prev) => {
      const next = [...prev];
      next[index] = isDirty;
      return next;
    });
  }, []);

  const handleConfirmSubmit = useCallback(() => {
    toggleConfirmPublishDialog();
  }, [toggleConfirmPublishDialog]);

  const responsesReviewTable = useMemo(() => {
    const formResponses = data?.myForm?.formBlocks?.filter((block) => {
      const formBlockTypeId = Number(block.formBlockType.id);

      const isHeadingOrParagraph = formBlockTypeId === FormBlockTypes.Heading
        || formBlockTypeId === FormBlockTypes.Paragraph;

      return !isHeadingOrParagraph;
    });

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Response</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formResponses?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                >
                  {row.label}
                </TableCell>
                <TableCell>
                  {formatResponse(row)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [data?.myForm]);

  return (
    <Page>
      <Container maxWidth="md">
        <ShowCard
          cardActions={(
            <CardActions>
              <Button
                disabled={isFormDirty}
                onClick={toggleConfirmPublishDialog}
                variant="contained"
              >
                Submit
              </Button>
            </CardActions>
          )}
          icon={AssignmentIcon}
          title={data?.myForm.title ?? ''}
        >
          <List component="ol">
            {data?.myForm.formBlocks.map((block, index) => (
              <ListItem
                key={block.id}
                sx={{ width: '100%' }}
              >
                <MyFormBlock
                  formAssignmentId={data?.myForm.assignmentId ?? ''}
                  formBlock={block}
                  index={index}
                  setIsFormDirtyWithIndex={handleSetIsFormDirtyWithIndex}
                />
              </ListItem>
            ))}
          </List>
        </ShowCard>
      </Container>

      <ConfirmationDialog
        confirmButtonAction={handleConfirmSubmit}
        confirmButtonText="Yes, submit"
        declineButtonAction={toggleConfirmPublishDialog}
        description={responsesReviewTable}
        handleClose={toggleConfirmPublishDialog}
        maxWidth="sm"
        open={isConfirmPublishDialogOpen}
        title="Are you ready to submit this form?"
        useCustomText
      />
    </Page>
  );
};

export default MyFormsShow;
