// External Dependencies
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomInput,
  DialogForm,
  EnhancedAlert,
} from 'components/shared';
import {
  useCreateLibraryCategory,
  useDistrictCreateLibraryCategory,
} from 'gql/mutations';

// Local Typings
interface Props {
  isAdmin: boolean;
  isOpen: boolean;
  onClose: () => void;
  organizationId: string | undefined;
}

interface FormValues {
  category: string;
}

// Local Variables
const initialValues: FormValues = {
  category: '',
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

// Component Definition
const DialogAddLibraryCategory: FC<Props> = ({
  isAdmin,
  isOpen,
  onClose,
  organizationId,
}) => {
  const [
    handleCreateLibraryCategory,
    {
      loading,
    },
  ] = useCreateLibraryCategory({
    clearCachePredicates: ['libraryCategories'],
    onCompleted: onClose,
  });

  const [
    handleDistrictCreateLibraryCategory,
    {
      loading: districtLoading,
    },
  ] = useDistrictCreateLibraryCategory({
    clearCachePredicates: ['dfaLibraryCategories'],
    onCompleted: onClose,
  });

  if (isAdmin && !organizationId) {
    return null;
  }

  const handleSubmit = async (values: FormValues) => {
    if (isAdmin && organizationId) {
      await handleDistrictCreateLibraryCategory({
        variables: {
          input: {
            label: values.category,
            organizationId,
          },
        },
      });
    } else {
      await handleCreateLibraryCategory({
        variables: {
          label: values.category,
        },
      });
    }
  };

  return (
    <DialogForm<FormValues>
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
        open: isOpen,
      }}
      initialValues={initialValues}
      isSubmitting={districtLoading || loading}
      onClose={onClose}
      onSubmit={handleSubmit}
      title="Add Library Category"
    >
      <StyledTypography color="textSecondary">
        A library category will be available when adding or editing library items.
      </StyledTypography>

      <Box mb={2}>
        <CustomInput
          label="New Category"
          name="category"
        />
      </Box>

      {isAdmin && (
        <EnhancedAlert severity="info">
          The new category will only apply to the selected organization.
        </EnhancedAlert>
      )}
    </DialogForm>
  );
};

export default DialogAddLibraryCategory;
