// External Dependencies
import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import {
  DashboardCard,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { pluralize } from 'utils';
import { tableQueryParams } from 'state/table/selectors';

// Local Typings
interface Props {
  count?: number;
}

// Local Variables
const StyledStrong = styled.strong({
  fontSize: '1.25rem',
  whiteSpace: 'pre',
});

// Component Definition
const PendingMembersCard: FC<Props> = ({ count }) => {
  const pendingMembersParams = useSelector(tableQueryParams('pendingMembers'));

  const theme = useTheme();

  if (!count) {
    return null;
  }

  const handleNavigateToPendingMembersPage = () => {
    navigate(`/${PATHS.PENDING_MEMBERS}${pendingMembersParams}`);
  };

  const zeroStateIcon = (
    <ZeroStateIcon
      Icon={ThumbsUpDownIcon}
      htmlColor={theme.palette.prestoPrimaryLight}
    />
  );

  const content = (
    <>
      {zeroStateIcon}

      <Box px={2}>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          You have{' '}
          <StyledStrong>{count}</StyledStrong>{' '}
          pending {pluralize(count, 'member')} to review.
        </Typography>
      </Box>

      <Button
        color="primary"
        onClick={handleNavigateToPendingMembersPage}
        size="small"
        variant="outlined"
      >
        View Pending Members
      </Button>
    </>
  );

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent
      title="Pending Members"
      verticallyCenterContent
    />
  );
};

export default PendingMembersCard;
