// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { CustomInput } from 'components/shared';
import GridWrapper from 'pages/Forms/components/DetailsCard/GridWrapper';
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';

// Component Definition
const FormInputs: FC = () => {
  return (
    <GridWrapper>
      <CustomInput
        label="Title"
        name="title"
      />

      <SchoolYearSelect
        hidePreviousYears
        name="schoolYearEnding"
      />
    </GridWrapper>
  );
};

export default FormInputs;
