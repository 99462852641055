// External Dependencies
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal Dependencies
import {
  EnhancedCard,
  ShowCardHeader,
  ShowPageDataDisplay,
} from 'components/shared';
import { Form, Formik } from 'formik';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';
import { useGetMyFinancialFeesAll } from 'gql/queries';

// Local Dependencies
import { addNotification } from 'state/notifications/actions';
import {
  convertCentsToDollars,
  convertDollarsToCents,
  displayPriceStringFromDollarAmount,
} from 'utils';
import PaymentAmountsCard from '../shared/PaymentAmountsCard';
import PaymentCard from '../shared/PaymentCard';
import PaymentTable, { PaymentsFormValues } from '../PaymentTable';

// Local Typings
interface Props {
  hasFees: boolean;
  onSetPaymentValues: (values: PaymentsFormValues | null) => void;
  paymentAmountValues: PaymentsFormValues | null;
}

// Component Definition
const EnterPaymentAmounts: FC<Props> = ({
  hasFees,
  onSetPaymentValues,
  paymentAmountValues,
}) => {
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const dispatch = useDispatch();

  const { data: myFeesAllData, loading: isLoadingFees } = useGetMyFinancialFeesAll();

  const fees = myFeesAllData?.myFinancialFeesAll;

  const outstandingFees = useMemo<GQL.IFinancialFeeIndexItem[]>(
    () =>
      (fees ?? []).filter(
        (fee) => fee.balanceDueInCents > 0 && fee.onlinePaymentsEnabled,
      ),
    [fees],
  );

  const hasMixedFees = useMemo(() => {
    const hasItemsWithFee = outstandingFees.some((fee) => !fee.isOrganizationCoveringStripeFee);
    const hasItemsWithoutFee = outstandingFees.some((fee) => fee.isOrganizationCoveringStripeFee);

    return hasItemsWithFee && hasItemsWithoutFee;
  }, [outstandingFees]);

  const outstandingFeeTotal = displayPriceStringFromDollarAmount(
    convertCentsToDollars(outstandingFees.reduce(
      (prev, curr) => prev + curr.balanceDueInCents,
      0,
    )),
  );

  const initialFormValues = useMemo<PaymentsFormValues>(
    () => ({
      payments: outstandingFees.map((fee) => ({
        amountInDollars: 0,
        balanceDueInCents: fee.balanceDueInCents,
        financialFeeId: fee.id,
        isOrganizationCoveringStripeFee: fee.isOrganizationCoveringStripeFee,
        itemLabel: fee.financialItemLabel,
        userName: `${fee.userFirstName} ${fee.userLastName}`,
      })),
    }),
    [outstandingFees],
  );

  const handleEdit = useCallback(() => {
    onSetPaymentValues(null);
  }, [onSetPaymentValues]);

  const handleFormikSubmit = (values: PaymentsFormValues) => {
    const hasNegativeValues = values.payments.some(
      (payment) => payment.amountInDollars < 0,
    );
    const hasOverpaidValues = values.payments.some(
      (payment) =>
        convertDollarsToCents(payment.amountInDollars)
        > payment.balanceDueInCents,
    );
    const filteredPayments = values.payments.filter(
      (payment) => payment.amountInDollars > 0,
    );

    const areAllFeesCovered = filteredPayments
      .every((payment) => payment.isOrganizationCoveringStripeFee);
    const areNoFeesCovered = filteredPayments
      .every((payment) => !payment.isOrganizationCoveringStripeFee);

    const areFeesMixed = !areAllFeesCovered && !areNoFeesCovered;

    if (hasNegativeValues) {
      dispatch(addNotification('Negative amounts are not allowed.', 'error'));
    } else if (hasOverpaidValues) {
      dispatch(addNotification('Amount may not exceed remaining balance.', 'error'));
    } else if (filteredPayments.length === 0) {
      dispatch(addNotification('At least one fee must have a positive amount.', 'error'));
    } else if (areFeesMixed) {
      dispatch(addNotification('Please choose only items with processing fees or items without processing fees.', 'error'));
    } else {
      onSetPaymentValues({
        payments: filteredPayments,
      });
    }
  };

  if (isLoadingFees) {
    return (
      <EnhancedCard>
        <CardContent>
          <Box
            display="flex"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        </CardContent>
      </EnhancedCard>
    );
  }

  if (!isLoadingFees && !outstandingFees.length) {
    return (
      <EnhancedCard>
        <CardContent>
          <Typography>
            You have no outstanding fees that can be paid online.
          </Typography>
        </CardContent>
      </EnhancedCard>
    );
  }

  return paymentAmountValues ? (
    <Container maxWidth="sm">
      <PaymentAmountsCard
        hasFees={hasFees}
        onEdit={handleEdit}
        paymentAmounts={paymentAmountValues}
      />
    </Container>
  ) : (
    <Formik<PaymentsFormValues>
      enableReinitialize
      initialValues={initialFormValues}
      onSubmit={handleFormikSubmit}
      validateOnChange={false}
    >
      {({ handleSubmit, values }) => (
        <EnhancedCard>
          <ShowCardHeader title="Payment Amount" />
          <Form onSubmit={handleSubmit}>
            <CardContent>
              {(isMobileOrTabletScreen ? (
                <>
                  {outstandingFees.map((fee, index) => (
                    <PaymentCard
                      fee={fee}
                      hasMixedFees={hasMixedFees}
                      index={index}
                      key={fee.id}
                    />
                  ))}
                  <Box
                    alignItems="flex-start"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-around"
                    width="100%"
                  >
                    <ShowPageDataDisplay
                      align="left"
                      label="Total Payment"
                      type="text"
                      value={displayPriceStringFromDollarAmount(values.payments.reduce(
                        (prev, curr) => prev + Number(curr.amountInDollars),
                        0,
                      ))}
                    />
                    <ShowPageDataDisplay
                      align="left"
                      label="Total Due"
                      type="text"
                      value={outstandingFeeTotal}
                    />
                  </Box>
                </>
              ) : (
                <PaymentTable
                  financialFees={outstandingFees}
                  formValues={values}
                  hasMixedFees={hasMixedFees}
                />
              ))}
            </CardContent>

            <CardActions>
              <Box
                display="flex"
                justifyContent="flex-end"
                marginTop={2}
                width="100%"
              >
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Next
                </Button>
              </Box>
            </CardActions>
          </Form>
        </EnhancedCard>
      )}
    </Formik>
  );
};

export default EnterPaymentAmounts;
