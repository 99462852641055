// External Dependencies
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ApolloProvider } from '@apollo/client';
import { FC } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { LocationProvider } from '@reach/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import {
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment';

// Internal Dependencies
import { ErrorBoundary, darkTheme, lightTheme } from 'components/shared';
import { initializeStore } from 'state/configureStore';
import { useDarkMode } from 'state/ui/quickSettingsMenu/selectors';
import CookieWrapper from 'components/shared/CookieWrapper';
import DarkTheme from 'components/shared/DarkTheme';
import HealthStatusProvider from 'components/shared/HealthStatusProvider';
import LightTheme from 'components/shared/LightTheme';
import Routes from 'routes';
import StripeProvider from 'components/shared/StripePaymentForm/StripeProvider';
import SuccessDialog from 'components/shared/SuccessDialog';
import client from 'gql/client';

// Local Typings
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme { }
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY ?? '');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  },
});

const GlobalStateProviders: FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ApolloProvider client={client}>
      <ReduxProvider store={initializeStore()}>
        {children}
      </ReduxProvider>
    </ApolloProvider>
  </QueryClientProvider>
);

const StyleProviders: FC = ({ children }) => {
  const isAppDarkMode = useSelector(useDarkMode);

  const theme = isAppDarkMode ? darkTheme : lightTheme;

  return (
    <StylesProvider
      // This ensures that MUI styles occur at the beginning of <head>
      // So styled-components will win when specificity is otherwise equal
      injectFirst
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <StyledComponentsThemeProvider theme={theme}>
            {isAppDarkMode ? <DarkTheme /> : <LightTheme />}

            {children}
          </StyledComponentsThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
};

// Component Definition
const App: FC = () => (
  <GlobalStateProviders>
    <StyleProviders>
      <HealthStatusProvider>
        <ErrorBoundary>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            dateLibInstance={moment}
          >
            <LocationProvider>
              <CookieWrapper>
                <StripeProvider>
                  <Routes />

                  <SuccessDialog />
                </StripeProvider>
              </CookieWrapper>
            </LocationProvider>
          </LocalizationProvider>
        </ErrorBoundary>
      </HealthStatusProvider>
    </StyleProviders>
  </GlobalStateProviders>
);

export default App;
