// External Dependencies
import { FC, useCallback } from 'react';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';
import { useCreateChecklist } from 'gql/mutations';

// Local Dependencies
import ChecklistInfoForm from '../shared/ChecklistInfoForm';

// Component Definition
const ChecklistsNew: FC = () => {
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);
  // For tiny phone screens, we reduce padding on the inputs
  const inputSize = isMobileOrTabletScreen ? 'small' : 'medium';

  const handleNavigateToMembersStep = useCallback((id: string) => {
    navigate(`/${PATHS.CHECKLISTS}/${id}/edit/members?step=2`);
  }, []);

  const [
    createChecklist,
    {
      error: checklistDataError,
      loading: isCreatingChecklist,
    },
  ] = useCreateChecklist(
    {
      clearCachePredicates: ['checklists'],
      onCompleted: (data) => {
        handleNavigateToMembersStep(data.createChecklist.id);
      },
    },
  );

  const handleSubmit = useCallback(async (
    values: GQL.ICreateChecklistInput,
  ) => {
    await createChecklist({
      variables: {
        input: {
          ...values,
          schoolYearEnding: Number(values.schoolYearEnding),
        },
      },
    });
  }, [createChecklist]);

  return (
    <ChecklistInfoForm
      checklistDataError={checklistDataError?.message}
      inputSize={inputSize}
      loading={isCreatingChecklist}
      onSubmit={handleSubmit}
    />
  );
};

export default ChecklistsNew;
