// External Dependencies
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import {
  currencyFromCentsColumn,
  dateColumn,
  dateTimeColumn,
} from 'utils/lib/tableColumns';
import { displaySchoolYearString } from 'utils/lib/displaySchoolYearString';
import { useGetOrganizationSchoolYearOptions } from 'gql/queries';

// Local Variables
export const useColumns = (
  extraColumns: DataGridColDef<GQL.IFinancialTransactionReportItem>[],
) => {
  const {
    data: schoolYearEndingData,
  } = useGetOrganizationSchoolYearOptions();

  return useMemo(() => {
    const columns: DataGridColDef<GQL.IFinancialTransactionReportItem>[] = [
      dateTimeColumn({
        field: 'createdAt',
        headerName: 'Created At',
      }),
      dateColumn({
        field: 'paymentDate',
        headerName: 'Payment Date',
      }),
      currencyFromCentsColumn({
        field: 'paymentAmountInCents',
        headerName: 'Payment Amount',
      }),
      currencyFromCentsColumn({
        field: 'creditAppliedInCents',
        headerName: 'Credit Applied',
      }),
      currencyFromCentsColumn({
        field: 'totalInCents',
        headerName: 'Total',
        hideable: false,
      }),
      {
        field: 'itemLabel',
        headerName: 'Item',
        minWidth: 160,
      },
      {
        field: 'itemSchoolYearEnding',
        headerName: 'Item School Year',
        minWidth: 160,
        renderCell: (params) => {
          return displaySchoolYearString(params.row.itemSchoolYearEnding);
        },
        type: 'singleSelect',
        valueOptions: schoolYearEndingData?.organizationSchoolYearSelectOptions?.map((option) => ({
          label: option.label,
          value: option.id,
        })) ?? [],
      },
      {
        field: 'memberFirstName',
        headerName: 'First Name',
        minWidth: 160,
      },
      {
        field: 'memberLastName',
        headerName: 'Last Name',
        minWidth: 160,
      },
      {
        field: 'paymentType',
        headerName: 'Payment Type',
        minWidth: 160,
      },
      {
        field: 'createdByMemberFirstName',
        headerName: 'Created By First Name',
        minWidth: 180,
      },
      {
        field: 'createdByMemberLastName',
        headerName: 'Created By Last Name',
        minWidth: 180,
      },
      {
        field: 'stripeChargeId',
        headerName: 'Stripe Charge ID',
        minWidth: 180,
      },
      {
        field: 'stripePaymentId',
        headerName: 'Stripe Payment ID',
        minWidth: 180,
      },
      {
        field: 'stripePaymentIntentId',
        headerName: 'Stripe Payment Intent ID',
        minWidth: 180,
      },
      {
        field: 'stripeTransferId',
        headerName: 'Stripe Transfer ID',
        minWidth: 180,
      },
      {
        field: 'transactionId',
        headerName: 'Transaction ID',
        minWidth: 180,
      },
      currencyFromCentsColumn({
        field: 'groupedTransactionTotalInCents',
        headerName: 'Grouped Transaction Total',
        minWidth: 200,
      }),
      currencyFromCentsColumn({
        field: 'groupedTransactionTotalPlusCreditAppliedInCents',
        headerName: 'Grouped Transaction Total + Credit',
        minWidth: 250,
      }),
      ...extraColumns,
    ];

    return columns;
  }, [extraColumns, schoolYearEndingData]);
};
