// External Dependencies
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Grow,
  Typography,
} from '@mui/material/';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomFormButton,
  OnboardingStepTitle,
  StyledLink,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { displaySchoolYearString } from 'utils/lib/displaySchoolYearString';
import { navigate } from '@reach/router';
import { pluralize } from 'utils';
import { useCallback } from 'react';
import { useGetOrganization } from 'gql/queries';
import { useIgnoreEoyWarning } from 'gql/mutations';
import BeachSvg from 'components/shared/BeachSvg';

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '.buttonText': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
    },
    fontSize: '1.1em',
    textTransform: 'none',
  },
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}));

// Component Definition
const OnboardingEndOfYearWarning = () => {
  const organization = useGetOrganization();

  const [
    ignoreEoyWarning,
    {
      loading: isIgnoring,
    },
  ] = useIgnoreEoyWarning();

  const handleClickBegin = useCallback(async () => {
    await ignoreEoyWarning();
    navigate(`/${PATHS.END_OF_YEAR}`);
  }, [ignoreEoyWarning, navigate]);

  const handleClickIgnore = useCallback(() => {
    ignoreEoyWarning();
  }, [ignoreEoyWarning]);

  const { data: orgData, loading } = organization;

  const daysUntilCurrentSchoolYearExpires = orgData?.organization.daysUntilCurrentSchoolYearExpires;
  const currentSchoolYearEnding = orgData?.organization.currentSchoolYearEnding;

  const nextYearText = displaySchoolYearString((currentSchoolYearEnding ?? 0) + 1);

  const title = (daysUntilCurrentSchoolYearExpires ?? 0) < 30 ? 'Summer is Here!' : 'Summer is Almost Here!';

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <OnboardingStepTitle title={title} />

      <Grow in>
        <StyledCardContent>
          <Box
            p={2}
            width="75%"
          >
            <BeachSvg />
          </Box>
          <Box m={4}>
            <Typography>
              It is time to promote students to the next grade
              and prepare for the upcoming school year.
            </Typography>

            {(daysUntilCurrentSchoolYearExpires ?? 0) > 0 && (
              <Typography sx={{ mt: 2 }}>
                There are{' '}
                <strong>
                  {daysUntilCurrentSchoolYearExpires} {pluralize(daysUntilCurrentSchoolYearExpires, 'day')}
                </strong>
                {' '}remaining to complete this process and begin the <strong>{nextYearText}</strong> school year.
              </Typography>
            )}

            <Typography sx={{ mt: 2 }}>
              Questions? Please{' '}
              <StyledLink href="mailto:support@presto-assistant.com">
                contact us
              </StyledLink>{' '}
              any time. We&apos;re glad to help!
            </Typography>
          </Box>

          {isIgnoring ? <CircularProgress /> : (
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <CustomFormButton
                buttonText="Begin End of Year Process"
                key="onboarding-complete-submit-button"
                labelClasses="buttonText"
                onClick={handleClickBegin}
                size="large"
              />

              {(daysUntilCurrentSchoolYearExpires ?? 0) > 0 && (
                <Button
                  color="primary"
                  onClick={handleClickIgnore}
                >
                  I&apos;ll do this later
                </Button>
              )}
            </Box>
          )}
        </StyledCardContent>
      </Grow>
    </>
  );
};

export default OnboardingEndOfYearWarning;
