// External Dependencies
import { FC, useState } from 'react';
import { createUserSchema } from '@presto-assistant/api_types/schemas/user';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { trimValues } from 'utils/lib/trimValues';
import { useCreateUser } from 'gql/mutations';
import { useGetGettingStartedCounts, useMemberLookup } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import DialogConfirmExistingMember from 'pages/People/shared/DialogConfirmExistingMember';

// Local Dependencies
import AdultForm from '../../shared/AdultForm';

// Local Variables
const navigateToShow = (id: string) => {
  navigate(`/${PATHS.DIRECTORS}/${id}`);
};

// Component Definition
const DirectorsNew: FC = () => {
  const [payload, setPayload] = useState<GQL.ICreateUserInput | null>(null);

  const {
    isOpen: isConfirmationDialogOpen,
    toggleIsOpen: handleToggleIsConfirmationDialogOpen,
  } = useIsOpen();

  const directorsParams = useSelector(tableQueryParams('directors'));

  const {
    data: gettingStartedCountsData,
  } = useGetGettingStartedCounts();

  const hasAnotherDirector = (gettingStartedCountsData?.dashboardMetrics?.directorCount ?? 0) > 1;

  const [createDirector, { error }] = useCreateUser(
    {
      clearCachePredicates: [
        'directorsIndex',
        ...!hasAnotherDirector ? ['dashboardMetrics'] : [],
      ],
      onCompleted: (data) => navigateToShow(data.createUser.id),
    },
  );

  const checkForExistingMemberInThisOrganization = useMemberLookup();

  const handleCreateDirector = () => {
    if (payload) {
      createDirector({
        variables: {
          input: payload,
        },
      });
    }
  };

  const handleSubmit = async (values: GQL.ICreateUserInput) => {
    const localPayload = {
      ...trimValues(values) as GQL.ICreateUserInput,
      primarySpokenLanguageId: values.primarySpokenLanguageId
        ? Number(values.primarySpokenLanguageId)
        : 1,
    };

    setPayload(localPayload);

    const { data: existingMemberData } = await checkForExistingMemberInThisOrganization({
      memberEmail: values.email,
    });

    const isExistingMember = Boolean(existingMemberData.memberLookup.length);

    if (isExistingMember) {
      handleToggleIsConfirmationDialogOpen();
    } else {
      await createDirector({
        variables: {
          input: localPayload,
        },
      });
    }
  };

  return (
    <Page
      backButtonProps={{
        label: 'Directors',
        path: `/${PATHS.DIRECTORS}${directorsParams}`,
      }}
      error={error}
    >
      <AdultForm
        isDirector
        onSubmit={handleSubmit}
        validationSchema={createUserSchema}
      />

      <DialogConfirmExistingMember
        isEditing={false}
        isOpen={isConfirmationDialogOpen}
        onSubmit={handleCreateDirector}
        onToggle={handleToggleIsConfirmationDialogOpen}
      />
    </Page>
  );
};

export default DirectorsNew;
