// External Dependencies
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { GridRowId } from '@mui/x-data-grid-pro';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PersonIcon from '@mui/icons-material/Person';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { hasPermission } from 'state/self/selectors';
import { useGetFinancialTransactionReport } from 'gql/queries/financial-transaction-queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';
import FinancialTransactionsTableZeroState from './FinancialTransactionsTableZeroState';

// Local Typings
interface Props {
  onChangeTotalInCents: (totalInCents: number) => void;
}

// Local Variables
const handleGetRowId = (row: GQL.IFinancialTransactionReportItem) => `${row.type}-${row.paymentId}`;

// Component Definition
const FinancialTransactionsTable: FC<Props> = ({
  onChangeTotalInCents,
}) => {
  const [filteredRowIds, setFilteredRowIds] = useState<GridRowId[]>([]);

  const {
    data,
    loading: isLoading,
  } = useGetFinancialTransactionReport();

  const canReadMembers = useSelector(hasPermission('users', 'read'));

  const handleClickViewPayment = useCallback((row: GQL.IFinancialTransactionReportItem) => {
    navigate(`/${PATHS.FINANCIAL_PAYMENTS}/${row.paymentId}`);
  }, []);

  const handleClickViewMember = useCallback((row: GQL.IFinancialTransactionReportItem) => {
    navigate(`/${PATHS.MEMBERS}/${row.memberId}`);
  }, []);

  const extraColumns = useMemo<DataGridColDef<GQL.IFinancialTransactionReportItem>[]>(
    () => {
      const actionsColumn = createDataGridActionsColumn<GQL.IFinancialTransactionReportItem>([
        {
          action: handleClickViewPayment,
          icon: <LocalAtmIcon />,
          text: 'View Payment',
        },
        ...(canReadMembers ? [{
          action: handleClickViewMember,
          icon: <PersonIcon />,
          text: 'View Member',
        }] : []),
      ]) as DataGridColDef<GQL.IFinancialTransactionReportItem>;

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      canReadMembers,
      handleClickViewPayment,
      handleClickViewMember,
    ],
  );

  const columns = useColumns(extraColumns);

  const filteredData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.financialTransactionReport
      .filter((row) => filteredRowIds.includes(handleGetRowId(row)));
  }, [data, filteredRowIds]);

  const totalInCents = useMemo(() => {
    return filteredData
      .reduce((acc, row) => acc + row.totalInCents, 0);
  }, [filteredData]);

  useEffect(() => {
    onChangeTotalInCents(totalInCents);
  }, [onChangeTotalInCents, totalInCents]);

  return (
    <DataGridContainer>
      <TableDataGrid
        columns={columns}
        components={{
          NoRowsOverlay: FinancialTransactionsTableZeroState,
        }}
        getRowId={handleGetRowId}
        loading={isLoading}
        onFilter={setFilteredRowIds}
        rows={data?.financialTransactionReport ?? []}
        tableResource="financialTransactions"
        withSearch
      />
    </DataGridContainer>
  );
};

export default FinancialTransactionsTable;
