// External Dependencies
import { Autocomplete, Chip, TextField } from '@mui/material';
import { GridColDef, GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { OrganizationEntityTypes, UserRoles } from '@presto-assistant/api_types';
import { useImperativeHandle, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { hasPermission, isDirector } from 'state/self/selectors';
import { mapEnum } from 'utils/lib/map_enum';
import {
  useGetGroupsAll,
  useGetOrganization,
  useGetOrganizationRoles,
  useGetPossibleFeederOrganizations,
} from 'gql/queries';
import { useGradeColDef } from 'components/shared/TableDataGrid/hooks';

// Local Variables
const GroupsInput = (props: GridFilterInputValueProps) => {
  const { applyValue, focusElementRef, item } = props;

  const inputRef = useRef<HTMLInputElement>();

  useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      inputRef.current?.querySelector<HTMLInputElement>('input[id="group-filter"]')?.focus();
    },
  }));

  const handleFilterChange = (_event: any, selectedGroups: GQL.ISimpleGroup[]) => {
    applyValue({ ...item, value: selectedGroups });
  };

  const {
    data: groupsData,
  } = useGetGroupsAll();

  return (
    <Autocomplete
      defaultValue={item.value}
      getOptionLabel={(group: GQL.ISimpleGroup) => group.label}
      id="group-filter"
      multiple
      onChange={handleFilterChange}
      options={groupsData?.groups.data ?? [] as any}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Groups"
          placeholder="Groups"
          size="medium"
          variant="standard"
        />
      )}
      // Match the default styles of chips for datagrid singleSelect
      renderTags={(value: readonly GQL.ISimpleGroup[], getTagProps) =>
        value.map((option: GQL.ISimpleGroup, index: number) => (
          <Chip
            label={option.label}
            size="small"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))}
      size="small"
    />
  );
};

const SecondaryRolesInput = (props: GridFilterInputValueProps) => {
  const { applyValue, focusElementRef, item } = props;

  const inputRef = useRef<HTMLInputElement>();

  useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      inputRef.current?.querySelector<HTMLInputElement>('input[id="secondary-roles-filter"]')?.focus();
    },
  }));

  const handleFilterChange = (_event: any, selectedGroups: GQL.ISecondaryRole[]) => {
    applyValue({ ...item, value: selectedGroups });
  };

  const {
    data: rolesData,
  } = useGetOrganizationRoles();

  return (
    <Autocomplete
      defaultValue={item.value}
      getOptionLabel={(role: GQL.ISecondaryRole) => role.label}
      id="secondary-roles-filter"
      multiple
      onChange={handleFilterChange}
      options={rolesData?.organizationRoles ?? [] as any}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Roles"
          placeholder="Roles"
          size="medium"
          variant="standard"
        />
      )}
      // Match the default styles of chips for datagrid singleSelect
      renderTags={(value: readonly GQL.ISecondaryRole[], getTagProps) =>
        value.map((option: GQL.ISecondaryRole, index: number) => (
          <Chip
            label={option.label}
            size="small"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))}
      size="small"
    />
  );
};

export const useColumns = (canSeelAllMembers: boolean) => {
  const {
    data: organizationRoleData,
  } = useGetOrganizationRoles();

  const { data: organizationData } = useGetOrganization();

  const canReadUsers = useSelector(hasPermission('users', 'read'));
  const isUserDirector = useSelector(isDirector);

  const gradeCol = useGradeColDef<GQL.IUser>({ field: 'grade' });

  const {
    data: possibleFeederOrganizationData,
  } = useGetPossibleFeederOrganizations();

  const directorColumns = useMemo<GridColDef[]>(() => [
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 160,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 160,
    },
    ...(canReadUsers ? [{
      field: 'email',
      headerName: 'Email',
      width: 240,
    }] : []),
  ], [canReadUsers]);

  const studentColumns = useMemo<GridColDef[]>(() => {
    const isCollegeOrUniversity = organizationData?.organization
      .organizationType.id === OrganizationEntityTypes.College.toString();
    const hasSuccessorOrganization = !!organizationData?.organization.successorOrganization;

    return [
      ...directorColumns,
      {
        field: 'roleLabel',
        headerName: 'Role',
        type: 'singleSelect',
        valueOptions: mapEnum(UserRoles).map((option) => ({
          label: option.label,
          value: option.label,
        })),
        width: 80,
      },
      { ...gradeCol },
      {
        field: 'primaryRoleLabel',
        headerName: 'Primary Role', // TODO: make this say "instrument" for band, orch; "part" for choir; "role" for theater, dance
        type: 'singleSelect',
        valueGetter: (params) => (params.row as GQL.IMemberIndex).primaryRole?.label,
        valueOptions: organizationRoleData?.organizationRoles.map((role) => ({
          label: role.label,
          value: role.label,
        })),
      },
      {
        field: 'secondaryRoles',
        filterOperators: [
          {
            InputComponent: SecondaryRolesInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const secondaryRoleIds = (params.row as GQL.IStudentIndex)
                  .secondaryRoles.map((role) => role.id);

                const selectedRoles: GQL.ISecondaryRole[] = filterItem.value;
                const selectedRoleIds = selectedRoles.map((role) => Number(role.id));

                if (!selectedRoles.length) {
                  return true;
                }

                return secondaryRoleIds.some((id) => selectedRoleIds.includes(id));
              };
            },
            label: 'is any of',
            value: 'isAnyOf',
          },
          {
            InputComponent: SecondaryRolesInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const secondaryRoleIds = (params.row as GQL.IStudentIndex)
                  .secondaryRoles.map((role) => role.id);

                const selectedRoles: GQL.ISecondaryRole[] = filterItem.value;
                const selectedRoleIds = selectedRoles.map((role) => Number(role.id));

                if (!selectedRoles.length) {
                  return true;
                }

                return selectedRoleIds.every((id) => secondaryRoleIds.includes(id));
              };
            },
            label: 'is all of',
            value: 'isAllOf',
          },
          {
            InputComponent: SecondaryRolesInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const secondaryRoleIds = (params.row as GQL.IStudentIndex)
                  .secondaryRoles.map((role) => role.id);

                const selectedRoles: GQL.ISecondaryRole[] = filterItem.value;
                const selectedRoleIds = selectedRoles.map((role) => Number(role.id));

                if (!selectedRoles.length) {
                  return true;
                }

                return selectedRoleIds.every((id) => !secondaryRoleIds.includes(id));
              };
            },
            label: 'is not in',
            value: 'isNotIn',
          },
        ],
        headerName: 'Secondary Roles', // TODO: make this say "instrument" for band, orch; "part" for choir; "role" for theater, dance
        valueGetter: (params) => (params.row as GQL.IStudentIndex).secondaryRoles
          .map((secondaryRole) => secondaryRole.label).join('; '),
        valueOptions: organizationRoleData?.organizationRoles.map((role) => ({
          label: role.label,
          value: role.label,
        })),
      },
      {
        field: 'studentId',
        headerName: 'Student ID',
      },
      ...((canReadUsers && isUserDirector) ? [{
        field: 'isEligible',
        headerName: 'Is Eligible',
        type: 'boolean',
      }] : []),
      {
        field: 'groups',
        filterOperators: [
          {
            InputComponent: GroupsInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const groupIds = (params.row as GQL.IStudentIndex).groups.map((g) => g.id);

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroups.length) {
                  return true;
                }

                return groupIds.some((id) => selectedGroupIds.includes(id));
              };
            },
            label: 'is any of',
            value: 'isAnyOf',
          },
          {
            InputComponent: GroupsInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const groupIds = (params.row as GQL.IStudentIndex).groups.map((g) => g.id);

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroupIds.length) {
                  return true;
                }

                return selectedGroupIds.every((id) => groupIds.includes(id));
              };
            },
            label: 'is all of',
            value: 'isAllOf',
          },
          {
            InputComponent: GroupsInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField
                || !filterItem.value
                || !filterItem.operatorValue
              ) {
                return null;
              }

              return (params): boolean => {
                const groupIds = (params.row as GQL.IStudentIndex).groups.map((g) => g.id);

                const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
                const selectedGroupIds = selectedGroups.map((g) => g.id);

                if (!selectedGroupIds.length) {
                  return true;
                }

                return selectedGroupIds.every((id) => !groupIds.includes(id));
              };
            },
            label: 'is not in',
            value: 'isNotIn',
          },
        ],
        headerName: 'Groups',
        valueGetter: (params) => (params.row as GQL.IStudentIndex).groups
          .map((group) => group.label).join('; '),
      },
      ...(!isCollegeOrUniversity && hasSuccessorOrganization ? [{
        field: 'successorOrganizationLabel',
        headerName: 'Successor Organization',
        type: 'singleSelect',
        valueGetter: (params) => (params.row as GQL.IStudentIndex)
          .successorOrganization?.label
          ?? organizationData?.organization.successorOrganization?.label,
        valueOptions: possibleFeederOrganizationData?.possibleFeederOrganizations
          .map((possibleOrganization) => ({
            label: possibleOrganization.label,
            value: possibleOrganization.label,
          })) ?? [],
        width: 180,
      } as GridColDef] : []),
    ];
  }, [
    canReadUsers,
    directorColumns,
    gradeCol,
    isUserDirector,
    organizationData?.organization.organizationType.id,
    organizationData?.organization.successorOrganization,
    organizationRoleData?.organizationRoles,
    possibleFeederOrganizationData?.possibleFeederOrganizations,
  ]);

  return canSeelAllMembers ? studentColumns : directorColumns;
};
