// External Dependencies
import {
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

// Internal Dependencies
import { Address, EnhancedCard, StyledLink } from 'components/shared';
import { DOWNLOADS } from 'utils/constants/routes';
import {
  getFullName,
  pluralize,
} from 'utils';
import { isDistrictAdmin } from 'state/self/selectors';
import ManageDistrictAssistantsListItem from 'pages/DistrictAdmin/AdminSettings/ManageDistrictAssistantsListItem';

// Local Dependencies
import DistrictCardHeader from './DistrictCardHeader';

// Local Typings
interface Props {
  district: GQL.IDistrict;
}

// Local Variables
const useStyles = makeStyles({
  listItem: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  listItemFirst: {
    fontWeight: 500,
  },
  subtitle: {
    fontSize: '1rem',
    fontWeight: 500,
  },
});

const StyledEnhancedCard = styled(EnhancedCard)({
  address: {
    fontStyle: 'normal',
  },
});

// Component Definition
const DistrictDataUI: FC<Props> = ({ district }) => {
  const classes = useStyles();

  const isDistrictAdminUser = useSelector(isDistrictAdmin);

  if (!district) {
    return null;
  }

  const {
    addressOne,
    addressTwo,
    admin,
    city,
    label: districtName,
    phoneNumber,
    state: {
      label: stateLabel,
    },
    zipcode,
  } = district;

  return (
    <StyledEnhancedCard>
      <DistrictCardHeader />

      <CardContent>
        <Typography
          className={classes.subtitle}
          component="h3"
        >
          District Info
        </Typography>

        <List>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={districtName}
              primaryTypographyProps={{
                component: 'div',
              }}
              secondary={(
                <Address
                  addressOne={addressOne}
                  addressTwo={addressTwo}
                  city={city}
                  phoneNumber={phoneNumber}
                  state={stateLabel}
                  zipcode={zipcode}
                />
              )}
              secondaryTypographyProps={{
                component: 'div',
              }}
            />
          </ListItem>
        </List>

        <Typography
          className={classes.subtitle}
          component="h3"
        >
          District Administrator
        </Typography>

        <List>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={getFullName(admin)}
              primaryTypographyProps={{
                component: 'div',
              }}
              secondary={(
                <address>
                  {admin.email}
                </address>
              )}
              secondaryTypographyProps={{
                component: 'div',
              }}
            />
          </ListItem>
        </List>

        {/* District Admin "district" data will not have districtAssistants */}
        {district.districtAssistants?.length ? (
          <>
            <Typography
              className={classes.subtitle}
              component="h3"
            >
              {district.districtAssistants && pluralize(district.districtAssistants.length, 'District Assistant')}
            </Typography>

            <List>
              {district.districtAssistants.map((districtAssistant) => (
                <ListItem
                  className={classes.listItem}
                  key={districtAssistant.email}
                >
                  <ListItemText
                    primary={getFullName(districtAssistant)}
                    primaryTypographyProps={{
                      component: 'div',
                    }}
                    secondary={(
                      <address>
                        {districtAssistant.email}
                      </address>
                    )}
                    secondaryTypographyProps={{
                      component: 'div',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </>
        ) : null}
      </CardContent>

      <Divider />

      <CardContent>
        <Typography
          className={classes.subtitle}
          component="h3"
        >
          Downloads
        </Typography>

        <List>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={(
                <StyledLink
                  href={DOWNLOADS.ACCEPTABLE_USE_POLICY_AGREEMENT}
                  openInNewTab
                >
                  Acceptable Use Policy Agreement
                </StyledLink>
              )}
              primaryTypographyProps={{
                component: 'div',
              }}
            />
          </ListItem>
        </List>
      </CardContent>

      {isDistrictAdminUser && (
        <>
          <Divider />

          <CardContent>
            <Typography
              className={classes.subtitle}
              component="h3"
            >
              District Actions
            </Typography>

            <List>
              <ManageDistrictAssistantsListItem />
            </List>

          </CardContent>
        </>
      )}

    </StyledEnhancedCard>
  );
};

export default DistrictDataUI;
