// External Dependencies
import {
  Box,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Internal Dependencies
import { ShowPageDataDisplay } from 'components/shared';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';

// Local Typings
interface Props {
  inventoryItemData: GQL.IInventoryItem;
  isAdmin?: boolean;
  prestoEnhancedSerialNumber: string;
}

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  fontSize: '0.65rem',
  marginLeft: theme.spacing(3),
  maxWidth: '40%',
}));

const serialNumberHelperText = 'This unique serial number was added by Presto';

// Component Definition
const ShowInventoryBasicData: FC<Props> = ({
  inventoryItemData,
  isAdmin,
  prestoEnhancedSerialNumber,
}) => {
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  if (!inventoryItemData) {
    return null;
  }

  const {
    brand,
    category,
    customBarcode,
    label,
    model,
    organization,
    organizationType,
    quantity,
    serialNumber,
    systemBarcode,
  } = inventoryItemData;

  const serialNumberHelperTextElement = isMobileOrTabletScreen && (
    <StyledTypography color="textSecondary">
      {serialNumberHelperText}
    </StyledTypography>
  );

  return (
    <>
      {isAdmin && (
        <>
          <ShowPageDataDisplay
            label="Organization Type"
            value={organizationType?.label}
          />
          <ShowPageDataDisplay
            label="Organization"
            value={organization?.label}
          />
        </>
      )}
      <ShowPageDataDisplay
        label="Category"
        value={category?.label}
      />
      <ShowPageDataDisplay
        label="Name"
        value={label}
      />
      <ShowPageDataDisplay
        label="Quantity"
        value={quantity}
      />
      <ShowPageDataDisplay
        label="Brand"
        value={brand}
      />
      <ShowPageDataDisplay
        label="Model"
        value={model}
      />
      <Box
        alignItems="end"
        display="flex"
      >
        <ShowPageDataDisplay
          label="Serial Number"
          tooltipTitle={!serialNumber ? serialNumberHelperText : ''}
          value={serialNumber || prestoEnhancedSerialNumber}
        />
        {!serialNumber ? serialNumberHelperTextElement : null}
      </Box>
      <ShowPageDataDisplay
        label="Custom Barcode"
        value={customBarcode}
      />
      <ShowPageDataDisplay
        label="System Barcode"
        value={systemBarcode}
      />
    </>
  );
};

export default ShowInventoryBasicData;
