// External Dependencies
import {
  CardContent, IconButton,
} from '@mui/material';
import { FC } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import EditIcon from '@mui/icons-material/Edit';
import startCase from 'lodash.startcase';

// Internal Dependencies
import { EnhancedCard, ShowCardHeader, ShowPageDataDisplay } from 'components/shared';

// Local Typings
interface Props {
  onEdit: () => void;
  paymentMethod: PaymentMethod;
}

// Component Definition
const PaymentMethodCard: FC<Props> = ({
  onEdit,
  paymentMethod,
}) => (
  <EnhancedCard>
    <ShowCardHeader
      action={(
        <IconButton
          aria-label="Edit payment method"
          onClick={onEdit}
          size="large"
        >
          <EditIcon />
        </IconButton>
      )}
      title="Payment Method"
    />

    <CardContent>
      <ShowPageDataDisplay
        label="Card Type"
        value={startCase(paymentMethod.card?.brand)}
      />

      <ShowPageDataDisplay
        label="Last Four"
        value={paymentMethod.card?.last4}
      />

      <ShowPageDataDisplay
        label="Exp"
        value={`${paymentMethod.card?.exp_month} / ${paymentMethod.card?.exp_year}`}
      />
    </CardContent>
  </EnhancedCard>
);

export default PaymentMethodCard;
