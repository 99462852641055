// External Dependencies
import { FinancialCreditStatuses } from '@presto-assistant/api_types';
import { useMemo } from 'react';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import {
  convertCentsToDollars,
  displayPriceStringFromDollarAmount,
} from 'utils';

import {
  convertCentsToDollarsAllowNull,
} from '@presto-assistant/api_types/utils';
import { displayCell } from 'components/shared/TableV2';
import { mapEnum } from 'utils/lib/map_enum';
import { renderMoneyCell } from 'components/shared/TableDataGrid/helpers';
import { useGetFinancialAccounts } from 'gql/queries';
import StripeStatusChip from 'pages/Finances/FinancialPayments/shared/StripeStatusChip';

export const useColumns = (
  extraColumns?: DataGridColDef<GQL.IFinancialCreditIndexItem>[],
): DataGridColDef<GQL.IFinancialCreditIndexItem>[] => {
  const { data: financialAccountsData } = useGetFinancialAccounts();

  return useMemo(() => {
    const columns: DataGridColDef<GQL.IFinancialCreditIndexItem>[] = [
      {
        field: 'userFirstName',
        headerName: 'First Name',
      },
      {
        field: 'userLastName',
        headerName: 'Last Name',
      },
      {
        field: 'financialAccountLabel',
        headerName: 'Financial Account',
        type: 'singleSelect',
        valueGetter: (params) =>
          financialAccountsData?.financialAccounts.find((account) =>
            account.label === params.row.financialAccountLabel)?.label,
        valueOptions: financialAccountsData?.financialAccounts?.map((account) => ({
          label: account.label,
          value: account.label,
        })),
      },
      {
        field: 'status',
        headerName: 'Credit Status',
        type: 'singleSelect',
        valueOptions: mapEnum(FinancialCreditStatuses)
          .map((option) => ({
            label: option.label,
            value: option.label,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      },
      {
        field: 'initialAmount' as keyof GQL.IFinancialCreditIndexItem,
        headerName: 'Initial Amount',
        renderCell: (params) => {
          const { amountInCents, isRefunded } = params.row as GQL.IFinancialCreditIndexItem;
          if (isRefunded) {
            return (
              <>
                <StripeStatusChip
                  label="Refunded"
                  size="small"
                  stripePaymentStatusId={null}
                  sx={{ mr: 1 }}
                />
                {displayPriceStringFromDollarAmount(
                  convertCentsToDollars(amountInCents),
                )}
              </>
            );
          }
          return displayPriceStringFromDollarAmount(
            convertCentsToDollars(amountInCents),
          );
        },
        type: 'number',
        valueGetter: (params) => {
          return displayCell(convertCentsToDollarsAllowNull(params.row.amountInCents));
        },
      },
      {
        field: 'amountAppliedInCents',
        headerName: 'Amount Applied',
        renderCell: (params) => {
          if (params.value === null) {
            return null;
          }

          return renderMoneyCell(params.value);
        },
        type: 'number',
        valueGetter: (params) =>
          (params.row.isRefunded
            ? null
            : displayCell(convertCentsToDollarsAllowNull(params.row.amountAppliedInCents))),
      },
      {
        field: 'amountTransferredInCents',
        headerName: 'Amount Transferred',
        renderCell: (params) => {
          if (params.value === null) {
            return null;
          }

          return renderMoneyCell(params.value);
        },
        type: 'number',
        valueGetter: (params) =>
          (params.row.isRefunded
            ? null
            : displayCell(convertCentsToDollarsAllowNull(params.row.amountTransferredInCents))),
      },
      {
        field: 'amountRemainingInCents' as keyof GQL.IFinancialCreditIndexItem,
        headerName: 'Amount Remaining',
        renderCell: (params) => {
          if (params.value === null) {
            return null;
          }

          return renderMoneyCell(params.value);
        },
        type: 'number',
        valueGetter: (params) =>
          (params.row.isRefunded
            ? null
            : displayCell(convertCentsToDollarsAllowNull(params.row.creditRemainingInCents))),
      },
      {
        field: 'createdAt',
        headerName: 'Date Added',
        type: 'date',
        valueGetter: (params) =>
          displayCell(params.row.createdAt, { format: 'datetime' }),
      },
      {
        field: 'note',
        headerName: 'Note',
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [extraColumns, financialAccountsData]);
};
